<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('declarations.add_declaration.title')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class="  w-full mr-12">
        <FormSteps :steps="steps"/>
    </div>
      <div class=" w-full">
        <div class="mt-10 mr-6 " v-if="completed===false">
          <component
              v-for="(item, index) in steps"
              :key="`component-${index}`"
              :is="item.component"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"
              :active="item.active === true"
              v-show="item.active === true"
          />
        </div>
        <div class="mt-10 mr-10 ml-10" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FormSteps from "../FormSteps";

import Step1 from '@/components/declarations/declaration_steps/Step1.vue';
import Step2 from '@/components/declarations/declaration_steps/Step2.vue';
import Step3 from '@/components/declarations/declaration_steps/Step3.vue';
import Finish from '@/components/declarations/declaration_steps/Finish.vue';

export default {
  name: "AddDeclaration",
  components: {FormSteps, Step1, Step2, Step3, Finish},
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$i18n.t('breadcrumbs.declaration')
        }, {
          title: this.$i18n.t('breadcrumbs.add_declaration')
        }
      ],
      clientId: 0,
      declarationId: 0,
      completed: false,
      steps: [
        {
          id: 1,
          title: this.$i18n.t('declarations.add_declaration.steps.step1.title'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        }, {
          id: 2,
          title: this.$i18n.t('declarations.add_declaration.steps.step2.title'),
          completed: false,
          active: false,
          component: "Step2"
        }, {
          id: 3,
          title: this.$i18n.t('declarations.add_declaration.steps.step3.title'),
          completed: false,
          active: false,
          last: true,
          component: "Step3"
        }
      ],
    }
  },
  computed: {
    ...mapGetters("declaration", ["getCurrentStep"]),
  },
  mounted() {
    this.declarationId = 0;
    this.clientId = 0;
    this.init();
  },
  methods: {
    ...mapMutations("declaration", ["setCurrentStep", "init"]),
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    finish(e) {
      this.moveStep('next', true)
      this.completed = true
      if(e.declarationId) {
        this.declarationId = e.declarationId;
      }
      if(e.clientId) {
        this.clientId = e.clientId;
      }
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
        let step = 1;
        this.setCurrentStep(step)
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }

        })

        this.setCurrentStep(step)
      }
    },
    handleFinishButtonClick() {
      this.init();
      this.steps = this.steps.map(x => {
        return {...x, completed: false, active: false}
      })
      let step = 1;
      this.setCurrentStep(step)
      this.steps[0].active = true;
      this.completed = false;
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
