import {i18n} from '@/plugins/i18n';

export const taxModels = [
  {
    model: "Bundesmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.bundesmodell'),
    stateCodes: ["3", "4", "5", "8", "10", "11", "13", "15", "16"]
  },
  {
    model: "Modifiziertes Bundesmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.modifiziertes_bundesmodell'),
    stateCodes: ["12", "14"]
  },
  {
    model: "Modifiziertes Bodenwertmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.modifiziertes_bundesmodell'),
    stateCodes: ["1"]
  },
  {
    model: "Flächen-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_modell'),
    stateCodes: ["2"]
  },
  {
    model: "Flächen-Faktor-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_faktor_modell'),
    stateCodes: ["7"]
  },
  {
    model: "Flächen-Lage-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_lage_modell'),
    stateCodes: ["9"]
  },
  {
    model: "Wohnlagen-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.wohnlagen_modell'),
    stateCodes: ["6"]
  }
];
