import {ApiService} from "@/services/api.service";

const state = {
  isReloadGrid: 0,
  gridState: [],
  gridFilters: [],
  navRecords: []
};
const getters = {
  getGridsState: (state) => state.gridState,
  getGridsFilters: (state) => state.gridFilters,
  getGridsNavRecords: (state) => state.navRecords
}

const mutations = {
  initGrid: (state, gridId) => {
    let index = state.gridState.findIndex(o => o.gridId === gridId);
    if (index < 0) {
      state.gridState.push({
        "gridId": gridId,
        "filter": {
          "selected": "",
          "txt": ""
        },
        "view": "normal",
        "dt_state": '',
        "reload": false,
        "fetchCount": true,
        "stateLoaded": false
      });
      state.navRecords.push({
        "gridId": gridId,
        "uids": []
      })
    } else {
      state.gridState[index]["fetchCount"] = true;
      state.gridState[index]["uids"] = [];
    }
  },
  initGridFilter: (state, gridId) => {
    let index = state.gridFilters.findIndex(o => o.gridId === gridId);
    if (index < 0) {
      state.gridFilters.push({
        "gridId": gridId,
        "customFilters": [],
        "fetched": false
      });
    }
  },
  setFilter: (state, filter) => {
    let index = state.gridState.findIndex(o => o.gridId === filter.gridId);
    if (index >= 0) {
      let storageKey = 'grid-filter-' + filter.gridId;
      let selectedFilter = sessionStorage.getItem(storageKey);
      if(selectedFilter === null) {
        selectedFilter = {
          "txt": "",
          "filterId": ""
        };
      } else {
        selectedFilter = JSON.parse(selectedFilter);
      }
      if (typeof (filter.selected) !== 'undefined') {
        state.gridState[index]['filter']['selected'] = filter.selected;
        selectedFilter.filterId = filter.selected;
      }
      if (typeof (filter.txt) !== 'undefined') {
        state.gridState[index]['filter']['txt'] = filter.txt;
        selectedFilter.txt = filter.txt;
      }
      sessionStorage.setItem(storageKey, JSON.stringify(selectedFilter));
    }
  },
  triggerReload: (state, gridId) => {
    state.isReloadGrid++;
    let index = state.gridState.findIndex(o => o.gridId === gridId);
    if (index >= 0) {
      state.gridState[index]["reload"] = true;
    }
  },
  reloaded: (state, gridId) => {
    let index = state.gridState.findIndex(o => o.gridId === gridId);
    if (index >= 0) {
      state.gridState[index]["reload"] = false;
    }
  },
  saveGridState: (state, opt) => {
    let index = state.gridState.findIndex(o => o.gridId === opt.gridId);
    if (index >= 0) {
      for (let x in opt) {
        if (x !== 'gridId' && typeof (state.gridState[index][x]) !== 'undefined') {
          state.gridState[index][x] = opt[x];
        }
      }
      
      if (opt.persistent) {
        let service = new ApiService();
        let data = {};
        data.opt = 'saveState';
        data.grid_id = opt.gridId;
        data.grid_state = state.gridState[index];
        service.trigger('grid_service', data);
      }
    }
  },
  saveGridFilters: (state, params) => {
    let index = state.gridFilters.findIndex(o => o.gridId === params.gridId);
    if (index >= 0) {
      state.gridFilters[index]['customFilters'] = params.customFilters;
      state.gridFilters[index]['fetched'] = true;
    }
  },
  saveGridNavRecords: (state, params) => {
    let index = state.navRecords.findIndex(o => o.gridId === params.gridId);
    if (index >= 0) {
      state.navRecords[index]['uids'] = params.recordUids;
    }
  },
  triggerFetchCount: (state, gridId) => {
    let index = state.gridState.findIndex(o => o.gridId === gridId);
    if (index >= 0) {
      state.gridState[index]["fetchCount"] = true;
    }
  },
  fetchedCount: (state, gridId) => {
    let index = state.gridState.findIndex(o => o.gridId === gridId);
    if (index >= 0) {
      state.gridState[index]["fetchCount"] = false;
    }
  }
};

const actions = {};


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};



