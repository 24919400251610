<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component mt-4">{{ $t('clients.import.title') }}</p>

    <!-- Import Error Dialog -->
    <ModalDialog v-show="showImportError" :headerText="$t('general.import_error')" @close="closeImportErrorDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-2">
          <DataGrid
              grid-id="client_import_errors"
              :local-data="importErrors"
              grid-type="importError"
              default-sort-attr="row_no"
              :default-sort-dir="1"
              :show-select-col="false"
              :use-local-data="true"
              :state-ful="false"
              :reorderable-columns="false"
              :resizable-columns="false"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-2 mb-4 mr-6 justify-end   space-x-4">
          <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeImportErrorDlg"/>
        </div>
      </template>
    </ModalDialog>

    <div class="w-full flex flex-col pr-7 mt-8"/>
    <div class="tb_data" v-show="!showResult">
      <table>
        <thead>
        <tr class="pd_big">
          <th class="fix_width">{{ $t('clients.import.records') }}</th>
          <th class="fix_width">
            <div class="arw_box"><span class="cursor-pointer" @click="prevRow"><img
                src="@/assets/arrow-right.svg"></span>
              <p class="head">{{ getPageText }}</p><span class="cursor-pointer" @click="nextRow"><img
                  src="@/assets/arrow-left.svg"></span></div>
          </th>
          <th></th>
        </tr>
        <tr class="">
          <th>{{ $t('clients.import.col_header') }}</th>
          <th>{{ $t('clients.import.value') }}</th>
          <th class=" text-left">{{ $t('clients.import.choose_attr') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in getRow" :key="item.row_index">
          <td>{{ item.header }}</td>
          <td>{{ item.value }}</td>
          <td>
            <div class="select_bx">
              <InputSelect v-model="mapped_attrs[item.col_index]" :selectedValue="mapped_attrs[item.col_index]"
                           :options="attrs" placeholder=""></InputSelect>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="btn_row">
        <div class="flex mt-10 mb-10  justify-around space-x-4">
          <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="reImport"/>
          <Button class="w-56" :text="$t('clients.import.btn')" @click="importClients"/>
        </div>
      </div>
    </div>
    <div class=" flex flex-col justify-center items-center " v-show="showResult">
      <div class="finish-check-icon-wrapper">
        <img :src="getAssetPath('check_done.svg')"/>
      </div>
      <div class="flex flex-col justify-center items-center mt-6">
        <label class="text">{{ $t('clients.csv_import.finish.success') }}</label>
        <p class="text w-1/2 mt-2">{{
            $t('clients.csv_import.finish.info', {"count": totalLines, "mins": estimatedTime})
          }}</p>
      </div>
      <Button class=" w-96 mt-10" :text="$t('buttons.next')" @click="goToNotification"/>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import {ClientService} from "@/services/client.service";
import DataGrid from "../DataGrid";
import _ceil from "lodash/ceil";
import {S3Service} from "@/services/s3.service";

export default {
  name: "csvimportieren",
  components: {DataGrid},
  data() {
    return {
      service: new ClientService(),
      s3Service: new S3Service(),
      excelData: [],
      totalLines: 0,
      estimatedTime: 0,
      currentLine: 1,
      currentRow: {},
      attrs: [
        {
          "name": this.$t('clients.attrs.id'),
          "code": "client_id"
        }, {
          "name": this.$t('clients.attrs.type'),
          "code": "client_type"
        }, {
          "name": this.$t('clients.add.branch'),
          "code": "branch_name"
        }, {
          "name": this.$t('clients.attrs.company_name'),
          "code": "company_name"
        }, {
          "name": this.$t('general.attrs.first_name'),
          "code": "first_name"
        }, {
          "name": this.$t('general.attrs.last_name'),
          "code": "last_name"
        }, {
          "name": this.$t('clients.attrs.title'),
          "code": "title"
        }, {
          "name": this.$t('general.attrs.salutation'),
          "code": "salutation"
        }, {
          "name": this.$t('general.attrs.status'),
          "code": "status"
        }, {
          "name": this.$t('clients.attrs.legal_form'),
          "code": "legal_form"
        }, {
          "name": this.$t('clients.attrs.tax_number'),
          "code": "tax_number"
        }, {
          "name": this.$t('clients.attrs.client_number'),
          "code": "client_number"
        }, {
          "name": this.$t('clients.attrs.vat_country_code'),
          "code": "vat_country_code"
        }, {
          "name": this.$t('clients.attrs.vat_number_wot_country'),
          "code": "vat_number_wot_country"
        }, {
          "name": this.$t('clients.attrs.dob'),
          "code": "dob"
        }, {
          "name": this.$t('clients.attrs.street'),
          "code": "street"
        }, {
          "name": this.$t('clients.attrs.house_number'),
          "code": "house_number"
        }, {
          "name": this.$t('clients.attrs.po_box'),
          "code": "po_box"
        }, {
          "name": this.$t('clients.attrs.postcode'),
          "code": "postcode"
        }, {
          "name": this.$t('clients.attrs.zip_code'),
          "code": "postcode"
        }, {
          "name": this.$t('clients.attrs.city'),
          "code": "city"
        }, {
          "name": this.$t('clients.attrs.email'),
          "code": "email"
        }, {
          "name": this.$t('clients.attrs.mobile'),
          "code": "mobile"
        }, {
          "name": this.$t('general.attrs.mobile'),
          "code": "mobile"
        }, {
          "name": this.$t('clients.attrs.phone'),
          "code": "phone"
        }, {
          "name": this.$t('general.attrs.phone'),
          "code": "phone"
        }, {
          "name": this.$t('clients.attrs.fax'),
          "code": "fax"
        }, {
          "name": this.$t('clients.attrs.country'),
          "code": "country"
        }
      ],
      colHeaders: [],
      mapped_attrs: [],
      importErrors: [],
      showImportError: false,
      breadcrumbs: [
        {
          title: this.$t('clients.title'),
          link: '/client/list'
        }, {
          title: this.$t('clients.import.title'),
          link: ''
        }
      ],
      showResult: false,
      notificationId: 0
    }
  },
  computed: {
    getPageText() {
      return this.$t('clients.import.pagination', {
        "current": this.currentLine,
        "total": this.totalLines
      })
    },
    getRow() {
      if (this.excelData.length > 0) {
        let data = this.excelData[this.currentLine];
        let colCount = this.colHeaders.length;
        let rows = [];
        for (let k = 0; k < colCount; k++) {
          rows.push({
            "header": this.colHeaders[k],
            "value": data[k] ? data[k] : '',
            "row_index": this.currentLine + '-' + k,
            "col_index": k
          });
        }
        return rows;
      } else {
        return [];
      }
    }
  },
  mounted() {
    let clientData = this.getClientData();
    if (typeof (clientData['branch']) === 'undefined' || clientData['branch'] === 0) {
      this.cancelImport();
    }

    this.excelData = this.getImportData();
    if (this.excelData.length === 0) {
      this.cancelImport();
    } else {
      this.colHeaders = this.excelData[0];
      this.totalLines = this.excelData.length - 1; // First row will be header
      this.estimatedTime = _ceil(this.totalLines / 100) + 1;

      // Detect mapping
      let colLength = this.colHeaders.length;
      for (let k = 0; k < colLength; k++) {
        let attr = this.attrs.filter(item => {
          if (item.name.toLowerCase() === this.colHeaders[k].toLowerCase()) {
            return item;
          } else if(this.$isBusiness) {
            if(this.colHeaders[k].toLowerCase() === 'zentrale mand.-nr.' && item.code === 'client_id') {
              return item;
            } else if(this.colHeaders[k].toLowerCase() === 'mandantentyp' && item.code === 'client_type') {
              return item;
            }
          }
        })
        if (attr.length > 0) {
          this.mapped_attrs[k] = attr[0]['code'];
        }
      }
    }
  },
  methods: {
    ...mapGetters("client", ["getImportData", "getClientData"]),
    ...mapMutations("client", ["init", "setImportData"]),
    ...mapMutations("grid", ["triggerReload"]),
    cancelImport() {
      this.$router.push('/client/list');
    },
    reImport() {
      this.$router.go(-1);
    },
    importClients() {
      this.$loading.show();
      let clientData = this.getClientData();
      let s3Data = {};
      s3Data.clientData = this.excelData;
      s3Data.mappingAttrs = this.mapped_attrs;
      this.s3Service.upload({
        data: s3Data,
        fileName: 'client_import_job.json'
      }).then((res) => {
        if (res.success) {
          this.service.import(clientData.branch, clientData.branchIncludedInCsv, res.s3Path).then((response) => {
            this.$loading.hide();
            if (response.data.success) {
              this.notificationId = response.data.notificationId ? parseInt(response.data.notificationId) : 0;
              this.showResult = true;
            } else {
              this.$api.showToast(response.data.message, "error")
            }
          });
        } else {
          this.$loading.hide();
          this.$api.showToast(this.$t('general.errors.unknownError'), "error")
        }
      });
    },
    goToNotification() {
      if(this.notificationId > 0) {
        this.$router.push('/client/ImportStatus?uid=' + this.notificationId);
      } else {
        this.$router.push('/client/list')
      }
    },
    nextRow() {
      if (this.totalLines > this.currentLine) {
        this.currentLine++;
      }
    },
    prevRow() {
      if (this.currentLine > 1) {
        this.currentLine--;
      }
    },
    closeImportErrorDlg() {
      this.showImportError = false;
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.tb_data {
  font-size: 15px;

}

.tb_data td, .tb_data th {
  padding: 12px;
}

.tb_data > table {
  width: 100%;

}

.tb_data table tr {
  border-top: 1px solid rgba(0, 0, 0, .2);
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.tb_data tr th:nth-child(3) {
  width: 65%;
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, .2);
}

.tb_data tr td:nth-child(3) {
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, .2);
}

tr.tb_head {
  background: #f2f2f2;
}

.select_bx {
  width: 250px;
}

.btn_row {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.btn_row > button {
  max-width: 260px;
}

.select_bx span {
  font-size: 14px;
}

.tb_data {
  padding-right: 20px;
  display: block;
}

tr.pd_big th {
  padding: 24px 12px !important;
}

.arw_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;

}

.arw_box > span {

  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 25px;
  height: 23px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #797979;
  background-color: #ffffff;
  // box-sizing: border-box;
}

.arw_box > span img {
  width: 7px;
}

.arw_box > span:nth-child(1) {
  transform: rotate(
          180deg);
}

th {

  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  line-height: normal;
  font-size: 15px;
  text-align: left;

}

td {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
  text-align: left;
}

.fix_width {
  width: 300px;
  max-width: 200px;
  min-width: 200px;
}
</style>
