<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="w-full"></div>
      <div class="modal flex items-end ">
        <div class="bg-green"></div>
        <div class="h-screen  w-full">
          <header class="modal-header">
            <img src="@/assets/btn_close_gray.svg"
                 class="btn-close cursor-pointer"
                 @click="close"
                 v-if="showClose"
            />

          </header>

          <slot name="body">
          </slot>

          <slot name="footer">
          </slot>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>


<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 1;
}

.modal {
  width: 661px;
  background: #FFFFFF;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  display: flex;
  align-self: end;
  flex-direction: row;
  // border-bottom: 1px solid #eeeeee;
  color: #333333;
  justify-content: flex-end;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  //position: absolute;
  //top: 0;
  //right: 0;
  //border: none;
  //font-size: 40px;
  //padding: 10px;
  //cursor: pointer;
  //font-weight: bold;
  //color: #333333;
  //background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

</style>
