<template>
  <div class="">
    <ModalDialog v-show="isModalVisible" :showClose="false" @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4 mr-6">
          <label class="dheader mt-6 text-left ">Achtung</label>
          <img class="warning self-center" src="@/assets/warning.svg"/>
          <label class=" dtext text-left mt-10">Achtung, dieses Grundstück existiert bereits. Möglicherweise teilen sich
            mehrere Mandanten das gleiche Grundstück.
          </label>
          <label class=" dtext text-left mt-10">Sie können fortfahren, indem Sie erneut auf Weiter klicken, sofern Sie
            das Grundstück für einen weiteren Mandanten anlegen.</label>
          <div class="flex justify-around space-x-10 mt-6 mr-6 ">

            <div class="flex mt-10 mb-10 items-center justify-around w-4/5">

              <Button class="w-full " @click="cancel" :text="$t('buttons.cancel')"></Button>
              <Button @click="submitDialogNext" class="  w-full bg-green   text-white" text="Wieder fortfahren">
              </Button>

            </div>

          </div>
        </div>
      </template>

    </ModalDialog>

    <div class="flex justify-around space-x-10 mt-2">
      <InputSelect
          class="w-full"
          :isError="errors.federalStateUid.invalid"
          :errorMessage="$t('general.errors.required')"
          :label="$t('properties.step2.bundesland')"
          v-model="step2.federalStateUid"
          :filterable="true"
          :options="stateList"
          :isRequired="true"
          :placeholder="$t('general.please_select')"
          @on-item-selected="selectState"
      />
      <div class="flex link flex-col w-full mt-6">
        <a class=" text-left link" @click="copyAddressFromClient"> {{ $t('properties.step2.take_over_from_client') }}</a>
      </div>
    </div>
    <div class="flex justify-around space-x-10 mt-5" v-show="showCommunity">
      <InputSelect
          class="w-full"
          :isError="errors.community.invalid"
          :errorMessage="$t('general.errors.required')"
          :label="$t('properties.step2.community')"
          :selected-value="step2.community"
          :options="communityList"
          :loading="loadingCommunity"
          :filterable="true"
          :auto-complete="true"
          :placeholder="$t('general.please_select')"
          @on-item-selected="selectCommunity"
          :showHelpIconPopover="true"
          :popoverTexts="popoverTexts_community"
          :popoverLink="popoverLink_community"
      />
      <div class="w-full  invisible"/>
    </div>
    <div v-show="showForm">
      <div class="flex justify-around space-x-10 mt-5">
        <InputText
            class=" w-full"
            v-model="step2.street"
            :label="$t('properties.step2.street')"
            @submit="submitNext"
            :maxLength="25"
            placeholder=""
        />
        <InputText
            class=" w-full"
            v-model="step2.houseNumber"
            :label="$t('properties.step2.house')"
            @submit="submitNext"
            :maxLength="39"
            placeholder=""
        />
      </div>
      <div class="flex justify-around space-x-10 mt-5">
        <InputText
            class=" w-full"
            v-model="step2.zip"
            :isZip="true"
            :label="$t('properties.step2.zip')"
            @blur="searchZip"
            @submit="submitNext"
            placeholder=""
        />
        <InputSelect
            class="w-full"
            :label="$t('properties.step2.city')"
            :options="cityList"
            v-model="step2.city"
            :selected-value="step2.city"
            :loading="zipSearch"
            :filterable="true"
            :auto-complete="true"
            :placeholder="$t('general.please_select')"
            :maxLength="25"
            @on-item-selected="selectCity"
        />
      </div>
      <div class="flex justify-around space-x-10 mt-1">
        <InputText
            class="mt-2 w-full"
            v-model="step2.additional_information"
            :label="$t('properties.step2.additional_info')"
            :maxLimit="24"
            placeholder=""
        />
        <div class="w-full invisible"/>
      </div>
    </div>

    <div class="p-3 mt-8 mb-4 info-msg flex" v-if="helpInfo !== ''">
      <img src="@/assets/note.svg" width="15px">
      <label class="pl-2 text-left">
        {{ helpInfo }}
      </label>
    </div>

    <div class="flex justify-around space-x-4 mt-6 mb-10 ">
      <div class="w-full invisible"/>
      <div class="flex justify-end space-x-4 mt-6 mr-6">
        <Button v-if="stepIndex > 0" class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
        <Button v-else class="w-52" :text="$t('buttons.cancel')" :secondary="true" @click="cancelStep"/>
        <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {PropertyService} from "../../../services/property.service";
import {ClientService} from "@/services/client.service";

export default {
  name: "StepAddress",
  props: {
    stepIndex: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      popoverTexts_community: [
        this.$t('properties.step2.tooltip_community_text1'),
        this.$t('properties.step2.tooltip_community_text2')
      ],
      popoverLink_community: "https://www.statistikportal.de/de/gemeindeverzeichnis",
      service: new PropertyService(),
      clientService: new ClientService(),
      stateList: [],
      showCommunity: false,
      loadingCommunity: false,
      showForm: true,
      communityList: [],
      cityList: [],
      zipSearch: false,
      errors: {
        "federalStateUid": {
          invalid: false
        },
        "community": {
          invalid: false
        },
        "city": {
          invalid: false
        }
      },
      step2: {
        federalStateName: '',
        federalStateUid: '',
        community: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        additional_information: ''
      },
      isModalVisible: false,

      helpInfo: '',
    }
  },
  mounted() {
    this.initialize();
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });
  },
  computed: {
    ...mapGetters("property", ["getStepAddress", "getStepClient"]),
  },
  methods: {
    ...mapMutations("property", ["saveStepAddress"]),
    initialize() {
      this.step2.federalStateUid = this.getStepAddress.federalStateUid;
      this.step2.community = this.getStepAddress.community;
      this.step2.street = this.getStepAddress.street;
      this.step2.houseNumber = this.getStepAddress.houseNumber;
      this.step2.zip = this.getStepAddress.zip;
      this.step2.city = this.getStepAddress.city;
    },
    setHelpInfo() {
      /*if (this.step2.federalStateUid === '2') {
        this.helpInfo = this.$t('properties.help_info.bayern');
      } else if (this.step2.federalStateUid === '6') {
        this.helpInfo = this.$t('properties.help_info.hamburg');
      } else if (this.step2.federalStateUid === '9') {
        this.helpInfo = this.$t('properties.help_info.niedersachsen');
      } else {
        this.helpInfo = '';
      }*/
    },
    selectState(item) {
      this.communityList = [];
      this.step2.federalStateName = item.name;
      if (item.code === "0" || item.code === "") {
        this.showCommunity = false;
      } else {
        this.loadingCommunity = true;
        this.$api.get_community_list(item.name).then((list) => {
          this.communityList = list.map((o) => {
            return {
              name: o.name + ' - AGS: ' + o.community_key,
              code: o.code,
            }
          });
          this.loadingCommunity = false;
          this.showCommunity = true;
        })
        this.errors.federalStateUid.invalid = false;
      }

      this.setHelpInfo();
    },
    selectCommunity(item) {
      if (item.code === "0" || item.code === "") {
        // this.errors.community.invalid = true
        this.showForm = false;
      } else {
        this.step2.community = item.code;
        this.errors.community.invalid = false;
        this.showForm = true;
      }
    },
    selectCity(item) {
      if (item.code === "") {
        this.errors.city.invalid = true;
      } else {
        this.errors.city.invalid = false;
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.step2.city === '' && this.step2.zip !== '') {
        this.cityList = [];
        this.cityLookup(this.step2.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.step2.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },

    copyAddressFromClient() {
      if (this.getStepClient.clientInfo) {
        this.step2.street = this.getStepClient.clientInfo.street;
        this.step2.houseNumber = this.getStepClient.clientInfo.house_number;
        this.step2.zip = this.getStepClient.clientInfo.zip_code;
        this.step2.city = this.getStepClient.clientInfo.city;
      }
    },

    submitDialogNext() {
      this.saveStepAddress(this.step2);
      this.$emit("goToNextStep");
    },
    cancel() {
      this.isModalVisible = false
    },
    async submitNext() {
      if (this.step2.federalStateUid === "0" || this.step2.federalStateUid === "") {
        this.errors.federalStateUid.invalid = true
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.federalStateUid.invalid = false
      }

      this.saveStepAddress(this.step2);
      this.$emit("goToNextStep", this.step2);
      /*
      if (await this.service.save(this.getSteps)) {
        this.$emit('finish');
      }
      */
    },
    handleModalClose() {
      this.isModalVisible = false
      this.setSecondStepData(this.step2)
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    },
    cancelStep() {
      this.$emit("cancelStep");
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
}

.dtext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 20px;
}

.dheader {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.warning {
  width: 58px;
  height: 52px;
  box-sizing: border-box;
}

.info-msg {
  background-color: #f2f2f2;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;

    &.underline {
      text-decoration: underline;

    }
  }
}
</style>
