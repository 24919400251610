<template>
  <div class="space-y-4">
    <div class="flex justify-around space-x-8">
      <InputSelect class="mt-2 w-full" :isError="errors.assignedUser.invalid"
                   :errorMessage="$t('general.errors.required')"
                   :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
                   :options="userList"
                   v-model="step1.assignedUser"
                   :selectedValue="step1.assignedUser"
                   :filterable="true"
                   @on-item-selected="fetchClient"
                   :isRequired="true" :placeholder="$t('general.please_select')"/>
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex justify-around space-x-8 mt-1">
      <InputSelect class="mt-2 w-full" :isError="errors.clientId.invalid" :errorMessage="$t('general.errors.required')"
                   :label="$t('declarations.add_declaration.steps.step1.client')"
                   v-model="step1.clientId"
                   :options="clientList"
                   :selectedValue="step1.clientId"
                   :loading="loadingClient"
                   :search-fn="clientListSearch"
                   :total-record-count="totalClientsCount"
                   :filterable="true"
                   :sort="true"
                   :placeholder="$t('general.client_select')"
                   @on-item-selected="fetchProperties"
                   :isRequired="true"/>
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex justify-around space-x-8 mt-1" v-show="showPropertyList">
      <InputSelect class="mt-2 w-full"
                   :label="$t('properties.steps.step3')"
                   :isError="errors.propertyId.invalid"
                   :errorMessage="$t('general.errors.required')"
                   v-model="step1.propertyId"
                   :selectedValue="step1.propertyId"
                   :loading="loadingProperties"
                   :options="propertyList"
                   :search-fn="propertyListSearch"
                   :total-record-count="totalPropertiesCount"
                   :sort="true"
                   :filterable="true"
                   :isRequired="true" :placeholder="$t('general.property_select')"
                   @on-item-selected="selectProperty"
      />
      <div class="w-full pr-6 invisible"/>
    </div>

    <label class="not-found-label flex justify-start mt-2"> {{ $t('properties.step1.not_found') }} <span
        class="link  ml-2" @click="addClient">{{ getText }}</span></label>
    <div class="flex justify-end  mt-6 space-x-4 ">
      <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="goBack()"/>
      <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
    <div/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputSelect from "../../inputs/InputSelect";
import {UserService} from "@/services/user.service";
import {ClientService} from "@/services/client.service";
import {PropertyService} from "@/services/property.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step1",
  components: {InputSelect},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clientService: new ClientService(),
      propertyService: new PropertyService(),
      branchService: new BranchService(),
      userList: [],
      clientList: [],
      totalClientsCount: 0,
      propertyList: [],
      totalPropertiesCount: 0,
      showPropertyList: false,
      loadingClient: false,
      loadingProperties: false,
      federalStateUid: "",
      selectedProperty: [],
      step1: {
        assignedUser: '0',
        clientId: this.getCurrentClientId(),
        propertyId: this.getCurrentPropertyId(),
        declarationNumber: "",
        extraDetails: "",
        declarationRelevantMarkedByUser: 0,
        nextDeclarationType: "",
        nextDeclarationReferenceDate: "",
        participation: this.shouldParticipationBeChecked()
      },
      errors: {
        "assignedUser": {
          invalid: false
        },
        "clientId": {
          invalid: false
        },
        "propertyId": {
          invalid: false
        }
      },
    }
  },
  computed: {
    getText() {
      return this.showPropertyList ? this.$t('declarations.add_declaration.steps.step1.create_new_property') : this.$t('declarations.add_declaration.steps.step1.create_new')
    }
  },
  async mounted() {
    let userService = new UserService();
    let previousPage = localStorage.getItem("previousPage");
    let flag         = localStorage.getItem("flag");
    userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            "name": item.name,
            "code": item.id
          });
        }

        return filtered;
      }, []);
      if ((previousPage !== null) && (flag !== null)) {
        this.step1.assignedUser = localStorage.getItem("assignedUser");
      } else {
        this.step1.assignedUser = this.getCurrentUser().id;
      }
      if (this.step1.assignedUser && this.step1.clientId > 0 && this.step1.propertyId > 0) {
        this.setCurrentStep(1);

        if (this.$route.params.declarationNumber) {
          this.step1.declarationNumber = this.$route.params.declarationNumber;
        }

        if (this.$route.params.declarationRelevantMarkedByUser) {
          this.step1.declarationRelevantMarkedByUser = this.$route.params.declarationRelevantMarkedByUser;
        }

        if (this.$route.params.nextDeclarationType) {
          this.step1.nextDeclarationType = this.$route.params.nextDeclarationType;
        }

        if (this.$route.params.nextDeclarationReferenceDate) {
          this.step1.nextDeclarationReferenceDate = this.$route.params.nextDeclarationReferenceDate;
        }

        this.goToNext();
      }
      this.fetchClient();
    })

    this.initializeSteps(this.step1, this.getSteps)
    if((previousPage !== null) && (flag !== null)) {
      localStorage.removeItem("flag");
      this.step1.clientId     = localStorage.getItem("clientId");
      this.step1.propertyId   = localStorage.getItem("AddedProperty");
      this.step1.assignedUser = localStorage.getItem("assignedUser");
      localStorage.removeItem("previousPage");
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("declaration", ["saveSteps", "setCurrentStep", "setPropertyId", "setPropertyDeclarationRelevantMarkedByUser", "setPropertyNextDeclarationType", "setPropertyNextDeclarationReferenceDate", "setFederalStateId"]),
    ...mapGetters("declaration", ["getSteps", "getCurrentClientId", "getCurrentPropertyId"]),
    addClient() {
      if(this.showPropertyList) {
        localStorage.setItem("assignedUser", this.step1.assignedUser);
        localStorage.setItem("previousPage", window.location.href);
      }
      this.showPropertyList ? this.$router.push('/property/AddNewProperty') : this.$router.push('/client/add')
    },
    fetchClient() {
      this.loadingClient = true;
      this.clientService.listUserClient([this.step1.assignedUser, 'current'].join(",")).then((clients) => {
        if(clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.clientList = clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid,
              "branch_id": list.branch_id,
              "power_of_attorney": list.power_of_attorney
            }
          });

          if (!this.step1.clientId) {
            this.step1.clientId = this.getCurrentClientId();
          }
          this.loadingClient = false;
        }
      })
    },
    clientListSearch(params) {
      return this.clientService.listUserClient([this.step1.assignedUser, 'current'].join(","), params).then((clients) => {
        if(clients && clients.list) {
          return clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid,
              "branch_id": list.branch_id,
              "power_of_attorney": list.power_of_attorney
            };
          })
        } else {
          return false;
        }
      });
    },
    fetchProperties() {
      this.showPropertyList = true;
      this.loadingProperties = true;
      this.propertyService.listClientProperties(this.step1.clientId).then((property) => {
        if(property && property.list) {
          this.totalPropertiesCount = property.totalRecordCount;
          this.propertyList = property.list.map((list) => {
            return {
              "name": list.name,
              "declarationNumber": list.reference,
              "code": list.prim_uid,
              "declarationRelevantMarkedByUser": list.declarationRelevantMarkedByUser,
              "nextDeclarationType": list.nextDeclarationType,
              "nextDeclarationReferenceDate": list.nextDeclarationReferenceDate
            }
          });

          if (!this.step1.propertyId) {
            this.step1.propertyId = this.getCurrentPropertyId();
          }

          if((parseInt(this.step1.propertyId) === 0 || this.step1.propertyId === '') && this.propertyList.length === 1) {
            this.step1.propertyId = this.propertyList[0].code;
          }

          if (parseInt(this.step1.propertyId) > 0) {
            this.selectProperty();
          }

          this.loadingProperties = false;
        }
      });
    },
    propertyListSearch(params) {
      return this.propertyService.listClientProperties(this.step1.clientId, params).then((property) => {
        if(property && property.list) {
          return property.list.map((list) => {
            return {
              "name": list.name,
              "declarationNumber": list.reference,
              "code": list.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    selectProperty() {
      this.propertyList.map((property, index) => {
        if (property.code == this.step1.propertyId)
        {
          this.step1.declarationRelevantMarkedByUser = property.declarationRelevantMarkedByUser;
          this.step1.nextDeclarationType = property.nextDeclarationType;
          this.step1.nextDeclarationReferenceDate = property.nextDeclarationReferenceDate;
        }
      });
    },
    submitNext() {
      this.errors = this.resetErrors(this.errors);

      if(this.step1.assignedUser === "0" || this.step1.assignedUser === "") {
        this.errors.assignedUser.invalid = true
        return;
      }

      if(this.step1.clientId === "0" || this.step1.clientId === "") {
        this.errors.clientId.invalid = true
        return;
      }

      if(this.step1.propertyId === "0" || this.step1.propertyId === "") {
        this.errors.propertyId.invalid = true
        return;
      }

      this.goToNext();
    },
    async goToNext(skipOpenDeclarationCheck) {
      let openDeclarationCount = 0;
      let propertyId = parseInt(this.step1.propertyId);
      if (propertyId > 0) {
        this.setPropertyId(propertyId);

        this.setPropertyDeclarationRelevantMarkedByUser(parseInt(this.step1.declarationRelevantMarkedByUser));
        this.setPropertyNextDeclarationType(this.step1.nextDeclarationType);
        this.setPropertyNextDeclarationReferenceDate(this.step1.nextDeclarationReferenceDate);

        let index = this.selectedProperty.findIndex(o => parseInt(o.id) === propertyId);
        if(index >= 0) {
          this.step1.federalStateUid = this.selectedProperty[index].step2.federalStateUid;
          this.setFederalStateId(this.selectedProperty[index].step2.federalStateUid);
          openDeclarationCount = parseInt(this.selectedProperty[index].openDeclarationCount);

          if(this.selectedProperty[index].step2.reference) {
            this.step1.declarationNumber = this.selectedProperty[index].step2.reference;
          }

          if(this.selectedProperty[index].step2.extraDetails) {
            this.step1.extraDetails = this.selectedProperty[index].step2.extraDetails;
          }

        } else {
          await this.propertyService.get(propertyId, {"getOpenDeclarationCount": 1}).then((data) => {
            if (data.id) {
              this.selectedProperty.push(data);
              this.step1.federalStateUid = data.step2.federalStateUid;
              this.setFederalStateId(data.step2.federalStateUid);
              openDeclarationCount = parseInt(data.openDeclarationCount);

              if(data.step2.reference) {
                this.step1.declarationNumber = data.step2.reference;
              }

              if(data.step2.extraDetails) {
                this.step1.extraDetails = data.step2.extraDetails;
              }
            }
          });
        }
      }

      if(openDeclarationCount > 0 && !skipOpenDeclarationCheck) {
        this.$modal.show({
          headerText: this.$t('declarations.add_declaration.open_declaration_confirmation.title'),
          text: this.$t('declarations.add_declaration.open_declaration_confirmation.text'),
          showClose: true,
          canEscape: true,
          buttons: {
            "ok": this.$t('general.yes'),
            "cancel": this.$t('general.no'),
            "delete": false
          },
          onConfirm: () => {
            this.goToNext(true)
          }
        });

        return ;
      }

      let selectedClient = this.clientList.filter((o) => {
        return o.code === this.step1.clientId;
      });
      this.step1.powerOfAttorneyFromClient = 0;
      if(selectedClient.length > 0) {
        this.step1.powerOfAttorneyFromClient = parseInt(selectedClient[0].power_of_attorney);
      } else {
        let clientData = await this.clientService.get(this.step1.clientId);
        if(clientData.power_of_attorney) {
          this.step1.powerOfAttorneyFromClient = clientData.power_of_attorney;
        }
      }

      this.saveSteps(this.step1);
      this.$emit("goToNextStep", this.step1);
    },
    shouldParticipationBeChecked() {
      if (!this.$isBusiness) {
        return true;
      }

      if (this.getCurrentUser().partner === 'wts') {
        return true;
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">


.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.link {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}
</style>
