<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center">
      <div class="modal ">
        <div class=" w-full" style="display:grid">
          <header class="modal-header" >
            <img src="@/assets/btn_close_gray.svg"
                 class="btn-close cursor-pointer"
                 @click="close"
                 v-if="showClose"
            />
          </header>
          <div class="modal-dialog-body px-4">
            <slot name="body">
            </slot>
          </div>
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {

  name: 'ModalDialog',
  props: {
    showClose: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>


<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 1;
}

.modal {
  //width: 100%;
  max-width:80%;
  background: white;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  display: flex;
  flex-direction: row;
  position:relative;
  justify-content: center;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  display: flex;
  align-self: end;
  flex-direction: row;
 //border-bottom: 1px solid #eeeeee;
  color: #333333;
  justify-content: flex-end;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  //position: absolute;
  //top: 0;
  //right: 0;
  //border: none;
  //font-size: 40px;
  //padding: 10px;
  //cursor: pointer;
  //font-weight: bold;
  //color: #333333;
  //background: transparent;
}
.form_grp .input-box {
    height: 30px !important;
    border: 2px solid #7a7a7a;
    display:none !important;
}
.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}
.after_end_term_class .modal {
    max-width: 650px;
    min-height: 250px;
    width: 650px;
}
.bill_address_class .modal {
    max-width: 1100px;
    width: 1100px;
}
.plan_subscription_class .modal {
    max-width: 1225px;
    width: 1225px;
}
.payment_type_class .modal {
    min-width: 1100px;
    min-height: 290px;
}
.modal-dialog-body {
  overflow-y: scroll;
}
</style>
