<template>
  <div>
    <ModalDialog
      v-if="viewFileEnabled"
      v-show="viewFileUrl != ''"
      :headerText="viewFileName"
      @close="viewFileClose()"
      modalSize="large"
      :canCloseOutsideClick=false
    >
      <template v-slot:body>
        <div class="flex flex-col">
          <vue-pdf-embed :source="sourceObjectForPdfjs(viewFileUrl)"/>
        </div>
      </template>
    </ModalDialog>

    <div class="flex ">
      <!--      Label with required indicator-->
      <label class="label mb-1">{{ label }}</label><span v-if='isRequired' style="color:red"> *</span>
      <!--      help icon-->
      <img v-if="showHelpIcon" class="help_icon" :src="getAssetPath('help_green.svg')" v-tooltip.right="tooltip"/>
      <!--      Error Message label-->
      <label class="error-label " :class="isError===true?'ml-1 visible':'invisible'" style="color:red">
        {{ errorMessage }}</label>
    </div>
    <div>
      <div class="upload flex justify-between items-center pl-2 pr-2 cursor-pointer" @click="choose">
        <input ref="fileInput" type="file" class="hidden" :multiple="multiple" :accept="accept" @change="fileSelect"/>
        <label class="lbl ml-3">{{ text }}</label>
        <img class="image-upload" :src="getAssetPath('clip_green.svg')"/>
      </div>
      <!-- Drag and Drop-->
      <div class="mt-3" v-show="showDragAndDrop">
        <label class="label  mb-3"> {{ $t('general.or') }} </label>
        <div class="drop flex-col flex  overflow-y-scroll" v-cloak @drop.prevent="addFile" @dragover.prevent>
          <div class="flex flex-col mt-8 justify-center items-center" v-show="files.length===0">
            <img class="img" src="@/assets/docs_gray.svg"/>
            <label class="label">{{ $t('documents.drop_files') }}</label>
          </div>
          <table class="filelist bg-white divide-y divide-gray">
            <tr v-for="(file,index) in files" :key="index" style="display: flex;align-items: center;justify-content: space-between">
              <div class="flex-grow">
                <td v-if="!allowFilenameEditing" class="filename">{{ file.name }}</td>
                <div v-else class="flex" style="align-items: center">
                  <InputText class="flex-grow mb-1 mt-2"
                             v-model="file.description"
                             @input="handleUploadFieldInputChange(index)"
                             :maxLimit="maxFilenameLength"
                             :is-error="isFileDescriptionInvalid(file)"
                             :error-message="$t('general.errors.limitDesc').replace('%length%', file.description.length).replace('%maxLimit%', maxFilenameLength)"
                  />
                  <span class="mb-1 mr-4">.{{ file.extension }}</span>
                </div>
              </div>

              <td class="status" v-if="showUploadStatus">
                <div v-show="file.uploadFailed">
                  <img :src="getAssetPath('warning.svg')" v-tooltip.top="getErrorMessage(file)"/>
                </div>
                <div v-show="file.uploaded"><img :src="getAssetPath('tick_password.svg')"></div>
              </td>

              <td class="flex">
                <div class="mr-2" style="font-size: 13px">({{ file.size | formatBytes(byteFormatUnit, byteFormatDecimals) }})</div>
                <div class="multi_icons">
                  <img style="width: 18px; height: 11px; margin-top: 2px; float: left;"
                       src="@/assets/btn_close_gray.svg"
                       class="img-close mr-2 self-center cursor-pointer"
                       @click="removeFile(file)"
                  />
                  <img style="width:18px; height:11px; margin-top:2px; float: left;"
                       v-if="viewFileEnabled"
                       src="@/assets/eye_green.svg"
                       class="img-close mr-2 self-center cursor-pointer"
                       @click="viewFile(file)"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- Description label -->
    <label
        v-show="description !== ''"
        class="error-label self-start mt-1"
    >
      {{ description }}
    </label>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import {uuid} from "vue-uuid";
import {mapGetters, mapMutations} from "vuex";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import ModalDialog from "@/components/ModalDialog";
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";

export default {
  name: "InputUpload",
  components: {VuePdfEmbed, ModalDialog},
  mixins: [pdfjsCVEMixin],
  directives: {'tooltip': Tooltip},
  props: {
    accept: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowFilenameEditing: {
      type: Boolean,
      default: false,
    },
    normalizeFilenames: {
      type: Boolean,
      default: false,
    },
    maxFilenameLength: {
      type: Number,
      default: 255
    },
    fileLimit: {
      type: Number,
      default: 25
    },
    maxFileSize: {
      type: Number,
      default: 20971520 // 20 MB
    },
    maxOverallSize: {
      type: Number,
      default: 0
    },
    errorMessage: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: 'No tool tip provided'
    },
    showDragAndDrop: {
      type: Boolean,
      default: false
    },
    showUploadStatus: {
      type: Boolean,
      default: true
    },
    viewFileEnabled: {
      type: Boolean,
      default: false
    },
    viewFileService: {
      type: Object,
      default: null
    },
    description: {
      type: String,
      default: "",
    },
    byteFormatUnit: {
      type: String,
      default: "kb"
    },
    byteFormatDecimals: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      viewFileName: '',
      viewFileUrl: '',
      field: '',
      files: []
    };
  },
  computed: {
    ...mapGetters("documents", ["getUploadResult"]),
    text() {
      if (this.files.length === 0) {
        return '';
      }

      if (this.multiple) {
        return this.$t('general.x_files_selected', {
          count: this.files.length
        })
      }

      return this.files[0].name;
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.field = this.value;
      }
    },
    field(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
    getUploadResult: {
      deep: true,
      handler: function(nv) {
        if(this.files.length > 0) {
          this.files = this.files.map((o) => {
            if(!o.uploaded) {
              o.uploaded = false;
              o.uploadFailed = false;
              o.errorMessage = '';
            }
            return o;
          });

          if(nv.length > 0) {
            for(let x of nv) {
              this.files = this.files.map((o) => {
                if(!o.uploaded && o.uuid === x.fileUuid) {
                  if(x.errorMessage) {
                    o.uploadFailed = true;
                    o.errorMessage = x.errorMessage;
                  } else if(x.uploaded) {
                    o.uploaded = true;
                  }
                }
                return o;
              });
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations("documents", ["setUploadResult"]),
    clear() {
      this.reset = false;
      this.files = [];
      this.setUploadResult([]);
    },
    getFileName(file) {
      return file.name.split('.').at(0);
    },
    getFileExtension(file) {
      return file.name.split('.').at(-1);
    },
    async handleUploadFieldInputChange(index) {
      this.files = this.files.map((file, i) => {
        if (i === index) {
          let fileUuid = file.uuid;
          let fileDescription = file.description;
          let fileExtension = file.extension;

          // Need to make a difference between actual files
          // and placeholders for already uploaded files
          if (file instanceof File) {
            file = new File(
              [file],
              file.description + '.' + fileExtension,
              {type: file.type}
            );
          } else {
            file.name = file.description + '.' + fileExtension;
          }

          file.uuid = fileUuid;
          file.extension = fileExtension;
          file.description = fileDescription;
        }

        return file;
      });

      this.$emit('filesChanged', this.files);
    },
    choose() {
      if(!this.showDragAndDrop) {
        this.files = [];
      }
      this.$refs.fileInput.click();
    },
    fileSelect(event) {
      let files = '';
      files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      if (files.length > 0) {
        if(this.processSelectedFiles(files)) {
          this.$emit('select', {originalEvent: event, files: this.files});
          this.$refs.fileInput.value='';
        }
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      let selectedFiles = [];
      ([...droppedFiles]).forEach(f => {
        selectedFiles.push(f);
      });
      if(selectedFiles.length > 0) {
        if(this.processSelectedFiles(selectedFiles)) {
          this.$emit('select', {originalEvent: event, files: this.files});
        }
      }
    },
    addAlreadyUploadedFile(file) {
      file.extension = this.getFileExtension(file);
      file.description = this.getFileName(file);

      this.files.push(file);
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
      this.$emit('select', {originalEvent: event, files: this.files});
    },
    async viewFile(file) {
      if (file instanceof File) {
        this.viewFileUrl = await this.getFileUrl(file);
        this.viewFileName = file.name;
        return;
      }

      this.viewFileService.getFileContentFromS3(
        {
          parentId: file.parentId,
          recordId: file.recordId,
          fileKey: file.uuid
        }
      ).then(async (response) => {
        if (response.data.success) {
          let fileContent = response.data.fileContent;
          let mimeType = 'application/pdf';

          this.viewFileUrl = this.getFileUrlWithContent(fileContent, mimeType);
          this.viewFileName = file.name;
        }
      });
    },
    async getFileUrl(file) {
      return await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    },
    viewFileClose() {
      this.viewFileUrl = '';
      this.viewFileName = '';
    },
    getFileUrlWithContent(fileContent, mimeType) {
      return "data:" + mimeType + ";base64," + fileContent;
    },
    getErrorMessage(file) {
      return file.errorMessage ? file.errorMessage : '';
    },
    processSelectedFiles(files) {
      if (files.length > 0) {
        // Validate file type
        if (this.accept) {
          let allowed_types = this.accept.split(",");
          for (let file of files) {
            let fext = file.name.substr((file.name.lastIndexOf('.') + 1)).toLowerCase();
            if (!allowed_types.includes('.' + fext)) {
              this.$api.showToast(this.$t('general.file_type_error', {
                "allowed_types": this.accept
              }), 'error');
              return false;
            }
          }
        }

        // Validate file size
        if (this.maxFileSize > 0) {
          let fileSizesSum = 0;
          for (let file of files) {
            fileSizesSum = fileSizesSum + file.size;
            if (file.size > this.maxFileSize) {
              this.$api.showToast(this.$t('general.fileTooBig').replace('%fileSizeLimit%', this.$options.filters.formatBytes(this.maxFileSize, this.byteFormatUnit, this.byteFormatDecimals)), 'error');
              return false;
            }
          }
        }

        if (this.maxOverallSize > 0) {
          let fileSizesSum = 0;

          this.files.forEach((file) => {
            fileSizesSum = fileSizesSum + file.size;
          })

          for (let file of files) {
            fileSizesSum = fileSizesSum + file.size;
          }

          if (fileSizesSum > this.maxOverallSize) {
            this.$api.showToast(this.$t('general.filesTooBig').replace('%sizeLimit%', this.$options.filters.formatBytes(this.maxOverallSize, this.byteFormatUnit, this.byteFormatDecimals)), 'error');
            return false;
          }
        }

        if (this.files.length + files.length > this.fileLimit) {
          this.$api.showToast(this.$t('general.upload_file_limit_error').replace('%fileLimit%', this.fileLimit), 'error');
          return false;
        }

        for (let file of files) {
          let fileUuid = uuid.v1();
          let fileExtension = this.getFileExtension(file);

          if (this.normalizeFilenames) {
            let normalizedFilename = this.getFileName(file)
              .replace(/[^A-Za-z 0-9\-]/g, ' ') // Remove non-alphanumerical chars
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()); // Make first letter of every word uppercase

            file = new File(
              [file],
              normalizedFilename + '.' + fileExtension,
              {type: file.type}
            );
          }

          file.uuid = fileUuid;
          file.description = this.getFileName(file);
          file.extension = fileExtension;

          this.files.push(file);
        }

        return true;
      }

      return false;
    },
    isFileDescriptionInvalid(file) {
      if (file.description.trim() === '' || file.description.length > this.maxFilenameLength) {
        return true;
      }

      return false;
    }
  },
  filters: {
    formatBytes: function (bytes, to, decimals) {
      const byteBase = 1024;

      var regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?');

      if (to === "kb") {
        return (bytes / byteBase).toString().replace('.', ',').match(regex)[0] + "kB";
      } else if (to === "mb") {
        return (bytes / byteBase ** 2).toString().replace('.', ',').match(regex)[0] + "MB";
      }  else {
        return bytes;
      }
    }
  }
}

</script>

<style scoped lang="scss">

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
.hidden {
  display: none;
}

.right-text {
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: $primary;
  text-align: right;
  line-height: normal;

  margin-right: 5px;
  font-size: 15px;
}

input {
  @include segoe-regular;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  color: black;
  text-align: left;
}

.label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.error-label {
  @include segoe-regular;
  float: left;
}

.upload {
  height: 32px;
  padding: 2px 2px 2px 2px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
}

.image-upload {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-right: 8px;
}

.lbl {
  font-family: 'Segoe UI', sans-serif;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;

  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.drop {

  height: 139px;
  padding: 2px 2px 2px 2px;
  //border: 2px dashed #c4c4c4;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4C4C4FF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.img {
  width: 43px;
  height: 43px;
}

.img-close {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.overflow-y-scroll {
  padding: 15px;
  overflow-y: auto;
}
.filelist {
  table-layout: fixed;
  width: 100%;
  td {
    text-align: left;
  }
  td.filename {
    white-space: nowrap;
    width: 440px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 5px;
  }
  td.status {
    width: 40px;

    img {
      margin-left: auto;
      margin-right: auto;
      width: 20px;
    }
  }
  td.action {
    width: 20px;
    padding-left: 0px;
    padding-right: 10px;
  }
}

.flex-grow {
  flex-grow: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 1001;
}

</style>
