<template>
  <div class="">
    <div class="w-full h-full mt-4 mr-8 pb-8">
      <div class="info no-bottom flex justify-start items-center">
        <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
        <label class="text-left ml-2">{{ poaCharacterLimitInfo }}</label>
      </div>
      <div class="flex flex-col w-full">
        <label class="text mt-6 mb-8">
          {{ $t('declarations.add_declaration.steps.step3.title') }}
          <span style="color:red">*</span></label>
        <div class="flex space-x-3">
          <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('none')">
            <RadioButton type="radio"
                         v-model="step3.recipientType"
                         name="radio-button"
                         value="none"
                         checked
                         @click="createOptionSelected('none')"/>
            <span class="ml-3 textradio">{{ $t('declarations.add_declaration.steps.step3.none') }}</span>
          </div>
          <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('tax_consultant')" v-show="!$isBusiness">
            <RadioButton type="radio"
                         name="radio-button"
                         v-model="step3.recipientType"
                         value="tax_consultant"
                         @click="createOptionSelected('tax_consultant')"/>
            <span class="ml-3 textradio">{{ $t('declarations.add_declaration.steps.step3.tax_consultant') }}</span>
          </div>
          <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('power_of_attorney_from_client')" v-show="showClientPowerOwAttorney">
            <RadioButton type="radio"
                         name="radio-button"
                         v-model="step3.recipientType"
                         value="power_of_attorney_from_client"
                         @click="createOptionSelected('power_of_attorney_from_client')"/>
            <span class="ml-3 textradio">{{ $t('declarations.add_declaration.steps.step3.power_of_attorney_from_client') }}</span>
          </div>
          <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('others')">
            <RadioButton type="radio"
                         name="radio-button"
                         v-model="step3.recipientType"
                         value="others"
                         @click="createOptionSelected('others')"/>
            <span class="ml-3 textradio">{{ $t('declarations.add_declaration.steps.step3.others') }}</span>
          </div>
        </div>
      </div>
      <div v-show="step3.recipientType === 'tax_consultant' || step3.recipientType === 'others' || step3.recipientType === 'power_of_attorney_from_client'" class="space-y-4 mt-8">
        <div class="flex justify-around space-x-8" v-show="step3.recipientType === 'tax_consultant'">
          <InputSelect class="w-full"
                       :options="powersOfAttorneyList"
                       v-model="step3.powerOfAttorney"
                       :selected-value="step3.powerOfAttorney"
                       :placeholder="$t('general.please_select')"
                       :filterable="true"
                       @on-item-selected="powerOfAttorneySelected"/>
          <div class="w-full text-left mt-3">
            <span class="link" @click="addNewAttorney">{{ $t('declarations.add_declaration.steps.step3.new_power_of_attorney') }}</span>
          </div>
        </div>
        <div class="flex justify-around space-x-8">
          <InputSelect class=" w-full"
                       :options="$t('general.salutation_elster_options')"
                       :isError="errors.recipient.salutation.invalid"
                       :errorMessage="$t('general.errors.required')"
                       :isRequired="!recipientDisabled"
                       v-model="recipient.salutation"
                       :label="$t('general.attrs.salutation')"
                       placeholder=""
                       :selected-value="recipient.salutation"
                       :disabled="recipientDisabled"/>
          <InputText class="w-full "
                     :label="$t('clients.attrs.title')"
                     v-model="recipient.title"
                     placeholder=""
                     :disabled="recipientDisabled"/>
        </div>
        <div class="flex justify-around space-x-8  ">
          <InputText class=" w-full"
                     dateId="date1"
                     :label="$t('clients.attrs.phone')"
                     :maxLength="16"
                     v-model="recipient.phone"
                     placeholder=""
                     :disabled="recipientDisabled"/>
          <div class="w-full invisible"/>
        </div>
        <div class="flex justify-around space-x-8 ">
          <InputText class="w-full "
                     :label="$t('declarations.add_declaration.steps.step3.first_name_company_line_1')"
                     :is-required="true"
                     :isError="errors.recipient.firstName.invalid"
                     :errorMessage="$t('general.errors.required')"
                     v-model="recipient.firstName"
                     :disabled="recipientDisabled"/>
          <InputText class="w-full "
                     :label="$t('declarations.add_declaration.steps.step3.name_company_line_1')"
                     v-model="recipient.lastName"
                     :disabled="recipientDisabled"/>
        </div>
        <div class="flex justify-around space-x-8 ">
          <InputText class=" w-full"
                     :label="$t('clients.attrs.street')"
                     v-model="recipient.street"
                     placeholder=""
                     :disabled="recipientDisabled"/>
          <InputText class="w-full"
                     :label="$t('clients.attrs.house_number')"
                     v-model="recipient.houseNumber"
                     placeholder=""
                     :disabled="recipientDisabled"/>
        </div>
        <div class="flex justify-around space-x-8 ">
          <InputText class=" w-full"
                     :isRequired="!recipientDisabled"
                     :label="$t('clients.attrs.postcode')"
                     :isError="errors.recipient.zip.invalid"
                     :errorMessage="$t('general.errors.required')"
                     v-model="recipient.zip"
                     @blur="searchZip"
                     :isZip="true"
                     placeholder=""
                     :disabled="recipientDisabled"/>
          <InputText class="w-full "
                     :label="$t('clients.attrs.po_box')"
                     v-model="recipient.postbox"
                     placeholder=""
                     :disabled="recipientDisabled"/>
          <InputSelect
              class="w-full"
              :isRequired="!recipientDisabled"
              :label="$t('clients.attrs.city')"
              :isError="errors.recipient.city.invalid"
              :errorMessage="$t('general.errors.required')"
              v-model="recipient.city"
              placeholder=""
              :disabled="recipientDisabled"
              :options="cityList"
              :selected-value="recipient.city"
              :loading="zipSearch"
              :filterable="true"
              :auto-complete="true"
              :placeholder="$t('general.please_select')"
              :maxLength="25"
              @on-item-selected="selectCity"
          />
        </div>
        <div class="flex flex-col w-full">
          <label class="text mt-8 mb-8">
            {{ $t('declarations.add_declaration.steps.step3.authorized_receiver') }}
            <span style="color:red" v-show="!recipientDisabled">*</span>
          </label>
          <div class="flex space-x-3">
            <div class="flex space-x-3 justify-start items-center cursor-pointer" @click="createOptionSelectedAuthorize('yes')">
              <RadioButton :disabled="recipientDisabled"
                           v-model="recipient.authorizedReceiver"
                           name="authorized_receiver"
                           value="1"/>
              <span class=" mt-1 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex space-x-3 justify-start items-center cursor-pointer" @click="createOptionSelectedAuthorize('no')">
              <RadioButton name="authorized_receiver"
                           :disabled="recipientDisabled"
                           v-model="recipient.authorizedReceiver"
                           value="0"/>
              <span class="mt-1 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end  mt-6 space-x-4 ">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="prevStep"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {requiredIf} from "vuelidate/lib/validators";
import InputText from "../../inputs/InputText";
import {DeclarationService} from "@/services/declaration.service";
import RadioButton from 'primevue/radiobutton';
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";

export default {
  name: "Step3",
  components: {RadioButton, InputText},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new DeclarationService(),
      list: this.$t('properties.step4.list'),
      powersOfAttorneyList: [],
      cityList: [],
      zipSearch: false,
      step3: {
        powerOfAttorney: 0,
        recipientType: 'none',
        recipient: {
          'salutation': '1',
          'title': '',
          'firstName': '',
          'lastName': '',
          'phone': '',
          'street': '',
          'houseNumber': '',
          'zip': '',
          'postbox': '',
          'city': '',
          'authorizedReceiver': 'yes'
        }
      },
      errors: {
        recipient: {
          "salutation": {
            invalid: false
          },
          "firstName": {
            invalid: false
          },
          "phone": {
            invalid: false
          },
          "zip": {
            invalid: false
          },
          "city": {
            invalid: false
          }
        }
      },
      recipient: {
        salutation: '1',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        postbox: '',
        city: '',
        authorizedReceiver: "1"
      }
    }
  },
  validations: {
    step3: {
      recipient: {
        firstName: {
          required: requiredIf(function () {
            return this.step3.recipientType === 'others'
          })
        },
        zip: {
          required: requiredIf(function () {
            return this.step3.recipientType === 'others'
          })
        },
        city: {
          required: requiredIf(function () {
            return this.step3.recipientType === 'others'
          })
        }
      }
    }
  },
  watch: {
    active(nv) {
      if(nv) {
        if(this.getSteps.powerOfAttorneyFromClient > 0) {
          this.createOptionSelected('power_of_attorney_from_client');
        } else if(this.step3.recipientType !== 'none') {
          this.createOptionSelected('none');
        } else if(this.powersOfAttorneyList.length === 1 && !this.$isBusiness) {
          this.step3.powerOfAttorney = this.powersOfAttorneyList[0].code;
        }
      }
    }
  },
  computed: {
    ...mapGetters("declaration", ["getSteps"]),
    recipientDisabled() {
      return this.step3.recipientType === 'tax_consultant' || this.step3.recipientType === 'power_of_attorney_from_client';
    },
    showClientPowerOwAttorney() {
      return this.getSteps.powerOfAttorneyFromClient > 0;
    },
    poaCharacterLimitInfo() {
      return this.$t('declarations.add_declaration.steps.step3.new_power_of_attorney_character_restriction_info')
        .toString()
        .replace('%s', this.$t('declarations.add_declaration.steps.step3.character_restriction_info_by_federal_state')[this.getSteps.federalStateUid])
    }
  },
  mounted() {
    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id,
          "detail": o
        }
      });
    });
  },
  methods: {
    ...mapMutations("declaration", ["saveSteps", "init"]),
    ...mapGetters("user", ["getCurrentUser"]),
    createOptionSelected(e) {
      if (e !== this.step3.recipientType) {
        this.recipient = {
          salutation: '1',
          title: '',
          phone: '',
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          zip: '',
          postbox: '',
          city: '',
          authorizedReceiver: "1"
        }

        if (e === 'tax_consultant') {
          if (this.step3.powerOfAttorney === 0) {
            let defaultPowerOfAttorney = this.powersOfAttorneyList.filter((o) => {
              return parseInt(o.detail.preassignedAsStandard) === 1;
            });
            if (defaultPowerOfAttorney) {
              this.step3.powerOfAttorney = parseInt(defaultPowerOfAttorney[0].code);
            }
          }

          if (parseInt(this.step3.powerOfAttorney) > 0) {
            this.fillRecipientByPowerOfAttorney(parseInt(this.step3.powerOfAttorney));
          }
        } else if (e === 'power_of_attorney_from_client' && this.getSteps.powerOfAttorneyFromClient > 0) {
          this.fillRecipientByPowerOfAttorney(this.getSteps.powerOfAttorneyFromClient)
        }

        this.step3.recipientType = e
        this.errors.recipient = this.resetErrors(this.errors.recipient);
      }
    },
    fillRecipientByPowerOfAttorney(id) {
      let selectedPowerOfAttorney = this.powersOfAttorneyList.filter((o) => {
        return parseInt(o.code) === parseInt(id);
      });
      if (selectedPowerOfAttorney.length > 0 && selectedPowerOfAttorney[0].detail) {
        this.recipient.salutation = selectedPowerOfAttorney[0].detail.salutation;
        this.recipient.phone = selectedPowerOfAttorney[0].detail.phone;
        this.recipient.firstName = selectedPowerOfAttorney[0].detail.firstName;
        this.recipient.lastName = selectedPowerOfAttorney[0].detail.lastName;
        this.recipient.street = selectedPowerOfAttorney[0].detail.street;
        this.recipient.houseNumber = selectedPowerOfAttorney[0].detail.houseNumber;
        this.recipient.zip = selectedPowerOfAttorney[0].detail.zip;
        this.recipient.city = selectedPowerOfAttorney[0].detail.city;
        this.recipient.postbox = selectedPowerOfAttorney[0].detail.postbox;
        this.recipient.authorizedReceiver = parseInt(selectedPowerOfAttorney[0].detail.authorizedRecipient) === 1 ? '1' : '0'
      }
    },
    createOptionSelectedAuthorize(e) {
      if (!this.recipientDisabled) {
        this.step3.recipient.authorizedReceiver = e
      }
    },
    submitNext() {
      if (this.step3.recipientType && this.step3.recipientType === 'others') {
        this.step3.recipient = this.recipient;
      }

      this.errors.recipient = this.resetErrors(this.errors.recipient);

      if (this.step3.recipientType === 'others') {
        if ((this.step3.recipient.salutation === "0" || this.step3.recipient.salutation === "")) {
          this.errors.recipient.salutation.invalid = true;
          return;
        }

        if ((this.step3.recipient.street === '' || this.step3.recipient.houseNumber === '') && this.step3.recipient.postbox === '') {
          this.$api.showToast(this.$t('declarations.street_pobox_error'), "error")
          return false;
        }
      }

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        for (let x in this.$v.step3.recipient) {
          if (this.$v.step3.recipient[x].$error) {
            this.errors.recipient[x].invalid = true;
          }
        }
        return;
      }

      this.saveSteps(this.step3);
      this.service.save(this.getSteps).then((response) => {
        if (response) {
          this.$emit("finish", {
            "declarationId": this.service.declarationId,
            "clientId": this.getSteps.clientId
          })
        }
      })
    },
    prevStep() {
      this.$emit("goBack");
    },
    addNewAttorney() {
      this.$router.push('/powerofattorney/addPowerOfAttorney');
    },
    powerOfAttorneySelected(item) {
      this.fillRecipientByPowerOfAttorney(parseInt(item.code))
    },
    selectCity(item) {
      if (item.code === "") {
        this.errors.recipient.city.invalid = true;
      } else {
        this.errors.recipient.city.invalid = false;
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.recipient.city === '' && this.recipient.zip !== '') {
        this.cityList = [];
        this.cityLookup(this.recipient.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.recipient.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">


.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 15px;
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.link {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.info {
  padding: 5px 0;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}

.no-bottom {
  margin-bottom: 0px;
}
</style>
