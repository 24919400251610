<template>
  <div class="flex h-full">
    <div
      v-if="hasAccessToMassAppealFeature"
      class="w-full"
    >
      <draft
        :title="$t('declarations.stages.massdispute')"
        :infoText="$t('declarations.stages_info.mass_appeal')"
        :server-params="serverParams"
        :breadcrumbs="breadcrumbs"
        :menus="menus"
        :columns="getColumns"
        :grid-id="gridId"
        :gridName="gridName"
        @get-method="handleAddMassElsterMessageTypeModalOpen"
      />

      <AddMassElsterMessageTypeModal
        :visible="showAddMassElsterMessageTypeModal"
        @close="handleAddMassElsterMessageTypeModalClose"
      />
    </div>

    <div
      v-else
      class="flex flex-col w-full justify-center space-x-4"
    >
      <div class="w-full">
        <div class="flex w-fit justify-start items-center text-sm p-3 my-4 mx-auto bg-gray_light rounded">
          <img
            class="image"
            :src="getAssetPath('info_gray.svg')"
          />
          <label
            class="text-left ml-3"
            v-html="$t('declarations.detail.appeal_type_selection.feature_not_available')"
          />
        </div>
      </div>

      <div
        v-if="hasAccessToBilling"
        class="mt-8"
      >
        <p v-html="$t('declarations.detail.appeal_type_selection.feature_not_available_booking_info', {featureName: $t('billing_trial.feature_mass_ops')})"/>

        <Button
          class="items-center mt-8"
          :text="'Zur Paketübersicht wechseln'"
          @click="goToPlanSubscriptionPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import Draft from '@/components/declarations/Draft';
import AddMassElsterMessageTypeModal from '@/components/declarations/components/AddMassElsterMessageTypeModal.vue';

export default {
  name: 'mass_appeal',
  components: {AddMassElsterMessageTypeModal, Draft},
  data() {
    return {
      gridId: 'dispute_declarations',
      gridName: 'mass_appeal',
      showAddMassElsterMessageTypeModal: false,
      serverParams: {
        filters: {
          status: 'dispute',
          always : 'only_appeal_type_mass'
        }
      },
      breadcrumbs: [
        {
          title: this.$t('declarations.draft.breadcrumbs.link1'),
          link: '/declaration/declarations'
        },
        {
          title: this.$t('declarations.stages.massdispute')
        }
      ],
      menus: [
        {
          id: 'dec.attachment_mass_elster_message_type',
          icon: this.getAssetPath('attachment_mass_appeal_type.svg'),
          title: this.$t('declarations.menus.attachment_mass_appeal_type')
        },
        {
          id: 'dec.edit',
          icon: this.getAssetPath('edit_declaration_green.svg'),
          icon_disabled: this.getAssetPath('edit-declaration-gray.svg'),
          title: this.$t('declarations.menus.edit'),
          disable_on_multi: true,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'dec.delete',
          icon: this.getAssetPath('delete_user.svg'),
          icon_disabled: this.getAssetPath('delete_user_gray.svg'),
          title: this.$t('declarations.menus.delete'),
          action_endpoint: 'declaration/delete',
          action_confirmation: {
            show: true,
          },
          callback: () => {
            this.setCurrentUserStatsDataChanged(true);
          },
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'dec.archive',
          icon: this.getAssetPath('archive_green.svg'),
          icon_disabled: this.getAssetPath('archive-gray.svg'),
          title: this.$t('declarations.menus.archive'),
          action_endpoint: 'declaration/archive',
          action_confirmation: {
            show: true,
            single: {
              title: this.$t('general.grid.archive_record'),
              message: this.$t('general.grid.archive_confirm')
            },
            multiple: {
              title: this.$t('general.grid.archive_record'),
              message: this.$t('general.grid.archive_confirm')
            },
            buttons: {
              ok: this.$t('general.grid.archive_record')
            }
          },
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'dec.export',
          icon: this.getAssetPath('export.svg'),
          title: this.$t('declarations.menus.export')
        },
        {
          id: 'dec.bulk_processing',
          icon: this.getAssetPath('transfer.svg'),
          icon_disabled: this.getAssetPath('transfer-gray.svg'),
          title: this.$t('declarations.menus.bulk_transfer'),
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true,
          canShow: false,
          sub_menu: {
            items: [
              {
                id: 'dec.mass_update.document_creation',
                icon: 'dec_document_creation',
                label: this.$t('declarations.mass_update.process.document_creation')
              },
              {
                id: 'dec.mass_update.mass_appeal',
                icon: 'dec_mass_appeal_icon',
                label: this.$t('declarations.menus.mass_appeal')
              }
            ]
          }
        },
        {
          id: 'grid_reload',
          icon: this.getAssetPath('refresh.svg'),
          title: this.$t('general.grid.refresh')
        }
      ],
      defaultColumns: [
        {
          field: 'client_uid',
          header: this.$t('clients.attrs.id'),
          isChecked: false,
          clickable: true,
          width: 180
        },
        {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        },
        {
          field: 'client',
          header: this.$t('clients.attrs.name'),
          filterLbl: this.$t('clients.attrs.name') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          filterLbl: this.$t('declarations.grid.address') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          isChecked: true,
          elType: 'user_select',
          clickable: true,
        },
        {
          field: 'appeal_deadline',
          header: this.$t('declarations.detail.tab1.appeal_deadline'),
          isChecked: true,
          clickable: true,
        },
        {
          field: 'appeal_sent_on',
          header: this.$t('declarations.detail.tab1.appeal_sent_on'),
          isChecked: true,
          clickable: true,
        },
        {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.type_options.filter_types'),
          clickable: true,
        },
        {
          field: 'reference_date',
          header: this.$t('declarations.grid.reference_date'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        },
        {
          field: 'fee_invoice_sent',
          header: this.$t('declarations.grid.fee_invoice_sent'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.grid.fee_invoice_sent_options'),
          clickable: true,
        },
        {
          field: 'branch_name',
          header: this.$t('declarations.grid.branch'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        },
        {
          field: 'complexity',
          header: this.$t('declarations.grid.complexity'),
          isChecked: false,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options')
        },
        {
          field: 'internal_note',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'internal_note_property',
          header: this.$t('declarations.grid.internal_note_property'),
          filterLbl: this.$t('declarations.grid.internal_note_property') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'internal_note_client',
          header: this.$t('declarations.grid.internal_note_client'),
          filterLbl: this.$t('declarations.grid.internal_note_client') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'extra_details',
          header: this.$t('declarations.grid.extra_details'),
          filterLbl: this.$t('declarations.grid.extra_details') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        },
        {
          field: 'created_at',
          header: this.$t('declarations.grid.created_at'),
          isChecked: false,
          clickable: true,
          sortable: false
        },
        {
          field: 'internal_approval_requested_at',
          header: this.$t('declarations.grid.approval_requested_at'),
          clickable: true,
          isChecked: false
        },
        {
          field: 'tc_approval_at',
          header: this.$t('declarations.grid.tc_approval_at'),
          clickable: true,
          isChecked: false
        },
        {
          field: 'elster_transferred_at',
          header: this.$t('declarations.grid.elster_transferred_at'),
          clickable: true,
          isChecked: false
        },
        {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between'
        }
      ]
    }
  },
  computed: {
    getColumns() {
      let columns = [...this.defaultColumns];

      if (!this.$isBusiness) {
        columns.push({
          field: 'client_approval_requested_at',
          header: this.$t('declarations.grid.client_approval_requested_at'),
          clickable: true,
          isChecked: false
        });

        columns.push({
          field: 'client_approval_at',
          header: this.$t('declarations.grid.client_approval_at'),
          clickable: true,
          isChecked: false
        });
      }

      columns = columns.map((o) => {
        if (o.field === 'reference_date') {
          let declarationReferenceDateOptions = [];
          for (var i = 2022; i <= 2050; i++) {
            declarationReferenceDateOptions.push({
              'name': i,
              'code': i
            });
          }

          o.filterOptions = declarationReferenceDateOptions;
        }

        return o;
      });

      return columns;
    },
    hasAccessToMassAppealFeature() {
      if (this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_appeal)) {
        return true;
      }

      return false;
    },
    hasAccessToBilling() {
      if (this.hasAccess('billing')) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    ...mapMutations('user', ['setCurrentUserStatsDataChanged']),
    handleAddMassElsterMessageTypeModalOpen() {
      this.showAddMassElsterMessageTypeModal = true;
    },
    handleAddMassElsterMessageTypeModalClose() {
      this.showAddMassElsterMessageTypeModal = false;
    },
    goToPlanSubscriptionPage() {
      this.$router.push({
        name: 'Setting',
        params: {
          'comp': 'billing',
          'open_plan_subscription': true
        }
      });
    }
  }
}
</script>
