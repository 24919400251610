<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <span class="font-bold">{{ $t('declarations.mass_update.steps.process_type') }}:</span>
          <span class="ml-2">{{ $t('declarations.mass_update.process.mass_appeal') }}</span>
        </div>
      </div>

      <Fieldset
        legend="Filter"
        class="mt-4 rounded"
      >
        <div class="flex justify-around space-x-4">
          <InputSelect
            class="mt-2 w-full"
            :label="$t('declarations.add_declaration.steps.step1.mass_appeal_types')"
            :options="massAppealTypesList"
            v-model="selectedMassAppealTypeUid"
            :filterable="true"
            :show-clear="true"
            :placeholder="$t('general.please_select')"
          />
        </div>
      </Fieldset>

      <div class="flex justify-around space-x-1 mt-5">
        <DualListSelect
          :list="declarationsList"
          ref="declarationDualList"
          v-model="selectedDeclarations"
          :value="selectedDeclarations"
          :columns="declarationDualListColumns"
          dataKey="id"
          sort-attr="name"
          :enable-all-select="true"
          :leftHeader="$t('declarations.mass_update.declarations')"
          :rightHeader="$t('declarations.mass_update.declarations')"
          :countShow="true"
        />
      </div>
    </div>

    <div class="sticky bottom-0 p-4 bg-white z-10">
      <div class="flex justify-end space-x-4">
        <Button
          class="w-56"
          :text="$t('buttons.back')"
          :secondary="true"
          @click="goBack"
        />
        <Button
          class="w-56"
          :text="$t('buttons.next')"
          :disabled="selectedDeclarations.length === 0"
          @click="submitNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { DeclarationService } from '@/services/declaration.service';
import { MassElsterMessageTypeService } from '@/services/MassElsterMessageType.service';

import Fieldset from 'primevue/fieldset';

import DualListSelect from '@/components/labels/DualListSelect';

export default {
  name: 'StepMassAppeal1',
  components: {
    DualListSelect,
    Fieldset
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      declarationService: new DeclarationService(),
      massElsterMessageTypeService: new MassElsterMessageTypeService(),

      declarations: [],
      selectedDeclarations: [],
      declarationDualListColumns: [
        {
          field: 'declarationId',
          label: this.$t('declarations.grid.id'),
          search: true
        },
        {
          field: 'name',
          label: this.$t('declarations.mass_update.declaration'),
          search: true
        }
      ],

      selectedMassAppealTypeUid: 0,
      massAppealTypes: [],
    }
  },
  computed: {
    selectedMassAppealType() {
      let selectedMassAppealType = null;

      this.massAppealTypes.forEach((massAppealType) => {
        if (massAppealType.prim_uid === this.selectedMassAppealTypeUid) {
          selectedMassAppealType = massAppealType;
        }
      });

      return selectedMassAppealType;
    },
    declarationsList() {
      let filteredDeclarations = this.declarations;

      if (this.selectedMassAppealType === null) {
        return filteredDeclarations;
      }

      this.selectedDeclarations = [];

      filteredDeclarations = filteredDeclarations.filter((o) => {
        if (o.massAppealType > 0 && parseInt(o.massAppealType) !== parseInt(this.selectedMassAppealTypeUid)) {
          return false;
        }

        if (this.selectedMassAppealType.administrative_act === '299' && ![2, 9].includes(parseInt(o.federalStateUid))) {
          return false;
        }

        if (this.selectedMassAppealType.administrative_act === '271' && [2, 7, 9].includes(parseInt(o.federalStateUid))) {
          return false;
        }

        if (this.selectedMassAppealType.federal_state_uids !== null && !this.selectedMassAppealType.federal_state_uids.includes(parseInt(o.federalStateUid))) {
          return false;
        }

        return true;
      });

      return filteredDeclarations;
    },
    massAppealTypesList() {
      return this.massAppealTypes.map((massAppealType) => {
        return {
          code: massAppealType.prim_uid,
          name: massAppealType.name
        };
      });
    },
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.show();
      }
    },
    selectedMassAppealTypeUid(newValue) {
      if (newValue === '') {
        this.selectedMassAppealTypeUid = 0;
        return;
      }

      this.fetchDeclarations();
    }
  },
  mounted() {
    if (this.$route.params.selectedDeclarations && this.$route.params.selectedDeclarations.length > 0) {
      this.selectedDeclarations = this.$route.params.selectedDeclarations;
    }
  },
  methods: {
    ...mapGetters('declaration_bulk_add', ['getSteps']),
    ...mapMutations('declaration_bulk_add', ['saveSteps']),
    ...mapMutations('grid', ['triggerReload']),
    show() {
      this.massElsterMessageTypeService.listAll('appeal', false).then((list) => {
        this.massAppealTypes = list;
      });

      this.fetchDeclarations();
    },
    submitNext() {
      this.saveSteps({
        selectedDeclarations: this.selectedDeclarations,
      });

      this.$emit('goToNextStep');
    },
    goBack() {
      this.$emit('goBack');
    },
    fetchDeclarations() {
      this.declarations = [];

      this.$nextTick(() => {
        this.$refs.declarationDualList.showloader = true;
      });

      let filters = {
        status: 'dispute'
      };

      let params = {
        rows: -1
      }

      this.declarationService.listAll(filters, params).then((response) => {
        this.$refs.declarationDualList.showloader = false;

        if (response.totalRecordCount <= 0) {
          this.$api.showToast(this.$t('declarations.mass_update.no_record_found'), 'error');
          this.goBack();
          return;
        }

        this.declarations = response.list.map((o) => {
          return {
            id: o.id,
            declarationId: o.uid,
            name: o.property.name,
            clientName: o.client.clientName,
            clientId: o.clientId,
            assignedUser: o.assignedUser,
            elsterStatus: o.elsterStatus,
            massAppealType: o.massAppealType,
            massAppealActDate: o.massAppealActDate,
            federalStateUid: o.property.federalStateUid,
            type: o.type,
          };
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-fieldset) {
  border-color: theme('colors.gray_df');

  .p-fieldset-legend {
    @apply px-2 py-0 border-0;

    background: none;
  }
}
</style>
