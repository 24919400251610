<template>
  <MainLayout value="notifications">
    <template>
      <div class=" bg-white pl-7 w-full" v-if="!rendering">
        <div class="gap"></div>
        <div class="flex justify-between items-center mr-6 page-caption">
          <p class="header-component ">{{ $t('dashboard.headers.notification') }}</p>
          <span class="text-link cursor-pointer underline" @click="markAsRead">
            {{ $t('dashboard.headers.mark-as-read') }}
          </span>
        </div>
        <hr style="margin-top: 25px;" class="hr-line-user  mr-7">
        <div class="flex justify-end items-end mr-7 mt-5">
          <filter-input :show-filter="false"
                        :show-view-menu="false"
                        @search="search"
          />
        </div>
        <div class="main h-full flex flex-col justify-between mr-7" v-if="notifications.length > 0">
          <div class="ml-4">
            <NotificationItem v-for="notification in notifications"
                              :key="notification.id"
                              :notification="notification"
                              class="-ml-4"
            />
          </div>
          <div class="flex paginator">
            <Paginator :rows="rowsPerPage"
                       template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                       :totalRecords="totalRecords"
                       :rowsPerPageOptions="rowsPerPageOptions"
                       :currentPageReportTemplate="$t('general.grid.page_info')"
                       @page="changePage"
            />
          </div>
        </div>
        <div class="main h-full flex flex-col justify-between mr-7" v-else>
          <label class="text-left text-gray mt-2">{{ $t('general.none') }}</label>
        </div>
      </div>
      <div class="mt-20 flex flex-col container h-full" v-else>
        <div class="flex flex-col w-full loading-container">
          <div class="spin"></div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import Paginator from 'primevue/paginator';
import {UserService} from "@/services/user.service";
import NotificationItem from "@/components/Dashboard/Items/NotificationItem";
import filterInput from "@/components/inputs/FilterInput";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Notifications",
  components: {Paginator, NotificationItem, filterInput},
  data() {
    return {
      service: new UserService(),
      rendering: true,
      searchTxt: '',
      rowsPerPage: 25,
      totalRecords: 0,
      currentOffset: 0,
      notifications: [],
      rowsPerPageOptions: [10, 25, 50, 100, 250]
    }
  },
  computed: {
    ...mapGetters("user", ["getNotificationCount"])
  },
  mounted() {
    this.startRendering();
  },
  watch: {
    '$route'() {
      this.startRendering();
    }
  },
  methods: {
    ...mapMutations("user", ["setNewNotificationCount"]),
    startRendering() {
      if(this.$route.query.uid) {
        this.service.readNotification(this.$route.query.uid);
        this.service.getNotification(this.$route.query.uid, false).then((resp) => {
          if(resp.data.success && resp.data.notification) {
            this.processNotification(resp.data.notification);
          } else {
            this.fetchNotifications(0)
          }
        })
      } else {
        this.fetchNotifications(0)
      }
    },
    fetchNotifications(offset) {
      this.rendering = false;
      let params = {
        "offset": offset,
        "rows": this.rowsPerPage,
        "filters": {
          "txt_filter": this.searchTxt
        }
      }
      if(this.$route.query.new && parseInt(this.$route.query.new) === 1) {
        params.filters.only_unread = 1;
      }
      this.service.listNotifications(params, true).then((notifications) => {
        this.notifications = notifications;
        this.currentOffset = offset;
        this.totalRecords = this.getNotificationCount;
      });
    },
    changePage(e) {
      if ((this.currentOffset !== e.first || this.rowsPerPage !== e.rows) && parseInt(e.first) < this.totalRecords) {
        this.rowsPerPage = e.rows;
        this.fetchNotifications(e.first);
      }
    },
    search(txtFilter) {
      this.searchTxt = txtFilter;
      this.fetchNotifications(this.currentOffset);
    },
    markAsRead() {
      this.service.readAllNotifications().then((response) => {
        if (response.data.success) {
          this.notifications = this.notifications.map((notification) => {
            if (!notification.isRead) {
              notification.isRead = 1;
            }

            return notification;
          });
          this.setNewNotificationCount(response.data.new_notification_count);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  @include segoe-regular;
  width: 350px;
  height: 34px;
  border: 1px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
    background-image: none
  }

  &:hover {
    background-image: none
  }
}

.search:focus-within {
  border: 2px solid $primary;

  input {
    // margin-left: -12px
  }
}

.input {
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-left: 5px;
  font-size: 13px;
}

.main {
  height: calc(90vh - 120px);
}
.paginator {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;

  ::v-deep {
    .p-paginator-current {
      position: absolute;
      right: 0;
      padding-right: 0;
      font-size: 13px;
    }

    .p-paginator {
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;

      .p-dropdown {
        width: 60px;
        height: 30px;
      }
    }

    .p-paginator-element {
      width: 30px !important;
      height: 30px !important;
      min-width: 10px !important;
      font-size: 13px !important;
    }

    .p-paginator-icon, .p-dropdown-trigger-icon, .p-dropdown-label, .p-dropdown-item {
      font-size: 13px;
    }

    .p-dropdown-label {
      padding: 5px !important;
    }

    .p-dropdown-trigger {
      width: 25px;
    }
  }
}
.container {
  position: relative;
}
.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.gap {
  margin-top: 72px;
}

.text-link {
  font-family: "Segoe UI", sans-serif;
  color: #229d56;
}
</style>
