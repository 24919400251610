export const pdfjsCVEMixin = {
    methods: {
        // mixin method to provide "source" prop in "vue-pdf-embed" components as an object instead as an url string only
        // in order to implement the workaround for CVE 2024-4367 (https://codeanlabs.com/blog/research/cve-2024-4367-arbitrary-js-execution-in-pdf-js/)

        sourceObjectForPdfjs(documentSource) {
            switch (typeof documentSource) {
                case 'string':
                    return {
                        url: documentSource,
                        isEvalSupported: false,
                    };
                case 'object':
                    documentSource.isEvalSupported = false;
                    if (typeof documentSource.url === 'undefined') {
                        documentSource.url = '';
                    }
                    return documentSource;
            }

            return {
                url: '',
                isEvalSupported: false,
            };
        }
    }
}