<template>
  <div class="flex flex-col">
    <p class="empty-text">{{ $t('dashboard.notification_text.no_data') }}</p>
    <img class="img-empty" :src="getAssetPath('empty_graphic.png')"/>
    <img class="img-empty" :src="getAssetPath('empty_graphic.png')"/>
    <img class="img-empty" :src="getAssetPath('empty_graphic.png')"/>


  </div>
</template>

<script>
export default {
  name: "EmptyList",
  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.empty-text {
  font-size: 14px;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.img-empty {
  height: 90px;
  width: 500px;
}
</style>
