<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img src="@/assets/check_done.svg"/>
    </div>
    <label class="text-finish ">{{ $t('properties.added_successfully') }}</label>
    <div class="flex flex-row justify-center items-center">
      <Button @click="goToEdit" class="label  bg-green  text-white mt-10 mx-2"
              :text="$t('components.properties_menu.edit_property')"></Button>
      <Button @click="goToAdd" class="label  bg-green  text-white mt-10 mx-2"
              :text="$t('properties.add_another_property')"></Button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {PropertyService} from "@/services/property.service";
import {ClientService} from "@/services/client.service";

export default {
  name: "Finish",
  props: {
    propertyUid: {
      type: Number,
      default: 0
    },
    clientUid: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      propertyService: new PropertyService(),
      clientService: new ClientService(),
    }
  },
  methods: {
    ...mapMutations("property", ["setCurrentStep"]),
    goToEdit() {
      this.$router.push({
        name: "Properties",
        query: {
          'uid': this.propertyUid
        },
        params: {
          'comp': 'edit',
          'client_uid': this.clientUid
        }
      });
    },
    goToAdd() {
      this.setCurrentStep(1)
      this.$emit("finishButtonClick", "finish");
    }
  },
  mounted() {
    let previousPage = localStorage.getItem("previousPage");
    if(previousPage !== null) {
      localStorage.setItem("AddedProperty", this.propertyUid);
      localStorage.setItem("clientId", this.clientUid);
      localStorage.setItem("flag", 1);
      window.location.href = previousPage;
    }

    // check if Skendata step is required
    let AddSkenDataToProperty = localStorage.getItem("AddSkenDataToProperty");
    localStorage.setItem("AddSkenDataToProperty", 0);
    if(parseInt(AddSkenDataToProperty) === 1){
      this.propertyService.get(this.propertyUid).then((data) => {

        let address = '';
        let property_uid = 0;
        let user_identifier = 0;
        let account_uid = 0;
        let user_uid = 0;

        property_uid = this.propertyUid;
        address = data.step2.street + ' ' +
            data.step2.houseNumber + ' ' +
            data.step2.zip + ' ' +
            data.step2.city;

        account_uid = this.$store.state.user.currentUser.account_uid;
        user_uid = this.$store.state.user.currentUser.id;
        user_identifier = account_uid + user_uid + property_uid;

        if (address.toString() !== '' && property_uid > 0 && user_identifier > 0) {
          this.$api.trigger(
              'skendata/fulfillment',
              {
                'property_uid': property_uid,
                'address': address,
                'user_identifier': user_identifier,
                'parcel_id' : 0,
                'process_id': ''
              }
          ).then((response) => {
                if (response) {
                  if (response.data.link) {
                    let fulfillment_link = response.data.link;
                    window.open(fulfillment_link, '_self');
                    //window.open(fulfillment_link, '_blank');
                  }
                  if (response.data.error) {
                    this.$toast.error(response.data.error)
                  }
                }
              });
        }
      });

    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;


  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}

.text-finish {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: normal;
  font-size: 15px;
}
</style>
