<template>
  <div class="">

    <!-- Data Acquisition modal loader -->
    <ModalDialog v-show="toggleDataAcquisitionLoader" :showClose="false"
                 :headerText="$t('properties.parcel.loading')">
      <template v-slot:body>
        <img class="ml-64 mt-2.5 mb-5 animate-spin" width="75" src="@/assets/loading.svg" alt=""/>
      </template>
    </ModalDialog>

    <!-- Data Acquisition Abo Requied modal -->
    <ModalDialog v-show="toggleDataAcquisitionAboRequired" :showClose="true"
                 @close="toggleDataAcquisitionAboRequired=false"
                 :headerText="$t('properties.parcel.modal_required_abo_title')">
      <template v-slot:body>
        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-3.5" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 mt-5 w-full">
                <p class="text-left mb-5 text-sm">
                  {{ $t('properties.parcel.modal_required_abo_text') }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/billingTrial'" class="button-external w-1/3 mt-4 mb-1">
                  {{ $t('properties.parcel.modal_required_abo_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>


    <!-- Data Acquisition is not active modal -->
    <ModalDialog v-show="toggleDataAcquisitionModalServiceNotActive" :showClose="true"
                 @close="toggleDataAcquisitionModalServiceNotActive=false"
                 :headerText="$t('properties.parcel.modal_data_acquisition_error_not_active_title')">
      <template v-slot:body>

        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-8 h-20" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-8" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 w-full">
                <p class="text-left mb-5 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisition_error_not_active_text').replace('%s', userLimitAmount) }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/systemsettings?tab=data_acquisition'"
                             class="w-1/3 mt-4 mb-1 button-external text-base">
                  {{ $t('properties.parcel.modal_data_acquisition_error_limit_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition Over Limit modal -->
    <ModalDialog v-show="toggleDataAcquisitionModalOverLimit" :showClose="true"
                 @close="toggleDataAcquisitionModalOverLimit=false"
                 :headerText="$t('properties.parcel.modal_data_acquisition_error_limit_title')">
      <template v-slot:body>
        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-8 px-8 h-20" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-7" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 w-full">
                <p class="text-left mb-5 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisition_error_limit_text').replace('%s', userLimitAmount) }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/systemsettings?tab=data_acquisition'"
                             class="w-1/3 mt-4 mb-1 button-external text-base"
                >
                  {{ $t('properties.parcel.modal_data_acquisition_error_limit_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition modal -->
    <ModalDialog
        v-show="toggleDataAcquisitionModal"
        :headerText="$t('properties.data_acquisitions.title').toString()"
        modalSize="large"
        @close="toggleDataAcquisitionModal=false"
        :showClose="true">
      <template v-slot:body>
        <div class="flex flex-col w-full mt-2 mb-4 h-96"
             style="overflow-y: auto; height: 431px; max-width: calc(99% - (10px)); padding: 2px 2px 2px 2px;
             box-sizing: border-box;">
          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_1') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_1_1') }}
                </div>
                <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.district') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.district_number') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_number') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_counter') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_denominator') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.ground_value') }}</li>
                </ul>
                <div class="text-left mb-4 text-sm"
                     v-html="$t('properties.parcel.modal_data_acquisitions_info_retrieve_text_1_2_1')"
                ></div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_2') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_2_1') }}<br/>
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_2_2') }}
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_3') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_3_1') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_3_2') }}<br/>
                  <a target="_blank" class="text-base label-chk text-sm text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/">
                    https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/
                  </a>
                </div>
                <div class="text-left mb-2">
                  <Button @click="confirmOpenDataAacquisitionAPI"
                          class="text-base"
                          :text="$t('properties.parcel.modal_data_acquisitions_info_retrieve_button')">
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text_bold text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_1') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_2') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  <strong>{{ $t('properties.parcel.modal_data_acquisitions_land_register_title_2') }}:</strong>
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_3') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_4') }} <br/>
                  <a target="_blank" class="text-base label-chk text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://taxtech-shop.de/"
                     target="_blank"
                     class="button-external text-base">
                    {{ $t('properties.parcel.modal_data_acquisitions_land_register_button') }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_text_1') }}
                </p>
                <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland1') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland2') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland3') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland4') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland5') }}</li>
                </ul>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_text_2') }}
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/"
                     target="_blank" class="button-external text-base"
                  >{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_button')}} </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Feature of BW modal -->
    <ModalDialog
        v-show="showFeatureForBWModal"
        :headerText="$t('properties.data_acquisitions.title')"
        modalSize="large"
        @close="showFeatureForBWModal=false"
        :showClose="true">
      <template v-slot:body>
        <div class="flex flex-col w-full mt-2 mb-4 h-96"
             style="overflow-y: auto; height: 431px; max-width: calc(99% - (10px)); padding: 2px ;
             box-sizing: border-box;">

          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <div class="text-left mb-4 text-sm text_bold" style="color: #229d56;">
                  {{ $t('properties.modal_data_acquisitions_overview.block_4.title_0') }}
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.modal_data_acquisitions_overview.block_4.title_1') }}
                </div>
                <div class="text-left mb-4 text-sm"
                     v-html="$t('properties.modal_data_acquisitions_overview.block_4.text_1')"
                ></div>

                <div class="text-left mb-4">
                  <Button @click="confirmOpenDataAacquisitionAPI"
                          class="text-base"
                          :text="$t('properties.modal_data_acquisitions_overview.block_4.button')">
                  </Button>
                </div>

                <div class="text-left mb-4 text-sm"
                     v-html="$t('properties.modal_data_acquisitions_overview.block_4.text_2')"
                ></div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text_bold text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_1') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_2') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  <strong>{{ $t('properties.parcel.modal_data_acquisitions_land_register_title_2') }}:</strong>
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_3') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_4') }} <br/>
                  <a target="_blank" class="text-base label-chk text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://taxtech-shop.de/"
                     target="_blank"
                     class="button-external text-base">
                    {{ $t('properties.parcel.modal_data_acquisitions_land_register_button') }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!--  modal retrieval -->
    <ModalDialog v-show="showInfoDlg" :headerText="$t('properties.retrieval')" @close="closeInfoDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start ">
          <div class="p-3 mt-8 mb-4 info-msg flex justify-start items-center">
            <img class="self-center" src="@/assets/note.svg" width="15px">
            <div class="pl-4 text-left labelBoris" v-html="infoMessage"></div>
          </div>
          <div class="flex mt-6 mb-4  justify-end space-x-4">
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="closeInfoDlg"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="mt-18 flex flex-col relative">
      <div class="flex flex-col justify-start">
        <div class="flex justify-start items-center">
          <label class="flex title mt-4">{{ $t('properties.step3.title') }}</label>
          <Button
              @click="openSkenDataModal"
              :text="$t('properties.data_acquisitions.title')"
              class="flex ml-auto">
          </Button>
        </div>
        <hr class=" mt-3 mb-4 hr-line-user"/>
      </div>
      <div class="flex justify-around space-x-10 mt-3">
        <InputSelect
            class="mt-2 w-full max-width-half"
            :label="$t('properties.step3.ownership_structure')"
            v-model="step3.ownershipStructure"
            :selected-value="step3.ownershipStructure"
            :options="ownership_structure"
            @on-item-selected="updateDisabledStatus"
            placeholder=""
            :showHelpIconPopover="true"
            :popoverTexts="popoverTexts12"
            :popoverLink="popoverLink8"
        />
        <InputSelect
            class="mt-2 w-full max-width-half"
            :label="$t('properties.step3.property_type')"
            v-model="step3.propertyType"
            :options="property_type"
            @on-item-selected="updateDisabledStatus"
            :disabled="propertyTypeDisabled"
            placeholder=""
            :showHelpIconPopover="true"
            :popoverTexts="popoverTexts11"
            :popoverLink="popoverLink7"
        />
      </div>
      <div class="flex justify-around space-x-10 mt-3">
        <InputSelect
            class="mt-2 w-full"
            :label="$t('properties.step3.development_state')"
            v-model="step3.developmentState"
            :options="development_state"
            :disabled="developmentStateDisabled"
            placeholder=""
            :showHelpIconPopover="true"
            :popoverTexts="popoverTexts13"
        />
        <div class="mt-2 w-full invisible"/>
      </div>
      <div class="flex justify-around space-x-10 mt-3">
        <div class=" flex flex-col  w-full">
          <div class="flex">
            <label class="text mt-5 mb-10">{{ $t('properties.step3.multi_communities') }}</label>
            <VDropdown offset="2"
                       :showTriggers="['click','hover']"
                       :popperTriggers="['hover','click']"
                       :hideTriggers="['click']"
            >
              <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
              <template #popper>
                <div class="tooltip-content">
                  <p class="space-y-2 mb-1 popover-text"
                     v-for="(text, index) in popoverTexts_multiple_communities"
                     :key="index"
                  >
                    {{ text }}
                  </p>
                  <h2 style="line-height:80%; margin-top: 5px;">
                    <a class="popover-text"
                       :href="popoverLink_multiple_communities"
                       target="_blank"
                    >
                      {{popoverLink_multiple_communities}}
                    </a>
                  </h2>
                </div>
              </template>
            </VDropdown>
          </div>
          <div class="flex space-x-4  mb-6">
            <div class="flex justify-start items-center">
              <RadioButton type="radio" v-model="step3.multiCommunities" name="radio-button" value="1"/>
              <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex justify-start items-center ">
              <RadioButton type="radio" v-model="step3.multiCommunities" name="radio-button" value="0"/>
              <span class="ml-3 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
        <div class=" flex flex-col  w-full">
          <div class="flex">
            <label class="text mt-5 mb-10">{{ $t('properties.step3.leasehold') }}</label>
            <VDropdown offset="2"
                       :showTriggers="['click','hover']"
                       :popperTriggers="['hover','click']"
                       :hideTriggers="['click']"
            >
              <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
              <template #popper>
                <div class="tooltip-content">
                  <p class="space-y-2 mb-1 popover-text"
                     v-for="(text, index) in popoverTexts7"
                     :key="index"
                  >
                    {{ text }}
                  </p>
                  <h2 style="line-height:80%; margin-top: 5px;">
                    <a class="popover-text"
                       :href="popoverLink_lease_hold"
                       target="_blank"
                    >
                      {{ popoverLink_lease_hold }}
                    </a>
                  </h2>
                </div>
              </template>
            </VDropdown>
          </div>

          <div class="flex space-x-4  mb-6">
            <div class="flex justify-start items-center">
              <RadioButton type="radio"
                           v-model="step3.leasehold"
                           :disabled="leaseholdDisabled"
                           name="radio-button3"
                           value="1"
              />
              <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex justify-start items-center ">
              <RadioButton type="radio"
                           v-model="step3.leasehold"
                           :disabled="leaseholdDisabled"
                           name="radio-button3"
                           value="0"
              />
              <span class="ml-3 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-around space-x-10">
        <div class="w-full">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-5 mb-10">{{ $t('properties.step3.buildingOnForeignGround') }}</label>
              <VDropdown offset="2"
                         :showTriggers="['click','hover']"
                         :popperTriggers="['hover','click']"
                         :hideTriggers="['click']"
              >
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text"
                       v-for="(text, index) in popoverTexts8"
                       :key="index"
                    >
                      {{ text }}
                    </p>
                    <h2 style="line-height:80%; margin-top: 5px;">
                      <a class="popover-text"
                         :href="popoverLink_foreign_land"
                         target="_blank"
                      >
                        {{ popoverLink_foreign_land }}
                      </a>
                    </h2>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-2">
              <div class="flex justify-start items-center">
                <RadioButton type="radio"
                             v-model="step3.buildingOnForeignGround"
                             :disabled="buildingOnForeignGroundDisabled"
                             name="radio-button4"
                             value="1"
                />
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center ">
                <RadioButton type="radio"
                             v-model="step3.buildingOnForeignGround"
                             :disabled="buildingOnForeignGroundDisabled"
                             name="radio-button4"
                             value="0"
                />
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-5 mb-10">{{ $t('properties.step3.propertyResidentialPurposes') }}</label>
              <VDropdown offset="2"
                         :showTriggers="['click','hover']"
                         :popperTriggers="['hover','click']"
                         :hideTriggers="['click']"
              >
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text"
                       v-for="text in [$t('properties.step3.tooltip.tooltip_propertyResidentialPurposes')]"
                       v-bind:key="text"
                    >
                      {{ text }}
                    </p>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-2">
              <div class="flex justify-start items-center">
                <RadioButton type="radio"
                             v-model="step3.propertyResidentialPurposes"
                             name="radio-button5"
                             value="1"
                             :disabled="propertyResidentialPurposesDisabled"
                />
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center ">
                <RadioButton type="radio"
                             v-model="step3.propertyResidentialPurposes"
                             name="radio-button5"
                             value="0"
                             :disabled="propertyResidentialPurposesDisabled"
                />
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-around space-x-10">
        <div class=" flex flex-col  w-full">
          <div class="flex">
            <label class="text mt-5 mb-10">{{ $t('properties.step3.buildingsOnThirdPartyOwners') }}</label>
          </div>
          <div class="flex space-x-4  mb-2">
            <div class="flex justify-start items-center">
              <RadioButton type="radio"
                           v-model="step3.buildingsOnThirdPartyOwners"
                           :disabled="buildingsOnThirdPartyOwnersDisabled"
                           name="radio-button5"
                           value="1"
              />
              <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex justify-start items-center ">
              <RadioButton type="radio"
                           v-model="step3.buildingsOnThirdPartyOwners"
                           :disabled="buildingsOnThirdPartyOwnersDisabled"
                           name="radio-button5"
                           value="0"
              />
              <span class="ml-3 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-start space-x-10 mt-1">
        <div class=" mt-3 w-1/2">
          <InputDate class="mt-2 w-full"
                     :label="$t('properties.step3.registration_land_registry')"
                     v-model="step3.registrationLandRegistry"
                     :disabled="registrationLandRegistryDisabled"
                     :isOnlyNumber="true"
                     placeholder=""
          />
        </div>
        <div class=" mt-3 w-1/2">
          <InputText class="mt-2 w-full hidden"
                     :isOnlyNumber="true"
                     v-model="step3.liftingRate"
                     :label="$t('properties.step3.liftingRate')"
                     placeholder=""
          />
        </div>
      </div>
      <div class="flex flex-col justify-start mt-8 mb-2">
        <label class="sub-heading">{{ $t('properties.step3.property_residential_purposes_headline') }}</label>
      </div>
      <div class="flex justify-start space-x-10 mt-1 mb-5 pr-10">
        <div class=" mt-3 w-1/2">
          <InputText class="mt-2 w-full"
                     :isOnlyNumber="true"
                     :isOnlyPositive="true"
                     :maxLength="9"
                     :label="$t('properties.step3.total_property_part')"
                     v-model="step3.totalPropertyPart"
                     placeholder=""
                     :disabled="totalPropertyPartDisabled"
          />
        </div>
      </div>

      <InputText class="mt-2 w-full hidden"
                 :isOnlyNumber="true"
                 v-model="step3.liftingRate"
                 :label="$t('properties.step3.liftingRate')"
                 placeholder=""/>

      <!--Section2-->
      <div v-if="districtShow">
        <div class="flex flex-col justify-start mt-12">
          <div class="flex justify-between">
            <label class="title">{{ $t('properties.step3.district_title') }}</label>
            <a class="link"
               href="https://hilfe.grundsteuer-digital.de/faq/wie-erfasse-ich-daten-aus-dem-grundbuchauszug-auf-der-grundsteuerdigital-plattform/"
               target="_blank">{{ $t('properties.step3.tutorial_link') }}</a>
          </div>

          <hr class=" mt-3 mb-4 hr-line-user"/>
        </div>

        <div class="flex flex-col" v-for="(item, index) in this.parcels" :key="index">
          <div class="box flex justify-start items-center"
               v-if="grundstucksadresse_info_box !== ''"
               v-show="address_info_box"
          >
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <label class="text-left ml-2">
              {{ grundstucksadresse_info_box }}
            </label>
          </div>
          <div class="flex justify-between mt-2">
            <label class="sub-heading mb-6 text-left self-start">{{
                $t('properties.step3.district') + " " + (index + 1)
              }} </label>
            <img class="delete delete-icon" :src="getAssetPath('delete_user.svg')"
                 @click="handleDelete(index)"/>
          </div>

          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2.community')"
                :options="communityList"
                v-model="item.community"
                :selected-value="item.community"
                :filterable="true"
                :auto-complete="true"
                @on-item-selected="changeParcelList"
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.district')"
                :options="parcelList"
                v-model="item.parcel"
                :selected-value="item.parcel"
                :filterable="true"
                :auto-complete="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts3"
                :popoverLink="popoverLink3"
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full" :label="$t('properties.step3.landRegisterSheet')"
                       v-model="item.landRegisterSheet" placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts2"
                       :popoverLink="popoverLink2"
                       :maxLength="6"
                       isRegex="[0-9]{1,5}[A-Za-z0-9]{0,1}"
            />
            <InputText class="mt-2 w-full"
                       :label="$t('properties.step3.corridor')"
                       v-model="item.corridor"
                       placeholder=""
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :disabled="corridorDisabled"
                       :maxLength="3"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :isOnlyNumber="true"
                       :label="$t('properties.step3.parcelDataCounter')"
                       v-model="item.parcelDataCounter"
                       placeholder=""
                       :maxLength="5"/>
            <InputText class="mt-2 w-full"
                       :label="$t('properties.step3.parcelDataDenominator')"
                       v-model="item.parcelDataDenominator"
                       :maxLength="4"
                       placeholder=""
                       :isAlphaNumric="true"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :isOnlyNumber="true"
                       :label="$t('properties.step3.areaOfTheLand')"
                       v-model="item.areaOfTheLand"
                       placeholder=""
                       :maxLength="step3.maxlength"/>
            <InputText class="mt-2 w-full"
                       :isFloat="true"
                       :decimalNumber="step3.decimalNumber"
                       :label="$t('properties.step3.shareOfOwnershipCounter')"
                       v-model="item.shareOfOwnershipCounter"
                       placeholder=""
                       :maxLength="step3.maxFloatField"
                       :fixedLength="true"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :label="$t('properties.step3.shareOfOwnershipDenominator')"
                       v-model="item.shareOfOwnershipDenominator"
                       placeholder=""
                       :maxLength="7"/>
            <InputText class="mt-2 w-full"
                       v-if="calculatedAreaShow"
                       :label="$t('properties.step3.calculatedArea')"
                       v-model="item.calculatedArea"
                       placeholder=""
                       :disabled="true"
            />
            <InputSelect class="mt-2 w-full"
                         v-else
                         v-model="item.containedInArea"
                         :selected-value="item.containedInArea"
                         :label="$t('properties.step3.containedInArea')"
                         :options="contained_area_options"
                         placeholder=""
            />
          </div>
          <hr class=" mt-12 mb-4 hr-line-user"/>
        </div>

        <div class="flex justify-end">
          <div class="flex justify-center items-center cursor-pointer" @click="addMore">
            <img class="plus-icon-add-property-step3" src="@/assets/plus_circle_green.svg"/>
            <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more') }}</label>
          </div>
        </div>
      </div>
       <subArea  :federalStateUid="getStepAddress.federalStateUid" v-show="this.showSubAreaComp" ref="compSubArea"/>

      <div class="flex flex-col justify-start mt-12 mb-2">
        <label class="sub-heading">{{ $t('properties.step3.area_information_of_property') }}</label>
        <div class="box-no-margin-bottom flex justify-start items-center mt-3"
             v-show="getStepAddress.federalStateUid==='7'||getStepAddress.federalStateUid==='9'">
          <img class="image ml-2" src="@/assets/info_gray.svg"/>
          <label class="text-info ml-2">
            {{ $t('properties.step3.area_information_of_property_info_box') }}
            <br/>
            <br/>
            {{ $t('properties.step3.area_information_of_property_info_box_1') }}
          </label>
        </div>
        <div class="box flex justify-start items-center mt-3">
          <img class="image ml-2" src="@/assets/info_gray.svg"/>
          <label class="text-info ml-2">
            {{ $t('properties.step3.area_information_of_property_info_box_2') }}
          </label>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex justify-around space-x-10 mb-4">
          <InputText
              class="mt-2 w-full"
              v-model="step3.areaOfTheLand1"
              :label="$t('properties.step3.area_of_the_land_property')"
              placeholder=""
              :disabled="areaOfTheLandDisabled"
              :showHelpIconPopover="true"
              :popoverTexts="popoverTexts_area_of_the_land_property"
              :popoverLink="popoverLink5"
              :isFloat="isNiedesachenOrHessen"
              :isOnlyNumber="!isNiedesachenOrHessen"
              :decimalNumber="6"
              :isOnlyPositive="true"
              :maxValue="maxAreaOfTheLand"
          />
          <div class=" mt-2 w-full flex justify-center items-center">
            <InputText
                class=" w-full"
                v-model="step3.areaOfTheLandValue1"
                :label="$t('properties.step3.area_of_the_land_value_property')"
                placeholder=""
                :disabled="areaOfTheLandValueDisabled1"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_areaOfTheLandValue1"
                :popoverLink="popoverLink4"
                :maxLength="9"
                :decimalNumber="2"
                :isFloat="true"
            />
          </div>
        </div>

        <div class="flex justify-around space-x-10 mb-4" v-if="getStepAddress.federalStateUid==='7'">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
              <!--      help icon with HTML tooltip/popover-->
              <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                         :hideTriggers="['click']">
                <!-- This will be the popover target (for the events and position) -->
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <!-- This will be the content of the popover -->
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_outdoor_property"
                       v-bind:key="text">
                      {{ text }}
                    </p>
                  </div>
                  <!-- You can put other components too -->
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio" v-model="step3.locatedInOutdoorArea1"
                             name="radio-button"
                             value="1"/>
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio" v-model="step3.locatedInOutdoorArea1"
                             name="radio-button"
                             value="0"/>
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="secondAreaInfoShow === true" class="mb-4">
        <div class="flex justify-around space-x-10 mb-4">
          <InputText
              class="mt-2 w-full"
              v-model="step3.areaOfTheLand2"
              :label="$t('properties.step3.area_of_the_land_property')"
              placeholder=""
              :disabled="areaOfTheLandDisabled"
              :showHelpIconPopover="true"
              :popoverTexts="popoverTexts_area_of_the_land_property"
              :popoverLink="popoverLink5"
              :isFloat="isNiedesachenOrHessen"
              :isOnlyNumber="!isNiedesachenOrHessen"
              :decimalNumber="6"
              :isOnlyPositive="true"
              :maxValue="maxAreaOfTheLand"
          />
          <div class=" mt-2 w-full flex justify-center items-center">
            <InputText
                class=" w-full"
                :isFloat="true"
                v-model="step3.areaOfTheLandValue2"
                :label="$t('properties.step3.area_of_the_land_value_property')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverLink="popoverLink4"
                :maxLength="9"
                :decimalNumber="2"
            />
          </div>
        </div>

        <div class="flex justify-around space-x-10 mb-4" v-if="getStepAddress.federalStateUid==='7'">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
              <!--      help icon with HTML tooltip/popover-->
              <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                         :hideTriggers="['click']">
                <!-- This will be the popover target (for the events and position) -->
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <!-- This will be the content of the popover -->
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_outdoor_property"
                       v-bind:key="text">
                      {{ text }}
                    </p>
                  </div>
                  <!-- You can put other components too -->
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio" v-model="step3.locatedInOutdoorArea2"
                             name="radio-button"
                             value="1"/>
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio" v-model="step3.locatedInOutdoorArea2"
                             name="radio-button"
                             value="0"/>
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-start mt-2 mb-2" v-if="secondAreaInfoShow === true">
        <div class="flex justify-end">
          <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideSecondAreaInfo"/>
        </div>
      </div>

      <div v-if="thirdAreaInfoShow === true" class="mb-4">
        <div class="flex justify-around space-x-10 mb-4">
          <InputText class="mt-2 w-full"
                     v-model="step3.areaOfTheLand3"
                     :label="$t('properties.step3.area_of_the_land_property')"
                     placeholder=""
                     :disabled="areaOfTheLandDisabled"
                     :showHelpIconPopover="true"
                     :popoverTexts="popoverTexts_area_of_the_land_property"
                     :popoverLink="popoverLink5"
                     :isFloat="isNiedesachenOrHessen"
                     :isOnlyNumber="!isNiedesachenOrHessen"
                     :decimalNumber="6"
                     :isOnlyPositive="true"
                     :maxValue="maxAreaOfTheLand"
                     :onPasteAction="true"
          />
          <div class=" mt-2 w-full flex justify-center items-center">
            <InputText class=" w-full"
                       :isRegexFloat="true"
                       v-model="step3.areaOfTheLandValue3"
                       :label="$t('properties.step3.area_of_the_land_value_property')"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverLink="popoverLink4"
                       :isFloat="isNiedesachenOrHessen"
                       :decimalNumber="6"
                       :maxValue="maxAreaOfTheLand"
                       :isOnlyPositive="true"
            />
          </div>
        </div>

        <div class="flex justify-around space-x-10 mb-4" v-if="getStepAddress.federalStateUid==='7'">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
              <VDropdown :showTriggers="['click','hover']"
                         :popperTriggers="['hover','click']"
                         :hideTriggers="['click']"
              >
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text"
                       v-for="text in popoverTexts_outdoor_property"
                       v-bind:key="text"
                    >
                      {{ text }}
                    </p>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio"
                             v-model="step3.locatedInOutdoorArea3"
                             name="radio-button"
                             value="1"
                />
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio"
                             v-model="step3.locatedInOutdoorArea3"
                             name="radio-button"
                             value="0"
                />
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-start mt-2 mb-2" v-if="thirdAreaInfoShow === true">
        <div class="flex justify-end">
          <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideThirdAreaInfo"/>
        </div>
      </div>

      <div v-if="fourthAreaInfoShow === true" class="mb-4">
        <div class="flex justify-around space-x-10 mb-4">
          <InputText class="mt-2 w-full"
                     v-model="step3.areaOfTheLand4"
                     :label="$t('properties.step3.area_of_the_land_property')"
                     placeholder=""
                     :disabled="areaOfTheLandDisabled"
                     :showHelpIconPopover="true"
                     :popoverTexts="popoverTexts_area_of_the_land_property"
                     :popoverLink="popoverLink5"
                     :isFloat="isNiedesachenOrHessen"
                     :decimalNumber="6"
                     :maxValue="maxAreaOfTheLand"
                     :isOnlyPositive="true"
          />
          <div class=" mt-2 w-full flex justify-center items-center">
            <InputText class=" w-full"
                       :isRegexFloat="true"
                       v-model="step3.areaOfTheLandValue4"
                       :label="$t('properties.step3.area_of_the_land_value_property')"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverLink="popoverLink4"
                       :isFloat="isNiedesachenOrHessen"
                       :decimalNumber="6"
                       :maxValue="maxAreaOfTheLand"
                       :isOnlyPositive="true"
            />
          </div>
        </div>

        <div class="flex justify-around space-x-10 mb-4" v-if="getStepAddress.federalStateUid==='7'">
          <div class=" flex flex-col w-full">
            <div class="flex">
              <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
              <VDropdown :showTriggers="['click','hover']"
                         :popperTriggers="['hover','click']"
                         :hideTriggers="['click']"
              >
                <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text"
                       v-for="text in popoverTexts_outdoor_property"
                       v-bind:key="text"
                    >
                      {{ text }}
                    </p>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio"
                             v-model="step3.locatedInOutdoorArea4"
                             name="radio-button"
                             value="1"
                />
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center relative">
                <RadioButton type="radio"
                             v-model="step3.locatedInOutdoorArea4"
                             name="radio-button"
                             value="0"
                />
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-start mt-2 mb-2" v-if="fourthAreaInfoShow === true">
        <div class="flex justify-end">
          <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideFourthAreaInfo"/>
        </div>
      </div>

      <hr class=" mt-3 mb-4 hr-line-user"
          v-if="(secondAreaInfoShow === false || thirdAreaInfoShow === false || fourthAreaInfoShow === false) && addMoreAreaLinkShow === true"
      />
      <div class="flex justify-end" v-if="secondAreaInfoShow === false && addMoreAreaLinkShow === true">
        <div class="flex justify-center items-center cursor-pointer" @click="showSecondAreaInfo">
          <img class="plus" src="@/assets/plus_circle_green.svg"/>
          <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
        </div>
      </div>

      <div class="flex justify-end"
           v-if="(getStepAddress.federalStateUid === '7' || getStepAddress.federalStateUid === '9') && thirdAreaInfoShow === false && secondAreaInfoShow === true && addMoreAreaLinkShow === true"
      >
        <div class="flex justify-center items-center cursor-pointer" @click="showThirdAreaInfo">
          <img class="plus" src="@/assets/plus_circle_green.svg"/>
          <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
        </div>
      </div>

      <div class="flex justify-end"
           v-if="(getStepAddress.federalStateUid === '7' || getStepAddress.federalStateUid === '9') && fourthAreaInfoShow === false && thirdAreaInfoShow === true && secondAreaInfoShow === true && addMoreAreaLinkShow === true"
      >
        <div class="flex justify-center items-center cursor-pointer" @click="showFourthAreaInfo">
          <img class="plus" src="@/assets/plus_circle_green.svg"/>
          <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
        </div>
      </div>

      <div class="flex justify-around space-x-10 mt-6 ">
        <div class="w-full invisible"/>
        <div class="flex justify-end space-x-4 mt-6 mr-6 pb-20">
          <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
          <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputText from "../../inputs/InputText";
import InputSelect from "../../inputs/InputSelect";
import RadioButton from 'primevue/radiobutton';
import {PropertyService} from "@/services/property.service";
import Tooltip from 'primevue/tooltip';
import subArea from "@/components/properties/components/subArea";
import {UserService} from "../../../services/user.service";

export default {
  name: "StepPlot",
  components: {InputSelect, InputText, RadioButton, subArea},
  directives: {
    'tooltip': Tooltip
  },
  props: {
    stepIndex: {
      type: Number,
      default: 3
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new PropertyService(),
      userService: new UserService(),
      popoverTexts: [
        this.$t('properties.step3.tooltip.tooltip_client_text1'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts2: [
        this.$t('properties.step3.tooltip.tooltip_landRegisterSheet'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts3: [
        this.$t('properties.step3.tooltip.tooltip_client_text4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_areaOfTheLandValue1: [
        this.$t('properties.step3.tooltip.tooltip_client_text_areaOfTheLandValue1_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text_areaOfTheLandValue1_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_area_of_the_land_property: [
        this.$t('properties.step3.tooltip.tooltip_client_area_of_the_land_property'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts7: [
        this.$t('properties.step3.tooltip.tooltip_client_text8_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text8_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts8: [
        this.$t('properties.step3.tooltip.tooltip_client_text9_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text9_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_multiple_communities: [
        this.$t('properties.step3.tooltip.tooltip_client_text10'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts11: [
        this.$t('properties.step3.tooltip.tooltip_client_text12'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts12: [
        this.$t('properties.step3.tooltip.tooltip_client_text13'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts13: [
        this.$t('properties.step3.tooltip.tooltip_client_text14'),
        this.$t('properties.step3.tooltip.tooltip_client_text15')
      ],
      popoverTexts_outdoor_property: [
        this.$t('properties.step3.tooltip.tooltip_client_text16')
      ],
      popoverTexts_step3_corridor: [
        this.$t('properties.step3.tooltip.tooltip_corridor_1'),
        this.$t('properties.step3.tooltip.tooltip_corridor_2'),
        this.$t('properties.step3.tooltip.tooltip_corridor_3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_counter: [
        this.$t('properties.step3.tooltip.tooltip_counter_1'),
        this.$t('properties.step3.tooltip.tooltip_counter_2'),
        this.$t('properties.step3.tooltip.tooltip_counter_3'),
        this.$t('properties.step3.tooltip.tooltip_counter_4'),
        this.$t('properties.step3.tooltip.tooltip_counter_5'),
        this.$t('properties.step3.tooltip.tooltip_counter_6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_denominator: [
        this.$t('properties.step3.tooltip.tooltip_denominator_1'),
        this.$t('properties.step3.tooltip.tooltip_denominator_2'),
        this.$t('properties.step3.tooltip.tooltip_denominator_3'),
        this.$t('properties.step3.tooltip.tooltip_denominator_4'),
        this.$t('properties.step3.tooltip.tooltip_denominator_5'),
        this.$t('properties.step3.tooltip.tooltip_denominator_6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_shareOfOwnershipCounter: [
        this.$t('properties.step3.tooltip.tooltip_shareOfOwnershipCounter'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_shareOfOwnershipDenominator: [
        this.$t('properties.step3.tooltip.tooltip_shareOfOwnershipDenominator'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverLink: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
      popoverLink2: "https://hilfe.grundsteuer-digital.de/faq/grundbuchblatt/",
      popoverLink3: "https://hilfe.grundsteuer-digital.de/faq/gemarkung/",
      popoverLink4: "https://hilfe.grundsteuer-digital.de/faq/bodenrichtwert/",
      popoverLink5: "https://hilfe.grundsteuer-digital.de/faq/grundstuecksflaeche-in-m%c2%b2/",
      popoverLink7: "https://hilfe.grundsteuer-digital.de/faq/art-des-grundstuecks/",
      popoverLink8: "https://hilfe.grundsteuer-digital.de/faq/eigentumsverhaeltnisse/ ",
      popoverLink_foreign_land: "https://hilfe.grundsteuer-digital.de/faq/gebaeude-auf-fremden-grund/",
      popoverLink_lease_hold: "https://hilfe.grundsteuer-digital.de/faq/erbbaurecht/",
      popoverLink_multiple_communities: "https://hilfe.grundsteuer-digital.de/faq/grundbesitz-ueber-mehrere-gemeinden/",
      popoverLink_step3_corridor: "https://hilfe.grundsteuer-digital.de/faq/flur/",
      popoverLink_step3_counter: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_step3_denominator: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_step3_shareOfOwnershipCounter: "https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/",
      popoverLink_step3_shareOfOwnershipDenominator: "https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/",

      ownership_structure: this.$t('properties.step3.ownership_structure_options'),
      property_type: [],
      development_state: this.$t('properties.step3.development_state_options'),
      contained_area_options: this.$t('properties.step3.contained_area_options'),
      taxModelObj: [],
      taxModels: [
        {
          model: "Bundesmodell",
          stateCodes: ["3", "4", "5", "8", "10", "11", "12", "13", "15", "16"]
        },
        {
          model: "Modifiziertes Bodenwertmodell",
          stateCodes: ["1"]
        },
        {
          model: "Flächen-Modell",
          stateCodes: ["2"]
        },
        {
          model: "Wohnlagen-Modell",
          stateCodes: ["6"]
        },
        {
          model: "Flächen-Faktor-Modell",
          stateCodes: ["7"]
        },
        {
          model: "Flächen-Lage-Modell",
          stateCodes: ["9"]
        },
        {
          model: "Modifiziertes Bodenwertmodell",
          stateCodes: ["14"]
        }
      ],

      step3: {
        ownershipStructure: "-1",
        propertyType: "0",
        developmentState: '',
        multiCommunities: false,
        leasehold: true,
        buildingOnForeignGround: true,
        buildingsOnThirdPartyOwners: true,
        propertyResidentialPurposes: true,
        areaOfTheLand1: '',
        areaOfTheLandValue1: '',
        locatedInOutdoorArea1: '0',
        areaOfTheLand2: '',
        areaOfTheLandValue2: '',
        areaOfTheLandTotal: '',
        locatedInOutdoorArea2: '0',
        areaOfTheLand3: '',
        areaOfTheLandValue3: '',
        locatedInOutdoorArea3: '0',
        areaOfTheLand4: '',
        areaOfTheLandValue4: '',
        locatedInOutdoorArea4: '0',
        benefit: '',
        maxlength: 0,
        maxFloatField: 0,
        decimalNumber: 0,
        liftingRate: '',
        totalPropertyPart: '',
        registrationLandRegistry: ''
      },
      parcels: [{
        community: '',
        parcel: '',
        landRegisterSheet: '',
        corridor: '',
        parcelDataCounter: '',
        parcelDataDenominator: '',
        areaOfTheLand: '',
        shareOfOwnershipCounter: '',
        shareOfOwnershipDenominator: '',
        containedInArea: '',
        calculatedArea: 0
      }],
      lastStep: true,

      showInfoDlg: false,
      infoMessage: '',
      loading1: false,
      btnDisabled1: false,
      loading2: false,
      btnDisabled2: false,

      propertyTypeDisabled: false,
      developmentStateDisabled: false,
      areaOfTheLandDisabled: false,
      areaOfTheLandValueDisabled1: false,
      areaOfTheLandValueDisabled2: false,
      secondAreaInfoShow: false,
      thirdAreaInfoShow: false,
      fourthAreaInfoShow: false,
      addMoreAreaLinkShow: true,
      showSubAreaComp: false,

      registrationLandRegistryDisabled: false,

      leaseholdDisabled: false,
      buildingOnForeignGroundDisabled: false,
      buildingsOnThirdPartyOwnersDisabled: false,
      propertyResidentialPurposesDisabled: false,
      totalPropertyPartDisabled: false,
      corridorDisabled: false,

      address_info_box: false,
      grundstucksadresse_info_box: '',

      toggleDataAcquisitionModal: false,
      toggleDataAcquisitionLoader: false,
      toggleDataAcquisitionAboRequired: false,
      toggleDataAcquisitionModalOverLimit: false,
      toggleDataAcquisitionModalServiceNotActive: false,
      showFeatureForBWModal: false,
      userLimitAmount: 0,
      communityList: [],
      parcelList: [],

      districtShow: true,
    }
  },
  mounted() {
    this.initialize();
    this.setMaxFieldLength();
    this.lastStep = this.isLast;
  },
  computed: {
    ...mapGetters("property", ["getSteps", "getStepAddress", "getStepPlot", "getAvailableFlowSteps", "getStepDetails"]),
    isExempted() {
      return this.getStepDetails && this.getStepDetails.exemption === "1";
    },
    isNiedesachenOrHessen() {
      return ['7', '9'].includes(this.getStepAddress.federalStateUid);
    },
    calculatedAreaShow() {
      return ['7', '9'].includes(this.getStepAddress.federalStateUid);
    },
    maxAreaOfTheLand() {
      return ['7', '9'].includes(this.getStepAddress.federalStateUid) ? 999999999.99999999 : 999999999;
    },
  },
  watch: {
    getStepAddress: {
      handler() {
        this.setMaxFieldLength();
        this.initSelectOpts();
        this.updateDisabledStatus();

        if (this.parcels[0].community === '') {
          this.parcels[0].community = this.getStepAddress.community;
        }
      },
      deep: true
    },
    getStepDetails: {
      handler() {
        this.updateDisabledStatus();
      },
      deep: true
    },
    parcels : {
      handler(item) {
        item.forEach((parcel, index) => {
          const areaOfTheLand = parcel.areaOfTheLand;
          const counter = parseFloat(parcel.shareOfOwnershipCounter.replace(',', '.'));
          const denominator = parseFloat(parcel.shareOfOwnershipDenominator.replace(',', '.'));

          const calcArea = areaOfTheLand * (counter/denominator);

          item[index].calculatedArea = isNaN(calcArea) ? 0 : calcArea.toLocaleString('DE');
        });

      },
      deep: true
    },
    step3: {
      handler() {
        this.updateDisabledStatus();
        this.buildingsOnThirdPartyOwnersChangeHandler();
      },
      deep: true
    },
    getAvailableFlowSteps(val) {
      let index = val.findIndex(item => item.component === 'StepPlot');
      if (index) {
        this.lastStep = val[index].last;
      }
    },
    getSteps: {
      deep: true,
      handler() {
        if (this.getSteps.deviatingOwner.firstName !== '' && (this.step3.ownershipStructure === '-1' || this.step3.ownershipStructure === 0)) {
          this.step3.ownershipStructure = '0';
        }
      }
    }
  },
  methods: {
    ...mapMutations("property", ["saveStepPlot", "saveStepPlotParcels", "saveSubArea"]),
    initialize() {
      this.step3.ownershipStructure = this.getStepPlot.ownershipStructure;
      this.step3.propertyType = this.getStepPlot.propertyType;
      this.step3.developmentState = this.getStepPlot.developmentState;
      this.step3.multiCommunities = this.getStepPlot.multiCommunities;
      this.step3.leasehold = this.getStepPlot.leasehold;
      this.step3.buildingOnForeignGround = this.getStepPlot.buildingOnForeignGround;
      this.step3.buildingsOnThirdPartyOwners = this.getStepPlot.buildingsOnThirdPartyOwners;
      this.step3.areaOfTheLand1 = this.getStepPlot.areaOfTheLand1;
      this.step3.areaOfTheLandValue1 = this.getStepPlot.areaOfTheLandValue1;
      this.step3.areaOfTheLandTotal = this.getStepPlot.areaOfTheLandTotal;
      this.step3.locatedInOutdoorArea1 = this.getStepPlot.locatedInOutdoorArea1;
      this.step3.areaOfTheLand2 = this.getStepPlot.areaOfTheLand2;
      this.step3.areaOfTheLandValue2 = this.getStepPlot.areaOfTheLandValue2;
      this.step3.locatedInOutdoorArea2 = this.getStepPlot.locatedInOutdoorArea2;
      this.step3.liftingRate = this.getStepPlot.liftingRate;
      this.step3.propertyResidentialPurposes = this.getStepPlot.propertyResidentialPurposes;
      this.step3.areaOfTheLand3 = this.getStepPlot.areaOfTheLand3;
      this.step3.areaOfTheLandValue3 = this.getStepPlot.areaOfTheLandValue3;
      this.step3.locatedInOutdoorArea3 = this.getStepPlot.locatedInOutdoorArea3;
      this.step3.areaOfTheLand4 = this.getStepPlot.areaOfTheLand4;
      this.step3.areaOfTheLandValue4 = this.getStepPlot.areaOfTheLandValue4;
      this.step3.locatedInOutdoorArea4 = this.getStepPlot.locatedInOutdoorArea4;

      if (this.getStepPlot.parcels.length > 0) {
        for (var i = 0; i < this.getStepPlot.parcels.length; i++) {
          this.parcels.push(this.getStepPlot.parcels[i]);
        }
      }

      this.updateDisabledStatus();
      this.buildingsOnThirdPartyOwnersChangeHandler();
    },
    initSelectOpts() {
      if (parseInt(this.getStepAddress.federalStateUid) === 2 || parseInt(this.getStepAddress.federalStateUid) === 6) {
        this.step3.areaOfTheLandValue1 = '';
        this.areaOfTheLandValueDisabled1 = true;
        this.btnDisabled1 = true;

        this.secondAreaInfoShow = false;
        this.addMoreAreaLinkShow = false;
        this.thirdAreaInfoShow = false;
        this.fourthAreaInfoShow = false;
      } else {
        this.areaOfTheLandValueDisabled1 = false;
        this.btnDisabled1 = false;

        this.addMoreAreaLinkShow = true;
      }

      if (parseInt(this.getStepAddress.federalStateUid) === 2 || parseInt(this.getStepAddress.federalStateUid) === 6 || parseInt(this.getStepAddress.federalStateUid) === 9) {
        this.buildingsOnThirdPartyOwnersDisabled = false;
      } else {
        this.buildingsOnThirdPartyOwnersDisabled = true;
      }

      if (parseInt(this.getStepDetails.economicEntityType) === 3) {
        this.step3.areaOfTheLand1 = '';
        this.step3.areaOfTheLand2 = '';
        this.step3.areaOfTheLand3 = '';
        this.step3.areaOfTheLand4 = '';
        this.areaOfTheLandDisabled = true;
      }

      // Get State Tax Model
      this.taxModelObj = this.taxModels.filter(item => {
        if (item.stateCodes.indexOf(this.getStepAddress.federalStateUid) != -1) {
          return item;
        }
      });

      if (this.property_type.length === 0) {
        this.property_type = this.$t('properties.step3.property_type_options.Bundesmodell');
        if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
          if (this.taxModelObj[0].model !== '') {
            this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
          }
        }
      }

      if (this.getStepAddress.federalStateName) {
        this.communityList = [];
        this.$api.get_community_list(this.getStepAddress.federalStateName).then((list) => {
          this.communityList = list;
        });

        this.parcelList = [];
        this.$api.get_gemarkungen_list(this.getStepAddress.federalStateName).then((list) => {
          this.parcelList = list;
        });
      }

      this.addressInfoBox();
    },
    changeParcelList(item) {
      this.parcelList = [];

      this.$api.get_gemarkungen_list(this.getStepAddress.federalStateName, item.code).then((list) => {
        this.parcelList = list;
      });
    },
    updateDisabledStatus() {

      this.districtShow = true;

      this.registrationLandRegistryDisabled = this.service.isRegistrationLandRegistryDisabled(
          this.getStepDetails.economicEntityType,
          this.step3.propertyType,
          this.getStepAddress.federalStateUid
      );

      this.propertyTypeDisabled = this.service.isPropertyTypeDisabled(
          this.getStepDetails.economicEntityType,
          this.getStepAddress.federalStateUid
      );

      this.developmentStateDisabled = false;
      this.buildingOnForeignGroundDisabled = false;
      this.propertyResidentialPurposesDisabled = false;
      this.totalPropertyPartDisabled = false;
      this.corridorDisabled = false;
      this.leaseholdDisabled = false;

      this.areaOfTheLandDisabled = false;
      this.areaOfTheLandValueDisabled1 = false;
      this.btnDisabled1 = false;
      this.addMoreAreaLinkShow = true;

      if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
        this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
      }

      if (parseInt(this.getStepAddress.federalStateUid) === 1) {
        if (parseInt(this.getStepDetails.economicEntityType) === 1) {
          this.buildingOnForeignGroundDisabled = true;
          this.totalPropertyPartDisabled = true;
        }
      } else {
        if (parseInt(this.getStepDetails.economicEntityType) === 2) {
          if (parseInt(this.getStepAddress.federalStateUid) === 6) {
            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.thirdAreaInfoShow = false;
            this.fourthAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;
          } else if (parseInt(this.getStepAddress.federalStateUid) === 7) {
            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
          } else if (parseInt(this.getStepAddress.federalStateUid) === 2) {
            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.thirdAreaInfoShow = false;
            this.fourthAreaInfoShow = false;
            this.propertyResidentialPurposesDisabled = true;
            if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
              this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
            }
          } else if (parseInt(this.getStepAddress.federalStateUid) === 9) {
            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;

            if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
              this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
            }
          } else {
            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
            if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
              this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
            }
          }
        } else if (parseInt(this.getStepDetails.economicEntityType) === 1) {
          if (parseInt(this.getStepAddress.federalStateUid) === 6) {
            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.thirdAreaInfoShow = false;
            this.fourthAreaInfoShow = false;
            this.propertyResidentialPurposesDisabled = true;
          } else if (parseInt(this.getStepAddress.federalStateUid) === 2) {
            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.thirdAreaInfoShow = false;
            this.fourthAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;
          } else if (parseInt(this.getStepAddress.federalStateUid) === 7) {
            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
          } else if (parseInt(this.getStepAddress.federalStateUid) === 9) {
            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
          } else {
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
            if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
              this.property_type = this.$t('properties.step3.property_type_options.' + this.taxModelObj[0].model);
            }
            this.step3.propertyType = '1';
          }
        }
      }

      if (parseInt(this.getStepDetails.economicEntityType) === 3) {
        this.developmentStateDisabled = true;
        this.leaseholdDisabled = true;
        this.propertyResidentialPurposesDisabled = true;
        this.totalPropertyPartDisabled = true;
        this.buildingOnForeignGroundDisabled = true;
        this.areaOfTheLandDisabled = true;
        this.areaOfTheLandValueDisabled1 = true;
        this.btnDisabled1 = true;
        this.secondAreaInfoShow = false;
        this.addMoreAreaLinkShow = false;

        this.districtShow = false;
      }

      if (parseInt(this.getStepAddress.federalStateUid) === 2 || parseInt(this.getStepAddress.federalStateUid) === 6) {
        this.corridorDisabled = true;
      }
      if (parseInt(this.getStepAddress.federalStateUid) !== 7 && parseInt(this.getStepAddress.federalStateUid) !== 10) {
        this.landRegisterSheetDisabled = true;
      }
      this.showSubAreaComp = [3, 4, 5, 8, 10, 11, 12, 13, 14, 15, 16].includes(parseInt(this.getStepAddress.federalStateUid, 10));
      this.addressInfoBox();
    },

    buildingsOnThirdPartyOwnersChangeHandler() {
      this.districtShow = true;
      this.areaOfTheLandDisabled = false;

      if (parseInt(this.getStepDetails.economicEntityType) === 3) {
        this.districtShow = false;
        this.areaOfTheLandDisabled = true;
      }

      if (parseInt(this.step3.buildingsOnThirdPartyOwners) > 0) {
        this.districtShow = false;

        if ([2, 6, 9].includes(parseInt(this.getStepAddress.federalStateUid)) && parseInt(this.step3.leasehold) > 0) {
          this.areaOfTheLandDisabled = false;
        } else {
          this.step3.areaOfTheLand1 = '';
          this.areaOfTheLandDisabled = true;

          if (this.secondAreaInfoShow) {
            this.step3.areaOfTheLand2 = '';
          }
        }
      }
    },

    addressInfoBox() {
      if (this.service.canShowStepNonResidential(this.getStepAddress.federalStateUid)) {
        this.address_info_box = true;
        /*if (this.getStepAddress.federalStateUid == '2') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_bayern');
        } else if (this.getStepAddress.federalStateUid == '6') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_hamburg');
        } else if (this.getStepAddress.federalStateUid == '9') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_niedersachsen');
        }*/
      } else {
        this.address_info_box = false;
      }
    },
    addMore() {
      this.parcels.push({
        community: this.getStepAddress.community,
        parcel: '',
        landRegisterSheet: '',
        corridor: '',
        parcelDataCounter: '',
        parcelDataDenominator: '',
        areaOfTheLand: '',
        shareOfOwnershipCounter: '',
        shareOfOwnershipDenominator: '',
        containedInArea: '',
        calculatedArea: 0,
      });
    },
    handleDelete(index) {
      this.parcels.splice(index, 1)
    },
    submitNext() {
      this.showInfo = false;

      this.saveStepPlot(this.step3);
      this.saveStepPlotParcels(this.parcels);
      if (this.$refs.compSubArea) {
        this.saveSubArea(this.$refs.compSubArea.getData());
      }

      if (this.lastStep === true) {
        this.$emit("finish");
      } else {
        this.$emit("goToNextStep");
      }
    },
    goBack() {
      this.showInfo = false;

      this.$emit("goBack");
    },
    closeInfoDlg() {
      this.showInfoDlg = false;
    },
    setMaxFieldLength() {
      const arr = ['2', '6', '7', '9'];
      this.step3.maxlength = arr.includes(this.getStepAddress.federalStateUid) ? 9 : 15;
      this.step3.maxFloatField = arr.includes(this.getStepAddress.federalStateUid) ? 11 : 10;
      this.step3.decimalNumber = arr.includes(this.getStepAddress.federalStateUid) ? 5 : 4;
    },
    async openSkenDataModal() {
      const isTrialAccount = this.$store.state.user.currentUser.account_pay_type === 'trial';
      const isDataAcquisitionActive = parseInt(this.$store.state.user.currentUser.settings.data_acquisition) === 1;
      this.editSkendataParcelId = 0;

      if (isTrialAccount) {
        this.toggleDataAcquisitionAboRequired = true;
        return false;
      }

      if (!isDataAcquisitionActive) {
        this.toggleDataAcquisitionModalServiceNotActive = true;
        return false;
      }

      const dataAcquisitionData = await this.userService.getDataAcquisitionData();
      if (dataAcquisitionData === []) {
        this.$toast.error(this.$t('general.errors.unknownError'));

        return false;
      }

      const userCostsLimit = parseFloat(this.$store.getters['user/getCurrentUser'].settings.data_acquisition_limit_amount.replace(',', '.'));
      this.userLimitAmount = new Intl.NumberFormat(
        "de-DE",
        {style: 'currency', currency: 'EUR'}
      ).format(userCostsLimit);
      const isCostsLimitActive = parseInt(this.$store.state.user.currentUser.settings.data_acquisition_limit) === 1;
      const userMonthCosts = parseInt(dataAcquisitionData.total_costs);

      if (isCostsLimitActive && (userCostsLimit <= userMonthCosts)) {
        this.toggleDataAcquisitionModalOverLimit = true;
        this.toggleDataAcquisitionModal = false;
        return false;
      }

      if (parseInt(this.getStepAddress.federalStateUid) === 1) {
        this.showFeatureForBWModal = true;
      } else {
        this.toggleDataAcquisitionModal = true;
      }

      return true;
    },
    confirmOpenDataAacquisitionAPI() {
      localStorage.setItem("AddSkenDataToProperty", 1);
      this.$emit("finish");
    },
    hideSecondAreaInfo() {
      this.secondAreaInfoShow = false;
      this.step3.areaOfTheLand2 = '';
      this.step3.areaOfTheLandValue2 = '';
      this.step3.locatedInOutdoorArea2 = '0';
    },
    showSecondAreaInfo() {
      this.step3.areaOfTheLand2 = '';
      this.step3.areaOfTheLandValue2 = '';
      this.step3.locatedInOutdoorArea2 = '0';
      this.secondAreaInfoShow = true;
    },
    hideThirdAreaInfo() {
      this.thirdAreaInfoShow = false;
      this.step3.areaOfTheLand3 = '';
      this.step3.areaOfTheLandValue3 = '';
      this.step3.locatedInOutdoorArea3 = '0';
    },
    showThirdAreaInfo() {
      this.step3.areaOfTheLand3 = '';
      this.step3.areaOfTheLandValue3 = '';
      this.step3.locatedInOutdoorArea3 = '0';
      this.thirdAreaInfoShow = true;
    },
    hideFourthAreaInfo() {
      this.fourthAreaInfoShow = false;
      this.step3.areaOfTheLand4 = '';
      this.step3.areaOfTheLandValue4 = '';
      this.step3.locatedInOutdoorArea4 = '0';
    },
    showFourthAreaInfo() {
      this.step3.areaOfTheLand4 = '';
      this.step3.areaOfTheLandValue4 = '';
      this.step3.locatedInOutdoorArea4 = '0';
      this.fourthAreaInfoShow = true;
    }
  }
}
</script>

<style scoped lang="scss">

.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.info-msg {
  background-color: #f2f2f2;
  display: flex;
  align-items: flex-start;
}

.add-more-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.delete-icon {
  cursor: pointer;
  height: 25px;
}

.title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;

}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
  margin-bottom: 15px;
}

.sub-heading {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.plus-icon-add-property-step3 {
  width: 29px;
  height: 29px;
  box-sizing: border-box;

}

.btext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: right;
  line-height: 20px;
  font-size: 15px;
}

.max-width-half {
  max-width: calc(50% - (20px));
}

.labelBoris {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 14px;
}

a:link {
  color: theme('colors.muted_black') !important;
  text-decoration: underline;
}

.tooltip-content {
  background-color: $primary;
  border-radius: 5px;
  padding: 10px;
  max-width: 350px;
  display: inline-table;
  text-align: left;
  color: white;
}


.help_icon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.popover-text {
  font-size: 13px;
  font-family: 'Segoe UI', sans-serif;
  color: #ffffff;
  text-align: left;
}

.button-external {
  width: 296px;
  height: 32px;
  padding: 8px;
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: center;
  line-height: 20px;
  text-decoration: none !important;
}

.text-info {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.box {
  height: auto;
  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px !important;

  .text {
    margin-bottom: 0 !important;
  }
}
.box-no-margin-bottom {
  @extend .box;
  margin-bottom: 0 !important;
}
</style>
