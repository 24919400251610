<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.popup.powers_of_attorney')" :breadcrumbs="breadcrumbs"/>

    <div class="mr-7">
      <AppGrid :grid-id="gridId"
               data-endpoint="powerofattorney/list"
               :columns="columns"
               default-sort-attr="firstName"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="id"
               :standard-filters="standardFilters"
               :show-filter="true"
               :menus="menus"
               @col-click="handleColClick"
               @menu-clicked="handleMenuClick"
               @create-new="createNew"
    />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {PowersOfAttorneyService} from "../../services/powersofattorney.service";
import {mapMutations} from "vuex";

export default {
  name: "powersofattorney",
  components: {
    AppGrid
  },
  data() {
    return {
      powersOfAttorneyService: new PowersOfAttorneyService(),
      gridId: "powersOfAttorney",
      selectedColumns: null,
      standardFilters: [],
      columns: [{
        field: 'salutationLbl',
        filterField: 'salutation',
        header: this.$t('settings.powers_of_attorney.table.salutation'),
        isChecked: true,
        elType: 'select',
        filterOptions: this.$t('settings.powers_of_attorney.salutation_options')
      }, {
        field: 'title',
        header: this.$t('settings.powers_of_attorney.table.title'),
        filterLbl: this.$t('settings.powers_of_attorney.table.title'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'phone',
        header: this.$t('settings.powers_of_attorney.table.phone'),
        filterLbl: this.$t('settings.powers_of_attorney.table.phone'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'firstName',
        header: this.$t('settings.powers_of_attorney.table.first_name'),
        filterLbl: this.$t('settings.powers_of_attorney.table.first_name'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'lastName',
        header: this.$t('settings.powers_of_attorney.table.last_name'),
        filterLbl: this.$t('settings.powers_of_attorney.table.last_name'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'street',
        header: this.$t('settings.powers_of_attorney.table.street'),
        filterLbl: this.$t('settings.powers_of_attorney.table.street'),
        isChecked: false,
        elType: 'text'
      }, {
        field: 'houseNumber',
        header: this.$t('settings.powers_of_attorney.table.house_number'),
        filterLbl: this.$t('settings.powers_of_attorney.table.house_number'),
        isChecked: false,
        elType: 'text'
      }, {
        field: 'zip',
        header: this.$t('settings.powers_of_attorney.table.zip'),
        filterLbl: this.$t('settings.powers_of_attorney.table.zip'),
        isChecked: false,
        elType: 'text'
      }, {
        field: 'city',
        header: this.$t('settings.powers_of_attorney.table.city'),
        filterLbl: this.$t('settings.powers_of_attorney.table.city'),
        isChecked: false,
        elType: 'text'
      }, {
        field: 'countryName',
        header: this.$t('settings.powers_of_attorney.table.country_uid'),
        filterLbl: this.$t('settings.powers_of_attorney.table.country_uid'),
        isChecked: false,
        elType: 'select',
        filterOptions: [],
        filterableSelect: true,
        sortable: false
      }, {
        field: 'postbox',
        header: this.$t('settings.powers_of_attorney.table.postbox'),
        filterLbl: this.$t('settings.powers_of_attorney.table.postbox'),
        isChecked: false,
        elType: 'text'
      }],
      breadcrumbs: [
        {
          title: this.$t('settings.powers_of_attorney.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.powers_of_attorney.menu.powers_of_attorney'),
          link: ''
        }
      ],
      menus: [
        {
          id: "power_of_attorney.add",
          "icon": this.getAssetPath('add_branch_green.svg'),
          "title": this.$t('settings.powers_of_attorney.menu.add_power_of_attorney')
        },
        {
          id: "power_of_attorney.edit",
          "icon": this.getAssetPath('edit_branch_green.svg'),
          "icon_disabled": this.getAssetPath('edit_branch_gray.svg'),
          "title": this.$t('settings.powers_of_attorney.menu.edit_power_of_attorney'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": false,
        },
        {
          id: "power_of_attorney.delete",
          "icon": this.getAssetPath('delete_green.svg'),
          "icon_disabled": this.getAssetPath('delete_gray.svg'),
          "title": this.$t('settings.powers_of_attorney.menu.delete_power_of_attorney'),
          "action_endpoint": 'powerofattorney/delete',
          "callback": () => {
            this.setPowersOfAttorneyList([]);
          },
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": false,
        },
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh'),
        }
      ]
    }
  },
  beforeMount() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field === 'countryName') {
          this.columns[i].filterOptions = this.countries;
        }
      }
    });
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations('grid', ["triggerReload"]),
    ...mapMutations('power_of_attorney', ["init", "setPowersOfAttorneyList"]),
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'power_of_attorney.add':
          this.$router.push('/powerofattorney/addPowerOfAttorney')
          break;

        case 'power_of_attorney.edit':
          if (args.data[0].uid) {
            this.$router.push("/powerofattorney/editPowerOfAttorney?uid=" + args.data[0].uid)
          }
          break;

        default:
          this.$emit('click', args.menuId)
      }
    },
    handleColClick(e) {
      if((e.field === 'firstName' || e.field === 'lastName') && parseInt(e.row.uid) > 0) {
        this.$router.push("/powerofattorney/editPowerOfAttorney?uid=" + parseInt(e.row.uid));
      }
    },
    createNew() {
      this.$router.push('/powerofattorney/addPowerOfAttorney');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

</style>
