<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component mt-4"> Grundstück hinzufügen</p>
    <hr class=" hr-line-user  mr-7 mb-1 mt-8 "/>

    <div class=" flex flex-col justify-center items-center ml-15 mr-15 mt-20">
      <div class="finish-check-icon-wrapper">
        <img src="@/assets/check_done.svg"/>
      </div>
      <label class="text-finish ">Neues Grundstück wurde hinzugefügt.</label>
      <Button @click="submit" class=" mt-10 "
              text="Grundstücke anzeigen"></Button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "FinishProperty",
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Grundstücke  Wirtschaftliche Einheiten',
          link: ''
        },
        {
          title: ' Wirtschaftliche Einheiten',
          link: ''
        },
        {
          title: 'Grundstück hinzufügen',
          link: ''
        }
      ],
    }
  },
  methods: {
    ...mapMutations("property", ["setCurrentStep"]),
    submit() {
      this.$router.push('/property/list');
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;


  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}

.text-finish {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: normal;
  font-size: 15px;
}
</style>
