<template>
  <MainLayout value="documentViewer">
    <template>
      <div class="h-full bg-white pl-7 w-full">
        <div v-if="loading" class="loading-container w-full h-full">
          <div class="spin-container">
            <div class="spin"></div>
          </div>
        </div>
        <div v-else>
          <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
          <div class="flex justify-between">
            <p v-show="!fileNameEditing" class="header-component mt-4 nowrap">
              {{ fileName }}
            </p>
            <InputText
                v-show="fileNameEditing && !readOnly"
                v-model="fileNameWoutExt"
                class="w-full"
                ref="el_filename"
                @keyup="saveFileName"
            />

            <!--drop down-->
            <div class="flex w-1/2 justify-end items-end cursor-pointer">
              <Button v-if="!isNewTab"
                class="mr-2"
                :icon="require('@/assets/back_white.svg')"
                :text="$t('buttons.back')"
                @click="menuClicked('back')"
              />
              <div
                  class="
                  relative
                  box
                  flex
                  items-center
                  mr-6
                  inline-block
                  text-left text-gray-800
                "
                  v-on-clickaway="closeMenu"
                  @click="isMenuOpen = !isMenuOpen"
              >
                <div>
                  <span class=""
                  ><img
                      class="box__image"
                      src="@/assets/horizontal_dots_gray.svg"
                  /></span>
                </div>
                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                  <div v-if="isMenuOpen" class="dropdown origin-top-right absolute right-0 mt-2 overflow-hidden z-20" :class="readOnly ? 'mt-24' : 'mt-48'">
                    <div class="mb-2 space-y-4">
                      <IconLabel
                          class="mt-2"
                          :icon="require('@/assets/down_gray.svg')"
                          :label="$t('documents.download')"
                          :blackText="true"
                          @click="menuClicked('download')"
                      />
                      <IconLabel
                          :icon="require('@/assets/i_gray.svg')"
                          :label="$t('documents.rename')"
                          :blackText="true"
                          @click="menuClicked('rename')"
                          v-if="!isViewAppendixFile && !readOnly"
                      />
                      <IconLabel
                          :icon="require('@/assets/delete_black.svg')"
                          :label="$t('documents.delete')"
                          :blackText="true"
                          @click="menuClicked('delete')"
                          v-if="!isViewAppendixFile && !readOnly"
                      />
                      <IconLabel
                          :icon="require('@/assets/document-rotate-white.svg')"
                          :label="$t('documents.rotate')"
                          :blackText="true"
                          @click="menuClicked('rotate')"
                          v-if="!isViewAppendixFile && !readOnly && fileExt === 'pdf'"
                      />
                      <IconLabel
                          :icon="require('@/assets/document-rotate-save-white.svg')"
                          :label="$t('documents.save_rotate')"
                          :blackText="true"
                          @click="menuClicked('save_rotate')"
                          v-if="!isViewAppendixFile && !readOnly && !disabledSaveRotate"
                      />
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <hr class="hr-line-user mr-7 mb-1 mt-3"/>
          <div class="mr-7">
            <div
                class="w-full h-full mt-2 border doc-container overflow-y-scroll"
            >
              <div v-if="fileExt === 'pdf'">
                <vue-pdf-embed :source="sourceObjectForPdfjs(fileUrl)" :rotation="fileRotation"/>
              </div>
              <div
                v-else-if="fileExt === 'rtf'"
                id="preview_rtf"
                class="p-8"
              ></div>
              <img v-else :src="fileUrl" class="image-preview"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {DocumentService} from "@/services/document.service";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {directive as onClickaway} from "vue-clickaway";
import {AppendixService} from "@/services/appendix.service";
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";

// handle read rtf file
import {RTFJS} from "rtf.js";

export default {
  name: "DocumentViewer",
  components: {MainLayout, VuePdfEmbed},
  mixins: [pdfjsCVEMixin],
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      isViewAppendixFile: false,
      appendixService: new AppendixService(),
      service: new DocumentService(),
      breadcrumbs: [],
      fileName: "",
      fileNameWoutExt: "",
      loading: true,
      parentType: "",
      parentId: "",
      documentId: "",
      fileUrl: "",
      fileRotation: 0,
      disabledSaveRotate: true,
      fileExt: "",
      allowedTypes: ["pdf", "jpg", "jpeg", "gif", "png", "rtf"],
      documentContent: "",
      mimeType: "",
      showOverlay: false,
      isMenuOpen: false,
      fileNameEditing: false,
      isNewTab: false,
      readOnly: false
    };
  },
  watch: {
    $route(to) {
      if (this.documentId && this.documentId !== to.params.id) {
        this.renderDocument();
      }
    },
  },
  destroyed() {
    this.documentContent = "";
    this.isViewAppendixFile = false;
  },
  mounted() {

    this.isNewTab = this.$route.name === 'DocumentViewerNewTab'
    this.renderDocument();

  },
  methods: {
    renderDocument() {
      this.loading = true;
      this.readOnly = this.$route.params.readOnly;
      if (this.$route.params.parentType) {
        this.parentType = this.$route.params.parentType;
      }
      if (this.$route.params.parentId) {
        this.parentId = this.$route.params.parentId;
      }

      if (this.$route.params.id) {
        this.documentId = this.$route.params.id;
      }
      if (this.$route.params.breadcrumbs) {
        this.breadcrumbs = this.$route.params.breadcrumbs;
      }

      if (this.$route.params.isAppendix) {
        this.isViewAppendixFile = true;
        let isTaxOfficeSoftwareDatev =
            this.$route.params.isTaxOfficeSoftwareDatev == "1" ? true : false;
        // fetch appendix file
        this.appendixService
            .get(this.documentId, isTaxOfficeSoftwareDatev)
            .then((document) => {
              this.loading = false;
              if (
                  document.file_type &&
                  this.allowedTypes.includes(document.file_type)
              ) {
                this.documentContent = document.content;
                this.fileExt = document.file_type;
                if (document.file_type == "pdf") {
                  this.mimeType = "application/octet-stream";
                  this.fileUrl = this.getDocUrl();
                } else if (document.file_type == "rtf") {
                  this.loadFile(document.rtf_content, {
                    baseURL: document.rtf_content.replace(/^(.*\/)[^\/]*$/, "$1"),
                  });
                } else {
                  this.fileUrl = this.getDocUrl();
                }
                this.fileNameWoutExt = "";
                this.fileName = document.filename;
              } else {
                this.$router.go(-1);
              }
            });
      } else {
        if (!this.parentType || !this.parentId || !this.documentId) {
          this.$router.go(-1);
        } else {
          this.service.setDefaultParams({
            parentType: this.parentType,
            parentId: this.parentId,
          });

          // fetch document
          this.service.get(this.documentId).then((document) => {
            if (
                document.mime_type &&
                this.allowedTypes.includes(document.file_ext)
            ) {
              this.loading = false;
              this.documentContent = document.content;
              this.mimeType = document.mime_type;
              this.fileExt = document.file_ext;
              this.fileUrl = this.getDocUrl();
              this.fileNameWoutExt = document.filename_wout_ext;
              this.fileName = document.filename;
            } else {
              this.$router.go(-1);
            }
          });
        }
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    getDocUrl() {
      return "data:" + this.mimeType + ";base64," + this.documentContent;
    },
    menuClicked(menu) {
      if (menu === "download") {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.getDocUrl();
        downloadLink.download = this.fileName;
        downloadLink.click();
      } else if (menu === "rename") {
        this.fileNameEditing = !this.fileNameEditing;
        if (this.fileNameEditing) {
          this.$nextTick(() => this.$refs.el_filename.setFocus());
        }
      } else if (menu === "delete") {
        this.$modal.show({
          headerText: this.$t("documents.confirmations.delete.single.title"),
          text: this.$t("documents.confirmations.delete.single.text"),
          showClose: true,
          canEscape: true,
          onConfirm: () => {
            this.service.delete(this.documentId).then((deleted) => {
              if (deleted) {
                this.$router.go(-1);
              }
            });
          },
        });
      } else if (menu === "back") {
        // check if any handler defined
        if (this.breadcrumbs.length > 0) {
          let handler = this.breadcrumbs.filter((o) => {
            return o.backButtonHandler;
          });

          if (handler.length > 0) {
            handler[0].handler();
            return;
          }
        }

        this.goBack();
      } else if (menu === 'rotate') {
        this.fileRotation = this.fileRotation + 90;
        if (this.fileRotation >= 360) {
          this.fileRotation = 0;
        }
        this.disabledSaveRotate = false;
      } else if (menu === 'save_rotate') {
        this.saveRotate();
      }
    },
    async saveRotate() {
      if (!this.readOnly) {
        let data = {
          documentId: this.documentId,
          fileRotation: this.fileRotation,
        };
        if (await this.service.saveRotate(data)) {
          this.disabledSaveRotate = true;
          this.fileRotation = 0;
          this.renderDocument();
        }
      }
    },
    async saveFileName() {
      if (!this.readOnly) {
        let data = {
          documentId: this.documentId,
          fileName: this.fileNameWoutExt + "." + this.fileExt,
        };
        if (await this.service.update(data)) {
          this.fileNameEditing = false;
          this.fileName = this.fileNameWoutExt + "." + this.fileExt;
        }
      }
    },
    stringToArrayBuffer(string) {
      var buffer = new ArrayBuffer(string.length);
      var bufferView = new Uint8Array(buffer);
      for (var i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
      }
      return buffer;
    },
    loadFile(rtf) {
      const doc = new RTFJS.Document(this.stringToArrayBuffer(rtf));
      // const meta = doc.metadata();
      //Need to send this to text area or render(how?)
      doc
          .render()
          .then(function (htmlElements) {
            document.querySelector("#preview_rtf").append(...htmlElements);
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
};
</script>

<style scoped lang="scss">
.loading-container {
  position: relative;

  .spin-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
  }
}

.nowrap {
  white-space: nowrap;
}

.overlaynew {
  width: 250px;
  height: auto;
  margin-left: 25px;
  bottom: 30px;
  text-align: center;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  display: block;
}

.hidden {
  width: 0px;
  height: 0px;
  display: none;
}

.box {
  width: 36px;
  height: 33px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;

  &__image {
    width: 38px;
    height: 4px;
    box-sizing: border-box;
  }
}

.dropdown {
  width: 207px;
  padding: 2px 2px 2px 2px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.button {
  min-width: 120px !important;
}

.border {
  border: 1px solid #dfdfdf;
}

.doc-container {
  height: calc(100vh - 140px);
}
</style>
