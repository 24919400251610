<template>
  <div>
    <p
      v-if="!needsFeatureHover"
      class="flex text-left items-center"
    >
      <span>{{ label }}</span>
    </p>

    <VDropdown
      v-else
      :showTriggers="['click', 'hover']"
      :hideTriggers="['hover', 'click']"
      :popperTriggers="['hover', 'click']"
      placement="right-start"
    >
      <p class="flex text-left items-center hover:underline cursor-pointer">
        <span>{{ label }}</span>
      </p>

      <template #popper>
        <div class="space-y-4">
          <div
            class="tooltip-content text-sm"
            :class="{'is-business': $isBusiness}"
          >
            <p
              v-if="title"
              class="font-semibold text-base"
            >
              {{ title }}
            </p>

            <p
              v-if="subtitle"
              class="mt-2"
            >
              {{ subtitle }}
            </p>

            <ul
              v-if="list"
              class="list-disc ml-4"
            >
              <li v-for="line in list">{{ line }}</li>
            </ul>

            <img
              v-if="imagePath && !$isBusiness"
              class="mt-2"
              :src="getAssetPath(imagePath)"
            />
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
export default {
  name: 'FeatureHover',
  props: {
    label: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    imagePath: {
      type: String,
      default: null
    }
  },
  computed: {
    needsFeatureHover() {
      if (
        this.title !== null
        || this.subtitle !== null
        || this.list !== null
        || this.imagePath !== null
      ) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.tooltip-content {
  @include segoe-regular;

  background-color: #5d5d5d;

  &.is-business {
    @apply text-black;
  }
}
</style>
