<template>
  <div class=" bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.account.title')" :breadcrumbs="breadcrumbs"/>
    <ModalDialog v-show="isModalVisible" @close="handleModalClose"
                 :headerText="$t('settings.account.cropDialog.title')">
      <template v-slot:body>
        <div class=" flex flex-col justify-center items-center" style="max-width:630px">
          <cropper
              ref="cropper"
              :src="image.src"
          />
          <div class="flex justify-end space-x-10 mt-4 mr-6 w-full">
            <div class="flex  mb-4 justify-end w-full space-x-4">
              <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="cancelCrop"/>
              <Button class=" " @click="cropImage" :text="$t('settings.account.cropDialog.button')"/>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="is2faModalVisible" @close="handle2faModalClose" :canEscape="false"
                 :canCloseOutsideClick="false" :showClose="false"
                 :headerText="$t('settings.account.2faDialog.title')">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <label class="label text-left  mt-4">{{ $t('settings.account.2faDialog.subtitle') }} </label>
          <label class="label text-left  mt-4">{{ $t('settings.account.2faDialog.code') }} </label>
          <div class=" pr-8 mt-2 ml-2 flex justify-center items-center">
            <img :src="twoFaCodeUrl"/>
          </div>
          <div class="flex flex-col justify-start">
            <label class=" label text-left  mt-4 mb-2">OTP-Code </label>
            <div class="code flex justify-between">
              <label class="label self-center">{{ twoFaCode }}</label>
              <div class="flex cursor-pointer" @click="copyCode">
                <span style="background-color: #d3d3d3; " class="h-full mr-3 w-0.5  "></span>
                <img src="@/assets/copy.png" class=" flex self-center h-4 w-4 label "/>
              </div>
            </div>
            <InputText class="mr-2 mt-3" label="" v-model="twoFaOtp"
                       :placeholder="$t('settings.account.2faDialog.otpPlaceholder')"/>
          </div>

          <div class="flex justify-end space-x-10 mt-4 ">

            <div class="flex justify-end mt-4 mb-6 mr-2 space-x-4  ">

              <Button @click="handle2faModalSave" class=" label w-full bg-green   text-white"
                      :text="$t('buttons.save')"></Button>
              <Button :secondary="true" class="w-full mr-3" @click="handle2faModalCancel"
                      :text="$t('buttons.cancel')"></Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="isDatevDeviceModalVisible" @close="closeDatevDeviceDlg"
                 :headerText="datevDeviceModalHeader">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-8 space-y-4 w-full">
          <InputText :label="$t('settings.datev_devices.name_label')" v-model="datevDevice.name"/>
          <InputText :label="$t('settings.datev_devices.password_label')" type="password" v-model="datevDevice.password"
                     v-show="!datevDevice.uid"
                     :show-password-template="true"
                     :show-password-view-icon="true"
          />
        </div>
        <div class="flex flex-col justify-start mt-8 space-y-4 w-full" v-show="!datevDevice.uid">
          <label class="align-left">{{ $t('settings.datev_devices.add_info') }}</label>
        </div>

        <div class="flex justify-end space-x-10 mt-10 mr-6 w-full">
          <div class="flex  mb-4 justify-end w-full space-x-4">
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="closeDatevDeviceDlg"/>
            <Button class=" " @click="saveDatevDevice" :text="$t('buttons.ok')"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="w-full  pr-7 mt-8">
      <!-- Personal information -->
      <div>
        <div class="flex justify-between">
          <p class="header  mb-3"> {{ $t('settings.account.header1') }}</p>
          <div class="flex justify-end">
            <label class="id"> {{ $t('sidebar.popup.user_id') }}: {{ getCurrentAccountId }}</label>
          </div>
        </div>
        <hr class=" hr-line-user  mb-1"/>
        <div class="flex justify-start space-x-8">
          <InputText class="w-full mt-3 user_id" :label="$t('settings.user_management.attrs.user_id')"
                     v-model="currentUser.user_id"
                     placeholder="" disabled/>
          <div class="w-full"></div>
        </div>
        <div class="flex justify-around space-x-8 mt-5">
          <InputSelect class="w-full" :label="$t('settings.account.fields.salutation')"
                       v-model="currentUser.salutation"
                       :selected-value="currentUser.salutation"
                       :options="salutations"
                       :show-help-icon="true"
                       :tooltip="$t('settings.account.salutation_help')"
                       :isRequired="true"
                       :isError="errors.salutation.invalid"
                       :error-message="$t('general.errors.required')"
                       placeholder=""/>
          <InputText class="w-full" :label="$t('settings.account.fields.name')" v-model="currentUser.name"
                     :isError="errors.name.invalid"
                     :isRequired="true" :error-message="$t('general.errors.required')" placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <InputText class="w-full"
                     :maxLength="16"
                     :label="$t('settings.account.fields.phone')"
                     v-model="currentUser.phone"
                     placeholder=""/>
          <InputText class="w-full"
                     :label="$t('settings.account.fields.email')"
                     v-model="currentUser.email"
                     :isRequired="true"
                     :isError="errors.email.invalid"
                     :error-message="$t('general.errors.required')"
                     placeholder=""/>
        </div>
      </div>
      <!-- datev device -->
      <div v-if="showDatevDevices" class="deviceGrid">
        <p class="header  mb-3 mt-10"> {{ $t('settings.datev_devices.section_head') }}</p>
        <hr class=" hr-line-user  mb-5"/>
        <AppGrid grid-id="grid-datev-devices"
                 data-endpoint="datev/list_devices"
                 :columns="datevDeviceColumns"
                 :menus="datevDeviceMenus"
                 default-sort-attr="name"
                 :default-sort-dir="1"
                 :selectable="true"
                 selection-data-key="id"
                 :show-create-record="false"
                 :pagination="false"
                 :reorderable-columns="false"
                 :resizable-columns="false"
                 :state-ful="false"
                 :show-search="false"
                 :show-select-col="false"
                 @menu-clicked="handleMenuClick"
                 @col-click="handleColClick"
        />
      </div>
      <!--      company information-->
      <div v-if="showCompanyInfo">
        <p class="header  mb-3 mt-10"> {{ $t('settings.account.header2') }}</p>
        <hr class=" hr-line-user  mb-5"/>
        <div v-if="!$isBusiness" class="flex justify-around space-x-8">
          <InputSelect class="w-full" :label="$t('settings.account.fields.salutation')"
                       v-model="currentUser.salutation_elster"
                       :selected-value="currentUser.salutation_elster"
                       :options="$t('general.salutation_elster_options')"
                       :show-help-icon="true"
                       :tooltip="$t('settings.account.salutation_elster_help')"
                       placeholder=""/>
          <div class="invisible w-full"/>
        </div>
        <div v-if="!$isBusiness" class="flex justify-around space-x-8 mt-5" v-show="showAccountName">
          <InputText class="w-full" :label="$t('settings.account.fields.fname')" v-model="currentUser.account_fname"
                     :isError="errors.account_fname.invalid"
                     :maxLength="25"
                     :isRequired="true" :error-message="$t('general.errors.required')" placeholder=""/>
          <InputText class="w-full" :label="$t('settings.account.fields.lname')"
                     v-model="currentUser.account_lname"
                     :isError="errors.account_lname.invalid"
                     :isRequired="true"
                     :maxLength="25"
                     :error-message="$t('general.errors.required')"
                     placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-5" v-show="!showAccountName || $isBusiness">
          <InputText class="w-full" :label="$t('settings.account.fields.company')" v-model="currentUser.company_name"
                     :isError="errors.company_name.invalid"
                     :maxLength="50"
                     :isRequired="true" :error-message="$t('general.errors.required')" placeholder=""/>
          <div class="invisible w-full"/>
        </div>
        <div class="flex justify-around space-x-8 mt-5">
          <InputText class="w-full"
                     :label="$t('settings.account.fields.street_and_house_number')"
                     v-model="currentUser.street"
                     placeholder=""/>
          <div class="invisible w-full"/>
        </div>
        <div class="flex justify-around space-x-8 mt-5">
          <InputText class="w-full"
                     :label="$t('settings.account.fields.zip')"
                     v-model="currentUser.zip"
                     :isRequired="true"
                     @blur="searchZip"
                     :isOnlyNumber="true"
                     :maxLength="5"
                     placeholder=""/>
          <InputText class="w-full"
                     :label="$t('settings.account.fields.city')"
                     v-model="currentUser.city"
                     :isRequired="true"
                     :loading="zipSearch"
                     placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-5 ">
          <InputSelect class="w-full"
                       :label="$t('settings.account.fields.country')"
                       v-model="currentUser.country"
                       :selected-value="currentUser.country"
                       :filterable="true"
                       :options="countries"
                       :isRequired="true"
                       placeholder=""/>
          <InputText class="w-full"
                     :label="$t('settings.account.fields.pobox')"
                     v-model="currentUser.pobox"
                     placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-5 ">
          <InputText
            class="w-full"
            :maxLength="16"
            :label="$t('settings.account.fields.phone')"
            v-model="currentUser.company_phone"
            placeholder=""
          />
          <InputText
            class="w-full"
            :label="$t('settings.account.fields.email')"
            v-model="currentUser.account_email"
            placeholder=""
          />
        </div>
      </div>

      <!--      Change password-->
      <div v-show="showChangePwd">
        <p class="header  mb-3 mt-10">{{ $t('settings.account.header3') }}</p>
        <hr class=" hr-line-user"/>
        <div v-if="currentUser.set_new_pwd === 1">
          <div class="flex justify-around space-x-8 mt-8">
            <InputText class="w-full" :label="$t('settings.account.fields.new_password')"
                       v-model="currentUser.newPassword"
                       :showPasswordViewIcon="true"
                       :showPasswordTemplate="true"
                       type="password"
                       placeholder=""/>
            <InputText class="w-full " :label="$t('settings.account.fields.retype_password')"
                       v-model="currentUser.confirmPassword" :isRequired="true"
                       type="password"
                       placeholder=""/>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-around space-x-8 mt-8">
            <InputText class="w-full" :label="$t('settings.account.fields.current_password')"
                       v-model="currentUser.currentPassword"
                       type="password"
                       :showPasswordViewIcon="true"
                       placeholder=""/>
            <InputText class="w-full"  :label="$t('settings.account.fields.new_password')"
                       v-model="currentUser.newPassword"
                       :showPasswordViewIcon="true"
                       :showPasswordTemplate="true"
                       type="password"
                       placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-5" v-show="currentUser.newPassword">
            <div class="w-full"></div>
            <InputText class="w-full " :label="$t('settings.account.fields.retype_password')"
                       v-model="currentUser.confirmPassword" :isRequired="true"
                       type="password"
                       placeholder=""/>
          </div>
        </div>
      </div>
      <!--      settings-->
      <div>
        <p class="header  mb-3 mt-10"> {{ $t('settings.account.header4') }}</p>
        <hr class=" hr-line-user "/>
        <div class="flex w-full space-x-10 mt-8">
          <div class="flex w-full flex-col">
            <div class="flex mb-2" v-show="showMfa">
              <p class="two-factor self-start">{{ $t('settings.account.fields.twofactor') }}</p>
              <img class="help_icon" :src="getAssetPath('help_green.svg')" v-tooltip.right="$t('settings.account.fields.twofactor_help')"/>
            </div>
            <label class="switch  mb-2" v-show="showMfa">
              <input type="checkbox" v-model="currentUser.two_fa" :disabled="isEditMfaDisabled"
                     true-value="1"
                     false-value="0">
              <span class="slider round"></span>
            </label>
            <div class="invisible mt-20" v-show="!showMfa"/>
            <InputSelect class="w-full mt-5" :label="$t('settings.account.fields.language')"
                         v-model="currentUser.language"
                         :selected-value="currentUser.language"
                         :options="languages"
                         placeholder=""/>
            <InputSelect class="w-full mt-5" :label="$t('settings.account.fields.email_notification')"
                         :show-help-icon="true"
                         :tooltip="$t('settings.account.fields.email_notification_help')"
                         v-model="currentUser.email_notification_schedule"
                         :selected-value="currentUser.email_notification_schedule"
                         :options="$t('settings.account.email_notification_options')"
                         placeholder=""/>
          </div>
          <div class="flex flex-col w-full justify-center items-center">
            <p class="photo-text">
              {{ $t('settings.account.profile_photo') }}
            </p>
            <div class="rounded-full mt-5 mb-5" v-if="currentUser.userProfilePic">
              <img class="profile-pic" :src="currentUser.userProfilePic"/>
            </div>
            <div class="rounded-full mt-5 mb-5" v-else>
              <img class="profile-pic" :src="defaultProfilePic"/>
            </div>
            <div class="flex justify-center items-center">
              <div class="flex space-x-3">
                <input
                    type="file"
                    style="display: none"
                    ref="profilePhotoInput"
                    accept="image/*"
                    @change="photoSelect"/>
                <Button class="profile-button  " @click="chooseProfilePhoto" :text=" $t('settings.account.upload')"/>
                <Button v-if='currentUser.userProfilePic' class=" profile-button " @click="deletePic('profile')"
                        :text="$t('settings.account.delete') "/>
              </div>

            </div>
            <div class="flex flex-col ml-3 mt-3 mb-2">
              <label class="date_format">{{ $t('settings.system.client_portal.file_types') }}: <span
                  style="font-weight: bold">jpg, png, gif</span></label>
              <label class="date_format mt-2 ">{{ $t('settings.system.client_portal.dimensions1') }} <span
                  style="font-weight: bold">100 x 100 </span> </label>
              <label class="date_format mt-1 "> {{ $t('settings.system.client_portal.dimensions2') }}
                <span style="font-weight: bold">5000 x 5000</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mr-6 mt-10">
      <hr class="hr-line-user mb-4">
      <div class="flex justify-end mt-5 space-x-6 w-full">
        <Button @click="goBack()" class="w-52" :secondary="true" :text="$t('settings.account.cancel')"/>
        <Button class="w-52 " @click="saveAccountSettings"
                :text="$t('settings.account.save') "/>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../inputs/InputSelect";
import InputText from "../inputs/InputText";
import {languages, salutations, userRoles} from "@/core/constants";
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Tooltip from 'primevue/tooltip';
import AppGrid from "@/components/AppGrid";
import {mapGetters, mapMutations} from "vuex";
import {email, required, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "accountsettings",
  components: {InputText, InputSelect, Cropper, AppGrid},
  directives: {'tooltip': Tooltip},
  data() {
    return {
      image: {
        name: "",
        src: "",
        type: ""
      },
      salutations: salutations,
      userRoles: userRoles,
      languages: languages,
      photoType: null,
      isModalVisible: false,
      is2faModalVisible: false,
      twoFaCodeUrl: '',
      twoFaCode: '',
      twoFaOtp: '',
      toggle2fa: parseInt(this.$store.state.user.currentUser.two_fa) === 1 ? "yes" : "no",
      defaultProfilePic: require("@/assets/UserProfileAccount.png"),
      defaultCompanyLogoPic: require("@/assets/CompanyLogo.png"),
      zipSearch: false,

      currentUser: {
        salutation: this.$store.state.user.currentUser.salutation,
        user_id: this.$store.state.user.currentUser.user_id,
        name: this.$store.state.user.currentUser.name,
        account_fname: this.$store.state.user.currentUser.account_fname,
        account_lname: this.$store.state.user.currentUser.account_lname,
        account_email: this.$store.state.user.currentUser.account_email,
        email: this.$store.state.user.currentUser.email,
        phone: this.$store.state.user.currentUser.phone,
        company_name: this.$store.state.user.currentUser.company_name,
        street: this.$store.state.user.currentUser.street,
        zip: this.$store.state.user.currentUser.zip,
        city: this.$store.state.user.currentUser.city,
        salutation_elster: this.$store.state.user.currentUser.salutation_elster,
        company_phone: this.$store.state.user.currentUser.company_phone,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        country: this.$store.state.user.currentUser.country_uid,
        pobox: this.$store.state.user.currentUser.pobox,
        language: this.$store.state.user.currentUser.lang_code,
        userProfilePic: this.$store.state.user.currentUser.userProfilePic,
        companyLogoPic: this.$store.state.user.currentUser.companyLogoPic,
        two_fa: this.$store.state.user.currentUser.two_fa,
        set_new_pwd: this.$store.state.user.currentUser.set_new_pwd ? this.$store.state.user.currentUser.set_new_pwd : 0,
        email_notification_schedule: this.$store.state.user.currentUser.usersettings.email_notification_schedule ? this.$store.state.user.currentUser.usersettings.email_notification_schedule : 'disabled'
      },
      showConfirmPassword: false,
      countries: [],
      breadcrumbs: [{
        title: this.$t('settings.account.menu.setting'),
        link: ''
      }, {
        title: this.$t('settings.account.menu.account'),
        link: ''
      }
      ],
      isDatevDeviceModalVisible: false,
      datevDeviceModalHeader: '',
      datevDevice: {
        uid: 0,
        name: "",
        password: ""
      },
      datevDeviceColumns: [
        {
          field: 'name',
          header: this.$t('settings.datev_devices.name'),
          isChecked: true,
          sortable: false,
          clickable: true
        }, {
          field: 'createdAt',
          header: this.$t('settings.datev_devices.created_at'),
          isChecked: true,
          sortable: false
        }, {
          field: 'lastUsed',
          header: this.$t('settings.datev_devices.last_used'),
          isChecked: true,
          sortable: false
        }
      ],
      datevDeviceMenus: [
        {
          id: "device.add",
          "icon": this.getAssetPath('add_declaration_green.svg'),
          "title": this.$t('settings.datev_devices.add')
        }, {
          id: "device.edit",
          "icon": this.getAssetPath('edit_declaration_green.svg'),
          "icon_disabled": this.getAssetPath('edit-declaration-gray.svg'),
          "title": this.$t('settings.datev_devices.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "device.delete",
          "icon": this.getAssetPath('delete_green.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('settings.datev_devices.delete'),
          "action_endpoint": 'datev/delete_device',
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }
      ],
      errors: {
        "name": {
          invalid: false
        },
        "salutation": {
          invalid: false
        },
        "email": {
          invalid: false
        },
        "account_fname": {
          invalid: false
        },
        "account_lname": {
          invalid: false
        },
        "company_name": {
          invalid: false
        }
      }
    }
  },
  validations: {
    currentUser: {
      name: {
        required
      },
      email: {
        required, email
      },
      account_fname: {
        required: requiredIf(function () {
          return this.showAccountName && this.showCompanyInfo && !this.$isBusiness;
        })
      },
      account_lname: {
        required: requiredIf(function () {
          return this.showAccountName && this.showCompanyInfo && !this.$isBusiness;
        })
      },
      company_name: {
        required: requiredIf(function () {
          return (!this.showAccountName || this.$isBusiness) && this.showCompanyInfo;
        })
      }
    }
  },
  computed: {
    showMfa() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.tax_office_software !== 'datev';
    },
    forceMfa() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.force_2fa === 1;
    },
    isEditMfaDisabled() {
      return this.forceMfa && (this.currentUser.two_fa.toString() === "1");
    },
    showDatevDevices() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.tax_office_software === 'datev';
    },
    showChangePwd() {
      return typeof this.getCurrentUser().settings !== 'undefined'
        && this.getCurrentUser().settings.tax_office_software !== 'datev';
    },
    getCurrentAccountId() {
      return this.getCurrentUser().account_id;
    },
    showCompanyInfo() {
      let allowed_roles = ['it_administrator', 'administrator'];
      return allowed_roles.includes(this.getCurrentUser().role);
    },
    showAccountName() {
      let person_salutations = ["1", "2", "3"]
      return person_salutations.includes(this.currentUser.salutation_elster);
    }
  },
  created() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
    });
    if(this.showCompanyInfo) {
      this.currentUser = {
        salutation: this.$store.state.user.currentUser.salutation,
        user_id: this.$store.state.user.currentUser.user_id,
        name: this.$store.state.user.currentUser.name,
        account_fname: this.$store.state.user.currentUser.account_fname,
        account_lname: this.$store.state.user.currentUser.account_lname,
        account_email: this.$store.state.user.currentUser.account_email,
        email: this.$store.state.user.currentUser.email,
        phone: this.$store.state.user.currentUser.phone,
        company_name: this.$store.state.user.currentUser.company_name,
        street: this.$store.state.user.currentUser.street,
        zip: this.$store.state.user.currentUser.zip,
        city: this.$store.state.user.currentUser.city,
        salutation_elster: this.$store.state.user.currentUser.salutation_elster,
        company_phone: this.$store.state.user.currentUser.company_phone,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        country: this.$store.state.user.currentUser.country_uid,
        pobox: this.$store.state.user.currentUser.pobox,
        language: this.$store.state.user.currentUser.lang_code,
        userProfilePic: this.$store.state.user.currentUser.userProfilePic,
        companyLogoPic: this.$store.state.user.currentUser.companyLogoPic,
        two_fa: this.$store.state.user.currentUser.two_fa,
        set_new_pwd: this.$store.state.user.currentUser.set_new_pwd ? this.$store.state.user.currentUser.set_new_pwd : 0
      };
      if(this.$store.state.user.currentUser.house_number) {
        this.currentUser.street += ' ' + this.$store.state.user.currentUser.house_number;
      }
    } else {
      this.currentUser = {
        salutation: this.$store.state.user.currentUser.salutation,
        user_id: this.$store.state.user.currentUser.user_id,
        name: this.$store.state.user.currentUser.name,
        email: this.$store.state.user.currentUser.email,
        phone: this.$store.state.user.currentUser.phone,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        language: this.$store.state.user.currentUser.lang_code,
        userProfilePic: this.$store.state.user.currentUser.userProfilePic,
        two_fa: this.$store.state.user.currentUser.two_fa,
        set_new_pwd: this.$store.state.user.currentUser.set_new_pwd ? this.$store.state.user.currentUser.set_new_pwd : 0
      };
    }

    this.currentUser.email_notification_schedule = this.$store.state.user.currentUser.usersettings.email_notification_schedule ? this.$store.state.user.currentUser.usersettings.email_notification_schedule : 'disabled'
  },
  mounted() {
    if (this.showDatevDevices) {
      this.triggerReload('grid-datev-devices')
    }
  },
  methods: {
    ...mapMutations("user", ["setCurrentUser"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("grid", ["triggerReload"]),
    saveAccountSettings() {
      // Reset validation status
      for (let attr in this.errors) {
        this.errors[attr].invalid = false;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        for (let attr in this.errors) {
          this.errors[attr].invalid = this.$v.currentUser[attr].$error;
        }
        return;
      }

      if (this.currentUser.currentPassword !== '' && this.currentUser.newPassword === '') {
        this.$api.showToast(this.$t('general.errors.new_password'), "error");
        return false;
      }
      if (this.currentUser.set_new_pwd === 0 && this.currentUser.currentPassword === '' && this.currentUser.newPassword !== '') {
        this.$api.showToast(this.$t('general.errors.current_password'), "error");
        return false;
      }

      if (this.currentUser.currentPassword !== '' && this.currentUser.newPassword !== '' && this.currentUser.currentPassword === this.currentUser.newPassword) {
        this.$api.showToast(this.$t('general.errors.passwordSameAsCurrent'), "error");
        return false;
      }

      if (this.currentUser.pobox === '' && this.currentUser.street === '') {
        this.$api.showToast(this.$t('settings.account.street_pobox_error'), "error");
        return false;
      }

      if(this.currentUser.salutation === '0' || this.currentUser.salutation === '') {
        this.errors.salutation.invalid = true;
        return false;
      }

      let data = {...this.currentUser};
      if (data.newPassword) {
        data.newPassword = this.$api.encrypt(data.newPassword);
      }
      if (data.currentPassword) {
        data.currentPassword = this.$api.encrypt(data.currentPassword);
      }
      if (data.confirmPassword) {
        data.confirmPassword = this.$api.encrypt(data.confirmPassword);
      }

      this.$api.trigger('update_user', data, true).then((response) => {
        if (response.data.success) {
          this.$api.showToast(this.$t('settings.account.save_success'));
          this.$api.get_user();
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      })
    },
    copyCode() {
      navigator.clipboard.writeText(this.twoFaCode);
    },
    deletePic(photoType) {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }

      this.$api.trigger('user/delete_photo', {
        "photoType": photoType
      }, true).then((response) => {
        if (response.data.success) {
          this.$api.get_user();
          if (photoType === 'profile') {
            this.currentUser.userProfilePic = '';
          } else {
            this.currentUser.companyLogoPic = '';
          }
        }
      })
    },
    chooseProfilePhoto() {
      this.$refs.profilePhotoInput.click()
      this.photoType = "profile"
    },
    photoSelect(event) {
      this.isModalVisible = true
      const files = event.target.files
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
      const blob = URL.createObjectURL(files[0]);
      this.image = {
        name: files[0].name,
        src: blob,
        type: files[0].type,
      };

      // Clear input
      this.$refs.fileInputCompany.value = '';
      this.$refs.profilePhotoInput.value = '';
    },
    cropImage() {
      let croppedImg = this.$refs.cropper.getResult().canvas.toDataURL(this.image.type);
      this.$api.trigger('user/upload_photo', {
        "name": this.image.name,
        "croppedImg": croppedImg,
        "photoType": this.photoType
      }, true).then((response) => {
        if (response.data.success) {
          if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
          }
          this.$api.get_user();
          if (this.photoType === 'profile') {
            this.currentUser.userProfilePic = response.data.image_url;
          } else {
            this.currentUser.companyLogoPic = response.data.image_url;
          }
          this.handleModalClose();
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    cancelCrop() {
      this.isModalVisible = false;
    },
    handleModalClose() {
      this.isModalVisible = false
    },
    handle2faModalClose() {
      this.is2faModalVisible = false
    },
    handle2faModalCancel() {
      this.handle2faModalClose();
      this.toggle2fa = false;
    },
    handle2faModalSave() {
      this.$api.trigger('update_two_fa', {
        otp: this.twoFaOtp
      }).then((response) => {
        if (response.data.success) {
          this.handle2faModalClose();
          this.$api.showToast(this.$t('settings.2faDialog.account.two_factor_enabled'))
          this.setCurrentUser({
            two_fa: 1
          })
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    onToggleTwoFa(event) {
      var data = event.target.checked;
      if (data === true) {
        this.$api.trigger('get_two_fa_code', null).then((response) => {
          if (response.data.success) {
            this.is2faModalVisible = true
            this.twoFaCode = response.data.code;
            this.twoFaCodeUrl = response.data.code_url;
          } else {
            this.toggle2fa = false;
          }
        })
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.currentUser.city === '' && this.currentUser.zip !== '') {
        this.cityLookup(this.currentUser.zip).then((cities) => {
          if (cities.length > 0) {
            this.currentUser.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'device.add':
          this.datevDeviceModalHeader = this.$t('settings.datev_devices.add');
          this.resetDatevDeviceModel();
          this.isDatevDeviceModalVisible = true;
          break;
        case 'device.edit':
          this.editDatevDevice(args.data[0])
          break;
      }
    },
    handleColClick(e) {
      if (e.field === 'name' && parseInt(e.row.id) > 0) {
        this.editDatevDevice(e.row)
      }
    },
    editDatevDevice(row) {
      this.datevDeviceModalHeader = this.$t('settings.datev_devices.edit');
      this.datevDevice.uid = row.id;
      this.datevDevice.name = row.name;
      this.datevDevice.password = '';
      this.isDatevDeviceModalVisible = true;
    },
    resetDatevDeviceModel() {
      this.datevDevice.uid = 0;
      this.datevDevice.name = '';
      this.datevDevice.password = '';
    },
    closeDatevDeviceDlg() {
      this.resetDatevDeviceModel();
      this.isDatevDeviceModalVisible = false;
    },
    saveDatevDevice() {
      let data = {...this.datevDevice};
      /*if (data.password) {
        data.password = this.$api.encrypt(data.password);
      }*/
      this.$api.trigger('datev/save_device', data, true).then((response) => {
        if (response.data.success) {
          if (data.uid === 0) {
            this.$api.showToast(this.$t('settings.datev_devices.success_info'));
          }
          this.triggerReload('grid-datev-devices');
          this.isDatevDeviceModalVisible = false;
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.align-left {
  text-align: left;
}
.footer {
  padding-bottom: 10px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}

.profile-button {
  min-width: 96px;
  @include segoe-regular;
  color: #ffffff;
  text-align: center;
}

.button {
  min-width: 205px;
}

.label {
  @include segoe-regular;

  font-size: 15px;
}

.profile-pic {
  width: 112px;
  height: 111px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  margin-top: 5px;
  border-radius: 50% 50%;
  object-fit: cover;
}

.photo-text {
  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: 20px;
}

.header {
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 18px;

}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.two-factor {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

.code {
  background-color: #eef1f5;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.flex.flex-col.w-full.mt-5.user_id {
  width: 48%;
}

.modal {
  height: 580px;
}

.modal .vue-advanced-cropper, .vue-advanced-cropper__background {
  height: 480px;
  overflow: hidden;
}

.id {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: normal;
  font-size: 15px;
}

.deviceGrid::v-deep {
  .table-container {
    height: auto;
  }
}

.date_format {
  font-family: 'Segoe UI', sans-serif;

  color: #a1a1a1;
  text-align: center;
  line-height: normal;
  font-size: 12px;
  width: 100%;
}
</style>
