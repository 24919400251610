<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.popup.communication')" :breadcrumbs="breadcrumbs"/>

    <!-- invite log preview modal -->
    <ModalDialog
        v-show="emailLogPreview"
        :headerText="$t('settings.communications.emailDetail.email')"
        @close="emailLogPreview=false"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 pt-4 preview-modal-body">
          <div class="flex space-x-10 mt-3">
            <div class="width-100px label">{{ $t('settings.communications.emailDetail.sender') }}:</div>
            <div class="label">{{ emailDetail.sender }}</div>
          </div>
          <div class="flex space-x-10 mt-3">
            <div class="width-100px label">{{ $t('settings.communications.emailDetail.date') }}:</div>
            <div class="label">{{ emailDetail.createdAt }}</div>
          </div>
          <div class="flex space-x-10 mt-3">
            <div class="width-100px label">{{ $t('settings.communications.emailDetail.recipient') }}:</div>
            <div class="label">{{ emailDetail.recipient }}</div>
          </div>
          <div class="flex space-x-10 mt-3">
            <div class="width-100px label">{{ $t('settings.communications.emailDetail.subject') }}:</div>
            <div class="label">{{ emailDetail.subject }}</div>
          </div>
          <div class="space-x-10 mt-3 label">
            {{ $t('settings.communications.emailDetail.content') }}:
          </div>
          <div class="message-body label" v-html="emailDetail.content">
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="emailLogPreview=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <AppTabs class="mt-10" :categories="availableTabs" :selected-tab="activeTab" :noFooter="true">
      <!-- tab 1 -->
      <template #tab-pane-clientPortal v-if="currentUser.role === 'administrator' || currentUser.role === 'user'">
        <div>
          <AppGrid grid-id="client-portal-email-log-grid"
                   data-endpoint="email_log/list"
                   :columns="cpEmailLogsColumns"
                   default-sort-attr="createdAt"
                   :default-sort-dir="-1"
                   :show-filter="false"
                   :menus="emailLogsMenus"
                   @col-click="handleEmailLogsColClick"
                   :showSelectCol="false"
                   :show-create-record="false"
                   :server-params="cpServerParams"
          />
        </div>
      </template>

      <!-- tab 2 -->
      <template #tab-pane-invitations v-if="currentUser.role === 'administrator' || currentUser.role === 'it_administrator'">
        <div>
          <AppGrid grid-id="email-log-grid"
                   data-endpoint="email_log/list"
                   :columns="inviteEmailLogsColumns"
                   default-sort-attr="createdAt"
                   :default-sort-dir="-1"
                   :show-filter="false"
                   :menus="emailLogsMenus"
                   @col-click="handleEmailLogsColClick"
                   :showSelectCol="false"
                   :show-create-record="false"
                   :server-params="inviteServerParams"
          />
        </div>
      </template>
    </AppTabs>
  </div>
</template>

<script>

import AppTabs from "@/components/AppTabs";
import AppGrid from "../AppGrid";
import {CommunicationsService} from "@/services/communications.service";
import {mapGetters} from "vuex";

export default {
  name: "communications",
  components: {
    AppTabs,
    AppGrid
  },
  data() {
    return {
      service: new CommunicationsService(),
      breadcrumbs: [
        {
          title: this.$t('settings.communications.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.communications.menu.communications'),
          link: ''
        }
      ],
      inviteEmailLogsColumns: [{
        field: 'createdAt',
        header: this.$t('settings.communications.grid.created_at'),
        isChecked: true,
        elType: 'date_range',
        operator: 'between',
        clickable: true
      }, {
        field: 'sender',
        header: this.$t('settings.communications.grid.sender'),
        filterLbl: this.$t('settings.communications.grid.sender') + ' ' + this.$t('general.contains'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'recipient',
        header: this.$t('settings.communications.grid.recipient'),
        filterLbl: this.$t('settings.communications.grid.recipient') + ' ' + this.$t('general.contains'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'accepted',
        header: this.$t('settings.communications.grid.accepted'),
        isChecked: true,
        elType: 'select',
        filterOptions: [{
          "code": '',
          "name": this.$t('general.please_select')
        }, {
          "code": '1',
          "name": this.$t('general.yes')
        }, {
          "code": '0',
          "name": this.$t('general.no')
        }]
      }],
      cpEmailLogsColumns: [{
        field: 'createdAt',
        header: this.$t('settings.communications.grid.created_at'),
        isChecked: true,
        elType: 'date_range',
        operator: 'between',
        clickable: true
      }, {
        field: 'parentName',
        header: this.$t('clients.attrs.name'),
        filterLbl: this.$t('general.attrs.name_contains'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'sender',
        header: this.$t('settings.communications.grid.sender'),
        filterLbl: this.$t('settings.communications.grid.sender') + ' ' + this.$t('general.contains'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'recipient',
        header: this.$t('settings.communications.grid.recipient'),
        filterLbl: this.$t('settings.communications.grid.recipient') + ' ' + this.$t('general.contains'),
        isChecked: true,
        elType: 'text',
        clickable: true
      }, {
        field: 'emailType',
        header: this.$t('settings.communications.grid.email_type'),
        isChecked: true,
        elType: 'select',
        filterOptions: this.$t('settings.communications.cp_email_type_options')
      }, {
        field: 'accepted',
        header: this.$t('settings.communications.grid.accepted'),
        isChecked: true
      }, {
        field: 'reminder',
        header: this.$t('settings.communications.grid.reminder'),
        isChecked: false
      }],
      cpServerParams: {
        "logType": "client_portal"
      },
      inviteServerParams: {
        "logType": "user_invitation"
      },
      emailLogsMenus: [
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh'),
        }
      ],
      emailDetail: {
        sender: '',
        createdAt: '',
        recipient: '',
        content: ''
      },
      emailLogPreview: false
    }
  },
  computed: {
    ...mapGetters("user", {
      "currentUser": "getCurrentUser"
    }),
    availableTabs() {
      if(this.currentUser.role === 'administrator') {
        return [
          {
            name: this.$t('settings.communications.tabs.tab1'),
            slug: 'clientPortal'
          }, {
            name: this.$t('settings.communications.tabs.tab2'),
            slug: 'invitations'
          }
        ];
      } else if(this.currentUser.role === 'user') {
        return [{
          name: this.$t('settings.communications.tabs.tab1'),
          slug: 'clientPortal'
        }];
      } else {
        return [
          {
            name: this.$t('settings.communications.tabs.tab2'),
            slug: 'invitations'
          }
        ];
      }
    },
    activeTab() {
      return this.currentUser.role === 'administrator' || this.currentUser.role === 'user' ? 'clientPortal' : 'invitations';
    }
  },
  methods: {
    handleEmailLogsColClick(e) {
      if((e.field === 'createdAt' || e.field === 'sender' || e.field === 'recipient') && parseInt(e.row.id) > 0) {
        this.emailDetail = {
          sender: '',
          createdAt: '',
          recipient: '',
          content: ''
        };
        let logType = e.gridId === 'client-portal-email-log-grid' ? 'client_portal' : 'user_invitation';
        this.service.get(e.row.id, logType).then((emailDetail) => {
          if (emailDetail.sender) {
            this.emailDetail = {...emailDetail};
            this.emailLogPreview = true;
          }
        });
      } else if(e.field === 'parentName' && e.gridId === 'client-portal-email-log-grid' && parseInt(e.row.parentUid) > 0) {
        this.$router.push("/client/edit?uid=" + e.row.parentUid)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.width-100px {
  width: 100px;
}

.message-body {
  border: 1px solid #eeeeee;
  padding: 10px;
  background: #f8f8f8;
}

</style>
