<template>
  <div class="step_box">
    <div class="flex justify-items-start space-x-10 mt-2 mb-4 ">
      <input-select class="w-1/2"
                    :label="$t('clients.datev_import.steps.step1.import_mode')"
                    :options="$t('clients.datev_import.steps.step1.import_modes')"
                    v-model="import_mode"
                    selected-value="new_clients"
      />
    </div>
    <div class="flex justify-items-start space-x-10 mt-2 mb-4 ">
      <InputCheck :text="$t('clients.datev_import.steps.step1.show_inactive_clients')"
                  @check="checkedInactiveClients"/>
    </div>
    <div class="box info-box1 flex flex-col w-full mr-6">
      <div class="flex justify-start items-center h-full">
        <img class="image ml-2 mr-2" src="@/assets/info_gray.svg"/>
        <div class="flex flex-col">
          <label class="text ml-2">
            {{ $t('clients.datev_import.steps.step1.info') }}
            <a class=" link" href="https://www.datev.de/web/de/mydatev/online-anwendungen/datev-basisdaten-online/"
               target="_blank">{{ $t('clients.datev_import.steps.step1.here') }}</a>
          </label>

        </div>
      </div>
    </div>
    <div class="box flex flex-col w-full mt-3 mr-6">
      <div class="flex justify-start items-center h-full">
        <img class="image ml-2 mr-2" src="@/assets/info_gray.svg"/>
        <div class="flex flex-col mt-2 mr-32">
          <label class="text ml-2">
            {{ $t('clients.datev_import.steps.step1.info_2') }}
          </label>
          <label class="text ml-2 ">
            {{ $t('clients.datev_import.steps.step1.info_3') }}
          </label>
          <label class="text ml-2 mt-2">
            {{ $t('clients.datev_import.steps.step1.info_4') }}
          </label>

          <label class="text ml-2 mb-2 mt-2">
            {{ $t('clients.datev_import.steps.step1.info_5') }}
            <a class=" link" href="https://hilfe.grundsteuer-digital.de/faq/import-von-datev-stammdaten-der-ehegatten/"
               target="_blank">{{ $t('clients.datev_import.steps.step1.here') }}</a>
          </label>
        </div>
      </div>
    </div>
    <div class="flex justify-around space-x-10 mt-2 "/>
    <div class="flex justify-end space-x-4 mr-2 mt-10">
      <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="goBack()"/>
      <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>
</template>

<script>
import InputCheck from "@/components/inputs/InputCheck";
import {mapGetters, mapMutations} from "vuex";
import InputSelect from "@/components/inputs/InputSelect";
import {ClientService} from "@/services/client.service";
export default {
  name: "Step1",
  components: {InputSelect, InputCheck},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new ClientService(),
      showInactiveClients: false,
      import_mode: 'new_clients'
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations("datev_import", ["setImportMode", "setImportKey", "init", "setShowInactiveClients"]),
    ...mapGetters("datev_import", ["getImportKey"]),
    ...mapGetters("user", ["getCurrentUser"]),
    submitNext() {
      let currentTs = new Date().getTime();
      this.setImportMode(this.import_mode);
      this.setImportKey(this.getCurrentUser().account_uid + '-' + this.getCurrentUser().id + '-' + currentTs);
      this.setShowInactiveClients(this.showInactiveClients);
      this.service.get_saved_datev_ids(this.getImportKey());
      this.$emit("goToNextStep");
    },
    checkedInactiveClients(checked) {
      this.showInactiveClients = checked;
    }
  }
}
</script>

<style scoped lang="scss">
.flex.radio_step > label {
  margin-right: 10px;
}

.step_box {
  text-align: left;
  font-size: 15px;
}

p.para_text {
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;

}


.chk {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.box {
  min-height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  align-self: start;
  margin-right: 100px;
  display: table;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

a {
  text-decoration: underline;
  color: theme('colors.muted_black');
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
.info-box1 {
  height: auto;
  min-height: 1px;
  padding: 10px 2px;
}
</style>
