<template>
  <div class="h-full bg-white pl-7 w-full " v-show="status !== 'none'">
    <PageHeader :title="getPageTitle" :logo="require('@/assets/datev_logo.png')"/>
    <div class="flex flex-col justify-center items-center">
      <div class="w-full">
        <div class="mt-10 mr-6 text-left" v-if="status === 'inProcess'">
          <label>{{ $t('clients.datev_import.in_progress') }}</label>

          <div class="flex justify-end space-x-6 mt-20 mr-3">
            <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="backToDashboard"/>
            <Button class="w-40" :text="$t('buttons.refresh')" @click="fetchNotification"/>
          </div>
        </div>
        <div class="mt-10 mr-6 text-left" v-else-if="nothingChanged">
          <label>{{ $t('clients.datev_import.no_records_added_or_updated') }}</label>
        </div>
        <div class="mt-10 mr-6 text-left" v-else>
          <ul>
            <li class="text self-start leading-8">&bull;
              {{ $t('clients.datev_import.x_clients_added', {"count": addedClient}) }}
            </li>
            <li class="text self-start leading-8">&bull;
              {{ $t('clients.datev_import.x_clients_updated', {"count": updatedClient}) }}
            </li>
            <li class="text self-start leading-8">&bull;
              {{ $t('clients.datev_import.x_clients_skipped', {"count": skippedClient}) }}
            </li>
            <li class="text self-start leading-8">&bull;
              {{ $t('clients.datev_import.x_clients_import_failed', {"count": failedClient}) }}
            </li>
            <li class="text self-start leading-8" v-if="tokenExpired">&bull;
              {{ $t('clients.datev_import.x_clients_import_failed_token_expired', {"count": tokenExpired}) }}
            </li>
          </ul>

          <div v-if="showFailedRecordList" class="mt-10">
            <label>{{ $t('clients.datev_import.client_list_failed_to_import') }}</label>
            <DataGrid grid-id="datev-failed-clients"
                      class="mt-5"
                      data-endpoint="client/datev_failure_list"
                      :columns="columns"
                      default-sort-attr="client_name"
                      :default-sort-dir="1"
                      :show-create-record="false"
                      :reorderable-columns="false"
                      :resizable-columns="false"
                      :show-select-col="false"
                      :serverParams="serverParams"
                      :auto-load="false"
            />
          </div>

          <div class="flex justify-end space-x-4 mt-20 mr-3">
            <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="backToDashboard"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {UserService} from "@/services/user.service";
import DataGrid from "@/components/DataGrid";
import {mapMutations} from "vuex";

export default {
  name: "ImportStatus",
  components: {DataGrid},
  data() {
    return {
      userService: new UserService(),
      status: "none",
      notification: {},
      columns: [{
        field: 'client_name',
        header: this.$t('clients.attrs.name'),
        isChecked: true,
        sortable: false,
        headerStyle: 'padding-left: 0px;',
        bodyStyle: 'padding-left: 0px;'
      }, {
        field: 'error_text',
        header: this.$t('general.error_grid.error_text'),
        isChecked: true,
        sortable: false
      }]
    }
  },
  computed: {
    getPageTitle() {
      return this.status === 'inProcess' ? this.$t('clients.datev_import.started') : this.$t('clients.datev_import.completed')
    },
    addedClient() {
      if (this.notification.extra_details && this.notification.extra_details.added) {
        return parseInt(this.notification.extra_details.added);
      }
      return 0;
    },
    updatedClient() {
      if (this.notification.extra_details && this.notification.extra_details.updated) {
        return parseInt(this.notification.extra_details.updated);
      }
      return 0;
    },
    skippedClient() {
      if (this.notification.extra_details && this.notification.extra_details.skipped) {
        return parseInt(this.notification.extra_details.skipped);
      }
      return 0;
    },
    failedClient() {
      if (this.notification.extra_details && this.notification.extra_details.failed) {
        return parseInt(this.notification.extra_details.failed);
      }
      return 0;
    },
    tokenExpired() {
      if (this.notification.extra_details && this.notification.extra_details.tokenExpired) {
        return parseInt(this.notification.extra_details.tokenExpired);
      }
      return 0;
    },
    showFailedRecordList() {
      return !!(this.notification.datevFailedClientRecordCount && parseInt(this.notification.datevFailedClientRecordCount) > 0);
    },
    serverParams() {
      if (this.notification.extra_details && this.notification.extra_details.jobId) {
        return {
          "jobId": this.notification.extra_details.jobId
        }
      }
      return {}
    },
    nothingChanged() {
      return this.addedClient === 0 && this.updatedClient === 0 && this.failedClient === 0 && this.tokenExpired === 0;
    }
  },
  watch: {
    '$route'(to) {
      if (typeof this.notification.prim_uid === 'undefined' || (this.notification.prim_uid && this.notification.prim_uid !== to.query.uid)) {
        this.fetchNotification();
      }
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    backToDashboard() {
      this.$router.push('/home');
    },
    fetchNotification() {
      this.status = 'none';
      this.userService.getNotification(this.$route.query.uid).then((response) => {
        if (response.data.success && response.data.notification && response.data.notification.prim_uid) {
          if (response.data.notification.extra_details) {
            response.data.notification.extra_details = JSON.parse(response.data.notification.extra_details);
          }
          this.notification = response.data.notification;
          this.status = this.notification.notification_type === "DATEV_CLIENT_IMPORT_COMPLETED" ? "completed" : "inProcess";

          if(this.showFailedRecordList) {
            this.triggerReload('datev-failed-clients');
          }
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
