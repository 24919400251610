<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1'}"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        {{ $t('declarations.detail.assessment_rate_modal.title') }}
      </h3>
    </template>

    <div class="text-left">
      <p>
        {{ $t('declarations.detail.assessment_rate_modal.description') }}
      </p>
    </div>

    <div class="mt-3">
      <InputText
        v-model="assessmentRateFormField"
        :label="$t('declarations.detail.assessment_rate_modal.assessment_rate_label')"
        :placeholder="$t('declarations.detail.assessment_rate_modal.assessment_rate_placeholder')"
        :isOnlyNumber="true"
        :isOnlyPositive="true"
        :maxLength="4"
      />
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse gap-3">
        <Button
          :text="$t('buttons.save')"
          @click="saveAssessmentRate"
        />
        <Button
          :text="$t('buttons.cancel')"
          :secondary="true"
          @click="isVisible = false"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import { mapGetters } from 'vuex';

import { DeclarationService } from '@/services/declaration.service';

export default {
  name: 'ChangeAssessmentRateModal',
  emits: ['close', 'change'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    assessmentRate: {
      type: String,
      default: ''
    },
    declarationUid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      assessmentRateFormField: '',
      declarationService: new DeclarationService(),
    }
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
  },
  watch: {
    isVisible: {
      handler(newValue, oldValue) {
        if (newValue === true && oldValue === false) {
          this.assessmentRateFormField = this.assessmentRate;
        }
      }
    },
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),

    saveAssessmentRate() {
      if (this.assessmentRate !== this.assessmentRateFormField) {
        this.declarationService.saveAssessmentRate({
          prim_uid: this.declarationUid,
          assessmentRate: this.assessmentRateFormField
        })
          .then((res) => {
            if (res) {
              this.isVisible = false;
              this.$emit('change', this.assessmentRateFormField);
            }
          });
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: 95vh;
  max-height: 18em;
  max-width: 40em;
  width: 80vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
