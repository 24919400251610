<template>
  <div class="ml-15 mr-15">
    <div class="mt-18 flex flex-col space-y-4 pr-6">
      <!--      <span class=" import-label flex justify-start mt-2 mb-1  cursor-pointer">Import land register data</span>-->

      <div class="flex justify-around space-x-8">
        <InputSelect class="mt-2 w-full" :label="$t('components.report_steps.step3.label1')" :options="chartType"
                     :placeholder="$t('general.please_select')" :isRequired="true"/>
        <div class="mt-2 w-full invisible"/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputSelect class="mt-2 w-full" :label="$t('components.report_steps.step3.label2')" :options="xAxis"
                     :placeholder="$t('general.please_select')" :isRequired="true"/>
        <InputSelect class="mt-2 w-full " :label="$t('components.report_steps.step3.label3')" :options="yAxis"
                     :placeholder="$t('general.please_select')" :isRequired="true" :showHelpIcon="true"/>
      </div>

      <div class="mt-8 ">
        <div class="flex   justify-end space-x-4 mt-10 w-full">
          <Button @click="goBack" class="w-60" :secondary="true" :text="$t('buttons.back')">
          </Button>
          <Button @click="submitNext" class="w-60  " :text="$t('buttons.save_view')">

          </Button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {optionsClientsAndEmployees, tools} from "@/core/constants";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "Step3",
  data() {
    return {
      clients: optionsClientsAndEmployees,
      xAxis: this.$t('components.report_create.list5'),
      yAxis: this.$t('components.report_create.list6'),
      chartType: this.$t('components.report_create.list4'),
      tools: tools,
      step3: {
        clients: '',
        employees: '',
        tools: '',
      }
    }
  },
  validations: {
    username: {
      required, email
    },
    password: {
      required
    }
  },
  computed: {
    ...mapGetters("report", ["getUserData"]),
  },
  mounted() {
    this.step3.clients = this.getUserData.clients
    this.step3.employees = this.getUserData.employees
    this.step3.tools = this.getUserData.tools
  },
  methods: {
    ...mapMutations("report", ["setCurrentStep"]),
    submitNext() {
      this.setCurrentStep(1)
      // this.setThirdStepData(this.step3)
      // this.$emit("goToNextStep");
      this.$emit("finish")
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">


.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}
</style>
