<template>
  <div
    class="main flex relative"
    :class="customClasses"
    v-on:click.stop="handleClick"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    v-tooltip="getTooltip"
  >
    <div class="vertical-line-declaration-item" :class="customClasses"/>

    <div class="flex flex-col wrapper-item">
      <div class="flex self-start w-full">
        <div class="flex flex-col mx-2 mb-1 space-y-1">
          <label class="id">{{ item.uid }}</label>

          <label :class="delarationNumberClasses">{{ item.declarationNumber }}</label>

          <div class="flex w-full justify-between items-center">
            <label class="name self-start">{{ item.client.clientName }}</label>
            <img
              class="delete_icon"
              v-show="hover"
              v-if="showDeleteIcon"
              :src="getAssetPath('delete_gray.svg')"
              v-on:click.stop="deleteItem"
            />
          </div>

          <label class="message">{{ item.property.name }}</label>

          <Tag
            v-if="item.status === 'dispute' && item.appealType.length > 0"
            :class="[
              {'bg-green': item.elsterMessageAppealsCount > 0},
              {'bg-red': item.elsterMessageAppealsCount === 0}
            ]"
            v-tooltip.bottom="massAppealTypeName"
          >
            <span v-if="item.appealType === 'mass'">{{ $t('declarations.detail.appeal_type_selection.appeal_type_mass') }}</span>
            <span v-else>{{ $t('declarations.detail.appeal_type_selection.appeal_type_single') }}</span>
          </Tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltip from 'primevue/tooltip';
import Tag from 'primevue/tag';

export default {
  name: "DeclarationItem",
  directives: {'tooltip': tooltip},
  components: {Tag},
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    massElsterMessageTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    customClasses() {
      return [
        {'dispute': this.item && this.item.status === 'dispute' && this.item.elsterMessageAppealsCount === 0},
        {'elster_submission': this.item && this.item.status === 'elster_submission' && this.item.elsterSubmissionApproved === 0},
        {'elster_submission_approved': this.item && this.item.status === 'elster_submission' && this.item.elsterSubmissionApproved === 1},
        {'completed': this.item && this.item.status === 'completed'}
      ]
    },
    delarationNumberClasses() {
      return [
        {
          'declaration-number': true,
          'smaller': this.item && this.item.declarationNumber.length > 24,
          'self-start': true
        }
      ]
    },
    showDeleteIcon() {
      if (this.item && this.item.elsterSubmissionTransferTicket !== '') {
        return false;
      }

      return true;
    },
    getTooltip() {
      if (this.item && this.item.status === 'elster_submission') {
        return this.item.elsterSubmissionApproved === 1 ? this.$t('declarations.submission_approval_tooltips.tip2') : this.$t('declarations.submission_approval_tooltips.tip1');
      }

      return {
        disabled: true,
        value: ''
      }
    },
    massAppealTypeName() {
      let massAppealTypeName = this.$t('declarations.detail.appeal_type_selection.appeal_type_single_tooltip');

      if (this.item.appealType === 'mass') {
        massAppealTypeName = this.$t('declarations.detail.appeal_type_selection.mass_appeal_type_not_found');

        this.massElsterMessageTypes.forEach((massElsterMessageType) => {
          if (parseInt(this.item.massAppealType) === massElsterMessageType.prim_uid) {
            massAppealTypeName = massElsterMessageType.name;
          }
        });
      }

      return massAppealTypeName;
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
    deleteItem() {
      let params = {
        headerText: this.$t('general.grid.delete_record'),
        title: this.$t('general.grid.delete_confirm'),
        showClose: true,
        canEscape: true,
        onConfirm: () => {
          this.$emit('delete', this.item);
        }
      };
      this.$modal.show(params);
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  cursor: pointer;
  width: 93%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.04);
  margin-top: 15px;
  margin-left: 6px;

  &.dispute {
    background-color: #fdeced;
  }

  &.elster_submission, &.elster_submission_approved {
    background-color: #f8f9f2;
  }

  &.completed {
    background-color: #F8F8F8;
  }

  .wrapper-item {
    min-height: 130px;
    overflow-y: hidden;
    width: 100%;
  }
}

.main:hover {
  border-color: #A4A4A4;
}

.vertical-line-declaration-item {
  width: 1px;
  padding: 2px 2px 2px 2px;
  background-color: $declaration-vertical-line;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.04);

  &.dispute {
    background-color: theme('colors.red');
  }

  &.elster_submission {
    background-color: #eacd47;
  }

  &.completed {
    background-color: #C0C0C0;
  }
}

.avatar-wrapper {
  width: 40px;
  height: 28px;
  border-radius: 22px;
}

.avatar {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  font-size: 10px;
}

.message {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #777777;
  text-align: left;
  cursor: pointer;
  font-size: 13px;
}

.name {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  cursor: pointer;
  font-size: 14px;
}

.id {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #777777;
  text-align: left;
  line-height: 20px;
  font-size: 13px;
  margin-top: 5px;
  cursor: pointer;
}

.declaration-number {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #777777;
  font-size: 13px;
  text-align: left;
  line-height: 18px;
  margin-top: 5px;
  cursor: pointer;

  &.smaller {
    font-size: 11px;
  }
}

.delete_icon {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
