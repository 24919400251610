<template>
  <MainLayout value="ggw">
    <template>
      <component :is="comp"/>
    </template>
  </MainLayout>
</template>

<script>
export default {
  name: "Ggw",
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.clickable-declaration_number {
  text-decoration: underline;
}
</style>
