const state = {
    userData: {
        name: '',
        email: '',
        phone: '',
        company: '',
        account_fname: '',
        account_lname: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        clients: '',
        employees: '',
        tools: '',
        password: '',
        salutation_elster: "0",
        salutation: ''
    },
    currentStep: 1


};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getUserData: (state) => state.userData,
}

const mutations = {
    setCurrentStep: (state, step) => {
        state.currentStep = step;
    },
    setFirstStepData: (state, step1) => {
        state.userData.name = step1.name;
        state.userData.email = step1.email;
        state.userData.phone = step1.phone;
        state.userData.salutation = step1.salutation ? step1.salutation : null;
    },
    setSecondStepData: (state, step2) => {
        state.userData.salutation_elster = step2.salutation_elster;
        state.userData.company = step2.company;
        state.userData.company2 = step2.company2;
        state.userData.account_fname = step2.account_fname;
        state.userData.account_lname = step2.account_lname;
        state.userData.street = step2.street;
        state.userData.zip = step2.zip;
        state.userData.city = step2.city;
        state.userData.country = step2.country;
    },
    setThirdStepData: (state, step3) => {
        state.userData.branches = step3.branches;
        state.userData.clients = step3.clients;
        state.userData.employees = step3.employees;
        state.userData.tools = step3.tools;
    },
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
