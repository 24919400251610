<template>
  <MainLayout value="clients" v-if="!verifyingAccess">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MainLayout from "../layouts/MainLayout";
import list from "@/components/clients/Clients";
import add from "@/components/clients/AddClient";
import edit from "@/components/clients/EditClient";
import ImportStatus from "@/components/clients/ImportStatus";
import {appMixin} from "@/core/utils/mixin";

export default {
  name: "Client",
  components: {MainLayout, list, add, edit, ImportStatus},
  mixins: [appMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters("client", ["getClientDataChanged"]),
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('clients');
  },
  methods: {
    ...mapMutations("client", ["setClientDataChanged"]),
    handleComponentClick(data) {
      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          break;
        case 'add_client':
          this.$router.push('/client/add')
          break;
        case 'edit_client':
          this.$router.push('/client/edit')
          break;
        case 'client_add_finish':
          this.$router.push('/client/list')
          break;
      }
    },
  },
  beforeRouteLeave (to, from, next)
  {
    if (this.getClientDataChanged) {
      this.$modal.show({
        headerText: this.$t('general.unsaved-change-exist-header'),
        text: this.$t('general.unsaved-change-exist-info'),
        showClose: true,
        canEscape: true,
        inverseBtnColor: true,
        buttons: {
          ok: this.$t('general.leave-page'),
          cancel: this.$t('general.cancel'),
          delete: false
        },
        onConfirm: () => {
          this.setClientDataChanged(false);
          next();
        },
        onCancel: () => {
          return;
        },
        onHide: () => {
          return;
        }
      });
    } else {
      next();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
