<template>
  <Dialog
    :modal="true"
    :closable="isClosable"
    :closeOnEscape="isClosable"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1'}"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        <span v-if="subscriptionExpired">{{ $t('settings.billing.select_package') }}</span>
        <span v-else-if="changeSubscriptionAfterEndTerm">{{ $t('settings.billing.select_after_end_term') }}</span>
        <span v-else>{{ $t('settings.billing.choose_package') }}</span>
      </h3>
    </template>

    <div>
      <SelectPackageTable
        :changeSubscriptionAfterEndTerm="changeSubscriptionAfterEndTerm"
        @downgraded="isVisible = false"
        @upgraded="isVisible = false"
      />
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse">
        <Button
          v-if="isClosable"
          :text="$t('buttons.cancel')"
          :secondary="true"
          @click="isVisible = false"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import SelectPackageTable from '@/components/Billing/SelectPackageTable.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'ChangeSubscriptionModal',
  emits: ['close'],
  components: {
    Dialog,
    SelectPackageTable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    changeSubscriptionAfterEndTerm: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
    isClosable() {
      return this.closable && !this.subscriptionExpired;
    },
    subscriptionExpired() {
      if (this.getCurrentUser().subscriptionExpired) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: 95vh;
  max-height: 95vh;
  max-width: 1240px;
  width: 80vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
