<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.document_sub.review_notice')" :breadcrumbs="breadcrumbs" :sticky="true"/>
    <ModalDialog v-show="showCompareDocumentsDialog" modalSize="x-large" @close="showCompareDocumentsDialog=false;"
                 class="document-review-dialog"
                 :headerText="$t('declarations.detail.buttons.review.title')">
      <template v-slot:body>
        <div class="flex justify-around document-switch">
          <InputSelect
            class="w-1/3 mt-2"
            label=""
            :options="taxDocumentSwitchOptions"
            :selected-value="selectedTaxDocumentSwitchOption"
            overlay-append-to="body"
            @on-item-selected="taxDocumentTypeChanged"
          />
          <div class="w-1/3 mt-2">
          </div>
        </div>
        <div class="flex justify-around space-x-5">

          <div class="left-document w-1/2">
            <div v-show="taxDocuments.showDeadline || taxDocuments.askDeadline"
                 class="flex flex-col justify-start space-y-4">
              <label class="text_bold text-left">{{ $t('general.optional') }}:</label>
              <label class="text-left" v-html="$t('bulk_notice.review.deadline_info')"/>
              <label v-show="taxDocuments.askDeadline" class="text-left"
                     v-html="$t('bulk_notice.review.deadline_info_ask')"/>
              <Button v-show="taxDocuments.askDeadline" class=""
                      :text="$t('bulk_notice.review.deadline_info_ask_button')" @click="askAssessmentDeadline(true)"/>
              <div v-show="taxDocuments.showDeadline">
                <div class="info flex justify-start items-center">
                  <img class="info__icon" src="@/assets/info_gray.svg"/>
                  <label class="info__text mr-5" v-html="$t('deadline.add_info')"/>
                </div>
                <InputDate
                    class="w-full"
                    v-model="taxDocuments.deadline.document_date"
                    :label="$t('deadline.attrs.document_date')"
                    appendTo="body"
                    :showHelpIcon="true"
                    :tooltip="$t('deadline.attrs_help.document_date')"
                    @date-selected="calculateDeadlineDate($event, 'document_date')"
                />
                <InputDate
                    class="w-full"
                    v-model="taxDocuments.deadline.announcement_date"
                    :label="$t('deadline.attrs.announcement_date')"
                    appendTo="body"
                    :showHelpIconPopover="true"
                    :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
                    @date-selected="calculateDeadlineDate($event, 'announcement_date')"
                />
                <InputDate
                    class="w-full"
                    v-model="taxDocuments.deadline.deadline_reminder"
                    :label="$t('deadline.attrs.deadline_reminder')"
                    appendTo="body"
                    :showHelpIcon="true"
                    :tooltip="$t('deadline.attrs_help.deadline_reminder')"
                />
                <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                           v-model="taxDocuments.deadline.deadline"
                           :disabled="true"
                           :showHelpIconPopover="true"
                           :popoverTexts="[$t('deadline.attrs_help.deadline')]"
                />
              </div>
            </div>
            <vue-pdf-embed v-show="!taxDocuments.showDeadline && !taxDocuments.askDeadline"
                           :source="sourceObjectForPdfjs(taxDocuments.taxCalculation.fileSource)"/>
          </div>
          <div class="right-document w-1/2">
            <vue-pdf-embed :source="sourceObjectForPdfjs(taxDocuments.taxAssessment.fileSource)"/>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 w-half justify-end space-x-4"
             v-show="!taxDocuments.showDeadline && !taxDocuments.askDeadline">
          <div class="flex w-full pl-5 justify-start space-x-4">
            <Button :icon="getAssetPath('edit-property-white.svg')" :text="$t('declarations.detail.edit_property')"
                                                                        @click="editProperty(false)" />
          </div>
          <div class="flex  w-full justify-end space-x-4">
          <Button :secondary="true" :text="$t('buttons.cancel')" @click="showCompareDocumentsDialog=false;"/>
          <Button :icon="getAssetPath('thumbs_up.svg')" :text="$t('declarations.detail.tax_assessment_fine')"
                  v-show="taxDocuments.showAccept"
                  @click="updateTaxAssessment('value_in_order')"/>
          <Button :icon="getAssetPath('thumbs_down.svg')" :text="$t('declarations.detail.tax_assessment_dispute')"
                  v-show="taxDocuments.showReject"
                  @click="showAssessmentDeadline"/>
          </div>
        </div>
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4"
             v-show="taxDocuments.showDeadline || taxDocuments.askDeadline">
          <Button :secondary="true" :text="$t('buttons.back')" @click="showCompareDocumentsDialog=false;"/>
          <Button class="" :text="$t('buttons.next')" @click="updateTaxAssessment('not_in_order')"/>
        </div>
      </template>
    </ModalDialog>

    <AppealTypeSelectionModal
      :visible="showAppealTypeSelectionModal"
      :declarationUid="parseInt(declarationForAppealTypeSelection.declarationId ?? 0)"
      :federalStateUid="parseInt(declarationForAppealTypeSelection.federalStateUid ?? 0)"
      :currentAppealType="declarationForAppealTypeSelection.appealType ?? ''"
      :currentMassAppealType="parseInt(declarationForAppealTypeSelection.massAppealType ?? 0)"
      :currentMassAppealActDate="declarationForAppealTypeSelection.massAppealActDate ?? ''"
      @close="closeAppealTypeSelectionModal"
      @savedAppealType="savedAppealType"
    />

    <CommunitySelector v-show="showCommunitySelect"
                       :federalStateId="federalStateForCommunitySelection"
                       :declarationId="declarationUidForCommunitySelection"
                       :context="contextForCommunitySelection"
                       @close=communitySelectionCloseHandler />
      <div class="mr-7 notice_grid" v-show="step === 1">
        <div class="flex flex-col justify-start mt-5">
          <div class="info flex justify-start items-center">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text" v-html="$t('bulk_notice.review.info')"/>
          </div>
        </div>
        <AppGrid :grid-id="gridId"
                 :local-data="notices"
                 :use-local-data="true"
                 :columns="columns"
                 default-sort-attr="documentName"
                 :default-sort-dir="1"
                 :state-ful-filter="true"
                 :menus="menus"
                 :showSelectCol="true"
                 :show-create-record="false"
                 :show-filter="false"
                 :show-view-menu="false"
                 :pagination="false"
                 @menu-clicked="handleMenuClick"
                 @col-click="handleColClick"
                 @prepend-icon-click="handleColClick"
        />
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4 footer">
          <Button class="" :text="$t('buttons.next')" :disabled="!nextEnabled" @click="updateDeclarations"/>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center mt-5 w-full" v-show="step === 2">
        <div class="flex flex-col items-start w-full">
          <UserMenu :icon="menus[0].icon" :title="menus[0].title"
                    @click="handleMenuClick(menus[0])"
          />
        </div>
        <div class="mt-16 flex flex-col justify-center items-center w-1/2">
          <div class="finish-check-icon-wrapper">
            <img :src="getAssetPath('check_done.svg')"/>
          </div>
          <label class="mt-10 text_bold ">{{ $t('bulk_notice.review.success') }}</label>
          <label class="mt-5 text-center pl-10 pr-10 ">{{ $t('bulk_notice.review.success_info') }}</label>
          <div class="flex mt-10 space-x-6">
            <Button class="w-64" :text="$t('buttons.next')" @click="goToDeclarationOverview"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AppGrid from "@/components/AppGrid";
import {DeclarationService} from "@/services/declaration.service";
import {DocumentService} from "@/services/document.service";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import _clone from "lodash/clone";
import CommunitySelector from "@/components/declarations/components/CommunitySelector.vue";
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";
import AppealTypeSelectionModal from '@/components/declarations/components/AppealTypeSelectionModal.vue';

export default {
  name: "reviewNotice",
  components: {
    CommunitySelector,
    AppGrid,
    VuePdfEmbed,
    AppealTypeSelectionModal
  },
  mixins: [pdfjsCVEMixin],
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {
      service: new DeclarationService(),
      documentService: new DocumentService(),
      breadcrumbs: [
        {
          title: this.$t('sidebar.document'),
          link: '/documents/list'
        }, {
          title: this.$t('sidebar.document_sub.review_notice'),
          link: ''
        }
      ],
      gridId: 'notices',
      notices: [],
      checkedNotices: [],
      columns: [
        {
          field: 'documentName',
          header: this.$t('bulk_notice.grid.cols.description'),
          filterLbl: this.$t('bulk_notice.grid.cols.description') + ' ' + this.$t('general.contains'),
          isChecked: true,
          hideInFilter: true,
          elType: 'text',
          width: 150
        }, {
          field: 'documentType',
          header: this.$t('bulk_notice.grid.cols.documentType'),
          isChecked: true,
          hideInFilter: true,
          elType: 'select',
          filterOptions: this.$t('deadline.document_types'),
          width: 150
        }, {
          field: 'declarationUid',
          header: this.$t('bulk_notice.grid.cols.declarationUid'),
          filterLbl: this.$t('bulk_notice.grid.cols.declarationUid') + ' ' + this.$t('general.contains'),
          isChecked: true,
          clickable: true,
          hideInFilter: true,
          elType: 'text',
          width: 200
        }, {
          field: 'clientName',
          header: this.$t('bulk_notice.grid.cols.clientName'),
          isChecked: true,
          clickable: true,
          elType: 'text',
          width: 200
        }, {
          field: 'taxValue',
          header: this.$t('bulk_notice.grid.cols.taxValue'),
          isChecked: true,
          hideInFilter: true,
          elType: 'text',
          clickable: true,
          html: true,
          width: 200,
          bodyClass: "currency-col"
        }, {
          field: 'noticeValue',
          header: this.$t('bulk_notice.grid.cols.noticeValue'),
          isChecked: true,
          hideInFilter: true,
          elType: 'text',
          width: 200,
          bodyClass: "currency-col"
        }, {
          field: 'noticeStatus',
          header: this.$t('bulk_notice.grid.cols.noticeStatus'),
          isChecked: true,
          hideInFilter: true,
          elType: 'text',
          clickable: true,
          sortable: false,
          width: 250,
          html: true,
          prependIcon: true,
          prependIconClickable: true,
          getIcon: (row) => {
            return require('@/assets/upload_doc.svg')
          },
        }
      ],
      menus: [
        {
          id: "upload_notice",
          "icon": this.getAssetPath('upload.svg'),
          "title": this.$t('sidebar.document_sub.upload_notice')
        }, {
          id: "reload_data",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      showCompareDocumentsDialog: false,
      taxDocuments: {
        notice: {},
        showAccept: false,
        showReject: false,
        askDeadline: false,
        showDeadline: false,
        stateUid: 0,
        taxCalculation: {
          fileSource: ''
        },
        taxAssessment: {
          fileSource: ''
        },
        deadline: {
          "declarationId": 0,
          "document": 0,
          "document_type": "",
          "document_date": "",
          "announcement_date": "",
          "deadline_reminder": "",
          "deadline": ""
        }
      },
      step: 1,
      showCommunitySelect: false,
      declarationUidForCommunitySelection: 0,
      federalStateForCommunitySelection: 0,
      contextForCommunitySelection: null,
      selectedTaxDocumentSwitchOption: '0',

      showAppealTypeSelectionModal: false,
      declarationForAppealTypeSelection: {},

      submissions: {},
      submissionsDeclarationId: 0,
    }
  },
  watch: {
    notices: {
      handler(val) {
        this.checkedNotices = val.length > 0 ? val.filter(o => o.statusKey !== 'check_manually') : [];
      },
      deep: true
    }
  },
  computed: {
    taxDocumentSwitchOptions() {
      let taxDocumentSwitchOptions = [];
      let lastIndex = 0;

      if (Array.isArray(this.submissions)) {
        this.submissions.map((submission, index) => {
          let optionName = '';

          if (typeof submission.elsterMessage !== 'undefined') {
            optionName = this.$t(
              'declarations.detail.tax_calculation_options.submitted',
              {date: submission.elsterMessage.elsterSubmissionReceivedDate}
            );
          } else if (typeof submission.freezedProperty !== 'undefined') {
            optionName = this.$t(
              'declarations.detail.tax_calculation_options.freezed',
              {date: submission.freezedProperty.createdAt}
            )
          }

          taxDocumentSwitchOptions.push({
            code: index.toString(),
            name: optionName
          });

          lastIndex = index;
        });

        lastIndex++;
      }

      taxDocumentSwitchOptions.push({
        'code': lastIndex.toString(10),
        'name': this.$t('declarations.detail.tax_calculation_options.actual')
      });

      return taxDocumentSwitchOptions;
    },
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('sidebar.document')}`
    },
    nextEnabled() {
      return this.checkedNotices.length > 0;
    },
  },
  mounted() {
    this.fetchNotices();

    // Fetch list of holidays from api and cache
    this.$api.get_state_holidays();
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
    ...mapMutations("grid", ["triggerReload"]),
    fetchNotices() {
      this.notices = [];
      this.$api.trigger('notice/list', null, true).then((response) => {
        if (response.data.success && response.data.records && response.data.total_records > 0) {
          this.notices = this.$api.parse_object_data(response.data.records);
        }
        this.triggerReload(this.gridId);
      });
    },
    getTheme() {
      return this.getCurrentTheme()
    },
    handleMenuClick(e) {
      if (e.menuId === 'upload_notice' || e.id === 'upload_notice') {
        this.$router.push('/documents/upload_notice');
      } else if (e.menuId === 'reload_data') {
        this.fetchNotices();
      }
    },
    async handleColClick(e) {
      if (e.field === 'taxValue' && e.row.taxError === 'no_community') {
        this.declarationUidForCommunitySelection = e.row.declarationId;
        this.federalStateForCommunitySelection = e.row.federalStateUid;
        this.showCommunitySelect = true;
        this.contextForCommunitySelection = {noticeId: e.row.id}
        return;
      }
      if (e.field === 'declarationUid' && e.row.declarationId) {
        this.$router.push('/declaration/edit?uid=' + e.row.declarationId);
      } else if (e.field === 'noticeStatus' && e.row.declarationId && e.row.documentUid) {
        this.declarationForAppealTypeSelection = e.row;

        this.taxDocuments = {
          notice: e.row,
          showAccept: e.row.statusKey === 'check_manually' || e.row.statusKey === 'not_in_order',
          showReject: e.row.statusKey === 'check_manually' || e.row.statusKey === 'value_in_order',
          askDeadline: false,
          showDeadline: false,
          stateUid: e.row.federalStateUid,
          taxCalculation: {
            fileSource: ''
          },
          taxAssessment: {
            fileSource: ''
          },
          deadline: {
            "declarationId": e.row.declarationId,
            "document": e.row.documentUid,
            "document_type": "",
            "document_date": "",
            "announcement_date": "",
            "deadline_reminder": "",
            "deadline": ""
          }
        };
        this.$loading.show();
        this.documentService.setDefaultParams({
          "parentType": 'declaration',
          "parentId": e.row.declarationId
        });

        await this.getSubmissions(e.row.declarationId);

        let promises = [];
        const submissionIndex = parseInt(this.selectedTaxDocumentSwitchOption);

        let documentType = (submissionIndex < this.taxDocumentSwitchOptions.length - 1)
            ? 'tax_calculation'
            : 'tax_calculation_modified';

        let freezedPropertyId = 0;
        let taxCalculationDocumentId = 0;
        let elsterSubmissionReceivedDate = '';

        if (documentType === 'tax_calculation') {
          if (typeof (this.submissions[submissionIndex].freezedProperty) !== 'undefined') {
            freezedPropertyId = this.submissions[submissionIndex].freezedProperty.id;
          }

          if (typeof (this.submissions[submissionIndex].taxCalculationDocument) !== 'undefined') {
            taxCalculationDocumentId = this.submissions[submissionIndex].taxCalculationDocument.id;
          }

          if (typeof (this.submissions[submissionIndex].elsterMessage) !== 'undefined') {
            elsterSubmissionReceivedDate = this.submissions[submissionIndex].elsterMessage.elsterSubmissionReceivedDate;
          }
        }

        promises.push(
          this.service.getTaxDocument(
            e.row.declarationId,
            documentType,
            false,
            freezedPropertyId,
            taxCalculationDocumentId,
            elsterSubmissionReceivedDate
          )
        );

        promises.push(this.documentService.get(e.row.documentUid));

        Promise.all(promises).then((documents) => {
          let documentDownloaded = true;
          for (let document of documents) {
            if (document === '') {
              continue;
            }

            if (!document.prim_uid && !document.content) {
              documentDownloaded = false;
              continue;
            }

            if (parseInt(document.prim_uid) !== parseInt(e.row.documentUid)) {
              this.taxDocuments.taxCalculation.fileSource = `data:${document.mime_type};base64,${document.content}`;
              continue;
            }

            this.taxDocuments.deadline.document_type = document.document_type;
            this.taxDocuments.taxAssessment.fileSource = `data:${document.mime_type};base64,${document.content}`;
          }

          if (documentDownloaded) {
            this.showCompareDocumentsDialog = true;
          }

          this.$loading.hide();
        });
      }
    },
    updateTaxAssessment(status) {
      let index = this.notices.findIndex(o => parseInt(o.id) === parseInt(this.taxDocuments.notice.id))
      if (index >= 0) {
        if (this.selectedTaxDocumentSwitchOption === '1') {
          this.service.getTaxDocument(this.taxDocuments.notice.declarationId, 'tax_calculation_modified', true);
        }

        this.notices[index]['statusKey'] = status;
        let statusCls = status === 'not_in_order' ? 'red_text' : 'green_text';
        this.notices[index]['noticeStatus'] = '<span class="' + statusCls + '">' + this.$t('bulk_notice.review_status.' + status) + '</span>';
        this.notices[index]['deadline'] = this.taxDocuments.deadline;

        if (status !== 'not_in_order') {
          this.closeCompareDocumentsDialog();
          return;
        }

        this.showAppealTypeSelectionModal = true;
      }
    },
    askAssessmentDeadline(ask) {
      if (!ask) {
        this.taxDocuments.askDeadline = true;
      } else {
        this.taxDocuments.askDeadline = false;
        if (this.taxDocuments.notice.documentDate > 0) {
          let document_date = '';
          let d = new Date(this.taxDocuments.notice.documentDate * 1000);
          let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
          let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
          let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
          document_date = `${ye}-${mo}-${da}`;
          this.taxDocuments.deadline.document_date = document_date;
          this.calculateDeadlineDate(document_date, 'document_date');
        }
        this.taxDocuments.showDeadline = true;
      }
    },
    showAssessmentDeadline() {
      this.askAssessmentDeadline(false);
    },
    updateDeclarations() {
      let postData = {};
      postData['notices'] = this.notices.filter(o => o.statusKey !== 'check_manually').map((o) => {
        return {
          "noticeId": o.id,
          "status": o.statusKey === 'value_in_order' ? 1 : 2,
          "deadline": o.statusKey === 'not_in_order' ? o.deadline : null
        }
      });
      if (postData['notices'].length > 0) {
        this.$api.trigger('notice/update', postData, true).then((response) => {
          if (response.data.success) {
            this.step = 2;
          } else {
            this.$api.showToast(response.data.message, "error")
          }
        })

        if (postData['notices'][0]['deadline'] !== null) {
          let postDataDeadLine = Object.fromEntries(Object.entries(postData['notices']['0']['deadline']));
          this.$api.trigger('deadline/save', postDataDeadLine, true).then((response) => {
            if (!response.data.success) {
              this.$api.showToast(response.data.message, 'error');
            }
          });
        }
      }
    },
    goToDeclarationOverview() {
      this.$router.push('/declaration/declarations');
    },
    calculateDeadlineDate(val, elName) {
      let date = null

      if (val !== '') {
        date = new Date(val);
      }

      if (date instanceof Date) {
        if (elName === 'document_date') {
          this.taxDocuments.deadline.announcement_date = this.calculateAnnouncementDate(date, this.taxDocuments.stateUid, 3, true);
          this.taxDocuments.deadline.deadline = this.calculateAnnouncementDate(_clone(this.taxDocuments.deadline.announcement_date), this.taxDocuments.stateUid, '1M', true);
          this.taxDocuments.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.taxDocuments.deadline.deadline), this.taxDocuments.stateUid, -7, false);
        } else if (elName === 'announcement_date') {
          this.taxDocuments.deadline.deadline = this.calculateAnnouncementDate(date, this.taxDocuments.stateUid, '1M', true);
          this.taxDocuments.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.taxDocuments.deadline.deadline), this.taxDocuments.stateUid, -7, false);
        }
      }
    },
    communitySelectionCloseHandler({community, declarationId,context}) {
      this.showCommunitySelect = false;
      if (community && declarationId) {
        this.updateTaxCommunity(community, declarationId,context)
      }
    },
    updateTaxCommunity(community, declarationId, {noticeId}) {
      this.$api.trigger(
        'notice/updateTaxValue',
        {community, declarationId, noticeId},
        true
      ).then((response) => {
        if (response.data.success) {
          this.fetchNotices();
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      });
    },
    taxDocumentTypeChanged(e) {
      if (this.selectedDocumentSwitchOption === e.code) {
        return;
      }

      this.$loading.show();
      this.selectedTaxDocumentSwitchOption = e.code;

      const submissionIndex = parseInt(e.code, 10);

      const type = (submissionIndex < this.taxDocumentSwitchOptions.length - 1)
          ? 'tax_calculation'
          : 'tax_calculation_modified';

      let freezedPropertyId = 0;
      let taxCalculationDocumentId = 0;
      let elsterSubmissionReceivedDate = '';

      if (type === 'tax_calculation') {
        if (typeof (this.submissions[submissionIndex].freezedProperty) !== 'undefined') {
          freezedPropertyId = this.submissions[submissionIndex].freezedProperty.id;
        }
        if (typeof (this.submissions[submissionIndex].taxCalculationDocument) !== 'undefined') {
          taxCalculationDocumentId = this.submissions[submissionIndex].taxCalculationDocument.id;
        }
        if (typeof (this.submissions[submissionIndex].elsterMessage) !== 'undefined') {
          elsterSubmissionReceivedDate = this.submissions[submissionIndex].elsterMessage.elsterSubmissionReceivedDate;
        }
      }

      this.service.getTaxDocument(
          this.submissionsDeclarationId,
          type,
          false,
          freezedPropertyId,
          taxCalculationDocumentId,
          elsterSubmissionReceivedDate
      ).then((document) => {
        if (document.content) {
          this.taxDocuments.taxCalculation.fileSource = `data:application/pdf;base64,${document.content}`;
        } else {
          this.taxDocuments.taxCalculation.fileSource = '';
        }
      }).finally(() => {
        this.$loading.hide();
      })
    },
    async editProperty(useFrozen = true) {
      const declaration =  await this.service.get(this.taxDocuments.notice.declarationId).finally(() => {
        this.$loading.hide();
      });

      if (declaration.freezedPropertyId && useFrozen) {
        this.$router.push('/property/edit?uid=' + declaration.freezedPropertyId);
      } else if (declaration.propertyId) {
        this.$router.push('/property/edit?uid=' + declaration.propertyId);
      }
    },
    closeCompareDocumentsDialog() {
      this.declarationForAppealTypeSelection = {};
      this.triggerReload(this.gridId);
      this.showCompareDocumentsDialog = false;
    },
    closeAppealTypeSelectionModal() {
      this.showAppealTypeSelectionModal = false;
    },
    savedAppealType() {
      this.closeCompareDocumentsDialog();
    },
    async getSubmissions(declarationId = 0) {
      const declaration = await this.service.get(declarationId);

      this.submissions = {};
      this.submissionsDeclarationId = declarationId;
      if (typeof declaration.submissions !== 'undefined') {
        this.submissions = JSON.parse(JSON.stringify(declaration.submissions));
      }
    }
  }
}
</script>

<style scoped lang="scss">

.notice_grid {
  ::v-deep {
    .table-container {
      height: calc(100vh - 300px);
    }

    .yellow_text {
      color: #f7c477;
      text-decoration: underline
    }

    .green_text {
      color: #239d57;
    }

    .red_text {
      color: #ff1717;
    }

    .button {
      background-color: $primary;
      color: white;
      height: 32px;
      display: inline-flex;
      flex-direction: row;
      min-width: 168px;
      max-width: 390px;
      padding: 10px;
      cursor: pointer;
    }
    .currency-col:has(:is(.button, .tax-error)) {
      text-align: left !important;
    }
    .currency-col {
      text-align: right;
    }
    .tax-error {
      font-style: italic;
      color: darkgray;
      cursor: auto;
    }
  }
}

.document-review-dialog ::v-deep .modal-x-large {
  width: 95% !important;
  max-width: 95% !important;
  max-height: calc(100vh - 20px) !important;
}

.left-document, .right-document {
  overflow-y: scroll;
  height: 100vh;
}

.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}

.footer {
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}

.document-switch {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 10px;
}
</style>
