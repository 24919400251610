<template>
  <div>
    <div v-show="isWeb && !authVerifying" class="flex w-full h-screen" v-if="NoAccountExist">
      <div class="left flex justify-center items-center lg:w-1/2 sm:w-full bg-white" style="position: relative">
        <div class="topcorner partner-logo"/>
        <div class="flex flex-col justify-between h-full items-center w-2/3 justify-center ">

          <div v-if="getTheme()==='ebnerstolz'" class="flex flex-col mt-20 items-center">
            <a href="https://grundsteuer-digital.de/" target="_blank">
              <img class="mb-4  " src="@/assets/themes/ebnerstolz/dashboard_header.svg">
            </a>
            <label class="mb-10 sub">{{ $t('login.version') }} {{ getVersion }}</label>

          </div>
          <div v-else class="flex flex-col mt-20 justify-center items-center">
            <a href="https://grundsteuer-digital.de/" target="_blank">
              <img class="  " :src="getAssetPath('logo.svg')">
            </a>
            <label class="mb-10 sub">{{ $t('login.version') }} {{ getVersion }}</label>
          </div>

          <transition name="slide" mode="out-in">
            <div key="4" v-if="show_choose_accounts_pnl" style="width: 90%">
              <div class="close_button user_icon">
                <span class="close-br">
                  <img class="w-1/2" src="@/assets/accounts.svg"/>
                </span>
              </div>
              <div class="px-5 usernotexit-text">
                <p class="mb-5"><b>{{ $t('accounts.ofcaccounttext') }}</b></p>
                <div class="account_list" v-for="account in accounts" :key="account.account_uid">
                  <a class="mb-5 mt-5 cursor-pointer" @click="chooseAccount(account)">{{ account.account_name }}</a>
                </div>
                <div class="flex mt-12 mb-10 justify-around space-x-4">
                  <div class="Button_Style_BTN">
                    <a class="btn w-full cursor-pointer" @click="cancelChooseAccount">{{
                        $t('sidebar.popup.logout')
                      }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div key="4" v-if="show_force_password_change_pnl" class=" px-5 otp_pnl" style="width: 90%">
              <div>
                <label>{{ $t('login.change_password_now') }}</label>
                <InputText v-model="newPassword"
                           :isError="isNewPasswordError"
                           :errorMessage="newPasswordError"
                           type="password"
                           :isRequired="true"
                           :label="$t('login.password')"
                           :placeholder="$t('login.passwordPlaceholder')"
                           :requestFocus="true"
                           @keyup="savePassword"
                           :showPasswordTemplate="true"
                           :showPasswordViewIcon="true"
                           class="mt-2 mb-1"/>
                <InputText v-model="confirmPassword"
                           :isError="isConfirmPasswordError"
                           :errorMessage="confirmPasswordError"
                           type="password"
                           :isRequired="true"
                           :label="$t('login.password_confirm')"
                           :placeholder="$t('login.password_confirm')"
                           @keyup="savePassword"
                           :showPasswordViewIcon="true"
                           class="mt-2 mb-5"/>
                <Button @click="savePassword"
                        class=" "
                        :fullwidth="true"
                        :text=" $t('buttons.save')">

                </Button>
              </div>
            </div>
            <div key="3" v-if="show_two_fa_setup_pnl" class=" px-5 w-full otp_pnl">
              <div>
                <div class="OrdnerText">
                  <label class=" text ml-10 mr-10">{{ $t('login.two_fa_setup.authy.info1') }}</label>
                  <label class=" text ml-10 mr-10 mt-4">{{ $t('login.two_fa_setup.authy.info2') }}</label>
                  <label class=" text ml-10 mr-10 mt-4">{{ $t('login.two_fa_setup.authy.info3') }}</label>
                </div>
                <div class="mt-10 flex justify-center mb-10">
                  <img :src="twoFaCodeUrl"/>
                </div>
                <InputText v-model="newOtp" :isError="isNewOtpError" :errorMessage="newOtpError"
                           :label="$t('login.otp_placeholder')" :isRequired="true"
                           :requestFocus="true"
                           @keyup="setupTwoFa"
                           :placeholder="$t('login.otp_placeholder')"/>
                <div class="flex justify-end space-x-10 mt-4">
                  <div class="flex justify-end mt-4 mb-6  ">
                    <Button :text="$t('buttons.submit')" class="mr-5 w-full" @click="setupTwoFa"/>
                    <Button :text="$t('buttons.cancel')" class="w-full" :secondary="true" @click="cancelTwoFaSetup"/>
                  </div>
                </div>
              </div>
            </div>
            <div key="2" v-if="show_two_fa_pnl" class=" px-5 w-full otp_pnl">
              <div>
                <div class="OrdnerText mb-10">
                  <label class=" text_bold ml-10 mr-10">{{ $t('login.two_fa_auth.email.info1') }}</label>
                  <label class=" text text-center-imp mt-4">{{ $t('login.two_fa_auth.email.info2') }}</label>
                  <label class=" text ml-10 mr-10 mt-4">{{ $t('login.two_fa_auth.email.info3') }}</label>
                </div>
                <InputText v-model="otp"
                           :label="$t('login.otp_lbl')" :isRequired="true"
                           :requestFocus="true"
                           @keyup="submit"
                           :placeholder="$t('login.otp_placeholder')"/>
                <div class="flex justify-end  mt-4">
                  <div class="flex justify-end mt-4 mb-6 space-x-4 ">
                    <Button class="w-full" @click="handleTwoFaCancel" secondary :text="$t('buttons.cancel')"></Button>
                    <Button :text="$t('login.otp_button_submit')" @click="submit"/>
                  </div>
                </div>
              </div>
            </div>
            <div key="1" v-if="show_login_pnl" class=" px-5  " style="width: 80%">
              <div>
                <!--                Web login -->
                <InputText v-model="username" :isError="isUsernameError" :errorMessage="usernameError" type="email"
                           :label="$t('login.usernameLabel')" :isRequired="true" :requestFocus="true" @keyup="submit"
                           :placeholder="$t('login.usernamePlaceholder')"/>
                <InputText v-model="password" :isError="isPasswordError" :errorMessage="passwordError" type="password"
                           :isRequired="true" :label="$t('login.password')"
                           :placeholder="$t('login.passwordPlaceholder')"
                           @keyup="submit"
                           class="mt-2 mb-1"/>
                <div class="flex justify-end ">
                  <InputCheck class="mt-3 mb-3 w-full flex-1 " :text=" $t('login.remember')" v-model="remember_me"
                              @check="selectRememberMe"/>
                  <div class="w-full flex-1 flex justify-end mt-3">
                    <label class=" forgot-password   align-end ">
                      <router-link to="/ForgotPassword">{{ $t('login.forgotPassword') }}</router-link>
                    </label>
                  </div>


                </div>

                <div class="flex flex-col w-full items-stretch">
                  <Button @click="submit" class="w-full" :fullwidth="true" :text="$t('login.signin')"></Button>
                </div>


                <div class="flex flex-col" v-show="ifTC">
                  <div class="flex flex-col mt-5">
                    <p>{{ $t('general.or') }}</p>

                    <!--                      old datev login button-->
                    <!--                        <img src="@/assets/datev_login.png" class="cursor-pointer datev_login"-->
                    <!--                             @click="startDatevLogin()"/>-->

                    <!--                      new datev login button-->
                    <div class="flex justify-center items-center">
                      <div class="flex   items-center datev-button" @click="startDatevLogin()">
                        <img :src="getAssetPath('datev_logo.png')"/>
                        <label class="datev-text">{{ $t('buttons.datev_login') }}</label>
                      </div>
                    </div>
                  </div>
                  <div class=" flex justify-center items-center box" v-if="datevOauthErrorCode!==''">
                    <label class="text-xs ">{{ datevOauthErrorCode }}</label>
                  </div>

                  <hr class="hr-line">
                </div>

                <div class="mt-3" v-show="isPilot">
                  <div class="register">
                    <label v-if="getTheme()!=='ebnerstolz'" class=" forgot-password">{{ $t('login.noAccount') }}</label>
                    <span class="label link_create_account ml-1">
                    <router-link v-if="getTheme()!=='ebnerstolz'" to="/register">{{
                        $t('login.create_account')
                      }}</router-link>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="footer flex">
            <span>
              <span class="footer-text"> {{ $t('general.footer.ssl') }} | </span>
              <a class="link"
                 :href="getTheme()==='ebnerstolz'?'https://www.ebnerstolz.de/de/impressum-4908.html':'https://grundsteuer-digital.de/impressum'"
                 target="_blank">{{ $t('general.footer.imprint') }}</a>
              <span class="footer-text"> | </span>
              <a class="link"
                 :href="getTheme()==='ebnerstolz'?'http://ebnerstolz.de/grundsteuer-avv':'https://grundsteuer-digital.de/datenschutz-grundsteuerdigital-anwendung/'"
                 target="_blank">{{ $t('general.footer.protection') }}</a>
              <span class="footer-text"> | ©  {{
                  new Date().getFullYear()
                }} {{ getTheme() === 'ebnerstolz' ? 'Ebner Stolz Digital Solutions GmbH' : 'fino taxtech GmbH' }}</span>

            </span>
          </div>
        </div>

      </div>

      <img class="right lg:w-1/2 lg:visible sm:invisible sm:w-0 " src="@/assets/login_illustration.png"/>
    </div>
    <div v-show="!isWeb" class="flex h-screen justify-center bg-white items-center">
      <div class=" w-530 p-5">
        <div class="flex flex-col items-center  justify-center    bg-white-200">
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img class=" mt-10" :src="getAssetPath('logo.svg')">
          </a>
          <label class="mb-10 sub">{{ getVersion }}</label>
          <div class="py-10 px-5 w-full">
            <div>
              <InputText v-model="username" :isError="isUsernameError" :errorMessage="usernameError" type="email"
                         :label="$t('login.usernameLabel')" :isRequired="true" :requestFocus="true" @keyup="submit"
                         :placeholder="$t('login.usernamePlaceholder')"/>
              <InputText v-model="password" :isError="isPasswordError" :errorMessage="passwordError" type="password"
                         :isRequired="true" :label="$t('login.password')"
                         :placeholder="$t('login.passwordPlaceholder')"
                         @keyup="submit"
                         class="mt-2 mb-1"/>
              <InputCheck class="mt-3 mb-3 " :text=" $t('login.remember')" v-model="remember_me"
                          @check="selectRememberMe"/>
              <div class="flex flex-col w-full items-stretch">
                <Button @click="submit" class="w-full" :fullwidth="true" :text="$t('login.signin')"></Button>

              </div>
              <div class="mt-10">
                <label class="forgot-password">
                  <router-link to="/ForgotPassword">{{ $t('login.forgotPassword') }}</router-link>
                </label>
              </div>
              <div class="mt-3">
                <label class=" forgot-password">{{ $t('login.noAccount') }}</label>
                <span class="label link ml-1"><router-link to="/register">{{ $t('login.signup') }}</router-link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full h-screen" v-if="!NoAccountExist">
      <div class=" flex justify-center items-center w-1/2 bg-white">
        <div class="flex flex-col items-center w-3/5  justify-center ">
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img class=" mb-10 mt-20" :src="getAssetPath('logo.svg')">
          </a>
          <div class="close_button">
              <span class="close-br">
                <img class="w-1/2" src="@/assets/close-icon-white.svg"/>
              </span>
          </div>
          <div class="px-5 usernotexit-text">
            <p>{{ $t('login.text1') }}</p>
            <p>{{ $t('login.text2') }}</p>
            <p>{{ $t('login.text3') }}</p>
            <div class="flex mt-12 mb-10 justify-around space-x-4">
              <div class="Button_Style_BTN">
                <router-link to="/" class="btn w-full">{{ $t('login.Anmeldung') }}</router-link>
                <router-link to="/register" class="btn w-full">{{ $t('login.anlegen') }}</router-link>
              </div>
            </div>

          </div>

        </div>
      </div>
      <img class="w-1/2" :src="getAssetPath('login_illustration.png')"/>
    </div>

  </div>
</template>

<script>
import {numeric, required, sameAs} from 'vuelidate/lib/validators'
import {validationMixin} from 'vuelidate'
import {mapGetters, mapMutations} from "vuex";
import InputCheck from "../components/inputs/InputCheck";

export default {
  name: 'Login',
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  components: {InputCheck},
  mixins: [validationMixin],
  data() {
    return {
      show_two_fa_pnl: false,
      show_login_pnl: true,
      show_two_fa_setup_pnl: false,
      show_force_password_change_pnl: false,
      show_choose_accounts_pnl: false,
      NoAccountExist: true,
      isPilot: true,
      otp: '',
      username: '',
      password: '',
      remember_me: false,
      isUsernameError: false,
      isPasswordError: false,
      passwordError: '',
      usernameError: '',
      account_uid: 0,
      accounts: [],
      datev_auth: false,

      newPassword: '',
      confirmPassword: '',
      isNewPasswordError: false,
      newPasswordError: '',
      isConfirmPasswordError: false,
      confirmPasswordError: '',

      newOtp: '',
      twoFaCodeUrl: '',
      isNewOtpError: false,
      newOtpError: '',

      changePasswordRequired: false,
      setupTwoFaRequired: false,
      datevOauthErrorCode: ''
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    },
    newOtp: {
      required, numeric
    },
    newPassword: {
      required
    },
    confirmPassword: {
      required, sameAsPassword: sameAs('newPassword')
    }
  },
  async created() {
    this.changeWindowSize()
    //await this.$recaptchaLoaded();
    //this.$recaptchaInstance.showBadge();

    // hide during pilot
    const location = window.location + " ";
    if (location.includes("p1l0t=dAt3V")) {
      this.isPilot = false;
    }
  },
  mounted() {
    this.$root.$on('post-login', (e) => {
      if (e.change_password) {
        this.changePasswordRequired = true;
      }
      if (e.two_fa_setup) {
        this.setupTwoFaRequired = true;
      }
      this.postLogin();
    })
    this.$root.$on('two-fa-setup', () => {
      this.togglePanel('setup_two_fa')
    })

    this.datevResponseHandler();
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : 'GrundsteuerDigital - Login'
    },
    ifTC() {
      return process.env.VUE_APP_DEV_VARIANT !== 'business'
    },
    getVersion() {
      let version = process.env.PACKAGE_VERSION;
      if (process.env.PACKAGE_REV != null && process.env.VUE_APP_SHOW_COMMIT_HASH_IN_LOGIN === 'true') {
        version = version + '-' + process.env.PACKAGE_REV.substring(0, 8);
      }
      return version;
    },

  },
  methods: {
    ...mapMutations("user", ["setCurrentUser"]),
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    datevResponseHandler() {
      let url = new URL(window.location.href);
      let code = url.searchParams.get("code");
      if (code !== null && code !== '') {
        this.$api.trigger('datev/oauth', {
          'code': code,
          'account_uid': this.account_uid
        }, true).then((response) => {
          this.datev_auth = true;
          if (response.data.success) {
            if (response.data.accounts) {
              this.accounts = response.data.accounts;
              this.togglePanel('choose_account');
              return true;
            } else if (response.data.logged_in) {
              this.postLogin()
            }

            if (response.data.redirect_url) {
              window.location.href = location.protocol + '//' + location.host + '/#/' + response.data.redirect_url;
            } else {
              window.location.href = location.protocol + '//' + location.host + location.pathname;
            }
          } else {

            if (typeof response.data.error_code !== "undefined") {
              this.datevOauthErrorCode = `${this.$t('general.error-code')}:  ${response.data.error_code}`;
            }

            this.$toast.error(response.data.message);
            window.history.replaceState({}, document.title, "/");
          }
        });
      }
    },
    async startDatevLogin() {
      let data = {
        'process': 'login',
        'oauth_type': 'datev'
      };

      let datevData = await this.$api.getDatevLocalParams(true);
      if (datevData) {
        data = {...data, ...datevData};
      }
      this.$api.trigger('datev/oauth', data, true).then((response) => {
        if (response.data.success) {
          if (typeof response.data.oauth_url !== 'undefined' && response.data.oauth_url !== '') {
            window.location.href = response.data.oauth_url;
          }
        } else {
          this.$toast.error(response.data.message)
        }
      });
    },
    submit() {
      // Validate login form
      this.$v.username.$touch();
      this.$v.password.$touch();
      if (this.$v.username.$invalid || this.$v.password.$invalid) {
        this.isUsernameError = this.$v.username.$error
        this.usernameError = this.$v.username.$error ? this.$t('general.required') : ""//this.$t('login.emailError')
        this.isPasswordError = this.$v.password.$error
        this.passwordError = this.$v.password.$error ? this.$t('login.passwordError') : ""
        return; // if any of the validations fail, we don't move on
      }

      //this.$recaptcha('login').then((recaptcha_token) => {
      this.$api.trigger('login', {
        'username': this.$api.encrypt(this.username),
        'password': this.$api.encrypt(this.password),
        'otp': this.otp,
        'remember_me': this.remember_me,
        'account_uid': this.account_uid,
        //'recaptcha_token': recaptcha_token
      }, true).then((response) => {
        if (response.data.success) {
          if (response.data.accounts) {
            this.accounts = response.data.accounts;
            this.togglePanel('choose_account');
          } else if (response.data.datev_oauth_url) {
            location.href = response.data.datev_oauth_url;
          } else if (response.data.two_factor_auth_required) {
            this.togglePanel('two_fa')
          } else {
            this.setCurrentUser({
              name: response.data.name,
              email: response.data.email,
              company_name: response.data.company_name,
              role: response.data.role,
              nps_filled_at: response.data.nps_filled_at
            });
            if (response.data.force_reset_password) {
              this.changePasswordRequired = true;
            }
            if (response.data.two_fa_required) {
              this.setupTwoFaRequired = true;
            }
            this.postLogin();
          }
        } else {
          this.$api.showToast(response.data.message, 'error')
        }
      })
      //});
    },
    selectRememberMe(checked) {
      this.remember_me = checked;
    },
    postLogin() {
      if (this.changePasswordRequired) {
        this.togglePanel('change_password')
      } else if (this.setupTwoFaRequired) {
        this.togglePanel('setup_two_fa')
      } else {
        localStorage.setItem('tc_logged_in', '1');
        this.authorized();
        //this.$recaptchaInstance.hideBadge();

        this.$api.get_user(true).then(() => {
          if (this.getCurrentUser().theme !== '') {
            this.changeTheme(this.getCurrentUser().theme)
          } else if (this.getCurrentTheme()) {
            location.reload();
          }

          this.redirectAfterLogin();
        });
        if (!this.isWeb) {
          window.ipcRenderer.send('resizeDashboard')
        }
      }
    },
    handleTwoFaCancel() {
      this.logout();
    },
    cancelChooseAccount() {
      this.logout(this.datev_auth);
    },
    chooseAccount(account) {
      if (account.account_uid) {
        this.account_uid = account.account_uid;
        if (account.auth_mode && account.auth_mode === 'datev') {
          this.datevResponseHandler();
        } else {
          this.submit();
        }
      }
    },
    savePassword() {
      this.$v.newPassword.$touch();
      this.$v.confirmPassword.$touch();
      if (this.$v.newPassword.$invalid || this.$v.confirmPassword.$invalid) {
        this.isNewPasswordError = this.$v.newPassword.$error
        this.newPasswordError = this.$v.newPassword.$error ? this.$t('login.passwordError') : ""

        this.isConfirmPasswordError = this.$v.confirmPassword.$error
        this.confirmPasswordError = this.$v.confirmPassword.$error ? this.$t('forgotPassword.password_mismatch') : ""
        return; // if any of the validations fail, we don't move on
      }

      this.$api.trigger('user/update_password', {
        'password': this.$api.encrypt(this.newPassword),
        'confirmPassword': this.$api.encrypt(this.confirmPassword),
        'uid': 'current'
      }, true).then((response) => {
        if (response.data.success) {
          this.changePasswordRequired = false;
          this.postLogin();
        } else {
          // this.showError = true
          // this.errorMessage = response.data.message
          this.$toast.error(response.data.message)
          return false;
        }
      })
    },
    setupTwoFa() {
      this.$v.newOtp.$touch();
      if (this.$v.newOtp.$invalid) {
        this.isNewOtpError = true;
        this.newOtpError = this.$t('settings.user_management.otp_lbl');
        return;
      }

      this.$api.trigger('update_two_fa', {
        otp: this.newOtp
      }, true).then((response) => {
        if (response.data.success) {
          this.setupTwoFaRequired = false;
          this.postLogin();
        } else {
          // this.showError = true
          // this.errorMessage = response.data.message
          this.$toast.error(response.data.message);
        }
      });
    },
    cancelTwoFaSetup() {
      localStorage.removeItem('tc_logged_in');
      this.resetCurrentUser()
      this.togglePanel('login');
    },
    changeWindowSize() {
      // return i18n.locales = "es";
      if (!this.isWeb) {
        window.ipcRenderer.send('resizeLogin')
      }
    },
    togglePanel(pnl) {
      this.show_login_pnl = false;
      this.show_two_fa_pnl = false;
      this.show_force_password_change_pnl = false;
      this.show_two_fa_setup_pnl = false;
      this.show_choose_accounts_pnl = false;

      if (pnl === 'login') {
        this.show_login_pnl = true;
      } else if (pnl === 'two_fa') {
        this.show_two_fa_pnl = true;
      } else if (pnl === 'setup_two_fa') {
        this.twoFaCodeUrl = '';
        this.$api.trigger('get_two_fa_code', {}, true).then((response) => {
          if (response.data.success) {
            this.twoFaCodeUrl = response.data.code_url;
            this.show_two_fa_setup_pnl = true;
          } else {
            // this.showError = true
            // this.errorMessage = response.data.message
            this.$toast.error(response.data.message);
          }
        });
      } else if (pnl === 'choose_account') {
        this.show_choose_accounts_pnl = true;
      } else if (pnl === 'change_password') {
        this.show_force_password_change_pnl = true;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.usernotexit-text a.mb-5.router-link-active {
  color: theme('colors.muted_black');
  margin: 6px 0;
  display: inline-block;
  text-decoration: underline;
  font-size: 15px;
}

.check {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: rgba(123, 124, 124, 1);
  text-align: left;
  line-height: 20px;
}

.label {
  font-family: 'Segoe UI', sans-serif;

  &__link {
    font-size: 15px;
    color: $primary;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    text-align: center;
    line-height: normal;
  }

}

a {
  color: $primary;
}

.enter-login {

  width: 419px;
  height: 32px;
  border: 2px solid #d1d1d1;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: black;
  text-align: left;

  &:focus {
    border: 2px solid green;
  }

}

.otp_pnl {
  min-height: 315px;
}

.forgot-password {
  @include segoe-regular;
  font-size: 15px;
  height: 20px;
  font-family: 'Segoe UI', sans-serif;

  text-align: center;
  line-height: normal;

  a {
    color: theme('colors.muted_black');
  }
}


hr.hr-line {
  margin: 25px 0;
  height: 1px;
  border: 0 !important;
}

.Button_Style_BTN a {
  background: $primary;
  width: 100%;
  max-width: 310px;
  height: 40px;
  border: 0;
  border-radius: 0;
  color: var(--secondary-button-text-color);
  display: block;
  line-height: 40px;
  padding: 0 20px;
  text-decoration: none;
}

.Button_Style_BTN a:hover {
  background: $button-hover-color;
}

.Button_Style_BTN {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

span.close-br {
  width: 100px;
  height: 100px;
  padding: 2px 2px 2px 2px;
  background-color: #e24c4b;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.close-br img {
  max-width: 35px;
  width: 100% !important;
}

.close_button {
  margin-bottom: 25px;
}

.usernotexit-text p:nth-child(2) {
  padding: 10px 0;
}

.user_icon {
  display: inline-flex;

  .close-br {
    background-color: #bdbdbd;
  }
}

.usernotexit-text {
  .Button_Style_BTN a {
    background: rgba(204, 204, 204, 1);
    width: 300px;
    height: 40px;
    font-size: 15px;
    border: 0;
    border-radius: 0;
    color: #333;
    display: block;
    line-height: 40px;
    padding: 0 20px;
    text-decoration: none;
  }

  .account_list {
    margin-top: 5px;

    a {
      color: theme('colors.muted_black');
      margin: 6px 0;
      display: inline-block;
      text-decoration: underline;
      font-size: 15px;
    }
  }
}

.OrdnerText label {
  display: block;
}

.sub {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: left;
  line-height: 20px;
  font-size: 14px;

}

.error {
  height: 35px;
  padding: 2px 2px 2px 2px;
  background-color: #fde7e9;
  box-sizing: border-box;
  margin-top: 15px;

  .error-text {
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 12px;
  }

  .error-img-left {
    width: 16px;
    height: 16px;
  }

  .error-img-right {
    width: 10px;
    height: 10px;
  }

}

@media only screen and (min-width: 500px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 400px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 300px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 200px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 1024px) {
  /* For tablets: */
  .right {
    width: 50%;
    visibility: visible;
  }
  .left {
    width: 50%;

  }
}

.footer {
  width: 678px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  vertical-align: bottom;
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
}

.footer-text {
  font-family: 'Segoe UI', sans-serif;
  color: #AEAEAE;
  text-align: center;
  line-height: normal;
  font-size: 14px;

}

.link {
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  color: $primary;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  text-decoration: underline;
}

.topcorner {
  position: absolute;
  top: 0;
  right: 0;
}

.datev_login {
  width: 136px;
  height: 42px;
  image-rendering: -webkit-optimize-contrast;
}

.link_create_account {
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: $primary;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  text-decoration: underline;
}

.box {
  height: 64px;
  margin-top: 15px;
  text-align: center;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.datev-button {
  width: 224px;
  height: 32px;
  padding: 2px 2px 2px 29px;
  border-radius: 24px;
  background-color: #d0dee3;
  box-sizing: border-box;
  color: #2c3335;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 2px;

  &:hover {
    background-color: $primary;
  }

  img {
    width: 20px;
    height: 19px;

    margin-right: 10px;
  }

  .datev-text {
    cursor: pointer;
    font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #2c3335;
  }
}
</style>
