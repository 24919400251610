import {ApiService} from "./api.service";

export class PowersOfAttorneyService extends ApiService {
  
  listAll() {
    if(this.$store.getters["power_of_attorney/getPowersOfAttorneyList"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["power_of_attorney/getPowersOfAttorneyList"]);
      });
    } else {
      return this.trigger('powerofattorney/list_all', {
        'sortField': 'first_name',
        'sortOrder': 1
      }).then((response) => {
        if (response.data.success && parseInt(response.data.total_records) > 0) {
          this.$store.commit('power_of_attorney/setPowersOfAttorneyList', this.parse_object_data(response.data.records))
          return this.$store.getters["power_of_attorney/getPowersOfAttorneyList"];
        } else {
          return [];
        }
      })
    }
  }
  
  save(data) {
    return this.trigger('powerofattorney/save', data, true).then(response => {
      if (response.data.success) {
        this.$store.commit('power_of_attorney/setPowersOfAttorneyList', [])
        return true;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }

  get(uid) {
    return this.trigger('powerofattorney/get', {
      uid: uid
    }, true).then((response) => {
      if (response.data.success) {
        if (response.data.powerOfAttorneyData.id) {
          response.data.powerOfAttorneyData.id = parseInt(response.data.powerOfAttorneyData.id);
        }
        
        return response.data.powerOfAttorneyData;
      } else {
        this.$toast.error(response.data.message);
        return [];
      }
    })
  }
}
