<template>
<div class="ml-15 mr-15" >
  <label class="mb-10">{{ $t('register.step3.header') }}</label>
  <div class="mt-6 space-y-4" v-if="!$isBusiness">
    <InputSelect :label="$t('register.step3.branches')" :options="getOptionsYesNo()"
                 v-model="step3.branches"
                 :isError="isBranchError"
                 :errorMessage="branchError"
                 @on-item-selected="itemSelected"
                 :placeholder="$t('general.please_select')" :isRequired="true"/>
    <InputSelect :label="$t('register.step3.clients')" :options="clients" v-model="step3.clients"
                 :placeholder="$t('register.step3.clientsPlaceholder')"
                 :showHelpIcon="true" :tooltip="$t('register.step3.tooltip.clients')"/>
    <InputSelect :label="$t('register.step3.employees')" :options="clients" v-model="step3.employees"
                 :placeholder="$t('register.step3.employeePlaceholder')"
                 :showHelpIcon="true" :tooltip="$t('register.step3.tooltip.employees')"/>
    <InputSelect :label="$t('register.step3.tools')" v-model="step3.tools" :options="tools" :multiselect="false"
                 :isError="isToolsError"
                 :errorMessage="toolsError" :placeholder="$t('register.step3.toolsPlaceholder')"
                 :isRequired="!$isBusiness"/>
    <div class="flex mt-12 mb-10 justify-around space-x-4">
      <Button v-show="showPrevButton" class="w-full" :text="$t('register.back')" :secondary="true" @click="goBack"/>
      <div v-show="!showPrevButton" class="w-full invisible"></div>
      <Button class="w-full" :text="$t('register.next')" @click="submitNext"/>
    </div>
  </div>
  <div class="mt-6 space-y-3" v-else>
    <InputSelect :label="$t('register.step3Business.department')" :options="getOptionsYesNo()"
                 v-model="step3.niederlassungens"
                 :isError="isBranchError"
                 :errorMessage="branchError"
                 @on-item-selected="itemSelected"
                 :isRequired="true"/>
    <InputSelect :label="$t('register.step3Business.plots')" :options="plots" v-model="step3.clients"
    />
    <InputSelect :label="$t('register.step3Business.employees')" :options="plots" v-model="step3.employees"
    />
    <div class="flex mt-12 mb-10 justify-around space-x-4">
      <Button v-show="showPrevButton" class="w-full" :text="$t('register.back')" :secondary="true" @click="goBack"/>
      <div v-show="!showPrevButton" class="w-full invisible"></div>
      <Button class="w-full" :text="$t('register.next')" @click="submitNext"/>
    </div>
  </div>

</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {optionsClientsAndEmployees, optionsPlots, tools} from "@/core/constants";
import {required} from "vuelidate/lib/validators";

export default {
  name: "Step3",
  props: {
    stepConfig: {
      type: Object
    }
  },
  data() {
    return {
      clients: optionsClientsAndEmployees,
      plots: optionsPlots,
      tools: tools,
      step3: {
        clients: '',
        employees: '',
        tools: null,
        branches: ''
      },
      isBranchError: false,
      branchError: '',
      isToolsError: false,
      toolsError: ''
    }
  },
  validations: {
    step3: {
      branches: {
        required
      },
      tools: {
        required
      }
    },
  },
  computed: {
    ...mapGetters("register", ["getUserData"]),
    showPrevButton() {
      return !this.stepConfig.first;
    }
  },
  mounted() {
    this.step3.clients = this.getUserData.clients
    this.step3.employees = this.getUserData.employees
    this.step3.tools = this.getUserData.tools
  },
  methods: {
    ...mapMutations("register", ["setThirdStepData"]),
    itemSelected(item) {
      if (item) {
        this.branchError = ''
        this.isBranchError = false
      }
    },
    submitNext() {
      if (this.$isBusiness) {
        if (this.step3.niederlassungens === undefined) {
          this.isBranchError = true
          this.branchError = this.$t('general.required') //this.$t('register.step3.branchErrorBusiness')
        } else {
          this.setThirdStepData(this.step3)
          this.$emit("goToNextStep");
        }
      } else {
        if (this.step3.branches === undefined || this.step3.branches === "") {
          this.isBranchError = true
          this.branchError = this.$t('general.required')//this.$t('register.step3.branchError')
        } else if (this.step3.tools === "") {
          this.isToolsError = true
          this.toolsError = this.$t('general.required')
        } else {
          this.setThirdStepData(this.step3)
          this.$emit("goToNextStep");
        }
      }

    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}
</style>
