<template>
  <div class="ml-15 mr-15">
    <div class="flex justify-around space-x-8 mt-6">
      <InputSelect
          class="w-full"
          :label="$t('settings.powers_of_attorney.take_over_from_branch')"
          :options="branchList"
          v-model="step1.branch"
          :placeholder="$t('general.please_select')"
          @on-item-selected="selectBranch"
      />
      <div class="w-full invisible pr-6"/>
    </div>
    <div class="flex justify-around space-x-8 mt-6">
      <InputSelect
          class="mt-2 w-full"
          v-model="step1.salutation"
          :label="$t('settings.powers_of_attorney.table.salutation')"
          :options="salutation_options"
          :isError="errors.salutation.invalid"
          :errorMessage="errors.salutation.errorMessage"
          :isRequired="true"
          :placeholder="$t('general.please_select')"
      />
      <InputText
          class="mt-2 w-full pr-6"
          v-model="step1.title"
          :label="$t('settings.powers_of_attorney.table.title')"
          placeholder=""
      />
    </div>
    <div class="flex justify-around space-x-8 mt-6">
      <InputText
        class="w-full"
        :label="$t('settings.powers_of_attorney.table.phone')"
        :maxLength="16"
        v-model="step1.phone"
        placeholder=""
      />
      <InputText class="w-full invisible pr-6" label="None"/>
    </div>
    <div class="flex justify-around space-x-8 mt-6">
      <InputText
        class="w-full"
        :label="$t('settings.powers_of_attorney.table.first_name')"
        :isError="errors.firstName.invalid"
        :errorMessage="errors.firstName.errorMessage"
        v-model="step1.firstName"
        :isRequired="true"
        placeholder=""
      />
      <InputText
        class="w-full pr-6"
        :label="$t('settings.powers_of_attorney.table.last_name')"
        v-model="step1.lastName"
        placeholder=""
      />
    </div>
    <div class="flex justify-around space-x-8 mt-4">
      <InputText
        class="w-full"
        :label="$t('settings.powers_of_attorney.table.street')"
        v-model="step1.street"
        placeholder=""
      />
      <InputText
        class="w-full pr-6"
        :label="$t('settings.powers_of_attorney.table.house_number')"
        v-model="step1.houseNumber"
        placeholder=""
      />
    </div>

    <div class="flex justify-around space-x-8 mt-4">
      <InputText
        class="w-full"
        :label="$t('settings.powers_of_attorney.table.zip')"
        :isError="errors.zip.invalid"
        :errorMessage="errors.zip.errorMessage"
        v-model="step1.zip"
        :isRequired="true"
        placeholder=""
        @blur="searchZip"
        :isOnlyNumber="true"
        :maxLength="5"
      />
      <InputText
        class="w-full pr-6"
        :label="$t('settings.powers_of_attorney.table.city')"
        :isError="errors.city.invalid"
        :errorMessage="errors.city.errorMessage"
        v-model="step1.city"
        :isRequired="true"
        placeholder=""
        :loading="zipSearch"
      />
    </div>

    <div class="flex justify-around space-x-8 mt-4">
      <InputSelect
          class="w-full"
          :label="$t('settings.powers_of_attorney.table.country_uid')"
          v-model="step1.countryUid"
          :filterable="true"
          :options="countryList"
          :selected-value="this.defaultCountryId"
          :isError="errors.countryUid.invalid"
          :errorMessage="errors.countryUid.errorMessage"
          :isRequired="true"
          :placeholder="$t('general.please_select')"
      />
      <InputText
        class="w-full pr-6"
        :label="$t('settings.powers_of_attorney.table.postbox')"
        v-model="step1.postbox"
        placeholder=""
      />
    </div>

    <div class="flex justify-around space-x-10 mt-6">
      <div class="flex flex-col w-full mt-2">
        <InputCheck
            :text="$t('settings.powers_of_attorney.preassigned_as_standard')"
            :isChecked="step1.preassignedAsStandard"
            @check="selectPreassignedAsStandard"
        />
      </div>
      <div class="w-full invisible mt-2 pr-6"></div>
    </div>

    <div class="flex justify-around space-x-8 mt-6">
      <div class=" flex flex-col w-full">
        <label class="text mt-2">{{ $t('settings.powers_of_attorney.authorized_recipient') }}<span style="color:red">*</span></label>
        <div class="flex space-x-4 mt-4">
          <div class="flex justify-start items-center">
            <RadioButton type="radio" v-model="step1.authorizedRecipient" name="radio-button" value="1"/>
            <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
          </div>
          <div class="flex justify-start items-center ">
            <RadioButton type="radio" v-model="step1.authorizedRecipient" name="radio-button" value="0"/>
            <span class="ml-3 textradio">{{ $t('general.no') }}</span>
          </div>
        </div>
      </div>
      <div class="w-full invisible pr-6"></div>
    </div>

    <div class="flex justify-end  mt-6 space-x-4 mr-6">
      <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="cancel"/>
      <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {PowersOfAttorneyService} from "../../../services/powersofattorney.service";
import {BranchService} from "@/services/branch.service";
import InputText from "../../inputs/InputText";
import InputSelect from "../../inputs/InputSelect";
import RadioButton from 'primevue/radiobutton';

export default {
  name: "Step1",
  components: {InputSelect, InputText, RadioButton},
  data() {
    return {
      step1: {
        branch: '0',
        salutation: '0',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        countryUid: '0',
        postbox: '',
        authorizedRecipient: '1',
        preassignedAsStandard: false
      },
      zipSearch: false,

      errors: {
        salutation: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_salutation')
        },
        firstName: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_first_name')
        },
        zip: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_zip')
        },
        city: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_city')
        },
        countryUid: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_country_uid')
        },
      },

      salutation_options: this.$t('settings.powers_of_attorney.salutation_options'),
      countryList: [],
      branchList: [],
    }
  },
  beforeMount() {
    this.$api.get_country_list().then((list) => {
      this.countryList = list;
    });
  },
  computed: {
    ...mapGetters("power_of_attorney", ["getPowerOfAttorneyData"]),
  },
  mounted() {
    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      if (list.length > 0) {
        this.step1.preassignedAsStandard = false;
      } else {
        this.step1.preassignedAsStandard = true;
      }
    });

    let branchService = new BranchService();
    branchService.listAll().then((list) => {
      this.branchList = list.map((o) => {
        return {
          "name": o.name.trim(),
          "code": o.uid,
          "phone": o.phone,
          "street": o.street,
          "zip": o.zip,
          "city": o.city
        }
      });
    });
  },
  methods: {
    ...mapMutations("power_of_attorney", ["setPowerOfAttorneyData"]),
    async submitNext() {
      this.errors.salutation.invalid = false;
      this.errors.firstName.invalid = false;
      this.errors.zip.invalid = false;
      this.errors.city.invalid = false;
      this.errors.countryUid.invalid = false;

      if (this.step1.salutation === '' || this.step1.salutation === '0') {
        this.errors.salutation.invalid = true;
        return false;
      }
      if (this.step1.firstName.trim() === '') {
        this.errors.firstName.invalid = true;
        return false;
      }
      if ((this.step1.street.trim() === '' || this.step1.houseNumber.trim() === '') && this.step1.postbox.trim() === '') {
        this.$api.showToast(this.$t('settings.powers_of_attorney.street_pobox_error'), "error")
        return false;
      }
      if (this.step1.zip.trim() === '') {
        this.errors.zip.invalid = true;
        return false;
      }
      if (this.step1.city.trim() === '') {
        this.errors.city.invalid = true;
        return false;
      }
      if (this.step1.countryUid === '' || this.step1.countryUid === '0') {
        this.errors.countryUid.invalid = true;
        return false;
      }

      this.step1.preassignedAsStandard = this.step1.preassignedAsStandard ? 1 : 0;
      this.setPowerOfAttorneyData(this.step1);

      const powersOfAttorneyService = new PowersOfAttorneyService();
      const postData = this.getPowerOfAttorneyData;

      if(await powersOfAttorneyService.save(postData)) {
        this.$emit("goToNextStep");
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    searchZip() {
      this.zipSearch = true;
      if (this.step1.city === '' && this.step1.zip !== '') {
        this.cityLookup(this.step1.zip).then((cities) => {
          if (cities.length > 0) {
            this.step1.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    selectPreassignedAsStandard(checked) {
      this.step1.preassignedAsStandard = checked;
    },
    selectBranch(item) {
      this.step1.city = item.city;
      this.step1.phone = item.phone;
      this.step1.street = item.street;
      this.step1.zip = item.zip;
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

</style>
