<template>
  <div>
    <div class="step_box mr-4 ml-3">
      <div class=" flex justify-start items-center mb-6 w-full">
        <label class="text ml-2">
          {{ $t('settings.branches.datev_import.steps.step3.info') }}
        </label>
      </div>
      <div class="table-container">
        <DataTable :value="dataEnvironments" :selection.sync="selectedEnvironments" responsiveLayout="scroll" dataKey="id" class="row" :scrollable="true" scrollHeight="flex">
          <Column selectionMode="multiple" :headerStyle="{'width': '3em','background-color':'#f2f2f2'}"></Column>
          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                  :headerStyle="{'background-color':'#f2f2f2'}"></Column>
        </DataTable>
      </div>
      <div class="flex justify-end space-x-4 mt-6 mr-6 mt-10 mb-10">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  name: "Step3",
  components: {DataTable, Column},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(nv) {
      if (nv) {
        this.dataEnvironments = this.getDataEnvironments().map((o) => {
          return {
            'id': o.id,
            'label': o.name,
            'brn': o.consultant_number
          }
        });
      }
    }
  },
  data() {
    return {
      selectedEnvironments: [],
      dataEnvironments: [],
      fetchingClients: false,
      columns: [
        {field: 'label', header: this.$t('dms.steps.step3.table_header')},
        {field: 'brn', header: this.$t('dms.steps.step3.brn')},
      ]
    }
  },
  methods: {
    ...mapGetters("datev_import", ["getDataEnvironments"]),
    ...mapMutations("datev_import", ["setSelectedEnvironments"]),
    async submitNext() {
      if(this.selectedEnvironments.length > 0) {
        this.setSelectedEnvironments(this.selectedEnvironments);
        this.$emit("goToNextStep");
      }
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">
.flex.radio_step > label {
  margin-right: 10px;
}

.step_box {
  text-align: left;
  font-size: 15px;
}

p.para_text {
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;

}


.chk {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  align-self: start;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

a {
  text-decoration: underline;
  color: theme('colors.muted_black');
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.row {
  border: 1px solid #dfdfdf;

  ::v-deep {
    .p-datatable-wrapper td:not(:first-child) {
      text-align: left !important;
    }
  }

}
.table-container {
  height: calc(100vh - 370px);
}

</style>
