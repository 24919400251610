<template>
  <div class="h-full bg-white w-full" :class="{ 'pl-7': showPageHeader }">
    <PageHeader v-if="showPageHeader" :title="title"/>
    <ModalDialog v-show="isModalVisible" modalSize="large"
                 :headerText="$t('components.properties_menu.data_acquisition')"
                 @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start overflow-y-scroll">

          <div class="  mt-2  ">
            <div class="box flex flex-col">
              <div class="flex justify-start items-center">
                <img class="image ml-2 mr-4" src="@/assets/info_gray.svg"/>
                <div class="flex flex-col">
                  <label class="text text-bold ml-2">{{
                      $t('properties.data_acquisition_text_1')
                    }}</label>
                  <label class="text ml-2 mt-2">{{ $t('properties.data_acquisition_text_2') }}</label>
                  <div class="text ml-2 mt-2"
                       v-html="$t('properties.data_acquisition_text_ul')"></div>
                  <label class="text text-bold ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_3')
                    }}</label>
                  <label class="text  ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_4')
                    }}</label>
                  <label class="text  ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_5')
                    }}</label>
                </div>

              </div>
              <div class="flex ml-4">
                <a class="ml-8 mt-2 text a-link mb-2"
                   href="https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/"
                   target="_blank">https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/</a>
              </div>

            </div>
            <div class="box flex flex-col " style="background-color:  #f2f2f2">
              <div class="flex justify-start items-center ">
                <img class="image ml-2 mr-4" src="@/assets/info_gray.svg"/>
                <div class="flex flex-col">
                  <label class="text ml-2 text-bold">{{
                      $t('properties.data_acquisition_text_6')
                    }}</label>
                  <label class="text ml-2 mt-2">{{ $t('properties.data_acquisition_text_7') }}</label>
                  <label class="text ml-2 mt-4">{{ $t('properties.data_acquisition_text_8') }}</label>
                  <label class="text text-bold ml-2 mt-4">{{
                      $t('properties.data_acquisition_text_9')
                    }}</label>
                  <label class="text ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_10')
                    }}</label>
                  <label class="text ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_11')
                    }}</label>
                </div>


              </div>
              <div class="flex flex-col ml-8 mb-2 ">
                <label class="text  mt-4 ml-4">{{ $t('properties.inventory_info_text3') }}</label>
                <a class="text a-link ml-4"
                   href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/"
                   target="_blank">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                <Button class="mt-5 ml-4" :text="$t('properties.data_acquisition_text_button_1')"
                        @click="acquistionButton1Click" :secondary="true"/>
              </div>
            </div>
            <div class="box flex flex-col " style="background-color:  #f2f2f2">
              <div class="flex justify-start items-center ">
                <img class="image ml-2 mr-4" src="@/assets/info_gray.svg"/>
                <div class="flex flex-col">
                  <label class="text text-bold ml-2">{{
                      $t('properties.data_acquisition_text_12')
                    }}</label>
                  <label class="text ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_13')
                    }}</label>
                  <div class="text ml-2 mt-2"
                       v-html="$t('properties.data_acquisition_text_ul_2')"></div>
                  <label class="text ml-2 mt-2">{{
                      $t('properties.data_acquisition_text_15')
                    }}</label>

                </div>


              </div>
              <div class="flex flex-col ml-8 mb-2 ">
                <Button class="mt-5 ml-4" :text="$t('properties.data_acquisition_text_button_2')"
                        @click="acquistionButton2Click" :secondary="true"/>
              </div>
            </div>
          </div>


          <!--          <div class="flex justify-end space-x-10 mt-2">-->
          <!--            <div class="flex mt-2 mb-6 ">-->


          <!--              <Button class=" ml-6 mr-3 " :text="$t('buttons.close')" :secondary="true"-->
          <!--                      @click="handleModalClose"></Button>-->
          <!--            </div>-->

          <!--          </div>-->
        </div>
        <div v-show="dStepAddress">
          <div class="flex justify-center items-center m-20">
            <div class=" flex flex-col justify-center items-center ml-15 mr-15">
              <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex    m-2">
                <img src="@/assets/check_done.svg"/>
              </div>
              <label class="label__text ">Für den Mandanten Timo Tauscher wurden Bestandsnachweise
                angefordert in Hamburg und Berlin.</label>
              <Button class="mt-10 w-2/4 "
                      text="OK"></Button>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end space-x-10 mt-2">
          <div class="flex mt-2 mb-6 ">


            <Button class=" ml-6 mr-3 " :text="$t('buttons.close')" :secondary="true"
                    @click="handleModalClose"></Button>
          </div>

        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition modal -->
    <ModalDialog
        v-show="toggleDataAcquisitionModal"
        :headerText="$t('properties.modal_data_acquisitions_overview.modal_title')"
        modalSize="large"
        @close="toggleDataAcquisitionModal=false"
        :showClose="true">
      <template v-slot:body>
        <div class="flex flex-col w-full mt-2 mb-4 h-96"
             style="overflow-y: auto; height: 431px; max-width: calc(99% - (10px)); padding: 2px 2px 2px 2px;
             box-sizing: border-box;">
          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <div class="text ml-4">
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.modal_data_acquisitions_overview.block_1.title_1').toString() }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_1.text_1').toString() }}
                </div>
                <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_1').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_2').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_3').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_4').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_5').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_1.list.label_6').toString()
                    }}
                  </li>
                </ul>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.modal_data_acquisitions_overview.block_1.title_2').toString() }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_1.text_2').toString() }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{
                    $t('properties.modal_data_acquisitions_overview.block_1.text_3').toString()
                  }}<br/>
                  <a target="_blank" class="text-base label-chk text-sm text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/">
                    https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <div class="text ml-4">
                <p class="text-left mb-4 text_bold text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_2.title_1').toString() }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_2.text_1_1').toString() }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_2.text_1_2').toString() }}
                </p>
                <p class="text-left mb-4 text-sm">
                  <strong>{{
                      $t('properties.modal_data_acquisitions_overview.block_2.title_2').toString()
                    }}</strong>
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_2.text_2_1').toString() }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_2.text_2_2').toString() }}
                  <br/>
                  <a target="_blank" class="text-base label-chk text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://taxtech-shop.de/" target="_blank"
                     class="text-base p-2"
                     style="background-color: rgba(204, 204, 204, 1);">
                    {{
                      $t('properties.modal_data_acquisitions_overview.block_2.button').toString()
                    }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <div class="text ml-4">
                <p class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.modal_data_acquisitions_overview.block_3.title_1').toString() }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_3.text_1').toString() }}
                </p>
                <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_3.list.state1').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_3.list.state2').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_3.list.state3').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_3.list.state4').toString()
                    }}
                  </li>
                  <li>{{
                      $t('properties.modal_data_acquisitions_overview.block_3.list.state5').toString()
                    }}
                  </li>
                </ul>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.modal_data_acquisitions_overview.block_3.text_2').toString() }}
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/"
                     target="_blank"
                     class="text-base p-2"
                     style="background-color: rgba(204, 204, 204, 1);">
                    {{
                      $t('properties.modal_data_acquisitions_overview.block_3.button').toString()
                    }}
                  </a>
                </p>
              </div>
            </div>
          </div>

        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="elsterValidationResultDialog" :headerText="$t('declarations.detail.elster_validation.title') + ' - ' + elsterValidationPropertyUid"
                 @close="handleElsterValidationResultDialogClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start elster-dlg">
          <div class="flex justify-around mt-2 space-x-10">
            <div class="w-full mt-2 elster-result-header">
              <div class="flex flex-col justify-center items-center">
                <img src="@/assets/warning.svg" width="15%">
              </div>
              <p class="textbold mt-4">{{ $t('declarations.detail.elster_validation.failure_message') }}</p>
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10">
            <div class="w-full flex flex-col justify-start mt-2 mb-4">
              {{ $t('declarations.detail.elster_validation.error_info') }}
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationErrors.length > 0">
            <div class="w-full flex flex-col justify-start mt-2">
              <label class="label text-left">{{ $t('declarations.detail.elster_validation.validation_error') }}:</label>
              <ul class="ul-list">
                <li v-for="error in elsterValidationErrors" :key="error.field" v-html="renderUrlsAsLinks(error.readable)"></li>
              </ul>
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationHints.length > 0">
            <div class="w-full flex flex-col justify-start mt-2">
              <label class="label text-left">{{ $t('declarations.detail.elster_validation.notes') }}:</label>
              <ul class="ul-list">
                <li v-for="hint in elsterValidationHints" :key="hint.field" v-html="renderUrlsAsLinks(hint.readable)"></li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 justify-end space-x-4 mr-6">
          <Button :text="$t('buttons.ok')" @click="handleElsterValidationResultDialogClose"/>
        </div>
      </template>
    </ModalDialog>

    <div class="mr-7 property-grid">
      <AppGrid :grid-id="gridId"
               data-endpoint="property/list"
               count-endpoint="property/list"
               :columns="getColumns"
               default-sort-attr="created_at"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="prim_uid"
               navigation-data-key="prim_uid"
               :standard-filters="standardFilters"
               :server-params="serverParams"
               :menus="menus"
               :auto-load="autoLoad"
               :state-ful-filter="true"
               :show-create-record="!archived"
               @menu-clicked="handleMenuClick"
               @col-click="handleColClick"
               @prepend-icon-click="handlePrependIconClick"
               @create-new="createNew"
               @on-data-loaded="onDataLoaded"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import AppGrid from "@/components/AppGrid"
import {mapGetters, mapMutations} from "vuex";
import {PropertyService} from "@/services/property.service";
import {UserService} from "@/services/user.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: "list",
  directives: {'tooltip': Tooltip},
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('components.properties')
      }
    },
    gridId: {
      type: String,
      default: "properties"
    },
    parentType: {
      type: String,
      default: ""
    },
    parentId: {
      type: Number,
      default: 0
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    archived: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  components: {
    AppGrid
  },
  data() {
    return {
      isModalVisible: false,
      isModalVisibleDataAcquisitions: false,
      toggleDataAcquisitionModal: false,
      userService: new UserService(),
      chips: [
        {
          name: "Baden-Württemberg",
          link: "https://www.service-bw.de/leistung/-/sbw/Liegenschaftskataster++Auszug+beantragen-359-leistung-0"
        },
        {name: "Bayern", link: "https://www.ldbv.bayern.de/produkte/kataster/katasterauszug.html"},
        {name: "Berlin", link: "https://service.berlin.de/dienstleistung/324341/"},
        {
          name: "Brandenburg",
          link: "https://geobasis-bb.de/lgb/de/geodaten/liegenschaftskataster/liegenschaftskataster-auszug/"
        },
        {name: "Bremen", link: "https://www.service.bremen.de/sixcms/detail.php?gsid=bremen128.c.8759.de"},
        {
          name: "Mecklenburg-Vorpommern",
          link: "https://www.geoportal-mv.de/portal/Geodatenshop/Liegenschaftskataster"
        },
        {
          name: "Hessen",
          link: "https://hvbg.hessen.de/geoinformation/liegenschaftskataster/ausgaben-aus-dem-liegenschaftskataster"
        },
        {name: "Niedersachsen", link: "https://www.geobasisdaten.niedersachsen.de/mapservice/webformulare/"},
        {name: "Nordrhein-Westfalen", link: ""},
        {
          name: "Rheinland-Pfalz",
          link: "https://lvermgeo.rlp.de/de/geodaten-geoshop/shops/bestellung-von-auszuegen-aus-dem-liegenschaftskataster/"
        },
        {name: "Saarland", link: "https://www.shop.lvgl.saarland.de/"},
        {name: "Sachsen", link: "https://www.landesvermessung.sachsen.de/bestandsnachweis-5716.html"},
        {
          name: "Sachsen-Anhalt",
          link: "https://www.lvermgeo.sachsen-anhalt.de/de/liegenschaftsbuch/liegenschaftsbuch.html"
        },
        {name: "Schleswig-Holstein", link: "https://geoserver.gdi-sh.de/formular/"},
        {
          name: "Thüringen",
          link: "https://tlbg-onlineshop.thueringen.de/onlineshop/flurstuecke-und-gebaeude/auszuege-liegenschaftskataster?tx_tlbgonlineshop_userdefinedproduct%5Baction%5D=show&tx_tlbgonlineshop_userdefinedproduct%5Bcontroller%5D=UserdefinedProduct&cHash=d379f38cf31261f96fb61a0650908a45"
        },
      ],
      service: new PropertyService(),
      serverParams: {
        "parentType": this.parentType,
        "parentId": 0,
        "archived": this.archived
      },
      standardFilters: [],
      menus: [
        {
          id: "pm.add",
          "icon": this.getAssetPath('add_property.svg'),
          "title": this.$t('components.properties_menu.add_property'),
          canShow: !this.archived,
        }, {
          id: "pm.edit",
          "icon": this.getAssetPath('edit_property.svg'),
          "icon_disabled": this.getAssetPath('edit-property-gray.svg'),
          "title": this.$t('components.properties_menu.edit_property'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "pm.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('components.properties_menu.delete_property'),
          "action_endpoint": 'property/delete',
          "action_confirmation": {
            "show": true
          },
          "callback": (response) => {
            this.setCurrentUserStatsDataChanged(true);
            this.deleteCallback(response);
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        },
        {
          id: "pm.inventory",
          "icon": this.getAssetPath('data_acquisition.svg'),
          "title": this.$t('components.properties_menu.data_acquisition'),
          canShow: !this.$isBusiness && !this.archived
        },
        {
          id: "pm.bulk",
          "icon": this.getAssetPath('doc_cog.svg'),
          "title": this.$t('components.properties_menu.bulk'),
          canShow: !this.archived,
          sub_menu: {
            items: [
              {
                id: "pm.elster_validate",
                icon: 'elster_validate_icon',
                label: this.$t('components.properties_menu.elster_validate')
              }, {
                id: "pm.new_declaration",
                icon: 'internal_approval_icon',
                label: this.$t('components.properties_menu.new_declaration')
              }
            ]
          }
        },
        {
          id: "pm.export",
          "icon": this.getAssetPath('export.svg'),
          "icon_disabled": this.getAssetPath('export_gray.svg'),
          "title": this.$t('components.properties_menu.export_property'),
          "disabled": true,
          canShow: !this.archived,
        },
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      columns: [
        {
          field: 'property_id',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          width: 180,
          elType: 'text',
          clickable: true,
          prependIcon: true,
          prependIconClickable: (row) => {
            return typeof this.elsterValidationResults[row.prim_uid] !== 'undefined' && !this.elsterValidationResults[row.prim_uid].success;
          },
          getIcon: (row) => {
            if(typeof this.elsterValidationResults[row.prim_uid] !== 'undefined') {
              return this.elsterValidationResults[row.prim_uid].success ? require('@/assets/elster_validate.svg') : require('@/assets/elster_validate_error.svg')
            } else {
              return require('@/assets/blank.png')
            }
          },
          tooltip: (row) => {
            if(typeof this.elsterValidationResults[row.prim_uid] !== 'undefined') {
              return this.elsterValidationResults[row.prim_uid].success ? this.$t('properties.tooltips.elster_success') : this.$t('properties.tooltips.elster_failed')
            } else {
              return false;
            }
          }
        }, {
          field: 'name',
          header: this.$t('properties.step2.name'),
          isChecked: true,
          elType: 'text',
          width: 500,
          clickable: true
        }, {
          field: 'client_name',
          header: this.$t('properties.client_name'),
          isChecked: true,
          elType: 'text',
          width: 200,
          clickable: true
        }, {
          field: 'client_id',
          header: this.$t('properties.client_number'),
          isChecked: true,
          elType: 'text',
          width: 180,
          clickable: true
        }, {
          field: 'reference',
          header: this.$t('components.properties_table_col.col8'),
          isChecked: true,
          elType: 'text',
          width: 200,
          clickable: true
        }, {
          field: 'elster_status',
          header: this.$t('components.properties_table_col.col25'),
          isChecked: true,
          elType: 'select',
          width: 180,
          filterOptions: this.$t('properties.elster_status_options'),
          clickable: true
        }, {
          field: 'declaration_status',
          header: this.$t('components.properties_table_col.col26'),
          isChecked: false,
          width: 280,
          elType: 'select',
          filterOptions: this.$t('properties.property_declaration_status_options'),
          clickable: true
        }, {
          field: 'last_declaration_status',
          header: this.$t('components.properties_table_col.col48'),
          isChecked: false,
          width: 280,
          elType: 'select',
          filterOptions: this.$t('properties.property_last_declaration_status'),
          clickable: true
        }, {
          field: 'declaration_number_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col33'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'declaration_type_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col32'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.types_old'),
          clickable: true
        }, {
          field: 'branch_name',
          header: this.$t('components.properties_table_col.col35'),
          isChecked: false,
          elType: 'select',
          sortable: false,
          filterOptions: '',
          clickable: true
        }, {
          field: 'street',
          header: this.$t('components.properties_table_col.col3'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'house_number',
          header: this.$t('components.properties_table_col.col22'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'zip',
          header: this.$t('components.properties_table_col.col6'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'city',
          header: this.$t('components.properties_table_col.col4'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'federal_state_name',
          header: this.$t('components.properties_table_col.col5'),
          isChecked: false,
          elType: 'state_select',
          filterableSelect: true,
          clickable: true
        }, {
          field: 'community',
          header: this.$t('components.properties_table_col.col7'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'property_type',
          header: this.$t('components.properties_table_col.col14'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.property_type_filter'),
          clickable: true
        }, {
          field: 'economic_entity_type',
          header: this.$t('components.properties_table_col.col23'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.step2a.economic_entity_types.default'),
          clickable: true
        }, {
          field: 'complexity',
          header: this.$t('properties.step2a.complexity'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options'),
          clickable: true
        }, {
          field: 'ownership_structure',
          header: this.$t('components.properties_table_col.col13'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.ownership_structure_filter'),
          clickable: true
        }, {
          field: 'area_of_the_land_total',
          header: this.$t('components.properties_table_col.col19'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'exemption',
          header: this.$t('components.properties_table_col.col24'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        }, {
          field: 'multi_communities',
          header: this.$t('components.properties_table_col.col17'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        }, {
          field: 'development_state',
          header: this.$t('components.properties_table_col.col16'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        }, {
          field: 'area_of_the_land_value_1',
          header: this.$t('components.properties_table_col.col20'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'amount_of_parcels',
          header: this.$t('components.properties_table_col.col31'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'internal_note',
          header: this.$t('properties.step2.internalNote'),
          filterLbl: this.$t('properties.step2.internalNote') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'internal_note_client',
          header: this.$t('clients.attrs.internal_note'),
          filterLbl: this.$t('clients.attrs.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'internal_note_declaration',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'extra_details',
          header: this.$t('properties.step2.extraDetails'),
          filterLbl: this.$t('properties.step2.extraDetails') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'declaration_relevant_marked_by_user',
          header: this.$t('components.properties_table_col.col28'),
          filterLbl: this.$t('components.properties_table_col.col28'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        }, {
          field: 'next_declaration_type',
          header: this.$t('components.properties_table_col.col29'),
          filterLbl: this.$t('components.properties_table_col.col29'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.next_declaration_type_options'),
          clickable: true
        }, {
          field: 'last_checked_for_changes',
          header: this.$t('components.properties_table_col.col39'),
          filterLbl: this.$t('components.properties_table_col.col39'),
          isChecked: false
        }, {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false
        }, {
          field: 'modified_by_name',
          header: this.$t('components.properties_table_col.col34'),
          filterLbl: this.$t('components.properties_table_col.col34'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true
        }, {
          field: 'updated_after_submission',
          header: this.$t('components.properties_table_col.col36'),
          filterLbl: this.$t('components.properties_table_col.col36'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        }, {
          field: 'assigned_user',
          header: this.$t('components.properties_table_col.col38'),
          filterLbl: this.$t('components.properties_table_col.col38'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true
        }
      ],
      stateList: [],
      elsterValidationResults: {},
      elsterValidationResultDialog: false,
      elsterValidationErrors: [],
      elsterValidationHints: [],
      elsterValidationPropertyUid: '',
      propertiesList: [],

      dStepAddress: false
    }
  },
  beforeMount() {
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });

    this.userService.getCurrentUserStats().then((stats) => {
      this.menus = this.menus.map((o) => {
        if (o.id === 'pm.export') {
          o.disabled = !(stats.propertyCount > 0);
        }

        if (this.isReadOnly) {
          if (o.id === "pm.add" || o.id === "pm.delete") {
            o.canShow = false;
          }
        }

        if (this.isReadOnly || this.archived) {
          if (o.id === "pm.edit") {
            o.title = this.$t('components.properties_menu.view_property');
            o.icon_disabled = this.getAssetPath('eye_gray.svg');
            o.icon = this.getAssetPath('eye_green.svg');
          }
        }

        return o;
      })
    });

    (new BranchService()).listMyBranches().then((list) => {
      let branchList = list.map((o) => {
        return {
          'name': o.name,
          'code': o.uid
        }
      });

      if (branchList.length > 0) {
        this.columns = this.columns.map((o) => {
          if (o.field === 'branch_name') {
            o.filterOptions = branchList;
          }

          return o;
        });
      }
    });

    this.userService.listAll().then(async (list) => {
      let userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active') {
          filtered.push({
            'name': item.name,
            'code': item.id
          });
        }

        return filtered;
      }, []);

      userList.push({
        'name': 'API',
        'code': '0'
      });

      if (userList.length > 0) {
        this.columns = this.columns.map((o) => {
          if (o.field === 'modified_by_name' || o.field === 'assigned_user') {
            o.filterOptions = userList;
          }

          return o;
        });
      }
    });
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('components.properties')}`
    },
    showPageHeader() {
      return typeof (this.parentType) !== 'undefined' && this.parentType === '';
    },
    isReadOnly() {
      return !(
        typeof this.getCurrentUser().permissions !== 'undefined'
        && typeof this.getCurrentUser().permissions.manage_property !== 'undefined'
        && parseInt(this.getCurrentUser().permissions.manage_property) === 1
      );
    },
    hasMassUpdatePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.allow_mass_update) === 1;
    },
    getColumns() {
      if (this.archived === false) {
        let archivedFieldExist = false;
        this.columns.map((o) => {
          if (o.field === 'archived') {
            archivedFieldExist = true;
          }
        });

        if (!archivedFieldExist) {
          this.columns.push({
            field: 'archived',
            header: this.$t('components.properties_table_col.col37'),
            isChecked: false,
            width: 280,
            elType: 'select',
            filterOptions: this.$t('properties.property_archived_status_options'),
            clickable: true
          });
        }
      }

      return this.columns;
    },
  },
  watch: {
    parentId() {
      if (this.parentId) {
        this.serverParams.parentId = this.parentId;
      }
    }
  },
  mounted() {
    const localStorageKeys = ["previousPage", "clientId", "AddedProperty", "assignedUser", "flag"];
    localStorageKeys.forEach(function (item) {
      localStorage.removeItem(item);
    });
    if (this.parentId) {
      this.serverParams.parentId = this.parentId;
    }
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
    ...mapGetters("user", ["getCurrentTheme", "getCurrentUser"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    createNew() {
      if(this.isReadOnly) {
       this.$api.showToast(this.$t('general.errors.noAddPropertyPermission'), "error")
      } else {
        this.$router.push({
          name: "Properties",
          params: {
            'comp': 'add',
            'parentType': this.parentType,
            'parentId': this.parentId
          }
        });
      }
    },
    onDataLoaded(gridData) {
      this.propertiesList = [];
      if(gridData.length > 0) {
        for(let k=0; k<gridData.length; k++) {
          this.propertiesList[k] = gridData[k].prim_uid;
        }
      }
    },
    acquistionButton1Click() {
      window.open('https://taxtech-shop.de/', '_blank')
    },
    acquistionButton2Click() {
      window.open('https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/', '_blank')
    },
    handleModalClose() {
      this.isModalVisible = false
    },
    handleStateClick(link) {
      window.open(link, '_blank')
    },
    handleMenuClick(args) {
      let params = {};
      let selectedProperties = [];
      let withoutDeclarationStatusOption = undefined;

      switch (args.menuId) {
        case "pm.add":
          this.$router.push({
            name: "Properties",
            params: {
              'comp': 'add',
              'parentType': this.parentType,
              'parentId': this.parentId
            }
          });
          break;
        case 'pm.edit':
          if (args.data[0].prim_uid && args.data[0].client_uid) {
            this.triggerEdit(args.data[0].prim_uid, args.data[0].client_uid);
          }
          break;
        case 'pm.inventory':
          this.isModalVisible = true
          break;
        case 'pm.data_acquisitions':
          this.toggleDataAcquisitionModal = true
          break;
        case 'pm.elster_validate':
          params = {
            headerText: this.$t('components.properties_menu.elster_validate'),
            textHtml: this.$t('properties.elster_validating'),
            showClose: true,
            canEscape: true,
            buttons: {
              "ok": this.$t('buttons.ok'),
              "cancel": this.$t('buttons.cancel'),
              "delete": false
            },
            onConfirm: () => {
              let uids = [];
              if(args.data.length > 0) {
                for(let k=0; k<args.data.length; k++) {
                  uids[k] = args.data[k].prim_uid;
                }
                this.triggerBulkElsterValidation(uids, true);
              } else if(this.propertiesList.length > 0) {
                this.triggerBulkElsterValidation(this.propertiesList, true);
              }
            }
          };
          this.$modal.show(params);
          break;
        case 'pm.new_declaration':
          if (this.hasMassUpdatePermission) {
            if (args.data.length > 0) {
              withoutDeclarationStatusOption = this.$t('properties.property_declaration_status_options').find( o => o.code === 'without_declaration');
              let propertiesWithExistingDeclarationsSelected = false;
              args.data.forEach((property) => {
                if (property.declaration_status !== withoutDeclarationStatusOption.name) {
                  propertiesWithExistingDeclarationsSelected = true;
                }

                selectedProperties.push({
                  "property_id": property.property_id,
                  "name": property.name,
                  "declaration_status": property.declaration_status,
                  "reference": property.reference,
                  "prim_uid": property.prim_uid,
                  "federalStateUid": parseInt(property.federal_state_uid),
                  "client_uid": property.client_uid,
                  "client_name": property.client_id + ' - ' + property.client_name,
                  "declarationRelevantMarkedByUser": property.declarationRelevantMarkedByUser,
                  "updatedAfterSubmission": property.updatedAfterSubmission,
                  "nextDeclarationType": property.nextDeclarationType
                });
              }, this);

              if (propertiesWithExistingDeclarationsSelected) {
                this.$modal.show({
                  headerText: this.$t('properties.new_declaration_confirm.title'),
                  text: this.$t('properties.new_declaration_confirm.message'),
                  showClose: true,
                  canEscape: true,
                  buttons: {
                    ok: this.$t('properties.new_declaration_confirm.button_1'),
                    cancel: this.$t('properties.new_declaration_confirm.button_2'),
                    delete: false
                  },
                  onConfirm: () => {
                    this.goToNewDeclaration(selectedProperties, args.data);
                    this.$modal.visible = false;
                  }
                });
              } else {
                this.goToNewDeclaration(selectedProperties, args.data);
              }
            } else {
              this.$api.showToast(this.$t('properties.mass_update.empty_selection'), "error");
            }
          } else {
            this.$api.showToast(this.$t('properties.mass_update.no_permission'), "error");
          }
          break;
        case 'pm.export':
          if (this.parentType === 'CLIENT' && this.parentId > 0) {
            args.filter.parent_type = 'client';
            args.filter.parent_uid = this.parentId;
          }

          let selectedRecordsStr = '';
          if (args.data.length > 0) {
            let selectedRecords = [];
            for (let item of args.data) {
              selectedRecords.push(item.prim_uid);
            }
            selectedRecordsStr = selectedRecords.join(",");
          }

          this.service.export(args.selectedColumns, args.filter, selectedRecordsStr);

          break;
        default:
          this.$emit('click', args.menuId)
      }
    },

    goToNewDeclaration(selectedProperties, argsData) {
      if (selectedProperties.length === 0) {
        this.$api.showToast(this.$t('properties.mass_update.zero_without_declaration'), 'error');
      } else {
        if (selectedProperties.length < argsData.length) {
          this.$api.showToast(this.$t('properties.mass_update.contains_without_declaration').replace('%count1%', argsData.length - selectedProperties.length), 'warning');
        }
        this.$router.push({
          name: 'Properties',
          params: {
            'comp': 'BulkAdd',
            'processType': 'new_declaration',
            'selectedDeclarations': [],
            'selectedProperties': selectedProperties,
            'breadcrumbs': [{
              title: this.$t('sidebar.properties'),
              link: '/property/list'
            }, {
              title: this.$t('components.properties_menu.new_declaration'),
              link: ''
            }],
          }
        });
      }
    },

    triggerBulkElsterValidation(uids, show_loader) {
      if(uids.length > 0) {
        if(uids.length > 50) {
          this.$api.showToast(this.$t('properties.elster_validation_limit'), "error")
        } else {
          this.elsterValidationResults = {};
          this.service.bulkElsterValidate(uids, show_loader).then((res) => {
            this.$loading.hide();
            if (res.validatorResult) {
              this.elsterValidationResults = res.validatorResult;
            }
          });
        }
      }
    },
    handleColClick(e) {
      if ((e.field === 'client_name' || e.field === 'client_id') && parseInt(e.row.client_uid) > 0) {
        this.$router.push("/client/edit?uid=" + parseInt(e.row.client_uid));
      } else {
        this.triggerEdit(e.row.prim_uid, e.row.client_uid);
      }
    },
    handlePrependIconClick(e) {
      this.elsterValidationPropertyUid = '';
      this.elsterValidationErrors = [];
      this.elsterValidationHints = [];

      if(e.row && e.row.prim_uid) {
        if(this.elsterValidationResults[e.row.prim_uid]) {
          this.elsterValidationPropertyUid = e.row.property_id;
          this.showElsterError(e.row.prim_uid)
        } else {
          this.service.elsterValidate(e.row.prim_uid, true).then((validationResponse) => {
            if(validationResponse) {
              this.elsterValidationPropertyUid = e.row.property_id;
              this.elsterValidationResults[e.row.prim_uid] = validationResponse;
              this.showElsterError(e.row.prim_uid);
            }
          });
        }
      }
    },
    showElsterError(propertyUid) {
      let validationResponse = this.elsterValidationResults[propertyUid];
      if(!validationResponse.success) {
        if (validationResponse.errors !== null && validationResponse.errors !== undefined) {
          Object.values(validationResponse.errors).forEach(error => {
            if (error.type === 'error') {
              this.elsterValidationErrors.push(error);
            } else if (error.type === 'hint') {
              this.elsterValidationHints.push(error);
            }
          });
          this.elsterValidationResultDialog = true;
        } else {
          this.$api.showToast(this.$t('general.errors.elsterValidationError'), "error")
        }
      }
    },
    handleElsterValidationResultDialogClose() {
      this.elsterValidationResultDialog = false;
    },
    triggerEdit(uid, client_uid) {
      this.$router.push({
        name: "Properties",
        query: {
          'uid': uid
        },
        params: {
          'comp': 'edit',
          'client_uid': client_uid,
          'parent_type': this.parentType,
          'nav_records': this.getGridNavUids(this.gridId)
        }
      });
    },
    deleteCallback(response) {
      if (response.data.success) {
        this.triggerReload(this.gridId);
        this.$toast.success(this.$t('properties.delete_msg'));
      }
      if (response.data.prevent_uids && response.data.prevent_uids !== 'undefined') {
        this.$toast.error(this.$t('properties.delete_prevent_msg').replace('%prevent_uids%', response.data.prevent_uids));
      }
    },
    openSkenDataModal() {
      this.toggleDataAcquisitionModal = !this.toggleDataAcquisitionModal;
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-text {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.dialog-text1 {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 40px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.dialog-title {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 50px;
}

th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.box {

  padding: 10px;
  background-color: #fff4ce;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: table;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;

}

.chip-container {
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    height: 39px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: center;
    border-radius: 150px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: white;
    }

    //img {
    //  cursor: pointer;
    //  margin-left: 8px;
    //  font-size: 12px;
    //
    //  width: 8px;
    //  height: 8px;
    //  box-sizing: border-box;
    //  color: #333333;
    //  text-align: center;
    //  line-height: normal;
    //}
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {


    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    font-size: 14px;
    margin-right: 5px;

    &:hover {
      color: white;
    }
  }

  .info-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

a {
  color: theme('colors.muted_black');
  text-decoration: none;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
}

.a-link {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}
.elster-dlg {
  label {
    color: #333333 !important;
  }

  .textbold {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 700;
    color: theme('colors.muted_black');
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 15px;
  }

  ul.ul-list {
    padding: 0;
    margin: 20px 0 0 20px;
  }

  ul.ul-list li {
    font-size: 15px;
    margin-bottom: 8px;
    list-style: disc;
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
  }
}

.elster-result-header {
  p {
    text-align: center !important;
  }
}

.property-grid::v-deep {
  .grid_action_icon {
    width: 30px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .elster_validate_icon {
    background-image: url('~@/assets/elster_validate.svg');
  }

  .internal_approval_icon {
    background-image: url('~@/assets/internal_approval.svg');
  }

}

</style>
