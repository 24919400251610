<template>
  <div class="">
    <div class="w-full h-full mt-4 mr-8 pb-8">
      <div>
        <div class="flex justify-around space-x-8">
          <InputText class=" w-full" :label="$t('clients.attrs.street')" v-model="step3.street" placeholder=""/>
          <InputText class="w-full " :label="$t('clients.attrs.house_number')" v-model="step3.house_number"
                     placeholder=""/>
          <InputText class="w-full " :label="$t('clients.attrs.po_box')" v-model="step3.po_box" placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-4 ">
          <InputText
            class=" w-full"
            :label="$t('clients.attrs.postcode')"
            v-model="step3.postcode"
            placeholder=""
            @blur="searchZip"
            :isZip="true"
            :zipCountry="contactCountry"
            :errorMessage="$t('general.invalidZip')"
          />
          <InputSelect
            class="w-full"
            :label="$t('clients.attrs.city')"
            :options="cityList"
            v-model="step3.city"
            :selected-value="step3.city"
            :loading="zipSearch"
            :filterable="true"
            :auto-complete="true"
            :placeholder="$t('general.please_select')"
            :maxLength="25"
          />
          <InputSelect class="w-full " :options="countries" :label="$t('clients.attrs.country')" v-model="step3.country"
                       :selected-value="step3.country"
                       :filterable="true" placeholder=""/>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <InputText class="w-full" :label="$t('general.attrs.email')" type="email" v-model="step3.email"
                     :isError="contactEmailInvalid"
                     :isEmail="true"
                     :errorMessage="$t('general.errors.invalid_email')"
                     placeholder=""/>
          <div class="w-full  invisible"/>
          <div class="w-full  invisible"/>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <InputText class="w-full"
                     :label="$t('clients.attrs.mobile')"
                     :maxLength="16"
                     v-model="step3.mobile"
                     placeholder=""/>
          <InputText class="w-full"
                     :label="$t('clients.attrs.phone')"
                     :maxLength="16"
                     v-model="step3.phone"
                     placeholder=""/>
          <InputText class="w-full"
                     :label="$t('clients.attrs.fax')"
                     :maxLength="16"
                     v-model="step3.fax"
                     placeholder=""/>
        </div>
        <div class="flex justify-end space-x-4 mt-10 ">
          <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
          <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputText from "../../inputs/InputText";
import {ClientService} from "@/services/client.service";
import {email} from "vuelidate/lib/validators";

export default {
  name: "Step3",
  components: {InputText},
  data() {
    return {
      countries: [],
      cityList: [],
      zipSearch: false,
      step3: {
        street: this.$store.state.client.clientData.contact.street,
        house_number: this.$store.state.client.clientData.contact.house_number,
        po_box: this.$store.state.client.clientData.contact.po_box,
        postcode: this.$store.state.client.clientData.contact.postcode,
        city: this.$store.state.client.clientData.contact.city,
        country: this.$store.state.client.clientData.contact.country,
        email: this.$store.state.client.clientData.contact.email,
        mobile: this.$store.state.client.clientData.contact.mobile,
        phone: this.$store.state.client.clientData.contact.phone,
        fax: this.$store.state.client.clientData.contact.fax
      },
      contactEmailInvalid: false,

      clientErrors: {
        "contactPostcode": {
          invalid: false
        }
      },
    }
  },
  validations: {
    step3: {
      email: {
        email
      }
    }
  },
  computed: {
    ...mapGetters("client", ["getClientData"]),
    contactCountry() {
      return (typeof this.step3.country !== 'undefined') ? parseInt(this.step3.country) : 50;
    }
  },
  mounted() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
    })

    if (this.step3.country === '') {
      this.step3.country = this.defaultCountryId
    }
  },
  methods: {
    ...mapMutations("client", ["setClientData"]),
    async submitNext() {
      this.contactEmailInvalid = false;
      if (this.step3.email !== '') {
        this.$v.$touch();
        if (this.$v.$invalid && this.$v.step3.email.$error) {
          this.contactEmailInvalid = true;
          return;
        }
      }

      this.setClientData({
        "contact": this.step3
      })

      if (this.$isBusiness) {
        // Trigger save
        const service = new ClientService();
        if (await service.save({...this.getClientData})) {
          this.$emit("finish");
        }
      } else {
        this.$emit("goToNextStep");
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    searchZip() {
      this.zipSearch = true;
      if (this.step3.city === '' && this.step3.postcode !== '') {
        this.cityList = [];
        this.$api.lookup_city(this.step3.postcode).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.step3.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">


.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}
</style>
