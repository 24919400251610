<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img src="@/assets/check_done.svg"/>
    </div>
    <label class="label__text ">{{ $t('settings.user_management.user_added_success') }}</label>
    <Button @click="submit" class=" mt-10 "
            :text="$t('settings.user_management.all_users')"></Button>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Finish",
  methods: {
    ...mapMutations("sub_user", ["setCurrentStep", "init"]),
    submit() {
      this.setCurrentStep(1)
      this.init();
      this.$emit("finishButtonClick", "finish")
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}
</style>
