import {ApiService} from "./api.service";

export class MassElsterMessageTypeService extends ApiService {
    constructor() {
        super();
    }

    listAll(messageType = null, withMetadata = true) {
        return this.trigger(
            'mass_elster_message_types/list',
            {
                messageType: messageType,
                withMetadata: withMetadata
            }
        ).then((response) => {
            if (response.data.success && parseInt(response.data.total_records) > 0) {
                let list = this.parse_object_data(response.data.records);
                list.forEach(massElsterMessage => {
                    if (massElsterMessage.federal_state_uids !== null) {
                        massElsterMessage.federal_state_uids = Object.values(massElsterMessage.federal_state_uids);
                    }
                });
                return list;
            } else {
                return [];
            }
        });
    }

    get(primUid) {
        return this.trigger(
            'mass_elster_message_types/get',
            {primUid: primUid}
        ).then((response) => {
            if (response.data.success && response.data.massElsterMessageType) {
                let data = this.parse_object_data(response.data.massElsterMessageType);

                if (data.federal_state_uids !== null) {
                    data.federal_state_uids = Object.values(data.federal_state_uids);
                }

                return data;
            } else {
                return [];
            }
        });
    }

    save(data) {
        return this.trigger('mass_elster_message_types/save', data, true).then(response => {
            if (response.data.success) {
                return response;
            } else {
                this.$toast.error(response.data.message)
                return false;
            }
        });
    }

    delete(data) {
        return this.trigger('mass_elster_message_types/delete', data, true).then(response => {
            if (response.data.success) {
                return response;
            } else {
                this.$toast.error(response.data.message)
                return false;
            }
        });
    }

    getFileContentFromS3(data) {
        return this.trigger('mass_elster_message_types/get_file_content_from_s3', data, true);
    }
}
