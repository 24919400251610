<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('components.reports')"/>
    <div class="mr-7">
      <AppGrid :grid-id="gridId"
               :show-select-col="false"
               :show-search="false"
               :columns="columns"
               default-sort-attr="title"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="id"
               :menus="menus"
               :show-create-record="false"
               :local-data="reportsList"
               :use-local-data="true"
               :state-ful="false"
               :resizable-columns="false"
               :reorderable-columns="false"
               :pagination="false"
               @menu-clicked="handleMenuClick"
               @col-click="handleColClick"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "@/components/AppGrid";
import PageHeader from "@/components/PageHeader";
import {available_reports} from "@/core/constants";
import {mapGetters} from "vuex";

export default {
  name: "Reports",
  components: {AppGrid, PageHeader},
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {
      gridId: 'reports',
      reportsList: [],
      columns: [
        {
          field: 'title',
          header: this.$t('components.reports_table_col.col1'),
          isChecked: true,
          clickable: true,
          autoWidth: true
        }, {
          field: 'report_type',
          header: this.$t('components.reports_table_col.col2'),
          isChecked: true
        }
      ],
      menus: [
        {
          id: 'report.view',
          "icon": this.getAssetPath('view_report.svg'),
          "icon_disabled": this.getAssetPath('eye_gray.svg'),
          "title": this.$t('components.reports_menu.view_report'),
          "slug": "view_report",
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }
      ]
    }
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('components.reports')}`
    }
  },
  mounted() {
    if (available_reports.length > 0) {
      this.reportsList = available_reports.filter((o) => {
        if (o.account_type) {
          if (this.$isBusiness && o.account_type === 'tax_consultant') {
            return false;
          }

          if (!this.$isBusiness && o.account_type === 'business') {
            return false;
          }
        }

        return true;
      }).map((o) => {
        return {
          "id": o.id,
          "title": this.$t('components.available_reports.' + o.id.toLowerCase()),
          "report_type": this.$t('components.report_types.' + o.type.toLowerCase()),
          "config": o
        }
      })
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentTheme"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    handleMenuClick(args) {
      if (args.menuId && args.data && args.data[0]) {
        this.$router.push({
          "name": "Reports",
          "params": {
            "comp": "ViewReport",
            "reportConfig": args.data[0].config
          }
        })
      }
    },
    handleColClick(args) {
      if(args.row && args.field === 'title') {
        this.$router.push({
          "name": "Reports",
          "params": {
            "comp": "ViewReport",
            "reportConfig": args.row.config
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}


</style>
