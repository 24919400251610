<template>
  <div class="h-full bg-white pl-7 w-full ">
    <ModalDialog v-show="downloadTimeout" :headerText="$t('components.reports_timeout.header')">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class=" mt-8">
            {{ $t('components.reports_timeout.text') }}
          </div>
          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="cancelDownload"/>
            <Button class="w-40" :text="$t('components.reports_timeout.button_yes')" @click="processInBackground"/>
          </div>
        </div>
      </template>
    </ModalDialog>
    <PageHeader :title="reportTitle" :breadcrumbs="breadcrumbs"/>
    <div v-if="reportConfig.type==='CHART'">
      <div class="flex flex-row justify-between">
        <div class="flex">
          <UserMenu v-for="(menu,index) in menus" :key="index" :icon="menu.icon" :title="menu.title"
                    :slug="menu.slug" @click="handleMenuClick"/>
        </div>
      </div>
      <div class="w-full pr-7 mt-8 report-chart" v-if="renderChart">
        <apexchart type="pie" height="500px" :options="getChartOptions" :series="getChartSeries"></apexchart>
      </div>
    </div>
    <div v-if="reportConfig.type==='LIST'" class="mr-7 report-grid">
      <AppGrid :grid-id="gridId"
               data-endpoint="report/data"
               :show-select-col="false"
               :show-search="reportConfig.show_search"
               :columns="getColumns"
               :default-sort-attr="reportConfig.default_sort_attr"
               :default-sort-dir="reportConfig.default_sort_order"
               :show-filter="reportConfig.show_filters"
               :menus="menus"
               :server-params="getReportParams"
               :always-show-pagination="false"
               :show-create-record="false"
               :state-ful="false"
               :resizable-columns="false"
               :reorderable-columns="false"
               @menu-clicked="handleMenuClick"
               @col-click="handleColClick"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "@/components/AppGrid";

export default {
  name: "ViewReport",
  components: {AppGrid},
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('components.reports'),
          link: '/report/reports'
        },
        {
          title: this.$t('components.reports_menu.view_report'),
          link: ''
        }
      ],
      menus: [
        {
          id: 'export',
          "icon": this.getAssetPath('export.svg'),
          "title": this.$t('components.reports_menu.export_report'),
          "slug": "report_export",
        }
      ],
      reportConfig: {},
      renderChart: false,
      pieChartSeries: [],
      pieChartOptions: {
        chart: {
          width: 400,
          type: 'pie',
        },
        legend: {
          position: 'right',
          horizontalAlign: 'start',
          fontSize: '15px',
          fontFamily: 'Segue UI, sans-serif',
          fontWeight: 200,
          offsetX: 150,
          offsetY: 10,
          markers: {
            horizontalAlign: 'start',
            width: 18,
            height: 18,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 0,
            offsetX: -5,
            offsetY: 4
          },
          itemMargin: {
            horizontal: 12,
            vertical: 12
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        labels: [],
        responsive: [{
          breakpoint: 300,
          options: {
            chart: {
              width: 500,
              fontFamily: 'Segoe UI, sans-serif'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      downloadTimeout: false
    }
  },
  computed: {
    gridId() {
      return this.reportConfig.id ? 'grid-' + this.reportConfig.id : '';
    },
    reportTitle() {
      return this.reportConfig.id ? this.$t('components.available_reports.' + this.reportConfig.id.toLowerCase()) : '';
    },
    getColumns() {
      if(this.reportConfig.columns) {
        return this.reportConfig.columns.map((o) => {
          o.isChecked = true;
          return o;
        });
      }

      return [];
    },
    getReportParams() {
      if(this.reportConfig.id) {
        let attributes = [];
        if(this.reportConfig.columns) {
          attributes = this.reportConfig.columns.map((o) => {
            return o.field;
          });
        }
        return {
          "id": this.reportConfig.id,
          "reportType": this.reportConfig.type,
          "columns": attributes
        }
      }

      return {};
    },
    getChartOptions() {
      if(this.reportConfig.type === 'CHART' && this.reportConfig.chart_type === 'pie') {
        return this.pieChartOptions;
      } else {
        return [];
      }
    },
    getChartSeries() {
      if(this.reportConfig.type === 'CHART' && this.reportConfig.chart_type === 'pie') {
        return this.pieChartSeries;
      } else {
        return {};
      }
    }
  },
  mounted() {
    if(typeof(this.$route.params.reportConfig) === 'undefined') {
      this.$router.push('/report/reports');
    }
    this.reportConfig = this.$route.params.reportConfig ? this.$route.params.reportConfig : {};

    if(this.reportConfig.type && this.reportConfig.type === 'CHART') {
      this.$api.trigger('report/data', {
        "id": this.reportConfig.id,
        "reportType": this.reportConfig.type
      }, true).then((response) => {
        if(response.data.success) {
          if (this.reportConfig.chart_type === 'pie') {
            if(response.data.labels) {
              this.pieChartOptions.labels = this.$api.parse_object_data(response.data.labels);
            }
            if(response.data.series) {
              this.pieChartSeries = this.$api.parse_object_data(response.data.series);
            }
            this.renderChart = true;
          }
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      });
    }
  },
  methods: {
    handleMenuClick() {
      if (this.reportConfig) {
        let attributes = this.getAttributes();

        let requestData = {
          id: this.reportConfig.id,
          reportType: this.reportConfig.type,
          attributes: attributes
        };

        this.$api.trigger('report/export', requestData, true, '', 'blob', true)
          .then((response) => {
            if (response.headers['download_timeout'] || [504, 524].includes(response.status)) {
              this.downloadTimeout = true;
            } else {
              this.$api.trigger_download(response);
            }
          });
      }
    },
    getAttributes() {
      let attributes = [];

      if (this.reportConfig.columns) {
        attributes = this.reportConfig.columns.map((o) => {
          return o.exportField ? o.exportField : o.field;
        });
      } else if (this.reportConfig.export_attributes) {
        attributes = this.reportConfig.export_attributes;
      }

      return attributes;
    },
    processInBackground() {
      let attributes = this.getAttributes();

      let requestData = {
          id: this.reportConfig.id,
          reportType: this.reportConfig.type,
          attributes: attributes,
          in_background: 1
      };

      this.$api.trigger('report/export', requestData, true, '', 'blob')
        .then((response) => {
          if (response.data.type === 'application/json') {
            response.data.text().then((responseString) => {
              let responseData = JSON.parse(responseString);

              if (responseData.success) {
                this.$api.showToast(this.$i18n.t('general.export_in_background'))
                this.downloadTimeout = false;
                return;
              }

              if (responseData.error_key) {
                this.$api.showToast(this.$t(`general.errors.${responseData.error_key}`), 'error');
                return;
              }

              this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
            });
          } else {
            this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
          }
      });
    },
    handleColClick(args) {
      let client_reports = ['PROPERTY_BY_CLIENT', 'CLIENT_WITHOUT_PROPERTY', 'CLIENT_INVITATION_PENDING'];
      if (this.reportConfig.id === 'PROPERTY_WITHOUT_DECLARATION') {
        if (args.field === 'uid' && args.row.prim_uid) {
          this.$router.push('/property/edit?uid=' + args.row.prim_uid);
        } else if (args.field === 'client_name' && args.row.client_uid) {
          this.$router.push('/client/edit?uid=' + args.row.client_uid);
        }
      } else if(this.reportConfig.id === 'PENDING_DECLARATION_APPROVAL') {
        this.$router.push('/declaration/edit?uid=' + args.row.prim_uid);
      } else if(this.reportConfig.id === 'SUBMITTED_DECLARATIONS_OF_DETERMINATION_WITHOUT_ACCOUNTING') {
        this.$router.push('/declaration/edit?uid=' + args.row.prim_uid);
      } else if(client_reports.includes(this.reportConfig.id) && args.row.prim_uid) {
        this.$router.push('/client/edit?uid=' + args.row.prim_uid);
      }
    },
    cancelDownload() {
      this.downloadTimeout = false;
    }
  }
}
</script>

<style scoped lang="scss">

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.report-grid::v-deep {
  td.client_type div.cell-content img.column_icon {
    margin-left: auto;
    margin-right: auto;
  }
}

.report-chart::v-deep {
  .apexcharts-legend-series {
    text-align: left;
  }
}
</style>
