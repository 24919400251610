<template>
  <div class=" flex flex-col ">
    <!--          header-->
    <div class=" flex justify-around items-center header">
      <p class="flex-1  section-header-text">{{ title }}</p>
      <div class="flex justify-center items-center space-x-2 cursor-pointer ">
        <!--        search-->
        <div class="search-header flex justify-center" v-if="showSearch">
          <input
              class="mr-6  w-36  focus:outline-none"
              @keyup.enter="searchWidget"
              v-model="txtFilter"
              :placeholder="$t('general.grid.search')"/>
        </div>
        <!--drop down-->
        <div class="pb-5 pt-5" v-on-clickaway="closeMenu"
             @click="isMenuOpen = !isMenuOpen">
          <div
              class="relative  flex items-center mr-2 inline-block text-left text-gray-800"

          >
            <div>
      <span class=" ">
        <img class="three-dots" src="@/assets/horizontal_dots_gray.svg"/>

      </span>
            </div>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
              <div v-show="showDropDown"
                   v-if="isMenuOpen"
                   class="dropdown  origin-top-right absolute right-1 -mr-3 mt-24     overflow-hidden  z-20"
              >
                <div class="mb-2 space-y-2">
                  <IconLabel :icon="require('@/assets/cog_gray.svg')" :label="$t('dashboard.settings')"
                             :blackText="true"
                             iconStyle="blackish" @click="handlepopupclick" v-if="showSetting"/>
                  <IconLabel :icon="require('@/assets/disable_gray.svg')" iconStyle="blackish"
                             :label="$t('dashboard.remove_widget')" :blackText="true"
                             @click="handlepopupclick"/>

                </div>

              </div>
            </transition>
          </div>
        </div>
      </div>

    </div>
    <!--            header line-->
    <hr/>

  </div>
</template>

<script>
import {directive as onClickaway} from "vue-clickaway";

export default {

  name: "Header",
  data() {
    return {
      isMenuOpen: false,
      txtFilter: ''
    }
  },

  directives: {
    onClickaway: onClickaway,
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    caller: {
      type: String,
      default: ""
    },
    showDropDown: {
      type: Boolean,
      default: false
    },
    showSetting: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.caller)
    },
    handlepopupclick() {
      this.isMenuOpen = false;
      this.$emit('popupclick', "remove")
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    searchWidget() {
      this.$emit('searchWidget', this.txtFilter)
    }
  }
}
</script>

<style scoped lang="scss">
.section-header-text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  padding-left: 20px;
}
.three-dots {
  width: 38px;
  height: 4px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
.header {
  height: 53px;
  text-align: left;
}

.header-line {
  @apply w-full;
  height: 1px;
  border: 1px solid #dfdfdf;
  background-color: rgba(255, 255, 255, 0);
  color: #333333;
}

.dropdown {
  width: 230px;
  padding: 2px 2px 2px 2px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
}
</style>
