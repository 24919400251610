<template>
  <div class="h-full bg-white pl-7 w-full ">
    <p class="header-component mt-10"> Add Client</p>
    <hr class=" hr-line-user  mr-7 mb-1 mt-8 "/>


    <div class="w-full mt-8 mr-8">
      <div class="flex justify-around space-x-10">
        <InputSelect class="w-full" label="Client Type" :isRequired="true" placeholder="Select Client Type"/>
        <InputText class="w-full pr-6" label="Name" :isRequired="true" placeholder="Name"/>

      </div>
      <div class="flex justify-around space-x-10 mt-4 ">
        <InputText class="w-full" label="Street" :isRequired="true"
                   placeholder="Enter Street"/>
        <InputText class="w-full pr-6" label="Zip" :isRequired="true"
                   placeholder="Enter Zip"/>

      </div>
      <div class="flex justify-around space-x-10 mt-6">
        <InputText class="w-full" label="City" :isRequired="true" placeholder="Enter City"/>
        <InputSelect class="w-full pr-6" label="Country" :isRequired="true" placeholder="Select Country"/>

      </div>
      <div class="flex justify-around space-x-10 mt-4">
        <InputText class="w-full" label="Phone" :isRequired="true" placeholder="Enter Phone (xxx-xxx-xxxx)"/>
        <InputText class="w-full pr-6" label="Email" :isRequired="true"
                   placeholder="Enter Email"/>

      </div>
      <div class="flex justify-center space-x-4 mt-20 mr-6">
        <Button class="w-40" :text="$t('buttons.save')" :secondary="true"/>
        <Button class="w-40" :text="$t('buttons.cancel')"/>
      </div>
      <!--      <div class="flex justify-center items-center">
              <div class="flex mt-20 space-x-6">
                <vnt-button class="label text-center bg-green  text-white  " primary>
                  Save
                </vnt-button>
                <vnt-button :click="handleSubmit" class=" label text-center text-white    ">
                  Cancel
                </vnt-button>
              </div>

            </div>-->

    </div>


  </div>
</template>

<script>
import {userRoles, userStatus} from "@/core/constants";

export default {
  name: "CreateNewClient",
  data() {
    return {
      roleOptions: userRoles,
      statusOptions: userStatus,
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
