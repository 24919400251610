<template>
  <div class="flex flex-col justify-center items-center">
    <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex m-2">
      <img src="@/assets/check_done.svg"/>
    </div>
    <div class="flex flex-col justify-start items-start mt-6">
      <label class="text self-start ">{{ $t('clients.addison_import.steps.finish.success') }}</label>
      <ul class="mt-2">
        <li class="text self-start">&bull; {{ clientImported }} {{ $t('clients.addison_import.steps.finish.added') }}</li>
        <li class="text self-start" v-show="clientFailed">&bull; {{ clientFailed }} {{ $t('clients.addison_import.steps.finish.failed') }}</li>
      </ul>
    </div>
    <Button class="w-1/2 mt-10" :text="$t('register.next')" @click="submit" :marginauto="true"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Finish",
  computed: {
    ...mapGetters("addison_import", ["getResult"]),
    clientImported() {
      return this.getResult.imported;
    },
    clientFailed() {
      return this.getResult.failed;
    }
  },
  methods: {
    ...mapMutations("addison_import", ["setCurrentStep", "init"]),
    submit() {
      this.setCurrentStep(1);
      this.init();
      this.$router.push('/client/list')
    }
  }
}
</script>

<style scoped lang="scss">


.label {

  font-family: 'Segoe UI', sans-serif;
  text-align: left;

  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}

.text {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}
</style>
