<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="userData.name" :breadcrumbs="breadcrumbs"/>
    <ModalDialog v-show="showTakeOverDialog" :headerText="$t('settings.user_management.permissions.take_over_title')"
                 @close="cancel_take_over">
      <template v-slot:body>
        <div class="flex flex-col justify-start  mt-6 ">
          <InputSelect :label="$t('settings.user_management.user')" :isRequired="true" :options="userList"
                       overlayAppendTo="body"
                       :filterable="true" v-model="permission_user_uid"/>
          <div class="flex mt-20 mb-4   justify-end   space-x-4">
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="cancel_take_over"/>
            <Button class="" :text="$t('settings.user_management.take_over')" @click="take_over_permissions"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="isDatevDeviceModalVisible" @close="closeDatevDeviceDlg"
                 :headerText="datevDeviceModalHeader">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-8 space-y-4 w-full">
          <InputText :label="$t('settings.datev_devices.name_label')" v-model="datevDevice.name"/>
          <InputText :label="$t('settings.datev_devices.password_label')" type="password" v-model="datevDevice.password"
                     v-show="!datevDevice.uid"
                     :show-password-template="true"
                     :show-password-view-icon="true"
          />
        </div>
        <div class="flex flex-col justify-start mt-8 space-y-4 w-full" v-show="!datevDevice.uid">
          <label class="align-left">{{ $t('settings.datev_devices.add_info') }}</label>
        </div>

        <div class="flex justify-end space-x-10 mt-10 mr-6 w-full">
          <div class="flex  mb-4 justify-end w-full space-x-4">
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="closeDatevDeviceDlg"/>
            <Button class=" " @click="saveDatevDevice" :text="$t('buttons.ok')"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="w-full mt-8 mr-8">
      <AppTabs :categories="categories" :splitSaveButton="true" :save-handler="submit" @buttonClick="tabsButtonClicked">
        <!-- tab1 -->
        <template #tab-pane-basic>
          <div class="flex justify-around space-x-8">
            <InputText :disabled="true" class="w-full" :label="$t('settings.user_management.attrs.user_id')"
                       :value="userData.user_id"/>
            <InputSelect class="w-full" :label="$t('settings.account.fields.salutation')"
                         v-model="userData.salutation"
                         :selected-value="userData.salutation"
                         :options="salutations"
                         :isRequired="true"
                         :is-error="isSalutationError"
                         :error-message="$t('general.errors.required')"
                         placeholder=""/>
          </div>
          <div class="flex justify-around space-x-8 mt-5">
            <InputText class="w-full" :label="$t('settings.user_management.attrs.name')" :isRequired="true"
                       :isError="isNameError" :errorMessage="nameError"
                       :placeholder="$t('settings.user_management.attrs.name')" v-model="userData.name"/>
            <InputText class="w-full" :label="$t('settings.user_management.attrs.email')" :isRequired="true"
                       :isError="isEmailError" :errorMessage="emailError"
                       :placeholder="$t('settings.user_management.attrs.email')" v-model="userData.email"/>
          </div>
          <div class="flex justify-around space-x-8 mt-5">
            <InputSelect class="w-full" :label="$t('settings.user_management.attrs.role')" :options="roleOptions"
                         :isRequired="true"
                         v-model="userData.role"
                         :selectedValue="userData.role"
                         :is-error="isRoleError"
                         :error-message="roleError"
                         :disabled="userData.main_user"
                         @input="roleSelected"
                         :placeholder="$t('settings.user_management.attrs.select_role')"/>
            <InputSelect class="w-full" :label="$t('settings.user_management.attrs.status')" :isRequired="true"
                         :options="statusOptions"
                         v-model="userData.status"
                         :selected-value="userData.status"
                         :is-error="isStatusError"
                         :error-message="statusError"
                         :placeholder="$t('settings.user_management.attrs.select_status')"/>
          </div>
          <div class="flex justify-around space-x-8 mt-5">
            <InputText class="w-full"
                       :label="$t('settings.user_management.attrs.phone')"
                       :maxLength="16"
                       v-model="userData.phone"
                       :placeholder="$t('settings.user_management.attrs.phone')"/>
            <InputText class="w-full" :label="$t('settings.user_management.attrs.agent_code')"
                       v-model="userData.agent_code"
                       :maxLength="16"
                       :placeholder="$t('settings.user_management.attrs.agent_code')"/>
          </div>

          <div v-if="!$isBusiness" class="flex justify-around space-x-8 mt-5">
            <InputSelect class="w-full" :label="$t('settings.account.fields.auth_mode')" :options="authModeOptions"
                         :isRequired="true"
                         v-model="userData.auth_mode"
                         :selectedValue="userData.auth_mode"
                         :is-error="isAuthModeError"
                         :error-message="authModeError"
                         :placeholder="$t('settings.user_management.attrs.select_auth_mode')"/>
            <div class="w-full pr-6 invisible"></div>
          </div>

          <div class="flex justify-around mt-5" :class="showMfa ? 'space-x-8' : ''">
            <div class="flex flex-col justify-start items-start mt-4 w-full" v-show="showMfa">
              <div class="flex">
                <p class="two-factor  self-start">{{ $t('settings.account.fields.twofactor') }}</p>
                <img class="help" :src="getAssetPath('help_green.svg')"/>
              </div>
              <label class="switch">
                <input type="checkbox" v-model="userData.two_fa_enabled"
                       true-value="1"
                       false-value="0"
                       :disabled="forceMfa"
                >
                <span class="slider round"></span>
              </label>
            </div>
            <div class="einzel_field w-full flex" style="align-items: center;">
              <div class=" check extra_dtl_check mr-2 mb-2">
                <InputCheck
                  class="ml-1 mb-2"
                  @check="toggle_main_user"
                  :isChecked="userData.main_user"
                  :isDisabled="mainUserDisabled"
                />
              </div>
              <label class="label-title">
                {{ $t('settings.account.fields.main_user') }}
                <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                           :hideTriggers="['click']" style="display: inline-block;">
                  <!-- This will be the popover target (for the events and position) -->
                  <img class="help_icon self-center cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                  <!-- This will be the content of the popover -->
                  <template #popper>
                    <div class="tooltip-content">
                      <p class="space-y-2 mb-1 popover-text">
                        {{ $t('settings.account.fields.main_user_help') }}
                      </p>
                    </div>
                    <!-- You can put other components too -->
                  </template>
                </VDropdown>
              </label>
            </div>
          </div>
        </template>

        <!-- tab2 -->
        <template #tab-pane-permission>
          <div class="info flex justify-start items-center" v-show="showDatevInfo">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text">{{ $t('settings.user_management.datev_auth_info') }}</label>
          </div>
          <div class="mt-18 flex flex-col">
            <div class="space-y-8">
              <div class="flex justify-between items-center">
                <InputCheck
                    :text="$t('settings.user_management.permissions.manage_property')"
                    :isChecked="userData.manage_property"
                    @check="clicked_manage_property"
                />
              </div>
              <div class="flex justify-between items-center">
                <InputCheck
                    :text="$t('settings.user_management.permissions.release_declaration')"
                    :isChecked="userData.release_declaration"
                    @check="clicked_release_declaration"
                />
              </div>
              <div class="flex justify-between items-center">
                <InputCheck :text="$t('settings.user_management.permissions.elster_transaction')"
                            :isChecked="userData.role === 'it_administrator' ? false : userData.elster_transaction"
                            :isDisabled="userData.role === 'it_administrator'"
                            @check="clicked_elster_transaction"/>
              </div>
              <div class="flex justify-between items-center">
                <InputCheck :text="$t('settings.user_management.permissions.allow_mass_update')"
                            :isChecked="userData.role === 'it_administrator' ? false : userData.allow_mass_update"
                            :isDisabled="userData.role === 'it_administrator'"
                            @check="clicked_allow_mass_update"/>
              </div>
              <div class="flex justify-between items-center">
                <InputCheck :text="$t('settings.user_management.permissions.can_force_delete_clients')"
                            :isChecked="userData.can_force_delete_clients === '1'"
                            @check="clicked_can_force_delete_clients"/>
              </div>
              <div class="flex justify-between items-center" v-if="useDms">
                <InputCheck :text="$t('settings.user_management.permissions.allow_datev_doc_transfer')"
                            :isChecked="useDms ? userData.allow_datev_doc_transfer === '1' : false"
                            @check="clicked_allow_datev_doc_transfer"/>
              </div>
              <div class="flex justify-between items-center" v-if="ggwAccount">
                <InputCheck :text="$t('settings.user_management.permissions.can_handover_item_values')"
                            :isChecked="userData.can_handover_item_values === '1'"
                            @check="clicked_can_handover_item_values"/>
              </div>

              <div class="flex justify-between items-center">
                <InputCheck :text="$t('settings.user_management.permissions.send_dispute')"
                            :isChecked="userData.send_dispute"
                            @check="clicked_send_dispute"/>
              </div>

              <div class="flex justify-between items-center">
                <InputCheck :text="$t('settings.user_management.permissions.all_branch')"
                            :isChecked="userData.all_branch_access" @check="clicked_all_branch"/>
                <label class="w-full link"
                       @click="clicked_takeover">{{ $t('settings.user_management.permissions.take_over') }}</label>
              </div>
              <div class="flex flex-col" v-show="!userData.all_branch_access">
                <div class="flex label "><label>{{ $t('settings.user_management.permissions.branch') }}</label></div>
                <div class="flex flex-col mt-1 w-1/2 mb-6">
                  <AutoComplete
                      v-if="!branchLoading"
                      v-model="userData.selected_branches"
                      :multiple="true"
                      field="name"
                      :suggestions="filteredBranches"
                      @complete="searchBranch($event)"
                      @item-select="branchSelected"
                      @item-unselect="branchSelected"
                      forceSelection
                      :auto-highlight="true"
                  />
                  <label class="sub-title mt-1">{{ $t('settings.user_management.permissions.instruction') }}</label>
                </div>
              </div>
              <div>
                <InputCheck :text="$t('settings.user_management.permissions.all_clients')"
                            :isChecked="userData.all_client_access" @check="clicked_all_client"/>
              </div>

              <div class="flex flex-row mb-4 fit">
                <InputCheck :text="$t('settings.user_management.permissions.change_management')"
                            :is-checked="userData.change_management_access"
                            @check="clicked_change_management"/>
              </div>

            </div>
            <div v-show="!userData.all_client_access" :class="{'client_dual_list' : !$isBusiness}">
              <div class="flex">
                <label class=" mt-6 mb-1  text-left" style="color: #4B4B4B">
                  {{ $t('settings.user_management.select_clients') }}
                  <span style="color:red;">*</span>
                </label>
              </div>
              <DualListSelect :list="available_clients" v-model="userData.selected_clients"
                              :columns="$isBusiness ? columnsBusiness : columns"
                              dataKey="prim_uid"
                              :totalRecordCount="totalClientCount"
                              @filterList="clientListSearch"
                              :enableAllSelect="true"
                              :recordLimit="1000"
                              :leftHeader="$t('settings.user_management.all_clients')"
                              :rightHeader="$t('settings.user_management.selected_clients')"
                              :showLeftHelpIcon="true"
                              :leftTooltip="$t('settings.user_management.tooltip.step2.dualListTooltipLeft')"
                              :showRightHelpIcon="true"
                              :rightTooltip="$t('settings.user_management.tooltip.step2.dualListTooltipRight')"
              />
            </div>
          </div>
        </template>

        <!-- tab3 -->
        <template #tab-pane-protocol>
          <VersionGrid :parent-id="parseInt(userData.prim_uid)" parent-type="users"/>
        </template>

        <!-- tab4 -->
        <template #tab-pane-devices>
          <AppGrid grid-id="grid-datev-devices"
                 data-endpoint="datev/list_devices"
                 :columns="datevDeviceColumns"
                 :menus="datevDeviceMenus"
                 :server-params="serverParams"
                 default-sort-attr="name"
                 :default-sort-dir="1"
                 :selectable="true"
                 selection-data-key="id"
                 :show-create-record="false"
                 :pagination="false"
                 :reorderable-columns="false"
                 :resizable-columns="false"
                 :state-ful="false"
                 :show-search="false"
                 :show-select-col="false"
                 @menu-clicked="handleMenuClick"
                 @col-click="handleColClick"
          />
        </template>
      </AppTabs>
    </div>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';
import {salutations, userRoles, userStatus} from "@/core/constants";
import AppTabs from "@/components/AppTabs";
import {UserService} from "@/services/user.service";
import {ClientService} from "@/services/client.service"
import {BranchService} from "@/services/branch.service";
import {email, required} from "vuelidate/lib/validators";
import VersionGrid from "../VersionGrid";
import {mapGetters, mapMutations} from "vuex";
import AppGrid from "@/components/AppGrid";

export default {
  name: "edituser",
  components: {AppTabs, AutoComplete, VersionGrid, AppGrid},
  data() {
    return {
      userService: new UserService(),
      clientService: new ClientService(),
      userData: {
        'prim_uid': 0,
        'salutation': '',
        'user_id': '',
        'name': '',
        'email': '',
        'role': '',
        'auth_mode': '',
        'status': '',
        'phone': '',
        'agent_code': '',
        'two_fa_enabled': 0,
        'main_user': false,
        'release_declaration': false,
        'all_branch_access': false,
        'elster_transaction': false,
        'allow_mass_update': false,
        'allow_datev_doc_transfer': false,
        'can_handover_item_values': false,
        'can_force_delete_clients': false,
        'all_client_access': false,
        'change_management_access': false,
        'manage_property': false,
        'send_dispute': true,
        'selected_clients': [],
        'selected_branches': []
      },
      showTakeOverDialog: false,
      branchLoading: true,
      columns: [
        {
          field: 'client_id',
          label: this.$t('clients.attrs.id'),
          search: true
        }, {
          field: 'client_type_icon',
          label: this.$t('clients.attrs.type'),
          type: 'icon'
        }, {
          field: 'client_name',
          label: this.$t('clients.attrs.name'),
          search: true
        }
      ],
      columnsBusiness: [
        {
          field: 'client_id',
          label: this.$t('clients.attrs.id'),
          search: true
        }, {
          field: 'client_name',
          label: this.$t('clients.attrs.name'),
          search: true
        },
      ],
      clients: [],
      available_clients: [],
      totalClientCount: 0,
      branches: [],
      filteredBranches: [],
      userList: [],
      permission_user_uid: "0",
      breadcrumbs: [
        {
          title: this.$t('breadcrumbs.settings'),
          link: ''
        },
        {
          title: this.$t('breadcrumbs.user_management'),
          link: '/setting/usermanagement'
        },
        {
          title: this.$t('breadcrumbs.edit_user'),
          link: ''
        }
      ],
      roleOptions: userRoles,
      authModeOptions: this.$t('settings.account.fields.auth_mode_options'),
      statusOptions: userStatus,
      salutations: salutations,
      currentCategory: 'basic',
      tabsFields: [
        {
          name: this.$t('user.edit_user.tabs.tab1'),
          slug: 'basic',
          fields:[
            {
            name:this.$t('settings.account.fields.salutation'),
            slug:'salutation'
            },
            {
              name:this.$t('settings.user_management.attrs.name'),
              slug:'name'
            },
            {
              name:this.$t('settings.user_management.attrs.email'),
              slug:'email'
            },
            {
              name:this.$t('settings.user_management.attrs.role'),
              slug:'role'
            },
            {
              name:this.$t('settings.account.fields.auth_mode'),
              slug:'auth_mode'
            },
            {
              name:this.$t('settings.user_management.attrs.status'),
              slug:'status'
            },

          ]
        }, {
          name: this.$t('user.edit_user.tabs.tab2'),
          slug: 'permission',
          hide: false,
          fields:[]
        }, {
          name: this.$t('user.edit_user.tabs.tab3'),
          slug: 'protocol',
          fields:[]
        }
      ],
      categories: [
        {
          name: this.$t('user.edit_user.tabs.tab1'),
          slug: 'basic'
        }, {
          name: this.$t('user.edit_user.tabs.tab2'),
          slug: 'permission',
          hide: false
        }, {
          name: this.$t('user.edit_user.tabs.tab3'),
          slug: 'protocol'
        }
      ],
      isSalutationError: false,
      isEmailError: false,
      emailError: '',
      isNameError: false,
      nameError: '',
      isRoleError: false,
      roleError: '',
      isAuthModeError: false,
      authModeError: '',
      isStatusError: false,
      statusError: '',
      mainUserDisabled: false,

      serverParams: {
        user_uid: 0
      },

      isDatevDeviceModalVisible: false,
      datevDeviceModalHeader: '',
      datevDevice: {
        uid: 0,
        name: '',
        password: ''
      },
      datevDeviceColumns: [
        {
          field: 'name',
          header: this.$t('settings.datev_devices.name'),
          isChecked: true,
          sortable: false,
          clickable: true
        },
        {
          field: 'createdAt',
          header: this.$t('settings.datev_devices.created_at'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'lastUsed',
          header: this.$t('settings.datev_devices.last_used'),
          isChecked: true,
          sortable: false
        }
      ],
      datevDeviceMenus: [
        {
          id: 'device.add',
          icon: this.getAssetPath('add_declaration_green.svg'),
          title: this.$t('settings.datev_devices.add')
        },
        {
          id: 'device.edit',
          icon: this.getAssetPath('edit_declaration_green.svg'),
          icon_disabled: this.getAssetPath('edit-declaration-gray.svg'),
          title: this.$t('settings.datev_devices.edit'),
          disable_on_multi: true,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'device.delete',
          icon: this.getAssetPath('delete_green.svg'),
          icon_disabled: this.getAssetPath('delete_user_gray.svg'),
          title: this.$t('settings.datev_devices.delete'),
          action_endpoint: 'datev/delete_device',
          action_confirmation: {
            show: true
          },
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        }
      ],
    }
  },
  validations: {
    userData: {
      salutation: {
        required
      },
      name: {
        required
      },
      email: {
        required, email
      },
      role: {
        required
      },
      auth_mode: {
        required
      },
      status: {
        required
      },
    },
  },
  computed: {
    showDatevInfo() {
      return this.getCurrentUser().settings.tax_office_software === 'datev';
    },
    showMfa() {
      return this.getCurrentUser().settings.tax_office_software !== 'datev';
    },
    forceMfa() {
      return this.getCurrentUser().settings.force_2fa === 1;
    },
    useDms() {
      return this.getCurrentUser().settings.tax_office_software === 'datev' && this.getCurrentUser().settings.datev_dms_enabled === '1';
    },
    ggwAccount() {
      return this.getCurrentUser().settings.tax_office_software && (this.getCurrentUser().settings.tax_office_software === 'datev' || this.getCurrentUser().settings.tax_office_software === 'addison-oneclick') ;
    }
  },
  async beforeCreate() {
    let userService = new UserService();
    userService.listAll().then((userList) => {
      this.userList = userList.map(o => {
        return {
          "name": o.userId + ' - ' + o.name,
          "code": o.id
        }
      })
    });

    let client = new ClientService();
    this.$loading.show();
    await client.listAll({"rows": 1000}).then((clients) => {
      if(clients && clients.list) {
        this.totalClientCount = clients.totalRecordCount;
        this.clients = clients.list;
      }

      this.fetchUser();
    });

    let branch = new BranchService();
    this.branches = this.filteredBranches = await branch.listAll();
    this.branchLoading = false;
  },
  mounted() {
    if(parseInt(this.getCurrentUser().id) === parseInt(this.$route.query.uid) && this.getCurrentUser().role === 'it_administrator') {
      this.goBack();
    }

    this.serverParams.user_uid = parseInt(this.$route.query.uid);

    this.handleDatevDevicesVisibility();
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("user", ["saveAccessLog"]),
    ...mapMutations("grid", ["triggerReload"]),
    handleDatevDevicesVisibility() {
      if (this.showDatevInfo) {
        let devicesExistInTabsFields = false;
        this.tabsFields.map((o) => {
          if (o.slug === 'devices') {
            devicesExistInTabsFields = true;
          }
        });

        if (!devicesExistInTabsFields) {
          this.tabsFields.push({
            name: this.$t('settings.datev_devices.section_head'),
            slug: 'devices',
            hide: true,
            fields:[]
          });
        }

        let devicesExistInCategories = false;
        this.categories.map((o) => {
          if (o.slug === 'devices') {
            devicesExistInCategories = true;
          }
        });

        if (!devicesExistInCategories) {
          this.categories.push({
            name: this.$t('settings.datev_devices.section_head'),
            slug: 'devices',
            hide: true
          });
        }

        this.triggerReload('grid-datev-devices')
      }
    },
    fetchUser() {
      this.mainUserDisabled = false;
      this.userService.get(this.$route.query.uid).then(userData => {
        if (userData.prim_uid) {
          this.saveAccessLog({
            "recordType": "user",
            "recordUid": userData.prim_uid
          });
          this.userData = userData;
          if (userData.selected_branches.length > 0) {
            this.branchSelected();
          } else {
            this.updateClientList();
          }

          if (this.userList.length > 0) {
            this.userList = this.userList.filter(o => {
              if (o.code !== userData.prim_uid) {
                return o;
              }
            });
          }

          if(this.getCurrentUser().settings.force_2fa === 1) {
            this.userData.two_fa_enabled = 1;
          }

          if (this.userData.main_user) {
            this.mainUserDisabled = true;
          }
        } else {
          this.$router.go(-1);
        }
      });
    },
    clientListSearch(params) {
      params.rows = 1000;
      this.clientService.listAll(params).then((clients) => {
        if(clients && clients.list) {
          this.clients = clients.list;
          this.updateClientList();
        }
      });
    },
    roleSelected(role) {
      this.categories = this.categories.map((o) => {
        o.hide = o.slug === 'permission' && role === 'it_administrator';
        return o;
      });
    },
    cancel_take_over() {
      this.showTakeOverDialog = false;
    },
    take_over_permissions() {
      // Trigger ajax request and fetch permission of selected user
      this.cancel_take_over();
      this.userService.get(this.permission_user_uid).then((userData) => {
        if (userData.prim_uid) {
          this.userData.release_declaration = userData.release_declaration;
          this.userData.all_branch_access = userData.all_branch_access;
          this.userData.elster_transaction = userData.elster_transaction;
          this.userData.allow_mass_update = userData.allow_mass_update;
          this.userData.allow_datev_doc_transfer = this.useDms ? userData.allow_datev_doc_transfer : false;
          this.userData.can_handover_item_values = this.ggwAccount ? userData.can_handover_item_values : false;
          this.userData.can_force_delete_clients = userData.can_force_delete_clients;
          this.userData.all_client_access = userData.all_client_access;
          this.userData.change_management_access = userData.change_management_access;
          if (this.userData.role === 'administrator') {
            this.userData.change_management_access = true;
          }

          this.userData.manage_property = userData.manage_property;
          this.userData.selected_branches = userData.selected_branches;
          this.userData.selected_clients = userData.selected_clients;
          this.userData.send_dispute = userData.send_dispute;

          this.updateClientList();
        }
      });
    },
    updateClientList() {
      if (!this.userData.all_branch_access) {
        this.branchSelected();
      } else {
        this.available_clients = this.clients.filter(a => !this.userData.selected_clients.map(b => b.prim_uid).includes(a.prim_uid))
      }
    },
    clicked_takeover() {
      this.showTakeOverDialog = true;
    },
    clicked_all_branch(checked) {
      this.userData.all_branch_access = checked
      if(checked) {
        this.available_clients = this.clients;
      } else {
        this.available_clients = [];
      }
    },
    clicked_elster_transaction(checked) {
      this.userData.elster_transaction = checked
    },
    clicked_allow_mass_update(checked) {
      this.userData.allow_mass_update = checked
    },
    clicked_allow_datev_doc_transfer(checked) {
      this.userData.allow_datev_doc_transfer = checked
    },
    clicked_can_handover_item_values(checked) {
      this.userData.can_handover_item_values = checked
    },
    clicked_can_force_delete_clients(checked) {
      this.userData.can_force_delete_clients = checked
    },
    clicked_all_client(checked) {
      this.userData.all_client_access = checked;
      if(!checked && this.userData.all_branch_access) {
        this.available_clients = this.clients.filter(a => !this.userData.selected_clients.map(b => b.prim_uid).includes(a.prim_uid))
      }
    },
    clicked_change_management(checked) {
      this.userData.change_management_access = checked;
    },
    clicked_manage_property(checked) {
      this.userData.manage_property = checked;
    },
    toggle_main_user(checked) {
      this.userData.main_user = checked;

      if (this.userData.main_user) {
        this.userData.role = 'administrator';
      }
    },
    clicked_release_declaration(checked) {
      this.userData.release_declaration = checked;
    },
    clicked_send_dispute(checked) {
      this.userData.send_dispute = checked;
    },
    tabsButtonClicked(btn_key) {
      if(btn_key === 'cancel') {
        this.$router.push('/setting/usermanagement');
      }
    },
    showErrorNotification(field){
      let outerField= this.tabsFields.find(x=>x.fields.find((str) => str.slug === field))
      let field_index=this.tabsFields.findIndex(x=>x.fields.find((str) => str.slug === field))
      let field_name=this.tabsFields[field_index].fields.find((str) => str.slug === field)
      let message= this.$t('general.check_fields').replace('%tab%', outerField.name).replace('%field%', field_name.name);
      this.$api.showToast(message,'error');
    },
    async submit(btn_key) {
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isSalutationError = this.$v.userData.salutation.$error
        this.isSalutationError ? this.showErrorNotification('salutation') : ''
        this.isNameError = this.$v.userData.name.$error
        this.nameError = this.$v.userData.name.$error ? this.$t('register.step1.nameError') : ""
        this.isNameError ? this.showErrorNotification('name') : ''
        this.isEmailError = this.$v.userData.email.$error
        this.emailError = this.$v.userData.email.$error ? this.$t('register.step1.emailError') : ""
        this.isEmailError ? this.showErrorNotification('email') : ''
        this.isRoleError = this.$v.userData.role.$error
        this.roleError = this.$v.userData.role.$error ? this.$t('settings.user_management.errors.required_role') : ""
        this.isRoleError ? this.showErrorNotification('role') : ''
        this.isAuthModeError = this.$v.userData.auth_mode.$error
        this.authModeError = this.$v.userData.auth_mode.$error ? this.$t('settings.user_management.errors.required_auth_mode') : ""
        this.isAuthModeError ? this.showErrorNotification('auth_mode') : ''
        this.isStatusError = this.$v.userData.status.$error
        this.statusError = this.$v.userData.status.$error ? this.$t('settings.user_management.errors.required_status') : ""
        this.isStatusError ? this.showErrorNotification('status') : ''

        return; // if any of the validations fail, we don't move on
      }

      this.userService.save(this.userData).then((response) => {
        if(response.data.success) {

          if (this.userData.main_user) {
            this.mainUserDisabled = true;
          }

          if(parseInt(this.getCurrentUser().id) === parseInt(this.userData.prim_uid)) {
            this.$api.get_user();
          }
          this.$api.showToast(this.$t('settings.user_management.user_updated_info'))
          if (btn_key === 'saveList') {
            this.$router.push('/setting/usermanagement')
          } else if (btn_key === 'saveNew') {
            this.$router.push('/user/add')
          }
        } else {
          if (!response.data.message && response.data.error_key) {
            this.$api.showToast(this.$t(`general.errors.${response.data.error_key}`), 'error');
          }
          this.$api.showToast(response.data.message, 'error');
        }
      });
    },
    searchBranch(event) {
      if (!event.query.trim().length) {
        this.filteredBranches = this.branches
      } else {
        this.filteredBranches = this.branches.filter(branch => !this.userData.selected_branches.includes(branch) && branch.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1)
      }
    },
    branchSelected() {
      if (!this.userData.all_branch_access) {
        if(this.userData.selected_branches.length > 0) {
          let branch_uids = this.userData.selected_branches.map((o) => {
            return o.uid;
          });

          this.available_clients = this.clients.filter(o => branch_uids.includes(o.branch_id));
          this.userData.selected_clients = this.userData.selected_clients.filter(o => branch_uids.includes(o.branch_id));
          if (this.userData.selected_clients.length > 0) {
            this.available_clients = this.available_clients.filter(a => !this.userData.selected_clients.map(b => b.prim_uid).includes(a.prim_uid));
          }
        } else {
          this.available_clients = [];
        }
      } else {
        this.updateClientList();
      }
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'device.add':
          this.datevDeviceModalHeader = this.$t('settings.datev_devices.add');
          this.resetDatevDeviceModel();
          this.isDatevDeviceModalVisible = true;
          break;

        case 'device.edit':
          this.editDatevDevice(args.data[0])
          break;
      }
    },
    handleColClick(e) {
      if (e.field === 'name' && parseInt(e.row.id) > 0) {
        this.editDatevDevice(e.row)
      }
    },
    editDatevDevice(row) {
      this.datevDeviceModalHeader = this.$t('settings.datev_devices.edit');
      this.datevDevice.uid = row.id;
      this.datevDevice.name = row.name;
      this.datevDevice.password = '';
      this.isDatevDeviceModalVisible = true;
    },
    resetDatevDeviceModel() {
      this.datevDevice.uid = 0;
      this.datevDevice.name = '';
      this.datevDevice.password = '';
    },
    closeDatevDeviceDlg() {
      this.resetDatevDeviceModel();
      this.isDatevDeviceModalVisible = false;
    },
    saveDatevDevice() {
      let data = {...this.datevDevice};

      if (parseInt(this.$route.query.uid) > 0) {
        data.user_uid = this.$route.query.uid;
      }

      this.$api.trigger('datev/save_device', data, true).then((response) => {
        if (response.data.success) {
          if (data.uid === 0) {
            this.$api.showToast(this.$t('settings.datev_devices.success_info'));
          }
          this.triggerReload('grid-datev-devices');
          this.isDatevDeviceModalVisible = false;
        } else {
          this.$api.showToast(response.data.message, 'error');
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    //.icontable {
    //  visibility: visible;
    //}

  }

  //.icontable {
  //  visibility: collapse;
  //}
}

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.chip-container {

  border: 2px solid #7a7a7a;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    display: flex;
    align-items: center;


    height: 22px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;

    img {
      cursor: pointer;
      margin-left: 8px;
      font-size: 12px;
      font-style: none;

      width: 8px;
      height: 8px;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial', sans-serif;
      color: #333333;
      text-align: center;
      line-height: normal;
    }
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {


    @include segoe-regular;
    color: #7b7c7c;
    font-size: 12px;

  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

.info {
  height: 46px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}

.header {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

.two-factor {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

.link {
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: theme('colors.muted_black');
  text-align: right;
  font-size: 15px;
  cursor: pointer;
}

.client_dual_list::v-deep {
  table th:nth-child(2) {
    text-align: center;
  }

  table td:nth-child(2) img.icon {
    margin-left: auto;
    margin-right: auto;
  }
}

.sub-title {
  font-size: 13px;
  font-family: 'Segoe UI', sans-serif;
  font-style: italic;
  color: #aeaeae;
  text-align: left;
  line-height: 20px;
}
</style>
