<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.user_management.title')" :breadcrumbs="breadcrumbs"/>
    <ModalDialogTable v-show="isPasswordModalVisible" :headerText="$t('settings.user_management.reset_password')"
                      :canCloseOutsideClick="false"
                      :canEscape="true" @close="handlePasswordModalClose">
      <template v-slot:body>
        <div class="flex flex-col h-full justify-start space-y-4 ml-4 pr-4  mt-4 ">
          <div class="mt-5">
            <InputText type="password"
                       :showPasswordTemplate="true"
                       :showPasswordViewIcon="true"
                       :isRequired="true" :label="$t('login.password')"
                       :placeholder="$t('login.passwordPlaceholder')"
                       v-model="newPassword"
                       class="mt-5 mb-5"/>
            <InputCheck :text="$t('settings.user_management.force_password_change')"
                        :isChecked="forcePasswordChange"
                        @check="forcePwdClicked"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end space-x-4 mt-10 mr-6 pb-20">
          <Button class="w-40" :text="$t('buttons.cancel')" @click="handlePasswordModalClose" :secondary="true"/>
          <Button class="w-40" :text="$t('buttons.save')" @click="changePassword"/>
        </div>
      </template>
    </ModalDialogTable>

    <div class="mr-7">
      <AppGrid v-if="gridShow"
               :grid-id="gridId"
               data-endpoint="user/list"
               :columns="columns"
               default-sort-attr="name"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="id"
               :standard-filters="standardFilters"
               :menus="menus"
               @menu-clicked="handleUserMenuClick"
               @col-click="handleColClick"
               @action-menu-click="handleGridAction"
               @create-new="createNew"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {userColumns} from "@/core/constants";
import {mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import {UserService} from "../../services/user.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: "usermanagement",
  components: {AppGrid},
  data() {
    return {
      service: new UserService(),
      gridId: "users",
      standardFilters: [
        {
          selected: true,
          default: true,
          name: this.$t('settings.user_management.standard_filters.all_users'),
          id: 'all_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.active_users'),
          id: 'active_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.inactive_users'),
          id: 'inactive_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.invited_users'),
          id: 'invited_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.admin_users'),
          id: 'admin_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.it_admin_users'),
          id: 'it_admin_users'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.user_management.standard_filters.standard_users'),
          id: 'standard_users'
        }
      ],
      branchList: [],
      columns: [],
      selectedRecords: [],
      isPasswordModalVisible: false,
      forcePasswordChange: true,
      user_uid: 0,
      newPassword: '',
      breadcrumbs: [
        {
          title: this.$t('breadcrumbs.settings'),
          link: ''
        }, {
          title: this.$t('breadcrumbs.user_management'),
          link: ''
        }
      ],
      menus: [
        {
          id: "um.add_user",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('settings.user_management.menu.add')
        },
        {
          id: "um.edit_user",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('settings.user_management.menu.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        },
        {
          id: "um.delete_user",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('settings.user_management.menu.delete'),
          "action_endpoint": 'user/delete',
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true,
          "callback": () => {
            this.setCurrentUserStatsDataChanged(true);;
          }
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      gridShow: false,
    }
  },
  validations: {
    newPassword: {
      required
    },
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('user_management');
    this.initBranchList();
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations('grid', ["triggerReload"]),
    ...mapMutations('sub_user', ["init"]),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
    async initBranchList() {
      let branchService = new BranchService();
      await branchService.listMyBranches().then((list) => {
        this.branchList = list.map((o) => {
          return {
            "name": o.name,
            "code": o.uid
          }
        });
      });
      this.columns = userColumns.map((userColumn) => {
        if (userColumn.field === 'branch') {
          userColumn.filterOptions = this.branchList;
        }

        return userColumn;
      });

      this.gridShow = true;
    },
    handleUserMenuClick(args) {
      switch (args.menuId) {
        case 'um.add_user':
          this.$router.push('/user/add')
          break;

        case 'um.edit_user':
          if (args.data[0].id) {
            this.$router.push("/user/edit?uid=" + args.data[0].id)
          }
          break;

        default:
          this.$emit('click', args.menuId)
      }
    },
    handleColClick(e) {
      if ((e.field === 'userId' || e.field === 'name') && parseInt(e.row.id) > 0) {
        this.$router.push("/user/edit?uid=" + parseInt(e.row.id));
      }
    },
    handleGridAction(args) {
      switch (args.menu.id) {
        case 'um.ga.options.edit_user':
          if (args.row.id) {
            this.$router.push("/user/edit?uid=" + args.row.id)
          }
          break;
        case 'um.ga.change_password':
          this.newPassword = '';
          this.user_uid = args.row.id;
          this.forcePasswordChange = true;
          this.isPasswordModalVisible = true;
          break;
      }
    },
    handlePasswordModalClose() {
      this.isPasswordModalVisible = false
      this.user_uid = 0;
    },
    forcePwdClicked() {
      this.forcePasswordChange = !this.forcePasswordChange;
    },
    changePassword() {
      if (this.newPassword !== '') {
        this.service.updatePassword({
          uid: this.user_uid,
          password: this.$api.encrypt(this.newPassword),
          forcePwdChange: this.forcePasswordChange
        }).then((res) => {
          if(res) {
            this.$api.showToast(this.$t('settings.user_management.password_change_success'))
            this.handlePasswordModalClose();
          }
        })
      }
    },
    createNew() {
      this.$router.push('/user/add');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

</style>
