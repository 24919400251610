<template>
  <div class="h-full bg-white pl-7 w-full">
    <PageHeader
      :title="$t('components.client_documents_download.title')"
      :breadcrumbs="breadcrumbs"
    />

    <div
      v-if="this.clientUid !== null"
      class="text-left"
    >
      <div class="w-full text-left flex mt-5">
        <strong class="mr-2">{{ $t('components.client_documents_download.client') }}:</strong> {{ clientName }}
      </div>

      <div
        v-if="this.expiresAt !== null"
        class="w-full text-left flex mt-5"
      >
        <strong class="mr-2">{{ $t('components.client_documents_download.available_till') }}:</strong> {{ downloadableTill }}
      </div>

      <div
        v-if="this.status === 'FINISHED'"
        class="w-full text-left flex mt-5"
      >
        <Button
          class="w-80"
          @click="download"
          :text="$t('components.client_documents_download.download')"
        />
      </div>

      <div
        v-if="this.status === 'PENDING' || this.status === 'STARTED'"
        class="w-full text-left flex mt-5"
      >
        {{ $t('components.client_documents_download.downloadPending') }}
      </div>

      <div
        v-if="this.status === 'EXPIRED'"
        class="w-full text-left flex mt-5"
      >
        {{ $t('components.client_documents_download.downloadExpired') }}
      </div>

      <div
        v-if="this.status === 'FAILED'"
        class="w-full text-left flex mt-5"
      >
        {{ $t('components.client_documents_download.downloadFailed') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DownloadDocuments',
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('components.clients'),
          link: '/client/list'
        }
      ],
      clientName: null,
      clientUid: null,
      downloadJobsId: null,
      status: '',
      expiresAt: null,
      downloadableTill: '',
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    fetchNotification() {
      this.$api.trigger('client/downloadDetails', {id: this.$route.query.uid}, true)
        .then((response) => {
          if (response.data.success) {
            this.clientName = response.data.details.client_name;
            this.clientUid = response.data.details.client_uid;
            this.downloadJobsId = response.data.details.download_jobs_id;
            this.status = response.data.details.status;
            this.expiresAt = response.data.details.expires_at;
            this.downloadableTill = moment(response.data.details.expires_at * 1000).format('DD.MM.YYYY, HH:mm');
          } else {
            if (response.data.errorKey) {
              this.$api.showToast(this.$t('clients.confirmDelete.permissionDenied'), 'error')
            } else {
              this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
            }
          }
        });
    },
    download() {
      this.$api.trigger('client/download', {uid: this.clientUid}, true, '', 'blob', true)
        .then((response) => {
          if (response.headers['downloadexpired']) {
            this.$api.showToast(this.$t('clients.confirmDelete.errorExpired'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else if (response.headers['downloadpending']) {
            this.$api.showToast(this.$t('clients.confirmDelete.errorAlreadyQueued'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else if (response.headers['permissiondenied']) {
            this.$api.showToast(this.$t('clients.confirmDelete.permissionDenied'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else {
            this.$api.trigger_download(response);
          }
        });
    }
  }
}
</script>
