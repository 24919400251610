<template>
  <div class="">
    <div class="w-full mt-8 mr-8 space-y-4">
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('settings.account.fields.salutation')"
                     v-model="step1.salutation"
                     :selected-value="step1.salutation"
                     :options="salutations"
                     :isRequired="true"
                     :is-error="isSalutationError"
                     :error-message="$t('general.errors.required')"
                     placeholder=""/>
        <InputText class="w-full" :label="$t('settings.user_management.attrs.name')" :isError="isNameError"
                   :errorMessage="nameError" v-model="step1.name" :isRequired="true"
                   :placeholder="$t('settings.user_management.attrs.name')"/>
      </div>
      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('settings.user_management.attrs.email')" :isError="isEmailError"
                   :errorMessage="emailError" v-model="step1.email" :isRequired="true" :isEmail="true"
                   :placeholder="$t('settings.user_management.attrs.email')"/>
        <InputSelect class="w-full" :label="$t('settings.user_management.attrs.role')" :options="roleOptions"
                     :isRequired="true"
                     v-model="step1.role"
                     :selected-value="step1.role"
                     :is-error="isRoleError"
                     :error-message="roleError"
                     :showHelpIconPopover="true"
                     :popoverTexts="popoverTexts_role"
                     :popoverLink="popoverLink_role"
                     :placeholder="$t('settings.user_management.attrs.select_role')"/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputText class="w-full"
                   :label="$t('settings.user_management.attrs.phone')"
                   :maxLength="16"
                   v-model="step1.phone"/>
        <InputText class="w-full" :label="$t('settings.user_management.attrs.agent_code')" v-model="step1.agent_code"
                   :maxLength="16"
                   :showHelpIconPopover="true"
                   :popoverTexts="popoverTexts_agent_code"/>
      </div>
      <div class="flex flex-col justify-start items-start mt-4" v-show="showMfa">
        <div class="flex mb-2">
          <p class="two-factor self-start">{{ $t('settings.account.fields.twofactor') }}</p>

          <VDropdown>
            <!-- This will be the popover target (for the events and position) -->
            <img class="help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
            <!-- This will be the content of the popover -->
            <template #popper>
              <div class="tooltip-content">
                <div class="space-y-4">
                  <p class=" popover-text" v-for="(text, index) in [$t('settings.user_management.tooltip.step1.2fa')]"
                     v-html="text"
                     :key="index">
                  </p>
                </div>

              </div>
              <!-- You can put other components too -->

            </template>
          </VDropdown>
        </div>
        <label class="switch">
          <input type="checkbox" v-model="step1.two_fa_enabled" :disabled="forceMfa"
                 true-value="1"
                 false-value="0">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="flex justify-end  mt-6 space-x-4 ">
      <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="cancel"/>
      <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>
</template>

<script>
import Tooltip from "primevue/tooltip";

import {mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import InputSelect from "../../inputs/InputSelect";
import {salutations, userRoles, userStatus} from "../../../core/constants";

export default {
  name: "Step1",
  components: {InputSelect},

  directives: {
    'tooltip': Tooltip
  },
  data() {
    return {
      popoverTexts_agent_code: [this.$t('settings.user_management.tooltip.step1.agent')],
      popoverTexts_role: [
        this.$t('settings.user_management.tooltip.step1.role_text_1'),
        this.$t('settings.user_management.tooltip.step1.role_text_2'),
        this.$t('settings.user_management.tooltip.step1.role_text_3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverLink_role: "https://hilfe.grundsteuer-digital.de/faq/benutzerverwaltung-auf-der-grundsteuerdigital-plattform-fuer-unternehmen/",
      roleOptions: userRoles,
      statusOptions: userStatus,
      salutations: salutations,
      step1: {
        salutation: this.$store.state.sub_user.userData.salutation,
        name: this.$store.state.sub_user.userData.name,
        email: this.$store.state.sub_user.userData.email,
        phone: this.$store.state.sub_user.userData.phone,
        role: this.$store.state.sub_user.userData.role,
        status: 'active',
        agent_code: this.$store.state.sub_user.userData.agent_code,
        two_fa_enabled: this.$store.state.sub_user.userData.two_fa
      },
      isSalutationError: false,
      isEmailError: false,
      emailError: '',
      isNameError: false,
      nameError: '',
      isRoleError: false,
      roleError: '',
      isStatusError: false,
      statusError: '',
    }
  },
  validations: {
    step1: {
      salutation: {
        required
      },
      name: {
        required
      },
      email: {
        required, email
      },
      role: {
        required
      },
      status: {
        required
      },
    },
  },
  computed: {
    showMfa() {
      return this.getCurrentUser().settings.tax_office_software !== 'datev';
    },
    forceMfa() {
      return this.getCurrentUser().settings.force_2fa === 1;
    }
  },
  mounted() {
    if(typeof(this.step1.status) === 'undefined' || this.step1.status === '') {
      this.step1.status = 'active';
    }
    if (this.forceMfa) {
      this.step1.two_fa_enabled = 1;
    }
  },
  methods: {
    ...mapMutations("sub_user", ["setUserData"]),
    ...mapGetters("user", ["getCurrentUser"]),
    submitNext() {
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isSalutationError = this.$v.step1.salutation.$error
        this.isNameError = this.$v.step1.name.$error
        this.nameError = this.$v.step1.name.$error ? this.$t('register.step1.nameError') : ""
        this.isEmailError = this.$v.step1.email.$error
        this.emailError = this.$v.step1.email.$error ? this.$t('register.step1.emailError') : ""
        this.isRoleError = this.$v.step1.role.$error
        this.roleError = this.$v.step1.role.$error ? this.$t('settings.user_management.errors.required_role') : ""
        this.isStatusError = this.$v.step1.status.$error
        this.statusError = this.$v.step1.status.$error ? this.$t('settings.user_management.errors.required_status') : ""

        return; // if any of the validations fail, we don't move on
      }

      this.setUserData(this.step1)
      this.$emit("goToNextStep", this.step1);
    },
    cancel() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">


.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

.two-factor {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

</style>
