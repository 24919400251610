<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img src="@/assets/check_done.svg"/>
    </div>
    <label class="label__text ">{{ showFinishLabel }}</label>
    <Button @click="submit" class=" mt-10 w-1/2"
            :text="$t('register.finishNext')"></Button>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Finish",
  computed: {
    showFinishLabel() {
      if (!this.isBusiness()) {
        if(this.$store.state.register.userData.tools === 'datev-arbeitsplatz' || this.$store.state.register.userData.tools === 'datev-dms') {
          return this.$t('register.finish_datev')
        } else {
          return this.$t('register.finish')
        }
      } else {
        return this.$t('register.finish')
      }
    }
  },
  methods: {
    ...mapMutations("register", ["setCurrentStep"]),
    submit() {
      this.setCurrentStep(1);
      location.href = '/';
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}
</style>
