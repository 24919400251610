const state = {
    list: []
};
const getters = {
    getCountries: (state) => state.list
}

const mutations = {
    setCountries: (state, countries) => {
        state.list = countries;
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



