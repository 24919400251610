import {ApiService} from "./api.service";

export class GridService extends ApiService {
  
  constructor(grid_id) {
    super();
    this.grid_id = grid_id;
  }
  
  saveFilter(filter) {
    let data = {};
    data.opt = 'saveFilter';
    data.grid_id = this.grid_id;
    data.filter = filter;
    return this.trigger('grid_service', data, true)
  }
  
  getFilter(filter_uid) {
    let data = {};
    data.opt = 'getFilter';
    data.grid_id = this.grid_id;
    data.filter_uid = filter_uid
    return this.trigger('grid_service', data)
  }
  
  getFilters() {
    let data = {};
    data.opt = 'getFilters';
    data.grid_id = this.grid_id;
    return this.trigger('grid_service', data)
  }
  
  deleteFilter(filter_uid) {
    let data = {};
    data.opt = 'deleteFilter';
    data.grid_id = this.grid_id;
    data.filter_uid = filter_uid
    return this.trigger('grid_service', data)
  }
  
  getState(gridId, show_loader) {
    if(typeof show_loader === 'undefined') {
      show_loader = true;
    }
    let data = {};
    data.opt = 'getState';
    data.grid_id = gridId;
    return this.trigger('grid_service', data, show_loader);
  }

  saveState(gridId, grid_state) {
    let data = {};
    data.opt = 'saveState';
    data.grid_id = gridId;
    data.grid_state = grid_state;
    return this.trigger('grid_service', data, true);
  }
}
