import {ApiService} from "./api.service";
import axios from 'axios'
import md5 from "md5";

export class S3Service extends ApiService {

  upload(params) {
    let s3Filename = Date.now() + '_' + params.fileName;
    let contentType = params.contentType ? params.contentType : "application/json";

    return this.trigger('user/get_upload_url', {
      "filename": s3Filename,
      'prefix': params.prefix ? params.prefix : '',
      "contentType": contentType
    }).then((response) => {
      if(response.data.success && response.data.url) {
        let contentHash = contentType === 'application/json' ? md5(JSON.stringify(params.data)) : '';
        return axios.put(response.data.url, params.data, {
          headers: {
            'Content-Type': contentType
          }
        }).then((res) => {
          let uploadedHash = res.headers.etag.replace(/"/g, "");
          return {
            success: uploadedHash === contentHash || contentHash === '',
            s3Path: s3Filename
          };
        })
      } else {
        this.showToast(response.data.message, "error");
        return {
          "success": false,
          "s3Path": ""
        };
      }
    });
  }
}
