import {ApiService} from "./api.service";

export class SearchService extends ApiService {

  constructor() {
    super();
  }

  searchDocuments(txtFilter, rows, showLoader) {
    this.$store.commit("search/setSearchResult", {
      documents: []
    });
    return this.trigger('document/list', {
      search: 1,
      filters: {
        txt_filter: txtFilter
      },
      first: 0,
      rows: 25
    }, showLoader).then((response) => {
      if (response.data.total_records > 0) {
        let documents = this.parse_object_data(response.data.records);
        this.$store.commit("search/setSearchResult", {
          documents: documents
        });

        if (documents.length > rows) {
          return documents.slice(0, rows);
        } else {
          return documents;
        }
      } else {
        return [];
      }
    })
  }

  searchClient(txtFilter, rows, showLoader) {
    this.$store.commit("search/setSearchResult", {
      clients: []
    });
    return this.trigger('client/list', {
      filters: {
        txt_filter: txtFilter
      },
      first: 0,
      rows: 25
    }, showLoader).then((response) => {
      if (response.data.total_records > 0) {
        let clients = this.parse_object_data(response.data.records);
        this.$store.commit("search/setSearchResult", {
          clients: clients
        });

        if (clients.length > rows) {
          return clients.slice(0, rows);
        } else {
          return clients;
        }
      } else {
        return [];
      }
    })
  }

  searchProperties(txtFilter, rows, showLoader) {
    this.$store.commit("search/setSearchResult", {
      properties: []
    });
    return this.trigger('property/list', {
      filters: {
        txt_filter: txtFilter,
        always: 'join_last_declaration'
      },
      first: 0,
      rows: 25
    }, showLoader).then((response) => {
      if (response.data.total_records > 0) {
        let properties = this.parse_object_data(response.data.records);
        this.$store.commit('search/setSearchResult', {
          properties: properties
        });

        if (properties.length > rows) {
          return properties.slice(0, rows);
        } else {
          return properties;
        }
      } else {
        return [];
      }
    })
  }

  searchDeclarations(txtFilter, rows, showLoader) {
    this.$store.commit("search/setSearchResult", {
      declarations: []
    });
    return this.trigger('declaration/list', {
      filters: {
        txt_filter: txtFilter
      },
      first: 0,
      rows: 25
    }, showLoader).then((response) => {
      if (response.data.total_records > 0) {
        let declarations = this.parse_object_data(response.data.records);
        this.$store.commit("search/setSearchResult", {
          declarations: declarations
        });

        if (declarations.length > rows) {
          return declarations.slice(0, rows);
        } else {
          return declarations;
        }
      } else {
        return [];
      }
    })
  }
}
