<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img :src="getAssetPath('check_done.svg')"/>
    </div>
    <label class="label__text ">{{ $t('settings.branches.branch_added') }}</label>
    <Button @click="submit" class="mt-10 "
            :text="$t('settings.branches.all_branches')"></Button>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Finish",
  methods: {
    ...mapMutations("branch", ["setCurrentStep"]),
    submit() {

      this.setCurrentStep(1)
      this.$emit("finishButtonClick", "finish")

    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}
</style>
