<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component mt-4"> {{ $t('billing_subscribe.ticketing') }}</p>

    <div class="w-full pr-7 mt-2">
      <div class="flex justify-around space-x-10 mr-6">
        <div class="rectangle w-full ">
          <div class="  ">
            <div class="flex  justify-between items-center h-14 ml-5">
              <label class="text-left text w-full"> {{ $t('billing_subscribe.chosen_package') }}:
                {{ this.form.planName }} <span @click="$router.go(-1)"
                                               class="text-color billing-text pointer">({{ $t('billing_subscribe.to_change') }})</span></label>

            </div>

            <hr class=" line"/>
            <div class="top_dec">
              <div class="mt-3 h-full col_3">
                <div class="floating text-left mr-8 ml-5 space-y-3">
                  <p class="text">{{ $t('billing_subscribe.base_price') }}:</p>
                  <p v-if="!$isBusiness" class="text">{{ $t('billing_subscribe.price_per_operation') }}:
                  </p>
                  <p class="text">{{ $t('billing_subscribe.contract_term') }}:

                  </p>

                </div>
                <div class="floating text-left mr-8 ml-5 space-y-3">
                  <p class="text-2">{{ this.form.package_price }} €</p>
                  <p v-if="!$isBusiness" class="text-2">{{ this.form.elster_price }} €</p>
                  <p class="text-2" v-if="!$isBusiness">{{ this.form.contract_period }} {{ $t('billing_trial.months') }}
                    ({{ $t('settings.billing.bis') }} {{ this.end_date }}) </p>
                  <p class="text-2" v-else>12 {{ $t('billing_trial.months') }}</p>
                </div>
              </div>

              <div class="msg_9">
                <div class="box flex justify-start items-center mr-4">
                  <img class="image ml-2" src="@/assets/info_gray.svg"/>
                  <div class="text-2 ml-2">{{ $t('billing_subscribe.note') }}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="flex justify-around space-x-10 mr-6">
        <div class="rectangle w-full  ">
          <div class="w-full">
            <div class="form_detail">
              <div class="  ">
                <div class="flex  justify-between items-center h-14 ml-5">
                  <div class=" head w-full">
                    {{ $t('billing_subscribe.billing_address') }}

                  </div>
                </div>


              </div>
              <hr class=" line  mr-7 ml-4   "/>
              <div class="form_grp">

                <div class="col_4">
                  <InputText class="w-full" type="text" :label="$t('billing_subscribe.company')" v-model='form.company'
                             :isRequired="true"
                             :placeholder="$t('billing_subscribe.company')"/>
                  <span v-if="form.errors().has('company')" v-text="form.errors().get('company')" class="err"></span>
                </div>
              </div>
              <div class="form_grp">
                <div class="col_4">
                  <InputSelect v-model="form.salutation" :options="Anrede" :label="$t('billing_subscribe.salutation')" :isRequired="true"
                               :selected-value="form.salutation"></InputSelect>
                  <span v-if="form.errors().has('salutation')" v-text="form.errors().get('salutation')"
                        class="err"></span>
                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model='form.first_name' type="text"
                             :label="$t('billing_subscribe.first_name')" :isRequired="true"
                             :placeholder="$t('billing_subscribe.first_name')"/>
                  <span v-if="form.errors().has('first_name')" v-text="form.errors().get('first_name')"
                        class="err"></span>
                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model='form.last_name' type="text"
                             :label="$t('billing_subscribe.last_name')" :isRequired="true"
                             :placeholder="$t('billing_subscribe.last_name')"/>
                  <span v-if="form.errors().has('last_name')" v-text="form.errors().get('last_name')"
                        class="err"></span>
                </div>
              </div>

              <div class="form_grp">
                <div class="col_4">
                  <InputText class="w-full" v-model='form.street' type="text" :label="$t('billing_subscribe.street')"
                             :placeholder="$t('billing_subscribe.street')"/>
                  <span v-if="form.errors().has('street')" v-text="form.errors().get('street')" class="err"></span>

                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model='form.house_number' type="text"
                             :label="$t('billing_subscribe.house_number')"/>
                  <span v-if="form.errors().has('house_number')" v-text="form.errors().get('house_number')"
                        class="err"></span>

                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model='form.post_office' type="text"
                             :label="$t('billing_subscribe.post_office')"
                             placeholder=""/>
                  <span v-if="form.errors().has('post_office')" v-text="form.errors().get('post_office')"
                        class="err"></span>

                </div>
              </div>

              <div class="form_grp">
                <div class="col_4">

                  <InputText class=" w-full"
                             :isRequired="true"
                             :label="$t('billing_subscribe.zip')"
                             v-model="form.zip"
                             @blur="searchZip"
                             :isZip="true"
                             placeholder=""/>
                  <span v-if="form.errors().has('zip')" v-text="form.errors().get('zip')" class="err"></span>

                </div>
                <div class="col_4">
                  <InputSelect
                      class="w-full"
                      :isRequired="true"
                      :label="$t('billing_subscribe.city')"
                      v-model="form.city"
                      :options="cityList"
                      :selected-value="form.city"
                      :loading="zipSearch"
                      :filterable="true"
                      :auto-complete="true"
                      :placeholder="$t('billing_subscribe.city')"/>
                  <span v-if="form.errors().has('city')" v-text="form.errors().get('city')" class="err"></span>
                </div>
                <div class="col_4">
                  <InputSelect v-model='form.country' :selected-value="form.country" :options="countries"
                               :label="$t('billing_subscribe.country')"
                               :filterable="true"
                               :isRequired="true"
                               :change="onChangeCountry()"
                               placeholder=""/>
                  <span v-if="form.errors().has('country')" v-text="form.errors().get('country')" class="err"></span>
                </div>
              </div>

              <div class="form_grp" v-if="form.country != 50">
                <div class="col_4">

                  <InputText class="w-full" type="text" v-model='form.vat_registration_id'
                             :label="$t('billing_subscribe.vat_registration_id')"
                             :isRequired="true"
                  />
                  <span v-if="form.errors().has('vat_registration_id')"
                        v-text="form.errors().get('vat_registration_id')" class="err"></span>

                </div>
              </div>

              <div class="form_grp">
                <div class="col_12">
                  <div class="devider head">{{ $t('billing_subscribe.payment') }}</div>
                </div>
              </div>
              <div class="form_grp">
                <div class="col_12">
                  <label class="zah">{{ $t('billing_subscribe.payment_method') }}<span
                      style="color:red">*</span></label>
                </div>
                <div class="col_12 ">
                  <div class="flex space-x-3  ">
                    <div class="flex justify-start items-center">
                      <RadioButton type="radio" v-model="form.debit_charge" name="Zahlungsart" value="direct_debit"
                                   checked/>
                      <span class="ml-2 textradio"> {{ $t('billing_subscribe.direct_debit') }}</span>
                    </div>
                    <div class="flex justify-start items-center ">
                      <RadioButton type="radio" name="Zahlungsart" value="bank_transfer" v-model="form.debit_charge"/>
                      <span class="ml-2 textradio"> {{ $t('billing_subscribe.bank_transfer') }}</span>
                    </div>

                  </div>
                </div>
              </div>

              <div v-if="form.debit_charge == 'direct_debit'" class="form_grp bank-account">
                <div class="col_4">
                  <InputText class="w-full" v-model="form.account_owner" type="text"
                             :label="$t('billing_subscribe.account_owner')"
                             :isRequired="true" :placeholder="$t('billing_subscribe.account_owner_name')" @blur="handleBlurAction"/>
                  <span v-if="accountOwner" v-text="$t('billing_subscribe.errors.acc_owner_required')"
                        class="err"></span>
                  <span v-if="form.errors().has('account_owner')" v-text="form.errors().get('account_owner') "
                        class="err"></span>
                  <span v-if="account_ownererror"
                        class="err">{{ $t('billing_subscribe.errors.acc_owner_required') }}</span>
                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model="form.iban" type="text" label="IBAN" :isRequired="true" @blur="handleBlurAction"/>
                  <span v-if="blurActionIban" v-text="$t('billing_subscribe.errors.ibanrequired')" class="err"></span>
                  <span v-if="form.errors().has('iban')" v-text="form.errors().get('iban')" class="err"></span>
                  <span v-if="ibanerror" class="err">{{ $t('billing_subscribe.errors.ibanerror') }}</span>
                  <span v-if="iban_empty_error" class="err">{{ $t('billing_subscribe.errors.ibanrequired') }} </span>
                  <span v-if="iban_bic_mismatch_error"
                        class="err">{{ $t('billing_subscribe.errors.ibanbicmismatcherror') }}</span>
                </div>
                <div class="col_4">
                  <InputText class="w-full" v-model="form.bic" type="text" label="BIC" :isRequired="true" @blur="handleBlurAction"/>
                  <span v-if="blurActionBic" v-text=" $t('billing_subscribe.errors.bic_required')" class="err"></span>
                  <span v-if="form.errors().has('bic')" v-text="form.errors().get('bic')" class="err"></span>
                  <span v-if="bic_val_error" class="err">{{ $t('billing_subscribe.errors.bic_val_error') }}</span>
                  <span v-if="bicerror" class="err">{{ $t('billing_subscribe.errors.bic_required') }}</span>
                  <span v-if="iban_bic_mismatch_error"
                        class="err">{{ $t('billing_subscribe.errors.ibanbicmismatcherror') }}</span>
                </div>
              </div>

              <div class="form_grp">
                <div class="col_12 check_cond">
                  <Checkbox v-model="form.has_read" :binary="true" class="mt-4"></checkbox>
                  <p class="inputcheck-text">{{ $t('billing_subscribe.InputCheck1') }} <a
                      class="text-color billing-text text-underline" href="https://grundsteuer-digital.de/agb/"
                      target="_blank">AGB</a> {{ $t('billing_subscribe.InputCheck2') }} </p>
                  <span v-if="form.errors().has('has_read')" v-text="form.errors().get('has_read')" class="err"></span>
                  <span v-if="has_readerror" class="err">{{ $t('billing_subscribe.errors.has_read_required') }}</span>

                </div>
                <div class="col_6  flex flex-col w-2/5  ml-10 ">
                  <InputText :label="$t('billing_subscribe.partner_key')" v-model="form.partner_key"/>
                </div>
              </div>


            </div>
          </div>
          <div>

            <div class="form_grp">
              <div class=" flex justify-end col_12 form_btnd">
                <Button :text="$t('billing_trial.book_free')" class="plan_button" @click='submit'></Button>
                <Button :text="$t('buttons.cancel')" :secondary="true" @click="$router.go(-1)"></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import form from 'vuejs-form'
import InputSelect from "../inputs/InputSelect";
import {salutations} from "@/core/constants";
import {BillingService} from "../../services/billing.service";
import {mapGetters} from "vuex";
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';

export default {
  name: "BillingSubscribe",
  components: {InputSelect, Checkbox, RadioButton},
  data: function () {
    return {
      blurActionBic:false,
      blurActionIban:false,
      accountOwner:false,
      breadcrumbs: [{
        title: 'Einstellungen',
        link: ''
      },
        {
          title: 'Abrechnung',
          link: ''
        },
        {
          title: 'Buchung',
          link: ''
        },
      ],
      cityList: [],
      zipSearch: false,
      Anrede: salutations,
      ibanerror: false,
      iban_empty_error: false,
      account_ownererror: false,
      has_readerror: false,
      bicerror: false,
      bic_val_error: false,
      iban_bic_mismatch_error: false,
      end_date: '',
      countries: [{code: '50', country_code: 'DE', name: 'Deutschland',}],
      has_read: false,
      form: form({
        plan_id: '',
        company: '',
        salutation: '',
        first_name: '',
        last_name: '',
        street: '',
        house_number: '',
        post_office: '',
        zip: '',
        city: '',
        country: '',
        country_code: '',
        vat_registration_id: '',
        debit_charge: 'direct_debit',
        direct_debit_type: 'core',
        account_owner: '',
        iban: '',
        contact_id: '',
        bic: '',
        planName: "",
        package_price: "",
        elster_price: "",
        contract_period: "",
        partner_key: "",
        has_read: false
      })
          .rules({
            company: 'required',
            salutation: 'required',
            first_name: 'required',
            last_name: 'required',
            zip: 'required',
            city: 'required',
            country: 'required',
            debit_charge: 'required',
            has_read: 'required'
          })
          .messages({
            'company.required': this.$t('billing_subscribe.errors.company_required'),
            'salutation.required': this.$t('billing_subscribe.errors.salutation_required'),
            'first_name.required': this.$t('billing_subscribe.errors.first_name_required'),
            'last_name.required': this.$t('billing_subscribe.errors.last_name_required'),
            'zip.required': this.$t('billing_subscribe.errors.zip_required'),
            'city.required': this.$t('billing_subscribe.errors.city_required'),
            'country.required': this.$t('billing_subscribe.errors.country_required'),
            'vat_registration_id.required': this.$t('billing_subscribe.errors.vat_registration_id_required')
          }),
      plan_id: '',
    }
  },
  watch: {
    ['form.data']: {
      deep: true,
      immediate: false,
      handler: 'onFormChange'
    }
  },
  created() {
    this.plan_id = this.$store.state.billing.planId
    this.form.plan_id = this.plan_id
    this.getPlanDetail(this.plan_id);
  },
  mounted() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
    });
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    downloadPdf() {

      const service = new BillingService()
      service.generatePdf({"plan_uid": this.getCurrentUser().plan_uid, "iban": this.form.iban, "bic": this.form.bic})

    },
    handleBlurAction(){
      this.form.bic.length===0 ? this.blurActionBic = true:this.blurActionBic = false
      this.form.iban.length===0 ? this.blurActionIban = true:this.blurActionIban = false
      this.form.account_owner.length===0 ? this.accountOwner = true:this.accountOwner = false
    },
    onFormChange() {
    },
    getPlanDetail(planId) {
      const service = new BillingService()
      service.selectedPlanDetail(planId).then((response) => {
        if (response.data.success) {
          this.form.planName = response.data.data[0].plan_name;
          this.form.package_price = response.data.data[0].plan_price;
          this.form.elster_price = response.data.data[0].elster_price;
          this.form.contract_period = response.data.data[0].contract_period;

          this.end_date = response.data.data[0].end_date;
        } else {
          this.$router.push('/setting/billing');
        }
      });

      service.getContactDetail().then((response) => {
        if (
          response.success
          && (
            response.response.billing_company != ''
            || response.response.billing_first_name != ''
            || response.response.billing_last_name != ''
          )
        ) {
          response = response.response;

          this.form.company = response.billing_company;
          this.form.email = response.billing_email;
          this.form.salutation = response.billing_salutation;
          this.form.first_name = response.billing_first_name;
          this.form.last_name = response.billing_last_name;
          this.form.street = response.billing_street;
          this.form.house_number = response.billing_house_number;
          this.form.zip = response.billing_zip;
          this.form.city = response.billing_city;
          this.form.country_code = response.billing_country_code;
          this.form.country = response.billing_country_uid;
          this.country_uid = response.billing_country_uid;
          this.country_name = response.billing_country_name;

          if (typeof response.vat_registration_id === 'undefined') {
            if (typeof response.tax_number === 'undefined') {
              this.form.vat_registration_id = '';
            } else {
              this.form.vat_registration_id = response.tax_number;
            }
          } else {
            this.form.vat_registration_id = response.vat_registration_id;
          }

          this.form.account_owner = response.bank_account_owner;
          this.form.iban = response.iban;
          this.form.bic = response.bic;
        }
      })

      if (this.form.company == '') {
        this.form.company = this.form.first_name + ' ' + this.form.last_name;
      }
    },
    submit() {
      if (this.form.country != 50) {
        let Validator = this.form.validator();
        Validator.addRule('vat_registration_id', 'required');
      }

      this.form.validate()
      if (this.form.validate().errors().any()) {
        return false;
      }

      if (!this.form.has_read) {
        this.has_readerror = true;
        return false;
      } else {
        this.has_readerror = false;
      }

      if (this.form.debit_charge == 'direct_debit') {
        if (!this.form.account_owner) {
          this.account_ownererror = true;
        } else {
          this.account_ownererror = false;
        }

        if (!this.form.bic) {
          this.bicerror = true;
        } else {
          this.bicerror = false;
        }

        if (this.form.iban && this.form.bic) {
          const billing = new BillingService();
          billing.checkIban(this.form.iban, this.form.bic).then((response) => {
            if (response.data.success) {
              this.iban_empty_error = false;
              this.ibanerror = false;
              this.bic_val_error = false;
              this.iban_bic_mismatch_error = false;

              if (!(this.account_ownererror) && !(this.bicerror)) {
                this.saveData();
              }
            } else {
              if (response.data.error_key) {
                if (response.data.error_key == 'iban_error') {
                  this.$toast.error(this.$t('billing_subscribe.errors.ibanerror'));
                } else if (response.data.error_key == 'bic_error') {
                  this.$toast.error(this.$t('billing_subscribe.errors.bicerror'));
                } else if (response.data.error_key == 'iban_bic_mismatch') {
                  this.$toast.error(this.$t('billing_subscribe.errors.ibanbicmismatcherror'));
                }
              }

              if (response.data.error_key == 'bic_error') {
                this.bic_val_error = true;
                this.ibanerror = false;
                this.bicerror = false;
                this.iban_bic_mismatch_error = false;
                return false;
              }

              if (response.data.error_key == 'iban_error') {
                this.ibanerror = true;
                this.iban_empty_error = false;
                this.bic_val_error = false;
                this.iban_bic_mismatch_error = false;
                return false;
              }

              if (response.data.error_key == 'iban_bic_mismatch') {
                this.ibanerror = false;
                this.iban_empty_error = false;
                this.bic_val_error = false;
                this.iban_bic_mismatch_error = true;
                return false;
              }
            }
          })
        } else if (this.form.iban && !this.form.bic) {
          this.bicerror = true;
          this.iban_empty_error = false;
          this.bic_val_error = false;
          this.ibanerror = false;
          this.iban_bic_mismatch_error = false;
        } else {
          this.iban_empty_error = true;
          this.ibanerror = false;
          this.bic_val_error = false;
          this.iban_bic_mismatch_error = false;
        }
      } else {
        this.saveData();
      }
    },
    saveData() {
      const billing = new BillingService();
      if (this.form.partner_key) {
        billing.checkPartnerKey(this.form.partner_key).then((response) => {
          if (response.data.success) {
            this.savePlan();
          }
        });
      } else {
        this.savePlan();
      }
    },
    savePlan() {
      const billing = new BillingService();
      if (typeof this.form.data.vat_registration_id !== 'undefined') {
        this.form.data.vat_registration_id = this.form.data.vat_registration_id.replace(/\s/g, '');
      }

      billing.save(this.form.data).then((response) => {
        if (response.data.success) {
          this.$api.get_user().then(() => {
            this.$router.push('/setting/billing?billingSuccess=1');
          });
        }
      });
    },
    onChangeCountry() {
      if (this.form.country !== undefined && this.form.country !== '') {
        if (typeof this.countries.find(item => item.code == this.form.country) !== 'undefined') {
          this.form.country_code = this.countries.find(item => item.code == this.form.country).country_code;
        }
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.form.city === '' && this.form.zip !== '') {
        this.cityList = [];
        this.cityLookup(this.form.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.form.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

span.sc_icr {
  display: flex;
  justify-content: center;
}

span.sc_icr img {
  width: 100px;
  height: 100px;
}

.sucss_msg {
  margin-top: 50px;
}

.sucss_msg label {
  font-size: 20px;
  font-weight: 600 !important;
  color: theme('colors.muted_black');
  margin: 20px auto;
  display: block;
}

.sucss_msg p {
  font-size: 15px;
  display: block;
  margin: 0 0 30px 0;
}

.sucss_msg a {
  background: #229d56;
  color: #fff !important;
  font-size: 15px;
  padding: 8px;
  max-width: 230px;
  width: 100% !important;
  display: inline-block;
}


.check_cond span {
  font-size: 15px;
}

.check_cond span a {
  color: rgba(34, 157, 86, 1);
  font-weight: 600;
  text-decoration: underline;
}

.msg_9 {
  margin-left: -50px;
}

.top_dec {
  display: flex;
  justify-content: space-between;
}


.col_3 {
  max-width: 460px;
  width: 100%;
}

.devider {
  display: block;
  font-size: 15px;
  padding: 0 0 20px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
}

.form_detail {
  padding: 20px 0;
}

.radio_inline {
  display: inline-block;
}

.radio_hold {
  display: flex;
  justify-content: flex-start;
}

.form_grp {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  flex: 100%;
}

.form_grp .col_12, .form_grp .col_4 {
  position: relative;
  padding-bottom: 20px !important;
  text-align: left;
}

.inf {
  display: flex;
  align-items: center;

  margin: 30px 10px 0 0;
  font-size: 12px;
  width: 890px;
  height: 46px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.inf > div {
  width: 60px;
}

span.err {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  text-align: left;
  width: 100%;
  display: block;
  color: red;
  font-family: "Segoe UI", sans-serif;
}

label.rad_label {
  font-size: 15px;
  margin-bottom: 15px;
}

.col_4 {
  max-width: 33.33%;
  width: 100%;
  padding: 0 15px;
}

.col_12.form_btnd > button:first-child {
  background: rgba(34, 157, 86, 1);
  color: #fff;
  font-size: 15px;
  display: inline-block;
  padding: 10px;
  margin: 0 20px;
  width: 240px;
}

.col_12.form_btnd > button:nth-child(2) {
  background: rgba(204, 204, 204, 1);
  color: theme('colors.muted_black');
  font-size: 15px;
  width: 240px;
  display: inline-block;
  padding: 10px;
  margin: 0 20px;
}

.col_12 {
  width: 100%;
  padding: 0 15px;
}

.form_grp input, .form_grp select {
  font-size: 15px;
}

.input-box {
  height: 32px;
  color: theme('colors.muted_black');
}

.box {

  height: 46px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-top: 20px;
}

.box-sub {
  height: auto;
  padding: 5px;
  margin-left: 26px;
  margin-right: 26px;
}

.text0 .text-2 {
  font-size: 14px;
  line-height: 20px;
}

.form_detail .form_grp:not(:first-child) {
  margin: 20px 0 !important;
}

.box1 {
  height: 55px;
  padding: 2px 2px 2px 2px;
  background-color: white;
  box-sizing: border-box;
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.date2 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;

  color: theme('colors.muted_black');
  text-align: right;
  line-height: 25px;
  font-size: 15px;
}

.form_detail label.vnt-select__title, .form_detail label {
  line-height: 1;
  text-align: left;
  display: flex;
}

.vnt-select__chosen {
  height: 32px;
  border: 2px solid #7a7a7a;
}

.tbox {
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.p1 {
  height: 42px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
}

.p2 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.p3 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.collapse .collapse-header {
  padding: 20px 20px 20px 40px;
  background: #f7f7f7;
  border-radius: 3px;
  /* position: relative; */
}

.map {
  border: 1px solid #797979;
  background-color: #ffffff;
  box-sizing: border-box;
}

th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
}

.floating {
  float: left;
}

.text-2 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text-to {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 15px;


}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.text-1 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;

  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 14px;

  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;

  line-height: 20px;
}

.line {
  color: #dfdfdf;

}

.rectangle {
  height: auto;
  padding-bottom: 15px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.id {

  height: 26px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: normal;
  font-size: 15px;
  //margin-right: 15px;
}

.name {
  width: 80%;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 2px;
}

.date {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
}


.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.head {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.zah {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.text-green {
  color: rgba(35, 157, 87, var(--tw-text-opacity)) !important;
}

.text-color {
  color: $primary
}

.inputcheck-text {
  margin-left: 28px;
  color: rgb(96 91 87);
  margin-top: -19px;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
}

.plan_button {

  margin: 0 20px;

}

.line_height {
  line-height: 20px !important;
}

.key_label {
  height: 32px;
  padding: 2px 2px 2px 2px;
  border: 2px solid #e1e1e1;
  background-color: #ffffff;
  color: #808080;
  font-size: 15px;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
}

.col_12.check_cond {
  width: 55%;
}
</style>
