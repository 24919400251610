const state = {
    list: [],
    communities: [],
    holidays: []
};
const getters = {
    getFederalStates: (state) => state.list,
    getStateHolidays: (state) => state.holidays,
    getCommunities: (state) => state.communities
}

const mutations = {
    setFederalStates: (state, federalStates) => {
        state.list = federalStates;
    },
    setStateHolidays: (state, holidays) => {
        state.holidays = holidays;
    },
    setCommunities: (state, val) => {
        state.communities[val.stateId] = val.communities
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



