const state = {
    list: [],
    powerOfAttorneyData: {},
    currentStep: 1
};
const getters = {
    getPowerOfAttorneyData: (state) => state.powerOfAttorneyData,
    getCurrentStep: (state) => state.currentStep,
    getPowersOfAttorneyList: (state) => state.list
}

const mutations = {
    init: (state) => {
        state.powerOfAttorneyData = {
            uid: 0,
            branch: '0',
            salutation: '0',
            title: '',
            phone: '',
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            countryUid: '0',
            postbox: '',
            preassignedAsStandard: false,
            authorizedRecipient: '1'
        };
        state.currentStep = 1;
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setPowerOfAttorneyData: (state, powerOfAttorney) => {
        state.powerOfAttorneyData = {...state.powerOfAttorneyData, ...powerOfAttorney};
    },
    setPowersOfAttorneyList: (state, powersOfAttorney) => {
        state.list = powersOfAttorney;
    },
};

const actions = {};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
