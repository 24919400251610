import {ApiService} from "./api.service";

export class BranchService extends ApiService {
  
  listAll(params) {
    if (this.$store.getters["branch/getBranchList"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["branch/getBranchList"]);
      });
    } else {
      return this.trigger('branch/list_all', {
        'sortField': 'name',
        'sortOrder': 1,
        'filters': {
          'uids': params && params.uids ? params.uids : []
        }
      }).then((response) => {
        if (response.data.success && parseInt(response.data.total_records) > 0) {
          this.$store.commit('branch/setBranchList', this.parse_object_data(response.data.records))
          return this.$store.getters["branch/getBranchList"];
        } else {
          return [];
        }
      })
    }
  }
  
  listMyBranches() {
    if (this.$store.getters["branch/getMyBranchList"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["branch/getMyBranchList"]);
      });
    } else {
      return this.trigger('branch/list_all', {
        'sortField': 'name',
        'sortOrder': 1,
        'filters': {
          'userId': 'current'
        }
      }).then((response) => {
        if (response.data.success && parseInt(response.data.total_records) > 0) {
          this.$store.commit('branch/setMyBranchList', this.parse_object_data(response.data.records))
          return this.$store.getters["branch/getMyBranchList"];
        } else {
          return [];
        }
      })
    }
  }
  
  save(data) {
    return this.trigger('branch/save', data, true).then(response => {
      if (response.data.success) {
        this.$store.commit('branch/setBranchList', [])
        this.$store.commit('branch/setMyBranchList', [])
        this.$store.commit("user/setCurrentUserStatsDataChanged", true);
        return true;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }
  
  import_datev_branch(data) {
    return this.trigger('branch/datev_import', data).then((response) => {
      if (response.data.success) {
        this.$store.commit('branch/setBranchList', [])
        this.$store.commit('branch/setMyBranchList', [])
        this.$store.commit("user/setCurrentUserStatsDataChanged", true);
      }
      
      return response;
    });
  }
  
  get_saved_datev_ids() {
    return this.trigger('branch/get_datev_ids', null).then((response) => {
      if (response.data.success) {
        return response.data.datevIds;
      } else {
        return [];
      }
    });
  }
  
  get(uid) {
    return this.trigger('branch/get', {
      uid: uid
    }, true).then((response) => {
      if (response.data.success) {
        response.data.branchData.selected_users = this.parse_object_data(response.data.branchData.selected_users).map(o => {
          o.name = (o.first_name + ' ' + o.last_name).trim();
          return o;
        });
        return response.data.branchData;
      } else {
        this.showToast(response.data.message, 'error');
        return [];
      }
    })
  }
  
  export(columns, filters) {
    let column_data = [];
    columns !== null && columns.map(column => {
      column_data.push({columnOrder: column.columnOrder, field: column.field});
    })
    return this.trigger('branch/export', {
      export_filters: filters,
      columns: column_data,
    }, true, '', 'blob').then((response) => {
      this.trigger_download(response);
    });
  }
}
