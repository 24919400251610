<template>
  <div class="h-full bg-white px-7 w-full ">
    <PageHeader :title="this.$t('declarations.bulk_transfer')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" px-5 w-full">
        <FormSteps :steps="availableSteps"/>
      </div>
      <div class=" w-full">
        <div class="mt-10 wiz" v-if="completed===false">
          <component
              v-for="(item, index) in availableSteps"
              v-show="item.component && item.active === true"
              :key="`component-${index}`"
              :is="item.component"
              :fromGrid="fromGrid"
              @changeWizard="updateWizard"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"
              :active="item.active"
          />
        </div>
        <div class="mt-10 mr-10 ml-10" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick"/>
        </div>
      </div>
    </div>
    <div class="w-full mt-8 mr-8">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { S3Service } from '@/services/s3.service';
import { DeclarationService } from '@/services/declaration.service';
import { PowersOfAttorneyService } from '@/services/powersofattorney.service';
import { ClientService } from '@/services/client.service';
import { BranchService } from '@/services/branch.service';

import FormSteps from '@/components/FormSteps';
import Step1 from '@/components/declarations/bulk_add_steps/Step1';
import Step2 from '@/components/declarations/bulk_add_steps/Step2';
import Step3 from '@/components/declarations/bulk_add_steps/Step3';
import Step4 from '@/components/declarations/bulk_add_steps/Step4';
import Step5 from '@/components/declarations/bulk_add_steps/Step5';
import Step6 from '@/components/declarations/bulk_add_steps/Step6';
import Step7 from '@/components/declarations/bulk_add_steps/Step7';

import StepMarkMassAppeal1 from '@/components/declarations/bulk_add_steps/StepMarkMassAppeal1';

import StepMassAppeal1 from '@/components/declarations/bulk_add_steps/StepMassAppeal1';
import StepMassAppeal2 from '@/components/declarations/bulk_add_steps/StepMassAppeal2';

import Step_document from '@/components/declarations/bulk_add_steps/Step_document';
import StepTaxCalculationCommunity from '@/components/declarations/bulk_add_steps/StepTaxCalculationCommunity';

import Finish from '@/components/declarations/bulk_add_steps/Finish.vue';

export default {
  name: "BulkAdd",
  components: {
    FormSteps,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    StepMarkMassAppeal1,
    StepMassAppeal1,
    StepMassAppeal2,
    Step_document,
    StepTaxCalculationCommunity,
    Finish
  },
  data() {
    return {
      s3Service: new S3Service,
      service: new DeclarationService(),
      poaService: new PowersOfAttorneyService(),
      clientService: new ClientService(),
      branchService: new BranchService(),
      propertyService: undefined,
      breadcrumbs: [
        {
          title: this.$t('declarations.title'),
          link: ''
        },
        {
          title: this.$t('declarations.bulk_transfer'),
          link: ''
        }
      ],
      completed: false,
      fromGrid: false,
      availableSteps: [],
      stepsOfNewDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('declarations.mass_update.steps.client_property'),
          completed: false,
          active: false,
          last: false,
          component: "Step2"
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.basic_info'),
          completed: false,
          active: false,
          last: false,
          component: "Step3"
        },
        {
          id: 4,
          title: this.$t('declarations.mass_update.steps.power_of_attorney'),
          completed: false,
          active: false,
          last: false,
          component: "Step4"
        },
        {
          id: 5,
          title: this.$t('declarations.mass_update.steps.overview'),
          completed: false,
          active: false,
          last: true,
          component: "Step5"
        },
      ],
      stepsOfUpdateDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('declarations.mass_update.steps.select_declarations'),
          completed: false,
          active: false,
          last: false,
          component: "Step6"
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: "Step7"
        }
      ],
      stepsOfUpdateDocumentDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('declarations.mass_update.steps.select_declarations'),
          completed: false,
          active: false,
          last: false,
          component: "Step6"
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.document'),
          completed: false,
          active: false,
          last: false,
          component: "Step_document"
        },
        {
          id: 4,
          title: this.$t('declarations.mass_update.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: "Step7"
        }
      ],
      stepsOfTaxCalculationCommunityDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: 'Step1'
        },
        {
          id: 2,
          title: this.$t('declarations.mass_update.steps.select_declarations'),
          completed: false,
          active: false,
          last: false,
          component: 'Step6'
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.tax_calculation_community'),
          completed: false,
          active: false,
          last: false,
          component: 'StepTaxCalculationCommunity'
        },
        {
          id: 4,
          title: this.$t('declarations.mass_update.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: 'Step7'
        }
      ],
      stepsOfMassAppealDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: 'Step1'
        },
        {
          id: 2,
          title: this.$t('declarations.mass_update.steps.select_declarations'),
          completed: false,
          active: false,
          last: false,
          component: 'StepMassAppeal1'
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: 'StepMassAppeal2'
        }
      ],
      stepsOfMarkMassAppealDeclaration: [
        {
          id: 1,
          title: this.$t('declarations.mass_update.steps.process_type'),
          completed: false,
          active: true,
          first: true,
          component: 'Step1'
        },
        {
          id: 3,
          title: this.$t('declarations.mass_update.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: 'StepMarkMassAppeal1'
        }
      ],
      fromProperties: {
        active: false,
        stepsOfNewDeclaration: [
          {
            id: 1,
            title: this.$t('declarations.mass_update.steps.basic_info'),
            completed: false,
            active: true,
            first: true,
            component: "Step3"
          },
          {
            id: 2,
            title: this.$t('declarations.mass_update.steps.power_of_attorney'),
            completed: false,
            active: false,
            last: false,
            component: "Step4"
          },
          {
            id: 3,
            title: this.$t('declarations.mass_update.steps.overview'),
            completed: false,
            active: false,
            last: true,
            component: "Step5"
          },
        ],
        assignedUser: '0',
        assignedUserName: '',
        selectedProperties: [],
        multiClients: [
          {
            code: '0',
            name: '',
            branchId: '0',
            powerOfAttorneyFromClient: '0',
            powerOfAttorneyFromBranch: '0'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters("declaration_bulk_add", ["getCurrentStep"])
  },
  mounted() {
    if(this.getCurrentUser() && this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_ops)) {
      if (parseInt(this.getCurrentUser().permissions.allow_mass_update) === 1) {
        this.init();

        if (this.$route.name === 'Properties') {
          this.fromProperties.active = true;

          if (this.$route.params.breadcrumbs) {
            this.breadcrumbs = [...this.$route.params.breadcrumbs];
          }
          if (this.$route.params.processType && this.$t('properties.mass_update.process')[this.$route.params.processType] && this.$route.params.selectedProperties.length > 0) {
            this.fromGrid = this.$route.params.fromGrid;
            this.$loading.show();
            this.availableSteps = this.fromProperties.stepsOfNewDeclaration;

            this.fromProperties.assignedUser = this.getCurrentUser().id;
            this.fromProperties.assignedUserName = this.getCurrentUser().name;

            this.fromProperties.selectedProperties = [];
            let withoutDeclarationStatusOption = this.$t('properties.property_declaration_status_options').find(o => o.code === 'without_declaration');
            this.$route.params['selectedProperties'].forEach((property) => {
              let myProperty = {
                "property_id": property.property_id,
                "name": property.name,
                "declarationNumber": property.reference,
                "prim_uid": property.prim_uid,
                "federalStateUid": parseInt(property.federal_state_uid ?? property.federalStateUid),
                "client_uid": property.client_uid,
                "client_name": property.client_name,
                "declarationRelevantMarkedByUser": property.declarationRelevantMarkedByUser,
                "nextDeclarationType": property.nextDeclarationType
              }
              this.fromProperties.selectedProperties.push(myProperty);
            }, this);

            try {
              let clientUids = this.fromProperties.selectedProperties.map(p => p['client_uid'])
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  });
              this.clientService.listAll({"uids": clientUids, "rows": -1}).then((clients) => {
                if (clients && clients.list && clients.list.length > 0) {
                  this.fromProperties.multiClients = clients.list.map((list) => {
                    return {
                      "name": list.client_id + ' - ' + list.client_name,
                      "code": list.prim_uid,
                      "branchId": list.branch_id,
                      "powerOfAttorneyFromClient": list.power_of_attorney,
                      "powerOfAttorneyFromBranch": "0"
                    }
                  });

                  let branchUids = this.fromProperties.multiClients.map(c => c['branchId'])
                      .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                      });

                  let listOfBranches = [];
                  this.branchService.listAll({"uids": branchUids}).then((branches) => {
                    if (branches && branches.length > 0) {

                      listOfBranches = branches.map((list) => {
                        return {
                          "branchId": list.uid,
                          "powerOfAttorney": list.power_of_attorney
                        }
                      });

                      this.fromProperties.multiClients.forEach((client) => {
                        let foundBranch = listOfBranches.find(branch => branch.branchId === client.branchId);
                        if (typeof foundBranch !== 'undefined') {
                          client.powerOfAttorneyFromBranch = foundBranch.powerOfAttorney;
                        }
                      }, this);

                    }
                    this.saveSteps({
                      "multiClients": JSON.parse(JSON.stringify(this.fromProperties.multiClients))
                    });
                    this.$loading.hide();
                  });
                } else {
                  this.$loading.hide();
                }
                this.saveSteps({
                  "multiClients": JSON.parse(JSON.stringify(this.fromProperties.multiClients))
                });
              });
            } catch {
              this.$loading.hide();
            }

            this.saveSteps({
              "processType": this.$route.params.processType,
              "selectedDeclarations": {},
              "assignedUser": this.fromProperties.assignedUser,
              "assignedUserName": this.fromProperties.assignedUserName,
              "selectedProperties": this.fromProperties.selectedProperties,
              "fromPropertiesActive": true,
              "fromChangeManagement": typeof this.$route.params.fromChangeManagement !== 'undefined' ? this.$route.params.fromChangeManagement : 0,
              "multiClients": JSON.parse(JSON.stringify(this.fromProperties.multiClients))
            });
            this.setCurrentStep(1);
            this.poaService.listAll();

          } else {
            this.$router.go(-1);
          }
        } else {
          if (
            this.$route.params.processType
            && (
              this.$t('declarations.mass_update.process')[this.$route.params.processType]
              || this.$route.params.processType === 'mark_mass_appeal'
            )
            && typeof this.$route.params.selectedDeclarations !== undefined
            && this.$route.params.selectedDeclarations.length > 0
          ) {
            if (this.$route.params.fromGrid) {
              this.fromGrid = this.$route.params.fromGrid;
            }

            this.$loading.show();

            this.availableSteps = this.stepsOfUpdateDeclaration;
            if (this.$route.params.processType === 'document_creation') {
              this.availableSteps = this.stepsOfUpdateDocumentDeclaration;
            } else if (this.$route.params.processType === 'tax_calculation_community') {
              this.availableSteps = this.stepsOfTaxCalculationCommunityDeclaration;
            } else if (this.$route.params.processType === 'mass_appeal') {
              this.availableSteps = this.stepsOfMassAppealDeclaration;
            } else if (this.$route.params.processType === 'mark_mass_appeal') {
              this.availableSteps = this.stepsOfMarkMassAppealDeclaration;
            }

            this.setCurrentStep(1);
            this.saveSteps({
              "processType": this.$route.params.processType,
              "selectedDeclarations": this.$route.params.selectedDeclarations
            });
            this.moveStep('next');
            this.moveStep('next');
            this.$loading.hide();
          } else {
            this.availableSteps = this.stepsOfNewDeclaration;
          }
          this.poaService.listAll(); // just to cache the data in store for usage in further steps
        }
      } else {
        this.$router.push('/home');
      }
    } else {
      this.showPackageLimitWarning();
      this.$router.push('/home');
    }
  },
  methods: {
    ...mapMutations("declaration_bulk_add", ["setCurrentStep", "init", "saveSteps", "setNotificationId"]),
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapGetters("user", ["getCurrentUser"]),
    updateWizard(e) {
      this.setCurrentStep(1);
      if (e === 'new_declaration') {
        this.availableSteps = this.stepsOfNewDeclaration;
      } else if (e === 'document_creation') {
        this.availableSteps = this.stepsOfUpdateDocumentDeclaration;
      } else if (e === 'tax_calculation_community') {
        this.availableSteps = this.stepsOfTaxCalculationCommunityDeclaration;
      } else if (e === 'mass_appeal') {
        this.availableSteps = this.stepsOfMassAppealDeclaration;
      } else if (e === 'mark_mass_appeal') {
        this.availableSteps = this.stepsOfMarkMassAppealDeclaration;
      } else {
        this.availableSteps = this.stepsOfUpdateDeclaration;
      }
    },
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      if (this.fromGrid ||
          (this.fromProperties.active === true && this.getCurrentStep === 1)) {
        this.$router.go(-1)
      } else {
        this.moveStep('back')
      }
    },
    finish() {
      this.$modal.show({
        headerText: this.$t('declarations.mass_update.save_confirmation.title'),
        textHtml: this.$t('declarations.mass_update.save_confirmation.message'),
        showClose: true,
        canEscape: false,
        canCloseOutsideClick: false,
        buttons: {
          ok: this.$t('declarations.mass_update.save_confirmation.proceed'),
          cancel: this.$t('buttons.cancel'),
          delete: false
        },
        onConfirm: () => {
          let recordCount = 0;
          let jobData = {
            "processType": this.getSteps().processType
          };

          if (this.getSteps().processType === "new_declaration") {
            if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
              jobData['properties'] = [];
              for (let item of this.getSteps().selectedProperties) {
                jobData['properties'].push({
                  'clientId': this.getSteps().clientId,
                  'propertyId': item.prim_uid,
                  'propertyTitle': item.propertyTitle,
                  'assignedUser': this.getSteps().assignedUser,
                  'declarationNumber': item.declarationNumber,
                  'recipientType': item.recipientType,
                  'recipientTypeLabel': item.recipientTypeLabel,
                  'declarationType': item.declarationType,
                  'declarationTypeLabel': item.declarationTypeLabel,
                  'declarationReferenceDate': item.declarationReferenceDate,
                  'declarationReferenceDateLabel': item.declarationReferenceDateLabel,
                  'participation': item.participation === true ? 1 : 0,
                });
              }
              recordCount = jobData['properties'].length;
            } else {
              return false;
            }
          } else if (this.getSteps().selectedDeclarations && this.getSteps().selectedDeclarations.length > 0) {
            jobData['declarations'] = this.getSteps().selectedDeclarations;
            jobData['assigneeForRelease'] = this.getSteps().assigneeForRelease;
            if (this.getSteps().processType === "document_creation") {
              if (this.getSteps().selectedDocumentTypes.length > 0) {
                jobData['selectedDocumentTypes'] = this.getSteps().selectedDocumentTypes;
              } else {
                return false;
              }
            } else if (this.getSteps().processType === 'tax_calculation_community') {
              if (typeof this.getSteps().calculationCommunity !== 'undefined' && this.getSteps().calculationCommunity !== '') {
                jobData['calculationCommunity'] = this.getSteps().calculationCommunity;
              }
            }
            recordCount = jobData['declarations'].length;
          } else {
            return false;
          }

          // Upload to bucket
          this.$loading.show();
          this.s3Service.upload({
            data: jobData,
            fileName: 'declarations_job.json'
          }).then((res) => {
            if (res.success) {
              this.service.start_mass_update_job({
                "s3Path": res.s3Path,
                "processType": jobData.processType,
                "fromChangeManagement": typeof this.getSteps().fromChangeManagement !== 'undefined' ? this.getSteps().fromChangeManagement : 0,
                "recordCount": recordCount
              }).then((response) => {
                this.$loading.hide();
                if (response.data.success) {
                  if (response.data.notificationId) {
                    this.setNotificationId(parseInt(response.data.notificationId));
                  }
                  this.moveStep('next', true)
                  this.completed = true
                } else {
                  this.$api.showToast(response.data.message, "error")
                }
              })
            } else {
              this.$loading.hide();
              this.$api.showToast(this.$t('general.errors.unknownError'), "error")
            }
          })
        }
      });
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.availableSteps = this.availableSteps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.availableSteps.findIndex(item => item.id === step)
        this.availableSteps = this.availableSteps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }
        })

        this.setCurrentStep(step)
      }
    },

    handleFinishButtonClick() {
      this.$emit("click", "client_add_finish")
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.wiz ::v-deep .footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
</style>
