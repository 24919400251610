<template>
  <div
    class="flex flex-none flex-col bg-gray_light px-4 py-3 tax-calculation-sidebar"
    :class="[hidden ? 'hidden' : '']"
  >
    <header>
      <div class="p-dialog-header">
        <h3 class="text-xl font-bold">
          {{ $t('declarations.tax_calculation.nav_title') }}
        </h3>
          <button
            aria-label="close"
            type="button"
            class="w-8 h-8 align-sub hover:bg-gray rounded"
            @click="hide"
          >
            <span class="pi pi-times"/>
          </button>
      </div>
    </header>

    <div class="flex justify-start items-center text-xs px-1 py-2 mb-6 mt-4 bg-white rounded">
      <img
        class="image ml-2"
        :src="getAssetPath('info_gray.svg')"
      />
      <label class="text-left ml-2">{{ $t('declarations.tax_calculation.nav_info') }}</label>
    </div>

    <div class="flex w-full justify-between items-center space-x-1 mb-6">
      <Button
        :text="$t('declarations.tax_calculation.btnOpenAll')"
        :isSlim="true"
        @click="openAllItems"
      />

      <Button
        :text="$t('declarations.tax_calculation.btnCloseAll')"
        :isSlim="true"
        @click="closeAllItems"
      />
    </div>

    <div v-if="loading">
      <i class="pi pi-spin pi-spinner text-green text-5xl mb-4" />
      <div>{{ $t('declarations.tax_calculation.nav_loading') }}</div>
    </div>

    <Accordion
      class="rounded"
      :multiple="true"
      :activeIndex="activeAccordionIndexes"
    >
      <AccordionTab
        v-for="(item, index) in filteredNavigationList"
        v-bind:key="index"
      >
        <template #header>
          <div class="w-full">
            <div>
              {{ item.community }}
              <span class="text-sm font-normal">({{ filterParcels(item.parcels).length }})</span>
            </div>
            <span class="text-sm font-normal">{{ item.description }}</span>
          </div>
        </template>

        <DataTable
          :stripedRows="false"
          :scrollable="false"
          :value="filterParcels(item.parcels)"
          :rows="filterParcels(item.parcels).length"
          selectionMode="single"
          :selection.sync="selectedRow"
          @row-select="onRowSelect"
        >
          <Column field="district">
            <template #body="slotProps">
              <div class="mb-2 text-lg font-semibold text-center">{{ slotProps.data.district }}</div>
              <ul>
                <li
                  class="text-sm font-normal"
                  v-for="(parcelDetail, index) in getParcelDetails(slotProps)"
                  v-bind:key="index"
                >
                  <span class="font-semibold">{{ parcelDetail.title }}:</span>&nbsp;{{ parcelDetail.value }}
                </li>
              </ul>
            </template>
          </Column>
        </DataTable>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { PropertyService } from '@/services/property.service';

export default {
  name: 'TaxCalculationSidebar',
  components: {
    Accordion,
    AccordionTab,
    DataTable,
    Column
  },
  emits: [
    'show',
    'hide',
    'navigationLoaded',
    'scrollToElementId',
  ],
  props: {
    parcelIdsWithoutTaxCalculationElement: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      hidden: true,
      loading: false,
      propertyData: undefined,
      navigationList: [],
      selectedRow: null,
      activeAccordionIndexes: [],

      propertyService: new PropertyService(),
    }
  },
  mounted() {
    this.activeAccordionIndexes = this.allAccordionIndexes;
  },
  computed: {
    allAccordionIndexes() {
      let indexes = [];
      for (let index = 0; index < this.filteredNavigationList.length; index++) {
        indexes.push(index);
      }

      return indexes;
    },
    filteredNavigationList() {
      return this.navigationList.filter(navItem => {
        if (this.filterParcels(navItem.parcels).length === 0) {
          return false;
        }

        return true;
      });
    }
  },
  watch: {
    propertyData: {
      handler(newVal) {
        this.refreshNavigationList(newVal);
      },
      deep: true
    }
  },
  methods: {
    show(propertyId) {
      this.loading = true;
      this.propertyService.get(propertyId, null, false).then((data) => {
        this.loading = false;
        this.setPropertyData(data);
      });

      this.hidden = false;
      this.$emit('show');
    },
    hide() {
      this.hidden = true;
      this.propertyData = [];
      this.$emit('hide');
    },
    toggle(propertyId) {
      if (this.hidden) {
        this.show(propertyId);
      } else {
        this.hide();
      }
    },
    setPropertyData(val) {
      if (typeof val === 'undefined') {
        this.propertyData = undefined;
        return;
      }
      this.propertyData = JSON.parse(JSON.stringify(val));
    },
    scroll(elementId) {
      this.$emit('scrollToElementId', elementId)
    },
    refreshNavigationList(propertyData) {
      this.navigationList = [];

      if (
          typeof propertyData === 'undefined'
          || typeof propertyData.step9 === 'undefined'
          || typeof propertyData.step9.gemeindes === 'undefined'
      ) {
        this.$emit('navigationLoaded', this.navigationList);
        return;
      }

      for (let gemeindeIndex = 0; gemeindeIndex < Object.keys(propertyData.step9.gemeindes).length; gemeindeIndex++) {
        let navigationListItem = {
          primUid: propertyData.step9.gemeindes[gemeindeIndex].primUid,
          community: propertyData.step9.gemeindes[gemeindeIndex].community,
          description: propertyData.step9.gemeindes[gemeindeIndex].description,
          federalStateUid: propertyData.step9.gemeindes[gemeindeIndex].federal_state_uid,
          isOpen: true,
          parcels: [],
        };

        if (typeof propertyData.step9.gemeindes[gemeindeIndex].parcels === 'object') {
          let parcelItem;
          for (let parcelIndex = 0; parcelIndex < Object.keys(propertyData.step9.gemeindes[gemeindeIndex].parcels).length; parcelIndex++) {
            parcelItem = {
              primUid: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].primUid,
              district: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].district,
              districtNumber: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].district_number,
              corridor: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].corridor,
              landRegisterSheet: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].landRegisterSheet,
              counter: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].counter,
              denominator: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].denominator,
              areaOfTheLand: propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].areaOfTheLand,
              elementId: 'id_parcel_' + propertyData.step9.gemeindes[gemeindeIndex].parcels[parcelIndex].primUid
            };
            navigationListItem.parcels.push(parcelItem);
          }
        }
        this.navigationList.push(navigationListItem);
      }

      this.$emit('navigationLoaded', this.navigationList);

      this.openAllItems();
    },
    filterParcels(parcels) {
      return parcels.filter(parcel => {
        return this.shouldShowNavItemForParcel(parcel.elementId);
      });
    },
    shouldShowNavItemForParcel(parcelId) {
      let shouldShowNavItem = true;

      this.parcelIdsWithoutTaxCalculationElement.forEach(elementId => {
        if (parcelId === elementId) {
          shouldShowNavItem = false;
        }
      });

      return shouldShowNavItem;
    },
    onRowSelect() {
      if (this.selectedRow !== null && typeof this.selectedRow.elementId !== 'undefined') {
        this.scroll(this.selectedRow.elementId);
      }
    },
    getParcelDetails(slotProps) {
      let parcelDetails = [];

      if (typeof slotProps.data.districtNumber !== 'undefined' && slotProps.data.districtNumber !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblDistrictNumber'),
          value: slotProps.data.districtNumber
        });
      }

      if (typeof slotProps.data.corridor !== 'undefined' && slotProps.data.corridor !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblCorridor'),
          value: slotProps.data.corridor
        });
      }

      if (typeof slotProps.data.landRegisterSheet !== 'undefined' && slotProps.data.landRegisterSheet !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblSheet'),
          value: slotProps.data.landRegisterSheet
        });
      }

      if (typeof slotProps.data.counter !== 'undefined' && slotProps.data.counter !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblParcelCounter'),
          value: slotProps.data.counter
        });
      }

      if (typeof slotProps.data.denominator !== 'undefined' && slotProps.data.denominator !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblParcelDenominator'),
          value: slotProps.data.denominator
        });
      }

      if (typeof slotProps.data.areaOfTheLand !== 'undefined' && slotProps.data.areaOfTheLand !== '') {
        parcelDetails.push({
          title: this.$t('declarations.tax_calculation.nav_parcel_details.lblAreaOfTheLand'),
          value: slotProps.data.areaOfTheLand.replace(/\B(?=(\d{3})+(?!\d))/g, this.$t('general.format_number.thousands_separator')) + ' m²'
        });
      }

      return parcelDetails;
    },
    openAllItems() {
      this.activeAccordionIndexes = this.allAccordionIndexes;
    },
    closeAllItems() {
      this.activeAccordionIndexes = [];
    }
  },
}
</script>

<style scoped lang="scss">
.tax-calculation-sidebar {
  height: 100vh;
  position: sticky;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  width: 300px;
}

:deep(.p-accordion) {
  .p-accordion-tab {
    @apply mb-4;
  }

  .p-accordion-content {
    padding: 0;
  }

  .p-accordion-header-link {
    @apply rounded p-2;
  }
}

:deep(.p-datatable) {
  .p-datatable-thead {
    display: none;
  }

  .p-datatable-tbody {
    .p-highlight {
      background-color: theme('colors.white');
    }
  }
}

:deep(a:hover) {
  text-decoration: unset;
}
</style>
