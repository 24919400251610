<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.powers_of_attorney.add_power_of_attorney')" :breadcrumbs="breadcrumbs"/>

    <div class="flex flex-col justify-center items-center">
      <div class="w-full ">
        <FormSteps :steps="steps"/>
      </div>
      <div class=" w-full">
        <div class="mt-10" v-if="completed===false">
          <component
              v-for="(item, index) in steps"
              :key="`component-${index}`"
              :is="item.component"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"
              v-show="item.active === true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FormSteps from "../FormSteps";
import Step1 from '@/components/settings/power_of_attorney_steps/Step1.vue';
import Finish from '@/components/settings/power_of_attorney_steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "addPowerOfAttorney",
  components: {FormSteps, Step1, Finish},
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },

  data() {
    return {
      breadcrumbs: [{
        title: this.$t('settings.powers_of_attorney.breadcrumbs.settings'),
        link: ''
      }, {
        title: this.$t('settings.powers_of_attorney.breadcrumbs.powers_of_attorney'),
        link: '/setting/powersofattorney'
      }],
      completed: false,
      steps: [
        {
          id: 1,
          title: this.$t('settings.powers_of_attorney.tabs.tab1'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('settings.powers_of_attorney.tabs.tab2'),
          completed: false,
          active: false,
          last: true,
          component: "Finish"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("power_of_attorney", ["getCurrentStep"]),
    getTitle() {
      return this.$t('settings.powers_of_attorney.title')
    }
  },
  methods: {
    ...mapMutations("power_of_attorney", ["setCurrentStep"]),
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    finish() {
      this.moveStep('next', true)
      this.completed = true
    },
    changeWindowSize() {
      // return i18n.locales = "es";
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }

        })

        this.setCurrentStep(step)
      }

    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
