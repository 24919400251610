<template>
  <MainLayout value="deadline">
    <template>
      <component :is="comp"/>
    </template>
  </MainLayout>
</template>

<script>
import list from "@/components/deadline/list"
import {mapGetters} from "vuex";

export default {
  name: "Deadline",
  components: {list},
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    },
    getTitle() {
      return `GrundsteuerDigital - ${this.$t('sidebar.deadline')}`
    }
  },
  created() {
    this.verifyingAccess = true;
    if(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_deadline_control)) {
      this.checkAccess('deadline');
    } else {
      this.showPackageLimitWarning();
      this.$router.push('/home');
    }

  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"])
  }
}
</script>

<style scoped lang="scss">

</style>
