<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <span class="font-bold">{{ $t('declarations.mass_update.steps.process_type') }}:</span>
          <span class="ml-2">{{ $t('declarations.mass_update.process.mass_appeal') }}</span>
        </div>
      </div>

      <div class="flex flex-col w-full p-3 mt-6 mx-auto bg-gray_light rounded">
        <div class="flex justify-start text-base">
          <img class="image" :src="getAssetPath('warning.svg')" />
          <label class="text-left ml-3">{{ $t('declarations.mass_update.save_warning_info') }}</label>
        </div>
      </div>

      <div class="mt-6">
        <div class="flex w-full">
          <h3 class="text-xl font-bold ">{{ $t("declarations.mass_update.selected_declarations_list") }}</h3>
        </div>

        <div class="flex justify-around space-x-1 mt-2">
          <DataGrid
            grid-id="grid-selected-declarations"
            :local-data="selectedDeclarations"
            :columns="columns"
            :pagination="false"
            default-sort-attr="name"
            :default-sort-dir="1"
            :show-select-col="false"
            :use-local-data="true"
            :resizable-columns="false"
            :reorderable-columns="false"
            :show-create-record="false"
          />
        </div>
      </div>
    </div>

    <div class="sticky bottom-0 p-4 bg-white z-10">
      <div class="flex flex-col">
        <div class="w-full p-3 mb-4 bg-gray_light rounded">
          <span v-html="$t('declarations.mass_update.mass_appeal_button_tipp', {total_sum: this.massAppealTotalPrice})"/>
        </div>
      </div>

      <div class="flex justify-end space-x-4">
        <Button
          class="w-56"
          :text="$t('buttons.back')"
          :secondary="true"
          @click="goBack"
        />
        <Button
          class="w-auto"
          :text="$t('declarations.mass_update.mass_appeal_button')"
          :disabled="!hasEnteredValidMassAppealDataForAllDeclarations"
          @click="submitNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { MassElsterMessageTypeService } from '@/services/MassElsterMessageType.service';

import Fieldset from 'primevue/fieldset';

import DataGrid from '@/components/DataGrid';

export default {
  name: 'StepMassAppeal2',
  components: {
    Fieldset,
    DataGrid
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fromGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      massElsterMessageTypeService: new MassElsterMessageTypeService(),

      selectedDeclarations: [],
      massAppealTypes: [],

      massAppealTypesIds: [],
      columns: [
        {
          field: 'declarationId',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          sortable: false,
        },
        {
          field: 'name',
          header: this.$t('declarations.mass_update.declaration'),
          isChecked: true,
          sortable: false,
        },
        // @TODO: Enable when OCR has been extended
        /*{
          field: 'ocrActDate',
          header: this.$t('declarations.mass_update.ocr_suggested_date'),
          isChecked: true,
          colElType: 'select-date',
          dataSource: [],
          minDate: new Date('2022-07-01'),
          maxDate: new Date(),
          sortable: false,
          disableFn: () => {
            return true;
          }
        },*/
        {
          field: 'massAppealActDate',
          header: this.$t('declarations.mass_update.mass_appeal_act_date'),
          isChecked: true,
          colElType: 'select-date',
          dataSource: [],
          model: 'massAppealActDate',
          sortable: false,
          minDate: new Date('2022-07-01'),
          maxDate: new Date(),
          isErrorCallback: (declaration) => {
            if (!this.hasSelectedMassAppealType) {
              return false;
            }

            return !this.isDeclarationActDateValid(declaration);
          }
        },
        {
          field: 'massAppealType',
          header: this.$t('declarations.mass_update.mass_appeal_types'),
          isChecked: true,
          sortable: false,
          colElType: 'select',
          model: 'massAppealType',
          dataSource: (declaration) => {
            return this.getApplicableMassAppealTypesForDeclaration(declaration);
          }
        }
      ],
    }
  },
  watch: {
    active: {
      handler(newValue) {
        if (newValue) {
          this.show();
        }
      },
      immediate: true
    }
  },
  computed: {
    hasSelectedDeclarationsFromSteps() {
      return this.getSteps().selectedDeclarations
        && this.getSteps().selectedDeclarations.length > 0;
    },
    selectedDeclarationsFromSteps() {
      if (!this.hasSelectedDeclarationsFromSteps) {
        return [];
      }

      return this.getSteps().selectedDeclarations;
    },
    massAppealPricePerMessage() {
      return parseFloat(this.getCurrentUser().plan_details.massAppealPrice);
    },
    massAppealTotalPrice() {
      let totalSum = 0;

      this.selectedDeclarations.forEach((declaration) => {
        if (declaration.type === '1001') {
          totalSum += 20;
        }

        totalSum += this.massAppealPricePerMessage;
      });

      return totalSum;
    },
    hasEnteredValidMassAppealDataForAllDeclarations() {
      let allDeclarationsHaveValidData = true;

      if (this.selectedDeclarations.length === 0) {
        allDeclarationsHaveValidData = false;
      }

      this.selectedDeclarations.forEach((declaration) => {
        if (!this.isDeclarationActDateValid(declaration)) {
          allDeclarationsHaveValidData = false;
        }

        let selectedMassAppealType = parseInt(declaration.massAppealType);
        if (isNaN(selectedMassAppealType) || selectedMassAppealType === 0) {
          allDeclarationsHaveValidData = false;
        }
      });

      return allDeclarationsHaveValidData;
    }
  },
  methods: {
    ...mapGetters('declaration_bulk_add', ['getSteps']),
    ...mapMutations('grid', ['triggerReload']),
    ...mapMutations('declaration_bulk_add', ['saveSteps']),
    show() {
      this.massElsterMessageTypeService.listAll('appeal', false).then((list) => {
        this.massAppealTypes = list;
      });

      this.selectedDeclarations = this.selectedDeclarationsFromSteps;

      this.triggerReload('grid-selected-declarations');
    },
    submitNext() {
      this.saveSteps({
        selectedDeclarations: this.selectedDeclarations
      });

      this.$emit('finish');
    },
    goBack() {
      this.$emit('goBack');
    },
    getApplicableMassAppealTypesForDeclaration(declaration) {
      let filteredMassAppealTypes = this.massAppealTypes;

      filteredMassAppealTypes = filteredMassAppealTypes.filter((o) => {
        if (o.administrative_act === '299' && ![2, 9].includes(parseInt(declaration.federalStateUid))) {
          return false;
        }

        if (o.administrative_act === '271' && [2, 7, 9].includes(parseInt(declaration.federalStateUid))) {
          return false;
        }

        if (o.federal_state_uids !== null && !o.federal_state_uids.includes(parseInt(declaration.federalStateUid))) {
          return false;
        }

        return true;
      });

      return filteredMassAppealTypes.map((massAppealType) => {
        return {
          code: massAppealType.prim_uid,
          name: massAppealType.name
        };
      });
    },
    isDeclarationActDateValid(declaration) {
      if (declaration.massAppealActDate === '') {
        return false;
      }

      return true;
    }
  }
}
</script>
