<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center" @click="onClickOutside">
      <div class="modal">
        <div class="  w-full h-screen flex flex-col justify-between">
          <header class=" flex justify-between  modal-header" v-if="showClose">
            <label class="dheader ">{{ headerText }}</label>
            <img src="@/assets/close_thin.png"
                 class="btn-close cursor-pointer"
                 @click="close"
                 v-if="showClose"
            />
          </header>
          <div class="flex flex-col h-full justify-between ">
            <div v-show="loading" class="flex flex-col w-full loading-container">
              <div class="spin"></div>
            </div>
            <div class="h-full" :class="{loading_body: loading}">
              <slot name="body">
              </slot>
            </div>
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {directive as onClickaway} from "vue-clickaway";

export default {

  name: 'ModalDialogTable',
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    headerText: {
      type: String,
      default: ""
    },
    canEscape: {
      type: Boolean,
      default: true
    },
    canCloseOutsideClick: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clickedOutSideCount: 0,
    }

  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.close()
        }

      }
    });
  },
  methods: {
    close() {
      this.clickedOutSideCount = 0;
      this.$emit('close');
    },
    onClickOutside() {

      this.clickedOutSideCount++;
      // if (this.clickedOutSideCount > 1) {
      if (this.canCloseOutsideClick) {
        this.close()

      }
      // }


    }
  },
};
</script>


<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 2;
}

.modal {
  width: 30%;

  background: white;
  right: 0;
  position: absolute !important;


  //overflow-y:auto;

}


.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  //display: flex;
  //align-self: end;
  //flex-direction: row;
  //border-bottom: 1px solid #eeeeee;
  color: #333333;
  //justify-content: flex-end;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  // position: relative;
  // padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  //position: absolute;
  //top: 0;
  //right: 0;
  //border: none;
  //font-size: 40px;
  //padding: 10px;
  //cursor: pointer;
  //font-weight: bold;
  //color: #333333;
  //background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.dheader {

  //padding: 0 20px;
  max-width: 90%;
  max-height: 100px;
  overflow: hidden;


  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}
.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}
.spin {
  margin: auto;
  position: relative;
}
.loading_body {
  opacity: 0.1;
}
</style>
