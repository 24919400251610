const state = {
  showModal: false,
  currentUser: {
    salutation: '',
    salutation_lbl: '',
    name: '',
    email: '',
    phone: '',
    lang_name: '',
    lang_code: '',
    company_name: '',
    street: '',
    two_fa: 0,
    zip: '',
    city: '',
    country_uid: 0,
    country_name: '',
    vat_registration_id: '',
    user_id: '',
    role: '',
    showTerms: false,
    billing_by_partner: false
  },
  refreshList: false,
  list: {},
  layoutList: {},
  currentStep: 1,
  authorized: null,
  currentTheme: '',
  newNotificationCount: 0,
  notificationCount: 0,
  statsChanged: true,
  stats: {
    clientCount: 0,
    propertyCount: 0,
    declarationCount: 0,
    branchCount: 0,
    userCount: 0
  },
  accessLog: {

  }
};
const getters = {
  isModalVisible: (state) => state.showModal,
  getCurrentUser: (state) => state.currentUser,
  getCurrentStep: (state) => state.currentStep,
  getUserList: (state) => state.list,
  listOutdated: (state) => state.refreshList,
  getUserRole: (state) => state.currentUser.role,
  isAuthorized: (state) => state.authorized,
  getLayoutList: (state) => state.layoutList,
  getCurrentTheme: (state) => state.currentTheme,
  getNewNotificationCount: (state) => state.newNotificationCount,
  getNotificationCount: (state) => state.notificationCount,
  getCurrentUserStats: (state) => state.stats,
  isStatsChanged: (state) => state.statsChanged,
  getAccessLog: (state) => state.accessLog
}

const mutations = {
  setCurrentStep: (state, step) => {
    state.currentStep = step
  },
  setAuthorized: (state, val) => {
    state.authorized = val
  },
  setModalVisible: (state, visibility) => {
    state.showModal = visibility
  },
  setCurrentUser: (state, user) => {
    state.currentUser = {...state.currentUser, ...user};
  },
  setNewNotificationCount: (state, count) => {
    state.newNotificationCount = count;
  },
  setNotificationCount: (state, count) => {
    state.notificationCount = count;
  },
  resetCurrentUser: (state) => {
    state.currentUser = {
      salutation: '',
      salutation_lbl: '',
      name: '',
      email: '',
      phone: '',
      lang_name: '',
      lang_code: '',
      company_name: '',
      two_fa: 0,
      street: '',
      zip: '',
      city: '',
      country_uid: 0,
      country_name: '',
      vat_registration_id: '',
      user_id: ''
    };
  },
  setUserList: (state, users) => {
    state.list = users;
  },
  setLayoutList: (state, layouts) => {
    state.layoutList = layouts;
  },
  setCurrentTheme: (state, theme) => {
    state.currentTheme = theme;
  },
  setCurrentUserStats: (state, stats) => {
    state.stats = {...state.stats, ...stats};
  },
  setCurrentUserStatsDataChanged: (state, res) => {
    state.statsChanged = res;
  },
  saveAccessLog: (state, log) => {
    state.accessLog[log.recordType + '-' + log.recordUid] = new Date().getTime();
  },
  resetAccessLog: (state, logKey) => {
    if(state.accessLog[logKey]) {
      delete state.accessLog[logKey]
    }
  }
};

const actions = {};


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
