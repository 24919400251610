<template>
  <div>
    <div class="flex w-full h-screen">
      <div class=" flex justify-center items-center w-1/2 bg-white">
        <div class="flex flex-col items-center w-4/5  justify-center ">
          <img class=" mb-10 mt-20" src="../assets/logo.svg">
          <div class=" px-5 w-4/5">
            <div class="OrdnerText">
              <label class="text-center text ml-10 mr-10 bold-label">{{ $t('settings.datev_devices.recovery.title') }} </label>
              <label class="text-center text ml-10 mt-5 mr-10">{{ $t('settings.datev_devices.recovery.info') }}</label>
            </div>
            <div class="InputTextBox mt-10 px-14">
              <form autocomplete="off" class="w-full" @submit.prevent="submit">
                <InputText v-model="smsCode"
                           :label="$t('settings.datev_devices.recovery.sms_code')"
                           :disabled="verifying_code"
                           :placeholder="$t('settings.datev_devices.recovery.sms_code')"
                           class="mt-2 mb-4"
                />
              </form>
            </div>
            <div class="flex justify-end px-14 mt-4">
              <div class="flex justify-end mt-4 mb-6 space-x-4 ">
                <Button class="btn" @click="goToLogin" secondary :text="$t('buttons.cancel')"></Button>
                <Button class="btn" :text="$t('buttons.next')" @click="submit"/>
              </div>
            </div>
          </div>

        </div>
      </div>
      <img class="w-1/2" src="@/assets/login_illustration.png"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "datevLoginRecovery",
  data() {
    return {
      code: '',
      smsCode: '',
      verifying_code: false
    }
  },
  created() {
    let code = this.$route.query.code;
    if (typeof (code) != 'undefined' && code !== '') {
      this.verifying_code = true;
      this.$api.trigger('datev/verify_recovery_code', {
        'code': code
      }, true).then((response) => {
        if (response.data.success) {
          this.code = code;
          this.verifying_code = false;
        } else {
          this.$api.showToast(response.data.message, "error");
          this.goToLogin();
        }
      })
    } else {
      this.$api.showToast(this.$t('settings.datev_devices.device_link.code_missing'), "error");
      this.goToLogin();
    }
  },
  methods: {
    goToLogin() {
      this.$router.push('/');
    },
    submit() {
      if(this.smsCode !== '') {
        this.$api.trigger('datev/verify_recovery_sms_code', {
          'code': this.code,
          'sms_code': this.smsCode
        }, true).then((response) => {
          if (response.data.success && response.data.oauth_url) {
            window.location.href = response.data.oauth_url;
          } else {
            this.$api.showToast(response.data.message, "error");
          }
        })
      } else {
        this.$api.showToast(this.$t('settings.datev_devices.device_link.password_missing'), "error")
      }
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;

  &__link {
    color: $primary;
    cursor: pointer;
  }
}

.bold-label {
  font-weight: 600 !important;
}

.OrdnerText label {
  display: block;
}

.OrdnerText {
  max-width: 500px;
  margin: 0 auto;
}

.btn ::v-deep .button {
  min-width: 120px;
  padding-left: 10px;
  padding-right: 10px;
 }
</style>
