<template>
  <div class="ml-15 mr-15">
    <label v-show="!hidePassword" class="mb-6">{{ $t('register.step4.header') }}</label>
    <div>
      <div v-if="!hidePassword">
        <InputText class="mt-6" v-model="password" :label="$t('register.step4.password')" :isError="isPasswordError"
                   :errorMessage="passwordError" type="password" :isRequired="true" :showPasswordTemplate="true"
                   :showPasswordViewIcon="true"
                   :placeholder="$t('register.step4.passwordPlaceholder')"/>
        <InputText class="mt-3" v-model="repeatPassword" :isError="isRepeatPasswordError"
                   :showPasswordViewIcon="true"
                   :errorMessage="repeatPasswordError" :label="$t('register.step4.repeatPassword')" type="password"
                   :isRequired="true"
                   :placeholder="$t('register.step4.retypePlaceholder')"/>
        <div class="flex text-left self-start mt-4">
          <label class="pwdtext ">{{ $t('register.step4.accept_terms.info1') }}
            <span class="link cursor-pointer" @click="handleDatenClick">{{
                $t('register.step4.accept_terms.info2')
              }}</span>. {{ $t('register.step4.accept_terms.info3') }} <span
                class="link cursor-pointer" @click="handleAGBClick">{{
                $t('register.step4.accept_terms.info4')
              }}</span>.
            {{ $t('register.step4.accept_terms.info5') }}</label>

        </div>
        <div class="flex text-left self-start mt-4">
          <Checkbox v-model="checked" :binary="true"/>
          <label class="pwdtext ml-2 ">{{ $t('register.step4.acceptance1') }}
            <span class="link cursor-pointer" @click="handleAVVClick">AVV</span> {{ $t('register.step4.und') }} <span
                class="link cursor-pointer" @click="handleVBVClick">
                Verschwiegenheit
              </span> </label>

        </div>
      </div>
      <div v-else class="mt-20">
        <div class="flex text-left self-start mt-4">
          <label class="pwdtext ">{{ $t('register.step4.datev.text1') }}
          </label>
        </div>

        <div class="flex text-left self-start mt-4">
          <label class="pwdtext ">{{ $t('register.step4.accept_terms.info1') }}
            <span class="link cursor-pointer" @click="handleDatenClick">{{
                $t('register.step4.accept_terms.info2')
              }}</span>. {{ $t('register.step4.accept_terms.info3') }} <span
                class="link cursor-pointer" @click="handleAGBClick">{{
                $t('register.step4.accept_terms.info4')
              }}</span>.
            {{ $t('register.step4.accept_terms.info5') }}</label>

        </div>
        <div class="flex text-left self-start mt-4">
          <Checkbox v-model="checked" :binary="true"/>
          <label class="pwdtext ml-2 ">{{ $t('register.step4.acceptance1') }}
            <span class="link cursor-pointer" @click="handleAVVClick">AVV</span> {{ $t('register.step4.und') }} <span
                class="link cursor-pointer" @click="handleVBVClick">
                Verschwiegenheit
              </span> </label>

        </div>
        <!--        <div class="datev-title mb-32 mt-20">-->
        <!--          <p>{{ $t('register.step4.datev_login') }}</p>-->
        <!--          <img src="@/assets/datav.png"/>-->
        <!--        </div>-->
      </div>
      <div class="flex mt-10 mb-10  justify-around space-x-4">
        <Button class="w-full" :text="$t('register.back')" :secondary="true" @click="goBack"/>
        <Button class="w-full" :text="hidePassword?$t('register.next'):$t('register.next')" @click="finish"
                :disabled="isDisabled"/>
      </div>
    </div>
  </div>
</template>

<script>
import {required, sameAs} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import Checkbox from 'primevue/checkbox';

export default {
  name: "Step4",
  components: {Checkbox},
  props: {
    stepConfig: {
      type: Object
    }
  },
  data() {
    return {
      checked: false,
      hidePassword: false,
      password: '',
      repeatPassword: '',
      isPasswordError: false,
      isRepeatPasswordError: false,
      passwordError: '',
      repeatPasswordError: '',
      isDatev: false
    }
  },
  validations: {
    password: {
      required,
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    ...mapGetters("register", ["getUserData"]),
    isDisabled() {
      if (this.isDatev && this.checked) {
        return false;
      } else if (!this.checked) {
        return true
      } else {
        return !this.$isBusiness && this.hidePassword;
      }
    }
  },
  watch: {
    '$store.state.register.userData.tools': {
      deep: false, //true if array
      handler(newValue) {
        this.isDatev = newValue === "datev"
        this.hidePassword = this.isDatev;

      }
    },
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),

    handleAGBClick() {
      if (this.isBusiness()) {
        window.open("https://grundsteuer-digital.de/agb-unternehmen/", "_blank")
      } else if (this.getCurrentTheme() === 'ebnerstolz') {
        window.open("https://ebnerstolz.de/grundsteuer-agb", "_blank")
      } else {
        window.open("https://grundsteuer-digital.de/agb-steuerberater/", "_blank")
      }
    },
    handleAVVClick() {
      if (this.getCurrentTheme() === 'ebnerstolz') {
        window.open("https://ebnerstolz.de/grundsteuer-avv", "_blank")
      } else {
        window.open("https://grundsteuer-digital.de/avv/", "_blank")
      }


    },
    handleVBVClick() {
      window.open("https://grundsteuer-digital.de/vbv/", "_blank")
    },
    handleDatenClick() {
      window.open("https://grundsteuer-digital.de/datenschutz-grundsteuerdigital-anwendung/", "_blank")
    },
    goBack() {
      this.$emit("goBack");
    },
    finish() {
      if (!this.checked) {
        return;
      }
      if (this.isDatev) {
        this.datevLogin();
      } else {
        this.$v.$touch(); // we check the form to see if it's valid
        if (this.$v.$invalid) {
          this.isPasswordError = this.$v.password.$error;
          this.passwordError = this.$v.password.$error ? this.$t('general.required') : "";

          if (!this.$v.repeatPassword.sameAsPassword) {
            this.isRepeatPasswordError = true;
            this.repeatPasswordError = this.$t('register.step4.retypePasswordError');
          }
          return; // if any of the validations fail, we don't move on
        }

        const post_data = {...this.$store.state.register.userData};
        post_data['password'] = this.$api.encrypt(this.password);
        post_data['enable_mfs'] = 1;
        post_data['mfa_mode'] = 'email';

        if (this.$route.query.preOrderSignup && this.$route.query.preOrderSignup !== '') {
          post_data['pre_order_signup_key'] = this.$route.query.preOrderSignup
        }

        let googleClientId = localStorage.getItem('gclid');
        if (googleClientId !== null) {
          post_data['gclid'] = googleClientId;
        }

        this.$api.trigger('signup', post_data, true).then((response) => {
          if (response.data.success) {
            localStorage.setItem('tc_logged_in', '1');
            this.$emit("finish")
          } else {
            this.$api.showToast(response.data.message, "error")
          }
        })
      }
    },
    async datevLogin() {
      let data = {...this.$store.state.register.userData};
      data['oauth_type'] = 'datev';
      if (this.$route.query.preOrderSignup && this.$route.query.preOrderSignup !== '') {
        data['pre_order_signup_key'] = this.$route.query.preOrderSignup;
      }

      let datevData = await this.$api.getDatevLocalParams(true);
      if (datevData) {
        data = {...data, ...datevData};
      }
      this.$api.trigger('datev/oauth', data, true).then((response) => {
        if (response.data.success) {
          if (typeof response.data.oauth_url !== 'undefined' && response.data.oauth_url !== '') {
            window.location.href = response.data.oauth_url;
          }
        } else {
          this.$toast.error(response.data.message)
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.pwdtext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: normal;
  font-size: 15px;

}

.link {
  font-family: 'Segoe UI', sans-serif;
  font-weight: bold;
  color: $primary;
  text-align: left;
  line-height: normal;
  font-size: 15px;
  text-decoration: underline;
}

.datev-title {
  img {
    margin: 20px auto 0 auto;
  }
}
</style>
