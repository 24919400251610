<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.powers_of_attorney.edit_power_of_attorney')" :breadcrumbs="breadcrumbs"/>

    <div class="ml-15 mr-15 mt-10">
      <div class="flex justify-around space-x-8">
        <InputSelect
            class="mt-2 w-full"
            v-model="powerOfAttorneyData.salutation"
            :selected-value="powerOfAttorneyData.salutation"
            :label="$t('settings.powers_of_attorney.table.salutation')"
            :options="salutation_options"
            :isError="errors.salutation.invalid"
            :errorMessage="errors.salutation.errorMessage"
            :isRequired="true"
            :placeholder="$t('general.please_select')"
        />
        <InputText
            class="mt-2 w-full pr-6"
            v-model="powerOfAttorneyData.title"
            :label="$t('settings.powers_of_attorney.table.title')"
            placeholder=""
        />
      </div>
      <div class="flex justify-around space-x-8 mt-6">
        <InputText
          class="w-full"
          :label="$t('settings.powers_of_attorney.table.phone')"
          :maxLength="16"
          v-model="powerOfAttorneyData.phone"
          placeholder=""
        />
        <InputText class="w-full invisible pr-6" label="None"/>
      </div>
      <div class="flex justify-around space-x-8 mt-6">
        <InputText
          class="w-full"
          :label="$t('settings.powers_of_attorney.table.first_name')"
          :isError="errors.firstName.invalid"
          :errorMessage="errors.firstName.errorMessage"
          v-model="powerOfAttorneyData.firstName"
          :isRequired="true"
          placeholder=""
        />
        <InputText
          class="w-full pr-6"
          :label="$t('settings.powers_of_attorney.table.last_name')"
          v-model="powerOfAttorneyData.lastName"
          placeholder=""
        />
      </div>
      <div class="flex justify-around space-x-8 mt-4">
        <InputText
          class="w-full"
          :label="$t('settings.powers_of_attorney.table.street')"
          v-model="powerOfAttorneyData.street"
          placeholder=""
        />
        <InputText
          class="w-full pr-6"
          :label="$t('settings.powers_of_attorney.table.house_number')"
          v-model="powerOfAttorneyData.houseNumber"
          placeholder=""
        />
      </div>

      <div class="flex justify-around space-x-8 mt-4">
        <InputText
          class="w-full"
          :label="$t('settings.powers_of_attorney.table.zip')"
          :isError="errors.zip.invalid"
          :errorMessage="errors.zip.errorMessage"
          v-model="powerOfAttorneyData.zip"
          :isRequired="true"
          placeholder=""
          @blur="searchZip"
          :isOnlyNumber="true"
          :maxLength="5"
        />
        <InputText
          class="w-full pr-6"
          :label="$t('settings.powers_of_attorney.table.city')"
          :isError="errors.city.invalid"
          :errorMessage="errors.city.errorMessage"
          v-model="powerOfAttorneyData.city"
          :isRequired="true"
          placeholder=""
          :loading="zipSearch"
        />
      </div>

      <div class="flex justify-around space-x-8 mt-4">
        <InputSelect
            class="w-full"
            :label="$t('settings.powers_of_attorney.table.country_uid')"
            v-model="powerOfAttorneyData.countryUid"
            :selected-value="powerOfAttorneyData.countryUid"
            :filterable="true"
            :options="countryList"
            :isError="errors.countryUid.invalid"
            :errorMessage="errors.countryUid.errorMessage"
            :isRequired="true"
            :placeholder="$t('general.please_select')"
        />
        <InputText
          class="w-full pr-6"
          :label="$t('settings.powers_of_attorney.table.postbox')"
          v-model="powerOfAttorneyData.postbox"
          placeholder=""
        />
      </div>

      <div class="flex justify-around space-x-10 mt-6">
        <div class="flex flex-col w-full mt-2">
          <InputCheck
              :text="$t('settings.powers_of_attorney.preassigned_as_standard')"
              :isChecked="powerOfAttorneyData.preassignedAsStandard"
              @check="selectPreassignedAsStandard"
          />
        </div>
        <div class="w-full invisible mt-2 pr-6"></div>
      </div>

      <div class="flex justify-around space-x-8 mt-6">
        <div class=" flex flex-col w-full">
          <label class="text mt-2">{{ $t('settings.powers_of_attorney.authorized_recipient') }}<span style="color:red">*</span></label>
          <div class="flex space-x-4 mt-4">
            <div class="flex justify-start items-center">
              <RadioButton type="radio" v-model="powerOfAttorneyData.authorizedRecipient" name="radio-button" value="1"/>
              <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex justify-start items-center ">
              <RadioButton type="radio" v-model="powerOfAttorneyData.authorizedRecipient" name="radio-button" value="0"/>
              <span class="ml-3 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
        <div class="w-full invisible pr-6"></div>
      </div>

      <div class="flex justify-end  mt-6 space-x-4 mr-6 mb-5">
        <Button
          @click="handleBtnClick('cancel')"
          class=" button-label text-center label__text-black "
          :secondary="true"
          :text="$t('buttons.cancel')"
        ></Button>
        <ButtonWithOption
          @click="handleBtnClick"
          class="button-label text-center bg-green text-white"
          primary
          :text="$t('buttons.save')"
          :options="saveButtonOptions"
        ></ButtonWithOption>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {PowersOfAttorneyService} from "../../services/powersofattorney.service";

import InputText from "../inputs/InputText";
import InputSelect from "../inputs/InputSelect";
import RadioButton from 'primevue/radiobutton';

export default {
  name: "editPowerOfAttorney",
  components: {InputSelect, InputText, RadioButton},
  data() {
    return {
      breadcrumbs: [{
        title: this.$t('settings.powers_of_attorney.breadcrumbs.settings'),
        link: ''
      }, {
        title: this.$t('settings.powers_of_attorney.breadcrumbs.powers_of_attorney'),
        link: '/setting/powersofattorney'
      }],
      zipSearch: false,
      powersOfAttorneyService: new PowersOfAttorneyService(),
      powerOfAttorneyData: {
        uid: '0',
        salutation: '0',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        countryUid: '0',
        postbox: '',
        preassignedAsStandard: false,
        authorizedRecipient: '1'
      },

      errors: {
        salutation: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_salutation')
        },
        firstName: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_first_name')
        },
        zip: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_zip')
        },
        city: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_city')
        },
        countryUid: {
          invalid: false,
          errorMessage: this.$t('settings.powers_of_attorney.errors.required_country_uid')
        },
      },

      salutation_options: this.$t('settings.powers_of_attorney.salutation_options'),
      countryList: [],

      saveButtonOptions: [
        {
          icon: require('@/assets/button_edit.svg'),
          label: this.$t('buttons.options.save_continue'),
          handler: 'handleSaveContinue',
          default: true,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_add.svg'),
          label: this.$t('buttons.options.save_new'),
          handler: 'handleSaveNew',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_list.svg'),
          label: this.$t('buttons.options.save_list'),
          handler: 'handleSaveList',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        }
      ]
    }
  },
  beforeMount() {
    this.$api.get_country_list().then((list) => {
      this.countryList = list;
    });
  },
  mounted() {
    this.powersOfAttorneyService.get(this.$route.query.uid)
    .then(powerOfAttorneyData => {
      if (powerOfAttorneyData.uid) {
        this.powerOfAttorneyData = powerOfAttorneyData;
        this.saveAccessLog({
          "recordType": "powersOfAttorney",
          "recordUid": powerOfAttorneyData.uid
        });
      } else {
        this.$router.go(-1);
      }
    });
  },
  computed: {
    ...mapGetters("power_of_attorney", ["getPowerOfAttorneyData"]),
  },
  methods: {
    ...mapMutations("power_of_attorney", ["setPowerOfAttorneyData"]),
    ...mapMutations("user", ["saveAccessLog"]),
    savePowerOfAttorney(btn_key) {
      this.errors.salutation.invalid = false;
      this.errors.firstName.invalid = false;
      this.errors.zip.invalid = false;
      this.errors.city.invalid = false;
      this.errors.countryUid.invalid = false;

      if (this.powerOfAttorneyData.salutation === '' || this.powerOfAttorneyData.salutation === '0') {
        this.errors.salutation.invalid = true;
        return false;
      }
      if (this.powerOfAttorneyData.firstName.trim() === '') {
        this.errors.firstName.invalid = true;
        return false;
      }
      if ((this.powerOfAttorneyData.street.trim() === '' || this.powerOfAttorneyData.houseNumber.trim() === '') && this.powerOfAttorneyData.postbox.trim() === '') {
        this.$api.showToast(this.$t('settings.powers_of_attorney.street_pobox_error'), "error")
        return false;
      }
      if (this.powerOfAttorneyData.zip.trim() === '') {
        this.errors.zip.invalid = true;
        return false;
      }
      if (this.powerOfAttorneyData.city.trim() === '') {
        this.errors.city.invalid = true;
        return false;
      }
      if (this.powerOfAttorneyData.countryUid === '' || this.powerOfAttorneyData.countryUid === '0') {
        this.errors.countryUid.invalid = true;
        return false;
      }

      this.powersOfAttorneyService.save(this.powerOfAttorneyData).then((res) => {
        if(res) {
          this.$api.showToast(this.$t('settings.powers_of_attorney.power_of_attorney_updated_info'))
          if (btn_key === 'saveList') {
            this.$router.push('/setting/powersofattorney');
          } else if (btn_key === 'saveNew') {
            this.$router.push('/powerofattorney/addPowerOfAttorney');
          }
        }
      });
    },
    selectPreassignedAsStandard(isCheck) {
      this.powerOfAttorneyData.preassignedAsStandard = isCheck;
    },
    handleBtnClick(btn_key) {
      if(btn_key === 'cancel') {
        this.$router.push('/setting/powersofattorney');
      } else if(btn_key === 'saveContinue' || btn_key === 'saveNew' || btn_key === 'saveList' || btn_key === 'save') {
        this.savePowerOfAttorney(btn_key);
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.powerOfAttorneyData.city === '' && this.powerOfAttorneyData.zip !== '') {
        this.cityLookup(this.powerOfAttorneyData.zip).then((cities) => {
          if (cities.length > 0) {
            this.powerOfAttorneyData.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

</style>
