const state = {
    list: [],
    listByUser: [],
    clientData: {},
    importData: [],
    currentStep: 1,
    myList: [],
    clientDataChanged: false
};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getClientData: (state) => state.clientData,
    getClientList: (state) => state.list,
    getMyClientList: (state) => state.myList,
    getImportData: (state) => state.importData,
    getClientDataChanged: (state) => state.clientDataChanged
}

const mutations = {
    init: (state) => {
      state.currentStep = 1;
      state.clientData = {
          branch: '',
          assignee: '0',
          clientType: 'private',
          private: {
              client_id: '',
              title: '',
              salutation: '',
              first_name: '',
              last_name: '',
              tax_number: '',
              client_number: '',
              dob: ''
          },
          business: {
              client_id: '',
              legal_form: '',
              salutation: '',
              company_name: '',
              vat_number_wot_code: '',
              vat_country_code: 'DE',
              tax_number: ''
          },
          contact: {
              street: '',
              house_number: '',
              po_box: '',
              postcode: '',
              city: '',
              country: '',
              email: '',
              mobile: '',
              phone: '',
              fax: ''
          },
          legal: {
              salutation: '',
              title: '',
              dob: '',
              first_name: '',
              last_name: '',
              street: '',
              house_number: '',
              zip: '',
              city: '',
              country_uid: '',
              postbox: '',
              phone: ''
          },
          client_portal_access: true,
          use_datev_login: false,
          invite: false
      }
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setClientData: (state, client) => {
        state.clientData = {...state.clientData, ...client};
    },
    resetClientList: (state) => {
        state.list = [];
        state.myList = [];
        state.listByUser = [];
    },
    setClientList: (state, clients) => {
        state.list = clients;
    },
    setMyClientList: (state, clients) => {
        state.myList = clients;
    },
    setImportData: (state, rows) => {
        state.importData = rows.filter((x) => {
            return typeof x !== 'undefined' && x.length > 0;
        });
    },
    setClientDataChanged: (state, payload) => {
        state.clientDataChanged = payload
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



