<template>
  <div class="ml-15 mr-15">
    <div class="mt-20 flex flex-col container h-150">
      <div class="flex flex-col w-full loading-container">
        <div class="spin"></div>
      </div>
    </div>
    <div class="flex flex-col w-full h-full">
      <p class="loading-text">{{ $t('clients.datev_import.steps.step2.loading') }}</p>
    </div>
    <div class="h-full bg-white pl-7 w-full ">
      <ModalDialog v-show="isDatevErrorModalVisible" :headerText="$t('clients.datev_import.dialog.error_text_header')"
                   @close="handleDatevErrorModalClose">
        <template v-slot:body>
          <div class="flex flex-col justify-start ">
            <label class=" label text-left  mt-4">
              {{ datevImpoertErrorText }}
              <div class="mt-1" v-if="datevImpoertErrorDocumentPresent">
                {{ $t('clients.datev_import.dialog.error_datev_help_document1') }}<a :href="datevImpoertErrorDocument"
                                                                                     target="_blank">{{ $t('clients.datev_import.dialog.error_datev_help_document2') }}</a>{{ $t('clients.datev_import.dialog.error_datev_help_document3') }}
              </div>
            </label>
            <div class="flex mt-20 mb-4   justify-end space-x-4">
              <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="handleDatevErrorModalClose"/>
            </div>
          </div>
        </template>
      </ModalDialog>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Step2",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async active(nv) {
      if (nv) {
        this.init();
        this.$api.get_datev_data_environments().then((response) => {
          if (response.oauth_url) {
            window.location.href = response.oauth_url;
          } else if (response.environments) {
            this.saveDataEnvironments(response.environments);
            this.$nextTick(() => {
              this.$emit("goToNextStep");
            })
          } else {
            this.goBack();
          }
        })
      }
    }
  },
  data() {
    return {
      isDatevErrorModalVisible: false,
      datevImpoertErrorText: '',
      datevImpoertErrorDocument: '',
      datevImpoertErrorDocumentPresent: false
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("datev_import", ["init", "saveDataEnvironments"]),
    handleDatevErrorModalClose() {
      this.isDatevErrorModalVisible = false;
      this.$router.push('/datev/setup_branches');
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.loading-text {
  color: #9d9d9d;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.label {

  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}
</style>
