<template>
    <div class="h-full bg-white pl-7 w-full ">
        <PageHeader :title="$t('settings.branches.add_branch')" :breadcrumbs="breadcrumbs"/>

        <div class="flex flex-col justify-center items-center">
            <div class="w-full mr-14 ">
                <FormSteps :steps="steps"/>
            </div>
            <div class=" w-full">


                <div class="mt-10" v-if="completed===false">
                    <component
                        v-for="(item, index) in steps"
                        :key="`component-${index}`"
                        :is="item.component"
                        @goToNextStep="goToNextStep"
                        @goBack="goBack"
                        @finish="finish"

                        v-show="item.active === true"
                    />
                </div>
                <div class="mt-10 mr-10 ml-10" v-else>
                    <Finish @finishButtonClick="handleFinishButtonClick"/>
                </div>
            </div>
        </div>

        <div class="w-full mt-8 mr-8">


        </div>


    </div>
</template>

<script>
import {userRoles, userStatus} from "@/core/constants";
import FormSteps from "../FormSteps";
import Step1 from '@/components/settings/branch_steps/Step1.vue';
import Step2 from '@/components/settings/branch_steps/Step2.vue';
import Finish from '@/components/settings/branch_steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "add",
    components: {FormSteps, Step1, Step2, Finish},
    metaInfo() {
        return {
            title: this.getTitle,
        }
    },

    data() {
        return {
            breadcrumbs: [{
                title: this.$t('settings.branches.breadcrumbs.settings'),
                link: ''
            }, {
                title: this.$t('settings.branches.menu.branches'),
                link: this.$isBusiness ? '/setting/departments' : '/setting/branches'
            },
                {
                    title: this.$t('settings.branches.add_branch'),
                    link: ''
                }
            ],
            roleOptions: userRoles,
            statusOptions: userStatus,
            completed: false,
            steps: [
                {
                    id: 1,
                    title: this.$t('settings.branches.tabs.tab1'),
                    completed: false,
                    active: true,
                    first: true,
                    component: "Step1"
                },
                {
                    id: 2,
                    title: this.$t('settings.branches.tabs.tab3'),
                    completed: false,
                    active: false,
                    last: true,
                    component: "Step2"
                }
            ]
        }
    },
    computed: {
        ...mapGetters("branch", ["getCurrentStep"]),
        getTitle() {
            return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : this.$t('settings.branches.title')
        },
    },
    methods: {
        ...mapMutations("branch", ["setCurrentStep"]),
        ...mapGetters("user", ["getCurrentTheme"]),
        getTheme() {
            return this.getCurrentTheme()
        },
        goToNextStep() {
            this.moveStep('next')
        },
        goBack() {
            this.moveStep('back')
        },
        finish() {
            this.moveStep('next', true)
            this.completed = true
        },
        changeWindowSize() {
            // return i18n.locales = "es";
            window.ipcRenderer.send('resizeRegister')
        },
        moveStep(direction, isLast) {
            if (isLast) {
                this.steps = this.steps.map(x => {
                    return {...x, completed: true, active: false}
                })
            } else {
                let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
                let index = this.steps.findIndex(item => item.id === step)
                this.steps = this.steps.map((x, i) => {
                    if (i === index) {
                        return {...x, completed: false, active: true}
                    } else if (i > index) {
                        return {...x, completed: false, active: false}
                    } else if (i < index) {
                        return {...x, completed: true, active: false}
                    }

                })

                this.setCurrentStep(step)
            }

        },
        handleFinishButtonClick() {
            this.$router.go(-1)
            this.$emit("click", "branch_add_finish")
        }
    }
}
</script>

<style scoped lang="scss">

.label {
    width: 200px;
    @include segoe-regular;

    &__link {
        color: $primary;
    }

    &__text-black {
        color: black;
    }
}

</style>
