import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=49c339f0&scoped=true"
import script from "./Step4.vue?vue&type=script&lang=js"
export * from "./Step4.vue?vue&type=script&lang=js"
import style0 from "./Step4.vue?vue&type=style&index=0&id=49c339f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c339f0",
  null
  
)

export default component.exports