<template>
  <div class="">
    <div class="mt-18 flex flex-col">
      <div
        class="flex justify-between space-x-8"
        v-show="!inviteUser"
      >
        <InputText
          class="w-full"
          v-model="password"
          :showPasswordViewIcon="true"
          :showPasswordTemplate="true"
          :label="$t('settings.user_management.attrs.password')"
          :isError="isPasswordError"
          :errorMessage="passwordError"
          type="password"
          :isRequired="true"
          :placeholder="$t('settings.user_management.attrs.password')"
        />
        <InputText
          class="w-full"
          v-model="repeatPassword"
          :showPasswordViewIcon="true"
          :label="$t('settings.user_management.attrs.confirm_password')"
          :isError="isRepeatPasswordError"
          :errorMessage="repeatPasswordError"
          type="password"
          :isRequired="true"
          :placeholder="$t('settings.user_management.attrs.confirm_password')"
        />
      </div>

      <div class="flex flex-col justify-start items-start mt-4">
        <InputCheck
          class="w-64"
          :text="$t('settings.user_management.invite_users_by_email')"
          :isChecked="invite"
          :checkable="!datevAccount"
          @check="clicked_invite"
        />

        <div
          v-show="inviteUser"
          class="w-full flex flex-col justify-start items-start"
        >
          <div class="mt-4">
            <label class="text_bold">{{ $t('settings.user_management.attrs.email') }}: {{ userEmail }}</label>
          </div>

          <div class="mt-4 w-full">
            <InputText
              bodyClass="w-full non-resize-textarea h-72"
              class="invite_email_body"
              :label="$t('settings.user_management.personal_message')"
              v-model="inviteEmailBody"
              :isMultiline="true"
              :rows="20"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end  mt-6 space-x-4 ">
        <Button
          class="w-56"
          :text="$t('buttons.back')"
          :secondary="true"
          @click="goBack"
        />
        <Button
          class="w-56"
          :text="$t('buttons.next')"
          @click="submitNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {requiredIf, sameAs} from "vuelidate/lib/validators";
import {UserService} from "../../../services/user.service";

export default {
  name: "Step3",
  data() {
    return {
      invite: this.$store.state.sub_user.userData.invite,
      password: '',
      repeatPassword: '',
      isPasswordError: false,
      isRepeatPasswordError: false,
      passwordError: '',
      repeatPasswordError: '',
      inviteUser: false,
      inviteEmailBody: '',
      email_template: 'user_invitation'
    }
  },
  validations: {
    password: {
      required: requiredIf(function () {
        return !this.invite;
      })
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    ...mapGetters("sub_user", ["getUserData"]),
    ...mapGetters("email", ["getTemplates"]),
    userEmail() {
      return this.getUserData.email;
    },
    datevAccount() {
      return this.getCurrentUser().settings.tax_office_software === 'datev';
    }
  },
  watch: {
    '$store.state.sub_user.userData': {
      handler: function () {
        let salutation_obj = this.$t('general.salutation').filter((o) => {
          return o.code === this.getUserData.salutation;
        })

        if(this.getTemplates[this.email_template]) {
          this.inviteEmailBody = this.getTemplates[this.email_template]
              .replace('%salutation%', salutation_obj.length > 0 ? salutation_obj[0].name : '')
              .replace('%name%', this.getUserData.name)
              .replace('%sender_name%', this.getCurrentUser().name)
              .replace(/<br>/g, "");
        }
      },
      deep: true
    },
    inviteUser(checked) {
      if (checked) {
        this.password = '';
        this.repeatPassword = '';
      }
    }
  },
  mounted() {
    if(this.datevAccount) {
      this.invite = true;
      this.inviteUser = true;
      this.email_template = 'datev_user_invitation';
    } else {
      this.email_template = 'user_invitation';
    }
    this.$api.getEmailTemplate(this.email_template).then((content) => {
      this.inviteEmailBody = content;
    })
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    async submitNext() {
      this.isPasswordError = false;
      this.isRepeatPasswordError = false;

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isPasswordError = this.$v.password.$error
        this.passwordError = this.$v.password.$error ? this.$t('register.step4.passwordError') : ""

        if (!this.$v.repeatPassword.sameAsPassword) {
          this.isRepeatPasswordError = true
          this.repeatPasswordError = this.$t('register.step4.retypePasswordError')
        }

        return; // if any of the validations fail, we don't move on
      }

      const user = new UserService();
      let post_data = this.getUserData;
      post_data['password'] = this.$api.encrypt(this.password);
      post_data['confirm_password'] = this.$api.encrypt(this.repeatPassword);
      post_data['invite'] = this.invite ? 1 : 0;
      post_data['invite_email'] = this.invite ? this.inviteEmailBody : '';
      user.save(post_data).then((response) => {
        if(response.data.success) {
            this.$emit("finish");
        } else if(response.data.error_key && ['tooManyActiveUsers', 'packageLimitReached'].includes(response.data.error_key)) {
          this.$modal.show({
            title: '',
            text: this.$t('settings.user_management.package_limit_reached'),
            showClose: false,
            canEscape: true,
            showCancel: false,
            canCloseOutsideClick: false,
            buttons: {
              'ok': 'OK',
              'delete': false
            },
            onConfirm: () => {
              if(this.getCurrentUser().account_pay_type === 'trial') {
                this.$router.push('/setting/billingTrial');
              } else {
                this.$router.push({
                  name: "Setting",
                  params: {
                    'comp': 'billing',
                    'open_plan_subscription': true
                  }
                });
              }
            }
          });
        } else {
          this.$api.showToast(response.data.message, 'error');
        }
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    clicked_invite(checked) {
      if(!this.datevAccount) {
        this.invite = checked;
        this.inviteUser = checked;
      }
    }
  }
}
</script>

<style scoped lang="scss">

.table_flex {
  display: flex;
  margin: 0 -15px;
}

.inp_holder input {
  height: 32px;
  border: 1px solid;
}


.tb_50 {
  padding: 0 15px;
  max-width: 50%;
  width: 100%;
}

.inp_holder img {
  position: absolute;
  left: 23px;
  top: 16px;
  transform: rotate(
          90deg);
}

.table_50 tr td:nth-child(even) img {
  display: block;
  margin: 0 auto;
}

.inp_holder input {
  padding-left: 25px;
}

.table_50 td, .table_50 th {
  font-size: 15px;
  padding: 12px;
}

.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.table_50 img {
  max-width: 20px;
}

.table_50 {
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.icon-table {
  width: 22px;
  height: 22px;
  box-sizing: border-box;
}

th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.rectangle {
  width: 648px;
  height: 575px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 1px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.input {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-top: -15px;
  margin-left: 25px;
  font-size: 13px;
}

.icon-table-cross {
  width: 15px;
  height: 15px;
}
</style>
