import {ApiService} from "./api.service";

export class CommunityService extends ApiService {

    constructor() {
        super();
    }

    get_communities({federalStateUid, community, communityUid}) {
        return this.trigger('community/get_communities', {
            federalStateUid, community, communityUid
        }).then((response) => {
            if (response.data.success) {
                return {
                    'error': false,
                    'data': response.data
                };
            } else {
                this.showToast(response.data.message, 'error');

                return {
                    'error': true,
                    'data': response.data.errorKey || response.data.error_key
                };
            }
        })
    }
}
