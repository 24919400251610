<template>
  <MainLayout value="datevDataUpdate">
    <template>
      <div class="h-full bg-white pl-7 w-full ">
        <PageHeader :title="$t('settings.datev_data_fix.title')" :logo="require('@/assets/datev_logo.png')"/>
        <ModalDialog v-show="isDatevErrorModalVisible" :headerText="$t('clients.datev_import.dialog.error_text_header')"
                     @close="handleDatevErrorModalClose">
          <template v-slot:body>
            <div class="flex flex-col justify-start ">
              <label class=" label text-left  mt-4">
                {{ datevImpoertErrorText }}
                <div class="mt-1" v-if="datevImpoertErrorDocumentPresent">
                  {{ $t('clients.datev_import.dialog.error_datev_help_document1') }}<a :href="datevImpoertErrorDocument"
                                                                                       target="_blank">{{ $t('clients.datev_import.dialog.error_datev_help_document2') }}</a>{{ $t('clients.datev_import.dialog.error_datev_help_document3') }}
                </div>
              </label>
              <div class="flex mt-20 mb-4   justify-end space-x-4">
                <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="handleDatevErrorModalClose"/>
              </div>
            </div>
          </template>
        </ModalDialog>

        <div class="flex flex-col justify-center">
          <div class=" mr-6 mt-10">
            <div class="box flex justify-start mb-6 w-full pt-5 pb-5 pr-5">
              <div class="w-full">
                <p class="text ml-2 w-full">{{ $t('settings.datev_data_fix.info1') }}</p>
                <p class="text ml-2 w-full mt-5">{{ $t('settings.datev_data_fix.info2') }}</p>
              </div>
            </div>
          </div>

          <div class="flex justify-around space-x-10 mt-2 "/>
          <div class="flex justify-end space-x-4 mt-6 mr-6 mt-10">
            <Button class="w-40" :text="$t('settings.datev_data_fix.button')" @click="updateDatevNow"/>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import {ClientService} from "@/services/client.service";
import {S3Service} from "@/services/s3.service";
import _ceil from "lodash/ceil";
import {mapGetters} from "vuex";

export default {
  name: "datevDataUpdate",
  data() {
    return {
      service: new ClientService(),
      s3Service: new S3Service(),
      importKey: '',
      offset: 0,
      selectedClients: [],
      totalClientFound: 0,
      clientCount: 0,
      detailsFetched: 0,
      isDatevErrorModalVisible: false,
      datevImpoertErrorText: '',
      datevImpoertErrorDocument: '',
      datevImpoertErrorDocumentPresent: false,
    }
  },
  mounted() {
    if (this.$route.params.oauth && this.$route.params.oauth === 'completed') {
      this.updateDatevNow();
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    updateDatevNow() {
      this.$loading.show();
      let currentTs = new Date().getTime();
      this.importKey = this.getCurrentUser().account_uid + '-' + this.getCurrentUser().id + '-' + currentTs;
      this.$api.trigger('datev/org_info', {
        "datevDataFix": 1
      }).then((response) => {
        if (response.data.success) {
          if (response.data.oauth_url) {
            window.location.href = response.data.oauth_url;
          } else {
            this.service.get_saved_datev_ids(this.importKey).then(() => {
              this.fetchDatevClient();
            })
          }
        } else {
          this.showToast(response.data.message, "error")
        }
      });
    },
    fetchDatevClient() {
      this.service.fetch_datev_clients({
        'datev_data_fix': 1,
        'importKey': this.importKey,
      }, this.offset).then((response) => {
            if (response.data.success) {
              if (response.data.clients) {
                response.data.clients = this.service.parse_object_data(response.data.clients);
                this.totalClientFound = response.data.total_count;
                this.offset += response.data.record_count;
                this.clientCount += response.data.clients.length;
                if (response.data.clients.length > 0) {
                  this.saveClients(response.data.clients)
                }

                if (this.totalClientFound > this.offset) {
                  this.fetchDatevClient();
                } else {
                  this.service.trigger('client/datev_clients_fetched', {
                    "importKey": this.importKey
                  });

                  if (this.selectedClients.length > 0) {
                    this.$loading.show();
                    let selectedClients = [];
                    for(let datevClient of this.selectedClients) {
                      selectedClients.push({
                        "id": datevClient.id,
                        "existing_etag": datevClient.existing_etag
                      });
                    }

                    let s3Data = {};
                    s3Data['selectedClients'] = selectedClients;
                    this.s3Service.upload({
                      data: s3Data,
                      fileName: 'selected_client.json'
                    }).then((res) => {
                      if(res.success) {
                        this.service.start_datev_client_import({
                          "s3Path": res.s3Path,
                          'datevDataFix': 1
                        }).then((response) => {
                          this.$loading.hide();
                          if(response.data.success) {
                            this.$loading.hide();
                            let estimatedTime = _ceil(selectedClients.length / 160) + 1;
                            this.$api.showToast(this.$t('clients.datev_import.steps.finish.info', {"count": selectedClients.length, "mins": estimatedTime}))
                          } else {
                            this.$api.showToast(response.data.message, "error")
                          }
                          this.$router.push('/client/list');
                        });
                      } else {
                        this.$api.showToast(this.$t('general.errors.unknownError'), "error")
                      }
                    })
                  } else {
                    this.$api.showToast(this.$t('clients.datev_import.steps.step4.no_clients_selected'), "error");
                    this.$router.push('/client/list');
                    this.$loading.hide();
                  }
                }
              } else {
                this.$api.showToast(this.$t('general.errors.unknownError'), "error")
                this.$loading.hide();
              }
            } else {
              this.$loading.hide();
              this.$api.showToast(response.data.message, 'error');
              if (response.data.error_key === 'nodataenvironments') {
                this.isDatevErrorModalVisible = true;
                this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_data_environment');
                if (response.data.document != '') {
                  this.datevImpoertErrorDocument = response.data.document;
                  this.datevImpoertErrorDocumentPresent = true;
                }
              }
              if (response.data.error_key == 'nouserpermissions') {
                this.isDatevErrorModalVisible = true;
                this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_user_permissions');
                if (response.data.document != '') {
                  this.datevImpoertErrorDocument = '<a :href="' + response.data.document + '" target="_blank">hier</a>';
                  this.datevImpoertErrorDocumentPresent = true;
                }
              }
              if (response.data.error_key == 'noappgrants') {
                this.isDatevErrorModalVisible = true;
                this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_app_grants');
                if (response.data.document != '') {
                  this.datevImpoertErrorDocument = response.data.document;
                  this.datevImpoertErrorDocumentPresent = true;
                }
              }
              if (!response.data.error_key) {
                this.$api.showToast(response.data.message, 'error');
                this.$router.push('/client/list');
              }
            }
          }
      )
    },
    handleDatevErrorModalClose() {
      this.isDatevErrorModalVisible = false;
      this.$router.push('/client/list');
    },
    saveClients(clients) {
      for (let k = 0; k < clients.length; k++) {
        this.selectedClients.push(clients[k]);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: auto;
}
</style>
