const state = {
    uploadResult: {},
};
const getters = {
    getUploadResult: (state) => state.uploadResult,
}

const mutations = {
    setUploadResult: (state, uploadResult) => {
        state.uploadResult = uploadResult;
    },
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



