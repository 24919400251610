<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{ flex: '1' }"
  >
    <template #header>
      <h3 class="text-2xl font-bold text-green">
        {{ getTextForSolution('title') }}
      </h3>
    </template>

    <div class="flex">
      <div class="flex-none w-2/3 text-left text-base">
        <p
          class="mb-4"
          v-html="getTextForSolution('intro')"
        />

        <p class="text-green font-bold">{{ getTextForSolution('deadlines_title') }}</p>
        <p
          class="mb-4"
          v-html="getTextForSolution('deadline_info_by_hh_ni')"
        />
        <p
          class="mb-4"
          v-html="getTextForSolution('deadline_info_bund')"
        />
        <p
          class="mb-4"
          v-html="getTextForSolution('deadline_info_bw_he')"
        />

        <p class="text-green font-bold">{{ getTextForSolution('obligations_title') }}</p>
        <p
          class="mb-4"
          v-html="getTextForSolution('hint_obligation')"
        />

        <p
          class="mb-4"
          v-html="getTextForSolution('hint_change_management')"
        />

        <p
          class="mb-4"
          v-html="getTextForSolution('deadline_extension')"
        />

        <p
          class="mb-4"
          v-html="getTextForSolution('blog_post')"
        />
      </div>

      <div class="flex-none w-1/3 text-blue">
        <h4 class="text-lg font-bold mb-6">
          {{ getTextForSolution('tutorials_title') }}
        </h4>

        <h5 class="text-base font-bold mb-4">
          {{ getTextForSolution('tutorials.property_change_request.title') }}
        </h5>

        <a
          :href="getTextForSolution('tutorials.property_change_request.help_link_url')"
          class="tutorial-button mr-2"
          target="_blank"
        >
          {{ getTextForSolution('tutorials.property_change_request.help_link_text') }}
        </a>

        <a
          :href="getTextForSolution('tutorials.property_change_request.video_link_url')"
          class="tutorial-button"
          target="_blank"
        >
          {{ getTextForSolution('tutorials.property_change_request.video_link_text') }}
        </a>

        <h5 class="text-base font-bold mt-8 mb-4">
          {{ getTextForSolution('tutorials.change_management.title') }}
        </h5>

        <a
          :href="getTextForSolution('tutorials.change_management.help_link_url')"
          class="tutorial-button mr-2"
          target="_blank"
        >
          {{ getTextForSolution('tutorials.change_management.help_link_text') }}
        </a>

        <a
          :href="getTextForSolution('tutorials.change_management.video_link_url')"
          class="tutorial-button"
          target="_blank"
        >
          {{ getTextForSolution('tutorials.change_management.video_link_text') }}
        </a>

        <div>
          <img
            class="w-full m-auto mt-10"
            :src="getAssetPath('property-tax-change-notice-deadlines.svg')"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
  name: 'PropertyTaxChangeNoticeModal',
  emits: ['close'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
  },
  methods: {
    getTextForSolution(translationKey) {
      const solution = this.$isBusiness ? 'business' : 'tax_consultant';

      return this.$t('dashboard.property_tax_change_notice.' + solution + '.' + translationKey);
    }
  }
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: 95vh;
  max-height: 95vh;
  max-width: 1240px;
  width: 95vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;

    .tutorial-button {
      @apply border border-solid rounded border-blue px-4 py-2 text-blue;

      &:hover {
        text-decoration: none;
        background: theme('colors.blue');
        color: theme('colors.white');
      }
    }
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
