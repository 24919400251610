<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('declarations.pending_elster.title')"/>
    <div class="flex flex-col justify-center items-center" v-if="!rendering">
      <div class="w-full">
        <div class="mt-10 mr-6 text-left" v-if="pendingElsterCount > 0">
          <div class="flex flex-col space-y-3 mt-5">
            <label class="w-full">{{ $t('declarations.pending_elster.info1', {"count": pendingElsterCount}) }}</label>
            <label>{{ $t('declarations.pending_elster.info2') }} <span class="cursor-pointer text-green" @click="goToDelcarationList">{{ $t('declarations.pending_elster.here') }}</span>.</label>
            <label class="w-full">{{ $t('declarations.pending_elster.info3') }}</label>
          </div>
          <div class="flex justify-end space-x-6 mt-20 mr-3">
            <Button class="w-auto" :text="$t('declarations.pending_elster.activate_transmission')" @click="activateTransmission"/>
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="backToDashboard"/>
          </div>
        </div>
        <div class="mt-10 mr-6 text-left" v-else>
          <div class="flex flex-col space-y-3 mt-5">
            <label class="w-full">{{ $t('declarations.pending_elster.info4') }}</label>
          </div>
          <div class="flex justify-end space-x-6 mt-20 mr-3">
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="backToDashboard"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {UserService} from "@/services/user.service";
import {mapGetters} from "vuex";
import {DeclarationService} from "@/services/declaration.service";

export default {
  name: "PendingElster",
  data() {
    return {
      userService: new UserService(),
      service: new DeclarationService(),
      rendering: true,
      pendingElsterCount: 0
    }
  },
  computed: {
    hasElsterPermission() {
      return this.getCurrentUser().permissions.elster_transaction && this.getCurrentUser().permissions.elster_transaction === '1' ? this.getCurrentUser().permissions.elster_transaction : 0;
    }
  },
  watch: {
    '$route'(to) {
      if (typeof this.notification.prim_uid === 'undefined' || (this.notification.prim_uid && this.notification.prim_uid !== to.query.uid)) {
        this.fetchNotification();
      }
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    backToDashboard() {
      this.$router.push('/home');
    },
    goToDelcarationList() {
      this.$router.push('/declaration/elstertransmisstion');
    },
    activateTransmission() {
      if(this.hasElsterPermission) {
        this.service.activateAllPendingElsterTransmission().then((resp) => {
          if(resp.data.success) {
            this.$router.push('/declaration/elstertransmisstion');
          } else {
            this.$api.showToast(resp.data.message, "error");
          }
        })
      } else {
        this.$api.showToast(this.$t('declarations.detail.elster_auto_submission.permission_error'), "error");
      }
    },
    fetchNotification() {
      this.rendering = true;
      this.userService.getNotification(this.$route.query.uid).then((response) => {
        if (response.data.success && response.data.notification && response.data.notification.prim_uid) {
          this.rendering = false;
          if(response.data.notification.pendingElsterCount) {
            this.pendingElsterCount = parseInt(response.data.notification.pendingElsterCount);
          }
        } else {
          this.$api.showToast(response.data.message, "error");
          this.backToDashboard()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
