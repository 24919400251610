<template>
  <div class="ml-15 mr-15">
    <label class="mb-20">{{ $t('register.step1.header') }}</label>
    <div>
      <InputSelect v-if="$isBusiness" class="w-full mt-8" :label="$t('settings.account.fields.salutation')"
                   v-model="salutation"
                   :options="$t('general.salutation')"
                   :isRequired="true"
                   :is-error="isSalutationError"
                   :error-message="$t('general.errors.required')"
                   placeholder=""/>
      <InputText class="mt-3" v-model="step1.name" :isError="step1.name===''?isNameError:false"
                 :errorMessage="step1.name===''?nameError:''" :requestFocus="true"
                 :label="$t('register.step1.name')"
                 :isRequired="true" :placeholder="$t('register.step1.namePlaceholder')" @keyup="submitNext"/>
      <InputText class="mt-3" v-model="step1.email" :isError="isEmailError" :errorMessage="emailError"
                 :label="$t('register.step1.email')"
                 type="email" :isRequired="true"
                 @keyup="submitNext"
                 :placeholder="$t('register.step1.emailPlaceholder')"/>
      <InputText class="mt-3"
                 v-model="step1.phone"
                 :label="$t('register.step1.phone')"
                 :maxLength="16"
                 type="tel"
                 :placeholder="$t('register.step1.phonePlaceholder')"
                 @keyup="submitNext"/>
      <div class="flex mt-10 mb-10  justify-around space-x-4">
        <Button class="w-full" :text="$t('register.back')" @click="goBack" :secondary="true"/>
        <Button class="w-full" :text="$t('register.next')" @click="submitNext"/>
      </div>
    </div>
  </div>


</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "Step1",
  props: {
    stepConfig: {
      type: Object
    }
  },
  data() {
    return {
      step1: {
        name: '',
        email: '',
        phone: ''
      },
      salutation: '',
      nameError: '',
      emailError: '',
      phoneError: '',
      isNameError: false,
      isEmailError: false,
      isPhoneError: false,
      isSalutationError: false
    }
  },
  validations: {
    step1: {
      name: {
        required
      },
      email: {
        required, email
      }
    },

  },
  computed: {
    ...mapGetters("register", ["getUserData"]),
  },
  mounted() {
    this.step1.name = this.getUserData.name
    this.step1.email = this.getUserData.email
    this.step1.phone = this.getUserData.phone
  },
  methods: {
    ...mapMutations("register", ["setFirstStepData"]),
    submitNext() {
      this.isSalutationError = false;

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isNameError = this.$v.step1.name.$error
        this.nameError = this.$v.step1.name.$error ? this.$t('general.required') : ""//this.$t('register.step1.nameError')
        this.isEmailError = this.$v.step1.email.$error
        this.emailError = this.$v.step1.email.$error ? this.$t('general.required') : "" //this.$t('register.step1.emailError')

        return; // if any of the validations fail, we don't move on
      }

      if (this.$isBusiness) {
        if (this.salutation.trim() === '') {
          this.isSalutationError = true;
          return;
        } else {
          this.step1.salutation = this.salutation;
        }
      }

      this.setFirstStepData(this.step1);
      this.$emit("goToNextStep", this.step1);
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

</style>
