<template>
  <div>
    <ModalDialog v-show="showEmailPreview"
                 :headerText="$t('components.change_management_data_query.title')"
                 @close="handleModalClose"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="w-full">
            <div class="info flex justify-start items-center">
              <img class="info__icon" src="@/assets/info_gray.svg"/>
              <label class="info__text" v-html="$t('components.change_management_data_query.process.email_send_info')"/>
            </div>
          </div>
          <div class="w-full">
            <InputText bodyClass="w-full"
                       v-model="emailBody"
                       :isMultiline="true"
                       :rows="22"
            />
          </div>
          <div class="flex justify-end w-full">
            <div class="flex justify-end space-x-4 mb-6">
              <Button class="w-44"
                      @click="handleModalClose"
                      :text="$t('buttons.cancel')"
                      :secondary="true"
              />
              <Button class="w-56"
                      :text="$t('components.change_management_data_query.process.email_send')"
                      @click="sendEmail()"
              />
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('components.change_management_data_query.process.steps.process_type') }}:</label>
          <label class="ml-5">{{ processType }}</label>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex w-full">
          <label class="text ">{{ $t('components.change_management_data_query.process.selected_properties_list') }}</label>
        </div>
        <div class="flex justify-around space-x-1 mt-2">
          <DataGrid grid-id="grid-selected-properties"
                  :local-data="selectedProperties"
                  :columns="columns"
                  :pagination="false"
                  default-sort-attr="name"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
        />
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="flex justify-end space-x-4 mr-6">
        <Button class="w-56"
                :text="$t('buttons.back')"
                :secondary="true"
                @click="goBack"
        />
        <Button class="w-56"
                :text="$t('components.change_management_data_query.process.email_test')"
                @click="sendEmail(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import {UserService} from "@/services/user.service";
import {ClientService} from "@/services/client.service";
import {PropertyService} from "@/services/property.service";

export default {
  name: "Step2",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fromGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new PropertyService(),
      selectedProperties: [],
      clientId: 0,
      selectedYear: '',
      columns: [
        {
          field: 'propertyId',
          header: this.$t('components.change_management_data_query.grid.id'),
          isChecked: true,
          sortable: false,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        }, {
          field: 'name',
          header: this.$t('components.change_management_data_query.grid.property'),
          isChecked: true,
          sortable: false
        }
      ],
      usersCanRelease: [],
      emailTemplate: 'query_clients_to_change_property',
      emailBody: '',
      showEmailPreview: false,
      emailPreview: {
        to: '',
        from: '',
        subject: '',
        message: ''
      }
    }
  },
  watch: {
    active(newValue) {
      if (newValue) {
        if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
          this.selectedProperties = JSON.parse(JSON.stringify(this.getSteps().selectedProperties));
          this.triggerReload('grid-selected-properties');
        }

        if (this.getSteps().clientId) {
          this.clientId = this.getSteps().clientId;
        }

        if (this.getSteps().selectedYear) {
          this.selectedYear = this.getSteps().selectedYear;
        }
      }
    }
  },
  computed: {
    processType() {
      return this.$t('components.change_management_data_query.process.title1');
    },
    processTypeVal() {
      return this.getSteps().processType ? this.getSteps().processType : '';
    }
  },
  mounted() {
    if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
      this.selectedProperties = JSON.parse(JSON.stringify(this.getSteps().selectedProperties));
      this.triggerReload('grid-selected-properties');
    }

    if (this.getSteps().clientId) {
      this.clientId = this.getSteps().clientId;
    }

    if (this.getSteps().selectedYear) {
      this.selectedYear = this.getSteps().selectedYear;
    }

    if (this.fromGrid) {
      let userService = new UserService();
      userService.listAll().then((list) => {
        this.usersCanRelease = list.filter((o) => {
          return o.permissions.release_properties && o.permissions.release_properties === '1';
        }).map((item) => {
          return {
            name: item.name,
            code: item.id
          };
        });
      });
    }
  },
  methods: {
    ...mapGetters('data_query_bulk_add', ['getSteps']),
    ...mapMutations('grid', ['triggerReload']),
    ...mapMutations('data_query_bulk_add', ['saveSteps']),
    submitNext() {
      if (this.fromGrid && this.processTypeVal === 'request_client_approval') {
        let invalid = [];
        for (let item in this.selectedProperties) {
          if (this.selectedProperties[item].elsterStatus === '2') {
            invalid.push(this.selectedProperties[item]);
          }
        }

        if (invalid.length > 0) {
          this.$api.showToast(
            this.$t('components.change_management_data_query.process.invalid_elster_status_all') + ': ' + invalid.map(items => items.propertyId).join(', '),
            'error'
          );
          return;
        }
      }
    },
    goBack() {
      this.$emit('goBack');
    },
    sendEmail(preview = false) {
      if (this.emailTemplate !== 'query_clients_to_change_property') {
        return;
      }

      this.$api.getEmailTemplate(this.emailTemplate, true).then((content) => {
        if (preview) {
          this.emailBody = content
              .replace('%sender_name%', this.getCurrentUser().name)
              .replace('%sender_company_name%', this.getCurrentUser().company_name)
              .replace(/<br\s*[/]?>/gi, "");
        }

        let selectedPropertiesIds = [];
        for (let item in this.selectedProperties) {
          selectedPropertiesIds.push(this.selectedProperties[item].id)
        }

        let clientService = new ClientService();
        clientService.sendQueryClientsToChangeProperty({
          clientUid: this.clientId,
          emailBody: this.emailBody,
          propertyCount: this.selectedProperties.length,
          selectedYear: this.selectedYear,
          preview: preview,
          selectedProperties: selectedPropertiesIds
        }).then((res) => {
          if (!res) {
            return;
          }

          if (res !== true) {
            this.emailPreview = res;
            this.showEmailPreview = true;
            this.emailBody = this.emailPreview.message;
            this.emailBody = this.emailBody .replace(/<br\s*[/]?>/gi, "");
            return;
          }

          this.showEmailPreview = false;
          let statusChangeQuery = 1;
          for (let item in this.selectedProperties) {
            this.service.setChangeStatusProperty(this.selectedProperties[item].id, statusChangeQuery);
          }

          this.emailTemplate = '';
          this.$router.push('/changemanagement/list_data_query');
        });
      });
    },
    handleModalClose() {
      this.showEmailPreview = false;
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
::v-deep {
  .property-type-cell {
    padding-left: 0px !important;
  }
}
.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}
</style>
