import {themes, userPermissions, vatCountryCodes} from "../constants";
import {mapGetters, mapMutations} from "vuex";
import {i18n} from "@/plugins/i18n";
import {ApiService} from "@/services/api.service";
import Vue from "vue";
import {isNumber} from "lodash";
import moment from "moment";

export const appMixin = {
    data: function () {
        return {
            authVerifying: false,
            verifyingAccess: false,
            isWeb: false,
            userAccess: [],
            defaultCountryId: "50",
            csrfFailed: false,
            sessionExpired: false
        }
    },
    created() {
        let userAgent = navigator.userAgent.toLowerCase();
        this.isWeb = userAgent.indexOf(' electron/') <= -1;
    },
    filters: {
        formatNumber: function(value) {
            return isNumber(value) ? (new Intl.NumberFormat('de-de')).format(value) : value;
        },
        formatArea: function(value) {
            return isNumber(value) ? (new Intl.NumberFormat('de-de')).format(value) + ' m²' : value;
        }
    },
    methods: {
        ...mapGetters("user", ["getUserRole", "getCurrentTheme", "getCurrentUser"]),
        ...mapMutations("user", ["resetCurrentUser", "setAuthorized", "setCurrentTheme"]),
        ...mapGetters("federal_states", ["getStateHolidays"]),
        authorized: function () {
            this.setAuthorized(true)
        },
        checkCurrentUser: async function (redirect) {
            let backend_token = this.$route.query.backend_token ? this.$route.query.backend_token : '';
            let login_token = this.$route.query.token ? this.$route.query.token : '';
            if (localStorage.getItem('tc_logged_in') || backend_token !== '' || login_token !== '') {
                if (backend_token !== '' || login_token !== '') {
                    this.resetCurrentUser();
                }
                if (this.$store.state.user.currentUser.user_id === '') {
                    this.authVerifying = true;
                    const api = new ApiService();
                    if (backend_token !== '') {
                        api.send_header('backend-token', backend_token);
                    }
                    if (login_token !== '') {
                        api.send_header('login-token', login_token);
                    }
                    await api.get_user(true).then((response) => {
                        if (typeof response !== 'undefined' && response.data.success) {
                            this.setAuthorized(true)
                            if (backend_token !== '' || login_token !== '') {
                                localStorage.setItem('tc_logged_in', '1');

                                if (response.data.userData.change_password) {
                                    response.data.userData.change_password = 0;
                                }

                                if (response.data.userData.two_fa_setup) {
                                    response.data.userData.two_fa_setup = 0;
                                }

                                delete Vue.axios.defaults.headers.post["X-LOGIN-TOKEN"];
                                delete Vue.axios.defaults.headers.post["X-BACKEND-TOKEN"];
                            }

                            if (response.data.userData.change_password || response.data.userData.two_fa_setup) {
                                this.$root.$emit('post-login', {
                                    'change_password': response.data.userData.change_password ? 1 : 0,
                                    'two_fa_setup': response.data.userData.two_fa_setup ? 1 : 0
                                });
                            } else if (!redirect) {
                                // datev/addison oauth handler
                                let url = new URL(window.location.href);
                                let code = url.searchParams.get("code");
                                if (code !== null && code !== '') {
                                    let state = url.searchParams.get("state");
                                    if (state === null || state === '') {
                                        window.history.replaceState({}, document.title, "/");
                                        this.$router.push('/home');
                                        this.$api.trigger('datev/oauth', {
                                            'code': code
                                        }, true).then((response) => {
                                            if (response.data.success) {
                                                if (response.data.route) {
                                                    let routeParams = {
                                                        oauth: "completed"
                                                    }
                                                    if (response.data.route.id) {
                                                        routeParams['comp'] = response.data.route.id;
                                                    }
                                                    this.$router.push({
                                                        name: response.data.route.name,
                                                        params: routeParams
                                                    });
                                                }
                                            } else {
                                                this.$toast.error(response.data.message);
                                            }
                                        });
                                    } else if (state === 'AddisonImport' || state === 'AddisonGgw') {
                                        window.history.replaceState({}, document.title, "/");
                                        this.$router.push('/home');

                                        this.$api.trigger('addison/oauth', {
                                            'code': code, 'state': state
                                        }, true).then((response) => {
                                            if (response.data.success) {
                                                if (response.data.route) {
                                                    this.$router.push({
                                                        name: response.data.route.name,
                                                        params: {
                                                            comp: response.data.route.id,
                                                            oauth: "completed"
                                                        }
                                                    });
                                                } else {
                                                    this.$router.push('/home'); //DEBUG
                                                }
                                            } else {
                                                this.$router.push('/home'); //DEBUG
                                                this.$toast.error(response.data.message);
                                            }
                                        });
                                    }
                                } else {
                                    this.redirectAfterLogin();
                                    if (!this.isWeb) {
                                        window.ipcRenderer.send('resizeDashboard')
                                    }
                                }
                            }
                        } else if (redirect) {
                            sessionStorage.setItem('deepLink', this.$route.fullPath);
                            this.$router.push('/');
                            if (!this.isWeb) {
                                window.ipcRenderer.send('resizeDashboard')
                            }
                        }
                        this.authVerifying = false;
                    });
                }
            } else if (redirect) {
                sessionStorage.setItem('deepLink', this.$route.fullPath);
                this.setAuthorized(false);
                this.$router.push('/');
            } else {
                this.setAuthorized(false);
            }
        },
        redirectAfterLogin() {
          let deepLink = sessionStorage.getItem('deepLink');
          if(deepLink) {
              sessionStorage.removeItem('deepLink');
              this.$router.push(deepLink);
          } else {
              this.$router.push('/home');
          }
        },
        getAssetPath: function (name) {
            let assetPath = name;
            let fallbackAssetPath = assetPath;
            if (this.getCurrentTheme()) {
                assetPath = 'themes/' + this.getCurrentTheme() + '/' + name;
            }
            try {
                return require('@/assets/' + assetPath);
            } catch (e) {
                if (!e.message.startsWith('Cannot find module')) throw e;

                if (this.isBusiness() && this.getCurrentTheme() !== 'business') {
                    assetPath = 'themes/business/' + name;
                    try {
                        return require('@/assets/' + assetPath);
                    } catch (e) {
                        if (!e.message.startsWith('Cannot find module')) throw e;

                        if (assetPath !== fallbackAssetPath) {
                            return require('@/assets/' + fallbackAssetPath);
                        }
                    }

                } else if (assetPath !== fallbackAssetPath) {
                    return require('@/assets/' + fallbackAssetPath);
                }
            }
        },
        getVatCountryCodes: function () {
            return vatCountryCodes.map((o) => {
                return {
                    "name": o,
                    "code": o
                }
            });
        },
        getOptionsYesNo: function () {
            return [
                {
                    "name": i18n.t('general.yes'),
                    "code": "1"
                }, {
                    "name": i18n.t('general.no'),
                    "code": "0"
                }
            ];
        },
        initializeSteps: function (step, data) {
            for (let x in step) {
                if (data[x]) {
                    step[x] = data[x];
                }
            }
            return step;
        },
        logout: async function (datev_logout) {
            let current_user = this.getCurrentUser();

            this.setAuthorized(false);
            localStorage.removeItem('tc_logged_in');
            sessionStorage.clear();
            this.resetCurrentUser();
            Vue.prototype.$loading.show();

            this.$api.trigger('logout', null).then(() => {
                location.href = '/';
            });
        },

        hasAccess: function (menuId) {
            if (menuId === 'billing') {
                if (this.getCurrentUser().billing_by_partner === true) {
                    return false;
                }
            }

            if (menuId === 'dmstransfer') {
                let datev_dms_enabled = this.getCurrentUser().settings
                    && typeof this.getCurrentUser().settings.datev_dms_enabled !== 'undefined'
                    && this.getCurrentUser().settings.datev_dms_enabled === '1'
                    && typeof this.getCurrentUser().settings.tax_office_software !== 'undefined'
                    && this.getCurrentUser().settings.tax_office_software === 'datev';

                if (!datev_dms_enabled) {
                    return false;
                }

                let allow_datev_doc_transfer = this.getCurrentUser().permissions
                    && typeof this.getCurrentUser().permissions.allow_datev_doc_transfer !== 'undefined'
                    && this.getCurrentUser().permissions.allow_datev_doc_transfer === '1';

                if (!allow_datev_doc_transfer) {
                    return false;
                }
            }

            if (menuId === 'datevggw') {
                let datev_ggw_enabled = this.getCurrentUser().settings
                    && typeof this.getCurrentUser().settings.datev_ggw_enabled !== 'undefined'
                    && this.getCurrentUser().settings.datev_ggw_enabled === '1'
                    && typeof this.getCurrentUser().settings.tax_office_software !== 'undefined'
                    && this.getCurrentUser().settings.tax_office_software === 'datev';

                let can_handover_item_values = this.getCurrentUser().permissions
                    && typeof this.getCurrentUser().permissions.can_handover_item_values !== 'undefined'
                    && this.getCurrentUser().permissions.can_handover_item_values === '1';

                return datev_ggw_enabled && can_handover_item_values;
            }

            if (menuId === 'addisonggw') {
                let addison_dms_enabled = this.getCurrentUser().settings
                    && typeof this.getCurrentUser().settings.addison_ggw_enabled !== 'undefined'
                    && this.getCurrentUser().settings.addison_ggw_enabled === '1'
                    && typeof this.getCurrentUser().settings.tax_office_software !== 'undefined'
                    && this.getCurrentUser().settings.tax_office_software === 'addison-oneclick';

                let can_handover_item_values = this.getCurrentUser().permissions
                    && typeof this.getCurrentUser().permissions.can_handover_item_values !== 'undefined'
                    && this.getCurrentUser().permissions.can_handover_item_values === '1';

                return addison_dms_enabled && can_handover_item_values;
            }

            if (menuId === 'declaration') {
                let slug = 'MassAppeal';
                if (typeof this.menus !== 'undefined') {
                    Object.entries(this.menus).map(([k, x]) => {
                        if (x.submenu && x.slug.toLowerCase() === menuId) {
                            let index = x.submenu.findIndex(item => item.slug === slug);
                            if (index >= 0) {
                                let allowMassUpdate = this.getCurrentUser().permissions
                                    && typeof this.getCurrentUser().permissions.allow_mass_update !== 'undefined'
                                    && this.getCurrentUser().permissions.allow_mass_update === '1';

                                let sendAppeal = this.getCurrentUser().permissions
                                    && typeof this.getCurrentUser().permissions.send_dispute !== 'undefined'
                                    && this.getCurrentUser().permissions.send_dispute === '1';

                                if (!allowMassUpdate || !sendAppeal) {
                                    this.menus[k].submenu.splice(index,1);
                                }
                            }
                        }
                    });
                }
            }

            if (
                this.getCurrentUser().permissions
                && typeof this.getCurrentUser().permissions.change_management_access !== 'undefined'
                && parseInt(this.getCurrentUser().permissions.change_management_access) === 1
                && this.getUserRole() !== ''
            ) {
                if (!userPermissions[this.getUserRole()].includes('change_management')) {
                    userPermissions[this.getUserRole()].push('change_management');
                }
            }

            if (this.userAccess.length === 0 && this.getUserRole() !== '') {
                this.userAccess = userPermissions[this.getUserRole()].concat(userPermissions['default']);
            }
            return this.userAccess.length > 0 && this.userAccess.includes(menuId);
        },
        checkAccess: function (menuId) {
            if (!this.hasAccess(menuId)) {
                this.$router.push('/home');
            } else {
                this.verifyingAccess = false;
            }
        },
        resetErrors: function (errors) {
            for (let x in errors) {
                errors[x].invalid = false;
            }

            return errors;
        },
        searchArray: function (list, attr, filter_txt) {
            filter_txt = filter_txt.toLowerCase();
            return list.filter((item) => {
                let found = false;
                for (let k = 0; k < attr.length; k++) {
                    if (item[attr[k]] && item[attr[k]].toLowerCase().indexOf(filter_txt) > -1) {
                        found = true;
                    }
                }
                return found;
            });
        },
        getGridState(gridId, state_key, default_val) {
            let gridState = this.$store.state.grid.gridState.filter((o) => {
                return o.gridId === gridId
            });

            if (gridState.length > 0) {
                if (state_key) {
                    return typeof (gridState[0][state_key]) !== 'undefined' ? gridState[0][state_key] : (typeof (default_val) !== 'undefined' ? default_val : '');
                } else {
                    return gridState[0];
                }
            }

            return {};
        },
        getSelectedFilterState(gridId) {
            let storageKey = 'grid-filter-' + gridId;
            let selectedFilterState = sessionStorage.getItem(storageKey);
            if(selectedFilterState) {
                selectedFilterState = JSON.parse(selectedFilterState);
            }

            return selectedFilterState;
        },
        getGridFilters(gridId) {
            let gridFilters = this.$store.getters["grid/getGridsFilters"].filter((o) => {
                return o.gridId === gridId
            });

            if (gridFilters.length > 0) {
                return gridFilters[0].customFilters;
            }

            return [];
        },
        gridFiltersFetched(gridId) {
            let gridFilters = this.$store.getters["grid/getGridsFilters"].filter((o) => {
                return o.gridId === gridId
            });

            if (gridFilters.length > 0) {
                return gridFilters[0].fetched;
            }

            return false;
        },
        getGridNavUids(gridId) {
            let navRecords = this.$store.getters["grid/getGridsNavRecords"].filter((o) => {
                return o.gridId === gridId
            });

            if (navRecords.length > 0) {
                return navRecords[0].uids;
            }

            return [];
        },
        isBusiness() {
            return process.env.VUE_APP_DEV_VARIANT === 'business'
        },
        goBack() {
            this.$router.go(-1)
        },
        cityLookup(zip) {
            return new Promise((resolve, reject) => {
                this.$api.lookup_city(zip)
                    .then((cities) => {
                        resolve(cities);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        changeTheme(theme) {
            if (theme && typeof themes[theme] === 'function') {
                this.setCurrentTheme(theme);
                themes[theme]();
            }
        },
        parseFloatLocalized(number) {
            if (typeof number === 'string') {
                if (i18n.locale === 'de') {
                    number = number.replace('.', '')
                        .replace(',', '.');
                }

                return parseFloat(number);
            } else {
                return number;
            }
        },
        calculateSumOfAreas(parcels) {
            let sumOfAreas = 0;
            for (let x in parcels) {
                if (parseFloat(parcels[x].areaOfTheLand) > 0 && parseFloat(parcels[x].shareOfOwnershipCounter) > 0 && parseFloat(parcels[x].shareOfOwnershipDenominator) > 0) {
                    let rowArea = parseFloat(parcels[x].areaOfTheLand) * parseFloat(parcels[x].shareOfOwnershipCounter) / parseFloat(parcels[x].shareOfOwnershipDenominator);
                    sumOfAreas += rowArea;
                }
            }

            return Math.floor(Number.parseFloat(sumOfAreas));
        },
        calculateSumOfAreasTotal(parcels, areaOfTheLandTotal, areaOfTheLand1, areaOfTheLand2, federalStateUid) {
            if (federalStateUid == '2' || federalStateUid == '6' ) {
                let val = this.calculateSumOfAreas(parcels);
                return val !== 0 ? val : '-';
            }

            let sumOfAreas = 0;
            if ((Math.floor(areaOfTheLand1) > 0) || (Math.floor(areaOfTheLand2) > 0)) {
                if (Math.floor(areaOfTheLand1) > 0) {
                    sumOfAreas = Math.floor(areaOfTheLand1);
                }

                if (Math.floor(areaOfTheLand2) > 0) {
                    sumOfAreas += Math.floor(areaOfTheLand2);
                }
            } else if ((Math.floor(areaOfTheLandTotal) > 0)) {
                sumOfAreas = Math.floor(areaOfTheLandTotal);
            }

            return Math.floor(Number.parseFloat(sumOfAreas));
        },
        processNotification(notification) {
            let notificationType = notification.notificationType ? notification.notificationType : (notification.notification_type ? notification.notification_type : '');
            let recordUid = notification.recordUid ? notification.recordUid : (notification.record_uid ? notification.record_uid : '');
            let notificationUid = notification.id ? notification.id : (notification.prim_uid ? notification.prim_uid : 0);
            let notificationTimestamp = notification.timestampCreatedAt ? notification.timestampCreatedAt : 0;

            if (notificationType) {
                switch (notificationType) {
                    case 'BDO_DATA_FIX':
                        this.$router.push('/datevDataUpdate');
                        break;
                    case 'DATEV_CLIENT_IMPORT_STARTED':
                    case 'DATEV_CLIENT_IMPORT_COMPLETED':
                        this.$router.push('/datev/ImportStatus?uid=' + notificationUid);
                        break;
                    case 'CSV_CLIENT_IMPORT_STARTED':
                    case 'CSV_CLIENT_IMPORT_COMPLETED':
                        this.$router.push('/client/ImportStatus?uid=' + notificationUid);
                        break;
                    case 'DECLARATION_JOB_STARTED':
                    case 'DECLARATION_JOB_COMPLETED':
                        this.$router.push('/declaration/BulkAddStatus?uid=' + notificationUid);
                        break;
                    case 'DATA_EXPORT':
                        this.$router.push('/dataExportStatus?uid=' + notificationUid);
                        break;
                    case 'ELSTER_MESSAGE_RESPONSE':
                        if (recordUid) {
                            this.$router.push('/declaration/edit?uid=' + recordUid);
                        }
                        break;
                    case 'ELSTER_SUBMISSION_APPROVAL':
                        this.$router.push('/declaration/PendingElster?uid=' + notificationUid);
                        break;
                    case 'INIT_SKENDATA':
                        this.$router.push('/setting/systemsettings/?tab=data_acquisition');
                        break;
                    case 'CLIENT_USER_APPROVED_PROPERTY':
                        if (recordUid) {
                            this.$router.push({path: '/property/edit?uid=' + recordUid,  query:{show_client_comment: '1'}});
                        }
                        break;
                    case 'CLIENT_USER_UPDATED_PROPERTY':
                        if (recordUid) {
                            this.$router.push({
                                name: 'Properties',
                                params: {
                                  'comp': 'edit',
                                  'oneTimeSelectedTab': 'protocol',
                                  'oneTimeSelectedProtocolTimestamp': notificationTimestamp
                                },
                                query: {
                                    'uid': recordUid,
                                    'show_client_comment': '1'
                                }
                            });
                        }
                        break;
                    case 'CLIENT_USER_UPLOADED_DOCUMENT':
                        if (recordUid) {
                            this.$router.push({
                                name: 'Properties',
                                params: {
                                    'comp': 'edit',
                                    'oneTimeSelectedTab': 'protocol',
                                    'oneTimeSelectedProtocolTimestamp': notificationTimestamp
                                },
                                query: {
                                    'uid': recordUid,
                                    'show_client_comment': '1'
                                }
                            });
                        }
                        break;
                    case 'CLIENT_USER_UPDATED_PROPERTY_WARNING':
                        let extraDetails = notification.extraDetails ? JSON.parse(notification.extraDetails) : (notification.extra_details ? JSON.parse(notification.extra_details) : {});
                        let msgBody = this.$t('edit_property_warning.line1', {"clientUserName": extraDetails.clientUserName});
                        msgBody += ' <router-link to="/property/edit?uid=' + recordUid + '">' + extraDetails.propertyName + '</router-link> ';
                        msgBody += this.$t('edit_property_warning.line2');
                        let params = {
                            headerText: this.$t('declarations.title'),
                            textHtml: msgBody,
                            showClose: false,
                            canEscape: false,
                            canCloseOutsideClick: false,
                            buttons: {
                                ok: this.$t('edit_property_warning.edit_declaration'),
                                cancel: this.$t('buttons.cancel'),
                                delete: false
                            },
                            onConfirm: () => {
                                this.$router.push('/declaration/edit?uid=' + extraDetails.declarationId);
                            },
                            onCancel: () => {
                                this.$router.push('/home')
                            }
                        }
                        this.$modal.show(params);
                        break;
                    case 'APPROVE_DECLARATION':
                    case 'APPROVED_DECLARATION':
                    case 'CLIENT_CHECKED_PROPERTY':
                        if (recordUid) {
                            this.$router.push('/declaration/edit?uid=' + recordUid);
                        }
                        break;
                    case 'ELSTER_SUBMISSION_ERROR':
                        if (recordUid) {
                            this.$router.push({
                                name: "Declaration",
                                params: {
                                    'comp': 'edit',
                                    'showSavedElsterResponse': true
                                },
                                query: {
                                    'uid': recordUid
                                }
                            });
                        }
                    case 'DEADLINE_REMINDER':
                        this.$router.push('/declaration/edit?uid=' + recordUid);
                        break;
                    case 'REPORT_GENERATED':
                        this.$router.push('/report/DownloadReport?uid=' + notificationUid);
                        break;
                    case 'CLIENT_DOCUMENT_ZIP':
                        this.$router.push('/client/DownloadDocuments?uid=' + notificationUid);
                        break;
                }
            }
        },
        calculateAnnouncementDate(documentDate, stateUid, days, excludeHoliday) {
            if(documentDate instanceof Date) {
                if(days === '1M') {
                    let m = moment(documentDate);
                    if(m.isValid()) {
                        m.add(1, 'M');
                        documentDate = m.utc().toDate();
                    } else {
                        documentDate.setMonth(documentDate.getMonth() + 1);
                    }
                } else {
                    documentDate.setDate(documentDate.getDate() + days);
                }
                let validDate = false;
                let dateStr = '';
                let holidaysList = this.getStateHolidays();
                let holidays = holidaysList[stateUid] ? Object.values(holidaysList[stateUid]) : [];
                do {
                    if(documentDate.getDay() > 0 && documentDate.getDay() <= 5) {
                        validDate = true;
                    }
                    if(validDate && excludeHoliday) {
                        // Check holidays as per state
                        dateStr = documentDate.toISOString().split('T')[0];
                        if(holidays.includes(dateStr)) {
                            validDate = false;
                        }
                    }

                    if(!validDate) {
                        documentDate.setDate(documentDate.getDate() + 1);
                    }
                } while (!validDate);
            }

            if(documentDate instanceof Date) {
                documentDate = new Date(Date.UTC(documentDate.getFullYear(), documentDate.getMonth(), documentDate.getDate(), documentDate.getHours(), documentDate.getMinutes(), documentDate.getSeconds()));
            }

            return documentDate;
        },
        showPackageLimitWarning() {
            if(this.hasAccess('billing')) {
                this.$modal.show({
                    title: '',
                    headerText: this.$t('general.feature_not_available'),
                    text: this.$t('general.errors.feature_not_in_package'),
                    showClose: false,
                    canEscape: true,
                    showCancel: true,
                    canCloseOutsideClick: false,
                    buttons: {
                        'ok': this.$t('general.choose_package_now'),
                        'cancel': this.$t('general.cancel'),
                        'delete': false
                    },
                    onConfirm: () => {
                        this.$router.push('/setting/billing');
                    }
                });
            } else {
                this.$api.showToast(this.$t('general.errors.feature_not_in_package'), "warn");
            }
        },
        renderUrlsAsLinks(text) {
            const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
            const subst = '<a href="$&" target="_blank">$&</a>';

            return text.replace(regex, subst);
        }
    }
}
