<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center" v-if="visible">
      <div class=" " ref="draggableContainer" id="draggable-container">
        <div class="w-full">
          <div class="flex flex-col justify-start ml-4 pt-10">
            <div class="spin"></div>
            <label class=" title   mt-4">{{ message }}</label>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
// we must import our Modal plugin instance
// because it contains reference to our Eventbus
import {Loading} from '@/plugins/modal-plugin';

export default {
  data() {
    return {
      visible: false,
      message: '',
      showClose: true,
      headerText: 'Grundstück löschen',
      canEscape: true,
      canCloseOutsideClick: true,
      // adding callback function variable
      onConfirm: {},
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      clickedOutSideCount: 0,
    }
  },
  methods: {
    close() {

      this.$emit('close');

    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {

      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    /* onClickOutside() {
       // this.clickedOutSideCount++;
       // if (this.clickedOutSideCount > 1) {
       if (this.canCloseOutsideClick) {
         this.hide()
       }
       // }


     },*/
    hide() {
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show() {
      // making modal visible
      this.visible = true;
      // setting title and text
      // this.message = params.message;
      // this.text = params.text;
      // this.showClose = params.showClose
      // this.headerText = params.headerText
      //this.canEscape = params.canEscape
      // this.canCloseOutsideClick = params.canCloseOutsideClick
      // setting callback function
      // this.onConfirm = params.onConfirm;
    }
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Loading.EventBus.$on('show', (params) => {

      this.show(params)
    })
    Loading.EventBus.$on('hide', () => {

      this.hide()
    })
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.hide();
        }

      }
    });
  },
}
</script>

<style scoped lang="scss">
.title {
  font-size: 15px;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: 40px;
  margin-bottom: 15px;
}

.dtext {
  font-size: 15px;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 40px;
}

.modal-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.modal-button {
  flex-grow: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 10000;
}

.modal {
  width: 500px;
  background: white;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;


}


.modal-header {

  color: #333333;

}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;

}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

#draggable-container {
  position: absolute;
  z-index: 9;
}

#draggable-header {
  z-index: 10;
}

.dheader {

  //padding: 0 20px;
  max-width: 50%;


  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}
</style>
