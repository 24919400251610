<template>
  <div class="flex flex-col h-full ">
    <div class="">
      <div class="py-2 align-left  w-auto sm:px-2 lg:px-2">
        <div class="overflow-hidden border-b border-gray ">
          <table class=" table-fixed min-w-full divide-y divide-gray">
            <thead class="">
            <tr>
              <th scope="col" class=" py-3 pr-6 text-left pl-14   ">
                {{ $t('settings.user_management.table.col1') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left pl-8  ">
                {{ $t('settings.user_management.table.col2') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('settings.user_management.table.col3') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('settings.user_management.table.col4') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('settings.user_management.table.col5') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                <div class="flex justify-center items-center">
                  <img class="column_icon" src="@/assets/select_column_green.svg"/>
                  <p> {{ $t('general.grid.select_col') }}</p>
                </div>

              </th>

            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray">
            <tr v-for="(person,index) in people" :key="person.email" class="hover:bg-gray_light">
              <td class=" py-4 whitespace-nowrap   px-5">

                <div class="flex  ">
                  <div class="flex w-full">
                    <div class=" icontable rounded-full w-4 h-4 mr-5 check flex justify-center items-center bg-green">
                      <img src="@/assets/check_white.svg"/>
                    </div>

                    <p class="w-28"> {{ person.name }}</p>
                  </div>
                  <div class="flex w-full justify-self-end">
                    <img class=" icontable ml-5 w-4 h-4 " src="@/assets/key_green.svg" @click="handleClick"/>
                    <img class=" vertical-dots ml-5 w-4 h-4 " src="@/assets/verticle-dots.svg"
                         @click="toggle($event,index)"/>
                  </div>
                  <OverlayPanel ref="overlay" appendTo="body" :showCloseIcon="false" id="overlay_panel">
                    <div class="-ml-6 space-y-4">
                      <IconLabel class="-mt-1" :icon="require('@/assets/edit_user_gray.svg')" :blackText="true"
                                 label="Benutzer bearbeiten"/>
                      <IconLabel :icon="require('@/assets/disable_gray.svg')" :blackText="true"
                                 label="Benutzer deaktivieren"/>

                      <IconLabel :icon="require('@/assets/delete_gray.svg')" :blackText="true"
                                 label="Benutzer löschen"/>
                    </div>
                  </OverlayPanel>

                </div>

              </td>
              <td class=" pr-6 py-4 whitespace-nowrap pl-8 ">
                <div class=" ">{{ person.account_id }}</div>
              </td>
              <td class="pr-6 py-4 whitespace-nowrap">
                <div class=" ">{{ person.email }}</div>
              </td>
              <td class="pr-6 py-4 whitespace-nowrap ">
                {{ person.role }}
              </td>
              <td class="pr-6 py-4 whitespace-nowrap ">
                {{ person.status }}
              </td>
              <td></td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import OverlayPanel from 'primevue/overlaypanel';

export default {
  name: "UserTable",
  components: {OverlayPanel},
  data() {
    return {
      people: [
        {
          name: 'Klaudia Liebsch',
          account_id: '194561-0',
          email: 'Universitätsplatz 12, 34121',
          role: 'Administrator (Account Owner)',
          status: "Active"
        },
        {
          name: 'Klaus Matheis',
          account_id: '194561-1',
          email: 'Universitätsplatz 12, 34122',
          role: 'Administrator ',
          status: "Active"
        },
        {
          name: 'Antonio Albers',
          account_id: '194561-2',
          email: 'Universitätsplatz 12, 34123',
          role: 'Read only user',
          status: "Active"
        },
        {
          name: 'Timm Burckhardt',
          account_id: '194561-3',
          email: 'Universitätsplatz 12, 34124',
          role: 'Upload only',
          status: "Active"
        },


      ],
    }
  },
  methods: {
    ...mapMutations("user", ["setModalVisible"]),
    handleClick() {
      //this.setModalVisible(true)
      this.$emit('openModal')
    },
    handleOptionsClick(event) {

      this.$refs.overlay.toggle(event);

    },
    toggle(event, index) {

      this.$refs.overlay[index].toggle(event);
    }

  }
}
</script>

<style scoped lang="scss">
.vertical-dots {
  height: 18px;
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  line-height: normal;
}

th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.column_icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  color: #333333;
}
</style>
