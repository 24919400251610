<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('declarations.bulk_transfer')" :breadcrumbs="breadcrumbs"
                :logo="getAssetPath('elster_transfer.svg')"/>
    <div class="mr-6 mb-8 mt-8">
      <DualListSelect/>
    </div>
    <div class="flex justify-end  mt-6 space-x-4 mr-6 mb-8">
      <Button class="w-56 mb-8" :text="$t('buttons.cancel')" :secondary="true"/>
      <Button class="w-56 mb-8" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>

</template>

<script>
export default {
  name: "BulkTransfer",
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('declarations.draft.breadcrumbs.link1')
        },
        {
          title: this.$t('declarations.stages.elster_submission')
        },
        {
          title: this.$t('declarations.bulk_transfer')
        }
      ],
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">

</style>
