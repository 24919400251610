<template>
  <div class="flex flex-col justify-start rectangle">
    <Header
      :title="$t('dashboard.headers.notification')"
      :showDropDown="true"
      :showSetting="false"
      @popupclick="remove"
    />

    <div class="account px-2 pb-20 overflow-hidden">
      <p class="bold-header ml-3 mt-6 mb-4" v-if="getNewNotificationCount() > 0">{{ getNewNotificationCount() }} {{ widgetTitle }}</p>
      <p class="bold-header ml-3 mt-6 mb-4" v-else>{{ widgetTitle }}</p>

      <div class="flex flex-col justify-between h-full overflow-y-scroll">
        <EmptyList v-if="notifications.length === 0"/>
        <div v-else class="px-3">
          <NotificationItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from "@/components/Dashboard/Items/NotificationItem";
import EmptyList from "@/components/EmptyList";
import {UserService} from "@/services/user.service";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "NotificationWidget",
  components: {EmptyList, NotificationItem},
  props: {
    config: {
      type: Object,
      default: () => {
      }
    },
    widgetId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      notifications: [],
      userService: new UserService()
    }
  },
  mounted() {
    this.userService.listNotifications().then((notifications) => {
      this.notifications = notifications;
    });
  },
  computed: {
    widgetTitle() {
      if(this.getNewNotificationCount() > 0) {
        return this.$t('dashboard.headings.notification')
      } else {
        return this.$t('dashboard.headings.no_new_notification');
      }
    }
  },
  methods: {
    ...mapMutations("user", ["setNewNotificationCount"]),
    ...mapGetters("user", ["getNewNotificationCount"]),
    remove() {
      this.$emit('click', "removeWidget")
    }
  }
}
</script>

<style scoped lang="scss">
.notification-button {
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
}

.notification {
  @include segoe-regular;
  font-size: 10px;
}
</style>
