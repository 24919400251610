<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.protocol.title') " :popoverTexts="popoverTexts" :popoverLink="popoverLink"
                :breadcrumbs="breadcrumbs"/>

    <div class="mr-7">
      <AppGrid :grid-id="gridId"
               data-endpoint="list_sys_protocol"
               :columns="columns"
               default-sort-attr="created_at"
               :default-sort-dir="-1"
               :standard-filters="standardFilters"
               :menus="menus"
               :show-create-record="false"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "@/components/AppGrid";
import {mapMutations} from "vuex";

export default {
  name: "protocol",
  components: {
    AppGrid
  },
  data() {
    return {
      popoverTexts: [
        this.$t('settings.protocol.header_tooltip'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverLink: "https://hilfe.grundsteuer-digital.de/faq/was-ist-das-systemprotokoll/",
      gridId: "protocol",
      standardFilters: [
        {
          selected: true,
          default: true,
          name: this.$t('settings.protocol.standard_filters.all_log'),
          id: 'all_log'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.today_log'),
          id: 'today_log'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.yesterday_log'),
          id: 'yesterday_log'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.current_month_log'),
          id: 'current_month_log'
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.last_month_log'),
          id: 'last_month_log',
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.current_year_log'),
          id: 'current_year_log',
        }, {
          selected: false,
          default: false,
          name: this.$t('settings.protocol.standard_filters.last_year_log'),
          id: 'last_year_log'
        }],
      columns: [{
        field: 'created_at',
        header: this.$t('settings.protocol.table.date_time'),
        filterLbl: this.$t('settings.protocol.table.date'),
        isChecked: true,
        elType: 'date_range',
        operator: 'between'
      }, {
        field: 'user_name',
        header: this.$t('settings.protocol.table.users'),
        isChecked: true,
        elType: 'user_select'
      }, {
        field: 'description',
        header: this.$t('settings.protocol.table.description'),
        filterLbl: this.$t('settings.protocol.table.description_contains'),
        isChecked: true,
        elType: 'text'
      }],
      menus: [{
        id: "grid_reload",
        "icon": this.getAssetPath('refresh.svg'),
        "title": this.$t('general.grid.refresh')
      }],
      breadcrumbs: [
        {
          title: this.$t('settings.protocol.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.protocol.menu.protocol'),
          link: ''
        }
      ]
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('protocol');
  },
  methods: {
    ...mapMutations('grid', ["triggerReload"]),
    reloadGrid() {
      this.triggerReload(this.gridId);
    }
  }
}
</script>

<style scoped lang="scss">
.vertical-dots {
  height: 18px;
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  line-height: normal;
}


.column_icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  color: #333333;
}


.label {
  @include segoe-regular;
}

</style>
