<template>
  <div>
    <Step2 v-if="fetchingClients" :active="true" ref="compFetchClient" @goToNextStep="goToNextStep" />
    <div v-else class="step_box">
      <div class="box flex justify-start items-center mb-6 w-1/2">
        <img class="image ml-2" src="@/assets/info_gray.svg"/>
        <label class="text ml-2">
          {{ $t('clients.datev_import.steps.step3.info') }}
        </label>
      </div>
      <div class="flex justify-around space-x-10 mt-2 ">
        <InputSelect class="mt-2 w-full"
                     v-model="businessPartner"
                     :selectedValue="businessPartner"
                     :label="$t('clients.datev_import.steps.step3.business_partner')"
                     :options="businessPartners"
                     :isRequired="true" placeholder=""/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-end space-x-4 mr-2 mt-10">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Step2 from "@/components/datev/data_steps/Step2";

export default {
  name: "Step3",
  components: {Step2},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(nv) {
      if (nv) {
        this.businessPartners = [
          {
            name: 'Alle',
            code: '0'
          }
        ];

        const businessPartners = this.getOrgInfo().business_partners.map((o) => {
          return {
            "name": o.name,
            "code": o.number.toString()
          }
        });

        businessPartners.forEach(businessPartner => {
          this.businessPartners.push(businessPartner);
        });
      }
    }
  },
  data() {
    return {
      businessPartner: "",
      businessPartners: [],
      fetchingClients: false
    }
  },
  methods: {
    ...mapGetters("datev_import", ["getOrgInfo"]),
    ...mapMutations("datev_import", ["setBusinessPartner"]),
    submitNext() {
      if (this.businessPartner === '') {
        return false;
      }

      this.setBusinessPartner(this.businessPartner);
      this.fetchingClients = true;
      this.$nextTick(() => this.$refs.compFetchClient.fetchDatevClient());
    },
    goToNextStep() {
      this.fetchingClients = false;
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">
.flex.radio_step > label {
  margin-right: 10px;
}

.step_box {
  text-align: left;
  font-size: 15px;
}

p.para_text {
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;

}


.chk {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  align-self: start;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

a {
  text-decoration: underline;
  color: theme('colors.muted_black');
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
</style>
