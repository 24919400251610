const state = {
    templates: {}
};
const getters = {
    getTemplates: (state) => state.templates
}

const mutations = {
    saveEmailTemplate: (state, template) => {
        state.templates = {...state.templates, ...template};
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



