<template>
  <MainLayout value="settings" v-if="!authVerifying">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>

</template>

<script>
import add from '@/components/user/AddUser'
import {appMixin} from "@/core/utils/mixin";

export default {
  name: "Settings",
  components: {add},
  mixins: [appMixin],
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    if(this.$route.params.comp === 'billing') {
      this.verifyingAccess = true;
      this.checkAccess('billing');
    }
  },
  methods: {
    handleComponentClick(data) {

      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          break;

        case 'add_branch':
          this.$router.push('/branch/add')
          break;
        case 'edit_branch':
          this.$router.push('/branch/edit')
          break;


      }

    },
  }
}
</script>

<style scoped lang="scss">

</style>
