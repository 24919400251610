import {ApiService} from "./api.service";

export class UserService extends ApiService {

  constructor() {
    super();
  }

  listAll() {
    if (this.$store.getters["user/getUserList"].length > 0 && !this.$store.getters["user/listOutdated"]) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["user/getUserList"]);
      });
    } else {
      return this.trigger('user/list', {
        'first': 0,
        'rows': -1,
        'sortField': 'name',
        'sortOrder': 1
      }).then((response) => {
        if (response.data.success) {
          let userList = this.parse_object_data(response.data.records).map(o => {
            return o;
          })

          this.$store.commit("user/setUserList", userList)
          return userList;
        } else {
          return [];
        }
      });
    }
  }

  listUserByClient(client_uid) {
    return this.trigger('user/list', {
      'first': 0,
      'rows': -1,
      'sortField': 'name',
      'sortOrder': 1,
      'filters': {
        'client_uid': client_uid
      }
    }).then((response) => {
      if (response.data.success) {
        return this.parse_object_data(response.data.records).map(o => {
          return o;
        });
      } else {
        return [];
      }
    });
  }

  save(data) {
    data['main_user'] = data['main_user'] ? 1 : 0;
    data['release_declaration'] = data['release_declaration'] ? 1 : 0;
    data['all_branch_access'] = data['all_branch_access'] ? 1 : 0;
    data['all_client_access'] = data['all_client_access'] ? 1 : 0;
    data['change_management_access'] = data['change_management_access'] ? 1 : 0;
    data['allow_mass_update'] = data['allow_mass_update'] ? 1 : 0;
    data['elster_transaction'] = data['elster_transaction'] ? 1 : 0;
    data['send_dispute'] = data['send_dispute'] ? 1 : 0;
    
    return this.trigger('user/save', data, true);
  }

  get(uid) {
    return this.trigger('user/get', {
      uid: uid
    }, true).then((response) => {
      if (response.data.success) {
        response.data.userData.main_user = parseInt(response.data.userData.main_user) === 1;
        response.data.userData.release_declaration = parseInt(response.data.userData.release_declaration) === 1;
        response.data.userData.all_client_access = parseInt(response.data.userData.all_client_access) === 1;
        response.data.userData.change_management_access = parseInt(response.data.userData.change_management_access) === 1;
        response.data.userData.all_branch_access = parseInt(response.data.userData.all_branch_access) === 1;
        response.data.userData.allow_mass_update = parseInt(response.data.userData.allow_mass_update) === 1;
        response.data.userData.elster_transaction = parseInt(response.data.userData.elster_transaction) === 1;
        response.data.userData.manage_property = parseInt(response.data.userData.manage_property) === 1;
        response.data.userData.send_dispute = parseInt(response.data.userData.send_dispute) === 1;
        response.data.userData.selected_branches = this.parse_object_data(response.data.userData.selected_branches);
        response.data.userData.selected_clients = this.parse_object_data(response.data.userData.selected_clients).map(o => {
          if (o.client_type === 'private') {
            o.client_type_icon = 'icon_user_green.svg';
          } else {
            o.client_type_icon = 'properties_green.svg';
          }
          return o;
        });

        return response.data.userData;
      } else {
        this.$toast.error(response.data.message);
        return [];
      }
    })
  }

  updatePassword(data) {
    return this.trigger('user/update_password', data, true).then(response => {
      if (response.data.success) {
        return true;
      } else {
        this.showToast(response.data.message, "error");
        return false;
      }
    });
  }

  listNotifications(params, showLoader) {
    if(typeof showLoader === 'undefined') {
      showLoader = false;
    }
    if(typeof params === 'undefined') {
      params = {};
    }
    return this.trigger('notification/list', {
      'first': params.offset ? params.offset : 0,
      'rows': params.rows ? params.rows : 25,
      'filters': params.filters ? params.filters : {},
      'sortField': 'created_at',
      'sortOrder': -1
    }, showLoader).then((response) => {
      if (response.data.success) {
        this.$store.commit('user/setNotificationCount', response.data.total_records);
        this.$store.commit('user/setNewNotificationCount', response.data.new_notification_count);
        return this.parse_object_data(response.data.records);
      } else {
        return [];
      }
    });
  }

  readNotification(uid) {
    return this.trigger('notification/read', {
      "id": uid
    });
  }

  readAllNotifications() {
    return this.trigger('notification/all_read', {});
  }
  getNotification(uid, show_loader) {
    if(typeof show_loader === 'undefined') {
      show_loader = true;
    }
    return this.trigger('notification/get', {
      "id": uid
    }, show_loader);
  }

  getCurrentUserStats() {
    if (!this.$store.getters["user/isStatsChanged"]) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["user/getCurrentUserStats"]);
      });
    } else {
      return this.trigger('dashboard/stats', null).then((response) => {
        if (response.data.success) {
          this.$store.commit("user/setCurrentUserStats", response.data.stats);
          this.$store.commit("user/setCurrentUserStatsDataChanged", false);
          return response.data.stats;
        } else {
          return [];
        }
      });
    }
  }
  getDataAcquisitionData() {
    this.loading = true;

    return this.trigger('user/data_acquisition', null).then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        return [];
      }
    }).finally(() => {
      this.loading = false;
    })
  }
}
