<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('declarations.mass_update.steps.process_type') }}:</label>
          <label class="ml-5">{{ processType }}</label>
        </div>
      </div>
      <div class="flex justify-around space-x-1 mt-2" v-if="processTypeVal === 'request_internal_approval'">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('settings.user_management.user') }}:</label>
          <InputSelect class="ml-11 w-full"
                       :options="usersCanRelease"
                       v-model="assigneeForRelease"
                       :selected-value="assigneeForRelease"
                       :filterable="true"
                       @on-item-selected="fetchDeclarations"
          />
        </div>
        <div class="w-full pr-6 invisible"/>
      </div>
      <Fieldset legend="Filter" class="filter-fieldset" v-if="showFilter">
        <div class="flex justify-around space-x-4">
          <InputSelect class="mt-2 w-full"
                       :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
                       :options="userList"
                       v-model="filters.assignee"
                       :filterable="true"
                       :show-clear="true"
                       :placeholder="$t('general.please_select')"
          />
          <InputSelect class="mt-2 w-full"
                       :label="$t('declarations.add_declaration.steps.step1.client')"
                       v-model="filters.clientId"
                       :options="clientList"
                       :loading="loadingClient"
                       :search-fn="clientListSearch"
                       :total-record-count="totalClientsCount"
                       :filterable="true"
                       :sort="true"
                       :show-clear="true"
                       :placeholder="$t('general.client_select')"
          />
        </div>
      </Fieldset>
      <div class="flex justify-around space-x-1 mt-5">
        <DualListSelect
            :list="filteredDeclarationList"
            ref="declarationDualList"
            v-model="selectedDeclarations"
            :columns="declarationDualListColumns"
            dataKey="id"
            sort-attr="name"
            :enable-all-select="true"
            :leftHeader="$t('declarations.mass_update.declarations')"
            :rightHeader="$t('declarations.mass_update.declarations')"
            :countShow="true"
            :validator="validator"
            @validatorFail="handleValidatorFail"
            @validatorFailAll="handleAllValidatorFails"
        />
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end  space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DualListSelect from "@/components/labels/DualListSelect";
import {DeclarationService} from "@/services/declaration.service";
import {UserService} from "@/services/user.service";
import Fieldset from "primevue/fieldset";
import {ClientService} from "@/services/client.service";

export default {
  name: "Step6",
  components: {DualListSelect, Fieldset},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fromGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clientService: new ClientService(),
      userService: new UserService(),
      declarationService: new DeclarationService(),
      currentProcessType: '',
      declarationList: [],
      selectedDeclarations: [],
      declarationDualListColumns: [
        {
          field: 'declarationId',
          label: this.$t('declarations.grid.id'),
          search: true
        }, {
          field: 'name',
          label: this.$t('declarations.mass_update.declaration'),
          search: true
        }
      ],
      usersCanRelease: [],
      assigneeForRelease: "0",
      assigneeNameForRelease: '',
      loadingClient: false,
      userList: [],
      clientList: [],
      totalClientsCount: 0,
      showFilter: false,
      filters: {
        "assignee": "0",
        "clientId": "0"
      }
    }
  },
  computed: {
    processType() {
      return this.getSteps().processType ? this.$t('declarations.mass_update.process')[this.getSteps().processType] : '';
    },
    processTypeVal() {
      return this.getSteps().processType ? this.getSteps().processType : '';
    },
    filteredDeclarationList() {
      let filteredDeclarations = this.declarationList;
      if(this.filters.assignee && parseInt(this.filters.assignee) > 0) {
        filteredDeclarations = filteredDeclarations.filter(o => o.assignedUser === this.filters.assignee);
      }
      if(this.filters.clientId && parseInt(this.filters.clientId) > 0) {
        filteredDeclarations = filteredDeclarations.filter(o => o.clientId === this.filters.clientId);
      }

      return filteredDeclarations;
    }
  },
  watch: {
    processType() {
      if(this.active && this.processType && this.processType !== this.currentProcessType) {
        let preselectedDeclarations = this.$route.params.selectedDeclarations && this.$route.params.selectedDeclarations.length > 0 && this.getSteps().processType !== 'request_internal_approval';
        if(!preselectedDeclarations) {
          this.fetchDeclarations();
        }
      }
    }
  },
  mounted() {
    if(this.$route.params.selectedDeclarations && this.$route.params.selectedDeclarations.length > 0) {
      this.selectedDeclarations =  this.$route.params.selectedDeclarations;
      this.showFilter = false;
    } else {
      this.showFilter = true;
      this.clientService.listAll().then((clients) => {
        if(clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.clientList = clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid
            };
          })
        }
      });
    }

    this.userService.listAll().then((list) => {
      this.usersCanRelease = list.filter((o) => {
        return o.permissions.release_declaration && o.permissions.release_declaration === "1";
      }).map((item) => {
        return {
          "name": item.name,
          "code": item.id
        };
      });

      if(this.showFilter) {
        this.userList = list.filter((o) => {
          return o.statusKey === 'active' && (o.roleKey === 'administrator' || o.roleKey === 'user');
        }).map((item) => {
          return {
            "name": item.name,
            "code": item.id
          };
        });
      }

      if(this.usersCanRelease.length === 1) {
        this.assigneeForRelease = this.usersCanRelease[0].code;
        this.assigneeNameForRelease = this.usersCanRelease[0].name;
      } else {
        this.assigneeForRelease = "0";
      }
    });
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    submitNext() {
      if(this.selectedDeclarations.length > 0) {
        this.saveSteps({
          "selectedDeclarations": this.selectedDeclarations,
          "assigneeForRelease": this.processTypeVal === 'request_internal_approval' ? this.assigneeForRelease : 0,
          "assigneeNameForRelease" : this.processTypeVal === 'request_internal_approval' ? this.assigneeNameForRelease : '',
        });
        this.$emit("goToNextStep")
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    clientListSearch(params) {
      return this.clientService.listAll(params).then((clients) => {
        if(clients && clients.list) {
          return clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    fetchDeclarations(item) {
      this.assigneeNameForRelease = item && item.name ? item.name : '';
      let filterStatus = '';
      this.declarationList = [];
      this.selectedDeclarations = [];
      if (this.getSteps().processType === 'request_data' || this.getSteps().processType === 'request_internal_approval') {
        filterStatus = 'draft,missing_data';
      } else if (this.getSteps().processType === 'request_client_approval' || this.getSteps().processType === 'client_release') {
        filterStatus = 'external_approval_requested';
      } else if (this.getSteps().processType === 'elster_submission') {
        filterStatus = 'elster_submission';
      } else if (this.getSteps().processType === 'internal_approval') {
        filterStatus = 'internal_approval_requested';
      }

      if(this.processTypeVal === 'request_internal_approval' && parseInt(this.assigneeForRelease) === 0) {
        return ;
      }

      if (
        filterStatus !== ''
        || this.getSteps().processType === 'document_creation'
        || this.getSteps().processType === 'tax_calculation_community'
      ) {
        this.$nextTick(() => this.$refs.declarationDualList.showloader = true);
        this.declarationService.listAll({
          "status": filterStatus,
          "processType": this.getSteps().processType,
          "userId": this.assigneeForRelease
        }, {
          "rows": -1
        }).then((response) => {
          if (response.totalRecordCount > 0) {
            this.declarationList = response.list.map((o) => {
              return {
                "id": o.id,
                "declarationId": o.uid,
                "name": o.property.name,
                "clientName": o.client.clientName,
                "clientId": o.clientId,
                "assignedUser": o.assignedUser,
                "elsterStatus": o.elsterStatus,
              };
            });
          } else if(this.getSteps().processType !== 'request_internal_approval') {
            this.$api.showToast(this.$t('declarations.mass_update.no_record_found'), "error");
            this.goBack();
          }

          this.$refs.declarationDualList.showloader = false;
        })
      }
    },
    validator(item) {
      return !(item.elsterStatus === '2' && this.processTypeVal === 'request_client_approval');
    },
    handleAllValidatorFails(items) {
      if (this.processTypeVal === 'request_client_approval') {
        this.$api.showToast(this.$t('declarations.mass_update.invalid_elster_status_all') + ': ' + items.map(items => items.declarationId).join(', '), "error");
      }
    },
    handleValidatorFail(item) {
      if (this.processTypeVal === 'request_client_approval') {
        this.$api.showToast(this.$t('declarations.mass_update.invalid_elster_status') + ': ' + item['declarationId'], "error");
      }
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.filter-fieldset {
  margin-top: 15px;
  border: 1px solid #dfdfdf;

  ::v-deep .p-fieldset-legend {
    margin-left: 15px;
    padding: 0 10px 0 10px;
    border: 0;
    background: unset;
  }
}
</style>
