<template>
  <div class="flex  flex-col items-center" v-if="notification && notification.id">
    <div class="flex w-full self-start mt-1 cursor-pointer" @click="notificationClicked">
      <img class="notification-icon notification-icon-filter mt-2" :src="getAssetPath(notification.icon)"/>
      <div class="flex flex-col flex-auto ml-3 mb-1 mr-2 items-center">
        <p class="notification-title self-start" :class="{'font-bold-text': !notification.isRead }">{{ notification.description }}</p>
        <p class="notification-sub-title self-start mt-1 ">{{ notification.formattedTime }}</p>
        <hr class="hr-line-user w-full mb-3 mt-3"/>
      </div>
    </div>
  </div>
</template>

<script>
import {UserService} from "@/services/user.service";
import {mapMutations} from "vuex";

export default {
  name: "NotificationItem",
  props: {
    notification: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      userService: new UserService()
    }
  },
  methods: {
    ...mapMutations("user", ["setNewNotificationCount"]),
    notificationClicked() {
      if (this.notification && this.notification.id) {
        if (!this.notification.isRead) {
          this.userService.readNotification(this.notification.id).then((response) => {
            if (response.data.success) {
              this.notification.isRead = 1;
              this.setNewNotificationCount(response.data.new_notification_count)
            }
          });
        }

        this.processNotification(this.notification);
      }

      this.$emit('notificationClicked', {
        id: this.notification.id
      })
    }
  }
}
</script>

<style scoped lang="scss">
.notification-bold-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
}

.notification-icon {
  width: 17px;
  height: 21px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.notification-title {
  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 16px;
  max-width: 520px;
}

.notification-sub-title {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
}
.font-bold-text {
  font-weight: 600 !important;
}

</style>
