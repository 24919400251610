<template>
  <div class="ml-15 mr-15">
    <!--    <label class="mb-20">{{ $t('register.step1.header') }}</label>-->

    <div class="flex justify-around space-x-8">
      <InputText class="w-full" :label="$t('components.report_steps.step1.label1')"
                 :showHelpIcon="true"
                 :tooltip="$t('components.report_steps.step1.tooltip.label1')"
                 :isRequired="true" placeholder=""/>
      <InputSelect class="w-full pr-6" :label="$t('components.report_steps.step1.label2')" :options="reportType"
                   :isRequired="true"
                   :showHelpIcon="true"
                   :tooltip="$t('components.report_steps.step1.tooltip.label2')"
                   :placeholder="$t('components.report_steps.step1.placeholder2')"/>

    </div>
    <div class="flex mt-6 justify-around space-x-8  ">
      <InputSelect class="w-full" :label="$t('components.report_steps.step1.label3')" :options="reportObject"
                   :isRequired="true"
                   :showHelpIcon="true"
                   :tooltip="$t('components.report_steps.step1.tooltip.label3')"
                   :placeholder="$t('components.report_steps.step1.placeholder3')"/>
      <InputSelect class="w-full pr-6 invisible" label="Report Object" :isRequired="true"
                   placeholder=""/>

    </div>
    <div class=" flex justify-start mt-4 mb-4">
      <InputCheck class="chk" :text="$t('components.report_steps.step1.label4')"/>
      <!--      <input type="checkbox" class="chk" v-model="isChecked" />-->
      <!--      <vnt-checkbox class="chk " v-model="isChecked">Persönlicher Bericht</vnt-checkbox>-->
    </div>
    <div class="flex justify-end space-x-4 mt-10 mr-6 pb-20">
      <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true"/>
      <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
    <!--    <div class="flex justify-around space-x-10 mt-6 ">
          <div class="w-full invisible"/>
          <div class="flex mt-10  justify-around w-full">
            <vnt-button class="w-full mr-4 ">{{ $t('buttons.cancel') }}</vnt-button>
            <vnt-button :click="submitNext" class=" label w-full bg-green  text-white">{{ $t('register.next') }}
            </vnt-button>
          </div>

        </div>-->

    <div>


    </div>
  </div>


</template>

<script>
// import InputText from "../inputs/InputText";
import {mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import InputSelect from "../../inputs/InputSelect";

export default {
  name: "Step1",
  components: {InputSelect},
  data() {
    return {
      reportType: this.$t('components.report_create.list2'),
      reportObject: this.$t('components.report_create.list1'),
      step1: {
        name: '',
        email: '',
        phone: ''
      },
      nameError: '',
      emailError: '',
      phoneError: '',
      isNameError: '',
      isEmailError: '',
      isPhoneError: ''
    }
  },
  validations: {
    step1: {
      name: {
        required
      },
      email: {
        required, email
      }
    },

  },
  computed: {
    ...mapGetters("register", ["getUserData"]),
  },
  mounted() {

    this.step1.name = this.getUserData.name
    this.step1.email = this.getUserData.email
    this.step1.phone = this.getUserData.phone
  },
  methods: {
    ...mapMutations("register", ["setFirstStepData"]),
    submitNext() {

      // this.$v.$touch() // we check the form to see if it's valid
      // if (this.$v.$invalid) {
      //   this.isNameError = this.$v.step1.name.$error
      //   this.nameError = this.$v.step1.name.$error ? this.$t('register.step1.nameError') : ""
      //   this.isEmailError = this.$v.step1.email.$error
      //   this.emailError = this.$v.step1.email.$error ? this.$t('register.step1.emailError') : ""
      //
      //   return; // if any of the validations fail, we don't move on
      // }
      this.setFirstStepData(this.step1)
      this.$emit("goToNextStep", this.step1);
    }
  }
}
</script>

<style scoped lang="scss">


.chk {

  //background-color: rgba(255, 255, 255, 0);

  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 15px;
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

</style>
