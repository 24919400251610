<template>
  <div class="ml-15 mr-15">
    <label class="mb-20">{{ $t('register.step2.header') }}</label>
    <div class="mt-6 space-y-4">
      <InputSelect v-if="!$isBusiness"
                   :label="$t('settings.account.fields.salutation')"
                   :isRequired="true"
                   :isError="errors.salutation_elster.invalid"
                   :error-message="errors.salutation_elster.invalid ? $t('general.errors.required') : ''"
                   v-model="step2.salutation_elster"
                   :selected-value="step2.salutation_elster"
                   :options="$t('general.salutation_elster_options')"
                   :placeholder="$t('general.please_select')"/>
      <InputText v-if="!$isBusiness"
                 v-show="showAccountName"
                 v-model="step2.account_fname"
                 :isError="errors.account_fname.invalid"
                 :error-message="errors.account_fname.invalid ? $t('general.errors.required') : ''"
                 :maxLength="25"
                 :label="$t('settings.account.fields.fname')"
                 :isRequired="true"
                 @keyup="submitNext"/>
      <InputText v-if="!$isBusiness"
                 v-show="showAccountName"
                 v-model="step2.account_lname"
                 :isError="errors.account_lname.invalid"
                 :error-message="errors.account_lname.invalid ? $t('general.errors.required') : ''"
                 :maxLength="25"
                 :label="$t('settings.account.fields.lname')"
                 :isRequired="true"
                 @keyup="submitNext"/>
      <InputText v-show="!showAccountName || $isBusiness"
                 ref="input"
                 v-model="step2.company"
                 :isError="errors.company.invalid"
                 :error-message="errors.company.invalid ? $t('general.errors.required') : ''"
                 :label="$t('settings.account.fields.company')"
                 :isRequired="true"
                 :placeholder="$t('settings.account.fields.company')"
                 @keyup="submitNext"/>
      <InputText v-model="step2.street"
                 :isError="errors.street.invalid"
                 :error-message="errors.street.invalid ? $t('general.errors.required') : ''"
                 :label="$t('register.step2.street_and_house_number')"
                 type="email"
                 :isRequired="true"
                 @keyup="submitNext"
                 :placeholder="$t('register.step2.streetPlaceholder')"/>
      <InputText v-model="step2.zip"
                 :label="$t('register.step2.zip')"
                 :isError="errors.zip.invalid"
                 :error-message="errors.zip.invalid ? $t('general.errors.required') : ''"
                 @keyup="submitNext"
                 @blur="searchZip"
                 :isRequired="true"
                 :isZip="true"
                 :zipCountry="step2.country"
                 :placeholder="$t('register.step2.zipPlaceholder')"/>
      <InputText v-model="step2.city"
                 :label="$t('register.step2.city')"
                 :isError="errors.city.invalid"
                 :error-message="errors.city.invalid ? $t('general.errors.required') : ''"
                 @keyup="submitNext"
                 :loading="zipSearch"
                 :isRequired="true"
                 :placeholder="$t('register.step2.cityPlaceholder')"/>
      <InputSelect :options="countries"
                   :isError="errors.country.invalid"
                   :error-message="errors.country.invalid ? $t('general.errors.required') : ''"
                   :label="$t('register.step2.country')"
                   :isRequired="true"
                   :filterable="true"
                   v-model="step2.country"
                   :placeholder="$t('register.step2.countryPlaceholder')"
                   :selectedValue="step2.country"/>
      <div class="flex mt-10 mb-10  justify-around space-x-4">
        <Button class="w-full" :text="$t('register.back')" :secondary="true" @click="goBack"/>
        <Button class="w-full" :text="$t('register.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import {ObserveVisibility} from 'vue-observe-visibility'

export default {
  name: "Step2",
  directives: {'observe-visibility': ObserveVisibility},
  props: {
    stepConfig: {
      type: Object
    }
  },
  data() {
    return {
      countries: [],
      myvalue: {},
      step2: {
        company: '',
        street: '',
        zip: '',
        city: '',
        country: "0",
        salutation_elster: "0",
        account_fname: '',
        account_lname: ''
      },
      zipSearch: false,

      errors: {
        "salutation_elster": {
          invalid: false
        },
        "account_fname": {
          invalid: false
        },
        "account_lname": {
          invalid: false
        },
        "company": {
          invalid: false
        },
        "street": {
          invalid: false
        },
        "zip": {
          invalid: false
        },
        "city": {
          invalid: false
        },
        "country": {
          invalid: false
        }
      }
    }
  },
  validations: {
    step2: {
      account_fname: {
        required: requiredIf(function () {
          return this.showAccountName && !this.$isBusiness;
        })
      },
      account_lname: {
        required: requiredIf(function () {
          return this.showAccountName && !this.$isBusiness;
        })
      },
      company: {
        required: requiredIf(function () {
          return !this.showAccountName || this.$isBusiness;
        })
      },
      street: {
        required
      },
      zip: {
        required
      },
      city: {
        required
      },
      country: {
        required
      }
    },
  },
  computed: {
    ...mapGetters("register", ["getUserData"]),
    showAccountName() {
      let person_salutations = ["1", "2", "3"]
      return person_salutations.includes(this.step2.salutation_elster);
    }
  },
  mounted() {
    this.step2.salutation_elster = this.getUserData.salutation_elster ? this.getUserData.salutation_elster : "0";
    this.step2.company = this.getUserData.company ? this.getUserData.company : "";
    this.step2.account_fname = this.getUserData.account_fname ? this.getUserData.account_fname : "";
    this.step2.account_lname = this.getUserData.account_lname ? this.getUserData.account_lname : "";
    this.step2.street = this.getUserData.street ? this.getUserData.street : "";
    this.step2.zip = this.getUserData.zip ? this.getUserData.zip : "";
    this.step2.city = this.getUserData.city ? this.getUserData.city : "";
    this.step2.country = this.getUserData.country ? this.getUserData.country : "0";
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    ...mapMutations("register", ["setSecondStepData"]),
    visibilityChanged() {
      this.$refs.input.setFocus()
    },
    getCountries() {
      this.$api.get_country_list().then((list) => {
        this.countries = list;
        if (this.step2.country === "0") {
          this.step2.country = this.defaultCountryId;
        }
      })
    },
    submitNext() {
      // Reset validation status
      for (let attr in this.errors) {
        this.errors[attr].invalid = false;
      }

      let hasError = false;
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        hasError = true;
        for (let attr in this.errors) {
          if(this.$v.step2[attr]) {
            this.errors[attr].invalid = this.$v.step2[attr].$error;
          }
        }
      }

      if (!this.$isBusiness && (this.step2.salutation_elster === '' || this.step2.salutation_elster === '0')) {
        this.errors.salutation_elster.invalid = true;
        hasError = true;
      }

      if (hasError) {
        return ;
      }

      this.setSecondStepData(this.step2)
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    },
    searchZip() {
      if (this.step2.zip !== '') {
        this.zipSearch = true;
        this.cityLookup(this.step2.zip).then((cities) => {
          if (cities.length > 0 && cities[0].code !== '') {
            this.step2.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}
</style>
