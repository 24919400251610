<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component mt-4"> Vermögenssteuer.pdf</p>
<!--drop down-->
    <div class="flex  w-full justify-end items-end">
      <div
          class="relative box flex items-center mr-6 inline-block text-left text-gray-800"
          v-on-clickaway="closeMenu" @click="isMenuOpen = !isMenuOpen"
      >
        <div>
      <span class=" ">
        <img class="box__image" src="@/assets/horizontal_dots_gray.svg"/>

      </span>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
          <div
              v-if="isMenuOpen"
              class="dropdown mt-48 origin-top-right absolute right-0 mt-2     overflow-hidden  z-20"
          >
            <div class="mb-2">
              <IconLabel :icon="require('@/assets/i_gray.svg')" label="Umbenennen" :blackText="true"/>
              <IconLabel :icon="require('@/assets/down_gray.svg')" label="Herunterladen" :blackText="true"/>
              <IconLabel class="mb-4" :icon="require('@/assets/delete_gray.svg')" label="Löschen" :blackText="true"/>
            </div>

          </div>
        </transition>
      </div>
    </div>
    <hr class=" hr-line-clients  mr-7 mb-1 mt-3 "/>


    <div class="w-full h-full  pr-7 mt-2">


    </div>


  </div>
</template>

<script>
import {directive as onClickaway} from 'vue-clickaway';

export default {
  name: "viewdocument",
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      showOverlay: false,
      isMenuOpen: false,
      breadcrumbs: [{
        title: 'Grundsteuererklärung',
        link: ''
      }, {
        title: 'Universitätsplatz 12, 34127',
        link: ''
      }, {
        title: 'Unterlagen',
        link: ''
      }, {
        title: 'Vermögenssteuer.pdf',
        link: ''
      }
      ],
    }
  },

  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
}
</script>

<style scoped lang="scss">
.overlaynew {


  width: 250px;
  height: auto;
  margin-left: 25px;
  //position: relative;
  //z-index: 5;
  bottom: 30px;
  //top: 150px;
  text-align: center;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  display: block;
}

//.showhim:hover .overlaynew {
//  display: block;
//  //position: absolute;
//}
.hidden {
  width: 0px;
  height: 0px;
  display: none;
}

.box {
  width: 36px;
  height: 33px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;

  &__image {
    width: 38px;
    height: 4px;
    box-sizing: border-box;
  }
}

.dropdown {
  width: 207px;
  padding: 2px 2px 2px 2px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;

}
</style>
