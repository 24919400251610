const state = {
    searchTxt: '',
    searchResult: {
        documents: [],
        clients: [],
        properties: [],
        declarations: []
    }
};
const getters = {
    getSearchTxt: (state) => state.searchTxt,
    getSearchResult: (state) => state.searchResult
}

const mutations = {
    setSearchTxt: (state, searchTxt) => {
        state.searchTxt = searchTxt
    },
    setSearchResult: (state, searchResult) => {
        state.searchResult = {...state.searchResult, ...searchResult};
    },
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
