<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader title="Mandant(en) hinzufügen" :breadcrumbs="breadcrumbs"/>
    <div v-if="!isDone">
      <div class="w-full flex flex-col pr-7 mt-8 ">
        <label class="text mb-4">Bitte wählen Sie Mandanten</label>
        <div class="flex justify-around space-x-10">
          <!--        col1-->
          <div class="w-full rectangle">
            <div class="flex justify-between ml-2 mr-2 mt-4 mb-8">
              <label class="text w-full mb-4">Alle Mandanten</label>
              <div
                  class="search pl-3 mt-1   focus:outline-none"
                  >
                <input
                    class="input  focus:outline-none"
                    placeholder="Suche"/>
              </div>
            </div>
            <!--table-->
            <div class="py-2 align-left  w-auto sm:px-2 lg:px-2">
              <div class="overflow-hidden border-b border-gray ">
                <table class=" table-fixed min-w-full divide-y divide-gray">
                  <thead class="">
                  <tr>
                    <th scope="col" class=" py-3 pr-6 text-left    ">
                      {{ $t('components.clients_table_col.col1') }}
                    </th>
                    <th scope="col" class="pr-6 py-3 text-left   ">
                      {{ $t('components.clients_table_col.col2') }}
                    </th>
                    <th scope="col" class="pr-6 py-3 text-left   ">
                      {{ $t('components.clients_table_col.col3') }}
                    </th>
                    <th></th>


                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray">
                  <tr v-for="person in clients" :key="person.email">
                    <td class=" py-4 whitespace-nowrap   ">

                      <p> {{ person.type }}</p>
                      <!--                    <div class="flex justify-between">-->
                      <!--                      <div class="flex">-->
                      <!--                        <div class=" icontable rounded-full w-4 h-4 mr-5 check flex justify-center items-center ">-->
                      <!--                          &lt;!&ndash;                      <img src="@/assets/check_white.svg"/>&ndash;&gt;-->
                      <!--                        </div>-->

                      <!--                        <p> {{ person.type }}</p>-->
                      <!--                      </div>-->


                      <!--                    </div>-->

                    </td>
                    <td class=" pr-6 py-4 whitespace-nowrap text-left">
                      <div class=" "><img class="icon-table" src="@/assets/icon_user_green.svg"/></div>
                    </td>
                    <td class="pr-6 py-4 whitespace-nowrap text-left">
                      <div class=" ">{{ person.street }}</div>
                    </td>
                    <!--              <td class="pr-6 py-4 whitespace-nowrap text-left ">-->
                    <!--                {{ person.city }}-->
                    <!--              </td>-->
                    <td class="pr-6 py-4 whitespace-nowrap text-left ">
                      <img class="icon-table" src="@/assets/circle_green.svg"/>
                    </td>
                    <td></td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--        col2-->
          <div class="w-full rectangle pr-6">
            <div class="flex justify-between ml-2 mr-2 mt-4 mb-8">
              <label class="text w-full mb-4">Ausgewählte Mandanten</label>
              <div
                  class="search pl-3 mt-1   focus:outline-none"
                  placeholder="Search">
                <input
                    class="input  focus:outline-none"
                    placeholder="Suche"/>
              </div>
            </div>
            <!--table-->
            <div class="py-2 align-left  w-auto sm:px-2 lg:px-2">
              <div class="overflow-hidden border-b border-gray ">
                <table class=" table-fixed min-w-full divide-y divide-gray">
                  <thead class="">
                  <tr>
                    <th scope="col" class=" py-3 pr-6 text-left    ">
                      {{ $t('components.clients_table_col.col1') }}
                    </th>
                    <th scope="col" class="pr-6 py-3 text-left   ">
                      {{ $t('components.clients_table_col.col2') }}
                    </th>
                    <th scope="col" class="pr-6 py-3 text-left   ">
                      {{ $t('components.clients_table_col.col3') }}
                    </th>
                    <th></th>


                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray">
                  <tr v-for="person in clients" :key="person.email">
                    <td class=" py-4 whitespace-nowrap   ">

                      <p> {{ person.type }}</p>
                      <!--                    <div class="flex justify-between">-->
                      <!--                      <div class="flex">-->
                      <!--                        <div class=" icontable rounded-full w-4 h-4 mr-5 check flex justify-center items-center ">-->
                      <!--                          &lt;!&ndash;                      <img src="@/assets/check_white.svg"/>&ndash;&gt;-->
                      <!--                        </div>-->

                      <!--                        <p> {{ person.type }}</p>-->
                      <!--                      </div>-->


                      <!--                    </div>-->

                    </td>
                    <td class=" pr-6 py-4 whitespace-nowrap text-left">
                      <div class=" "><img class="icon-table" src="@/assets/icon_user_green.svg"/></div>
                    </td>
                    <td class="pr-6 py-4 whitespace-nowrap text-left">
                      <div class=" ">{{ person.street }}</div>
                    </td>
                    <!--              <td class="pr-6 py-4 whitespace-nowrap text-left ">-->
                    <!--                {{ person.city }}-->
                    <!--              </td>-->
                    <td class="pr-6 py-4 whitespace-nowrap text-left ">
                      <img class="icon-table" src="@/assets/cross_green.svg"/>
                    </td>
                    <td></td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>


      </div>
      <div class="flex justify-end space-x-4 mt-6 mr-6">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
      <!--      <div class="w-full flex flex-col pr-7 mt-8  ">
              <div class="flex  justify-end space-x-4 mt-10 mr-6 mb-10 ">
                <vnt-button class="  ">{{ $t('buttons.back') }}</vnt-button>
                <vnt-button :click="submitNext" class=" label  bg-green  text-white">{{ $t('buttons.next') }}
                </vnt-button>
              </div>
            </div>-->
    </div>
    <div class="mt-20" v-else>
      <Finish/>
    </div>


  </div>
</template>

<script>

import Finish from "../clients/client_steps/Finish";

export default {
  name: "datevimport",
  components: {Finish},
  data() {
    return {
      isDone: false,
      breadcrumbs: [
        {
          title: 'Mandanten',
          link: ''
        },
        {
          title: 'Mandant(en) hinzufügen',
          link: ''
        }
      ],
      clients: [
        {
          type: 'A-278272',
          name: 'Frida Verse',
          street: 'Frida Verse',
          city: 'Kassel',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Timo Tauscher',
          street: 'Frida Verse',
          city: 'Kassel',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Hannes Bähr',
          street: 'Frida Verse',
          city: 'Berlin',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Friedrich Brantner',
          street: 'Frida Verse',
          city: 'Berlin',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Frida Verse',
          street: 'Frida Verse',
          city: 'Kassel',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Timo Tauscher',
          street: 'Frida Verse',
          city: 'Kassel',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Hannes Bähr',
          street: 'Frida Verse',
          city: 'Berlin',
          country: "Aktiv"
        },
        {
          type: 'A-278272',
          name: 'Friedrich Brantner',
          street: 'Frida Verse',
          city: 'Berlin',
          country: "Aktiv"
        },


      ],
    }
  },

  methods: {
    submitNext() {
      this.isDone = true;
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.rectangle {
  width: 648px;
  height: auto;
  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 1px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.input {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-top: -15px;
  margin-left: 25px;
  font-size: 13px;
}

.icon-table {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}
</style>
