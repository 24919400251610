<template>
  <MainLayout value="declaration">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MainLayout from "../layouts/MainLayout";

export default {
  name: "Declaration",
  components: {MainLayout},
  data() {
    return {}
  },
  computed: {
    ...mapGetters("declaration", ["getDeclarationDataChanged"]),
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('declaration');
  },
  methods: {
    ...mapMutations("declaration", ["setDeclarationDataChanged"]),
    handleComponentClick(data) {
      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          break;

          ////user Menu
        case 'add_user':
          this.currentComponent = 'adduser';

          break;
        case 'edit_user':
          this.currentComponent = 'EditUser';
          this.$router.push('/home/' + 'usermanagement')
          break;


          ///Property menu
        case 'add_property':
          this.currentComponent = 'AddProperty';

          break;
        case 'edit_property':
          this.currentComponent = 'EditProperty';

          break;
        case 'property_add_finish':
          this.currentComponent = 'Properties';

          break;
          ////Report Menu
        case 'edit_report':
        case 'create_report':
          this.currentComponent = 'CreateReport';

          break;
        case 'report_add_finish':
          this.currentComponent = 'Reports';

          break;
        case 'view_report':
          this.currentComponent = 'ViewReport';

          break;
        case 'edit_declaration':
          this.$router.push('/declaration/DeclarationDetails')
          break;
        case 'add_declaration':
          // this.currentComponent = 'AddDeclaration';
          this.$router.push('/declaration/AddDeclaration')

          break;
          /////Declaration menu
      }

    },
  },
  beforeRouteLeave (to, from, next)
  {
    if (this.getDeclarationDataChanged) {
      this.$modal.show({
        headerText: this.$t('general.unsaved-change-exist-header'),
        text: this.$t('general.unsaved-change-exist-info'),
        showClose: true,
        canEscape: true,
        inverseBtnColor: true,
        buttons: {
          ok: this.$t('general.leave-page'),
          cancel: this.$t('general.cancel'),
          delete: false
        },
        onConfirm: () => {
          this.setDeclarationDataChanged(false);
          next();
        },
        onCancel: () => {
          return;
        },
        onHide: () => {
          return;
        }
      });
    } else {
      next();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
