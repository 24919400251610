import Vue from "vue";
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import './styles/app.css';
import VueApexCharts from 'vue-apexcharts'
import * as VueMenu from '@hscmap/vue-menu'


import "primevue/resources/themes/fluent-light/theme.css";
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import {i18n} from "./plugins/i18n";
import {Loading, Modal} from './plugins/modal-plugin'
import VueMeta from 'vue-meta';
import VueObserveVisibility from "vue-observe-visibility";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import UUID from "vue-uuid";
import {Dropdown, Menu, Tooltip, VClosePopper, VTooltip} from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)
Vue.component('VDropdown', Dropdown)
Vue.component('VTooltip', Tooltip)
Vue.component('VMenu', Menu)
Vue.use(UUID);
Vue.use(VueMeta, {refreshOnceOnNavigation: true});
Vue.use(PrimeVue, {
    locale: i18n.t('primevue')
});
Vue.use(VueObserveVisibility);
Vue.use(ConfirmationService);
Vue.use(VueApexCharts)
Vue.use(Modal)
Vue.use(Loading)
const options = {
    position: "top-right",
    timeout: 15000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};
Vue.use(Toast, options);
Vue.use(VueMenu)
Vue.component('apexchart', VueApexCharts)
Vue.filter('kb', val => {
    return Math.floor(val / 1024);
});
Vue.use(VueRouter)
Vue.use(Vuelidate)
