<template>
  <div class="h-full bg-white pl-7 w-full overflow-y-hidden ">
    <PageHeader :title="$t('declarations.title')" :showBottomLine="true"/>

    <ModalDialog v-show="isModalVisible" @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <label class="dheader text-left ml-6 mt-4">Bearbeiter</label>
          <div class=" pr-8 mt-2 ml-2">
            <div class=" rounded-full mt-5 mb-5 flex justify-center ">
              <img class="profile-pic" src="@/assets/dummy_user.png"/>
            </div>
          </div>
          <div class="rectangle mr-6 pb-4">
            <div class=" ">
              <!--              header-->
              <div class="flex justify-start items-center h-14 ml-5">
                <label class="text-left text">Details</label>
              </div>
              <hr class=" line"/>
              <!--              content-->
              <div class="mt-3 ml-6">
                <div class="float-left text-left mr-8 ml-5 space-y-6">
                  <p class="text">Name:</p>
                  <p class="text">Email :</p>
                  <p class="text">Telefon :</p>
                </div>
                <div class="floating text-left mr-8 ml-10 space-y-6">
                  <p class="text-2">Lars Donner</p>
                  <p class="text-2">30-08-2021</p>
                  <p class="text-2">401-938-8937</p>
                </div>
              </div>

            </div>
          </div>
          <div class="flex justify-center space-x-10 mt-4 mr-6 ">
            <div class="flex mt-10 mb-10 justify-around w-full">
              <Button class=" " @click="handleModalClose" text="Schließen"></Button>
            </div>

          </div>
        </div>
      </template>

    </ModalDialog>
    <div class="flex w-full h-full justify-center items-center" v-if="!hasDeclarations">
      <div
        class="w-full h-full flex-1 space-y-3 table-container flex flex-col justify-center items-center mr-6"
        style="height: 340px">
        <img class="empty-declaration" :src="getAssetPath('empty.svg')"/>
        <label class="empty-text">{{ $t('general.grid.now_start') }}</label>
        <label class="empty-subtext">{{ $t('declarations.no_record_text') }}</label>
        <div class="flex w-full space-x-5 justify-center">
          <Button class="w-44" :text="$t('general.grid.create_new')" @click="addNew"/>
          <Button class="w-44" :text="$t('declarations.mass_create')" @click="bulkAdd"/>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="flex justify-between mt-3">
        <div class="flex">
          <!-- add declaration button-->
          <div class="add-declarations">
            <div class=" main justify-center items-center flex  cursor-pointer"
                 @click="newDeclaration">
              <div class=" mr-2 ">
                <img class="add-declarations__icon" :src="getAssetPath('add_declaration_green.svg')"/>
              </div>
              <div class="add-declarations__text flex-auto">{{ $t('declarations.add') }}</div>
            </div>
          </div>
          <!-- bulk add button -->
          <div class="add-declarations">
            <div class=" main justify-center items-center flex  cursor-pointer"
                 @click="bulkAdd">
              <div class="mr-2 ">
                <img class="add-declarations__icon" :src="getAssetPath('doc_cog.svg')"/>
              </div>
              <div class="add-declarations__text flex-auto">{{ $t('declarations.bulk_add') }}</div>
            </div>
          </div>
        </div>
        <div class="pr-7 flex justify-end ">
          <div class="flex mr-5" style="margin-top:2px">
            <img class="icon icon-filter1 cursor-pointer mt-2 mr-3" :src="sortIcon" @click="handleSortToggle"/>
            <InputSelect :options="$t('declarations.order_options')" :selectedValue="sortBy" @on-item-selected="sortingChanged"/>
          </div>
          <FilterInput :grid-id="gridId" :standard-filters="standardFilters" :columns="getColumns"
                       :show-view-menu="false"
                       :state-ful="true"
          />
        </div>
      </div>
      <div class="mainwrap overflow-x-scroll mr-7 mt-2 mb-2 " style="border:1px solid #dfdfdf">
        <div class="flex main-grid h-full">
          <Container
              orientation="horizontal"
              @drop="onColumnDrop($event)"
              drag-handle-selector=".column-drag-handle"
              :drop-placeholder="upperDropPlaceholderOptions"
              class="h-full"
            >
              <Draggable v-for="column in stagedDeclarations.children" :key="column.id" class="h-full declaration-dnd">
                <div class="card-container vertical-line h-full">
                  <div class="rectangle-top sticky top-0 flex flex-col items-center">
                    <div class="flex w-full justify-between">
                      <label class="text-top">{{ column.label }}</label>
                      <label class="text-top-right">
                        <span>{{ pagination[column.name].current }}</span>
                        <span>/</span>
                        <span>{{ pagination[column.name].total }}</span>
                      </label>
                    </div>
                    <label class="w-full self-start text-top"
                           v-if="!$isBusiness && column.name === 'external_approval_requested'">
                      <small>{{ $t('declarations.to_be_requested') }}</small>
                    </label>
                    <div class="w-full self-start text-top"
                         v-else-if="!$isBusiness && column.name === 'external_approval_waiting'">
                      <small>{{ $t('declarations.requested') }}</small>
                    </div>
                  </div>

                  <Container
                    group-name="col"
                    @drop="(e) => onCardDrop(column, e)"
                    :get-child-payload="getCardPayload(column.id)"
                    drag-class="card-ghost"
                    drop-class="card-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                    class="mt-2 "
                  >
                    <Draggable v-for="(item, index) in column.children" :key="column.name + '_' + index">
                      <DeclarationItem
                        :item="item"
                        :massElsterMessageTypes="massElsterMessageTypes"
                        @click="editDeclaration(item)"
                        @delete="deleteDeclaration"
                      />
                    </Draggable>
                  </Container>
                </div>
              </Draggable>
            </Container>
        </div>
      </div>
      <div class="flex paginator mr-7">
        <Paginator :rows="rowsPerPage"
                   template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                   :totalRecords="totalRecords"
                   :rowsPerPageOptions="rowsPerPageOptions"
                   :currentPageReportTemplate="$t('general.grid.page_info')"
                   @page="changePage"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd'

import { DeclarationService } from '@/services/declaration.service';
import { ClientService } from '@/services/client.service';
import { MassElsterMessageTypeService } from '@/services/MassElsterMessageType.service';
import { GridService } from '@/services/grid.service';
import { UserService } from '@/services/user.service';
import { BranchService } from '@/services/branch.service';

import Paginator from 'primevue/paginator';

import FilterInput from '@/components/inputs/FilterInput';
import IconLabel from '@/components/labels/IconLabel';
import DeclarationItem from '@/components/declarations/items/DeclarationItem';

export default {
  name: "Declarations",
  components: {
    IconLabel,
    DeclarationItem,
    FilterInput,
    Paginator,
    Container,
    Draggable
  },
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {
      gridId: 'declaration_board',
      service: new DeclarationService(),
      clientService: new ClientService(),
      gridService: new GridService(),
      massElsterMessageTypeService: new MassElsterMessageTypeService(),
      hasDeclarations: false,
      stagedDeclarations: {
        type: 'container',
        props: {
          orientation: 'horizontal'
        },
        children: []
      },
      search: '',
      pagination: {
        "draft": {
          "current": 0,
          "total": 0
        }
      },
      isModalVisible: false,
      sortIcon: require('@/assets/asc_icon.svg'),
      sortBy: 'client_name',
      sortDir: 1,
      defaultColumns: [
        {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          elType: 'text'
        }, {
          field: 'client',
          header: this.$t('declarations.grid.client'),
          filterLbl: this.$t('declarations.grid.client') + ' ' + this.$t('general.contains'),
          elType: 'text'
        }, {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          filterLbl: this.$t('declarations.grid.address') + ' ' + this.$t('general.contains'),
          elType: 'text',
        }, {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          elType: 'user_select',
        }, {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          elType: 'text',
        }, {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.type_options.filter_types'),
        }, {
          field: 'reference_date',
          header: this.$t('declarations.grid.reference_date'),
          elType: 'select',
          filterOptions: [],
        }, {
          field: 'fee',
          header: this.$t('declarations.grid.fee')
        }, {
          field: 'fee_invoice_sent',
          header: this.$t('declarations.grid.fee_invoice_sent'),
          elType: 'select',
          filterOptions: this.$t('declarations.grid.fee_invoice_sent_options'),
        }, {
          field: 'complexity',
          header: this.$t('declarations.grid.complexity'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options')
        }, {
          field: 'internal_note',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
        }, {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between'
        }, {
          field: 'branch_name',
          header: this.$t('declarations.grid.branch'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }
      ],
      standardFilters: [
        {
          selected: false,
          default: false,
          name: this.$t('declarations.standard_filters.my_declarations'),
          id: 'my_declarations'
        }
      ],
      rowsPerPage: 25,
      totalRecords: 0,
      currentOffset: 0,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      boardInitialized: false,
      navRecords: [],

      upperDropPlaceholderOptions: {
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },

      cardDropCount: 0,
      branchList: [],
      massElsterMessageTypes: []
    }
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? `ES Grundsteuer - ${this.$t('components.declaration')}` : `GrundsteuerDigital - ${this.$t('components.declaration')}`
    },
    getStages() {
      return this.$isBusiness ? this.$t('declarations.business_stages') : this.$t('declarations.stages_board')
    },
    getStageArr() {
      var stageArr = [];
      for (let x in this.getStages) {
        stageArr.push(x);
      }

      return stageArr;
    },
    hasMassUpdatePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.allow_mass_update) === 1;
    },
    getColumns() {
      let columns = [...this.defaultColumns];

      if (this.branchList.length > 0) {
        columns = columns.map((o) => {
          if (o.field === 'branch_name') {
            o.filterOptions = this.branchList;
          }

          return o;
        });
      }

      columns = columns.map((o) => {
        if (o.field === 'reference_date') {
          let declarationReferenceDateOptions = [];
          for (var i = 2022; i <= 2050; i++) {
            declarationReferenceDateOptions.push({
              'name': i,
              'code': i
            });
          }

          o.filterOptions = declarationReferenceDateOptions;
        }

        return o;
      });

      return columns;
    }
  },
  watch: {
    '$store.state.grid.isReloadGrid'(newValue, oldValue) {
      if (newValue !== oldValue) {
        let gridState = this.getGridState(this.gridId);
        if (gridState && gridState.gridId === this.gridId && gridState.reload) {
          let filters = {
            "selected": gridState.filter.selected ? gridState.filter.selected : '',
            "txt_filter": gridState.filter.txt ? gridState.filter.txt : ''
          };
          this.filterDeclarations(filters);
        }
      }
    },
  },
  created() {
    for (let x in this.getStages) {
      this.pagination[x] = {
        'total': 0,
        'current': 0
      }
    }

    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list.map((o) => {
        return {
          'name': o.name,
          'code': o.uid
        }
      });
    });
  },
  async mounted() {
    this.initGrid(this.gridId);
    if (!this.getGridState(this.gridId, 'stateLoaded')) {
      await this.gridService.getState(this.gridId).then((response) => {
        if (response.data.success) {
          let gridState = JSON.parse(response.data.state);
          if (Object.keys(gridState).length > 0) {
            gridState.gridId = this.gridId;
            gridState.stateLoaded = true;
            this.saveGridState(gridState);

            if (gridState) {
              this.applyState(gridState)
            }
          }
        }
      })
    } else {
      let gridState = this.getGridState(this.gridId);
      if (gridState) {
        this.applyState(gridState);
      }
    }

    // Apply filter if any saved in session
    let filters = {};
    let selectedSavedFilter = this.getSelectedFilterState(this.gridId);
    if (selectedSavedFilter) {
      if (selectedSavedFilter.filterId) {
        filters['selected'] = selectedSavedFilter.filterId;
      }

      if (selectedSavedFilter.txt) {
        filters['txt_filter'] = selectedSavedFilter.txt;
      }
    }

    this.filterDeclarations(filters);

    this.massElsterMessageTypeService.listAll().then((massElsterMessageTypes) => {
      this.massElsterMessageTypes = massElsterMessageTypes;
    });
  },
  methods: {
    ...mapMutations("declaration", ["setClientId", "setPropertyId", "setNewStatus", "setOldStatus", "setAddedElement", "setRemovedElement", "setAddElementStatus"]),
    ...mapGetters("declaration", ["getOldStatus", "getNewStatus", "getAddedElement", "getRemovedElement"]),
    ...mapMutations("grid", ["initGrid", "reloaded", "fetchedCount", "saveGridState", "triggerReload", "saveGridNavRecords"]),
    ...mapGetters("user", ["getCurrentTheme", "getCurrentUser"]),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),

    applyState(gridState) {
      if (gridState.dt_state) {
        if (typeof (gridState.dt_state.rows) !== 'undefined' && this.rowsPerPageOptions.includes(gridState.dt_state.rows)) {
          this.rowsPerPage = gridState.dt_state.rows;
        }
        if(gridState.dt_state.sortField) {
          this.sortBy = gridState.dt_state.sortField;
        }
        if(gridState.dt_state.sortOrder) {
          this.sortDir = gridState.dt_state.sortOrder;
          this.sortIcon = this.sortDir === 1 ? require('@/assets/asc_icon.svg') : require('@/assets/desc_icon.svg');
        }
      }
    },

    getTheme() {
      return this.getCurrentTheme()
    },
    clientListSearch(params) {
      return this.clientService.listMyClients(params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "name": o.client_id + ' - ' + o.client_name,
              "code": o.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    sortingChanged(item) {
      if(item.code && this.boardInitialized) {
        this.sortBy = item.code;
        this.triggerSaveState();
        this.triggerReload(this.gridId);
      }
    },
    handleSortToggle() {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
      this.sortIcon = this.sortDir === 1 ? require('@/assets/asc_icon.svg') : require('@/assets/desc_icon.svg');
      this.triggerSaveState();
      this.triggerReload(this.gridId);
    },
    addNew() {
      this.setClientId("0");
      this.setPropertyId("0");
      this.$router.push('/declaration/AddDeclaration')
    },

    resetStagedDeclarations() {
      this.stagedDeclarations = {
        type: 'container',
        props: {
          orientation: 'horizontal'
        },
        children: []
      };
    },

    filterDeclarations(filters) {
      if (typeof filters === 'undefined') {
        filters = {};
      }

      this.$loading.show();
      this.navRecords = [];

      // Fetch count
      let fetchCount = this.getGridState(this.gridId, 'fetchCount');

      if (fetchCount) {
        this.fetchedCount(this.gridId);
        let data = {...filters}
        let status = [];

        for (let stage in this.getStages) {
          status.push(stage);
        }
        data.status = status.join(",");
        this.service.listAll(data, {
          "requestType": "BOARD_PAGE_COUNT"
        }, false).then((count_by_status) => {
          if (count_by_status) {
            let declarationCount = 0;
            this.totalRecords = 0;
            for (let stage in this.getStages) {
              if (typeof (count_by_status[stage]) !== 'undefined') {
                this.pagination[stage].total = count_by_status[stage];

                let recordCount = parseInt(count_by_status[stage]);
                declarationCount += recordCount;
                if (recordCount > this.totalRecords) {
                  this.totalRecords = recordCount;
                }
              } else {
                this.pagination[stage].total = 0;
              }
            }

            if (!this.hasDeclarations) {
              this.hasDeclarations = declarationCount > 0;
            }
          }
        })
      }

      let promises = [];
      let columnId = 0;
      let draggableStages = ['draft', 'missing_data']

      for (let stage in this.getStages) {
        columnId ++;
        let childIndex = this.stagedDeclarations.children.findIndex(el => el.name === stage);
        if (childIndex === -1) {
          this.stagedDeclarations.children.push({
            id: `column${columnId}`,
            type: 'container',
            name: stage,
            draggable: draggableStages.includes(stage),
            label: this.getStages[stage],
            props: {
              orientation: 'vertical'
            },
            children: []
          });
        }

        let data = {...filters}
        data.status = stage;
        let params = {
          "requestType": "LIST",
          "rows": this.rowsPerPage,
          "offset": this.currentOffset,
          "sortBy": this.sortBy,
          "sortDir": this.sortDir
        }
        if (stage === 'external_approval_requested') {
          data["clientApprovalRequestSent"] = 0;
        } else if (stage === 'external_approval_waiting') {
          data["clientApprovalRequestSent"] = 1;
        }

        promises.push(this.service.listAll(data, params, false));
      }

      Promise.all(promises).then((responses) => {
        for (let resp of responses) {
          if (resp && resp.list && resp.stage) {
            this.pagination[resp.stage].current = resp.list.length;
            if (resp.list.length > 0) {
              this.hasDeclarations = true;
              let loop_count = resp.list.length;
              for(let k=0; k<loop_count; k++) {
                this.navRecords.push(resp.list[k]['id']);
                resp.list[k]['type'] = 'draggable';
              }
            }

            this.stagedDeclarations.children = this.stagedDeclarations.children.map((o) => {
              if(o.name === resp.stage) {
                o.children = resp.list;
              }
              return o ;
            });
          }
        }

        if (!this.hasDeclarations) {
          (new UserService()).getCurrentUserStats().then((stats) => {
            if(stats.declarationCount > 0) {
              this.hasDeclarations = true;
            }

            this.reloaded(this.gridId);
            this.$loading.hide();
            this.boardInitialized = true;
          });
        } else {
          this.reloaded(this.gridId);
          this.$loading.hide();
          this.boardInitialized = true;
        }
      });
    },
    newDeclaration() {
      this.setClientId("0");
      this.setPropertyId("0");
      this.$router.push('/declaration/AddDeclaration')
    },
    bulkAdd() {
      if(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_ops)) {
        if (this.hasMassUpdatePermission) {
          this.$router.push('/declaration/BulkAdd')
        } else {
          this.$api.showToast(this.$t('declarations.mass_update.no_permission'), "error")
        }
      } else {
        this.showPackageLimitWarning();
      }
    },
    editDeclaration(item) {
      this.$router.push({
        name: "Declaration",
        query: {
          'uid': item.id
        },
        params: {
          'comp': 'edit',
          'nav_records': this.navRecords
        }
      });
    },
    deleteDeclaration(item) {
      let data = [];
      data['records'] = [{
        "prim_uid": item.id
      }];
      this.service.trigger('declaration/delete', data, true).then((response) => {
        if (response.data.success) {
          this.setCurrentUserStatsDataChanged(true);

          let filters = {};
          let gridState = this.getGridState(this.gridId);
          if (gridState && gridState.gridId === this.gridId) {
            filters = {
              selected: gridState.filter.selected ? gridState.filter.selected : '',
              txt_filter: gridState.filter.txt ? gridState.filter.txt : ''
            };
          }

          this.filterDeclarations(filters);
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      })
    },
    handleModalClose() {
      this.isModalVisible = false
    },
    changePage(e) {
      if ((this.currentOffset !== e.first || this.rowsPerPage !== e.rows) && parseInt(e.first) < this.totalRecords) {
        this.rowsPerPage = e.rows;
        this.currentOffset = e.first;
        this.filterDeclarations();
        this.triggerSaveState();
      }
    },
    triggerSaveState() {
      this.saveGridState({
        "persistent": true,
        "gridId": this.gridId,
        "dt_state": {
          "rows": this.rowsPerPage,
          "sortField": this.sortBy,
          "sortOrder": this.sortDir
        }
      });
    },
    onColumnDrop (dropResult) {
      let stagedDeclarations = Object.assign({}, this.stagedDeclarations)
      stagedDeclarations.children = this.applyDrag(stagedDeclarations.children, dropResult)
      this.stagedDeclarations = stagedDeclarations
    },
    onCardDrop (columnObj, dropResult) {
      if ((dropResult.removedIndex !== null && dropResult.addedIndex === null) || (dropResult.removedIndex === null && dropResult.addedIndex !== null)) {
        let stagedDeclarations = Object.assign({}, this.stagedDeclarations)
        let column = stagedDeclarations.children.filter(p => p.id === columnObj.id)[0]
        let columnIndex = stagedDeclarations.children.indexOf(column)
        let newColumn = Object.assign({}, column)
        newColumn.children = this.applyDrag(newColumn.children, dropResult)
        stagedDeclarations.children.splice(columnIndex, 1, newColumn)
        this.stagedDeclarations = stagedDeclarations

        if (dropResult.removedIndex !== null) {
          this.setOldStatus(columnObj.name);
          this.setRemovedElement(dropResult.payload);
        }

        if (dropResult.addedIndex !== null) {
          this.setNewStatus(columnObj.name);
          this.setAddedElement(dropResult.payload);
        }

        this.cardDropCount ++;
        if (this.cardDropCount == 2) {
          this.onEnd();
        }
      }
    },
    getCardPayload (columnId) {
      return index => {
        return this.stagedDeclarations.children.filter(p => p.id === columnId)[0].children[index]
      }
    },
    applyDrag (arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      let result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },

    generateItems (count, creator) {
      let result = []
      for (let i = 0; i < count; i++) {
        result.push(creator(i))
      }
      return result
    },
    async onEnd() {
      this.cardDropCount = 0;

      const oldStatus = this.getOldStatus();
      const newStatus = this.getNewStatus();
      let moveStop = true;

      if (oldStatus === 'draft' && newStatus === 'missing_data') {
        moveStop = false;
      }

      // if (oldStatus === 'assessment_received') {
      //   if (newStatus === 'completed') {
      //     moveStop = false;
      //   }
      // } else if (oldStatus === 'draft') {
      //   if (newStatus === 'missing_data') {
      //     moveStop = false;
      //   } else if (newStatus === 'internal_approval_requested' && !this.$isBusiness) {
      //     moveStop = false;
      //   }
      // } else if (oldStatus === 'internal_approval_requested') {
      //   if ((newStatus === 'external_approval_requested' || newStatus === 'external_approval_waiting') && !this.$isBusiness) {
      //     moveStop = false;
      //   }
      // } else if (oldStatus === 'external_approval_requested') {
      //   if (newStatus === 'external_approval_waiting') {
      //     moveStop = false;
      //   }
      // }

      // if (this.getStageArr.indexOf(oldStatus) > this.getStageArr.indexOf(newStatus)) {
      //   moveStop = false;
      // }

      // if (oldStatus === newStatus) {
      //   moveStop = true;
      // }

      if (moveStop) {
        this.moveStop();
      } else {
        this.updateColumnHeader();
        var dragDropResult = await this.service.dragDropCompleted();
        if (dragDropResult) {
          this.setAddElementStatus(newStatus);
          const addedElement = this.getAddedElement();
          this.stagedDeclarations.children = this.stagedDeclarations.children.map((o) => {
            if (o.name === newStatus) {
              o.children = o.children.filter(el => el.id !== addedElement.id);
              o.children.push(addedElement);
            }

            return o;
          });
        } else {
          this.moveStop();
        }
      }
    },
    moveStop() {
      const removedElement = this.getRemovedElement();
      const addedElement = this.getAddedElement();

      this.stagedDeclarations.children = this.stagedDeclarations.children.map((o) => {
        if (o.name === this.getOldStatus()) {
          o.children.push(removedElement);
        } else if (o.name === this.getNewStatus()) {
          o.children = o.children.filter(el => el.id !== addedElement.id);
        }

        return o ;
      });
    },
    updateColumnHeader() {
      this.pagination[this.getOldStatus()].total --;
      this.pagination[this.getOldStatus()].current --;
      this.pagination[this.getNewStatus()].total ++;
      this.pagination[this.getNewStatus()].current ++;
    }
  }
}
</script>

<style scoped lang="scss">
.internal_approval_requested_header, .external_approval_requested_header {
  min-width: 165px !important;
}

.add-declarations {
  width: 156px;
  height: 40px;
  background-color: #ffffff;

  &__text {
    width: 117px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 600;
    color: theme('colors.muted_black');
    text-align: left;
    font-size: 14px;
  }

  &__icon {
    width: 27px;
    height: 27px;
    box-sizing: border-box;
  }
}

.transfer-icon {
  width: 33px;
  height: 28px;
}

.main-grid {
  //min-height: 80vh;
}

.rectangle-top {
  height: 58px;
  width: 100%;
  padding: 10px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  border-bottom: 1px solid #dfdfdf;
  z-index:1
}


.text-top {
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 14px;
  margin-right: 8px;

}

.text-top-right {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  font-size: 14px;
  margin-right: 5px;

  &__completed {
    color: $declaration-complete-text;
  }
}

.dheader {
  position: relative;
  top: -35px;
  left: -20px;
  //padding: 0 20px;
  max-width: 50%;

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.profile-pic {

  width: 112px;
  height: 111px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.rectangle {
  height: auto;
  padding-bottom: 15px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.line {
  color: #dfdfdf;

}

.floating {
  float: left;
  margin-left: 80px;
  padding-left: 8px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.text-2 {
  background-color: rgba(255, 255, 255, 0);

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.empty-declaration {
  width: 94px;
  height: 86px;
}

.empty-text {
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 25px;
}

.empty-subtext {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  font-size: 15px;
}

.vertical-line {
  width: 1px;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;

}

.mainwrap {
  //  scrollbar-color: #888686 transparent !important;
  height: calc(80vh - 120px);
  overflow: auto;
}

.boxScroll {
  height: calc(80vh - 180px);
  flex-grow: inherit;
  overflow-x: hidden;
  display: flow-root;
}

::-webkit-scrollbar:horizontal {
  /* any of these */
  height: 8px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  // background-color: #888686;
}

.paginator {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;

  ::v-deep {
    .p-paginator-current {
      position: absolute;
      right: 0;
      padding-right: 0;
      font-size: 13px;
    }

    .p-paginator {
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;

      .p-dropdown {
        width: 60px;
        height: 30px;
      }
    }

    .p-paginator-element {
      width: 30px !important;
      height: 30px !important;
      min-width: 10px !important;
      font-size: 13px !important;
    }

    .p-paginator-icon, .p-dropdown-trigger-icon, .p-dropdown-label, .p-dropdown-item {
      font-size: 13px;
    }

    .p-dropdown-label {
      padding: 5px !important;
    }

    .p-dropdown-trigger {
      width: 25px;
    }
  }
}

.card-container {
    width: 200px;
    padding-bottom: 10px;
}

.card {
    margin: 5px;
    /* border: 1px solid #ccc; */
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    padding: 10px;
}

.card-column-header {
    font-size: 18px;
}

.column-drag-handle {
    cursor: move;
    padding: 5px;
}
.declaration-dnd ::v-deep .smooth-dnd-container {
  min-height: 180px;
}

</style>
