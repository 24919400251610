<template>
  <div class="cell-content">
    <div class="prepend_icon">
      <VDropdown v-if="icon !== '' && detail.length > 0" :showTriggers="['click']" :popperTriggers="['click']" :hideTriggers="['click']">
        <img class="image ml-2 pointer" :src="getAssetPath(icon)"  v-if="icon !== ''" alt=""/>
        <template #popper>
          <div class="tooltip-warning">
              {{ detail }}
            <div v-if="helpLink.length > 0">
              <a :href="helpLink">{{ $t('general.more_info') }}</a>
            </div>
            <div v-if="declarationLink.length > 0">
              <a :href="declarationLink">{{ $t('general.to_declaration') }}</a>
            </div>
          </div>
        </template>
      </VDropdown>
    <img class="image ml-2" :src="getAssetPath(icon)" v-if="icon !== '' && detail.length === 0" alt=""/>
    </div>
      {{ message }}
    <a v-if="helpLink.length > 0 && detail === ''" :href="helpLink" target="_blank">{{ $t('general.more_info') }}</a>
    <a v-if="declarationLink.length > 0" :href="declarationLink">{{ $t('general.to_declaration') }}</a>
  </div>
</template>
<script>
import {i18n} from "@/plugins/i18n";
import Tooltip from 'primevue/tooltip';

export default {
  name: "TransferStatus",
  directives: {'tooltip': Tooltip},
  props: {
    id: {
      type: String
    },
    record: {
      type: Object
    }
  },
  data: function () {
    return {
      message: '',
      detail: '',
      errorCode: 0,
      helpLink: '',
      declarationLink: '',
      icon: '',
      tooltip: false
    }
  },
  mounted() {
    if(this.record.prim_uid && this.record.transferstatus && this.record.transferstatus === 'no_community') {
      var response = Object();
      response.status_code = 1001;
      response.declaration = this.record.prim_uid;
      response.fromList = true;
      this.updateGgwStatus(response);
    }

    if(typeof this.record.record_type !== 'undefined' && this.record.record_type === 'datev_ggw') {
      if (this.record.fee_invoice_sent === "1") {
        let transferDate = 0;
        if (this.record.last_object_values_success > 0) {
          transferDate = this.record.last_object_values_success;
        } else {
          transferDate = this.record.modified_at;
        }
        let dateObject = new Date(transferDate * 1000);
        this.message = i18n.t('components.ggw.results.transfered_at') + ' ' + dateObject.toLocaleDateString();
      }
    }
    this.$root.$on('update-transferstatus-' + this.id, this.updateStatus);
    this.$root.$on('update-ggw-status-' + this.id, this.updateGgwStatus);
    this.$root.$on('update-wk-ggw-status-' + this.id, this.updateWkGgwStatus);
    this.$root.$on('updated-community-' + this.id, this.updatedCommunityStatus);
  },
  methods: {
    updateStatus(response) {
      switch (response.status_code) {
        case 202:
          this.message = i18n.t('components.dms.results.success');
          this.icon = 'circle_tick.svg';
          break
        case 252:
          this.message = i18n.t('components.dms.results.e252');
          this.icon = 'warning.svg';
          break;
        case 255:
          this.message = i18n.t('components.dms.results.e255');
          this.icon = 'warning.svg';
          break;
        case 400:
          this.message = i18n.t('components.dms.results.e400');
          this.detail = i18n.t('components.dms.problemDetails.e400');
          this.icon = 'warning.svg';
          break;
        case 403:
          this.message = i18n.t('components.dms.results.e403');
          this.detail = i18n.t('components.dms.problemDetails.e403');
          this.icon = 'warning.svg';
          break;
        case 404:
          this.message = i18n.t('components.dms.results.e404');
          this.detail = i18n.t('components.dms.problemDetails.e404');
          this.icon = 'warning.svg';
          break;
        case 413:
          this.message = i18n.t('components.dms.results.e413');
          this.detail = i18n.t('components.dms.problemDetails.e413');
          this.icon = 'warning.svg';
          break;
        case 415:
          this.message = i18n.t('components.dms.results.e415');
          this.detail = i18n.t('components.dms.problemDetails.e415');
          this.icon = 'warning.svg';
          break;
        case 424:
          this.message = i18n.t('components.dms.results.e424');
          this.detail = i18n.t('components.dms.problemDetails.e424');
          this.icon = 'warning.svg';
          break;
        case 429:
          this.message = i18n.t('components.dms.results.e429');
          this.detail = i18n.t('components.dms.problemDetails.e429');
          this.icon = 'warning.svg';
          break;
        case 500:
        case 502:
        case 503:
          this.message = i18n.t('components.dms.results.e50x');
          this.detail = i18n.t('components.dms.problemDetails.e50x');
          this.icon = 'warning.svg';
          break;
      }

      if (
        typeof response.detail !== 'undefined'
        && response.detail !== null
        && typeof response.detail.instance !== 'undefined'
      ) {
        this.helpLink = response.detail.instance;
      }
    },
    updateGgwStatus(response) {
      switch (response.status_code) {
        case 202:
        case 200:
          this.message = i18n.t('components.ggw.results.success');
          this.icon = 'circle_tick.svg';
          break;
        case 503:
          this.message = i18n.t('components.ggw.results.e503');
          this.icon = 'warning.svg';
          this.helpLink = 'https://apps.datev.de/help-center/documents/1024678';
          break;
        case 1001:
          if(response.fromList === true) {
            this.message = i18n.t('components.ggw.results.e1001List');
          } else {
            this.message = i18n.t('components.ggw.results.e1001');
          }
          this.detail = i18n.t('components.ggw.problemDetails.e1001');
          this.declarationLink = '/#/declaration/edit?uid=' + response.declaration;
          this.icon = 'warning.svg';
          break;
        case 10000:
          this.message = i18n.t('components.ggw.results.e10000');
          this.icon = 'warning.svg';
          this.helpLink = 'https://apps.datev.de/help-center/documents/1024678';
          break;
        case 10001:
          this.message = i18n.t('components.ggw.results.e10001');
          this.icon = 'warning.svg';
          this.helpLink = 'https://apps.datev.de/help-center/documents/1024679';
          break;
        case 10002:
          this.message = i18n.t('components.ggw.results.e10002');
          this.icon = 'warning.svg';
          this.helpLink = 'https://apps.datev.de/help-center/documents/1024680';
          break;
        case 10003:
          this.message = i18n.t('components.ggw.results.e10003');
          this.icon = 'warning.svg';
          break;
        default:
          this.message = i18n.t('components.ggw.results.e500');
          this.icon = 'warning.svg';
          break;
      }
    },
    updateWkGgwStatus(response) {
      switch (response.status_code) {
        case 202:
        case 200:
          this.message = i18n.t('components.ggw.results.success');
          this.icon = 'circle_tick.svg';
          break;
        case 503:
          this.message = i18n.t('components.ggw.results.e503wk');
          this.icon = 'warning.svg';
          break;
        default:
          this.message = i18n.t('components.ggw.results.e500');
          this.icon = 'warning.svg';
      }
    },
    updatedCommunityStatus() {
      this.message = i18n.t('components.ggw.results.community_updated');
      this.icon = '';
      this.helpLink = '';
      this.declarationLink = '';
    },
    getTooltip() {
      return this.message;
    }
  }
}
</script>
<style scoped lang="scss">
.cell-content {
  .cell-content {
    width: 80%
  }
}

.prepend_icon {
  width: fit-content;
  float: left;
  padding-right: 10px;

  img {
    width: 18px;
    height: 20px;
  }
}

.pointer {
  cursor: pointer;
}

.tooltip-warning {
  padding: 10px;
  background: $primary;
  color: #fff;
  max-width: 300px;
  a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
