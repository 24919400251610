<template>
  <div class="flex justify-start items-center">
    <div class="flex justify-start items-center" v-for="(item,i) in getBreads" :key="i">
      <span class="breadchrumb" :class="descriptionById(item)" @click="handleClick(item)">{{ item.title }}</span>
      <img class="arrow" src="@/assets/right_arrow_gray.svg" v-show="breadcrumbs.length-1!==i"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "Breadchrumbs",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("client", ["getClientDataChanged"]),
    ...mapGetters("property", ["getPropertyDataChanged"]),
    getBreads() {
      return this.breadcrumbs
    },
    descriptionById: function () {
      return (item) => [{"nolink": item.link === ''}];
    }
  },
  methods: {
    ...mapMutations("client", ["setClientDataChanged"]),
    ...mapMutations("property", ["setPropertyDataChanged"]),
    customClasses(item) {
      return [{"nolink": item.link === ''}];
    },
    handleClick(item) {
      if(item.link) {
        const warningHeader = this.$t('general.unsaved-change-exist-header');
        const warningMsg = this.$t('general.unsaved-change-exist-info');
        if (item.link === '/property/list') {
          if (this.getPropertyDataChanged) {
            this.$modal.show({
              headerText: warningHeader,
              text: warningMsg,
              showClose: true,
              canEscape: true,
              inverseBtnColor: true,
              buttons: {
                ok: this.$t('general.leave-page'),
                cancel: this.$t('general.cancel'),
                delete: false
              },
              onConfirm: () => {
                this.setPropertyDataChanged(false);
                this.$router.push(item.link);
              }
            });
          } else {
            this.$router.push(item.link);
          }
        } else if (item.link === '/client/list') {
          if (this.getClientDataChanged) {
            this.$modal.show({
              headerText: warningHeader,
              text: warningMsg,
              showClose: true,
              canEscape: true,
              inverseBtnColor: true,
              buttons: {
                ok: this.$t('general.leave-page'),
                cancel: this.$t('general.cancel'),
                delete: false
              },
              onConfirm: () => {
                this.setClientDataChanged(false);
                this.$router.push(item.link);
              }
            });
          } else {
            this.$router.push(item.link);
          }
        } else {
          this.$router.push(item.link);
        }
      } else if(typeof item.handler === "function") {
        item.handler()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.arrow {
  width: 7px;
  height: 12px;
  margin-right: 7px;
  margin-left: 7px;
}

.breadchrumb {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
  cursor: pointer;

  &.nolink {
    cursor: default;
  }

}
</style>
