<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="getPageTitle" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" w-full mr-10 steps">
        <FormSteps :steps="steps"/>
      </div>
      <div class="w-full mr-10">
        <div class=" h-52" v-if="currentStep === 1">
          <div class="flex justify-center">
            <div class="flex items-center logo-container space-x-10">
              <img :src="getAssetPath('grst_connect_logo.svg')" class="connect_logo_1">
              <img :src="getAssetPath('green_plug_icon.svg')" class="connect_logo_2">
              <img :src="getAssetPath(taxSoftwareLogo)" class="connect_logo_3">
            </div>
          </div>
          <div class="flex justify-center">
            <label class=" label text-center mt-4 text-container">{{ taxSoftware === 'datev' ? $t('connect_gmi.datev.step1_info') : $t('connect_gmi.others.step1_info') }}</label>
          </div>
        </div>
        <div v-if="currentStep === 2">
          <div class="flex justify-center">
            <img :src="getAssetPath('Shield.svg')">
          </div>
          <div class="flex items-center flex-col">
            <label class="step2-block label text-center textbold mt-4 ml-5 mr-5">{{ $t('connect_gmi.step2_title')}}</label>
            <label class="step2-block label text-center mt-4 ml-5 mr-5" v-html="$t('connect_gmi.step2_info')"/>
            <div class="flex step2-block mt-5 cursor-pointer" @click="connectOptions='login'">
              <RadioButton name="radio-button" v-model="connectOptions" value="login"/>
              <span class="ml-4 label"> {{ $t('connect_gmi.login') }}</span>
            </div>
            <div class="flex step2-block mt-4 cursor-pointer mb-10" @click="connectOptions='signup'">
              <RadioButton name="radio-button" v-model="connectOptions" value="signup"/>
              <span class="ml-4 label"> {{ $t('connect_gmi.signup') }}</span>
            </div>
          </div>
        </div>
        <div class="mb-10" v-if="currentStep === 3">
          <div class="flex justify-center">
            <img :src="getAssetPath('gmi_logo.png')" class="gmi-logo">
          </div>
          <div class="flex items-center flex-col" v-if="connectOptions==='login'">
            <label class="w-1/2 label text-center textbold mt-4 ml-5 mr-5">{{ $t('connect_gmi.login_step.title')}}</label>
            <InputText v-model="username" :isError="isUsernameError" :errorMessage="$t('general.required')" type="email"
                       :label="$t('connect_gmi.login_step.email')" :isRequired="true" :requestFocus="true"
                       :placeholder="$t('connect_gmi.login_step.email')"
                       class="mt-5 w-4/12"
            />
            <InputText v-model="password" :isError="isPasswordError" :errorMessage="$t('general.required')" type="password"
                       :isRequired="true" :label="$t('connect_gmi.login_step.password')"
                       :placeholder="$t('connect_gmi.login_step.password')"
                       class="mb-1 w-4/12"/>
            <Button class="login-btn" :text="$t('connect_gmi.login_step.login_btn')" @click="doLogin"/>
            <div class="flex">
              <label class="label text-left mt-4 flex self-start mr-5" v-html="$t('connect_gmi.login_step.lost_password', {
                password_reset_url: getGMIPasswordResetUrl
              })"/>
              <label class="label text-left mt-4 flex self-end">
                {{ $t('connect_gmi.login_step.signup') }}
                <span class="cursor-pointer text-green" @click="connectOptions='signup'">{{ $t('login.signup') }}</span>
              </label>
            </div>
          </div>
          <div class="flex items-center flex-col" v-else>
            <label class="w-1/2 label text-center textbold mt-4 ml-5 mr-5">{{ $t('connect_gmi.signup_step.title')}}</label>
            <InputText v-model="username" :isError="isUsernameError" :errorMessage="$t('general.required')" type="email"
                       :label="$t('connect_gmi.login_step.email')" :isRequired="true" :requestFocus="true"
                       :placeholder="$t('connect_gmi.login_step.email')"
                       class="mt-5 w-4/12"
            />
            <div class="w-4/12">
              <InputCheck class="mt-3 mb-3 flex-1 " :text=" $t('connect_gmi.signup_step.accept_tos')"
                          :isChecked="tosAccepted"
                          @check="acceptTos"/>
            </div>
          </div>
        </div>
        <div class="mb-10" v-if="currentStep === 4">
          <div class="flex justify-center">
            <img :src="getAssetPath('success-1.svg')">
          </div>
          <div class="flex items-center flex-col">
            <label class="w-1/2 label text-center textbold mt-4 ml-5 mr-5">{{ $t('connect_gmi.completed.title')}}</label>
            <div class="items-center flex flex-col" v-if="taxSoftware === 'datev'">
              <label class="step4-block label text-center mt-4 ml-5 mr-5" v-html="$t('connect_gmi.completed.datev.info1')"/>
              <label class="step4-block label text-center mt-4 ml-5 mr-5" v-html="$t('connect_gmi.completed.datev.info2')"/>
              <label class="step4-block label text-center mt-1 ml-5 mr-5" v-html="$t('connect_gmi.completed.datev.info3')"/>
            </div>
            <div class="items-center flex-col flex" v-else>
              <label class="step4-block label text-center mt-4 ml-5 mr-5" v-html="$t('connect_gmi.completed.others.info1')"/>
            </div>
            <Button class="w-56 mt-5" :text="$t('general.ok')" @click="goToBilling"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-10 space-x-4 mr-7 mb-10" v-if="currentStep < 4">
      <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="moveStep('prev')"/>
      <Button class="w-56" :text="$t('buttons.next')" :disabled="disabledNext" @click="moveStep('next')"/>
    </div>
  </div>
</template>

<script>
import Breadchrumbs from "@/components/Breadchrumbs";
import FormSteps from "@/components/FormSteps";
import RadioButton from "primevue/radiobutton";
import {mapGetters} from "vuex";

export default {
  name: "ConnectGetMyInvoices",
  components: {Breadchrumbs, FormSteps, RadioButton},
  data: function () {
    return {
      breadcrumbs: [
        {
          title: this.$t('settings.system.settings'),
          link: ''
        },
        {
          title: this.$t('sidebar.popup.billing'),
          link: ''
        }
      ],
      currentStep: 1,
      connectOptions: '',
      username: '',
      password: '',
      isUsernameError: false,
      isPasswordError: false,
      tosAccepted: false,
      steps: [
        {
          id: 1,
          title: this.$t('connect_gmi.steps.step1'),
          completed: false,
          active: true,
          first: true
        },
        {
          id: 2,
          title: this.$t('connect_gmi.steps.step2'),
          completed: false,
          active: false
        },
        {
          id: 3,
          title: this.$t('connect_gmi.steps.step3'),
          completed: false,
          active: false,
          last: true
        },
        {
          id: 4,
          title: this.$t('connect_gmi.steps.step4'),
          completed: false,
          active: false,
          last: true
        }
      ]
    }
  },
  computed: {
    getGMIPasswordResetUrl() {
      return process.env.VUE_APP_GMI_PASSWORD_RESET_URL;
    },
    getPageTitle() {
      if(this.getCurrentUser().settings.tax_office_software === 'datev') {
        return this.$t('connect_gmi.datev.title');
      } else {
        return this.$t('connect_gmi.others.title');
      }
    },
    taxSoftwareLogo() {
      if(this.getCurrentUser().settings.tax_office_software === 'datev') {
        return 'connect_gmi_datev.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'buhl') {
        return 'bhb_logo.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'hmd-software') {
        return 'hmd-logo.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'lexware') {
        return 'lexware-vector-logo.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'addison-oneclick') {
        return 'oneclick-logo.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'simba') {
        return 'simba-logo.png';
      } else if(this.getCurrentUser().settings.tax_office_software === 'stotax') {
        return 'stotax-kanzlei-logo.png';
      } else {
        return 'andere_logo.png';
      }
    },
    taxSoftware() {
      return this.getCurrentUser().settings.tax_office_software;
    },
    disabledNext() {
      if(this.currentStep === 3) {
        if(this.connectOptions === 'signup' && !this.tosAccepted) {
          return true;
        } else if(this.connectOptions === 'login') {
          return true;
        }
      }

      return false;
    }
  },
  mounted() {
    if (this.getCurrentUser().gmi_connected && parseInt(this.getCurrentUser().gmi_connected) === 1) {
      this.$router.push('/setting/billing');
    }

    if (this.taxSoftware !== 'datev') {
      this.steps[2].title = this.$t('connect_gmi.others.step3_head')
    }

    if (this.$route.query.step && parseInt(this.$route.query.step) > 0) {
      for (let currentStep = 1; currentStep < parseInt(this.$route.query.step); currentStep++) {
        this.moveStep('next');
      }
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    acceptTos(checked) {
      this.tosAccepted = checked;
    },
    goToBilling() {
      this.$router.push('/setting/billing');
    },
    doLogin() {
      if(this.currentStep === 3 && this.connectOptions === 'login') {
        if(this.username.trim() !== '' && this.password.trim() !== '') {
          this.$api.trigger('connect_getmyinvoices/login', {
            email: this.username,
            password: this.password
          }, true).then((loginResponse) => {
            if (loginResponse.data.success) {
              this.$api.get_user(); // Update current user data in store
              this.moveStep('next');
            } else {
              this.$api.showToast(loginResponse.data.message, 'error');
            }
          })
        } else {
          this.isUsernameError = this.username.trim() === '';
          this.isPasswordError = this.password.trim() === '';
        }
      }
    },
    async moveStep(direction) {
      if(direction === 'prev' && this.currentStep === 1) {
        this.goBack();
      } else {
        if(this.currentStep === 2) {
          this.username = '';
          this.password = '';
          if(this.connectOptions === 'signup') {
            this.username = this.getCurrentUser().email;
          }
        }
        if(this.currentStep === 3 && this.tosAccepted && this.connectOptions === 'signup') {
          if(this.username.trim() !== '') {
            let signupResponse = await this.$api.trigger('connect_getmyinvoices/signup', {
              email: this.username,
              company_name: this.getCurrentUser().company_name,
              name: this.getCurrentUser().name
            }, true);
            if (!signupResponse.data.success) {
              this.$api.showToast(signupResponse.data.message, 'error');
              return false;
            } else {
              this.$api.get_user(); // Update current user data in store
            }
          } else {
            this.isUsernameError = true;
            return false;
          }
        }

        let step = direction === 'next' ? this.currentStep + 1 : this.currentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }
        });
        this.currentStep = step;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.steps ::v-deep {
  .step_3 {
    white-space: nowrap;
  }
  .step_4 {
    max-width: 130px;
  }
}
.gmi-logo {
  width: 63px;
  height: 63px;
}
.login-btn {
  width: 402px;
  ::v-deep .button {
    width: 100%;
    max-width: 100%;
  }
}
.connect_logo_1 {
  width: 152px;
  height: 54px;
}
.connect_logo_2 {
  width: 37px;
  height: 37px;
}
.connect_logo_3 {
  width: 84px;
}
.logo-container {
  width: 390px;
}
.text-container {
  width: 465px;
}
.step2-block, .step4-block {
  width: 620px;
}
</style>
