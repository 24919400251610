<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img src="@/assets/check_done.svg"/>
    </div>
    <label class="label__text mt-3 mb-4">{{ $t('declarations.add_declaration.steps.finish.success') }}</label>
    <div class="flex justify-end  mt-6 space-x-4 ">

      <Button class="w-56" :text="$t('declarations.add_declaration.steps.finish.edit')" @click="edit"/>
      <Button class="w-56" :text="$t('declarations.add_declaration.steps.finish.add_more')" @click="add_more"/>
      <Button v-if="!$isBusiness" class="w-56" :text="$t('declarations.add_declaration.steps.finish.edit_client')" @click="edit_client"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Finish",
  methods: {
    ...mapGetters("declaration", ["getSteps"]),
    edit() {
      this.$router.push('/declaration/edit?uid=' + this.$parent.declarationId)
    },
    add_more() {
      this.$emit('finishButtonClick')
    },
    edit_client() {
      this.$router.push('/client/edit?uid=' + this.$parent.clientId)
    }
  },
  mounted() {
      const localStorageKeys = ["previousPage", "clientId", "AddedProperty", "assignedUser", "flag"];
      localStorageKeys.forEach(function (item, index) {
        localStorage.removeItem(item);
      });
    }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;


  &__link {
    color: $primary;
  }

  &__text {
    font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 600;
    color: theme('colors.muted_black');
    text-align: center;
    line-height: normal;
  }
}
</style>
