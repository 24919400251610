<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('billing_trial.header')" :breadcrumbs="breadcrumbs" :showBottomLine="false"/>

    <div class="w-full pr-7 mt-5">
      <div class="gstb">{{ $t('billing_trial.gstb_number') }}: {{ this.getCurrentUser().account_id }}</div>
      <div class="flex justify-around space-x-10">
        <div class="rectangle w-full">
          <div>
            <div class="p-4 text-left">
              <span class="font-semibold w-full"> {{ $t('billing_trial.current_package') }}: </span>
              <span>Testphase</span>
            </div>

            <hr class="line"/>

            <div class="p-4 text-left">
              <span class="font-semibold">{{ $t('billing_trial.expiry_date') }}: </span>
              <span>{{ this.getCurrentUser().trial_end }} </span>
            </div>
          </div>
        </div>
      </div>

      <SelectPackageTable class="mt-3 pb-5"/>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import SelectPackageTable from '@/components/Billing/SelectPackageTable.vue';

export default {
  name: 'BillingTrial',
  components: {
    SelectPackageTable,
  },
  directives: {
    'tooltip': Tooltip,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('billing_trial.title1'),
          link: '',
        },
        {
          title: this.$t('billing_trial.title2'),
          link: '',
        },
      ],
    }
  },
  mounted() {
    this.checkUser();
  },
  created() {
    setTimeout(() => {
      if (this.getCurrentTheme() === 'ebnerstolz') {
        this.$router.push('/home');
      }
    }, 1000);
  },
  methods: {
    checkUser() {
      if (this.getCurrentUser().account_pay_type == 'paid') {
        this.$router.push('/setting/billing');
      }
    },
  }
}
</script>

<style scoped lang="scss">
.line {
  color: #dfdfdf;
}

.rectangle {
  border: 1px solid #dfdfdf;
}

.gstb {
  color: #aeaeae;
  text-align: right;
  line-height: normal;
  height: 26px;
}
</style>
