<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('clients.menu.add')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" mr-12 w-full">
        <FormSteps :steps="steps" v-if="!$isBusiness"/>
        <FormSteps :steps="stepsBusiness" v-if="$isBusiness"/>
      </div>
      <div class=" w-full">
        <div class="mt-10  mr-6 " v-if="completed===false">
          <component
              v-for="(item, index) in getSteps"
              :key="`component-${index}`"
              :is="item.component"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"
              :active="item.active === true"
              v-show="item.active === true"
          />
        </div>
        <div class="mt-10 mr-10 ml-10" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick"/>
        </div>
      </div>
    </div>
    <div class="w-full mt-8 mr-8">
    </div>
  </div>
</template>

<script>
import FormSteps from "../FormSteps";
import Step1 from '@/components/clients/client_steps/Step1.vue';
import Step2 from '@/components/clients/client_steps/Step2.vue';
import Step3 from '@/components/clients/client_steps/Step3.vue';
import Step4 from '@/components/clients/client_steps/Step4.vue';
import Finish from '@/components/clients/client_steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";
import {appMixin} from "@/core/utils/mixin";

export default {
  name: "manual",
  components: {FormSteps, Step1, Step2, Step3, Step4, Finish},
  mixins: [appMixin],
  data() {
    return {
      breadcrumbs: [{
        title: this.$t('clients.title'),
        link: '/client/list'
      }, {
        title: this.$t('clients.menu.add'),
        link: ''
      }],
      completed: false,
      steps: [
        {
          id: 1,
          title: this.$isBusiness ? this.$t('clients.steps.step1.title_business') : this.$t('clients.steps.step1.title'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        }, {
          id: 2,
          title: this.$t('clients.steps.step2.title'),
          completed: false,
          active: false,
          component: "Step2"
        }, {
          id: 3,
          title: this.$t('clients.steps.step3.title'),
          completed: false,
          active: false,
          component: "Step3"
        }, {
          id: 4,
          title: this.$t('clients.steps.step4.title'),
          completed: false,
          active: false,
          last: true,
          component: "Step4"
        },
      ],
      stepsBusiness: [
        {
          id: 1,
          title: this.$t('clients.steps.step1.title'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        }, {
          id: 2,
          title: this.$t('clients.steps.step2.title'),
          completed: false,
          active: false,
          component: "Step2"
        }, {
          id: 3,
          title: this.$t('clients.steps.step3.title'),
          completed: false,
          active: false,
          last: true,
          component: "Step3"
        }
      ],
    }
  },

  computed: {
    ...mapGetters("client", ["getCurrentStep", "getClientData"]),
    getSteps() {
      return this.$isBusiness ? this.stepsBusiness : this.steps
    },
    // isBusiness() {
    //   return process.env.VUE_APP_DEV_VARIANT === 'business'
    // }
  },
  mounted() {
    if(!this.getClientData.branch) {
      this.$router.go(-1);
    }
  },
  methods: {
    ...mapMutations("client", ["setCurrentStep"]),
    goToNextStep() {
      this.$isBusiness ? this.moveStepBusiness('next') : this.moveStep('next')
    },
    goBack() {
      this.$isBusiness ? this.moveStepBusiness('back') : this.moveStep('back')
    },
    finish() {
      this.$isBusiness ? this.moveStepBusiness('next', true) : this.moveStep('next', true)
      this.completed = true
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }

        })

        this.setCurrentStep(step)
      }

    },
    moveStepBusiness(direction, isLast) {
      if (isLast) {
        this.stepsBusiness = this.stepsBusiness.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.stepsBusiness.findIndex(item => item.id === step)
        this.stepsBusiness = this.stepsBusiness.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }

        })

        this.setCurrentStep(step)
      }

    },
    handleFinishButtonClick() {
      this.$emit("click", "client_add_finish")
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
