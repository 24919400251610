<template>
  <div>
    <div class="flex flex-row justify-between">
      <div class="flex mt-2" v-if="!isReadOnly">
        <UserMenu v-for="(menu,index) in appMenus" :key="index" :icon="menu.icon" :title="menu.title"
                  :canShow="menu.canShow"
                  :disabled="menu.disabled || isInTransfer" :disabledIcon="menu.icon_disabled"
                  :sub_menu="menu.sub_menu"
                  :slug="menu.slug"
                  @submenu-click="handleSubMenuClick"
                  @click="handleClick(menu)"
        />
      </div>
      <div class="mt-2" v-if="showSearch">
        <FilterInput :grid-id=gridId :standard-filters="standardFilters" :columns="columns" :show-filter="showFilter"
                     :show-view-menu="showViewMenu"
                     :state-ful="stateFulFilter"
                     @isSearchActive="searchActive"/>
      </div>
    </div>
    <div class="w-full mt-8">
      <DataGrid
          ref="datatable"
          :grid-type="gridType"
          :data-endpoint="dataEndpoint"
          :count-endpoint="countEndpoint"
          :columns="columns"
          :grid-id="gridId"
          :default-sort-attr="defaultSortAttr"
          :default-sort-dir=defaultSortDir
          :selectable="selectable"
          :expandable="expandable"
          :expansionFormat=expansionFormat
          :selection-data-key="selectionDataKey"
          :navigation-data-key="navigationDataKey"
          :resizable-columns="resizableColumns"
          :reorderable-columns="reorderableColumns"
          :page-length="pageLength"
          :virtual-row-height="virtualRowHeight"
          :pagination="pagination"
          :server-params="serverParams"
          :show-select-col="showSelectCol"
          :auto-load="autoLoad"
          :local-data="localData"
          :use-local-data="useLocalData"
          :state-ful="stateFul"
          :state-ful-filter="stateFulFilter"
          :show-create-record="showCreateRecord"
          :show-dms-notice="showDmsNotice"
          :always-show-pagination="alwaysShowPagination"
          :is-read-only="isReadOnly"
          :show-state-loader="showStateLoader"
          :edit-mode="editMode"
          @selected-filters="handleSelectedFilters"
          @reset-selection="resetSelection"
          @row-selection="rowSelection"
          @col-click="handleColClick"
          @col-check="handleColCheck"
          @cell-edit-completed="cellEditCompleted"
          @prepend-icon-click="handlePrependIconClick"
          @action-menu-click="handleActionMenuClick"
          @create-new="createNew"
          :searchActive="isSearchActive"
          @on-item-selected="selectItem"
          @on-data-loaded="onDataLoaded"
          @on-community-save="onCommunitySave"
          @on-extra-data-loaded="onExtraDataLoaded"
      />
    </div>
  </div>
</template>

<script>

import DataGrid from "./DataGrid";
import FilterInput from "./inputs/FilterInput";
import {mapMutations} from "vuex";
import {ApiService} from "@/services/api.service";

export default {
  name: "AppGrid",
  components: {
    DataGrid, FilterInput
  },
  props: {
    gridType: {
      type: String,
      default: ""
    },
    gridId: {
      type: String,
      default: ""
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    dataEndpoint: {
      type: String,
      default: ""
    },
    countEndpoint: {
      type: String,
      default: ""
    },
    columns: {
      type: Array,
      default: () => []
    },
    localData: {
      type: Array,
      default: () => []
    },
    useLocalData: {
      type: Boolean,
      default: false
    },
    defaultSortAttr: {
      type: String,
      default: ""
    },
    defaultSortDir: {
      type: Number,
      default: -1
    },
    resizableColumns: {
      type: Boolean,
      default: true
    },
    reorderableColumns: {
      type: Boolean,
      default: true
    },
    pageLength: {
      type: Number,
      default: 25
    },
    virtualRowHeight: {
      type: Number,
      default: 40
    },
    pagination: {
      type: Boolean,
      default: true
    },
    alwaysShowPagination: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectionDataKey: {
      type: String,
      default: ''
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expansionFormat: {
      type: String,
      default: ''
    },
    navigationDataKey: {
      type: String,
      default: ''
    },
    menus: {
      type: Array,
      default: () => []
    },
    standardFilters: {
      type: Array,
      default: () => [],
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    selectedColumns: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    serverParams: {
      type: Object,
      default: () => {
      }
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    showSelectCol: {
      type: Boolean,
      default: true
    },
    stateFul: {
      type: Boolean,
      default: true
    },
    stateFulFilter: {
      type: Boolean,
      default: false
    },
    showCreateRecord: {
      type: Boolean,
      default: true
    },
    showDmsNotice: {
      type: Boolean,
      default: false
    },
    showViewMenu: {
      type: Boolean,
      default: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    showStateLoader: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isSearchActive: false,
      hasError: false,
      service: new ApiService(),
      selectedFiltersValue: [],
      selectedRecords: [],
      appMenus: [],
      _selectedColumns: [],
      isInTransfer: false,
      transfered: []
    }
  },
  watch: {
    selectedRecords() {
      this.rowSelection(this.selectedRecords);
    },
    serverParams: {
      handler: function () {
        if (this.serverParams && Object.keys(this.serverParams).length > 0) {
          this.service.setDefaultParams(this.serverParams)
        }
      },
      deep: true
    },
    menus: {
      handler: function (nv) {
        this.appMenus = this.menus;
      },
      deep: true
    }
  },
  created() {
    if (this.serverParams && Object.keys(this.serverParams).length > 0) {
      this.service.setDefaultParams(this.serverParams)
    }
    this.appMenus = this.menus;
    this._selectedColumns = this.selectedColumns
  },
  methods: {
    ...mapMutations("grid", ["triggerReload", "triggerFetchCount"]),
    searchActive(status) {
      this.isSearchActive = status
    },
    resetSelection() {
      this.selectedRecords = [];
    },
    rowSelection(selectedRecords) {
      this.selectedRecords = selectedRecords;
      // Disable/enable menus as per rows selection
      this.appMenus = this.menus.map(o => {
        if (selectedRecords.length === 1) {
          if (o.enable_on_select) {
            o.disabled = false
          }
        } else if (selectedRecords.length > 1) {
          if (o.disable_on_multi) {
            o.disabled = true;
          } else if (o.enable_on_select) {
            o.disabled = false
          }
        } else {
          if (o.enable_on_select) {
            o.disabled = true
          }
        }
        return o;
      });

      this.$emit('row-selection', this.selectedRecords);
    },
    handleSelectedFilters(value) {
      this.selectedFiltersValue = value;
    },
    handleItemSelected(theItem) {
      this.field = theItem.name
      this.$emit('on-item-selected', theItem)
    },
    handleClick(menu) {
      this._selectedColumns = this.columns.filter(o => o.isChecked);
      if (!menu.disabled) {
        if (menu.id === 'dms.transfer') {
          let i = 0;
          if (this.selectedRecords[i].description.length > 255) {
            this.$root.$emit('update-transferstatus-' + this.selectedRecords[i].prim_uid, {'status_code': 255});
          } else if (this.selectedRecords[i].description.length < 2) {
            this.$root.$emit('update-transferstatus-' + this.selectedRecords[i].prim_uid, {'status_code': 252});
          } else {
            if (!(this.selectedRecords[i].prim_uid in this.transfered)) {
              this.$api.trigger('/datev/dms_transfer', {
                'id': this.selectedRecords[i].prim_uid,
                'description': this.selectedRecords[i].description
              }).then((response) => {
                this.$root.$emit('update-transferstatus-' + this.selectedRecords[i].prim_uid, response.data);
                this.selectedRecords.splice(i, 1);
                //only go on when errors 424 or 429 did not occur
                if (response.data.status_code !== 424 && response.data.status_code !== 429 && this.selectedRecords.length > 0) {
                  this.handleClick(menu);
                }

                if (response.data.status_code === 202 || response.data.status_code === 200) {
                  this.transfered[response.data.id] = true;
                }
              });
            } else {
              this.selectedRecords.splice(i, 1);
              //only go on when errors 424 or 429 did not occur
              if (this.selectedRecords.length > 0) {
                this.handleClick(menu);
              }
            }
          }
        }
        if (menu.id === 'grid_reload') {
          this.triggerReload(this.gridId);
        } else if (menu.action_endpoint) {
          if (menu.action_confirmation && menu.action_confirmation.show) {
            let cannotRecordUids = [];
            this.appMenus = this.selectedRecords.map(o => {
              if (o.cannot_delete === 1) {
                cannotRecordUids.push(o.uid);
              }
            });

            if (menu.id === 'dec.delete' && cannotRecordUids.length > 0) {
              let transmittedMessage = this.$t('general.grid.cannot_delete_confirm').replace('%declaration_uids%', cannotRecordUids.join(", "));
              this.$api.showToast(transmittedMessage, 'error');
              return;
            }

            let params = {
              headerText: this.$t('general.grid.delete_record'),
              title: this.$t('general.grid.delete_confirm'),
              showClose: true,
              canEscape: true,
              onConfirm: () => {
                this.trigger_action(menu.action_endpoint, {
                  records: this.selectedRecords
                }, menu.callback)
              }
            };

            if (this.selectedRecords.length === 1 && typeof (menu.action_confirmation.single) !== 'undefined') {
              if (typeof (menu.action_confirmation.single.title) !== 'undefined') {
                params.headerText = menu.action_confirmation.single.title;
              }
              if (typeof (menu.action_confirmation.single.message) !== 'undefined') {
                params.title = menu.action_confirmation.single.message;
              }
            } else if (this.selectedRecords.length > 1 && typeof (menu.action_confirmation.multiple) !== 'undefined') {
              if (typeof (menu.action_confirmation.multiple.title) !== 'undefined') {
                params.headerText = menu.action_confirmation.multiple.title;
              }
              if (typeof (menu.action_confirmation.multiple.message) !== 'undefined') {
                params.title = menu.action_confirmation.multiple.message;
              }
            }

            if (typeof (menu.action_confirmation.buttons) !== 'undefined' && typeof (menu.action_confirmation.buttons.ok) !== 'undefined') {
              params.buttons = {
                "ok": menu.action_confirmation.buttons.ok
              };
            }

            this.$modal.show(params);
          } else {
            this.trigger_action(menu.action_endpoint, {
              records: this.selectedRecords
            }, menu.callback)
          }
        } else {
          this.$emit('menu-clicked', {
            "menuId": menu.id,
            "data": this.selectedRecords,
            "filter": this.getGridState(this.gridId).filter,
            "selectedColumns": this._selectedColumns
          })
        }
      }
      if (menu.id === 'ggw.transfer' && (!menu.disabled || this.isInTransfer === true)) {
        if (this.isInTransfer === false) {
          this.isInTransfer = true;
        }
        this.$api.trigger('/datev/ggw_transfer', {
          'id': this.selectedRecords[0].prim_uid,
        }).then((response) => {
          this.$root.$emit('update-ggw-status-' + this.selectedRecords[0].prim_uid, response.data);
          this.selectedRecords.splice(0, 1);
          if (this.selectedRecords.length > 0) {
            this.handleClick(menu);
          } else {
            this.isInTransfer = false;
          }
        });
      } else if (menu.id === 'ggw.transfer_wk') {
        if (this.isInTransfer === false) {
          this.isInTransfer = true;
        }
        this.$api.trigger('/addison/ggw_transfer', {
          'id': this.selectedRecords[0].prim_uid,
        }).then((response) => {
          this.$root.$emit('update-wk-ggw-status-' + this.selectedRecords[0].prim_uid, response.data);
          this.selectedRecords.splice(0, 1);
          if (this.selectedRecords.length > 0) {
            this.handleClick(menu);
          } else {
            this.isInTransfer = false;
          }
        });
      }
    },
    handleSubMenuClick(subMenuId) {
      this.$emit('menu-clicked', {
        "menuId": subMenuId,
        "data": this.selectedRecords,
        "filter": this.getGridState(this.gridId).filter,
        "selectedColumns": this._selectedColumns
      })
    },
    handlePrependIconClick(e) {
      this.$emit('prepend-icon-click', e);
    },
    handleColClick(e) {
      this.$emit('col-click', e);
    },
    handleColCheck(e) {
      this.$emit('col-check', e);
    },
    cellEditCompleted(e) {
      this.$emit('cell-edit-completed', e);
    },
    handleActionMenuClick(e) {
      this.$emit('action-menu-click', e);
    },
    trigger_action(endpoint, data, callback) {
      return this.service.trigger(endpoint, data, true).then(response => {
        this.selectedRecords = [];
        if (response.data.success) {
          if (typeof callback === "function") {
            callback(response);
          } else if (response.data.message) {
            this.$api.showToast(response.data.message, 'success');
          }
        } else {
          if (typeof response.data.prevent_uids !== 'undefined' && typeof callback === "function") {
            callback(response);
          } else {
            this.$api.showToast(response.data.message, 'error');
          }
        }
        this.triggerFetchCount(this.gridId);
        this.triggerReload(this.gridId);

        return response;
      });
    },
    createNew() {
      this.$emit('create-new');
    },
    selectItem(theItem) {
      this.$emit('on-item-selected', theItem);
    },
    onDataLoaded(data) {
      this.$emit('on-data-loaded', data);
    },
    onExtraDataLoaded(extraData) {
      this.$emit('on-extra-data-loaded', extraData);
    },
    onCommunitySave({communityId, declarationId, save}) {
      this.$emit(
        'on-community-save',
        {
          communityId: communityId,
          declarationId: declarationId,
          save: save,
        }
      );
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.menu-icon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  cursor: pointer;
}

.disabled_menu label {
  color: #b0b0b0;
}

.menu-label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
  text-align: left;
  margin-left: 10px;
  cursor: pointer;
  word-wrap: inherit;
}

.user_submenus {
  z-index: 99;
}

</style>
