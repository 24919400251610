<template>
  <div>
    <div class="mt-18 flex flex-col" v-observe-visibility="{
              callback: stepVisible
             }">
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.manage_property')"
                    :isChecked="step2.manage_property"
                    :is-disabled="disablePermissions"
                    @check="clicked_manage_property"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.release_declaration')"
                    :is-disabled="disablePermissions"
                    @check="clicked_release_declaration"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.elster_transaction')"
                    :is-disabled="disablePermissions"
                    @check="clicked_elster_transaction"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.allow_mass_update')"
                    :is-disabled="disablePermissions"
                    @check="clicked_allow_mass_update"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.can_force_delete_clients')"
                    :is-disabled="disablePermissions"
                    @check="clicked_can_force_delete_clients"
        />
      </div>
      <div class="flex flex-row mb-4 fit" v-if="datevAccount">
        <InputCheck :text="$t('settings.user_management.permissions.can_handover_item_values')"
                    @check="clicked_can_handover_item_values"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.send_dispute')"
                    :is-disabled="disablePermissions"
                    :is-checked="step2.send_dispute"
                    @check="clicked_send_dispute"
        />
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.change_management')"
                    :is-checked="step2.change_management_access"
                    :is-disabled="disablePermissions"
                    @check="clicked_change_management"/>
      </div>
      <div class="flex flex-row mb-4 fit">
        <InputCheck :text="$t('settings.user_management.permissions.all_branch')"
                    :is-checked="step2.all_branch_access"
                    :is-disabled="disablePermissions"
                    @check="clicked_all_branch"/>
      </div>
      <div class="pb-6 w-1/2 fit" v-show="!step2.all_branch_access && !disablePermissions">
        <div class="flex justify-start">
          <label class=" mb-1  ">{{ $t('settings.user_management.permissions.choose_branch') }}</label>
        </div>
        <AutoComplete
            v-if="!branchLoading"
            v-model="step2.selected_branches"
            :multiple="true"
            field="name"
            :suggestions="filteredBranches"
            @complete="searchBranch($event)"
            @item-select="branchSelected"
            @item-unselect="branchSelected"
            forceSelection
            :auto-highlight="true"
        />
        <label class="sub-title mt-2">{{ $t('settings.user_management.permissions.instruction') }}</label>
      </div>
      <div v-show="!hideClientAccess" class="fit">
        <InputCheck :text="$t('settings.user_management.permissions.all_clients')"
                    :is-checked="step2.all_client_access"
                    :is-disabled="disablePermissions"
                    @check="clicked_all_client"/>
      </div>
      <div v-show="!step2.all_client_access && !hideClientAccess">
        <div class=" mt-6 mb-1 lbl text-left">{{ $t('settings.user_management.select_clients') }}<span
            style="color:red;">*</span></div>
        <DualListSelect :list="available_clients"
                        dataKey="prim_uid"
                        :totalRecordCount="totalClientCount"
                        @filterList="clientListSearch"
                        :enableAllSelect="true"
                        v-model="step2.selected_clients"
                        :columns="$isBusiness ? columnsBusiness : columnsTc"
                        :leftHeader="$t('settings.user_management.all_clients')"
                        :rightHeader="$t('settings.user_management.selected_clients')"
                        :showLeftHelpIcon="true"
                        :leftTooltip="$t('settings.user_management.tooltip.step2.dualListTooltipLeft')"
                        :showRightHelpIcon="true"
                        :rightTooltip="$t('settings.user_management.tooltip.step2.dualListTooltipRight')"/>
      </div>
      <div class="flex justify-end  mt-6 space-x-4 ">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';
import {mapGetters, mapMutations} from "vuex";
import InputCheck from "../../inputs/InputCheck";
import {ClientService} from "@/services/client.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step2",
  components: {InputCheck, AutoComplete},
  data() {
    return {
      clientService: new ClientService(),
      branchLoading: true,
      columnsTc: [
        {
          field: 'client_id',
          label: this.$t('clients.attrs.id'),
          search: true
        }, {
          field: 'client_type_icon',
          label: this.$isBusiness ? this.$t('clients.attrs.permission') : this.$t('clients.attrs.type'),
          type: 'icon'
        }, {
          field: 'client_name',
          label: this.$t('clients.attrs.name'),
          search: true
        }
      ],
      columnsBusiness: [
        {
          field: 'client_id',
          label: this.$t('clients.attrs.id'),
          search: true
        }, {
          field: 'client_type_icon',
          label: this.$t('settings.user_management.permission'),
          type: 'icon'
        }, {
          field: 'client_name',
          label: this.$t('clients.attrs.name'),
          search: true
        }
      ],
      clients: [],
      available_clients: [],
      totalClientCount: 0,
      branches: [],
      filteredBranches: [],
      step2: {
        all_branch_access: this.$store.state.sub_user.userData.all_branch_access,
        selected_branches: [],
        all_client_access: this.$store.state.sub_user.userData.all_client_access,
        change_management_access: this.$store.state.sub_user.userData.change_management_access,
        selected_clients: [],
        allow_mass_update: false,
        can_handover_item_values: false,
        can_force_delete_clients: false,
        manage_property: true,
        send_dispute: true
      },
      hideClientAccess: false,
      disablePermissions: false
    }
  },
  async beforeCreate() {
    let client = new ClientService();
    await client.listAll().then((clients) => {
      if(clients && clients.list) {
        this.totalClientCount = clients.totalRecordCount;
        this.clients = clients.list;
      }
    });

    let branch = new BranchService();
    this.branches = this.filteredBranches = await branch.listAll();
    this.branchLoading = false;
  },
  computed: {
    datevAccount() {
      return this.getCurrentUser().settings.tax_office_software && this.getCurrentUser().settings.tax_office_software === 'datev';
    }
  },
  methods: {
    ...mapMutations("sub_user", ["setUserData"]),
    ...mapGetters("sub_user", ["getUserData"]),
    ...mapGetters("user", ["getCurrentUser"]),
    clientListSearch(params) {
      this.clientService.listAll(params).then((clients) => {
        if(clients && clients.list) {
          this.clients = clients.list;

          this.available_clients = this.clients.filter(a => !this.step2.selected_clients.map(b => b.client_id).includes(a.client_id))
        }
      });
    },
    submitNext() {
      this.setUserData(this.step2)
      this.$emit("goToNextStep", this.step2);
    },
    goBack() {
      this.$emit("goBack");
    },
    clicked_all_branch(checked) {
      this.step2.all_branch_access = checked;
      if(checked) {
        this.available_clients = this.clients;
      } else {
        this.available_clients = [];
      }
    },
    clicked_all_client(checked) {
      this.step2.all_client_access = checked;
      if(!checked && this.step2.all_branch_access) {
        this.available_clients = this.clients;
      }
    },
    clicked_change_management(checked) {
      this.step2.change_management_access = checked;
    },
    searchBranch(event) {
      if (!event.query.trim().length) {
        this.filteredBranches = this.branches
      } else {
        this.filteredBranches = this.branches.filter(branch => !this.step2.selected_branches.includes(branch) && branch.name.toLowerCase().startsWith(event.query.toLowerCase()))
      }
    },
    stepVisible() {
      if (this.getUserData().role === 'it_administrator') {
        this.hideClientAccess = true;
        this.step2.all_branch_access = false;
        this.step2.all_client_access = false;
        this.step2.change_management_access = false;
        this.disablePermissions = true;
      } else {
        this.hideClientAccess = false;
        this.disablePermissions = false;
        if (typeof this.step2.all_branch_access === 'undefined') {
          this.step2.all_branch_access = true;
        }

        if (typeof this.step2.all_client_access === 'undefined') {
          this.step2.all_client_access = true;
        }

        if (typeof this.step2.change_management_access === 'undefined' || this.getUserData().role === 'administrator') {
          this.step2.change_management_access = true;
        }
      }
    },
    branchSelected() {
      if (!this.step2.all_branch_access) {
        if( this.step2.selected_branches.length > 0) {
          let branch_uids = this.step2.selected_branches.map((o) => {
            return o.uid;
          });
          this.available_clients = this.clients.filter(o => branch_uids.includes(o.branch_id));
          this.step2.selected_clients = this.step2.selected_clients.filter(o => branch_uids.includes(o.branch_id));
        } else {
          this.available_clients = [];
        }
      } else {
        this.available_clients = this.clients;
      }
    },
    clicked_manage_property(checked) {
      this.step2.manage_property = checked;
    },
    clicked_release_declaration(checked){
      this.step2.release_declaration = checked;
    },
    clicked_elster_transaction(checked){
      this.step2.elster_transaction = checked;
    },
    clicked_send_dispute(checked){
      this.step2.send_dispute = checked;
    },
    clicked_allow_mass_update(checked){
      this.step2.allow_mass_update = checked;
    },
    clicked_can_handover_item_values(checked) {
      this.step2.can_handover_item_values = checked;
    } ,
    clicked_can_force_delete_clients(checked) {
      this.step2.can_force_delete_clients = checked;
    }
  }
}
</script>

<style scoped lang="scss">


.table_flex {
  display: flex;
  margin: 0 -15px;
}

.inp_holder input {
  height: 32px;
  border: 1px solid;
}


.tb_50 {
  padding: 0 15px;
  max-width: 50%;
  width: 100%;
}

.inp_holder img {
  position: absolute;
  left: 23px;
  top: 16px;
  transform: rotate(
          90deg);
}

.table_50 tr td:nth-child(even) img {
  display: block;
  margin: 0 auto;
}

.inp_holder input {
  padding-left: 25px;
}

.table_50 td, .table_50 th {
  font-size: 15px;
  padding: 12px;
}

.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.table_50 img {
  max-width: 20px;
}

.table_50 {
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.box {

  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text-info {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.lbl {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
}

.fit {
  width: fit-content;
}

.sub-title {
  font-size: 13px;
  font-family: 'Segoe UI', sans-serif;
  font-style: italic;
  color: #aeaeae;
  text-align: left;
  line-height: 20px;
}
</style>
