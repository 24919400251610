<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('components.reports_menu.create_report')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" px-5 w-2/5">
        <FormSteps :steps="steps"/>
      </div>
      <div class="  w-full">
        <div class="mt-10 ">
          <component
              v-for="(item, index) in steps"
              :key="`component-${index}`"
              :is="item.component"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"

              v-show="item.active === true"
          />
        </div>
        <!--        <div class="mt-10 mr-10 ml-10" v-else>-->
        <!--          <Finish @finishButtonClick="handleFinishButtonClick"/>-->
        <!--        </div>-->
      </div>
    </div>



  </div>

</template>

<script>
import {userRoles, userStatus} from "@/core/constants";
import FormSteps from "../FormSteps";
import Step1 from '@/components/reports/report_steps/Step1.vue';
import Step2 from '@/components/reports/report_steps/Step2.vue';
import Step3 from '@/components/reports/report_steps/Step3.vue';
// import Step4 from '@/components/reports/report_steps/Step4.vue';
// import Finish from '@/components/reports/report_steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";


export default {
  name: "CreateReport",
  components: {FormSteps, Step1, Step2, Step3},
  data() {
    return {
      roleOptions: userRoles,
      statusOptions: userStatus,
      completed: false,
      breadcrumbs: [{
        title: this.$t('components.reports'),
        link: ''
      }, {
        title: this.$t('components.reports_menu.create_report'),
        link: ''
      }
      ],
      steps: [
        {
          id: 1,
          title: this.$t('components.report_steps.step1.tab'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"

        },
        {
          id: 2,
          title: this.$t('components.report_steps.step2.tab'),
          completed: false,
          active: false,
          component: "Step2"

        },
        {
          id: 3,
          title: this.$t('components.report_steps.step3.tab'),
          completed: false,
          active: false,
          last: true,
          component: "Step3"

        },


      ],
    }
  },
  computed: {
    ...mapGetters("report", ["getCurrentStep"]),
  },
  methods: {
    ...mapMutations("report", ["setCurrentStep"]),
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    finish() {
      //this.moveStep('next', true)
      this.completed = true
      this.$emit("click", "report_add_finish")
    },
    changeWindowSize() {
      // return i18n.locales = "es";
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1

        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }

        })

        this.setCurrentStep(step)
      }

    },
    handleFinishButtonClick() {
      this.$emit("click", "report_add_finish")
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}
</style>
