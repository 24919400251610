<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center">
      <div class="modal ">

        <div class="  w-full" v-on-clickaway="onClickOutside">
          <header class=" flex justify-between modal-header mt-2" v-if="showClose">
            <label class="dheader ">{{ headerText }}</label>
            <img src="@/assets/close_thin.png"
                 class="btn-close cursor-pointer"
                 @click="close"
                 v-if="showClose"
            />

          </header>

          <slot name="body">
          </slot>

          <slot name="footer">
          </slot>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import {directive as onClickaway} from "vue-clickaway";

export default {

  name: 'ModalDialogRight',
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    headerText: {
      type: String,
      default: ""
    },
    canEscape: {
      type: Boolean,
      default: true
    },
    canCloseOutsideClick: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      clickedOutSideCount: 0,
    }

  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.close()
        }

      }
    });
  },
  methods: {
    close() {
      this.clickedOutSideCount = 0;
      this.$emit('close');
    },
    onClickOutside() {

      this.clickedOutSideCount++;
      if (this.clickedOutSideCount > 1) {
        if (this.canCloseOutsideClick) {
          this.close()

        }
      }


    }
  },
};
</script>


<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 1;
}

.modal {
  width: 100%;
  background: white;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  display: flex;
  flex-direction: row;
  position:relative;
  justify-content: center;
}
.modal {
    right: 0;
    position: absolute !important;
    height: 100%;
    max-width: 560px !important;
    overflow-y:auto;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  //display: flex;
  //align-self: end;
  //flex-direction: row;
  //border-bottom: 1px solid #eeeeee;
  color: #333333;
  //justify-content: flex-end;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  //position: absolute;
  //top: 0;
  //right: 0;
  //border: none;
  //font-size: 40px;
  //padding: 10px;
  //cursor: pointer;
  //font-weight: bold;
  //color: #333333;
  //background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.dheader {

  //padding: 0 20px;
  //max-width: 50%;


  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}
</style>
