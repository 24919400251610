<template>
  <MainLayout value="reports">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
export default {
  name: "Report",
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    },
    getTitle() {
      return `GrundsteuerDigital - ${this.$t('components.reports')}`
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('reports');
  },
  methods: {
    handleComponentClick(data) {
      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          //  this.$router.push('/home/' + 'usermanagement')
          // this.$router.push({name: 'Home', params: {id: '1'}})
          break;

          ////Report Menu
        case 'edit_report':
        case 'create_report':
          //this.currentComponent = 'CreateReport';
          this.$router.push('/report/CreateReport')

          break;
        case 'report_add_finish':
          // this.currentComponent = 'Reports';
          this.$router.push('/report/ViewReport')

          break;
        case 'view_report':
          //this.currentComponent = 'ViewReport';
          this.$router.push('/report/ViewReport')

          break;
        case 'download_report':
          //this.currentComponent = 'DownloadReport';
          this.$router.push('/report/DownloadReport')

          break;

      }

    },
  }
}
</script>

<style scoped lang="scss">

</style>
