<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-left space-x-1 mt-2">
        <div class=" text-left flex flex-col space-y-6">
          <InputCheck class=" w-full" :text="$t('declarations.mass_update.document_types.clearance_doc')"
                      :showHelpIconPopover="true"
                      :popoverTexts="[$t('declarations.mass_update.doc_tooltip.clearance_doc')]"
                      @check="documentTypeClicked($event, 'elster_validation_protocol')"
          />
          <InputCheck class=" w-full" :text="$t('declarations.mass_update.document_types.elster_form_print')"
                      @check="documentTypeClicked($event, 'elster_form_print')"
          />
          <InputCheck class=" w-full" :text="$t('declarations.mass_update.document_types.completeness_declaration')"
                      :showHelpIconPopover="true"
                      :popoverTexts="[$t('declarations.mass_update.doc_tooltip.completeness_declaration')]"
                      @check="documentTypeClicked($event, 'declaration_of_completeness')"
          />
          <InputCheck class=" w-full" :text="$t('declarations.mass_update.document_types.tax_calculation')"
                      @check="documentTypeClicked($event, 'tax_calculation')"
          />
          <InputCheck v-if="!$isBusiness" class=" w-full" :text="$t('declarations.mass_update.document_types.item_value')"
                      @check="documentTypeClicked($event, 'property_value')"
          />
          <InputCheck class=" w-full" :text="$t('declarations.mass_update.document_types.transport')"
                      :showHelpIconPopover="true"
                      :popoverTexts="[$t('declarations.mass_update.doc_tooltip.transport')]"
                      @check="documentTypeClicked($event, 'elster_submission_protocol')"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end  space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('declarations.mass_update.execute')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Step_document",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDocumentTypes: []
    }
  },
  computed: {
    processType() {
      return this.getSteps().processType ? this.$t('declarations.mass_update.process')[this.getSteps().processType] : '';
    },
    processTypeVal() {
      return this.getSteps().processType ? this.getSteps().processType : '';
    }
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    submitNext() {
      if(this.selectedDocumentTypes.length > 0) {
        this.saveSteps({
          "selectedDocumentTypes": this.selectedDocumentTypes
        });
        this.$emit("goToNextStep")
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    documentTypeClicked(checked, documentType) {
      if(checked) {
        this.selectedDocumentTypes.push(documentType);
      } else {
        let index = this.selectedDocumentTypes.indexOf(documentType);
        if(index >= 0) {
          this.selectedDocumentTypes.splice(index, 1);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
</style>
