<template>
  <div class="flex flex-col h-full ">
    <div class="my-2  sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-left  w-auto sm:px-6 lg:px-8">
        <div class="overflow-hidden border-b border-gray-200 ">
          <table class="min-w-full divide-y divide-gray-200  mr-6 ml-6">
            <thead class="">
            <tr>
              <th scope="col" class=" py-3 pr-6 text-left    ">
                {{ $t('dashboard.table.type') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('dashboard.table.name') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('dashboard.table.street') }}
              </th>
              <th scope="col" class="pr-6 py-3 text-left   ">
                {{ $t('dashboard.table.city') }}
              </th>

            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="person in people" :key="person.email">
              <td class=" py-4 whitespace-nowrap">

                <div>
                  {{ person.type }}
                </div>

              </td>
              <td class=" pr-6 py-4 whitespace-nowrap">
                <div class=" ">{{ person.name }}</div>
              </td>
              <td class="pr-6 py-4 whitespace-nowrap">
                <div class=" ">{{ person.street }}</div>
              </td>
              <td class="pr-6 py-4 whitespace-nowrap ">
                {{ person.city }}
              </td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      people: [
        {
          type: 'Private Person',
          name: ' Brigitte Sieber',
          street: 'Universitätsplatz 12, 34127',
          city: 'Kassel',

        },
        {
          type: 'Company',
          name: 'Mirko Scheel',
          street: ' Kurparkhotel Bad 15, 34127',
          city: 'Kassel',

        },
        {
          type: 'Private Person',
          name: ' Ellen Hölken',
          street: 'Handyschmiede-Saar 14, 66125',
          city: 'Berlin',

        },
        {
          type: 'Company',
          name: 'Louisa Kabbani',
          street: ' Dürr Assembly 12, 66125',
          city: 'Nuremberg',

        },
        {
          type: 'Private Person',
          name: 'Hendrik Oßwald',
          street: ' Atrium Bad 14, 66125',
          city: 'Stuttgart',

        },
        {
          type: 'Company',
          name: 'Yannick Kummetz',
          street: 'Atrium Bad 14, 66125',
          city: 'Stuttgart',

        },

      ],
    }
  },

}
</script>

<style scoped lang="scss">
th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
}

</style>
