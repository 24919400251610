<template>
  <Dialog
    :modal="true"
    :closable="isClosable"
    :closeOnEscape="isClosable"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1', width: 'auto', height: 'auto'}"
    :autoZIndex="false"
  >
    <template #header>
      <h3 class="text-xl font-bold text-left">
        {{ $t('declarations.menus.attachment_mass_appeal_type') }}
      </h3>
    </template>

    <div>
      <div
        v-if="isLoading"
        class="h-full content-center"
      >
        <i class="pi pi-spin pi-spinner text-green text-5xl mb-4" />
        <div>{{ $t('declarations.detail.elster_message.mass_appeal_types_loading') }}</div>
      </div>

      <div v-if="!isLoading">
        <div class="flex justify-around space-x-5 mt-5">
          <div class="flex flex-col shrink-0 w-1/3 h-full pr-2">
            <div class="flex mb-2 -mt-4">
              <Button
                :icon="getAssetPath('plus_circle_green.svg')"
                :text="$t('declarations.menus.add')"
                :isTextButton="true"
                @click="handleAdd"
              />

              <Button
                v-if="isCurrentlySelectedElsterMessageTypeDeletable"
                class="ml-2"
                :icon="getAssetPath('delete_user_green.svg')"
                :text="$t('declarations.menus.delete')"
                :isTextButton="true"
                @click="handleDelete"
              />
            </div>

            <Listbox
              class="mt-2 rounded"
              v-model="selectedMassElsterMessageTypeUid"
              :options="massElsterMessageTypes"
              optionLabel="name"
              optionValue="prim_uid"
              dataKey="prim_uid"
              :filter="true"
            >
              <template #option="slotProps">
                <div class="flex flex-row">
                  <span class="w-full text-left">
                    {{ slotProps.option.name }}
                    <span v-if="slotProps.option.name.length === 0">{{ $t('declarations.detail.elster_message.empty_mass_appeal_type_name_label') }}</span>
                  </span>

                  <Chip
                    label="System"
                    v-if="slotProps.option.account_uid === null"
                  />
                </div>
              </template>
            </Listbox>
          </div>

          <div class="flex flex-col w-1/3 h-full space-y-5">
            <InputText
              class="mt-2"
              :label="$t('declarations.detail.elster_message.attachment_mass_appeal_type_titel')"
              v-model="selectedMassElsterMessageType.name"
              :isRequired="true"
              :maxLength="massElsterMessageTypeNameMaxLimit"
              :infoText="$t('general.errors.limitDesc').replace('%length%', selectedMassElsterMessageType.name.length).replace('%maxLimit%', massElsterMessageTypeNameMaxLimit)"
              :isError="massElsterMessageTypeValidations.name.invalid"
              :error-message="$t('general.required')"
            />
            <InputText
              class="mt-2"
              :label="$t('declarations.detail.elster_message.subject')"
              v-model="selectedMassElsterMessageType.subject"
              :isRequired="true"
              :maxLength="elsterMessageSubjectMaxLimit"
              :infoText="$t('general.errors.limitDesc').replace('%length%', selectedMassElsterMessageType.subject.length).replace('%maxLimit%', elsterMessageSubjectMaxLimit)"
              :showHelpIconPopover="true"
              :popoverTexts="$t('declarations.detail.elster_message.subject_help')"
              :isError="massElsterMessageTypeValidations.subject.invalid"
              :error-message="$t('general.required')"
            />
            <InputText
              class="mt-2"
              bodyClass="resize-textarea h-1"
              :label="$t('declarations.detail.elster_message.justification')"
              :isMultiline="true"
              v-model="selectedMassElsterMessageType.justification"
              :isRequired="true"
              :maxLength="elsterMessageJustificationMaxLimit"
              :infoText="$t('general.errors.limitDesc').replace('%length%', selectedMassElsterMessageType.justification.length).replace('%maxLimit%', elsterMessageJustificationMaxLimit)"
              :isError="massElsterMessageTypeValidations.justification.invalid"
              :error-message="$t('general.required')"
            />
            <InputSelect
              class="mt-2"
              :label="$t('declarations.detail.elster_message.administrative_act')"
              :options="$t('declarations.detail.elster_message.administrative_act_options')"
              v-model="selectedMassElsterMessageType.administrative_act"
              :selected-value="selectedMassElsterMessageType.administrative_act"
              :isRequired="true"
              :isError="massElsterMessageTypeValidations.administrative_act.invalid"
              :error-message="$t('general.required')"
              :infoText="administrativeActHinweis ? $t('general.errors.administrative_act_hinweis_' + selectedMassElsterMessageType.administrative_act) : ''"
              :showHelpIconPopover="true"
              :popoverTexts="$t('declarations.detail.elster_message.administrative_act_help')"
            />
            <InputSelect
              class="mt-2"
              :label="$t('declarations.detail.elster_message.administrative_deadline')"
              :options="administrativeActDeadlineOptions"
              v-model="selectedMassElsterMessageType.administrative_act_deadline"
              :selected-value="selectedMassElsterMessageType.administrative_act_deadline"
              v-show="selectedMassElsterMessageType.administrative_act === '108'"
              :is-required="selectedMassElsterMessageType.administrative_act === '108'"
              :isError="massElsterMessageTypeValidations.administrative_act_deadline.invalid"
              :error-message="$t('general.required')"
            />
            <InputSelect
              class="mt-2"
              :label="$t('declarations.detail.elster_message.suspension_of_execution')"
              :options="$t('declarations.detail.elster_message.suspension_of_execution_options')"
              v-model="selectedMassElsterMessageType.suspension_of_execution"
              :selected-value="selectedMassElsterMessageType.suspension_of_execution"
              :isRequired="true"
              :isError="massElsterMessageTypeValidations.suspension_of_execution.invalid"
              :error-message="$t('general.required')"
            />
            <InputSelect
              class="mt-2"
              :label="$t('declarations.detail.elster_message.mass_appeal_type_federal_state')"
              :options="federalStatesList"
              v-model="selectedFederalStateOptions"
              :selectedValue="selectedFederalStateOptions"
              :multiselect="true"
            />
            <div class="flex flex-col">
              <div class="flex">
                <InputCheck
                  :text="$t('declarations.detail.elster_message.check_box_text')"
                  :isRequired="true"
                  :isChecked="selectedMassElsterMessageType.confirmed"
                  :isError="massElsterMessageTypeValidations.suspension_of_execution.invalid"
                  :error-message="$t('general.required')"
                  @check="massElsterMessageTypeConfirmationChecked"
                />
              </div>
              <div class="flex" v-show="massElsterMessageTypeValidations.confirmation.invalid">
                <label class="self-start mt-1 text-red">{{ $t('general.required') }}</label>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-1/3 h-full space-y-5">
            <InputUpload
              ref="massElsterMessageTypeAttachmentsUpload"
              class="file-attachments-upload mt-2"
              :label="$t('declarations.detail.elster_message.attachments_select_files')"
              multiple="multiple"
              :accept="$t('declarations.detail.elster_message.attachments_allowed_file_types')"
              :isBlackText="true"
              :showDragAndDrop="true"
              :fileLimit="parseInt($t('declarations.detail.elster_message.attachment_limits.files'))"
              :maxFileSize="parseInt($t('declarations.detail.elster_message.attachment_limits.file_size'))"
              :maxOverallSize="parseInt($t('declarations.detail.elster_message.attachment_limits.overall_size'))"
              byteFormatUnit="mb"
              :byteFormatDecimals="2"
              :description="$t('declarations.detail.elster_message.attachments_description').replace('%attachments%', selectedMassElsterMessageType.attachments.length).replace('%maxAttachments%', $t('declarations.detail.elster_message.attachment_limits.files'))"
              :showHelpIcon="true"
              :tooltip="getAttachmenUploadTooltip()"
              overlay-append-to="body"
              :allowFilenameEditing="true"
              :normalizeFilenames="true"
              :maxFilenameLength="parseInt($t('declarations.detail.elster_message.attachment_limits.filename_length'))"
              :showUploadStatus="false"
              :viewFileEnabled="true"
              :viewFileService="massElsterMessageTypeService"
              @select="massElsterMessageTypeAttachmentsFileSelect"
              @filesChanged="massElsterMessageTypeAttachmentsFilesChanged"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse">
        <Button class="w-44 ml-2" @click="handleSave" :text="$t('buttons.save')"/>
        <Button class="w-44" @click="handleClose" :secondary="true" :text="$t('buttons.cancel')"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import { MassElsterMessageTypeService } from '@/services/MassElsterMessageType.service';

import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import Chip from 'primevue/chip';

import InputCheck from '@/components/inputs/InputCheck.vue';

export default {
  name: 'AddMassElsterMessageTypeModal',
  emits: ['close'],
  components: {
    Dialog,
    Listbox,
    Chip,
    InputCheck
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      messageType: 'appeal',
      massElsterMessageTypeNameMaxLimit: 120,

      massElsterMessageTypeService: new MassElsterMessageTypeService(),

      federalStatesList: [],
      massElsterMessageTypes: [],
      massElsterMessageTypeValidations: {
        name: {
          invalid: false,
        },
        subject: {
          invalid: false,
        },
        justification: {
          invalid: false
        },
        confirmation: {
          invalid: false
        },
        administrative_act: {
          invalid: false
        },
        administrative_act_deadline: {
          invalid: false,
        },
        suspension_of_execution: {
          invalid: false
        },
        federal_state_uids: {
          invalid: false
        },
        attachments: [],
      },

      isLoading: false,
      administrativeActHinweis: false,

      selectedMassElsterMessageTypeUid: 0,
    }
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
    isClosable() {
      return this.closable;
    },
    elsterMessageSubjectMaxLimit() {
      let maxLimit = parseInt(this.$t('declarations.detail.elster_message.subject_limit'));
      if (isNaN(maxLimit)) {
        return 0;
      }

      return maxLimit;
    },
    elsterMessageJustificationMaxLimit() {
      let maxLimit = parseInt(this.$t('declarations.detail.elster_message.justification_limits.appeal'));
      if (isNaN(maxLimit)) {
        return 0;
      }

      return maxLimit;
    },
    administrativeActDeadlineOptions() {
      let deadlineOpts = [];
      for (let year = 2022; year <= 2050; year++) {
        deadlineOpts.push({
          'name': '01.01.' + year,
          'code': year
        });
      }

      return deadlineOpts;
    },
    isCurrentlySelectedElsterMessageTypeDeletable() {
      let isDeletable = false;
      this.massElsterMessageTypes.forEach((massElsterMessageType) => {
        if (massElsterMessageType.prim_uid !== this.selectedMassElsterMessageTypeUid) {
          return;
        }

        isDeletable = (massElsterMessageType.account_uid !== null);
      });

      return isDeletable;
    },
    hasUnsavedNewElsterMessageType() {
      let hasUnsavedNewElsterMessageType = false;

      this.massElsterMessageTypes.forEach((massElsterMessageType) => {
        if (massElsterMessageType.prim_uid === 0) {
          hasUnsavedNewElsterMessageType = true;
        }
      });

      return hasUnsavedNewElsterMessageType;
    },
    selectedMassElsterMessageType() {
      let selectedMassElsterMessageType = this.getDefaultMassElsterMessageTypeData();

      this.massElsterMessageTypes.forEach((massElsterMessageType) => {
        if (massElsterMessageType.prim_uid === this.selectedMassElsterMessageTypeUid) {
          selectedMassElsterMessageType = massElsterMessageType;
        }
      });

      return selectedMassElsterMessageType;
    },
    selectedFederalStateOptions: {
      get: function () {
        if (this.federalStatesList.length === 0) {
          return [];
        }

        if (this.selectedMassElsterMessageType.federal_state_uids === null) {
          return [];
        }

        return this.selectedMassElsterMessageType.federal_state_uids;
      },
      set: function (value) {
        this.selectedMassElsterMessageType.federal_state_uids = value;
      },
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.show();
      }
    },
    selectedMassElsterMessageTypeUid(newValue, oldValue) {
      if (newValue === null && oldValue !== null) {
        this.selectedMassElsterMessageTypeUid = oldValue;
        return;
      }

      if (this.$refs.massElsterMessageTypeAttachmentsUpload) {
        this.$refs.massElsterMessageTypeAttachmentsUpload.clear();
      }

      this.selectedMassElsterMessageType.attachments.forEach((attachment) => {
        this.massElsterMessageTypeValidations.attachments.push({'invalid': false});
        this.$refs.massElsterMessageTypeAttachmentsUpload.addAlreadyUploadedFile(attachment.file);
      });
    },
    'selectedMassElsterMessageType.administrative_act': {
      handler: function (newValue, oldValue) {
        if (newValue !== '') {
          this.massElsterMessageTypeValidations.administrative_act.invalid = false;
          this.administrativeActHinweis = true;
        } else {
          this.massElsterMessageTypeValidations.administrative_act.invalid = true;
          this.administrativeActHinweis = false;
        }

        if (oldValue === '108' && newValue !== '108') {
          this.massElsterMessageTypeValidations.administrative_act_deadline.invalid = false;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    getDefaultMassElsterMessageTypeData() {
      return {
        prim_uid: 0,
        account_uid: this.getCurrentUser().account_uid,
        name: '',
        subject: '',
        justification: '',
        administrative_act: '',
        administrative_act_deadline: '',
        suspension_of_execution: 'no',
        federal_state_uids: [],
        message_type: this.messageType,
        attachments: [],
        confirmed: false,
        selected: true
      };
    },
    show() {
      this.$api.get_state_list().then((list) => {
        this.federalStatesList = list.map((federalState) => {
          return {
            code: parseInt(federalState.code),
            name: federalState.name
          };
        });
      });

      this.fetchMassElsterMessageTypes();
    },
    fetchMassElsterMessageTypes() {
      this.isLoading = true;

      this.massElsterMessageTypes = [];
      this.massElsterMessageTypeService.listAll(this.messageType).then((massElsterMessageTypes) => {
        let firstPrimUid = 0;

        massElsterMessageTypes.forEach((massElsterMessageType) => {
          if (firstPrimUid === 0) {
            firstPrimUid = massElsterMessageType.prim_uid;
          }

          let attachments = Object.values(massElsterMessageType.attachments);

          attachments.forEach((attachment, index) => {
            attachments[index] = {
              file: {
                uuid: attachment.file_name,
                name: attachment.description + '.pdf',
                size: attachment.size,
                recordId: attachment.prim_uid,
                parentId: attachment.mass_elster_message_type_uid,
              },
              description: attachment.description,
              fileName: attachment.file_name
            };
          });

          massElsterMessageType.attachments = attachments;
        });

        this.massElsterMessageTypes = massElsterMessageTypes;

        this.selectedMassElsterMessageTypeUid = firstPrimUid;

        this.isLoading = false;
      });
    },
    handleClose() {
      this.resetMassElsterMessageTypeValidations();
      this.isVisible = false;
    },
    async handleSave() {
      if (this.preValidateMassElsterMessageType() === false) {
        this.$api.showToast(this.$t('declarations.detail.elster_message.mass_appeal_required_fields_not_filled'), 'error');
        return;
      }

      if (!this.selectedMassElsterMessageType.confirmed) {
        this.$api.showToast(this.$t('general.errors.confirmation_required'), 'error');
        this.massElsterMessageTypeValidations.confirmation.invalid = true;
        return;
      }

      let massElsterMessageTypeData = await this.getMassElsterMessageTypeForRequest();
      let response = await this.massElsterMessageTypeService.save(massElsterMessageTypeData);

      if (response && response.data.success && response.data.primUid) {
        this.selectedMassElsterMessageType.prim_uid = response.data.primUid;
        this.$api.showToast(this.$t('general.data_saved'));
        this.fetchMassElsterMessageTypes();
      }
    },
    async handleDelete() {
      if (this.selectedMassElsterMessageType.account_uid === null) {
        return;
      }

      this.$modal.show({
        headerText: this.$t('general.grid.delete_record'),
        text: this.$t('general.grid.delete_confirm'),
        showClose: true,
        canEscape: true,
        onConfirm: async () => {
          if (this.selectedMassElsterMessageType.prim_uid === 0) {
            let selectedMassElsterMessageTypeIndex = this.massElsterMessageTypes.findIndex((massElsterMessageType) => massElsterMessageType.prim_uid === this.selectedMassElsterMessageTypeUid);
            this.massElsterMessageTypes.splice(selectedMassElsterMessageTypeIndex, 1);

            let firstElsterMessageType = this.massElsterMessageTypes.find((massElsterMessageType) => massElsterMessageType.prim_uid > 0);
            this.selectedMassElsterMessageTypeUid = (firstElsterMessageType) ? firstElsterMessageType.prim_uid: 0;
            return;
          }

          await this.massElsterMessageTypeService.delete(this.selectedMassElsterMessageType);
          this.fetchMassElsterMessageTypes();
        }
      });
    },
    handleAdd () {
      if (!this.hasUnsavedNewElsterMessageType) {
        this.massElsterMessageTypes.push(this.getDefaultMassElsterMessageTypeData());
      }

      this.selectedMassElsterMessageTypeUid = 0;
    },
    massElsterMessageTypeConfirmationChecked(checked) {
      this.selectedMassElsterMessageType.confirmed = checked;
    },
    massElsterMessageTypeAttachmentsFileSelect(e) {
      this.massElsterMessageTypeAttachmentsFilesChanged(e.files);
    },
    massElsterMessageTypeAttachmentsFilesChanged(files) {
      this.selectedMassElsterMessageType.attachments = [];
      this.massElsterMessageTypeValidations.attachments = [];

      if (files.length === 0) {
        return;
      }

      files.forEach((file) => {
        this.massElsterMessageTypeValidations.attachments.push({invalid: false});

        let attachment = {
          file: file,
          description: file.description
        };

        if (file.uuid.endsWith('.pdf')) {
          attachment.fileName = file.uuid;
        }

        this.selectedMassElsterMessageType.attachments.push(attachment);
      });
    },
    async getMassElsterMessageTypeForRequest() {
      let massElsterMessageType = {...this.selectedMassElsterMessageType};
      if (massElsterMessageType.attachments.length > 0) {
        let attachments = await Promise.all(
            massElsterMessageType.attachments.map(async (attachment) => {
              if (attachment.fileName) {
                return {
                  recordId: attachment.file.recordId,
                  fileName: attachment.fileName,
                  description: attachment.description
                };
              }

              return await this.getAttachmentForRequest(attachment);
            })
        );

        massElsterMessageType.attachments = attachments;
      }

      if (massElsterMessageType.account_uid === null) {
        massElsterMessageType.account_uid = this.getCurrentUser().account_uid;
        massElsterMessageType.prim_uid = 0;
      }

      return massElsterMessageType;
    },
    async getAttachmentForRequest(attachment) {
      let fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(attachment.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      return {
        fileData: fileContent,
        fileUuid: attachment.file.uuid,
        description: attachment.description
      }
    },
    preValidateMassElsterMessageType() {
      this.resetMassElsterMessageTypeValidations();

      if (this.selectedMassElsterMessageType.name.trim() === '' || this.selectedMassElsterMessageType.name.length > this.massElsterMessageTypeNameMaxLimit) {
        this.massElsterMessageTypeValidations.name.invalid = true;
        return false;
      }

      if (this.selectedMassElsterMessageType.subject.trim() === '' || this.selectedMassElsterMessageType.subject.length > this.elsterMessageSubjectMaxLimit) {
        this.massElsterMessageTypeValidations.subject.invalid = true;
        return false;
      }

      if (this.selectedMassElsterMessageType.justification.trim() === '' || this.selectedMassElsterMessageType.justification.length > this.elsterMessageJustificationMaxLimit) {
        this.massElsterMessageTypeValidations.justification.invalid = true;
        return false;
      }

      if (typeof this.selectedMassElsterMessageType.administrative_act === 'undefined' || this.selectedMassElsterMessageType.administrative_act.trim() === '') {
        this.massElsterMessageTypeValidations.administrative_act.invalid = true;
        return false;
      }

      if (this.selectedMassElsterMessageType.administrative_act_deadline === '' && this.selectedMassElsterMessageType.administrative_act === '108') {
        this.massElsterMessageTypeValidations.administrative_act_deadline.invalid = true;
        return false;
      }

      if (this.selectedMassElsterMessageType.suspension_of_execution.trim() === '') {
        this.massElsterMessageTypeValidations.suspension_of_execution.invalid = true;
        return false;
      }

      if (this.selectedMassElsterMessageType.attachments.length > 0) {
        let attachmentsAreInvalid = false;

        let attachmentHasDuplicateDescription = false;
        let attachmentDescriptions = [];
        this.selectedMassElsterMessageType.attachments.forEach((attachment, index) => {
          if (attachment.description.trim() === '' || attachment.description.length > 50) {
            this.massElsterMessageTypeValidations.attachments[index].invalid = true;
            attachmentsAreInvalid = true;
          }

          if (attachmentDescriptions.includes(attachment.description)) {
            this.massElsterMessageTypeValidations.attachments[index].invalid = true;
            attachmentsAreInvalid = true;
            attachmentHasDuplicateDescription = true;
          }

          attachmentDescriptions.push(attachment.description);
        });

        if (attachmentsAreInvalid === true) {
          if (attachmentHasDuplicateDescription) {
            this.$api.showToast(this.$t('general.errors.duplicate_attachment_description'), 'error');
          }

          return false;
        }
      }
    },
    resetMassElsterMessageTypeValidations() {
      this.massElsterMessageTypeValidations = {
        name: {
          invalid: false,
        },
        subject: {
          invalid: false,
        },
        justification: {
          invalid: false
        },
        confirmation: {
          invalid: false
        },
        administrative_act: {
          invalid: false
        },
        administrative_act_deadline: {
          invalid: false
        },
        suspension_of_execution: {
          invalid: false
        },
        federal_state_uids: {
          invalid: false
        },
        attachments: []
      };
    },
    getAttachmenUploadTooltip() {
      return this.$t('declarations.detail.elster_message.attachments_help')
        .replace('%maxAttachments%', this.$t('declarations.detail.elster_message.attachment_limits.files'))
        .replace('%maxSingleFileSize%', this.$t('declarations.detail.elster_message.attachment_limits.file_size'))
        .replace('%maxSumFileSize%', this.$t('declarations.detail.elster_message.attachment_limits.overall_size'));
    }
  }
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: 95vh;
  max-height: 95vh;
  width: 95vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;

    & > div {
      height: 100%;
    }

    .file-attachments-upload {
      .drop {
        height: 100%;
        max-height: 500px;
      }
    }
  }

  .p-dialog-footer {
    @apply p-3;
  }
}

:deep(.p-listbox) {
  border-color: theme('colors.gray_df');

  .p-listbox-filter {
    border-color: theme('colors.gray_df');

    @apply rounded;

    &:focus {
      border-width: 1px;
    }
  }

  .p-listbox-item {
    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      background-color: theme('colors.green');
      color: theme('colors.white')
    }
  }
}
</style>
