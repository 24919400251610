<template>
  <MainLayout value="home" v-show="!authVerifying">
    <template>
      <component :is="currentComponent" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MainLayout from "../layouts/MainLayout";

export default {

  name: "Home",
  components: {MainLayout},
  data() {
    return {
      currentComponent: 'dashboard',
      bigNav: true,
      settingsSelected: false,
      menus: [
        {
          name: 'Home',
          isActive: true,
          icon: require('@/assets/home.svg'),
          component: 'Dashboard'
        },
        {
          name: 'Declaration',
          isActive: false,
          icon: require('@/assets/declaration.svg'),
          component: 'Declarations',
          submenu: [
            {
              name: 'Draft',
              component: 'Draft'
            },
            {
              name: 'In Progress',
              component: 'DeclarationDetails'//'DeclarationProgress'
            },
            {
              name: 'Waiting for Client',
              component: 'DeclarationClient'
            },
            {
              name: 'Submitted',
              component: 'DeclarationSubmit'
            },
            {
              name: 'Request for info',
              component: 'DeclarationRequest'
            },
            {
              name: 'Dispute',
              component: 'DeclarationDespute'
            },
            {
              name: 'Completed',
              component: 'DeclarationCompleted'
            }
          ]
        },
        {
          name: 'Clients',
          isActive: false,
          icon: require('@/assets/clients.svg'),
          component: 'Clients'
        },
        {
          name: 'Properties',
          isActive: false,
          icon: require('@/assets/properties.svg'),
          component: 'Properties'
        },
        {
          name: 'Reports',
          isActive: false,
          icon: require('@/assets/reports.svg'),
          component: 'Reports'
        },
        {
          name: 'Help',
          isActive: false,
          icon: require('@/assets/help.svg')
        },
      ]

    }
  },
  computed: {
    ...mapGetters("user", ["isModalVisible"]),
    customClasses() {
      return [{'nav-open': this.bigNav === true}, {'nav-close': this.bigNav === false}];
    }
  },
  mounted() {
    this.chart()
  },
  methods: {
    ...mapMutations("user", ["setModalVisible"]),
    handleComponentClick(data) {
      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          //  this.$router.push('/home/' + 'usermanagement')
          // this.$router.push({name: 'Home', params: {id: '1'}})
          break;

          ////user Menu
        case 'add_user':
          this.currentComponent = 'add';
          this.$router.push('/home/' + 'usermanagement')
          break;
        case 'edit_user':
          this.currentComponent = 'edit';
          this.$router.push('/home/' + 'usermanagement')
          break;
///Client Menu
        case 'add_client':
          this.currentComponent = 'CreateNewClient';

          break;
        case 'edit_client':
          this.currentComponent = 'EditClient';

          break;

          ///Property menu
        case 'add_property':
          this.currentComponent = 'AddProperty';

          break;
        case 'edit_property':
          this.currentComponent = 'EditProperty';

          break;
        case 'property_add_finish':
          this.currentComponent = 'Properties';

          break;
          ////Report Menu
        case 'edit_report':
        case 'create_report':
          this.currentComponent = 'CreateReport';

          break;
        case 'report_add_finish':
          this.currentComponent = 'Reports';

          break;
        case 'view_report':
          this.currentComponent = 'ViewReport';

          break;
        case 'edit_declaration':
        case 'add_declaration':
          this.currentComponent = 'AddDeclaration';

          break;

      }

    },
    handleModalClose() {
      this.setModalVisible(false)
    },
    handleSettingClick() {
      this.settingsSelected = true;
      this.currentComponent = 'AccountSettings'//'LogProtocol'//'UserManagement';

      this.menus = this.menus.map((x) => {
        return {...x, isActive: false}
      })
    },
    handleClick(data) {
      this.settingsSelected = false

      this.currentComponent = data.data.component
      let name = data.submenuClicked ? 'Declaration' : data.data.name
      let index = this.menus.findIndex(item => item.name === name)
      this.menus = this.menus.map((x, i) => {
        return i === index ? {...x, isActive: true} : {...x, isActive: false}
      })
    },
    chart() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    handleToggle() {
      this.bigNav = !this.bigNav
      //alert('toggle')
    },
    toggle() {
      this.bigNav = !this.bigNav
    }
  }
}
</script>

<style scoped lang="scss">
.charts {
  width: 90%;
  position: relative;


  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.scroll-height {
  height: 375px;
}

.scroll-height-table {
  height: 450px;
}

.rectangle-right {
  width: 40%;
  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.notification-button {


  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  //padding: 6px 17px 7px 18px;


}

.container-main {
  background-color: #e6e6e6;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.nav-open {
  width: 300px;
  animation: slide-right .2s;
}

.nav-close {
  width: 56px;
  animation: slide-left .2s reverse;
}

@-webkit-keyframes slide-left {
  from {
    width: 56px;
  }

  to {

    width: 300px;
  }
}

@-webkit-keyframes slide-right {
  from {
    width: 56px;
  }

  to {


    width: 300px;
  }
}


.add-cards {
  height: 25px;
  margin-top: 10px;
}

.icon-add {
  width: 18px;
  height: 18px;
}

.icon-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;

}

.progress-bar {
  width: 90%;
  height: 18px;
  border-radius: 10px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

progress {

  height: 18px;
  border-radius: 10px;
  background-color: $primary;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

progress::-webkit-progress-bar {
  background-color: #f2f2f2;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: $primary;
  border-radius: 10px;
  // box-shadow: 1px 1px 5px 3px rgba( 255, 0, 0, 0.8 );
}

progress::-moz-progress-bar {
  /* style rules */
}

.rectangle-chart {
  width: 95%;
  height: 100%;
  margin-top: 20px;
  margin-right: 50px;
  //background-color: #ffffff;
  //  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  //line-height: normal;
  // box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.rectangle {
  width: 59%;
  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.account {
  height: 440px;
  scrollbar-width: none;
}

//::-webkit-scrollbar {
//  display: none;
//}


.welcome {
  font-size: 26px;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-left: 10px;
  padding-left: 20px;
  float: left;
}


.top-banner {
  height: 150px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  //text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
  padding-top: 30px;
}

.sidebar {
  width: 300px;
  height: 100%;
  overflow-y: hidden;
  background-color: $green-dashboard;
  color: #333333;
}

canvas {
  height: 100px;
}

.image {
  height: 105px;
}

//.enter-login {
//  @include segoe-regular;
//  width: 100%;
//  height: 34px;
//  border: 2px solid white;
//  background-color: transparent;
//  box-sizing: border-box;
//  color: white;
//  text-align: left;
//
//  &:focus {
//    border: 2px solid white;
//  }
//
//  background-image: url('~@/assets/search.svg');
//  background-repeat: no-repeat;
//  background-position: right;
//  padding: 5px;
//  background-origin: content-box;
//
//
//}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

//.child-img {
//  border-radius: 50%;
//  height: 15px;
//  width: 15px;
//  position: absolute;
//  top: -5px;
//  left: 8px;
//  right: 0;
//}

.notification {
  @include segoe-regular;
  font-size: 10px;
}

</style>
