<template>
  <MainLayout value="search">
    <template>
      <div class="h-full bg-white pl-7 w-full ">
        <p class="header-component mt-20">{{ $t('search.title') }}</p>
        <hr class=" hr-line-user  mr-7 mb-1 mt-8 "/>
        <div class="w-full mt-4 mr-8">
          <Tabs :categories="tabs" @click="handleTabClick" :currentTab="currentTab"></Tabs>
          <div class="mt-6 search-result">
            <!-- Files -->
            <div class="flex justify-start ml-4" v-show="showFileResult">
              <div class="flex flex-col">
                <label class="title text-left">{{ $t('search.tabs.tab2') }}</label>
                <div class="flex flex-col space-y-1">
                  <FileItem v-for="item in searchResult['documents']" :key="item.prim_uid" :item="item"/>
                </div>
              </div>
            </div>
            <!-- Clients -->
            <div class="flex justify-start ml-4 mb-4" v-show="showClientResult">
              <div class="flex flex-col">
                <label class="title text-left mt-4">{{ $t('search.tabs.tab3') }}</label>
                <div class="flex flex-col space-y-1">
                  <ClientItem v-for="item in searchResult['clients']" :key="item.prim_uid" :item="item"/>
                </div>
              </div>
            </div>
            <!-- Properties -->
            <div class="flex justify-start ml-4" v-show="showPropertyResult">
              <div class="flex flex-col">
                <label class="title text-left ">{{ $t('search.tabs.tab4') }}</label>
                <div class="flex flex-col space-y-1">
                  <PropertyItem v-for="item in searchResult['properties']" :key="item.prim_uid" :item="item"/>
                </div>
              </div>
            </div>
            <!-- Declaration -->
            <div class="flex justify-start ml-4" v-show="showDeclarationResult">
              <div class="flex flex-col">
                <label class="title text-left mt-4">{{ $t('search.tabs.tab5') }}</label>
                <div class="flex flex-col">
                  <TaxItem v-for="item in searchResult['declarations']" :key="item.id" :item="item"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>

import {SearchService} from "@/services/search.service";
import {mapGetters} from "vuex";
import TaxItem from "@/components/search/TaxItem";

export default {
  name: "Search",
  components: {TaxItem},
  data() {
    return {
      service: new SearchService(),
      currentTab: 'all',
      searchTxt: '',
      searchResult: {
        documents: [],
        clients: [],
        properties: [],
        declarations: []
      },
      tabs: [
        {
          name: this.$t('search.tabs.tab1'),
          isActive: true,
          slug: 'all'
        }, {
          name: this.$t('search.tabs.tab2'),
          isActive: false,
          slug: 'files',
          hide: true
        }, {
          name: this.$t('search.tabs.tab3'),
          isActive: false,
          slug: 'clients',
          hide: true
        }, {
          name: this.$t('search.tabs.tab4'),
          isActive: false,
          slug: 'properties',
          hide: true
        }, {
          name: this.$t('search.tabs.tab5'),
          isActive: false,
          slug: 'declaration',
          hide: true
        }
      ],
    }
  },
  computed: {
    ...mapGetters("search", ["getSearchResult"]),
    showFileResult() {
      return (this.currentTab === 'all' || this.currentTab === 'files') && this.searchResult['documents'].length > 0
    },
    showClientResult() {
      return (this.currentTab === 'all' || this.currentTab === 'clients') && this.searchResult['clients'].length > 0
    },
    showPropertyResult() {
      return (this.currentTab === 'all' || this.currentTab === 'properties') && this.searchResult['properties'].length > 0
    },
    showDeclarationResult() {
      return (this.currentTab === 'all' || this.currentTab === 'declaration') && this.searchResult['declarations'].length > 0
    }
  },
  watch: {
    getSearchResult: {
      deep: true,
      handler: function(nv) {
        this.searchResult = nv;
        this.updateTabs();
      }
    }
  },
  mounted() {
    this.searchResult = this.getSearchResult;
    this.updateTabs()
  },
  methods: {
    handleTabClick(args) {
      this.currentTab = args
      let index = this.tabs.findIndex(item => item.slug === args)
      this.tabs = this.tabs.map((x, i) => {
        return i === index ? {...x, isActive: true} : {...x, isActive: false}
      })
    },
    updateTabs() {
      this.tabs.map((o) => {
        if(o.slug === 'clients') {
          o.hide = !this.searchResult['clients'].length > 0;
        } else if(o.slug === 'properties') {
          o.hide = !this.searchResult['properties'].length > 0;
        } else if(o.slug === 'declaration') {
          o.hide = !this.searchResult['declarations'].length > 0;
        } else if(o.slug === 'files') {
          o.hide = !this.searchResult['documents'].length > 0;
        }

        return o;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 17px;
}
</style>
