<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('declarations.mass_update.steps.process_type') }}:</label>
          <label class="ml-5">{{ processType }}</label>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex w-full">
          <label class="text ">{{ $t("declarations.mass_update.selected_properties_list") }}</label>
        </div>
        <div class="flex justify-around space-x-1 mt-2">
          <DataGrid grid-id="grid-selected-properties-3"
                  :local-data="selectedProperties"
                  :columns="columns"
                  :pagination="false"
                  default-sort-attr="propertyTitle"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
                  :is-read-only="true"
        />
        </div>
        <div class="box flex justify-start items-center mb-10">
          <img class="image ml-2" :src="getAssetPath('warning.svg')"/>
          <label class="text-left ml-2">{{ $t('declarations.mass_update.save_warning_info') }}</label>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('declarations.mass_update.execute')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";

export default {
  name: "Step5",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      poaService: new PowersOfAttorneyService(),
      selectedProperties: [],
      poaList: [],
      columns: [
        {
          field: 'propertyTitle',
          header: this.$t('declarations.mass_update.property'),
          isChecked: true,
          sortable: false,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell',
          autoWidth: true
        }, {
          field: 'clientName',
          header: this.$t('declarations.mass_update.client'),
          isChecked: false, // only set true for bulk add from properties
          sortable: false,
          autoWidth: true
        }, {
          field: 'declarationReferenceDateLabel',
          header: this.$t('declarations.add_declaration.steps.step2.reference_date'),
          isChecked: true,
          sortable: false,
          autoWidth: true
        }, {
          field: 'declarationNumber',
          header: this.$t('declarations.add_declaration.steps.step2.declaration_number'),
          isChecked: true,
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.tooltip.type'),
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
          autoWidth: true
        }, {
          field: 'declarationTypeLabel',
          header: this.$t('declarations.add_declaration.steps.step2.type'),
          isChecked: true,
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.tooltip.declaration_number'),
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/grund-der-feststellung/",
          autoWidth: true
        }, {
          field: 'recipientTypeLabel',
          header: this.$t('declarations.add_declaration.steps.step3.title'),
          isChecked: true,
          sortable: false,
          autoWidth: true
        }, {
          field: 'participation',
          header: this.$t('declarations.add_declaration.steps.step3.participation'),
          isChecked: true,
          colElType: 'checkbox',
          disabledEl: true,
          enableDisabledChecked: true,
          sortable: false,
          autoWidth: true
        }
      ],
      fromPropertiesActive: false
    }
  },
  watch: {
    active(newValue) {
      if(newValue) {
        if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
          this.fromPropertiesActive = this.getSteps().fromPropertiesActive === true;
          this.selectedProperties = JSON.parse(JSON.stringify(this.getSteps().selectedProperties));
          this.selectedProperties = this.selectedProperties.map((o) => {
            o.declarationTypeLabel = '';
            o.recipientTypeLabel = '';
            if(o.dataSources['declarationType'] && o.declarationType) {
              let index = o.dataSources['declarationType'].findIndex(s => s.code === o.declarationType);
              if(index >= 0) {
                o.declarationTypeLabel = o.dataSources['declarationType'][index].name;
              }
            }

            o.declarationReferenceDateLabel = '';
            if (o.dataSources['declarationReferenceDate'] && o.declarationReferenceDate) {
              let index = o.dataSources['declarationReferenceDate'].findIndex(s => s.code === o.declarationReferenceDate);
              if (index >= 0) {
                o.declarationReferenceDateLabel = o.dataSources['declarationReferenceDate'][index].name;
              }
            }

            if(o.dataSources['recipientType'] && o.recipientType) {
              let parts = o.recipientType.split(':');
              let index = o.dataSources['recipientType'].findIndex(s => s.code === parts[0]);
              if(index >= 0) {
                o.recipientTypeLabel = o.dataSources['recipientType'][index].name;
                if(parts[0] === 'tax_consultant' && parts[1]) {
                  let selectedPoa = this.poaList.filter(poa => parseInt(poa.code) === parseInt(parts[1]));
                  if(selectedPoa.length > 0) {
                    o.recipientTypeLabel += ' (' + selectedPoa[0].name + ')';
                  }
                }
              }
            }

            if (this.fromPropertiesActive) {
              o.clientName = o.client_name;
            }

            return o;
          });

          this.triggerReload('grid-selected-properties-3');
        }
      }
    }
  },
  computed: {
    processType() {
      return this.getSteps().processType ? this.$t('declarations.mass_update.process')[this.getSteps().processType] : '';
    }
  },
  mounted() {
    this.poaService.listAll().then((list) => {
      this.poaList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
    });
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    submitNext() {
      this.selectedProperties = this.selectedProperties.map((o) => {
        delete o.dataSources;
        return o;
      });
      this.saveSteps({
        "selectedProperties": this.selectedProperties
      });

      this.$emit("finish")
    },
    goBack() {
      this.$emit("goBack");
    }
  },
  beforeMount() {
    if (this.$route.name === 'Properties') {
      this.columns.find(c => c.field === 'clientName').isChecked = true;
      this.columns.find(c => c.field === 'clientName').header =
          this.$isBusiness ? this.$t('declarations.mass_update.clients_business') : this.$t('declarations.mass_update.client');
    }
  }

}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
::v-deep {
  .property-type-cell {
    padding-left: 0px !important;
  }
}
</style>
