<template>
  <ModalDialog
    v-show="show"
    @close="close()"
    style="z-index: 99"
    :headerText=" $t('declarations.detail.tax_calculation.error_title')"
  >
    <template v-slot:body>
      <div class="flex flex-col justify-start">
        <div class="flex justify-around mt-2 space-x-10">
          <div class="w-full mt-2 text-left">
            <p>{{ $t('declarations.detail.tax_calculation.error_message') }}</p>
          </div>
        </div>
        <div class="flex justify-around mt-2 space-x-10">
          <div class="w-full flex flex-col justify-start mt-2">
            <ul  class="ul-list">
              <li v-for="(error, index) in errors" :key="index">{{ error.readable }}</li>
            </ul>
          </div>
        </div>
        <div class="flex justify-around space-x-10 mt-4 mb-4" v-show="errorKey === 'no_land_value_HE_NI'">
          <InputText
              class="mt-2 w-full"
              v-model="areaOfTheLand1"
              :label="$t('declarations.detail.area_of_the_land_data_modal.input1')"
              placeholder=""
              :isOnlyNumber="true"
              :isOnlyPositive="true"
              :maxLength="9"
          />
          <InputText
              class="mt-2 w-full"
              :decimalNumber="2"
              :maxValue="maxAreaOfLandValue1"
              :isFloat="true"
              v-model="areaOfTheLandValue1"
              :label="$t('declarations.detail.area_of_the_land_data_modal.input2')"
              placeholder=""
          />
        </div>
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
          <Button class="" :text="$t('general.cancel')" :secondary="errorKey === 'no_land_value_HE_NI'" @click="close()"/>
          <Button class="" :text="$t('general.submit')" @click="saveAreaOfTheLandData" v-show="errorKey === 'no_land_value_HE_NI'"/>
        </div>
      </div>
    </template>
  </ModalDialog>
</template>

<script>
import ModalDialog from '../../ModalDialog.vue';
import {DeclarationService} from "@/services/declaration.service";

export default {
  name: 'TaxCalculationErrorModal',
  extends: ModalDialog,
  props: {
    errorKey: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    header: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    declarationUid: {
      type: Number,
      default: 0
    },
    defaultAreaOfTheLand1: {
      type: String,
      default: ''
    },
    defaultAreaOfTheLandValue1: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      service: new DeclarationService(),
      areaOfTheLand1: this.defaultAreaOfTheLand1,
      areaOfTheLandValue1: this.defaultAreaOfTheLandValue1,
      maxAreaOfLandValue1: 999999.99
    };
  },
  watch: {
    defaultAreaOfTheLand1: function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.areaOfTheLand1 = newValue;
    },
    defaultAreaOfTheLandValue1: function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.areaOfTheLandValue1 = newValue;
    }
  },
  mounted() {
    this.areaOfTheLand1 = this.defaultAreaOfTheLand1;
    this.areaOfTheLandValue1 = this.defaultAreaOfTheLandValue1;
  },
  methods: {
    saveAreaOfTheLandData() {
      this.service.saveAreaOfTheLandData({
        'prim_uid': this.declarationUid,
        'areaOfTheLand1': this.areaOfTheLand1,
        'areaOfTheLandValue1': this.areaOfTheLandValue1
      }).then((res) => {
        if (res) {
          this.close();
          this.$emit('regenerate-tax-calculation');
        }
      })
    }
  }
}
</script>

<style scoped>
ul.ul-list {
  padding: 0;
  margin: 20px 0 0 20px;
  max-width: 100%;
}

ul.ul-list li {
  font-size: 15px;
  margin-bottom: 8px;
  list-style: disc;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
}
</style>
