<template>
  <div class="register-container">
    <div class=" h-full w-600 flex flex-col justify-between   border border-gray_df  p-5 bg-white">
      <div v-if="renderPage" class="flex flex-col items-center  justify-center">
        <div v-if="getTheme()==='ebnerstolz'" class="flex flex-col  items-center">
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img class="mb-4  " src="@/assets/themes/ebnerstolz/dashboard_header.svg">
          </a>
        </div>
        <div v-else>
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img width="168px" height="64px" class=" mt-5" :src="getAssetPath('logo.svg')">
          </a>
        </div>
        <div class=" px-5 w-full mt-4">
          <div class="  justify-center w-full items-center">
            <FormSteps :steps="steps"/>
          </div>
          <div class="mt-10 mr-10 ml-10" v-if="completed===false">
            <component
                v-for="(item, index) in steps"
                :key="`component-${index}`"
                :is="item.component"
                :stepConfig="item"
                @goToNextStep="goToNextStep"
                @goBack="goBack"
                @finish="finish"
                v-show="item.active === true"
            />
          </div>
          <div class="mt-10 mr-10 ml-10" v-else>
            <Finish/>
          </div>
        </div>
      </div>
      <div class="footer flex justify-end">
        <div class="flex flex-col w-full">

          <span class="footer-text"> {{ $t('general.footer.ssl') }} </span>
          <div>
            <a class="link"
               :href="getTheme()==='ebnerstolz'?'https://www.ebnerstolz.de/de/impressum-4908.html':'https://grundsteuer-digital.de/impressum'"
               target="_blank">{{ $t('general.footer.imprint') }}</a>
            <span class="footer-text"> | </span>
            <a class="link"
               :href="getTheme()==='ebnerstolz'?'http://ebnerstolz.de/grundsteuer-avv':'https://grundsteuer-digital.de/datenschutz-grundsteuerdigital-anwendung/'"
               target="_blank">{{ $t('general.footer.protection') }}</a>

            <span class="footer-text"> | ©  {{
                new Date().getFullYear()
              }} {{ getTheme() === 'ebnerstolz' ? 'Ebner Stolz Digital Solutions GmbH' : 'fino taxtech GmbH' }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import FormSteps from "../components/FormSteps";
import Step1 from "../components/Register/Step1";
import Step2 from "../components/Register/Step2";
import Step3 from "../components/Register/Step3";
import Step4 from "../components/Register/Step4";
import Finish from "../components/Register/Finish";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Register",

  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  components: {
    FormSteps, Step1, Step2, Step3, Step4, Finish
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          title: this.$t('step1Label'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        }, {
          id: 2,
          title: this.$t('step2Label'),
          completed: false,
          active: false,
          component: "Step2"
        }, {
          id: 3,
          title: this.$t('step3Label'),
          completed: false,
          active: false,
          component: "Step3"
        }, {
          id: 4,
          title: this.$t('step4Label'),
          completed: false,
          active: false,
          last: true,
          component: "Step4"
        }
      ],
      completed: false,
      renderPage: false
    }
  },
  computed: {
    ...mapGetters("register", ["getCurrentStep"]),
    getTitle() {
        return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : this.$t('register.pageTitle')
    }
  },
  created() {
    this.changeWindowSize()
    if(!this.isAuthorized() && this.$route.query.preOrderSignup && this.$route.query.preOrderSignup !== '') {
      this.$api.trigger('verify_code/pre_order_signup', {
        'code': this.$route.query.preOrderSignup
      }, true).then((response) => {
        if(response.data.success) {
          this.steps = [
            {
              id: 1,
              title: this.$t('step3Label'),
              completed: false,
              active: true,
              first: true,
              component: "Step3"
            }, {
              id: 2,
              title: this.$t('step4Label'),
              completed: false,
              active: false,
              last: true,
              component: "Step4"
            }
          ];
          this.renderPage = true;
        } else {
          this.$router.push('/');
        }
      })
    } else {
      this.renderPage = true;
    }
  },
  methods: {
    ...mapMutations("register", ["setCurrentStep"]),
    ...mapGetters("user", ["isAuthorized", "getCurrentTheme"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    finish() {
      this.moveStep('next', true)
      this.completed = true
    },
    changeWindowSize() {
      if(window.ipcRenderer) {
        window.ipcRenderer.send('resizeRegister')
      }
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps.slice(1, 1)
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }
        })
        this.setCurrentStep(step)
      }
    }
  },
}
</script>

<style scoped lang="scss">

.footer {
  margin-top: 25px;
}

.footer-text {
  font-family: 'Segoe UI', sans-serif;
  color: #AEAEAE;
  text-align: center;
  line-height: normal;
  font-size: 14px;

}

.link {
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  color: $primary;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  text-decoration: underline;
}

.register-container {
  height: 100vh;
  display: inline-table;
}
</style>
