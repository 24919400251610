<template>
  <div class="h-full">
    <!-- add changes in detail -->
    <ModalDialog
        v-show="isChangesInDetail"
        :headerText="$t('components.changes_in_detail')"
        :canCloseOutsideClick="false"
        @close="isChangesInDetail=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex justify-around space-x-10 mt-3 mb-10">
          <table class="changesList bg-white">
            <thead>
              <tr>
                <th>
                  <InputSelect class="mb-5 w-full"
                              v-model="selectModalChangesData.oldTitle"
                              :selected-value="selectModalChangesData.oldTitle"
                              :options="selectModalChangesData.oldDeclarationTitleOptions"
                              :disabled="selectModalChangesData.declarationTitleDisabled"
                              overlay-append-to="body"
                              placeholder=""
                  />
                </th>
                <th></th>
                <th>
                  <div class="mb-5 w-full">
                    <label class="label-text-bold">{{ $t('components.changes_detail_current_status') }}</label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="renderChangesInDetail">
              <tr v-for="(col, index) of getChangeManagementChangesInDetail(slotPropsChangesInDetail.data.declarationSubmissions, selectModalChangesData.oldTitle)" :key="index"">
                <td
                  class="align-top border-left-gray line-height-1-5 w-1/2"
                  :class="{'border-top-gray': index == 0, 'border-bottom-gray': index == lastIndexChangeManagementChangesArray}"
                >
                  <span v-if="col.table.length > 0"><div class="table-title">{{ col.table }}</div><br/></span>
                  <span class="label-text-bold mb-2">{{ col.attr }}</span><br />
                  <del class="span-old ml-2" v-if="col.old.length > 0">{{ col.old }}</del>
                  <span v-if="col.old.length == 0">&nbsp;</span>
                </td>

                <td class="align-top border-right-left-gray" style="min-width:24px;"></td>

                <td
                  class="align-top border-right-gray  line-height-1-5 w-1/2"
                  :class="{'border-top-gray': index == 0, 'border-bottom-gray': index == lastIndexChangeManagementChangesArray}"
                >
                  <span v-if="col.table.length > 0"><div class="table-title">{{ col.table }}</div><br/></span>
                  <span class="label-text-bold mb-2">{{ col.attr }}</span><br />
                  <span class="span-new ml-2" v-if="col.new.length > 0">{{ col.new }}</span>
                  <span v-if="col.new.length == 0">&nbsp;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>
        <hr class="mt-3 mb-4 hr-line-user">
        <div class="flex justify-end">
          <div class="flex mt-4 mb-8 space-x-4 ">
            <label class="label-text-bold">{{ $t('components.changes_detail_save_ask') }}</label>
            <Button class="w-40" :text="$t('buttons.yes')" @click="showNextDeclarationTypeDialog"></Button>
            <Button class="w-40" @click="cancelNextDeclarationType" :text="$t('buttons.no')" :secondary="true"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- next declaration type modal -->
    <ModalDialog
        v-show="nextDeclarationTypeDialog"
        :headerText="nextDeclarationTypeModalTitle"
        @close="nextDeclarationTypeDialog = false"
    >
      <template v-slot:body>
        <div class="flex flex-col w-full">
          <div class="flex justify-start text-left pb-3">
            <label>
              {{ nextDeclarationTypeModalDescription }}
            </label>
          </div>
          <InputSelect
              class="mt-2 w-full"
              v-model="nextDeclarationType"
              :selected-value="nextDeclarationType"
              :label="$t('properties.relevant.title-1')"
              :options="nextDeclarationTypeOptions"
              @on-item-selected="selectNextDeclarationTypeOpt"
              placeholder=""
              overlay-append-to="body"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button @click="nextDeclarationTypeDialog = false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="nextDeclarationTypeModalButtonText" @click="selectNextDeclarationType"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!--   column Select-->
    <ModalDialogTable v-show="isSelectColDialogVisible" :canEscape="true"
                      :headerText="$t('general.grid.select_col')"
                      :canCloseOutsideClick="false" @close="closeSelectColDialog">
      <template v-slot:body>
        <div
            class="flex flex-col h-full overflow-y-scroll select-columns-pnl  justify-start space-y-4 ml-4 pr-4  mt-4 ">
          <InputCheck v-for="(item,index) in availableColumns"
                      :text="item.header"
                      :dataTableItem="item"
                      :isChecked="item.isChecked"
                      :key="index"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end items-center space-x-4 pb-20 mt-10 mr-6">
          <Button class="w-40" :text="$t('buttons.cancel')" @click="closeColumnFilter" :secondary="true"/>
          <Button class="w-40" :text="$t('buttons.save')" @click="saveColumnFilter"/>
        </div>
      </template>
    </ModalDialogTable>

    <CommunitySelector v-show="this.showCommunitySelect"
                       :federalStateId="communitySelectorFederalStateUid"
                       :declarationId="communitySelectorDeclarationUid"
                       :context="communitySelectorContext"
                       @check="handleCommunityCheck"
                       @close=handleCommunityClose
    />

    <div v-if="gridData.length === 0 && !gridLoading && showCreateRecord && !searchActive"
         class="w-full table-container space-y-2 flex flex-col justify-center items-center">
      <img class="empty" :src="getAssetPath('empty.svg')"/>
      <label class="empty-text">{{ $t('general.grid.now_start') }}</label>
      <label class="empty-subtext">{{ $t('general.grid.no_records_found') }}</label>
      <Button class="w-40" :text="$t('general.grid.create_new')" @click="createNew"/>
    </div>
    <div v-if="gridData.length === 0 && !gridLoading && showDmsNotice && !searchActive"
         class="w-full table-container space-y-2 flex flex-col justify-center items-center">
      <img class="empty" :src="getAssetPath('empty.svg')"/>
      <label class="empty-text">{{ $t('general.grid.dms.nothing_found') }}</label>
      <label class="empty-subtext">{{ $t('general.grid.dms.nothing_found_info') }}</label>
      <Button class="w-40" :text="$t('general.grid.dms.button')" @click="goToClients"/>
    </div>
    <div v-else-if="!fetchingState" class="w-full table-container"
         :class="{containerClass:containerClass, 'compact-grid-view':currentView==='compact'}"
    >
      <DataTable v-if="showTable" :value="gridData" :lazy="lazyLoad"
                 :ref="`dt-${gridId}`"
                 :selection.sync="selectedRecords"
                 :data-key="selectionDataKey"
                 :expandedRows.sync="expandedRows"
                 :totalRecords="totalRecords"
                 :scrollable="true" scrollHeight="flex"
                 :sortField="sortAttr" :sortOrder="sortOrder"
                 :loading="gridLoading"
                 :resizableColumns="resizableColumns"
                 column-resize-mode="expand"
                 state-storage="session"
                 :state-key="getStateKey"
                 :rows="rowsPerPage"
                 :paginator="pagination"
                 :always-show-paginator="alwaysShowPagination"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                 :rowsPerPageOptions="rowsPerPageOptions"
                 :currentPageReportTemplate="$t('general.grid.page_info')"
                 :reorderableColumns="reorderableColumns"
                 styleClass="tdLessPadding"
                 :auto-layout="false"
                 :alwaysShowPaginator="true"
                 :editMode="editMode"
                 @row-select="rowSelect"
                 @row-select-all="rowSelectAll"
                 @row-unselect="rowUnselect"
                 @row-unselect-all="rowUnselectAll"
                 @column-resize-end="colStateHandler"
                 @column-reorder="colStateHandler"
                 @virtual-scroll="scrollGrid"
                 @page="changePage"
                 @state-save="stateSave"
                 @sort="sortGrid($event)"
                 @cell-edit-complete="cellEditCompleted"
                 v-observe-visibility="{callback: loadGridData, once: true}"
      >

        <template #empty>
          <div class="-ml-3">{{ $t('general.grid.no_records_found') }}</div>
        </template>
        <Column v-if="expandable"
                :reorderable-column="false"
                :expander="true"
                header-class="checkHeader"
                body-class="checkHeader"
                column-key="0"
        >
        </Column>
        <Column v-if="selectable"
                :reorderable-column="false"
                :expander="false"
                selection-mode="multiple"
                header-class="checkHeader"
                body-class="checkHeader"
                column-key="1"
        >
        </Column>
        <Column v-for="(col,index) of selectedColumns" :field="col.field" :header="col.showHelpIcon ? '' : col.header"
                :header-style="getHeaderStyle(col)"
                :header-class="col.headerClass ? col.headerClass : ''"
                :key="col.field + '_' + index"
                :sortable="isSortable(col)"
                :bodyClass="cellClass(col)"
                :body-style="getBodyStyle(col)"
                :hidden="!col.isChecked"
        >

          <template #header>
            <div class="flex items-center cursor-pointer" v-if="col.showHelpIcon">
              <span class="p-column-title">
                <InputCheck v-if="col.showHeaderCheckbox"
                            :ref="buildHeaderCheckboxRef(col)"
                            :isChecked="col.headerCheckboxValue"
                            :isDisabled="col.disabledEl"
                            :enableDisabledChecked="col.enableDisabledChecked"
                            @check="handleColHeaderCheck(col)"
                />
                <span v-if="col.showHeaderCheckbox" class="pl-30" >{{ col.header }}</span>
                <span v-else> {{ col.header }}</span>
              </span>
              <VDropdown offset="2" v-if="col.helpIconType === 'popover'" :showTriggers="['click','hover']"
                         :popperTriggers="['hover','click']"
                         :hideTriggers="['click']">
                <!-- This will be the popover target (for the events and position) -->
                <img class="column_icon right_column_icon help_icon" :src="getAssetPath('help_green.svg')"/>
                <!-- This will be the content of the popover -->
                <template #popper>
                  <div class="tooltip-content">
                    <div class="space-y-4">
                      <p class=" popover-text" v-for="(text,index) in col.popoverTexts" :key="index" v-html="text">
                      </p>
                    </div>
                    <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text " :href="col.popoverLink" target="_blank">{{
                        col.popoverLink
                      }}</a></h2>
                  </div>
                  <!-- You can put other components too -->
                </template>
              </VDropdown>
              <img class="column_icon right_column_icon help_icon" v-else :src="getAssetPath('help_green.svg')"/>
            </div>
          </template>
          <template #body="slotProps">
            <div v-if="col.prependIcon" class="prepend_icon">
              <img v-if="hasTooltip(col, slotProps.data) && colClickable(col, slotProps.data, true)" class="cursor-pointer"
                   :src="col.getIcon(slotProps.data)" v-tooltip="colTooltip(col, slotProps.data)"
                   @click="handlePrependIconClick(col.field, slotProps.data)"
              >
              <img v-else-if="hasTooltip(col, slotProps.data)" :src="col.getIcon(slotProps.data)" v-tooltip="colTooltip(col, slotProps.data)">
              <img v-else-if="colClickable(col, slotProps.data, true)" class="cursor-pointer"
                   :src="col.getIcon(slotProps.data)"
                   @click="handlePrependIconClick(col.field, slotProps.data)"
              >
              <img v-else :src="col.getIcon(slotProps.data)">
            </div>
            <div v-if="col.multiIcons" class="multi_icons">
              <img v-for="(icon, index) of col.getIcons(slotProps.data)" :key="index" :src="icon.src" :class="icon.clickable ? 'cursor-pointer' : ''" @click="handleIconClick(col.field, slotProps.data, icon)">
            </div>
            <div v-else-if="colClickable(col, slotProps.data)" class="cell-content" :class="'clickable-' + col.field">
              <span v-if="col.html" class="cursor-pointer"
                    @click="handleColClick(col.field, slotProps.data)"
                    v-html="slotProps.data[col.field]"
              />
              <span v-else class="cursor-pointer"
                    @click="handleColClick(col.field, slotProps.data)">{{ slotProps.data[col.field] }}</span>
            </div>
            <div v-else-if="col.href" class="cell-content">
              <a :href="'mailto:' + slotProps.data[col.field]" v-if="col.href === 'mailto'">{{
                  slotProps.data[col.field]
                }}</a>
              <a :href="slotProps.data[col.field]" v-else>{{ slotProps.data[col.field] }}</a>
            </div>
            <div v-else-if="col.html" class="cell-content html" v-html="slotProps.data[col.field]"/>
            <div v-else-if="col.image" class="cell-content">
              <img v-if="hasTooltip(col, slotProps.data)" class="column_icon" :src="getAssetPath(slotProps.data[col.field])"
                   v-tooltip="colTooltip(col, slotProps.data)"/>
              <img v-else class="column_icon" :src="getAssetPath(slotProps.data[col.field])"/>
            </div>
            <div v-else-if="col.colElType" class="cell-el-content flex justify-center">
              <!-- Input field -->
              <InputText v-if="col.colElType === 'text'"
                         class="w-full"
                         :bodyClass="col.bodyClass"
                         :disabled="isColElDisabled(col, slotProps.data)"
                         v-model="slotProps.data[col.field]"/>

              <div v-if="col.colElType === 'textinput'">
                <input :value="slotProps.data[col.field]" type="text" />
              </div>
              <!-- Dropdown field -->
              <InputSelect v-if="col.colElType === 'select'"
                           class="w-full"
                           v-model="slotProps.data[col.model]"
                           :selected-value="slotProps.data[col.model]"
                           :options="getDataSource(col, slotProps.data)"
                           :filterable="col.dataSource.length > 10"
                           :disabled="isColElDisabled(col, slotProps.data)"
                           overlayAppendTo="body"
                           @on-item-selected="handleItemSelected($event, col, slotProps.data)"
                           :colData="slotProps.data"
              />
              <poa-select v-if="col.colElType === 'poa-select'"
                           class="w-full"
                           v-model="slotProps.data[col.model]"
                           :selected-value="slotProps.data[col.model]"
                           :poa-type-options="getDataSource(col, slotProps.data)"
                           :filterable="col.dataSource.length > 10"
                           :disabled="isColElDisabled(col, slotProps.data)"
                           overlayAppendTo="body"
                           @on-item-selected="handleItemSelected($event, col, slotProps.data)"
                           :colData="slotProps.data"
              />
              <TransferStatus v-if="col.colElType === 'transferstatus'"
                              :id="slotProps.data[col.idField]"
                              :record="slotProps.data"
                              />
              <InputDate
                v-if="col.colElType === 'select-date'"
                class="w-full"
                v-model="slotProps.data[col.model]"
                :selected-value="slotProps.data[col.model]"
                :options="getDataSource(col, slotProps.data)"
                :filterable="col.dataSource.length > 10"
                :disabled="isColElDisabled(col, slotProps.data)"
                :isError="(typeof col.isErrorCallback !== 'undefined') ? col.isErrorCallback(slotProps.data) : false"
                :errorMessage="(typeof col.errorMessage !== 'undefined') ? col.errorMessage : $t('general.required')"
                :minDateValueProp="(typeof col.minDate !== 'undefined') ? col.minDate : null"
                :maxDateValueProp="(typeof col.maxDate !== 'undefined') ? col.maxDate : null"
                overlayAppendTo="body"
                @on-item-selected="handleItemSelected($event, col, slotProps.data)"
                :colData="slotProps.data"
              />
              <div v-if="col.colElType === 'propertyvalue'">
                <div v-if="slotProps.data[col.field] == 0">
                 <Button @click="handleCommunityClick(parseInt(slotProps.data[col.fieldFederalState]), parseInt(slotProps.data[col.fieldDeclarationUid]), slotProps.data)"
                         :text="$t('declarations.tax_calculation.select_community')"
                         class="button-label text-center bg-gray text-white"
                  />
                </div>
                <div v-else>
                  {{ slotProps.data[col.field] }}
                </div>
              </div>
              <!-- Input checkbox field -->
              <InputCheck
                v-if="col.colElType === 'checkbox'"
                :isChecked="slotProps.data[col.field]"
                class="center-aligned"
                :isDisabled="col.disabledEl"
                :enableDisabledChecked="col.enableDisabledChecked"
                @check="handleColCheck(col.field, slotProps.data)"
              />
            </div>
            <div v-else :class="col.editorElType ? 'cell-el-content cursor-pointer' : 'cell-content'">
              {{ slotProps.data[col.field] }}
            </div>
            <div v-if="col.menus" class="grid_action">
              <div v-for="(menu) of col.menus" :key="menu.id" class="action_icons"
                   v-show="menuVisible(menu, slotProps.data)" :class="menu.class"
                   @click="actionMenu($event, menu, slotProps.data)">
                <img class="um_actions_img" :src="getAssetPath(menu.icon)">
              </div>
            </div>
          </template>
          <template #editor="slotProps" v-if="col.editorElType">
            <div class="cell-el-content flex justify-center">
              <!-- Dropdown field -->
              <InputSelect v-if="col.editorElType === 'select'"
                           class="w-full"
                           v-model="slotProps.data[col.model + 'Changed']"
                           :selected-value="slotProps.data[col.model]"
                           :options="getDataSource(col, slotProps.data)"
                           :filterable="col.dataSource.length > 10"
                           :disabled="isColElDisabled(col, slotProps.data)"
                           overlayAppendTo="body"
              />
            </div>
          </template>
        </Column>
        <Column :reorderableColumn="false" field="action" v-if="showSelectCol" header-style="width: 200px;"
                body-style="width:200px;">
          <template #header>
            <div class="flex justify-center items-center cursor-pointer" @click="selectColumns">
              <img class="column_icon" :src="getAssetPath('select_column_green.svg')"/>
              <p> {{ $t('general.grid.select_col') }}</p>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps" v-if="expandable">
          <table class="versionList bg-white divide-y divide-gray" v-if="expansionFormat==='Version'">
            <tr>
              <td colspan="2" class="event_lbl">{{ slotProps.data.event }}</td>
            </tr>
            <tr v-for="(col, index) of getVersionChanges(slotProps.data.changes)" :key="index">
              <td class="attr align-top">{{ col.attr }}</td>
              <td class="allow-wrap">
                <del v-if="col.old.length > 0">{{ col.old }}<br /></del>
                {{ col.new }}
              </td>
            </tr>
          </table>
          <table class="versionList bg-white divide-y divide-gray" v-if="expansionFormat==='keyValuePair' && slotProps.data[expandableDataKey]">
            <tr v-for="(value, key) of slotProps.data[expandableDataKey]" :key="key">
              <td class="attr">{{ key }}</td>
              <td>{{ value }}</td>
            </tr>
          </table>
          <table class="versionList bg-white" v-if="expansionFormat==='ChangeManagement'">
            <tr>
              <td colspan="2" class="event_lbl">{{ slotProps.data.event }}<hr class="mt-3 hr-line-user"></td>
            </tr>
            <tr v-for="(col, index) of getChangeManagementChanges(slotProps.data.changes, slotProps.data.prim_uid)" :key="index" v-if="index < 4">
              <td class="attr align-top">
                <div class="table-title" v-if="col.table.length > 0">{{ col.table }}</div>
                <span class="label-text-bold">{{ col.attr }}</span>
                <br/><br/>
                <del>{{ col.old_truncated }}</del>
              </td>
              <td class="allow-wrap align-top">
                <div class="table-title invisible" v-if="col.table.length > 0">&nbsp;</div>
                <span class="label-text-bold">{{ col.attr }}</span>
                <br/><br/>
                {{ col.new_truncated }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="event_lbl"><div  class="flex cursor-pointer color-green-dashboard" @click="addChangesInDetail(slotProps)" >{{ slotProps.data.eventMore }}</div></td>
            </tr>
          </table>
        </template>
      </DataTable>
      <Menu ref="refActionMenu" :model="actionMenuItems" :popup="true"/>
    </div>
  </div>
</template>

<script>

import DataTable from "primevue/datatable";
import Menu from 'primevue/menu';
import Column from 'primevue/column';
import tooltip from 'primevue/tooltip';
import {GridService} from "@/services/grid.service";
import {mapGetters, mapMutations} from "vuex";
import {importErrorCols} from "@/core/constants";
import _orderBy from "lodash/orderBy"
import _random from "lodash/random"
import _clone from "lodash/clone"
import TransferStatus from "@/components/widgets/TransferStatus";
import poaSelect from "@/components/declarations/PoaSelect";

import {PropertyService} from "@/services/property.service";
import CommunitySelector from "@/components/declarations/components/CommunitySelector.vue";

export default {
  name: "DataGrid",
  directives: {'tooltip': tooltip},
  components: {
    CommunitySelector,
    DataTable, Column, Menu, TransferStatus, poaSelect
  },
  props: {
    gridId: {
      type: String,
      default: ""
    },
    dataEndpoint: {
      type: String,
      default: ""
    },
    countEndpoint: {
      type: String,
      default: ""
    },
    columns: {
      type: Array,
      default: () => []
    },
    localData: {
      type: Array,
      default: () => []
    },
    gridType: {
      type: String,
      default: ''
    },
    useLocalData: {
      type: Boolean,
      default: false
    },
    showSelectCol: {
      type: Boolean,
      default: true
    },
    defaultSortAttr: {
      type: String,
      default: ""
    },
    defaultSortDir: {
      type: Number,
      default: -1
    },
    resizableColumns: {
      type: Boolean,
      default: true
    },
    reorderableColumns: {
      type: Boolean,
      default: true
    },
    pageLength: {
      type: Number,
      default: 25
    },
    virtualRowHeight: {
      type: Number,
      default: 50
    },
    pagination: {
      type: Boolean,
      default: true
    },
    alwaysShowPagination: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    selectionDataKey: {
      type: String,
      default: ''
    },
    navigationDataKey: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    serverParams: {
      type: Object,
      default: () => {
      }
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    expansionFormat: {
      type: String,
      default: 'Version'
    },
    expandableDataKey: {
      type: String,
      default: ''
    },
    stateFul: {
      type: Boolean,
      default: true
    },
    stateFulFilter: {
      type: Boolean,
      default: false
    },
    showCreateRecord: {
      type: Boolean,
      default: true
    },
    showDmsNotice: {
      type: Boolean,
      default: false
    },
    searchActive: {
      type: Boolean,
      default: false
    },
    defaultCellWidth: {
      type: Number,
      default: 150
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    showStateLoader: {
      type: Boolean,
      default: true
    },
    showInfoOnLoad: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: String,
      default: null
    },
    initialTxtFilter: {
      type: String,
      default: ""
    }
  },
  computed: {
    availableColumns() {
      return _orderBy(this.columnDefs.filter(o => {
        if (!o.hideInFilter && !o.hideInChecked) {
          return o;
        }
      }), 'listOrder', 'asc');
    },
    getStateKey() {
      return this.stateFul ? 'dt-state-' + this.gridId : null;
    },
    rowsPerPageOptions() {
      return this.$isBusiness ? this.rowsPerPageOptionsForBusiness : this.rowsPerPageOptionsForTc;
    },
    showTable() {
      if (this.gridData.length === 0 && !this.gridLoading && !this.searchActive) {
        if (this.showDmsNotice || this.showCreateRecord) {
          return false;
        }
      }

      return this.renderTable;
    },
  },
  data() {
    return {
      selectModalChangesData: {
        oldTitle: 0,
        oldDeclarationTitleOptions: [],
        declarationTitleDisabled: false,
      },
      lastIndexChangeManagementChangesArray: 0,
      property_uid: 0,
      lastDeclarationUid: 0,
      service: new PropertyService(),
      propertyData: {step2 : {}},
      nextDeclarationTypeDialog: false,
      cancelNextDeclarationTypeDialog: false,
      nextDeclarationType: '0',
      nextDeclarationReferenceDate: '',
      lastCheckedForChanges: '0',
      nextDeclarationTypeOptions: [],
      gridService: new GridService(this.gridId),
      gridData: [],
      columnDefs: [],
      fetchingState: true,
      lazyLoad: true,
      selectedRecords: null,
      expandedRows: null,
      gridLoading: true,
      totalRecords: 0,
      rowsPerPage: 25,
      gridParams: {},
      selectedColumns: null,
      isSelectColDialogVisible: false,
      isChangesInDetail: false,
      slotPropsChangesInDetail: {
        data: {
          changes : [],
          declarationSubmissions: [],
        },
      },
      actionMenuItems: [],
      initialized: false,
      localFilterAttrs: [],
      currentView: 'normal',
      renderTable: true,
      sortAttr: '',
      sortOrder: '',
      triggerStateSave: false,
      rowsPerPageOptionsForTc: [10, 25, 50, 100, 250],
      rowsPerPageOptionsForBusiness: [10, 25, 50, 100, 250, 500, 1000],
      recordUids: [],
      showCommunitySelect: false,
      communitySaveChecked: true,
      communitySelectorDeclarationUid: 0,
      communitySelectorFederalStateUid: 0,
      communitySelectorContext: {},
      nextDeclarationTypeModalTitle: '',
      nextDeclarationTypeModalDescription: '',
      nextDeclarationTypeModalButtonText: '',

      renderChangesInDetail: true,
    }
  },
  async mounted() {
    if (this.gridType === 'importError') {
      this.columns = importErrorCols;
      this.containerClass = 'importGrid';
      this.pagination = false;
    }

    this.rowsPerPage = this.pageLength;
    let columnCount = this.columns.length;
    this.columnDefs = this.columns.map((o, index) => {
      o.columnOrder = index;
      o.listOrder = columnCount + index;
      return o;
    });
    this.columnDefs = this.columnDefs.filter((o) => {
      if (typeof o.hideInChecked === 'undefined' || !o.hideInChecked) {
        return true;
      }
    });
    this.selectedColumns = this.columnDefs.filter(o => o.isChecked);
    this.sortAttr = this.defaultSortAttr;
    this.sortOrder = this.defaultSortDir;
    await this.init();

    if (this.useLocalData) {
      this.gridLoading = false;
      this.gridData = this.localData;
      this.totalRecords = this.localData.length;
      this.lazyLoad = false;
      this.sortLocalData();
      this.localFilterAttrs = this.columnDefs.filter((o) => {
        return o.filterable;
      }).map((o) => {
        return o.field;
      });
    } else {
      this.gridLoading = true;
      this.gridParams = {
        first: 0,
        rows: this.rowsPerPage,
        sortField: this.sortAttr,
        sortOrder: this.sortOrder,
        filters: {}
      };
      if (this.initialTxtFilter !== "") {
        this.gridParams.filters["txt_filter"] = this.initialTxtFilter;
      }

      // Apply filter if any saved in session
      if(this.gridId && this.stateFulFilter) {
        let selectedSavedFilter = this.getSelectedFilterState(this.gridId);
        if (selectedSavedFilter) {
          if (selectedSavedFilter.filterId) {
            this.gridParams.filters['selected'] = selectedSavedFilter.filterId;
          }

          if (selectedSavedFilter.txt) {
            this.gridParams.filters['txt_filter'] = selectedSavedFilter.txt;
          }
        }
      }

      if (this.serverParams && Object.keys(this.serverParams).length > 0) {
        if(this.serverParams.filters) {
          this.serverParams.filters = {...this.serverParams.filters, ...this.gridParams.filters}
        }
        this.gridService.setDefaultParams(this.serverParams)
      }

      if (this.autoLoad) {
        this.loadData();
      }
    }
  },
  watch: {
    '$store.state.grid.isReloadGrid'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reloadGrid();
      }
    },
    '$store.state.grid.gridState': {
      deep: true,
      handler: function () {
        this.currentView = this.getGridState(this.gridId, 'view', this.currentView);
      }
    },
    serverParams: {
      handler: function (nv, ov) {
        if (this.serverParams && Object.keys(this.serverParams).length > 0 && !this.fetchingState) {
          this.gridService.setDefaultParams(this.serverParams);
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapMutations("grid", ["reloaded", "initGrid", "saveGridState", "triggerFetchCount", "fetchedCount", "saveGridNavRecords"]),
    buildHeaderCheckboxRef(column) {
      return "header-checkbox-" + column.field;
    },
    async init() {
      this.initGrid(this.gridId);
      if (!this.useLocalData && this.stateFul) {
        if (!this.getGridState(this.gridId, 'stateLoaded')) {
          await this.gridService.getState(this.gridId, this.showStateLoader).then((response) => {
            if (response.data.success) {
              let gridState = JSON.parse(response.data.state);

              if (gridState.dt_state && gridState.dt_state.columnWidths) {
                  gridState.dt_state.columnWidths = gridState.dt_state.columnWidths.replaceAll(',0', ',200');
                  let result = gridState.dt_state.columnWidths.substring(0, 2);
                  if (result === '0,') {
                    gridState.dt_state.columnWidths = gridState.dt_state.columnWidths.replace('0,', '25,');
                  }
              }

              if (Object.keys(gridState).length > 0) {
                gridState.gridId = this.gridId;
                gridState.stateLoaded = true;
                this.saveGridState(gridState);

                if (gridState) {
                  this.applyState(gridState)
                }
              }
            }
          })
        } else {
          let gridState = this.getGridState(this.gridId);
          if (gridState) {
            this.applyState(gridState)
          }
        }
      }
      this.fetchingState = false;
    },
    applyState(gridState) {
      if (gridState.dt_state) {
        gridState.dt_state.first = 0;
        if (typeof (gridState.dt_state.rows) === 'undefined' || !this.rowsPerPageOptions.includes(gridState.dt_state.rows)) {
          gridState.dt_state.rows = 25;
        }
        this.rowsPerPage = gridState.dt_state.rows;

        if (gridState.dt_state.columnOrder && gridState.dt_state.columnOrder.length > 0) {
          this.columnDefs = this.columnDefs.map((o, index) => {
            o.isChecked = gridState.dt_state.columnOrder.includes(o.field);

            let columnOrder = gridState.dt_state.columnOrder.indexOf(o.field);
            if (columnOrder >= 0) {
              o.columnOrder = gridState.dt_state.columnOrder.indexOf(o.field);
            } else {
              o.columnOrder = _random(100, 10000);
            }
            if(o.isChecked) {
              o.listOrder = index;
            }

            return o;
          });
          this.columnDefs = _orderBy(this.columnDefs, "columnOrder", 'asc');
          this.selectedColumns = this.columnDefs.filter(o => o.isChecked);
        }

        if (gridState.dt_state.sortField) {
          this.sortAttr = gridState.dt_state.sortField;
          this.gridParams.sortAttr = this.sortAttr;
        }
        if (gridState.dt_state.sortOrder) {
          this.sortOrder = parseInt(gridState.dt_state.sortOrder);
          this.gridParams.sortOrder = this.sortOrder;
        }

        // Update state
        sessionStorage.setItem('dt-state-' + this.gridId, JSON.stringify(gridState.dt_state));
      } else {
        sessionStorage.removeItem('dt-state-' + this.gridId);
      }

      this.currentView = this.getGridState(this.gridId, 'view', this.currentView);
    },
    hasTooltip(col, data) {
      if (typeof col.tooltip === 'function') {
        return !!col.tooltip(data);
      }
      return false;
    },
    getHeaderStyle(col) {
      let headerStyle = col.headerStyle ? col.headerStyle : '';
      if (col.width) {
        headerStyle += '; width:' + col.width + 'px';
      } else if(typeof(col.autoWidth) === 'undefined') {
        headerStyle += '; width:' + this.defaultCellWidth + 'px';
      }

      return headerStyle;
    },
    getBodyStyle(col) {
      let bodyStyle = col.bodyStyle ? col.bodyStyle : '';
      if (col.width) {
        bodyStyle += '; width:' + col.width + 'px';
      } else if(typeof(col.autoWidth) === 'undefined') {
        bodyStyle += '; width:' + this.defaultCellWidth + 'px';
      }

      return bodyStyle;
    },
    cellClass(col) {
      let cell_css = '';
      if (col.menus) {
        cell_css += ' cell_with_action';
      }
      if (col.prependIcon) {
        cell_css += ' cell_with_icon';
      }
      if (col.bodyClass) {
        cell_css += ' ' + col.bodyClass;
      }
      return cell_css;
    },
    isSortable(col) {
      return typeof (col.sortable) !== 'undefined' ? col.sortable : true;
    },
    reloadGrid(force) {
      if (!this.useLocalData) {
        if (typeof (force) === 'undefined') {
          force = false;
        }
        let index = this.$store.state.grid.gridState.findIndex(o => o.gridId === this.gridId);
        let gridState = index >= 0 ? this.$store.state.grid.gridState[index] : {};
        if (gridState.reload || force) {
          this.$emit('on-data-loading');
          if (typeof this.gridParams.filters === 'undefined') {
            this.gridParams = {
              filters: {}
            };
          }

          this.gridParams.filters['selected'] = gridState.filter.selected ? gridState.filter.selected : '';
          this.gridParams.filters['txt_filter'] = gridState.filter.txt ? gridState.filter.txt : '';
          this.gridParams.first = 0;
          this.gridParams.rows = this.rowsPerPage;

          if (this.serverParams && Object.keys(this.serverParams).length > 0) {
            this.serverParams.filters = {...this.serverParams.filters, ...this.gridParams.filters}
            this.gridService.setDefaultParams(this.serverParams)
          }
          this.loadData();
          this.$emit('selected-filters', this.gridParams);
        }
      } else {
        this.loadData();
      }
    },
    loadData() {
      this.selectedRecords = [];
      this.expandedRows = null;
      this.$emit('reset-selection');
      if (!this.useLocalData) {
        this.gridParams.gridId = this.gridId;
        let fetchCount = this.getGridState(this.gridId, 'fetchCount');
        if(this.countEndpoint !== '' && fetchCount) {
          this.fetchedCount(this.gridId);
          let countRequestParams = _clone(this.gridParams);
          countRequestParams.requestType = 'COUNT';
          this.gridService.trigger(this.dataEndpoint, countRequestParams).then(response => {
            if (response.data.success) {
              this.totalRecords = parseInt(response.data.total_records);
            }
          })
        }

        if(this.countEndpoint !== '') {
          this.gridParams.requestType = 'LIST';
        }

        let selectedColumns = this.columns.filter(o => o.isChecked);
        let selectedColumnNames = [];
        for(let selectedColumn of selectedColumns) {
          selectedColumnNames.push(selectedColumn.field);
        }

        this.$emit('on-data-loading');
        this.gridLoading = true;
        this.gridParams.initialize = this.initialized ? 0 : 1;
        this.gridParams.table = 1;
        this.gridParams.selectedColumns = selectedColumnNames;
        this.gridService.trigger(this.dataEndpoint, this.gridParams).then(response => {
          this.initialized = true;
          this.gridLoading = false;
          this.reloaded(this.gridId);
          if (response.data.success) {
            this.gridData = this.gridService.parse_object_data(response.data.records);
            this.$emit('on-data-loaded', this.gridData);
            if(this.showInfoOnLoad && response.data.info) {
              this.$api.showToast(response.data.info, "info");
            }

            if (typeof response.data['isReferenceParcelInfo'] === 'object') {
              this.$emit('on-extra-data-loaded', JSON.parse(JSON.stringify(response.data['isReferenceParcelInfo'])));
            }

            if(this.countEndpoint === '') {
              this.totalRecords = parseInt(response.data.total_records);
            }
            this.prepareRecordUids();
          } else {
            this.$api.showToast(response.data.message, 'error');
          }
        })
      } else {
        this.gridLoading = false;
        this.gridData = this.localData;
        this.totalRecords = this.localData.length;
        this.filterLocalData();
        this.sortLocalData();
        this.prepareRecordUids();
      }
    },
    sortLocalData() {
      if (this.sortAttr && this.totalRecords > 0) {
        if (this.sortOrder === -1) {
          this.gridData = _orderBy(this.gridData, this.sortAttr, 'desc')
        } else {
          this.gridData = _orderBy(this.gridData, this.sortAttr, 'asc')
        }
      }
    },
    prepareRecordUids() {
      this.recordUids = [];
      if(this.navigationDataKey) {
        let loopCount = this.gridData.length;
        if(loopCount > 0) {
          for(let k=0; k<loopCount; k++) {
            if(this.gridData[k][this.navigationDataKey]) {
              this.recordUids.push(this.gridData[k][this.navigationDataKey]);
            }
          }
        }
      }
      this.saveGridNavRecords({
        "gridId": this.gridId,
        "recordUids": this.recordUids
      })
    },
    filterLocalData() {
      let index = this.$store.state.grid.gridState.findIndex(o => o.gridId === this.gridId);
      let gridState = index >= 0 ? this.$store.state.grid.gridState[index] : {};
      if (gridState.filter.txt && this.localFilterAttrs.length > 0) {
        this.gridData = this.gridData.filter((o) => {
          let found = false;
          for (let k = 0; k < this.localFilterAttrs.length; k++) {
            if (o[this.localFilterAttrs[k]] && o[this.localFilterAttrs[k]].indexOf(gridState.filter.txt) > -1) {
              found = true;
            }
          }

          if (found) {
            return true;
          }
        })
      }
    },
    changePage(e) {
      if (!this.useLocalData) {
        if (this.gridParams.rows !== e.rows) {
          this.triggerStateSave = true;
        }
        if ((this.gridParams.first !== e.first || this.gridParams.rows !== e.rows) && parseInt(e.first) < this.totalRecords) {
          this.gridParams.first = e.first;
          this.gridParams.rows = e.rows;
          this.loadData();
        }
      }
    },
    scrollGrid(e) {
      if (!this.useLocalData) {
        if ((this.gridParams.first !== e.first || this.gridParams.rows !== e.rows) && parseInt(e.first) < this.totalRecords) {
          this.gridParams.first = e.first;
          this.gridParams.rows = e.rows;
          this.loadData();
        }
      }
    },
    sortGrid(e) {
      if (!this.useLocalData) {
        this.gridParams.first = e.first;
        this.gridParams.rows = e.rows;
        this.gridParams.sortField = e.sortField;
        this.gridParams.sortOrder = e.sortOrder;
        if(this.stateFul) {
          this.saveGridState({
            "persistent": true,
            "gridId": this.gridId,
            "sortAttr": e.sortField,
            "sortOrder": e.sortOrder
          });
          this.triggerStateSave = true;
        }
        this.loadData();
      }
    },
    colStateHandler() {
      this.triggerStateSave = true;
    },
    cellEditCompleted(e) {
      let column = this.columns.filter((o) => o.field === e.field);
      if(column.length > 0) {
        let newValue = e.data[(column[0].model ? column[0].model : column[0].field) + 'Changed'];
        let oldValue = e.data[column[0].model ? column[0].model : column[0].field]
        if(oldValue !== newValue) {
          this.$emit('cell-edit-completed', {
            data: e.data,
            field: column[0].model ? column[0].model : column[0].field,
            newValue: newValue
          });
        }
      }
    },
    stateSave(e) {
      if (this.triggerStateSave) {
        this.triggerStateSave = false;
        this.saveGridState({
          "persistent": true,
          "gridId": this.gridId,
          "dt_state": e
        });
      }
    },
    selectColumns() {
      this.isSelectColDialogVisible = true
    },
    closeSelectColDialog() {
      this.isSelectColDialogVisible = false
    },
    addChangesInDetail(slotProps) {
      this.isChangesInDetail = true;

      this.slotPropsChangesInDetail = slotProps;
      this.propertyData = this.slotPropsChangesInDetail.data;
      this.property_uid = this.slotPropsChangesInDetail.data.prim_uid;
      this.lastDeclarationUid = parseInt(this.slotPropsChangesInDetail.data.declaration_id_of_the_last_declaration);
      this.selectModalChangesData.oldDeclarationTitleOptions = [];

      this.slotPropsChangesInDetail.data.declarationSubmissions = [];
      this.service.getSubmissions(this.property_uid).then((response) => {
        if (typeof response.success === 'undefined' || !response.success) {
          return;
        }

        this.slotPropsChangesInDetail.data.declarationSubmissions = response.data;

        this.slotPropsChangesInDetail.data.declarationSubmissions.forEach((submission, index) => {
          let detailSuffix = 'ELSTER Versand vom';
          let detailDate = submission.elsterSubmissionReceivedDate;
          if (detailDate === '') {
            detailSuffix = 'Stand vom';
            detailDate = submission.freezedPropertyCreatedAt;
          }

          this.selectModalChangesData.oldDeclarationTitleOptions.push({
            name: `${submission.type} zum ${submission.referenceDate} (${submission.declarationUid}) - ${detailSuffix} ${detailDate}`,
            code: index.toString(10)
          });
        });
      });

      this.initNextDeclarationTypeOptions(this.slotPropsChangesInDetail.data.federal_state_uid);

      this.isChangesInDetail = true;
    },
    async forceRender() {
      this.renderChangesInDetail = false;
      await this.$nextTick();
      this.renderChangesInDetail = true;
    },
    getChangeManagementChangesInDetail(data, index) {
      if (
        typeof data === 'undefined'
        || typeof index === 'undefined'
        || typeof (data[index]) === 'undefined'
      ) {
        return [];
      }

      if (typeof (data[index].changes) !== 'undefined') {
        this.lastIndexChangeManagementChangesArray = Object.keys(this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes']).length - 1;
        return this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes'];
      }

      if (
        typeof (this.property_uid) === 'undefined'
        || typeof (data[index].freezedPropertyId) === 'undefined'
      ) {
        return [];
      }

      this.service.getChanges(this.property_uid, data[index].freezedPropertyId).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes'] = response.data;
        } else {
          this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes'] = {};
        }

        this.lastIndexChangeManagementChangesArray = Object.keys(this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes']).length - 1;

        this.forceRender();

        return this.slotPropsChangesInDetail.data.declarationSubmissions[index]['changes'];
      });
    },
    saveColumnFilter() {
      this.renderTable = false;
      this.selectedColumns = this.columnDefs.filter(o => o.isChecked);
      this.$nextTick(() => {
        this.renderTable = true;
        this.reorderColumnsList();
        this.reloadGrid(true);
      });
      this.closeSelectColDialog();
      this.triggerStateSave = true;
    },
    reorderColumnsList() {
      let columnCount = this.columns.length;
      this.columnDefs = this.columns.map((o, index) => {
        o.listOrder = o.isChecked ? index : columnCount + index;
        return o;
      });
    },
    closeColumnFilter() {
      this.closeSelectColDialog()
    },
    rowSelect() {
      this.$emit('row-select', this.selectedRecords);
      this.$emit('row-selection', this.selectedRecords);
    },
    rowSelectAll() {
      this.$emit('row-select-all', this.selectedRecords);
      this.$emit('row-selection', this.selectedRecords);
    },
    rowUnselect() {
      this.$emit('row-unselect', this.selectedRecords);
      this.$emit('row-selection', this.selectedRecords);
    },
    rowUnselectAll() {
      this.$emit('row-unselect-all', this.selectedRecords);
      this.$emit('row-selection', this.selectedRecords);
    },
    handlePrependIconClick(field, record) {
      this.$emit('prepend-icon-click', {
        gridId: this.gridId,
        field: field,
        row: record
      });
    },
    handleIconClick(field, record, icon) {
      if(icon.clickable) {
        this.$emit('col-click', {
          gridId: this.gridId,
          field: field,
          row: record,
          iconKey: icon.key
        });
      }
    },
    handleColClick(field, record) {
      this.$emit('col-click', {
        gridId: this.gridId,
        field: field,
        row: record
      });
    },
    handleColCheck(field, record) {
      record[field] = !record[field];
      this.$emit('col-check', record);
    },
    handleColHeaderCheck(column) {
      column.headerCheckboxValue = !column.headerCheckboxValue;
      this.$emit('col-header-check', column);
    },
    checkColHeader(colFieldName, value) {
      if (typeof this.$refs !== 'undefined' &&
          typeof this.$refs['header-checkbox-' + colFieldName] !== 'undefined' &&
          typeof this.$refs['header-checkbox-' + colFieldName][0] !== 'undefined' &&
          typeof value === 'boolean') {
        let inputHeaderCheckboxComp = this.$refs['header-checkbox-' + colFieldName][0];
        inputHeaderCheckboxComp.checked = value;
        let myColumn = this.selectedColumns.find( o => o.field === colFieldName);
        if (typeof myColumn !== 'undefined') {
          myColumn.headerCheckboxValue = value;
        }
      }
    },
    actionMenu(event, menu, record) {
      this.actionMenuItems = [];
      if (menu.items) {
        this.actionMenuItems = menu.items.map(o => {
          o.icon += ' grid_action_icon';
          if (o.visibility) {
            o.visible = () => {
              return o.visibility(record);
            }
          } else {
            o.visible = true;
          }
          o.command = () => {
            this.handleActionMenuClick(o, record);
          }
          return o;
        });
        this.$refs.refActionMenu.toggle(event);
      } else {
        this.handleActionMenuClick(menu, record);
      }
    },
    handleActionMenuClick(menu, record) {
      if (menu && menu.id) {
        if (menu.action_endpoint) {
          let data = {
            record: record,
            menu: menu.id
          }

          if (menu.action_confirmation.show) {
            let params = {
              headerText: this.$t('general.grid.delete_record'),
              text: this.$t('general.grid.delete_confirm'),
              showClose: true,
              canEscape: true,
              buttons: {},
              onConfirm: () => {
                this.trigger_action(menu.action_endpoint, data, menu.callback);
              }
            };

            if (typeof (menu.action_confirmation.title) !== 'undefined') {
              params.headerText = menu.action_confirmation.title;
            }
            if (typeof (menu.action_confirmation.message) !== 'undefined') {
              params.text = menu.action_confirmation.message;
            }
            if (typeof (menu.action_confirmation.button_ok) !== 'undefined') {
              params.buttons.ok = menu.action_confirmation.button_ok;
            }
            if (typeof (menu.action_confirmation.button_cancel) !== 'undefined') {
              params.buttons.cancel = menu.action_confirmation.button_cancel;
            }

            this.$modal.show(params);
          } else {
            this.trigger_action(menu.action_endpoint, data, menu.callback);
          }
        } else {
          this.$emit('action-menu-click', {
            menu: menu,
            row: record
          });
        }
      }
    },
    trigger_action(endpoint, data, callback) {
      return this.gridService.trigger(endpoint, data, true).then(response => {
        if (response.data.success) {
          if (typeof callback === "function") {
            callback();
          } else if (response.data.message) {
            this.$api.showToast(response.data.message, 'success');
          }
        } else {
          if (!response.data.message && response.data.error_key){
            this.$api.showToast(this.$t(`general.errors.${response.data.error_key}`), 'error');
          }
          this.$api.showToast(response.data.message, 'error');
        }

        this.triggerFetchCount(this.gridId);
        this.reloadGrid(true);

        return response;
      });
    },
    getVersionChanges(data) {
      return this.gridService.parse_object_data(data);
    },
    getChangeManagementChanges(data, index) {
      if (typeof data !== 'undefined' && typeof (data[index]) !== 'undefined') {
        this.lastIndexChangeManagementChangesArray = Object.keys(data[index]).length - 1 ;
        return this.gridService.parse_object_data(data[index]);
      }

      return [];
    },
    menuVisible(menu, record) {
      if (menu.visibility) {
        return menu.visibility(record);
      }

      return true;
    },
    colTooltip(col, data) {
      if (typeof col.tooltip === 'function') {
        return col.tooltip(data);
      }

      return '';
    },
    colClickable(col, data, prependIcon) {
      if(prependIcon) {
        if(typeof col.prependIconClickable === 'function') {
          return col.prependIconClickable(data);
        }

        return col.prependIconClickable;
      }

      if(typeof col.clickable === 'function') {
        return col.clickable(data);
      }

      return col.clickable;
    },
    getDataSource(col, data) {
      if (data.dataSources && data.dataSources[col.field]) {
        return data.dataSources[col.field];
      } else if (col.dataSource) {
        if (typeof col.dataSource === 'function') {
          return col.dataSource(data);
        }

        return col.dataSource;
      } else {
        return  [] ;
      }
    },
    isColElDisabled(col, data) {
      if (col.disableFn) {
        return col.disableFn(data);
      }
      return this.isReadOnly;
    },
    createNew() {
      this.$emit('create-new');
    },
    goToClients()
    {
      this.$router.push('/clients/list');
    },
    loadGridData(visible) {
      if (visible && !this.autoLoad) {
        this.loadData();
      }
    },
    handleItemSelected(theItem, col, row) {
      this.$emit('on-item-selected', {
        item: theItem,
        col: col,
        row: row
      })
    },
    initNextDeclarationTypeOptions(federalStateUid) {
      if (parseInt(federalStateUid) == 6 || parseInt(federalStateUid) == 2 || parseInt(federalStateUid) == 9) {
        //types_bayern
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_bayern');
      } else if (parseInt(federalStateUid) == 1) {
        //types_baden
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_baden');
      } else if (parseInt(federalStateUid) == 7) {
        //types_hessen
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_hessen');
      } else {
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_all');
      }

      let attributionPerpetuationExists = false;
      this.nextDeclarationTypeOptions.map(o => {
        if (o.code === 'zurechnungsfortschreibung') {
          attributionPerpetuationExists = true;
        }
      });

      if (!attributionPerpetuationExists) {
        this.nextDeclarationTypeOptions.push({
          name: 'Zurechnungsfortschreibung',
          code: 'zurechnungsfortschreibung'
        });
      }
    },
    selectNextDeclarationTypeOpt(item) {
      if (item.code === "zurechnungsfortschreibung") {
        this.nextDeclarationTypeModalTitle = this.$t('properties.relevant.header-3');
        this.nextDeclarationTypeModalDescription = this.$t('properties.relevant.desc-3');
        this.nextDeclarationTypeModalButtonText = this.$t('properties.relevant.button-3');
      } else {
        this.nextDeclarationTypeModalTitle = this.$t('properties.relevant.header-1');
        this.nextDeclarationTypeModalDescription = this.$t('properties.relevant.desc-1');
        this.nextDeclarationTypeModalButtonText = this.$t('buttons.save');
      }
    },
    showNextDeclarationTypeDialog() {
      this.nextDeclarationTypeDialog = true;
    },
    showCancelNextDeclarationTypeDialog() {
      this.cancelNextDeclarationTypeDialog = true;
    },
    selectNextDeclarationType() {
      if (this.nextDeclarationType === 'zurechnungsfortschreibung') {
        if (this.lastDeclarationUid > 0) {
          this.$router.push('/declaration/edit?uid=' + this.lastDeclarationUid + '&redirect=attribution_perpetuation&property_uid=' + this.property_uid);
        }
      } else {
        this.renderTable = false;
        this.service.selectNextDeclarationType(this.property_uid, this.nextDeclarationType).then((response) => {
          if (typeof response.success !== 'undefined' && response.success) {
            this.nextDeclarationTypeDialog = false;
            this.isChangesInDetail = false;

            this.propertyData.declarationRelevantMarkedByUser = 1;
            this.propertyData.nextDeclarationType = this.nextDeclarationType;
            this.propertyData.lastCheckedForChanges = new Date().getTime();

            this.$api.showToast(this.$t('properties.relevant.info-1'));
            this.$nextTick(() => {
              this.renderTable = true;
              this.reorderColumnsList();
              this.reloadGrid(true);
            });
          }
        });
        this.$nextTick(() => {
          this.renderTable = true;
          this.reorderColumnsList();
          this.reloadGrid(true);
        });
      }
    },

    cancelNextDeclarationType() {
      this.renderTable = false;
      this.service.cancelNextDeclarationType(this.property_uid).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.cancelNextDeclarationTypeDialog = false;
          this.nextDeclarationTypeDialog = false;
          this.isChangesInDetail = false;

          this.propertyData.declarationRelevantMarkedByUser = 0;
          this.propertyData.nextDeclarationType = '0';
          this.nextDeclarationType = '0';
          this.propertyData.nextDeclarationReferenceDate = '';
          this.nextDeclarationReferenceDate = '';
          this.propertyData.lastCheckedForChanges = new Date().getTime();

          this.$api.showToast(this.$t('properties.relevant.info-2'));
          this.$nextTick(() => {
            this.renderTable = true;
            this.reorderColumnsList();
            this.reloadGrid(true);
          });
        }
      });
      this.$nextTick(() => {
        this.renderTable = true;
        this.reorderColumnsList();
        this.reloadGrid(true);
      });
    },
    handleCommunityClose({communityId, declarationId}) {
      this.$emit(
        'on-community-save',
        {
          communityId: communityId,
          declarationId: declarationId,
          save: this.communitySaveChecked,
        }
      );

      this.showCommunitySelect = false;
    },
    handleCommunityClick(federalStateId, declarationId, context) {
      this.communitySelectorFederalStateUid = federalStateId;
      this.communitySelectorDeclarationUid = declarationId;
      this.communitySelectorContext = context;
      this.showCommunitySelect = true;
    },
    handleCommunityCheck(value) {
      this.communitySaveChecked = value;
    },
  }
}
</script>

<style lang="scss">
.checkHeader {
  min-width: 25px;
  width: 25px;
  padding-left: 0 !important;
}

.p-datatable-wrapper th:not(.checkHeader) {
  justify-content: center !important;
}

.p-datatable-wrapper td:not(:first-child) {
  text-align: center !important;
}

.default-user-checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  border: 2px solid #7a7a7a;
  border-radius: 0px;
  background-color: rgb(34, 157, 86);
}

.default-user-checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.color-green-dashboard {
  color: $green-dashboard;
}

</style>
<style scoped lang="scss">
.vertical-dots {
  height: 18px;
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.column_icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  color: #333333;
}
.right_column_icon {
  margin-left: 5px;
}
.help_icon {
  width: 18px;
  height: 18px;
}

.table-container {
  height: calc(100vh - 255px);
  z-index: 10;

  ::v-deep {
    .p-paginator-current {
      position: absolute;
      right: 0;
      padding-right: 0;
      font-size: 13px;
    }

    .p-paginator {
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;

      .p-dropdown {
        width: auto;
        height: 30px;
      }
    }

    .p-cell-editing {
      padding: 0 !important;
    }

    .p-paginator-element {
      width: 30px !important;
      height: 30px !important;
      min-width: 10px !important;
      font-size: 13px !important;
    }

    .p-paginator-icon, .p-dropdown-trigger-icon, .p-dropdown-label, .p-dropdown-item {
      font-size: 13px;
    }

    .p-dropdown-label {
      padding: 5px !important;
    }

    .p-dropdown-trigger {
      width: 25px;
    }
  }
}


::-webkit-scrollbar {
  width: 1.4vw;
  height: 3.3vh;
}

::-webkit-scrollbar-track {
  //background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $gray;
  background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
  );
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ec8f02;
}

.label {
  @include segoe-regular;
}

.cell_with_action {
  .cell-content {
    width: 90%
  }
}

.cell_with_icon {
  .cell-content {
    width: 75%
  }
}

.cell-content {
  min-height: 25px;
}

.versionList {
  table-layout: auto;
  width: 100%;

  td {
    text-align: left;
    padding: 0.75rem 0.75rem;
  }

  td.attr {
    width: 20vw;
  }

  td.event_lbl {
    font-weight: bold;
  }

  td.action {
    width: 20px;
    padding-left: 10px;
  }
}

.changesList {
  table-layout: auto;
  width: 100%;

  td {
    text-align: left;
    padding: 0.75rem 0.75rem 0.0rem 0.75rem;
  }

  td.attr {
    width: 20%
  }

  td.event_lbl {
    font-weight: bold;
  }

  td.action {
    width: 20px;
    padding-left: 10px;
  }
}

.border-right-left-gray{
  border-right: 1px solid rgb(211,211,211);
  border-left: 1px solid rgb(211,211,211);
}

.border-right-gray{
  border-right: 1px solid rgb(211,211,211);
}

.border-left-gray{
  border-left: 1px solid rgb(211,211,211);
}

.line-height-1-5{
  line-height: 1.5;
}

.span-old{
  color: rgb(205, 75, 91);
  display: block;
}

.span-new{
  color: rgb(34, 157, 86);
  display: block;
}

.border-top-gray{
  border-top: 1px solid rgb(211,211,211);
}

.border-bottom-gray{
  border-bottom: 1px solid rgb(211,211,211);
}

.p-menu.p-menu-overlay {
  min-width: 200px;
  width: auto !important;

  .p-menuitem-text {
    white-space: nowrap;
  }
}

a {
  color: theme('colors.muted_black');
}

.compact-grid-view {
  ::v-deep {
    .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th {
      padding: 0.35rem 0.75rem
    }

    .p-datatable .p-datatable-tbody td div.grid_action {
      padding-top: 7px !important;
    }

    .p-datatable .p-datatable-tbody .p-checkbox, .p-datatable .p-datatable-thead .p-checkbox {
      width: 18px;
      height: 18px;
      margin-top: 2px;
    }
  }
}

.empty {
  width: 70px;
}

.empty-text {
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 25px;
}

.label-text-bold {
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: bold;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.empty-subtext {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  font-size: 15px;
}

.select-columns-pnl {
  height: calc(100vh - 160px);
}

.prepend_icon {
  width: fit-content;
  float: left;
  padding-right: 10px;

  img {
    width: 18px;
    height: 20px;
  }
}
.cell-el-content {
  min-height: 25px;

  label.center-aligned {
    width: auto;
  }
}
.multi_icons {
  img {
    width: 18px;
    height: 20px;
    float: left;
    margin-right: 10px;
  }
}

::v-deep {
  .clickable-datev_transfered_at span {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
  }
}

.document-grid .table-container {
  height: calc(100vh - 410px) !important;
}

.pl-30 {
  padding-left: 30px;
}

.allow-wrap {
  white-space: normal !important;
}

.document-review-dialog ::v-deep .modal-x-large {
  width: 95% !important;
  max-width: 95% !important;
  max-height: calc(100vh - 20px) !important;
}

.table-title {
  border-bottom-color: rgb(34, 157, 86);
  border-bottom-width: 3px;
  font-family: "Segoe UI", "Segoe UI Regular", "Segoe UI Bold", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 15px;

  .invisible {
    border-bottom-color: transparent;
  }
}
</style>
