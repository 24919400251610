import {ApiService} from "./api.service";

export class DashboardService extends ApiService {

  constructor() {
    super();
  }

  // List all account setup notification
  listAll() {
    return this.trigger('dashboard/list_todos', {
      'sortField': 'name',
      'sortOrder': 1
    }).then((response) => {
      if (response.data.success && parseInt(response.data.total_records) > 0) {
        return response.data.records;
      } else {
        return [];
      }
    })
  }
  
  saveLayout(data) {
    return this.trigger('dashboard/save_layouts', data).then(response => {
      if (response.data.success) {
        let layoutList = this.parse_object_data(response.data.records).map(o => {
          return o;
        })
        this.$store.commit("user/setLayoutList", layoutList)
        return true;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }
  
  getLayouts() {
    if (this.$store.getters["user/getLayoutList"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["user/getLayoutList"]);
      });
    } else {
      return this.trigger('dashboard/get_layouts').then((response) => {
        if (response.data.success && response.data.records) {
          let layoutList = this.parse_object_data(response.data.records).map(o => {
            return o;
          })
          
          this.$store.commit("user/setLayoutList", layoutList)
          return layoutList;
        } else {
          return [];
        }
      });
    }
  }
  
  cleanDashboard() {
    return this.trigger('dashboard/clean_dashboard', null).then((response) => {
      this.$store.commit("user/setLayoutList", []);
      return response;
    })
  }
}
