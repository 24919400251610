<template>
  <div >
    <draft :title="$t('declarations.stages.assessment_received')"
           :server-params="serverParams"
           :breadcrumbs="breadcrumbs"
           :menus="menus"
           :columns="getColumns"
           :grid-id="gridId"
    />
  </div>
</template>

<script>
import Draft from "@/components/declarations/Draft";
import {mapMutations} from "vuex";

export default {
  name: "notificationreceived",
  components: {Draft},
  data() {
    return {
      gridId: 'assessment_received_declarations',
      serverParams: {
        'filters': {
          'status': "assessment_received"
        }
      },
      breadcrumbs: [
        {
          title: this.$t('declarations.draft.breadcrumbs.link1'),
          link: '/declaration/declarations'
        }, {
          title: this.$t('declarations.stages.assessment_received')
        }
      ],
      menus: [
        {
          id: "dec.add",
          "icon": this.getAssetPath('add_declaration_green.svg'),
          "title": this.$t('declarations.menus.add')
        }, {
          id: "dec.edit",
          "icon": this.getAssetPath('edit_declaration_green.svg'),
          "icon_disabled": this.getAssetPath('edit-declaration-gray.svg'),
          "title": this.$t('declarations.menus.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('declarations.menus.delete'),
          "action_endpoint": 'declaration/delete',
          "action_confirmation": {
            show: true,
          },
          "callback": () => {
            this.setCurrentUserStatsDataChanged(true);
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.archive",
          "icon": this.getAssetPath('archive_green.svg'),
          "icon_disabled": this.getAssetPath('archive-gray.svg'),
          "title": this.$t('declarations.menus.archive'),
          "action_endpoint": 'declaration/archive',
          "action_confirmation": {
            show: true,
            single: {
              "title": this.$t('general.grid.archive_record'),
              "message": this.$t('general.grid.archive_confirm')
            },
            multiple: {
              "title": this.$t('general.grid.archive_record'),
              "message": this.$t('general.grid.archive_confirm')
            },
            buttons: {
              ok: this.$t('general.grid.archive_record')
            }
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.export",
          "icon": this.getAssetPath('export.svg'),
          "title": this.$t('declarations.menus.export')
        }, {
          id: "dec.bulk_processing",
          "icon": this.getAssetPath('transfer.svg'),
          "icon_disabled": this.getAssetPath('transfer-gray.svg'),
          "title": this.$t('declarations.menus.bulk_transfer'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true,
          "canShow": false,
          "sub_menu": {
            "items": [
              {
                id: 'dec.mass_update.document_creation',
                icon: 'dec_document_creation',
                label: this.$t('declarations.mass_update.process.document_creation')
              },
              {
                id: 'dec.mass_update.mark_mass_appeal',
                icon: 'dec_mass_appeal_icon',
                label: this.$t('declarations.menus.mark_mass_appeal')
              }
            ]
          }
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      defaultColumns: [
        {
          field: 'client_uid',
          header: this.$t('clients.attrs.id'),
          isChecked: false,
          clickable: true,
          width: 180
        }, {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        }, {
          field: 'client',
          header: this.$t('clients.attrs.name'),
          filterLbl: this.$t('clients.attrs.name') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          filterLbl: this.$t('declarations.grid.address') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          isChecked: true,
          clickable: true,
          elType: 'user_select'
        }, {
          field: 'appeal_deadline',
          header: this.$t('declarations.detail.tab1.appeal_deadline'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          isChecked: false,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.type_options.filter_types')
        }, {
          field: 'reference_date',
          header: this.$t('declarations.grid.reference_date'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'fee_invoice_sent',
          header: this.$t('declarations.grid.fee_invoice_sent'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.grid.fee_invoice_sent_options'),
          clickable: true,
        }, {
          field: 'branch_name',
          header: this.$t('declarations.grid.branch'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'complexity',
          header: this.$t('declarations.grid.complexity'),
          isChecked: false,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options')
        }, {
          field: 'internal_note',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_property',
          header: this.$t('declarations.grid.internal_note_property'),
          filterLbl: this.$t('declarations.grid.internal_note_property') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_client',
          header: this.$t('declarations.grid.internal_note_client'),
          filterLbl: this.$t('declarations.grid.internal_note_client') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'extra_details',
          header: this.$t('declarations.grid.extra_details'),
          filterLbl: this.$t('declarations.grid.extra_details') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'created_at',
          header: this.$t('declarations.grid.created_at'),
          isChecked: false,
          clickable: true,
          sortable: false
        }, {
          field: 'internal_approval_requested_at',
          header: this.$t('declarations.grid.approval_requested_at'),
          clickable: true,
          isChecked: false
        }, {
          field: 'tc_approval_at',
          header: this.$t('declarations.grid.tc_approval_at'),
          clickable: true,
          isChecked: false
        }, {
          field: 'elster_transferred_at',
          header: this.$t('declarations.grid.elster_transferred_at'),
          clickable: true,
          isChecked: false
        }, {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between'
        }
      ]
    }
  },
  computed: {
    getColumns() {
      let columns = [...this.defaultColumns];

      if (!this.$isBusiness) {
        columns.push({
          field: 'client_approval_requested_at',
          header: this.$t('declarations.grid.client_approval_requested_at'),
          clickable: true,
          isChecked: false
        });

        columns.push({
          field: 'client_approval_at',
          header: this.$t('declarations.grid.client_approval_at'),
          clickable: true,
          isChecked: false
        });
      }

      columns = columns.map((o) => {
        if (o.field === 'reference_date') {
          let declarationReferenceDateOptions = [];
          for (var i = 2022; i <= 2050; i++) {
            declarationReferenceDateOptions.push({
              'name': i,
              'code': i
            });
          }

          o.filterOptions = declarationReferenceDateOptions;
        }

        return o;
      });

      return columns;
    }
  },
  methods: {
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
  }
}
</script>
