<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <InputSelect class="mt-2 w-full" :isError="errors.assignedUser.invalid"
                     :errorMessage="$t('general.errors.required')"
                     :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
                     :options="userList"
                     v-model="step2.assignedUser"
                     :selectedValue="step2.assignedUser"
                     :filterable="true"
                     @on-item-selected="fetchClient"
                     :isRequired="true" :placeholder="$t('general.please_select')"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-around space-x-1 mt-1">
        <InputSelect class="mt-2 w-full" :isError="errors.clientId.invalid" :errorMessage="$t('general.errors.required')"
                     :label="$t('declarations.add_declaration.steps.step1.client')"
                     v-model="step2.clientId"
                     :options="clientList"
                     :selectedValue="step2.clientId"
                     :loading="loadingClient"
                     :search-fn="clientListSearch"
                     :total-record-count="totalClientsCount"
                     :filterable="true"
                     :sort="true"
                     :placeholder="$t('general.client_select')"
                     @on-item-selected="fetchProperties"
                     :isRequired="true"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-around space-x-1 mt-5" v-show="showProperty">
        <DualListSelect
            :list="propertyList"
            v-model="step2.selectedProperties"
            ref="propertyDualList"
            :columns="propertyDualListColumns"
            dataKey="prim_uid"
            sort-attr="name"
            :enable-all-select="true"
            :leftHeader="$t('declarations.mass_update.all_properties')"
            :rightHeader="$t('declarations.mass_update.selected_properties')"
            :countShow="true"
        />
      </div>
    </div>
    <div class="flex justify-end  mt-6 space-x-4 mr-6 mb-8">
      <Button class="w-56 mb-8" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
      <Button class="w-56 mb-8" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {ClientService} from "@/services/client.service";
import {PropertyService} from "@/services/property.service";
import DualListSelect from "@/components/labels/DualListSelect";
import {UserService} from "@/services/user.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step2",
  components: {DualListSelect},
  data() {
    return {
      clientService: new ClientService(),
      propertyService: new PropertyService(),
      userService: new UserService(),
      branchService: new BranchService(),
      loadingClient: false,
      loadingProperties: false,
      userList: [],
      clientList: [],
      totalClientsCount: 0,
      propertyList: [],
      propertyDualListColumns: [
        {
          field: 'property_id',
          label: this.$t('components.properties_table_col.col1'),
          search: true
        }, {
          field: 'name',
          label: this.$t('properties.step2.name'),
          search: true
        }, {
          field: 'last_declaration_type',
          label: this.$t('components.properties_table_col.col30'),
          search: true
        }
      ],
      step2: {
        assignedUser: '0',
        assignedUserName: '',
        clientId: '0',
        clientName: '',
        powerOfAttorneyFromClient: '0',
        powerOfAttorneyFromBranch: '0',
        branchId: '0',
        selectedProperties: []
      },
      errors: {
        "assignedUser": {
          invalid: false
        },
        "clientId": {
          invalid: false
        }
      },
    }
  },
  computed: {
    showProperty() {
      return this.step2.clientId && parseInt(this.step2.clientId) > 0
    }
  },
  mounted() {
    this.userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            "name": item.name,
            "code": item.id
          });
        }

        return filtered;
      }, []);

      this.step2.assignedUser = this.getCurrentUser().id;
      this.step2.assignedUserName = this.getCurrentUser().name;
      this.fetchClient();
    });
  },
  methods: {
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapGetters("user", ["getCurrentUser"]),
    fetchClient(e) {
      if(e && e.name) {
        this.step2.assignedUserName = e.name;
      }
      this.loadingClient = true;
      this.clientService.listUserClient([this.step2.assignedUser, 'current'].join(",")).then((clients) => {
        if(clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.clientList = clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid,
              "power_of_attorney": list.power_of_attorney,
              "branch_id": list.branch_id
            }
          });
          this.loadingClient = false;
        }
      })
    },
    clientListSearch(params) {
      return this.clientService.listUserClient([this.step2.assignedUser, 'current'].join(","), params).then((clients) => {
        if(clients && clients.list) {
          return clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid,
              "power_of_attorney": list.power_of_attorney,
              "branch_id": list.branch_id
            };
          })
        } else {
          return false;
        }
      });
    },
    fetchProperties(e) {
      this.step2.clientName = e.name ? e.name : '';
      this.step2.powerOfAttorneyFromClient = e.power_of_attorney ? e.power_of_attorney : '0';
      this.step2.branchId = e.branch_id ? e.branch_id : '0';
      this.step2.selectedProperties = [];
      this.$refs.propertyDualList.showloader = true;
      this.propertyService.listClientProperties(this.step2.clientId).then((property) => {
        if(property && property.list) {
          let withoutDeclarationStatusOption = this.$t('properties.property_declaration_status_options').find(o => o.code === 'without_declaration');
          this.propertyList = property.list.map((list) => {
            return {
              "property_id": list.property_id,
              "name": list.name,
              "declarationNumber": list.reference,
              "prim_uid": list.prim_uid,
              "federalStateUid": parseInt(list.federal_state_uid),
              "declarationRelevantMarkedByUser": list.declarationRelevantMarkedByUser,
              "nextDeclarationType": list.nextDeclarationType,
              "nextDeclarationReferenceDate": list.nextDeclarationReferenceDate,
              "last_declaration_type": list.last_declaration_type !== '' ? list.last_declaration_type : withoutDeclarationStatusOption.name
            }
          });
        }
      });
    },
    goNext() {
      if (parseInt(this.step2.branchId) > 0) {
        this.branchService.get(this.step2.branchId).then((branch) => {
          if (branch.power_of_attorney && parseInt(branch.power_of_attorney) > 0) {
            this.step2.powerOfAttorneyFromBranch = branch.power_of_attorney;
          }
          this.saveSteps(this.step2);
          this.$emit("goToNextStep")
        });
      }
    },
    submitNext() {
      if(this.step2.selectedProperties.length > 0) {
        let hasDeclaration = false;
        let withoutDeclarationStatusOption = this.$t('properties.property_declaration_status_options').find(o => o.code === 'without_declaration');
        this.step2.selectedProperties.map((o) => {
          if (typeof o.last_declaration_type !== 'undefined' && o.last_declaration_type !== withoutDeclarationStatusOption.name) {
            hasDeclaration = true;
          }
        });

        if (hasDeclaration) {
          this.$modal.show({
            headerText: this.$t('properties.new_declaration_confirm.title'),
            text: this.$t('properties.new_declaration_confirm.message'),
            showClose: true,
            canEscape: true,
            buttons: {
              ok: this.$t('properties.new_declaration_confirm.button_1'),
              cancel: this.$t('properties.new_declaration_confirm.button_2'),
              delete: false
            },
            onConfirm: () => {
              this.goNext();
              this.$modal.visible = false;
            }
          });
        } else {
          this.goNext();
        }
      }
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
</style>
