import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _merge from 'lodash/merge'

Vue.use(VueI18n);
function loadI18() {
    let locales;
    locales = require.context(
        '../locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    
    // For business, merge locales
    if(process.env.VUE_APP_DEV_VARIANT === 'business') {
        locales = require.context(
            '../locales/business',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i
        );
        locales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                
                messages[locale] = _merge(messages[locale], locales(key));
            }
        });
    }
    
    return messages;
}

export const i18n = new VueI18n({
    locale: process.env.VUE_APP_DEFAULT_LANGUAGE, // set locales
    fallbackLocale: 'en', // set fallback locales
    messages: loadI18() // set locales messages
});

