const state = {
    planId: ''
};

const mutations = {
    setPlanDetail: (state, plan) => {
        state.planId = plan;
    }
};
const getters = {
    getPlanDetail: (state) => state.plan,
}

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
