<template>
  <div class="flex w-full">
    <label class="container" :class="containerClass">

      <label :class="customClasses" class="cursor-pointer"
             @click="clickCheckLabel($event, text , Routetype)" v-html="text"></label>
      <span v-show="isRequired" style="color:red; margin-left: 5px;">*</span>

      <input ref="checkbox" @click="check($event)" type="checkbox" :checked="checked" :disabled="isDisabled">
      <span class="checkmark" :class="isError ? 'error' : ''"></span>
    </label>
    <div class="flex">
      <img v-if="showHelpIcon" class="help_icon inline" :src="getAssetPath('help_green.svg')"
           v-tooltip.right="helpText"/>
      <VDropdown v-if="showHelpIconPopover" class="help_icon inline" :showTriggers="['click','hover']"
                 :popperTriggers="['hover','click']"
                 :hideTriggers="['click']">
        <!-- This will be the popover target (for the events and position) -->
        <img class="help_icon inline self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <!-- This will be the content of the popover -->
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text" v-for="text in popoverTexts" v-html="text" v-bind:key="text">
              </p>
            </div>
            <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text" :href="popoverLink"
                                                             target="_blank">{{ popoverLink }}</a></h2>
          </div>
          <!-- You can put other components too -->
        </template>
      </VDropdown>
    </div>
  </div>

</template>

<script>
import Tooltip from 'primevue/tooltip';

export default {
  name: "InputCheck",
  directives: {
    'tooltip': Tooltip,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    isstrike:{
        type: Boolean,
        default: false
    },
    Routetype: {
      type: String,
      default: ""
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    enableDisabledChecked: {
      type: Boolean,
      default: false
    },
    strikethrough: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: true
    },
    dataTableItem: {
      type: Object,
      default: () => {
      }
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    showHelpIconPopover: {
      type: Boolean,
      default: false
    },
    popoverTexts: {
      type: Array,
      default: () => []
    },
    popoverLink: {
      type: String,
      default: ""
    },
    helpText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      removeStrike: false,
      checked: false,
      disabled: false,
      strike:false,
    }
  },
  computed: {
    customClasses() {
        if (this.isstrike) {
            return "checked";
        }
      return [{"checked": this.strikethrough}, {"unChecked": this.removeStrike}]

    },

    containerClass() {
      return [{"disabled-checkbox": this.isDisabled}]
    }
  },
  mounted() {
    this.checked = this.isChecked;
    if (this.isDisabled && !this.enableDisabledChecked) {
      this.checked = false;
    }
    this.strike = this.isstrike;
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
    isChecked(newValue) {
      this.checked = newValue;
    }
  },
  methods: {
    check(value) {
      if (this.checkable) {
        this.checked = !this.checked;
        if (this.strikethrough) {
          this.removeStrike = value.target.checked === false;
        }
        if (this.dataTableItem !== undefined) {
          this.dataTableItem.isChecked = !this.dataTableItem.isChecked
          this.$emit('check', this.dataTableItem)
        } else {
          this.$emit('check', this.checked)
          this.$emit('change')
        }
      } else {
        value.preventDefault();
        value.stopPropagation();
        this.$emit('clickNonCheckable')
      }
    },
    clickCheckLabel(event, text, Routetype) {
      if (!this.isDisabled) {
        this.$emit('click', text, Routetype);
        this.check(event);
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 28px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
  width: auto;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: auto;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  //background-color: #eee;
  border:1px solid #d1d1d1;
  border-radius: 2px;
}

.checkmark.error {
  border: 1px solid #d13438;
}

.cell-content .checkmark {
  left: 40px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  //background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $primary;
  border:transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.checked {
  color: black;
  text-decoration: line-through
}

.unChecked {
  color: theme('colors.muted_black');
  text-decoration: none;
}

.disabled-checkbox {
  label {
    color: #AEAEAEFF;
  }

  .checkmark {
    border-color: #E8E8E8 !important;
    background-color: #E8E8E8 !important;
  }
  .checkmark:after {
    border-color: #000000 !important;
  }

  .cursor-pointer, input, .checkmark {
    cursor: default;
  }
}
.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
</style>
