<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('properties.title')" :breadcrumbs="breadcrumbs"/>
    <ModalDialog v-show="showEmailPreview" :headerText="$t('properties.client_invite.email_preview')" @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-8 space-y-4 mb-5">
          <div class="flex justify-around space-x-6">
            <label class="w-1/5 text-left">{{ $t('properties.client_invite.from') }}:</label>
            <label class="w-full text-left">{{ emailPreview.from }}</label>
          </div>
          <div class="flex justify-around space-x-6 mb-4">
            <label class="w-1/5 text-left">{{ $t('properties.client_invite.to') }}:</label>
            <label class="w-full text-left">{{ emailPreview.to }}</label>
          </div>
          <div class="flex justify-around space-x-6 mb-4">
            <label class="w-1/5 text-left">{{ $t('properties.client_invite.subject') }}:</label>
            <label class="w-full text-left">{{ emailPreview.subject }}</label>
          </div>
          <div class="flex justify-around">
            <label class="w-full text-left">{{ $t('properties.client_invite.message') }}:</label>
          </div>
          <div class="flex justify-around space-x-6 mb-4">
            <div class="rect w-full text-left" v-html="emailPreview.message" />
          </div>
          <div class="flex mt-4">
            <div class="flex mt-10 justify-start -ml-4 w-full link">
              <IconLabel :icon="require('@/assets/clipboard.svg')" @click="copyMessage" :blackText="true" :label="$t('properties.client_invite.copy')"/>
            </div>
            <div class="flex mt-10 justify-end w-full">
              <Button class="w-44" @click="handleModalClose" :text="$t('buttons.cancel')" :secondary="true"></Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <div class="w-full flex flex-col pr-7 mt-2" v-if="!finish">
      <p class="header1 mt-4">{{ multiClient ? $t('properties.client_invite.multi_title') : $t('properties.client_invite.title') }}</p>
      <div class="flex" v-show="!multiClient"><label class=" link" @click="toggleMultiClient">{{ $t('properties.client_invite.multi_client') }}</label></div>
      <div class="flex justify-around mt-2 space-x-10" v-show="!multiClient">
        <InputSelect class="w-full" :label="$t('properties.client_invite.client')" :isRequired=true :options="list"
                     :filterable="true"
                     :placeholder="$t('general.client_select')"
                     :search-fn="clientListSearch"
                     :total-record-count="totalClientsCount"
                     :sort="true"
                     @on-item-selected="handleSelected"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-around mt-2 space-x-10" v-show="multiClient">
        <DualListSelect :list="availableClients" v-model="selectedClients"
                        ref="clientDualSelect"
                        :columns="columns"
                        :totalRecordCount="totalClientsCount"
                        @filterList="clientDualListSearch"
                        dataKey="prim_uid"
                        sort-attr="client_name"
                        :validator="validateMultiClient"
                        @input="multiClientSelected"
                        :leftHeader="$t('settings.user_management.all_clients')"
                        :rightHeader="$t('settings.user_management.selected_clients')"
        >
          <template v-slot:left-footer>
            <div class="w-full mt-5">
              <Button class="w-40 float-left" :text="$t('general.all_select')" @click="selectAllClients()"/>
              <input-check class="float-right w-auto mt-1 mr-1" :is-checked="excludeInvitedClients" @check="excludeInvitedClientsChecked" :text="$t('properties.client_invite.not_requested_clients')" />
            </div>
          </template>
        </DualListSelect>
      </div>
      <div class="flex justify-around mt-2 space-x-10" v-if="blockVisible">
        <div class="w-full flex flex-col justify-start mt-2">
          <label class="text-left textbold " v-show="!multiClient">{{ $t('settings.user_management.attrs.email') }}: <span
              style="text-decoration: underline">{{ selectedClient.email }} </span></label>
          <div class="flex flex-col justify-start">
            <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                       :label="$t('settings.user_management.personal_message')" v-model="inviteEmailBody"
                       :isMultiline="true" :rows="20"/>
          </div>
          <InputCheck v-show="!multiClient" class="mt-4" :text="$t('properties.client_invite.use_email_app')" @check="emailCheck"/>
        </div>
        <div class="w-full invisible"></div>
      </div>

      <div class="flex justify-end space-x-4 mt-6 mb-6 ">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="$router.go(-1)"/>
        <Button class="w-40" :text="buttonText" @click="submitNext"/>
      </div>
    </div>
    <div class="flex flex-col w-full mr-7" v-else>
      <!-- finish -->
      <div class="w-full justify-center items-center">
        <div class=" w-full flex flex-col justify-center items-center ml-15 mr-15 mt-20">
          <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex    m-2">
            <img src="@/assets/check_done.svg"/>
          </div>
          <label class="text-finish ">{{ $t('properties.client_invite.added_successfully') }}</label>
          <Button @click="backToList" class=" mt-10 "
                  :text="$t('properties.client_invite.back_to_list')"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ClientService} from "@/services/client.service";
import {mapGetters} from "vuex";
import DualListSelect from "@/components/labels/DualListSelect";
import InputCheck from "@/components/inputs/InputCheck";

export default {
  name: "mandant",
  components: {InputCheck, DualListSelect},
  data() {
    return {
      service: new ClientService(),
      buttonText: this.$t('buttons.send'),
      finish: false,
      multiClient: false,
      list: [],
      totalClientsCount: 0,
      showEmailPreview: false,
      selectedClient: {},
      emailPreview: {
        "to": "",
        "from": "",
        "subject": "",
        "message": ""
      },
      inviteEmailBody: '',
      blockVisible: false,
      useEmailApp: false,
      breadcrumbs: [
        {
          title: this.$t('breadcrumbs.land_units'),
          link: ''
        }, {
          title: this.$t('breadcrumbs.add_property'),
          link: ''
        }
      ],
      columns: [
        {
          field: 'client_id',
          label: this.$t('clients.attrs.id'),
          search: true
        }, {
          field: 'client_type_icon',
          label: this.$t('clients.attrs.type'),
          type: 'icon'
        }, {
          field: 'client_name',
          label: this.$t('clients.attrs.name'),
          search: true
        }
      ],
      availableClients: [],
      selectedClients: [],
      excludeInvitedClients: true
    }
  },
  created() {
    this.$api.getEmailTemplate('client_user_invitation').then((content) => {
      this.inviteEmailBody = content
          .replace('%sender_name%', this.getCurrentUser().name)
          .replace(/<br\s*[\/]?>/gi, "");
      this.emailDialog = true
    });
  },
  mounted() {
    let params = {};
    params.rows = 250;
    params.excludeInvitedClients = this.excludeInvitedClients ? 1 : 0;
    this.service.listMyClients(params).then((clients) => {
      if (clients && clients.list) {
        this.totalClientsCount = clients.totalRecordCount;
        this.list = clients.list.map((o) => {
          return {
            "code": o.prim_uid,
            "name": o.client_id + ' - ' + o.client_name,
            "email": o.client_user_email ? o.client_user_email : o.email
          }
        });

        this.availableClients = clients.list;
      }
    })
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    clientListSearch(params) {
      return this.service.listMyClients(params).then((clients) => {
        if(clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "code": o.prim_uid,
              "name": o.client_id + ' - ' + o.client_name,
              "email": o.client_user_email ? o.client_user_email : o.email
            };
          })
        } else {
          return false;
        }
      });
    },
    clientDualListSearch(params) {
      params.rows = 250;
      params.excludeInvitedClients = this.excludeInvitedClients ? 1 : 0;
      this.service.listMyClients(params).then((clients) => {
        if(clients && clients.list) {
          this.availableClients = clients.list.filter(a => !this.selectedClients.map(b => b.prim_uid).includes(a.prim_uid))
        }
      });
    },
    toggleMultiClient() {
      this.buttonText = this.$t('buttons.send');
      this.multiClient = true;
      this.blockVisible = false;
    },
    backToList() {
      this.$router.push('/property/list')
    },
    submitNext() {
      if(this.multiClient) {
        let data = {
          "clientUids": [],
          "emailBody": this.inviteEmailBody
        }
        data.clientUids = this.selectedClients.map((o) => {
          return o.prim_uid
        });
        this.service.sendUserInvite(data).then((res) => {
          if(res) {
            this.finish = true
          }
        })
      } else if(this.selectedClient.email) {
        let data = {
          "clientUids": [this.selectedClient.code],
          "emailBody": this.inviteEmailBody
        }
        if(this.useEmailApp) {
          this.service.getUserInviteEmailPreview(data).then((response) => {
            if(response.data.success && response.data.emailPreview) {
              this.emailPreview = {...response.data.emailPreview};
              this.showEmailPreview = true;
            }
          })
        } else {
          this.service.sendUserInvite(data).then((res) => {
            if(res) {
              this.finish = true
            }
          })
        }
      } else {
        this.$api.showToast(this.$t("properties.client_invite.single_select_email_missing_error"), "error")
      }
    },
    multiClientSelected() {
      this.blockVisible = this.selectedClients.length > 0;
    },
    validateMultiClient(item, silent) {
      let valid;
      valid = item.email || item.client_user_email;
      if(!valid && !silent) {
        this.$api.showToast(this.$t('properties.client_invite.multi_select_email_missing_error').replace('%client_name%', item.client_name), "error");
      }
      return valid;
    },
    handleSelected(item) {
      this.selectedClient = {};
      if(item.email) {
        this.selectedClient = item;
        this.blockVisible = true
      } else {
        this.blockVisible = false;
        this.$api.showToast(this.$t("properties.client_invite.single_select_email_missing_error"), "error")
      }
    },
    selectAllClients() {
      this.$refs.clientDualSelect.allSelect();
    },
    excludeInvitedClientsChecked(checked) {
      this.excludeInvitedClients = checked;
      this.$refs.clientDualSelect.showloader = true;
      this.clientDualListSearch({
        "dropdownSearch": 1,
        "txtFilter": this.$refs.clientDualSelect.filterLeft
      })
    },
    emailCheck(isCheck) {
      this.useEmailApp = isCheck;
      if (isCheck) {
        this.buttonText = this.$t('properties.client_invite.preview')
      } else {
        this.buttonText = this.$t('buttons.send')
      }
    },
    copyMessage() {
      navigator.clipboard.writeText(this.emailPreview.message)
    },
    handleModalClose() {
      this.showEmailPreview = false;
    }
  }
}
</script>

<style scoped lang="scss">


.header1 {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-bottom: 20px;
}

.rect {

  height: auto;
  padding: 10px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 5px;
}

.textbold {

  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.link {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
  margin-bottom: 10px;
}
</style>
