<template>
  <MainLayout value="change_management"  v-if="!verifyingAccess">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import list from "@/components/changemanagement/List";
import list_data_query from "@/components/changemanagement/ListDataQuery";

export default {
  name: 'ChangeManagement',
  components: {MainLayout, list, list_data_query},
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('change_management');
  },
  methods: {
    handleComponentClick(data) {
    }
  }
}
</script>

<style scoped lang="scss">

</style>
