<template>
  <div class="ml-15 mr-15">
    <div class="flex justify-around space-x-10">
      <InputSelect
        class="mt-2 w-full"
        :isError="isAssignedUserError"
        :errorMessage="$t('general.errors.required')"
        :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
        :options="userList"
        v-model="step1.assignedUser"
        :selectedValue="step1.assignedUser"
        :filterable="true"
        @on-item-selected="fetchClient"
        :isRequired="true"
        :placeholder="$t('general.please_select')"
      />
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex justify-around space-x-10 mt-4">
      <InputSelect
          class="mt-2 w-full"
          v-model="step1.clientUid"
          :selected-value="step1.clientUid"
          :loading="loadingClient"
          :isError="isClientError"
          :errorMessage="clientError"
          :label="$t('properties.step1.client')"
          :options="clientList"
          :search-fn="clientListSearch"
          :total-record-count="totalClientsCount"
          :sort="true"
          :filterable="true"
          :isRequired=true
          placeholder=""
          @on-item-selected="selectClientUid"
      />
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex">
      <label class="not-found-label flex justify-start mt-2"> {{ $t('properties.step1.not_found') }} <span
          class="label_add_property_step1 cursor-pointer ml-2  underline" @click="addClient">{{
          $t('properties.step1.create_new')
        }}</span></label>
    </div>
    <deviatingOwner v-if="!$isBusiness" ref="compDeviatingOwner"/>

    <div class="flex justify-around space-x-8 mt-6 mb-10 ">
      <div class="w-full invisible"/>
      <div class="flex justify-end space-x-4 mt-6 mr-6">
        <Button class="w-52" :text="$t('buttons.back')" :secondary="true" @click="goBack()"/>
        <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
    <div/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputSelect from "../../inputs/InputSelect";
import {ClientService} from "@/services/client.service";
import {UserService} from "@/services/user.service";
import deviatingOwner from "@/components/properties/components/deviatingOwner";

export default {
  name: "StepClient",
  components: {InputSelect, deviatingOwner},
  props: {
    stepIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      clientService: new ClientService(),
      clientList: [],
      totalClientsCount: 0,
      step1: {
        assignedUser: "0",
        clientUid: "",
        clientInfo: {}
      },
      clientError: '',
      isClientError: false,
      isAssignedUserError: false,
      loadingClient: false,
      userList: [],
    }
  },
  mounted() {
    let userService = new UserService();
    userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            name: item.name,
            code: item.id
          });
        }

        return filtered;
      }, []);

      this.step1.assignedUser = this.getCurrentUser().id;
    });

    this.clientService.listUserClient('current').then((clients) => {
      if (clients && clients.list) {
        this.totalClientsCount = clients.totalRecordCount;
        this.clientList = clients.list.map((o) => {
          return {
            "name": o.client_id + ' - ' + o.client_name,
            "code": o.prim_uid,
            "street": o.street,
            "house_number": o.house_number,
            "zip_code": o.zip_code,
            "city": o.city
          }
        });

        this.step1.clientUid = this.getStepClient.clientUid;

        if (this.clientList.length === 1) {
          this.step1.clientUid = this.clientList[0].code;
          this.submitNext();
        }
      }
    });
  },
  computed: {
    ...mapGetters("property", ["getStepClient"]),
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("property", ["saveStepClient", "saveDeviatingOwner", "saveClientPersons"]),
    selectClientUid(item) {
      if (item.code === "0" || item.code === "") {
        this.isClientError = true;
        this.step1.clientInfo = {};
      } else {
        this.isClientError = false;
        this.step1.clientInfo = item;

        this.fetchClientPersonData(item.code);
      }
    },
    submitNext() {
      this.isClientError = false;
      this.isAssignedUserError = false;
      if (this.step1.clientUid === "0" || this.step1.clientUid === "") {
        this.isClientError = true
        this.clientError = this.$t('general.errors.required')
        return; // if any of the validations fail, we don't move on
      }

      if (this.step1.assignedUser === '0' || this.step1.assignedUser === '') {
        this.isAssignedUserError = true
        return;
      }

      if(this.$refs.compDeviatingOwner && !this.$refs.compDeviatingOwner.isValid()) {
        return false;
      }

      this.saveStepClient(this.step1);
      if(this.$refs.compDeviatingOwner) {
        this.saveDeviatingOwner(this.$refs.compDeviatingOwner.getData());
      }
      this.$emit("goToNextStep", this.step1);
    },
    addClient() {
      this.$router.push('/client/add')
    },
    clientListSearch(params) {
      return this.clientService.listUserClient('current', params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "name": o.client_id + ' - ' + o.client_name,
              "code": o.prim_uid,
              "street": o.street,
              "house_number": o.house_number,
              "zip_code": o.zip_code,
              "city": o.city
            };
          })
        } else {
          return false;
        }
      });
    },

    fetchClientPersonData(client_uid) {
      let getData = {}
      getData.parentType = 'client';
      getData.parentId = client_uid;

      this.clientService.getPersonData(getData, true).then((list) => {
        this.saveClientPersons(list);
      });
    },

    fetchClient() {
      this.loadingClient = true;
      this.clientService.listUserClient([this.step1.assignedUser, 'current'].join(',')).then((clients) => {
        if (clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;

          this.clientList = clients.list.map((o) => {
            return {
              name: o.client_id + ' - ' + o.client_name,
              code: o.prim_uid,
              street: o.street,
              house_number: o.house_number,
              zip_code: o.zip_code,
              city: o.city
            }
          });

          this.step1.clientUid = this.getStepClient.clientUid;

          if (this.clientList.length === 1) {
            this.step1.clientUid = this.clientList[0].code;
          }

          this.loadingClient = false;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">


.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label_add_property_step1 {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}

</style>
