<template>
  <div class="flex  flex-col items-center  ">
    <div class="flex items-center self-start  mt-2 cursor-pointer" @click="viewDocument">
      <img class="file" src="@/assets/file_gray.svg">
      <div class="flex flex-col flex-auto ml-4 mb-1 items-center">
        <p class="name self-start">{{ getTitle }} </p>
        <p class="sub-text self-start">{{ getSubTitle }} </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getTitle() {
      if(this.item.prim_uid) {
        return this.item.filename;
      }

      return '';
    },
    getSubTitle() {
      if(this.item.prim_uid) {
        return this.item.created_by_name + this.$t('search.doc_subtext') + this.item.uploaded_at_str;
      }

      return '';
    }
  },
  methods: {
    viewDocument() {
      this.$router.push({
        name: "DocumentViewer",
        params: {
          'id': this.item.prim_uid,
          'parentType': this.item.parent_type,
          'parentId': this.item.parent_uid
        }
      });
    }
  }
}

</script>

<style scoped lang="scss">
.line {
  width: 95%;
}

.notification-bold-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
}

.file {
  width: 20px;
  height: 25px;
  box-sizing: border-box;
}


.message {
  display: block;
  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 16px;

  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.name {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.sub-text {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 3px;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: left;

  font-size: 12px;
}


.date {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
}
</style>
