<template>
  <div>
    <div class="flex">
      <!--      Label with required indicator-->
      <div :class="CustomClasses" class="label">{{ label }}</div>
      <span v-if='isRequired' style="color:red"> *</span>
      <!--      help icon-->
      <img v-if="showHelpIcon" class="help_icon" src="@/assets/help_green.svg" v-tooltip.right="tooltip"/>
      <VDropdown v-if="showHelpIconPopover" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                 :hideTriggers="['click', 'hover']">
        <!-- This will be the popover target (for the events and position) -->
        <img class="help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <!-- This will be the content of the popover -->
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text" v-for="text in popoverTexts" v-html="text" v-bind:key="text">
              </p>
            </div>
          </div>
          <!-- You can put other components too -->
        </template>
      </VDropdown>
    </div>
    <div>
      <!--      input View-->
      <div class="mt-1 " v-if=disabled>
        <div class="flex justify-between input-wrapper-disabled">
          <input
            v-model="field"
            class="date-input-disabled "
            :disabled="disabled"
            type="text"
            :placeholder="placeholder"
            @keypress="onKeyPress"
            @blur="blurHandler"
          />
        </div>

      </div>
      <div v-else class="flex justify-between mt-1">
        <Calendar ref="date-picker" v-model="field" :selectionMode="selectionMode" :manualInput="manualInputEnabled()" class="w-full"
                  :minDate="minDateValue"
                  :maxDate="maxDateValue"
                  :month-navigator="true"
                  :year-navigator="true"
                  :year-range="yearRange"
                  :appendTo="appendTo"
                  :placeholder="placeholderComputed"
                  @date-select="dateSelected"
                  @keypress="onKeyPress"
                  @blur="blurHandler"
                  :show-icon="false"
                  :show-on-focus="false"/>
        <img class="img_input_date self-center cursor-pointer" @click="showCalendar"
             :src="getAssetPath('calander_green.svg')"/>
      </div>

      <!--      Error Message label-->
      <label class="error-label self-start mt-1 " v-show="isError" style="color:#a4262c">{{
          errorMessage
        }}</label>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import Calendar from "primevue/calendar";
import moment from 'moment';

export default {
  name: "InputDate",
  directives: {'tooltip': Tooltip},
  props: {
    value: {
      default: ''
    },
    dateId: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: 'email'
    },
    label: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    showHelpIconPopover: {
      type: Boolean,
      default: false
    },
    popoverTexts: {
      type: Array,
      default: () => []
    },
    rightText: {
      type: String,
      default: ""
    },
    dob: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    tooltip: {
      type: String,
      default: 'No tool tip provided'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: "single"
    },
    isOnlyNumber: {
      type: Boolean,
      default: false
    },
    minDateValueProp: {
      type: Date,
      default: () => {return new Date('1500-01-01')}
    },
    maxDateValueProp: {
      type: Date,
      default: () => {return new Date('3000-01-01')}
    },
    appendTo: {
      type: String,
      default: null
    }
  },
  components: {Calendar},
  data() {
    return {
      field: null,
      selectedValue: '',
      minDateValue: this.minDateValueProp,
      maxDateValue: this.maxDateValueProp
    };
  },
  computed: {
    CustomClasses() {
      return [{"multiline": this.isMultiline}, {"error": this.isError}]
    },
    yearRange() {
      return this.minDateValue.getFullYear() + ':' + this.maxDateValue.getFullYear();
    },
    placeholderComputed() {
      if (typeof this.placeholder !== "undefined") {
        return this.placeholder;
      } else {
        return this.selectionMode==="range" ? this.$t("primevue.placeholderRange") : this.$t("primevue.placeholderSingle");
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedValue = newValue;
      }
    },
    selectedValue(newValue, oldValue) {
      if (typeof newValue[1] !== 'undefined' && newValue[1] === null) {
        newValue[1] = newValue[0];
      }

      if (this.disabled) {
        this.field = newValue instanceof Date ? moment(newValue).format(this.$t('general.dateFormat')) : newValue;
        return;
      }

      if (newValue !== oldValue && this.selectionMode === 'single') {
        if (newValue !== '') {
          if (!(newValue instanceof Date)) {
            this.field = this.toDate(newValue);
          } else if (newValue instanceof Date && !isNaN(newValue)) {
            this.field = newValue;
          }
        } else {
          this.field = newValue;
        }
      } else if (this.selectionMode === 'range') {
        if (Array.isArray(newValue) && newValue.length) {
          if (this.field === null) {
            this.field = [newValue[0], newValue[1]];
          }
        } else if (newValue === '') {
          this.field = null;
        }
      }
    },
    field(newValue, oldValue) {
      if (!this.disabled && newValue && newValue instanceof Date) {
        this.selectedValue = moment(newValue).format('YYYY-MM-DD');
      } else if (newValue === '') {
        this.selectedValue = newValue;
      }

      if (newValue !== oldValue && !this.disabled) {
        if (this.selectionMode === 'single' && this.selectedValue != '') {
          this.$emit('input', moment(this.selectedValue).format('YYYY-MM-DD'));
        } else if (this.selectionMode === 'range' && this.selectedValue.length) {
          this.$emit('input', this.selectedValue.map((value) => moment(value).format('YYYY-MM-DD')));
        } else {
          this.$emit('input', this.selectedValue);
        }
      }
    }
  },
  mounted() {
    if (this.value && this.value !== '') {
      this.selectedValue = this.value;
    } else {
      this.field = null;
    }

    if (this.dob) {
      this.maxDateValue = new Date();
    }
  },
  methods: {
    manualInputEnabled() {
      let isSingleSelection = this.selectionMode === 'single';

      if (isSingleSelection && this.field !== null && !this.validInput(this.field)) {
        this.field = '';
      }

      return isSingleSelection;
    },
    showCalendar() {
      if (!this.$refs["date-picker"].overlayVisible) {
        this.$refs["date-picker"].$refs.input.$el.focus();
        this.$refs["date-picker"].overlayVisible = true;
      } else {
        this.$refs["date-picker"].overlayVisible = false;
      }
    },
    dateSelected(date) {
      if (this.selectionMode === 'single') {
        this.selectedValue = this.toUtc(date);
      } else if (this.selectionMode === 'range') {
        this.selectedValue = [
          this.field[0] === null ? null : this.toUtc(this.field[0]),
          this.field[1] === null ? null : this.toUtc(this.field[1])
        ];
        if (this.field[1] !== null) {
          this.showCalendar();
        }
      }

      this.$emit('date-selected', this.selectedValue);
    },
    toDate(date) {
      if (date instanceof Date) {
        return date;
      }

      return new Date(date);
    },
    toUtc(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    onKeyPress(e) {
      this.errorShow = false;
      let skip = false;

      if (e.keyCode !== 46 && (e.keyCode < 48 || e.keyCode > 57) && this.isOnlyNumber) {
        skip = true;
      } else {
        if (e.code === 'Space' && this.selectionMode === 'range') {
          this.field = null;
          this.selectedValue = '';
          skip = true;
        }
      }

      if (skip) {
        e.preventDefault();
      } else {
        if (e.keyCode === 13) {
          this.adjustDateInput();
        }

        this.$emit('keypress');
      }
    },
    blurHandler() {
      if (this.selectionMode !== 'range') {
        this.adjustDateInput();

        if (!this.validField(this.field) && !(this.field instanceof Date)) {
          this.field = '';
        } else {
          this.$emit('date-selected', this.field);
        }
      }
    },
    validInput(str) {
      if (this.selectionMode === 'range') {
        return true;
      } else {
        return /^[0-9\.]+$/.test(str) || str instanceof Date;
      }
    },
    validField(str) {
      if (this.selectionMode === 'range') {
        return true;
      } else {
        return /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(str);
      }
    },
    adjustDateInput() {
      if (this.selectionMode === 'single' && this.field !== null && this.field instanceof Date === false) {
        let fieldNumbers = this.field.replaceAll('.', '');

        let pattern = '';
        if (fieldNumbers.length === 4) {
          pattern = /(\d{1})\.?(\d{1})\.?(\d{2})/;
        } else if (fieldNumbers.length === 5) {
          pattern = /(\d{2})\.?(\d{1})\.?(\d{2})/;
        } else if (fieldNumbers.length === 6) {
          let thirdCharacter = fieldNumbers.substring(2, 3);
          if (parseInt(thirdCharacter) > 1) {
            pattern = /(\d{1,2})\.?(\d{1,2})\.?(\d{4})/;
          } else {
            pattern = /(\d{2})\.?(\d{2})\.?(\d{2})/;
          }
        } else if (fieldNumbers.length === 7) {
          pattern = /(\d{2})\.?(\d{1,2})\.?(\d{4})/;
        } else if (fieldNumbers.length >= 8) {
          pattern = /(\d{1,2})\.?(\d{1,2})\.?(\d{2,4})/;
        }

        if (pattern !== '' && pattern.test(this.field)) {
          let day = this.field.match(pattern)[1];
          let month = this.field.match(pattern)[2];
          let year = this.field.match(pattern)[3];

          const currentDate = new Date();

          // year adjustment
          const currentDateYear = currentDate.getFullYear();
          const currentDateYearStr = currentDateYear.toString();

          const currentDateMonth = currentDate.getMonth() + 1;
          const currentDateMonthStr = currentDateMonth.toString();

          const currentDateDay = currentDate.getDay();
          const currentDateDayStr = currentDateDay.toString();

          const minDateYear = this.minDateValue.getFullYear();
          const maxDateYear = this.maxDateValue.getFullYear();

          let last2year = year.substring(year.length - 2);
          let last2currentDateYearStr = currentDateYearStr.substring(currentDateYearStr.length - 2);

          let first2currentDateYearStr = currentDateYearStr.slice(0, 2);

          if (year.length < 4) {
            if (parseInt(last2year) <= parseInt(last2currentDateYearStr)) {
              year = first2currentDateYearStr + last2year;
            } else {
              year = (parseInt(first2currentDateYearStr) - 1) + last2year;
            }
          }

          if (year.length >= 4) {
            year = year.slice(0, 4);
            if (parseInt(year) < minDateYear) {
              year = minDateYear;
            } else if (parseInt(year) > maxDateYear) {
              year = maxDateYear;
            }
          }

          // month adjustment
          if (parseInt(month.slice(0, 1)) > 1 && month.length < 2) {
            month = '0' + month;
          }

          if (month.length > 2) {
            month = month.slice(0, 2);
          }

          if (month.length === 2 && parseInt(month) > 12) {
            month = '12';
          }

          if (parseInt(month) === 0) {
            month = currentDateMonthStr;
          }

          if (month.length === 1) {
            month = '0' + month;
          }


          // day adjustment

          if (parseInt(day.slice(0, 1)) > 3 && day.length < 2) {
            day = '0' + day;
          }

          if (day.length > 2) {
            day = day.slice(0, 2);
          }

          if (day.length === 2 && parseInt(day) > 31) {
            day = '31';

            if (parseInt(month) === 2) {
              day = '27';
            }
          }

          if (parseInt(day) === 0) {
            day = currentDateDayStr;
          }

          if (day.length === 1) {
            day = '0' + day;
          }

          this.field = this.toDate(month + '.' + day + '.' + year);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.right-text {
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: #229d56;
  text-align: right;
  line-height: normal;

  margin-right: 5px;
  font-size: 15px;
}


input {
  @include segoe-regular;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  color: #aeaeae;
  text-align: left;

  &.disabled {
    background-color: #f4f1f3;
    color: #aeaeae;
  }
}

.label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;

  &.disabled {
    color: #797979;
  }
}

.error-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
}

.img_input_date {
  width: 18px;
  height: 18px;
  margin-left: -40px;
  margin-right: 8px;
  z-index: 1;
}

.input-wrapper-disabled {
  height: 32px;
}

.date-input-disabled {
  width: 100%;
  border: 0px solid #7a7a7a;
  background: #f4f1f3;
  border-radius: 0px;
  padding-left: 10px;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.popover-text ::v-deep ul {
  list-style-type: disc;
  margin-left: 12px;
}

</style>
