<template>
  <div>
    <div class="mt-4 mr-6 pb-8">
      <div class="tabs">
        <div class="tabs-triggers space-x-1">
          <div class="tabs-trigger" v-for="(item, index) in getCategories" :key="index"
               :class="[item.slug === activeTab  ? 'tabs-trigger--active' : '']" @click="activate(item)">{{ item.name }}
          </div>
        </div>
      </div>
      <div class="tab-content h-full">
        <div class="tab-pane" v-for="(item, index) in categories" :key="index" v-show="activeTab === item.slug">
          <hr class="hr-line-user" v-if="item.showTopLine">
          <slot :name="`tab-pane-${item.slug}`"/>
        </div>
      </div>
    </div>
    <div class="tab-footer mr-6" v-if="!noFooter">
      <hr class="hr-line-user mt-25">
      <slot name="tab-footer">
      <div class="flex justify-end mt-5 space-x-6">
        <Button @click="handleButtonClick('cancel')" class=" button-label  text-center   label__text-black "
                :secondary="true"
                :text="$t('buttons.cancel')"></Button>
        <ButtonWithOption
          v-if="splitSaveButton"
          @click="handleButtonClick"
          class="button-label  text-center bg-green  text-white "
          primary
          :text="$t('buttons.save')"
          :options="saveButtonWithOptions"
        ></ButtonWithOption>
        <Button v-else @click="handleButtonClick('save')" class="button-label  text-center bg-green  text-white  " primary
                :text="$t('buttons.save')"></Button>
      </div>
    </slot>
    </div>
  </div>
</template>
<script>

export default {
  name: "AppTabs",
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    selectedTab: {
      type: String,
      default: '',
    },
    saveHandler: {
      type: Function
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    splitSaveButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: '',
      saveBtnOptions: ['saveContinue', 'saveNew', 'saveList', 'save'],
      saveButtonWithOptions: [
        {
          icon: require('@/assets/button_edit.svg'),
          label: this.$t('buttons.options.save_continue'),
          handler: 'handleSaveContinue',
          default: true,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_add.svg'),
          label: this.$t('buttons.options.save_new'),
          handler: 'handleSaveNew',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_list.svg'),
          label: this.$t('buttons.options.save_list'),
          handler: 'handleSaveList',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        }
      ]
    }
  },
  computed: {
    getCategories() {
      return this.categories.filter(o => !o.hide)
    }
  },
  watch: {
    selectedTab(nv) {
      if (nv) {
        let tab = this.categories.find(o => o.slug === nv);
        if (tab && tab.slug) {
          this.activeTab = tab.slug;
        }
      }
    }
  },
  mounted() {
    if (this.selectedTab) {
      this.activeTab = this.selectedTab;
    } else {
      this.activeTab = this.categories.length > 0 ? this.categories[0].slug : '';
    }
  },
  methods: {
    activate(item) {
      this.activeTab = item.slug;
      this.$emit('click', item.slug)
    },
    handleButtonClick(btn_key) {
      if (this.saveBtnOptions.includes(btn_key) && this.saveHandler) {
        this.saveHandler(btn_key);
      }
      this.$emit('buttonClick', btn_key);
    }
  }
}
</script>

<style scoped lang="scss">
$base-duration: 1250ms;

// Colors

$secondary: #EFCB68;
$accent: #686963;
$white: #fff;


// Breakpoints
$sm: 20rem;
$med: 48rem;
$lg: 64rem;

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}


.tabs {
  position: relative;
  height: 100%;
  background-color: $white;
  width: 100%;
  overflow-y: auto;

  &-triggers {
    display: flex;
    width: 100%;
  }

  &-trigger {
    //flex: 1 0 auto;

    padding-left: 1rem;
    padding-right: 1rem;
    // background-color: $white;
    //transition: 500ms linear all;
    cursor: pointer;

    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: center;


    &--active {
      background-color: $white;
      color: theme('colors.muted_black');
      @include segoe-semi-bold;
      border-bottom-width: 3px;
      border-bottom-color: $primary;
      padding-bottom: 15px;

    }
  }

  &-content {
    padding: 1rem;
    background-color: $white;
  }
}

.tab-content {
  padding-top: 39px;
}
.tab-footer {
  padding-bottom: 10px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}

.button-label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
