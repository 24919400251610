<template>
  <div class="">
    <div class="flex justify-around space-x-8">
      <InputSelect class="mt-2 w-full"
                   v-model="clientType"
                   :selectedValue="clientType"
                   :label="$t('clients.attrs.type')"
                   :options="$t('clients.client_types')"
                   :isRequired="false" placeholder=""/>
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex justify-around space-x-8 mt-4">
      <InputSelect
          class="w-full"
          :label="$t('clients.attrs.assignee')"
          :options="userList"
          v-model="assignee"
          :selected-value="assignee"
          placeholder=""
      />
      <div class="w-full pr-6 invisible"/>
    </div>
    <div class="flex justify-around space-x-8 mt-6 ">
      <div class="w-full invisible"/>
      <div class="flex justify-end space-x-4 mt-6 mr-6">
        <Button class="w-52" :text="$t('buttons.back')" @click="goBack()" :secondary="true"/>
        <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputSelect from "../../inputs/InputSelect";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step1",
  components: {InputSelect},
  data() {
    return {
      clientType: this.$store.state.client.clientData.clientType,
      assignee: this.$store.state.client.clientData.assignee,
      userList: [],
    }
  },
  mounted() {
    // fetch user list
    if (this.getClientData.branch) {
      let branchService = new BranchService();
      branchService.get(this.getClientData.branch).then(branchData => {
        if (branchData.prim_uid) {
          if (branchData.selected_users.length > 0) {
            this.userList = branchData.selected_users.reduce((filtered, item) => {
              filtered.push({
                "name": item.name,
                "code": item.prim_uid
              });
              return filtered;
            }, []);
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters("client", ["getClientData"])
  },
  methods: {
    ...mapMutations("client", ["setClientData"]),
    submitNext() {
      this.setClientData({
        clientType: this.clientType,
        assignee: this.assignee
      });
      this.$emit("goToNextStep", this.step1);
    }
  }
}
</script>

<style scoped lang="scss">


.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

</style>
