const state = {
    userData: {
        name: '',
        email: '',
        phone: '',
        company: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        clients: '',
        employees: '',
        tools: '',
        password: ''
    },
    currentStep: 1


};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getUserData: (state) => state.userData,
}

const mutations = {
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setFirstStepData: (state, step1) => {
        state.userData.name = step1.name;
        state.userData.email = step1.email;
        state.userData.phone = step1.phone;
    },
    setSecondStepData: (state, step2) => {

        state.userData.company = step2.company
        state.userData.street = step2.street
        state.userData.zip = step2.zip
        state.userData.city = step2.city
        state.userData.country = step2.country
    },
    setThirdStepData: (state, step3) => {

        state.userData.clients = step3.clients
        state.userData.employees = step3.employees
        state.userData.tools = step3.tools

    },

};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



