<template>
  <div class="flex button_background" v-show="hasVisibleOptions">
    <div :class="CustomClasses" class=" button  flex justify-center items-center" v-on="defaultHandler !== '' ? { click: () => excuteFuncHandler(defaultHandler) } : {click: (event) => clickMainBtn(event) }">
      <img class="img" :src="icon" v-if="icon"/>
      <div class="flex flex-row items-center">
        <i class="pi pi-spin pi-spinner mr-4" v-show="loading"/>
        <p :class="CustomClasses" class="button-text self-center">{{ text }}</p>
      </div>
      <img class="img-right" :src="rightIcon" v-if="rightIcon"/>
    </div>

    <div>
      <div class="flex cursor-pointer mr-2" @click="togglePanel">
        <div class="flex justify-center items-center space-x-2">
          <hr class="verticle-line"/>
          <img class="down_img" :src="getAssetPath('arrow_down.svg')"/>
        </div>
      </div>
      <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_notification_panel" :class="overlayClass">
        <div class="flex flex-col h-auto justify-between" style="margin-right: -15px; margin-top: -3px; margin-bottom: -9px;">
          <div class="" style="margin-left: -25px; margin-right: 7px;">
            <div class="space-y-2" style="padding-left: 0px;">
              <template v-for="(option, index) in options">
                <IconLabel
                  v-if="option.show && option.icon !== false"
                  :icon="option.icon"
                  :blackText="option.blackText"
                  iconStyle="blackish"
                  :isHover="true"
                  :fontSize="option.fontSize"
                  :label="option.label"
                  :key="index"
                  @click="excuteFuncHandler(option.handler, option.index)"
                />

                <div
                  v-if="option.show && option.icon === false && option.checkbox === true"
                  class="main justify-center items-center flex mt-3 cursor-pointer mb-3 text_black"
                >
                  <div class="line w-3 border-l-3 mr-2 text-white invisible"></div>
                  <InputCheck :is-checked="option.checked"
                              @check="excuteFuncHandler(option.handler, option.index)"
                              class="mr-3 checkbox-text-row"
                              :text="option.label"/>
                </div>
              </template>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script>
import {directive as onClickaway} from 'vue-clickaway';
import OverlayPanel from 'primevue/overlaypanel';
import InputCheck from "./InputCheck";

export default {
  name: 'ButtonWithOption',
  directives: {
    onClickaway: onClickaway,
  },
  components: {OverlayPanel, InputCheck},
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: String,
      default: ''
    },
    secondary: {
      type: Boolean,
      default: false
    },
    delete: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    blackText: {
      type: Boolean,
      default: false
    },
    isSubmenu: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      overlayClass: ''
    }
  },
  computed: {
    CustomClasses() {
      return [
        {'secondary': this.secondary},
        {'fullwidth': this.fullwidth},
        {'delete': this.delete},
        {'disabled': this.disabled},
        {'input_loader': this.loading}
      ]
    },
    defaultHandler() {
      let defaultHandler = '';
      let found = this.options.filter((option) => {
        return option.default === true;
      });

      if (found.length > 0) {
        defaultHandler = found[0].handler;
      }

      return defaultHandler;
    },
    hasVisibleOptions() {
      let found = this.options.filter((option) => {
        return option.show === true;
      });

      if (found.length > 0) {
        return true;
      }

      return false;
    }
  },
  methods: {
    excuteFuncHandler(funcName, index) {
      if (funcName !== '') {
        var fn = this[funcName];
        if (typeof fn === 'function') {
          if (typeof index !== 'undefined') {
            fn(index);
          } else {
            fn();
          }
        }
      }
    },
    clickMainBtn(event) {
      this.togglePanel(event, 1);
    },
    handleSaveContinue() {
      this.$emit('click', 'saveContinue');
    },
    handleSaveNew() {
      this.$emit('click', 'saveNew');
    },
    handleSaveList() {
      this.$emit('click', 'saveList');
    },
    handleClick() {
      this.$emit('click');
    },
    archivePropertyModalShow() {
      this.$emit('click', 'archivePropertyModalShow');
      this.$refs.op.toggle();
    },
    startDeclaration() {
      this.$emit('click', 'startDeclaration');
      this.$refs.op.toggle();
    },
    duplicate() {
      this.$emit('click', 'duplicate');
      this.$refs.op.toggle();
    },
    showNextDeclarationTypeDialog() {
      this.$emit('click', 'showNextDeclarationTypeDialog');
      this.$refs.op.toggle();
    },
    showCancelNextDeclarationTypeDialog() {
      this.$emit('click', 'showCancelNextDeclarationTypeDialog');
      this.$refs.op.toggle();
    },
    downloadPropertyValuePdf() {
      this.$emit('click', 'downloadPropertyValuePdf');
      this.$refs.op.toggle();
    },
    openCommunitySelection() {
      this.$emit('click', 'openCommunitySelection');
      this.$refs.op.toggle();
    },
    calculatePropertyValue() {
      this.$emit('click', 'calculatePropertyValue');
      this.$refs.op.toggle();
    },
    considerExemptionInPropertyValueToggle() {
      this.$emit('click', 'considerExemptionInPropertyValueToggle');
      this.$refs.op.toggle();
    },
    declarationWorkflow(index) {
      this.$emit('click', 'declarationWorkflow', index);
      this.$refs.op.toggle();
    },
    togglePanel(event, param) {
      if (typeof param !== 'undefined' && param === 1) {
        this.overlayClass = 'otherFunction';
      } else {
        this.overlayClass = '';
      }

      this.$refs.op.toggle(event);
    },
  }
}
</script>

<style scoped lang="scss">
.button_background {
  border-radius: 4px;
}

.button {
  background-color: $primary;
  color: white;
  height: 32px;
  display: inline-flex;
  flex-direction: row;
  min-width: 168px;
  max-width: 372px;
  cursor: pointer;
  border-radius:4px;

  &:hover {
    background-color: $button-hover-color;
  }

  &.fullwidth {
    max-width: 100%;
  }

  &.secondary {
    background-color: var(--secondary-button-bg-color);
    box-sizing: border-box;
    color: #404040;

    &:hover {
      background-color: #AEAEAE
    }
  }

  &.disabled {
    background-color: #E9E9E9;
    box-sizing: border-box;
    color: #A4A4A4;
    cursor: default;

    &:hover {
      background-color: #E9E9E9;
    }
  }

  &.delete {
    background-color: rgba(213, 89, 89, 1);
    box-sizing: border-box;
    color: white;

    &:hover {
      background-color: #ae3939
    }
  }
}

.button-text {
  font-family: 'Segoe UI', sans-serif;
  color: $button-text-color;

  &.secondary {
    color: theme('colors.muted_black');
  }

  &.disabled {
    color: #A4A4A4;
  }

  &.delete {
    color: white;
  }
}

.input_loader {
  position: relative;

  i.pi-spinner {
    position: absolute;
    right: 5px;
    top: 6px;
  }

  input {
    padding-right: 30px;
  }
}

.img {
  width: 17px;
  height: 21px;
  margin-right: 6px;
}

.img-right {
  width: 17px;
  height: 21px;
  margin-left: 8px;
}

.verticle-line {
  width: 1px;
  height: 17px;
  border: 0px solid #e6e6e6;
  background-color: #e6e6e6;
  color: #aeaeae;
  margin-bottom: 7px;
  margin-top: 8px;
}

.down_img {
  width: 14px;
  height: 8px;
}

.p-overlaypanel:after, .p-overlaypanel:before {
  bottom: 100%;
  left: calc(var(--overlayArrowLeft, 0) + 20.50rem);
  content: " ";
  height: 0;
  width: 0;
  display: none;
  position: absolute;
  pointer-events: none;
}

.p-overlaypanel, .p-overlaypanel {
  left: calc(var(--overlayArrowLeft, 0) - 13.2rem) !important;
  margin-left: 12px;
  position: absolute;
  margin-top: 0px;
  border-radius: 4px;
}

.p-overlaypanel.otherFunction {
  left: calc(var(--overlayArrowLeft, 0) - 2.2rem) !important;
}

.icon_text, .text_black {
  font-size: 10px !important;
}
</style>
