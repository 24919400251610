import Vue from "vue";
import Vuex from "vuex";

import register from "./modules/register";
import user from "./modules/user";
import property from "./modules/property";
import report from "./modules/report";
import client from "./modules/client";
import branch from "./modules/branch";
import dms from "./modules/dms";
import declaration from "./modules/declaration";
import declaration_bulk_add from "./modules/declaration_bulk_add";
import data_query_bulk_add from "./modules/data_query_bulk_add";
import datev_import from "./modules/datev_import";
import addison_import from "./modules/addison_import";
import grid from "./modules/grid";
import sub_user from "./modules/sub_user";
import countries from "./modules/countries";
import email from "./modules/email"
import federal_states from "./modules/federal_states";
import documents from "@/store/modules/documents";
import search from "@/store/modules/search";
import billing from "@/store/modules/billing";
import power_of_attorney from "./modules/power_of_attorney";
import record_observer from "@/store/modules/record_observer";
import communications from "./modules/communications";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
      register,
      user,
      property,
      report,
      client,
      datev_import,
      addison_import,
      declaration,
      declaration_bulk_add,
      data_query_bulk_add,
      grid,
      sub_user,
      branch,
      countries,
      email,
      federal_states,
      documents,
      billing,
      search,
      power_of_attorney,
      record_observer,
      communications,
      dms
  },
  strict: true,
});
