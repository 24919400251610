<template>
  <div class="h-full bg-white pl-7 w-full">
    <PageHeader :title="$t('components.report_download.title')" :breadcrumbs="breadcrumbs"/>
    <div v-if="this.downloadToken !== null" class="text-left">
      <div class="w-full text-left flex mt-5">
        <strong class="mr-2">{{ $t('components.report_download.type') }}:</strong>
        {{ downloadType }}
      </div>
      <div class="w-full text-left flex mt-5">
        <strong class="mr-2">{{ $t('components.report_download.generated_at') }}:</strong> {{ downloadDate }}
      </div>
      <div class="w-full text-left flex mt-5">
        <Button class="w-80" @click="download" :text="$t('components.report_download.download')"></Button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'DownloadReport',
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('components.reports'),
          link: '/report/reports'
        }
      ],
      downloadToken: null,
      downloadType: null,
      downloadLink: null,
      downloadDate: null,
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    fetchNotification() {
      this.$api.trigger('report/download', {id: this.$route.query.uid}, true)
        .then((response) => {
          if (response.data.success) {
            this.downloadToken = response.data.token;
            this.downloadType = this.$t('components.available_reports.' + response.data.name);
            this.downloadDate = response.data.created;
            this.downloadLink = '/download?token=' + response.data.token;
          } else {
            this.$api.showToast(response.data.message, 'error')
          }
        });
    },
    download() {
      const downloadLink = document.createElement('a');
      downloadLink.href = this.downloadLink;
      downloadLink.click();
    }
  }
}
</script>
