<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.document')"/>

    <div class="mr-7 ">
      <div class="flex w-full justify-between mb-5">
        <div class="w-1/2 ">
          <IconLabel :icon="require('@/assets/refresh.svg')" :label="$t('buttons.refresh')"
                     :blackText="true"
                     iconStyle="blackish"
                     @click="search()"
          />
        </div>
        <div class="w-1/2 flex  justify-end">
          <InputSelect v-if="useDms"
                       class="mr-2 w-1/4"
                       :label="'DMS-Übertragung'"
                       :options="getDMSFilterOption()"
                       :selected-value="filterDMS"
                       :showHelpIconPopover="false"
                       :total-record-count="4"
                       @on-item-selected="handleDMSFilter"
          />
          <InputSelect
              class="client_search "
              :isRequired=false
              :options="clientList"
              :filterable="true"
              :label="$t('general.client_select')"
              :search-fn="clientListSearch"
              :total-record-count="totalClientsCount"
              :sort="true"
              :show-clear="true"
              @on-item-selected="handleClientSearchResult"
          />
        </div>
      </div>
      <div>
        <TreeTable :lazy="true" :paginator="true" :rows="rows" :value="nodes"
                   :expandedKeys="expandedKeys"
                   :loading="loading" @node-expand="fetchChildren" :totalRecords="totalRecords" @page="onPage"
                   scrollable scrollHeight="200px" style="width: 100%;height: 1000px;overflow: auto;display: block">
          <Column headerClass="headerClass" field="name" :rowspan=3
                  :header="$t('components.documents.tree_table.col_document')"
                  :expander="true">
            <template #body="slotProps">
              <div class="flex ml-16  items-center space-x-3 -mt-10">
                <img class="tree_icon" :src="getIcon(slotProps.node.data.icon)"
                     :alt="slotProps.node.data.icon+' symbol'"/>
                <span class="document_name cursor-pointer"
                      @click="expandNode(slotProps.node)">{{ slotProps.node.data.name }}</span>
                <span @click="editOrViewNode">
                  <img class="edit-icon tree_icon" :src="getEditOrViewIcon(slotProps.node.type)"
                       @click="triggerEdit(slotProps.node)"
                       :alt="'eye symbol'"/>
                </span>
              </div>
            </template>
          </Column>
          <Column headerStyle="width:100px" headerClass="headerClass" field="file_size"
                  :header="$t('components.documents.tree_table.col_size')"></Column>
          <Column headerStyle="width:140px;text-align:center;" field="created_at"
                  :header="$t('components.documents.tree_table.col_created')"></Column>
          <Column headerStyle="width:200px" v-if="isActiveDms" field="dms_transfer"
                  :header="$t('components.documents.tree_table.col_dms_transfer')">
            <template #body="slotProps">
              <InputCheck isChecked v-if="slotProps.node.data.dms_transfer"/>
            </template>
          </Column>
          <Column headerStyle="width:240px;text-align:center;" bodyStyle="padding-left:100px; padding-bottom: 33px "
                  field="visible_in_client"
                  :header="$t('components.documents.tree_table.col_visible_in_client')">
            <template #body="slotProps">

              <InputCheck v-if="slotProps.node.type === 'document'"
                          :is-checked="slotProps.node.data.visible_portal"
                          @check="handleCheck(slotProps.node)"
                          :isDisabled="slotProps.node.parent_type === 'client'"
              />
            </template>
          </Column>
          <Column headerStyle="width:240px;text-align:center;" bodyStyle="padding-left:100px; padding-bottom: 33px "
                  v-if="useDms" field="dms"
                  :header="$t('components.documents.tree_table.col_dokumententransfer')">
            <template #body="slotProps">
              <InputCheck v-if="slotProps.node.type === 'document'"
                          :is-checked="slotProps.node.data.dms" @check="handleDocuTransferCheck(slotProps.node)"/>
            </template>
          </Column>
        </TreeTable>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import TreeTable from 'primevue/treetable';
import InputSelect from '@/components/inputs/InputSelect';
import Column from 'primevue/column';
import FilterInput from "@/components/inputs/FilterInput";
import IconLabel from "@/components/labels/IconLabel";
import PageHeader from "@/components/PageHeader";
import {DocumentService} from "@/services/document.service";
import {documentColumns, documentMenus, documentStandardFilters} from "@/core/constants";
import InputCheck from "@/components/inputs/InputCheck";
import ModalDialog from "@/components/ModalDialog";
import {ClientService} from "@/services/client.service";

export default {
  name: 'list',
  components: {InputCheck, InputSelect, ModalDialog, IconLabel, PageHeader, FilterInput, TreeTable, Column},

  metaInfo() {
    return {
      title: this.getTitle,
    }
  },

  data() {
    return {
      expandedKeys: {},
      clientService: new ClientService(),
      rendering: true,
      documentService: new DocumentService(),
      standardFilters: documentStandardFilters,
      columns: documentColumns,
      menus: documentMenus,
      serverParams: {
        'parentType': this.parentType,
        'parentId': this.parentId,
        'gridId': this.gridId
      },
      totalClientsCount: 0,
      clientList: [],
      terms_accepted: false,
      showUploadDlg: false,
      showRenameDlg: false,
      renameDocument: {},
      nodes: null,
      searchTerm: '',
      isActiveDms: false,
      rows: 10,
      totalRecords: 0,
      loading: true,
      gridId: 'documents',
      filterDMS: 0,
      useDms: true,
      account_active: false,
      allowDatevDocTransfer: true
    }
  },

  mounted() {
    this.useDms = (
      (
        this.getCurrentUser().settings.tax_office_software === 'datev'
        && this.getCurrentUser().settings.datev_dms_enabled === '1'
      )
      && (
        this.getCurrentUser().permissions
        && typeof this.getCurrentUser().permissions.allow_datev_doc_transfer !== 'undefined'
        && this.getCurrentUser().permissions.allow_datev_doc_transfer === '1'
      )
    );

    this.init();
  },

  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('sidebar.document')}`
    },
  },

  methods: {
    ...mapGetters('user', ['getCurrentUser', 'getCurrentTheme']),

    init() {
      let params = {rows: 250, excludeInvitedClients: 0}

      this.clientService.listMyClients(params).then((clients) => {
        if (clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.list = clients.list.map((o) => {
            return {
              'code': o.prim_uid,
              'name': o.client_id + ' - ' + o.client_name,
              'email': o.client_user_email ? o.client_user_email : o.email
            }
          });

          this.availableClients = clients.list;
        }
      });

      this.documentService.tree({type: 'root', limit: this.rows, offset: 0}).then(({success, result}) => {
        this.totalRecords = parseInt(result.total_records);
        this.nodes = this.parseTreeData(result.tree);
        this.loading = false;
      });
    },

    handleCheck({data, uid, parent_type, parent_uid}) {
      let checked = !data.visible_portal;
      data.visible_portal = !data.visible_portal;

      this.documentService.update({
        'documentId': uid,
        'visibleInClientPortal': checked,
        'parentType': parent_type,
        'parentId': parent_uid
      }, true);
    },

    handleDocuTransferCheck({data, uid, parent_type, parent_uid}) {
      let checked = !data.dms;
      this.documentService.update({
        'documentId': uid,
        'datevDms': checked,
        'parentType': parent_type,
        'parentId': parent_uid
      }, true);
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;
      }

      this.expandedKeys = {...this.expandedKeys};
    },

    getIcon(type) {
      return this.getAssetPath(`${type}.svg`);
    },

    getTheme() {
      return this.getCurrentTheme();
    },

    parseTreeData(result, expand = false) {
      let leafs = _.values(result);

      const flattenChildrenAndAddKey = (leaf, index_outer) => {
        if (leaf.children) {
          leaf.children = _.values(leaf.children);
          leaf.children.forEach((child, index) => flattenChildrenAndAddKey(child, index_outer + '-' + index));
        }

        leaf.key = "" + index_outer;
        if (expand) {
          this.expandNode(leaf);
        }
      }

      leafs.forEach((child, index) => flattenChildrenAndAddKey(child, index));

      return leafs;
    },

    search(data = {}, expand = false) {
      this.loading = true;
      this.expandedKeys = {};

      if (!data.searchTerm) {
        data.searchTerm = this.searchTerm;
      }

      data.filterDMS = this.filterDMS;
      data = Object.assign({type: 'root', limit: this.rows, offset: 0}, data);
      this.documentService.tree(data)
        .then(({success, result}) => {
          this.totalRecords = parseInt(result.total_records)
          this.nodes = this.parseTreeData(result.tree, expand);
        })
        .catch(() => this.nodes = [])
        .finally(() => {
          this.loading = false;
        });
    },

    getDMSFilterOption() {
      return [
        {name: 'Alle', code: 0},
        {name: 'Wurde übertragen', code: 1},
        {name: 'Kann übertragen werden', code: 2},
        {name: 'Nicht übertragen', code: 3}
      ];
    },

    getEditOrViewIcon(type) {
      if (type === 'document') {
        return this.getIcon('eye_green_full');
      }

      return this.getIcon('editpen');
    },

    editOrViewNode() {
    },

    async fetchChildren(node) {
      if (node.children) {
        return false;
      }

      this.loading = true;

      let queryResult = {total: 0, leafs: []};
      let chunkNumber = 0;
      let only_documents = true;
      while (true) {
        let currentQuery = await this.documentService.tree({
          type: node.type,
          id: node.uid,
          limit: 250,
          offset: 250 * chunkNumber,
          only_documents: only_documents
        });

        if (!currentQuery.success) {
          break;
        }

        const result = Array.isArray(currentQuery.result) ? currentQuery.result : Object.values(currentQuery.result);

        chunkNumber++;
        queryResult.total += parseInt(result.length);

        queryResult.leafs = [...queryResult.leafs, ...result];

        if (result.length < 250) {
          if (only_documents && node.type !== 'declaration') {
            only_documents = false;
            chunkNumber = 0;
          } else {
            break;
          }
        }
      }

      this.loading = false;

      let nodeCopy = {...node};

      queryResult.leafs.map((item, index) => item['key'] = nodeCopy.key + '-' + index)
      nodeCopy.children = queryResult.leafs;
      let nodes = this.nodes.map(n => {
        if (n.key === node.key) {
          n = nodeCopy;
        }

        if (n.children) {
          n.children = n.children.map((child) => {
            if (child.children) {
              child.children = child.children.map((child) => {
                if (child.key === node.key) {
                  return nodeCopy;
                }

                return child
              });
            }

            if (child.key === node.key) {
              return nodeCopy;
            }

            return child
          });
        }

        return n;
      });

      this.loading = false;
      this.nodes = nodes;
    },

    onPage(event) {
      if (this.totalRecords <= this.rows) {
        return;
      }

      this.search({type: 'root', limit: this.rows, offset: event.first});
    },

    /**
     * Handles the click on an edit button in the TableTree
     * @param node
     */
    triggerEdit(node) {
      const uid = node.uid;
      const parent_uid = node.parent_uid;
      const parent_type = node.parent_type;

      let name = '';
      switch (node.type) {
        case 'document': {
          const routeData = this.$router.resolve({
            name: 'DocumentViewerNewTab',
            params: {
              'id': uid,
              'parentType': parent_type,
              'parentId': parent_uid,
            }
          });

          window.open(routeData.href, '_blank');
          break;
        }

        case 'property' : {
          name = 'Properties';
          this.$router.push({
            name,
            query: {
              'uid': uid
            },
            params: {
              'comp': 'edit',
              'client_uid': parent_uid,
              'parent_type': 'client'
            }
          });
          break
        }

        case 'declaration' : {
          name = 'Declaration';
          this.$router.push({
            name,
            query: {
              'uid': uid
            },
            params: {
              'comp': 'edit',
              'client_uid': parent_uid,
              'parent_type': 'property'
            }
          });
          break
        }

        case 'client' : {
          this.$router.push(`/client/edit?uid=${uid}`)
          break;
        }
      }
    },

    clientListSearch(params) {
      return this.clientService.listMyClients(params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              'code': o.prim_uid,
              'name': o.client_id + ' - ' + o.client_name,
              'email': o.client_user_email ? o.client_user_email : o.email
            };
          });
        } else {
          return false;
        }
      });
    },

    handleClientSearchResult({code, email, name}) {
      this.searchTerm = code;
      this.search();
    },

    handleDMSFilter({code, email, name}) {
      //This is a workaround because the function get triggerd mutiple times and will run into an endless loop
      if (this.filterDMS === code || code === '') {
        return;
      }

      this.filterDMS = code;
      this.search();
    },
  }
}
</script>

<style scoped lang="scss">
.drive_text {
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 500;
  color: theme('colors.muted_black');
  text-align: right;
  line-height: 20px;
  font-size: 15px;
  margin-left: 2px;
  margin-top: 2px;
}

.kdrive_img {
  width: 32px;
  height: 25px;
  box-sizing: border-box;

}

.edit-icon {
  cursor: pointer;
}

.model-terms {
  height: 1200px;
  width: 400px;
}

.tree_icon {
  width: 19px;
  height: 23px;
  box-sizing: border-box;
}

.document_name {
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
}


.headerClass {
  width: 1000px;
}

.p-treetable .p-treetable-thead > tr > th:nth-child(2) {
  width: 1000px;
}


.client_search {
  width: 300px;
}

.check-mark {
  width: 30px;
  height: 30px;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  margin-right: 10px;
}

.powered {
  font-size: 12px;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 500;
  color: theme('colors.muted_black');
  text-align: right;
  line-height: 20px;
}

.banner {
  width: 190px;
  height: 171px;
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 1000;
  margin-top: -40px;
}


.footerimg {
  width: 75px;
  height: 69px;
}
</style>
