<template>
  <div class="main progress-arrow">
    <div class="progress px-4" :class="customClasses">
      <div class="flex justify-center text-container ">
        <span class="text-left self-center inline-block align-middle progress-title">{{ step.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrowProgress",
  props: {
    step: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    customClasses() {
      return [
        {"active": this.step.isActive},
        {"completed": this.step.isCompleted},
        {"pending": !this.step.isCompleted && !this.step.isActive},
      ]
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.progress-arrow .progress {
  margin-bottom: 0;
  padding: 0 15px;
  min-width: 145px;
}

span.progress-title {
  font-size: 75% !important;
  margin: 0 !important;
  white-space: break-spaces;
  word-break: break-word;
  hyphens: auto;
  line-height: 1;

  .active {
    color: #BB940B;
  }
}

@media (min-width: 2900px) and (max-width: 7000px) {
  .text-left {
    margin: 10px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 15px;
  }

  span.progress-title {
    margin: 8px 25px !important;
    font-size: 20px !important;
  }
}

@media (min-width: 2500px) and (max-width: 2899px) {
  .text-left {
    margin: 15px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 15px;
  }

  span.progress-title {
    margin: 8px 25px !important;
    font-size: 20px !important;
  }
}

@media (min-width: 2300px) and (max-width: 2499px) {
  .text-left {
    margin: 15px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 15px;
  }

  span.progress-title {
    margin: 14px 5px !important;
    font-size: 20px !important;
  }
}

@media (min-width: 2000px) and (max-width: 2299px) {
  .text-left {
    margin: 15px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 15px;
  }

  span.progress-title {
    margin: 14px 5px !important;
    font-size: 20px !important;
  }
}

@media (min-width: 1900px) and (max-width: 1999px) {
  .text-left {
    margin: 10px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 15px;
  }

  span.progress-title {
    margin: 12px 5px !important;
    font-size: 20px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1899px) {
  .text-left {
    margin: 5px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 10px;
    font-size: 17px;
  }

  span.progress-title {
    margin: 2px 1px !important;
    font-size: 18px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1799px) {
  .text-left {
    margin: 1px 0px;
    width: 100%;
  }

  .progress div {
    padding-left: 5px;
  }

  span.progress-title {
    margin: 0px 0px !important;
    font-size: 15px !important;
  }
}
</style>
