<template>
  <div :class="customClass" class=" main justify-center items-center flex  cursor-pointer" @click.stop="handleClick">
    <div :class="selected?'visible':'invisible'" class="line w-3 border-l-3 mr-4 text-white "></div>
    <div class="mr-4 icondiv" :class="[iconStyle]">
      <div class="with-select-icon" v-if="withSelectedIcon">
        <img class="icon" :src="require('@/assets/check-light.svg')" v-show="showSelectedIcon"/>
      </div>
      <img class="icon" :src="require('@/assets/check-light.svg')" v-if="icon === 'selected'"/>
      <img class="icon icon-filter-input wts-filter " :class="[iconStyle, applyIconFilter ? 'icon-filter': '']" :src="icon"
           v-else-if="icon"
           v-tooltip.right="getLabel"/>
      <div class="icon" v-else/>
    </div>
    <div class="icon_text flex-auto" :style="{fontSize: fontSize + 'px' }" :class="customClass">{{ label }}</div>

    <div v-if="slug==='changelog'" class="changelog" v-show="showChangeLog">
      <div class="anchor"><!-- This is a Icon we use --></div>

    </div>
  </div>

</template>

<script>
import * as HEADWAY_WIDGET_URL from "@/scripts/headwaywidget"
import Tooltip from 'primevue/tooltip';

export default {
  name: "IconLabel",
  directives: {'tooltip': Tooltip},
  props: {
    label: {
      type: String,
      default: ""
    },
    slug: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    },
    blackText: {
      type: Boolean,
      default: false
    },
    isSubmenu: {
      type: Boolean,
      default: false
    },
    withSelectedIcon: {
      type: Boolean,
      default: false
    },
    showSelectedIcon: {
      type: Boolean,
      default: false
    },
    showChangeLog: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: '15'
    },
    isHover: {
      type: Boolean,
      default: false
    },
    iconStyle: {
      type: String,
      default: ''
    },
    applyIconFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: this.$uuid.v4(),
      widget: undefined,
      config: {
        selector: '.changelog',
        account: 'J3YaEx',
        translations: {
          title: "Letzte Aktualisierungen"
        },
        callbacks: {
          onWidgetReady: (widget) => {
            this.widget = widget
          },
          onShowWidget: () => this.$parent.$emit('close:header-items', [this.id])
        }
      },
    }
  },
  mounted() {
    if (this.label === 'Grundstücke') {
      let headwayScript = document.createElement('script')
      headwayScript.async = true
      headwayScript.setAttribute('src', HEADWAY_WIDGET_URL)
      document.head.appendChild(headwayScript)
      const waitForHeadway = () => {
        if (window['Headway']) {
          window.Headway.init(this.config)
        } else {
          setTimeout(() => waitForHeadway(), 50)
        }
      }
      waitForHeadway()
      this.$parent.$on('close:header-items', ([id]) => {
        if (this.id !== id) this.hide()
      })
    }
  },
  computed: {
    customClass() {
      return [{'isHover': this.isHover}, {'text_black_theme': this.iconStyle === 'blackish'},{'wts-filter-side-setting': this.iconStyle === 'blackish'}, {'text_black': this.blackText}, {'text_bold': this.selected}, {'submenu': this.isSubmenu}]
    },
    getLabel() {
      return this.label === '' ? {value: '', disabled: true} : this.label
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.label)

    }
  }
}
</script>

<style scoped lang="scss">

.main {
  &.isHover {
    &:hover {
      background-color: #F3F2F1;
      margin-left: 10px;
      // margin-right: -7px;
      .icondiv {
        margin-left: -10px;
        //margin-top: 5px;
        //margin-bottom: 5px;
      }
    }
  }

  .line {
    width: 3px;
    height: 25px;
    background-color: $icon-label-left-line-color;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;
  }

  .with-select-icon {
    width: 23px;
    height: 23px;
    float: left;
    margin-right: 10px;
  }
}

#HW_badge {
  background: $primary;
}

.smallIcon {
  width: 18.9px;
  height: 20.7px;
}

.text_black_theme {
  color: #404040 !important;
}

.icon-less-space .icondiv {
  margin-right: 0.5rem !important;
}


</style>
