<template>
  <div class="flex  flex-col items-center  ">
    <div class="flex items-center self-start mt-2 cursor-pointer" @click="viewClient">
      <div class="avatar-wrapper rounded-full flex items-center bg-green justify-center">
        <p class="avatar">{{ getInitials }}</p>
      </div>
      <div class="flex flex-col flex-auto ml-4 mb-1 items-center">
        <p class="name self-start">{{ item.client_name }} </p>
        <p class="sub-text self-start">{{ item.client_id }} </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getInitials() {
      if(this.item.client_name) {
        let matches = this.item.client_name.match(/\b(\w)/g);
        return matches.join('').slice(0, 2)
      } else {
        return '';
      }
    }
  },
  methods: {
    viewClient() {
      this.$router.push("/client/edit?uid=" + this.item.prim_uid)
    }
  }
}
</script>

<style scoped lang="scss">


.avatar-wrapper {
  width: 25px;
  height: 25px;
  padding: 2px 2px 2px 2px;
  background-color: $primary;
  box-sizing: border-box;
}

.avatar {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  font-size: 10px;
}

.message {
  display: block;
  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 16px;

  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.name {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.sub-text {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 3px;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: left;

  font-size: 12px;
}


.date {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
}
</style>
