import {i18n} from '@/plugins/i18n';
import _find from "lodash/find";

export const widgets = [
  {
    "x": 0,
    "y": 0,
    "w": 6,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "notification-widget",
    "comp": "NotificationWidget"
  }, {
    "x": 6,
    "y": 0,
    "w": 4,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "todo-widget",
    "comp": "ToDoWidget"
  }, {
    "x": 0,
    "y": 1,
    "w": 6,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "client-widget",
    "comp": "ListWidget"
  }, {
    "x": 0,
    "y": 1,
    "w": 6,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "view-log-widget",
    "comp": "ListWidget"
  }, {
    "x": 0,
    "y": 1,
    "w": 6,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "update-log-widget",
    "comp": "ListWidget"
  }, {
    "x": 0,
    "y": 1,
    "w": 6,
    "minW": 4,
    "h": 10,
    "minH": 10,
    "i": "deadline-widget",
    "comp": "ListWidget"
  }
]

export const widgetConfigs = {
  "notification-widget": {
    "menu": i18n.t("dashboard.widgets.notification.title"),
    "help": i18n.t("dashboard.widgets.notification.help"),
    "thumb": "s1.png"
  },
  "todo-widget": {
    "menu": i18n.t("dashboard.widgets.account_setup.title"),
    "help": i18n.t("dashboard.widgets.account_setup.help"),
    "thumb": "s2.png"
  },
  "client-widget": {
    "menu": i18n.t("dashboard.widgets.client.title"),
    "help": i18n.t("dashboard.widgets.client.help"),
    "thumb": "s3.png",
    showSearch: true,
    "title": i18n.t('dashboard.headers.clients'),
    "columns": [
      {
        field: 'client_id',
        header: i18n.t('clients.attrs.id'),
        isChecked: true,
        clickable: true
      }, {
        field: 'client_type_icon',
        header: i18n.t('clients.attrs.type'),
        isChecked: true,
        image: true,
        bodyClass: 'client_type',
        tooltip: (row) => {
          if (row.client_type) {
            let found = _find(i18n.t('clients.client_types'), {"code": row.client_type});
            if (found) {
              return found.name;
            }
          }
        }
      }, {
        field: 'client_name',
        header: i18n.t('clients.attrs.name'),
        isChecked: true,
        clickable: true
      }
    ],
    "gridId": "dashboard-client",
    "defaultSortAttr": "name",
    "defaultSortDir": 1,
    "dataEndpoint": "client/list",
    'hideForBusiness': true
  },
  "view-log-widget": {
    "menu": i18n.t("dashboard.widgets.recently_seen.title"),
    "help": i18n.t("dashboard.widgets.recently_seen.help"),
    "thumb": "s5.png",
    showSearch: false,
    "title": i18n.t('dashboard.headers.seen_recently'),
    "columns": [
      {
        field: 'recordTypeIcon',
        header: i18n.t('dashboard.table.type'),
        isChecked: true,
        sortable: false,
        image: true,
        headerClass: "log-type-icon",
        bodyClass: "log-type-icon",
        width: 35,
        tooltip: (row) => {
          return row.recordTypeTooltip;
        }
      }, {
        field: 'name',
        header: i18n.t('dashboard.table.name'),
        isChecked: true,
        sortable: false,
        width: 200,
        clickable: true
      }, {
        field: 'logTime',
        header: i18n.t('dashboard.table.date'),
        isChecked: true,
        sortable: false
      }
    ],
    "gridId": "dashboard-seen-recently",
    "defaultSortAttr": "logTime",
    "defaultSortDir": 1,
    "pageLength": 50,
    "serverParams": {
      "logType": "view"
    },
    "dataEndpoint": "dashboard/get_activity_log"
  },
  "update-log-widget":  {
    "menu": i18n.t("dashboard.widgets.recently_edit.title"),
    "help": i18n.t("dashboard.widgets.recently_edit.help"),
    "thumb": "s5.png",
    showSearch: false,
    "title": i18n.t('dashboard.headers.edited_recently'),
    "columns": [
      {
        field: 'recordTypeIcon',
        header: i18n.t('dashboard.table.type'),
        isChecked: true,
        sortable: false,
        image: true,
        width: 35,
        headerClass: "log-type-icon",
        bodyClass: "log-type-icon",
        tooltip: (row) => {
          return row.recordTypeTooltip;
        }
      }, {
        field: 'name',
        header: i18n.t('dashboard.table.name'),
        isChecked: true,
        sortable: false,
        width: 200,
        clickable: true
      }, {
        field: 'logTime',
        header: i18n.t('dashboard.table.date'),
        isChecked: true,
        sortable: false
      }
    ],
    "gridId": "dashboard-seen-recently",
    "defaultSortAttr": "logTime",
    "defaultSortDir": 1,
    "pageLength": 50,
    "serverParams": {
      "logType": "updated"
    },
    "dataEndpoint": "dashboard/get_activity_log"
  },
  "deadline-widget":  {
    "menu": i18n.t("dashboard.widgets.deadline.title"),
    "help": i18n.t("dashboard.widgets.deadline.help"),
    "thumb": "s3.png",
    showSearch: false,
    "title": i18n.t('dashboard.headers.deadline'),
    "columns": [
      {
        field: 'deadline',
        header: i18n.t('deadline.cols.deadline'),
        isChecked: true,
        width: 150
      }, {
        field: 'documentType',
        header: i18n.t('deadline.cols.document_type'),
        isChecked: true,
        width: 200
      }, {
        field: 'declarationUid',
        header: i18n.t('deadline.cols.declaration_uid'),
        isChecked: true,
        clickable: true,
        width: 200
      }, {
        field: 'clientName',
        header: i18n.t('deadline.cols.client_name'),
        isChecked: true,
        clickable: true,
        width: 250
      }, {
        field: 'propertyName',
        header: i18n.t('deadline.cols.property'),
        isChecked: true,
        clickable: true
      }, {
        field: 'statusTxt',
        header: i18n.t('deadline.cols.status'),
        isChecked: true,
        clickable: true
      }
    ],
    "gridId": "dashboard-deadline",
    "defaultSortAttr": "deadline",
    "defaultSortDir": 1,
    "pageLength": 50,
    "dataEndpoint": "deadline/list",
    "serverParams": {
      "filters": {
        'state': 'open',
        'reminderReached': 1
      }
    },
  }
};
