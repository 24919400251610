<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center" v-if="visible">
      <div class="modal modal-small" ref="draggableContainer" id="draggable-container">
        <div class="   w-full" v-on-clickaway="onClickOutside">
          <header class=" flex justify-between modal-header cursor-move " @mousedown="dragMouseDown">
            <label class="dheader cursor-move">{{ headerText }}</label>
            <img src="@/assets/close_thin.png"
                 class="btn-close cursor-pointer"
                 @click="hide"
                 v-if="showClose"
            />
          </header>
          <div class="flex flex-col justify-start ml-4 mr-3">
            <label class=" title text-left">{{ title }}</label>
            <label class=" dtext text-left html-message" v-if="textHtml !== ''" v-html="textHtml"/>
            <label class=" dtext text-left" v-else>{{ text }}</label>
            <div class="flex justify-around space-x-10 mt-8 ">
              <div class="flex mt-10 mb-4 justify-end w-full space-x-4" v-if="inverseBtnColor">
                <Button @click="cancel" v-show="showCancel" :text="buttons.cancel" :delete="buttons.delete" ></Button>
                <Button @click="confirm" class="  " :text="buttons.ok"  :secondary="true" />
              </div>
              <div class="flex mt-10 mb-4 justify-end w-full space-x-4" v-else>
                <Button @click="cancel" v-show="showCancel" :text="buttons.cancel" :secondary="true"></Button>
                <Button @click="confirm" class="  " :text="buttons.ok" :delete="buttons.delete"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>


<script>
// we must import our Modal plugin instance
// because it contains reference to our Eventbus
import {Modal} from '@/plugins/modal-plugin';
import {directive} from "vue-clickaway";

export default {
  directives: {
    onClickaway: directive,
  },
  data() {
    return {
      visible: false,
      title: '',
      text: '',
      textHtml: '',
      showClose: true,
      headerText: 'Grundstück löschen',
      canEscape: true,
      canCloseOutsideClick: true,
      showCancel: true,
      buttons: {
        ok: this.$t('buttons.delete'),
        cancel: this.$t('buttons.cancel'),
        delete: true
      },
      // adding callback function variable
      onConfirm: {},
      onCancel: {},
      onHide: {},
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      clickedOutSideCount: 0,
      inverseBtnColor: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    onClickOutside() {
      // this.clickedOutSideCount++;
      // if (this.clickedOutSideCount > 1) {
      if (this.canCloseOutsideClick) {
        this.visible = false;
      }
      // }

    },
    hide() {
      if (typeof this.onHide === 'function') {
        this.onHide();
        this.visible = false;
      } else {
        this.visible = false;
      }
    },
    cancel() {
      if (typeof this.onCancel === 'function') {
        this.onCancel();
        this.visible = false;
      } else {
        this.visible = false;
      }
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm();
        this.visible = false;
      } else {
        // we only close the modal
        this.visible = false;
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      this.title = params.title;
      this.text = params.text ? params.text : '';
      this.textHtml = params.textHtml ? params.textHtml : '';
      this.showClose = params.showClose
      this.headerText = params.headerText
      this.canEscape = params.canEscape
      this.canCloseOutsideClick = params.canCloseOutsideClick
      this.inverseBtnColor = typeof params.inverseBtnColor !== 'undefined' ? params.inverseBtnColor : false;
      this.buttons = {
        ok: this.$t('buttons.delete'),
        cancel: this.$t('buttons.cancel'),
        delete: true
      }

      if(typeof(params.buttons) !== 'undefined') {
        if(typeof(params.buttons.ok) !== 'undefined') {
          this.buttons.ok = params.buttons.ok;
        }
        if(typeof(params.buttons.cancel) !== 'undefined') {
          this.buttons.cancel = params.buttons.cancel;
        }
        if(typeof(params.buttons.delete) !== 'undefined') {
          this.buttons.delete = params.buttons.delete;
        }
      }

      if(typeof(params.showCancel) !== 'undefined') {
        this.showCancel = params.showCancel;
      }

      // setting callback function
      this.onConfirm = params.onConfirm;
      this.onCancel = params.onCancel ? params.onCancel : '';
      this.onHide = params.onHide ? params.onHide : '';
    }
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Modal.EventBus.$on('show', (params) => {

      this.show(params)
    })
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.visible = false;
        }

      }
    });
  },
}
</script>

<style scoped lang="scss">
.title {
  font-size: 15px;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

}

.dtext {
  font-size: 15px;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
}

.modal-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.modal-button {
  flex-grow: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 1001;
}

.modal {
  width: 500px;
  background: white;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;


}


.modal-header {

  color: #333333;

}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;

}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

#draggable-container {
  position: absolute;
  z-index: 9;
}

#draggable-header {
  z-index: 10;
}

.dheader {
  max-width: 90%;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}
.html-message {
  line-height: 30px;
}
</style>
