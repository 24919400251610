<template>
  <transition name="modal-fade">
    <div class="modal-backdrop flex justify-center items-center"  :class="backdropClass">
      <div class="modal" ref="draggableContainer" id="draggable-container" :class="getModalClass">
        <div class="w-full " v-on-clickaway="onClickOutside" style="display:grid">
          <header class="px-4 flex justify-between items-center modal-header cursor-move sticky-header "
                  @mousedown="dragMouseDown">
            <label class="dheader cursor-move">{{ headerText }}</label>
            <img src="@/assets/close_thin.png"
                 class="btn-close cursor-pointer"
                 @click="close"
                 v-if="showClose"
            />
          </header>
          <div class="modal-dialog-body px-4">
            <slot name="top">
            </slot>
          </div>
          <div class="modal-dialog-body px-4">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-dialog-footer mr-4">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import {directive as onClickaway} from "vue-clickaway";

export default {
  name: 'ModalDialog',
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    headerText: {
      type: String,
      default: ""
    },
    backdropClass: {
      type: String,
      default: ""
    },
    canEscape: {
      type: Boolean,
      default: true
    },
    canCloseOutsideClick: {
      type: Boolean,
      default: false
    },
    modalSize: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      clickedOutSideCount: 0,
    }
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.close()
        }

      }
    });
  },
  computed: {
    getModalClass() {
      if (this.modalSize === 'full-screen') {
        return 'modal-full-screen';
      } else if (this.modalSize === 'x-large') {
        return 'modal-x-large';
      } else if (this.modalSize === 'large') {
        return 'modal-large';
      } else if (this.modalSize === 'small') {
        return 'modal-small';
      } else {
        return '';
      }
    }
  },
  methods: {
    close() {

      this.$emit('close');

    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {

      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    onClickOutside() {

      // this.clickedOutSideCount++;
      // if (this.clickedOutSideCount > 1) {
      if (this.canCloseOutsideClick) {
        this.close()
      }
      // }


    }

  },
};
</script>


<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: space-between;
  z-index: 2;
}

.modal {
  max-width: 661px;
  width: 661px;
  max-height: calc(100vh - 150px);
  background: white;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-header,
.modal-footer {
  padding-top: 20px;
  padding-botton: 20px;
  display: flex;
}


.modal-header {

  color: #333333;

}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  //position: absolute;
  //top: 0;
  //right: 0;
  //border: none;
  //font-size: 40px;
  //padding: 10px;
  //cursor: pointer;
  //font-weight: bold;
  //color: #333333;
  //background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

#draggable-container {
  position: absolute;
  z-index: 9;
  overflow-y: unset;
}

#draggable-header {
  z-index: 10;
}

.dheader {

    //padding: 0 20px;
    max-width: 90%;
    font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 700;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 25px;
    font-size: 20px;
}

.land-modal .modal {
  overflow-x: auto;
}

.sticky-header {
  top: 0;
  background: #fff;
  z-index:100;
  margin-bottom: 21px;
}
.modal-dialog-footer {
  position: sticky;
  bottom: 0px;
  background: #fff;
  z-index: 100;
}
.modal-dialog-body {
  overflow-y: scroll;
}
</style>
