<template>
  <MainLayout value="user">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>

</template>


<script>
import add from "@/components/user/AddUser";
import edit from "@/components/user/EditUser";

export default {
  name: "user",
  components: {add, edit},
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('user_management');
  },
  methods: {
    handleComponentClick() {

    }
  }
}
</script>

<style scoped lang="scss">

</style>
