import {ApiService} from "@/services/api.service";

const state = {
  recordType: "",
  recordId: 0,
  recordTimestamp: 0,
  declarationStatus: '',
  reloadRecord: false,
  changeInfo: {},
  timer: null
};

const getters = {
  getRecordUpdatedTime: (state) => state.recordTimestamp,
  getRecordChangeInfo: (state) => state.changeInfo,
  triggerRefreshRecord: (state) => state.reloadRecord,
  getRecordType: (state) => state.recordType,
  getDeclarationStatus: (state) => state.declarationStatus
}

const mutations = {
  start: (state, payload) => {
    state.recordType = payload.recordType ? payload.recordType : '';
    state.recordId = payload.recordId ? parseInt(payload.recordId) : 0;
    state.recordTimestamp = payload.recordTimestamp ? parseInt(payload.recordTimestamp) : 0;
    state.declarationStatus = payload.declarationStatus ? payload.declarationStatus : '';
  },
  stop: (state) => {
    state.recordType = '';
    state.recordId = 0;
    state.recordTimestamp = 0;
    state.declarationStatus = '';
    state.changeInfo = {}
    state.reloadRecord = false;
    clearTimeout(state.timer);
    state.timer = null;
  },
  update: (state, payload) => {
    state.reloadRecord = false;
    state.changeInfo = payload.changeInfo ? payload.changeInfo : {};
    state.recordTimestamp = payload.recordTimestamp ? parseInt(payload.recordTimestamp) : 0;
  },
  refreshRecord: (state) => {
    state.reloadRecord = true;
  },
  setTimer: (state, timer) => {
    state.timer = timer;
  }
};

const actions = {
  startObserver({commit, dispatch}, payload) {
    commit("stop");
    commit("start", payload);
    dispatch("trigger");
  },
  stopObserver({commit}) {
    commit("stop");
  },
  trigger({commit, dispatch}) {
    if(state.recordType !== '' && state.recordId > 0) {
      let api = new ApiService();
      let endpoint = '';
      if(state.recordType === 'clients') {
        endpoint = 'client/';
      } else if(state.recordType === 'properties') {
        endpoint = 'property/';
      } else if(state.recordType === 'declarations') {
        endpoint = 'declaration/';
      }
      api.trigger(endpoint + 'data_changes', {
        "parentType": state.recordType,
        "parentId": state.recordId,
        "recordTimestamp": state.recordTimestamp
      }).then((res) => {
        if(res.data.success && res.data.modifiedBy) {
          let changeInfo = {
            "userName": res.data.modifiedBy,
            "updateTime": res.data.modifiedAt
          }
          if(res.data.status) {
            changeInfo.status = res.data.status;
          }
          if(res.data.clientApprovalStatus) {
            changeInfo.clientApprovalStatus = res.data.clientApprovalStatus;
          }
          commit('update', {
            "recordTimestamp": res.data.recordTimestamp,
            "changeInfo": changeInfo
          });
        }
      })

      let timer = setTimeout(() => {
        dispatch("trigger");
      }, 60000);
      commit("setTimer", timer);
    }
  }
};


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
