<template>
  <div class="ml-15 mr-15 space-y-5 mb-10">
    <div v-show="$isBusiness" class="pr-6 space-y-4">

      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label1')" placeholder=""/>
        <InputSelect class="w-full " :label="$t('components.report_steps.step2.label2')" :options="reportType"
                     :placeholder="$t('general.please_select')"/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label3')"
                     :placeholder="$t('general.please_select')"/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label4')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label5')" placeholder=""/>
        <InputDate class="w-full " :label="$t('components.report_steps.step2.label6')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label7')" placeholder=""/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label8')"

                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label9')"
                     :placeholder="$t('general.please_select')"/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label10')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label11')"
                     :placeholder="$t('general.please_select')"/>
        <InputSelect class="w-full " :label="$t('components.report_steps.step2.label12')" :options="reportType"
                     :placeholder="$t('general.please_select')"/>

      </div>
      <!--    <div class="flex justify-around space-x-8">-->
      <!--      <InputSelect class="w-full" :label="$t('components.report_steps.step2.label13')" placeholder=""/>-->
      <!--      <div class="w-full pr-6 invisible"/>-->

      <!--    </div>-->
    </div>
    <div v-show="!$isBusiness" class=" pr-6 space-y-4">

      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label1')" placeholder=""/>
        <InputSelect class="w-full " :label="$t('components.report_steps.step2.type')" :options="reportType"
                     :placeholder="$t('general.please_select')"/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label3')"
                     :placeholder="$t('general.please_select')"/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label2')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label5')" placeholder=""/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label4')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputText class="w-full" :label="$t('components.report_steps.step2.label7')" placeholder=""/>
        <InputDate class="w-full " :label="$t('components.report_steps.step2.label6')"
                   placeholder=""/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label9')"
                     :placeholder="$t('general.please_select')"/>
        <InputText class="w-full " :label="$t('components.report_steps.step2.label8')"
        />

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label11')"
                     :placeholder="$t('general.please_select')"/>
        <InputSelect class="w-full " :label="$t('components.report_steps.step2.label10')" :options="reportType"
                     :placeholder="$t('general.please_select')"/>

      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full" :label="$t('components.report_steps.step2.label12')"
                     :placeholder="$t('general.please_select')"/>
        <div class="w-full  invisible"/>

      </div>
    </div>


    <div class="flex mt-10 mb-10 pr-6 justify-end space-x-4">
      <Button class="w-40" :text="$t('register.back')" :secondary="true" @click="goBack"/>
      <Button class="w-40" :text="$t('register.next')" @click="submitNext"/>
    </div>


  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import InputSelect from "../../inputs/InputSelect";


export default {
  name: "Step2",
  components: {InputSelect},
  data() {
    return {
      combination: [
        {
          "label": "Und",
          "value": "1"
        },
      ],
      condition: this.$t('components.report_create.list3'),
      step2: {
        company: '',
        street: '',
        zip: '',
        city: '',
        country: '',
      },
      isCompanyError: false,
      isStreetError: false,
      isZipError: false,
      isCityError: false,
      isCountryError: false,

      companyError: '',
      streetError: '',
      zipError: '',
      cityError: '',
      countryError: '',

    }
  },
  validations: {
    step2: {
      company: {
        required
      },
      street: {
        required
      },
      zip: {
        required
      },
      city: {
        required
      },
      country: {
        required
      }
    },


  },
  computed: {
    ...mapGetters("report", ["getUserData"]),
  },
  mounted() {
    this.step2.company = this.getUserData.company
    this.step2.street = this.getUserData.street
    this.step2.zip = this.getUserData.zip
    this.step2.city = this.getUserData.city
    this.step2.country = this.getUserData.country
  },
  methods: {
    ...mapMutations("report", ["setSecondStepData"]),
    submitNext() {
      // this.$v.$touch() // we check the form to see if it's valid
      // if (this.$v.$invalid) {
      //   this.isCompanyError = this.$v.step2.company.$error
      //   this.companyError = this.$v.step2.company.$error ? this.$t('register.step2.companyError') : ""
      //
      //   this.isStreetError = this.$v.step2.street.$error
      //   this.streetError = this.$v.step2.street.$error ? this.$t('register.step2.streetError') : ""
      //
      //   this.isZipError = this.$v.step2.zip.$error
      //   this.zipError = this.$v.step2.zip.$error ? this.$t('register.step2.zipError') : ""
      //
      //   this.isCityError = this.$v.step2.city.$error
      //   this.cityError = this.$v.step2.city.$error ? this.$t('register.step2.cityError') : ""
      //
      //   this.isCountryError = this.$v.step2.country.$error
      //   this.countryError = this.$v.step2.country.$error ? this.$t('register.step2.countryError') : ""
      //
      //   return; // if any of the validations fail, we don't move on
      // }
      this.setSecondStepData(this.step2)
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">


th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.delete {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.header {
  height: 45px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;

}

.items {
  height: 45px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;

}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}
</style>
