<template>
  <div class="h-full bg-white pl-7 w-full ">
    <ModalDialog v-show="showManualAssignmentDialog" :headerText="$t('bulk_notice.manual_assignment.title')"
                 @close="closeManualAssignmentDialog">
      <template v-slot:body>
        <div class="flex flex-col">
          <label class="label text-left">{{ $t('bulk_notice.manual_assignment.info') }}</label>
        </div>
        <div class="flex flex-col mt-5">
          <InputSelect v-if="showDocumentTypeSelect" :options="$t('deadline.document_types')"
                       v-model="manualAssignment.documentType"
                       :label="$t('deadline.attrs.document_type')"
                       :isRequired="true"
                       :selectedValue="manualAssignment.documentType"
                       overlayAppendTo="body"
                       class="mb-5"
          />
          <InputSelect :options="clientList"
                       v-model="manualAssignment.client"
                       :label="$t('declarations.add_declaration.steps.step1.client')"
                       :isRequired="true"
                       :loading="loadingClient"
                       :search-fn="clientListSearch"
                       :total-record-count="totalClientsCount"
                       :selectedValue="manualAssignment.client"
                       :filterable="true"
                       :sort="true"
                       overlayAppendTo="body"
                       @on-item-selected="clientChanged"
          />
        </div>
        <div v-show="parseInt(manualAssignment.client) > 0">
          <div class="flex flex-col mt-5">
            <label class="label text-left">{{ $t('bulk_notice.manual_assignment.info2') }}</label>
          </div>
          <div class="flex items-center justify-between search mt-2">
            <img :src="getAssetPath('search-green.svg')" class="image-filter-input-search">
            <form autocomplete="off" @submit.prevent="findDeclaration" class="w-full">
              <input class="search-input focus:outline-none"
                     autocomplete="off" type="search"
                     v-model="manualAssignment.searchTxt" :placeholder="$t('general.grid.search')"
                     @keyup="fetchDeclarations"
              />
            </form>
          </div>
          <div class="flex flex-col mt-5 search-result overflow-y-scroll relative">
            <div class="spin-container" v-show="searchingDeclaration">
              <div class="spin"></div>
            </div>
            <div v-show="!searchingDeclaration && filteredDeclarations.length > 0">
              <div class="flex justify-start items-center mb-5" v-for="declaration in filteredDeclarations" :key="declaration.id">
                <RadioButton v-model="manualAssignment.declarationId" name="radio-button" :value="declaration.id"/>
                <span class="ml-4 textradio cursor-pointer" @click="manualAssignment.declarationId=declaration.id">{{ declaration.uid }}, {{ declaration.name }}</span>
              </div>
            </div>
        </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
          <Button class="" :text="$t('buttons.back')" :secondary="true" @click="closeManualAssignmentDialog"/>
          <Button class="" :text="$t('buttons.next')" :disabled="parseInt(manualAssignment.declarationId) === 0 || manualAssignment.documentType === ''" @click="doManualAssignment"/>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="documentViewer.show" @close="closeDocumentViewerDialog"
                 class="document-review-dialog"
                 :headerText="documentViewer.documentName">
      <template v-slot:body>
        <div class="flex flex-col w-full">
          <vue-pdf-embed :source="sourceObjectForPdfjs(documentViewer.fileSource)" />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
          <Button :secondary="true" v-show="documentViewer.documentId && parseInt(documentViewer.documentId) > 0" @click="downloadDocument"  :text="$t('documents.download')"></Button>
          <Button :secondary="true" :text="$t('buttons.cancel')" @click="closeDocumentViewerDialog"/>
        </div>
      </template>
    </ModalDialog>
    <PageHeader :title="$t('sidebar.document')" :breadcrumbs="breadcrumbs"/>
    <div class="mr-7 mt-5 text-left">
      <div class="flex flex-col justify-start mt-5">
        <div class="info flex justify-start items-center">
          <img class="info__icon" src="@/assets/info_gray.svg"/>
          <label class="info__text" v-html="$t('bulk_notice.upload.info')"/>
        </div>
      </div>
      <div class="flex items-center">
        <img :src="getAssetPath('upload.svg')" class="icon mr-2">
        <label>{{ $t('sidebar.document_sub.upload_notice') }}</label>
      </div>

      <!-- Drag and Drop-->
      <div class="mt-3" v-show="step === 1">
        <div class="upload flex-col flex justify-center items-center" v-cloak @drop.prevent="fileSelect" @dragover.prevent>
          <img class="icon-upload flex" :src="getAssetPath('docs_gray.svg')"/>
          <label class="label w-1/2 mt-5">{{ $t('bulk_notice.help1') }}</label>
          <input ref="fileInput" type="file" class="hidden" :multiple="true" accept=".pdf" @change="fileSelect"/>
          <Button class="mt-5" :text="$t('bulk_notice.choose_files')" @click="choose"/>
        </div>
      </div>

      <div v-show="step === 2">
        <div class="upload-res flex flex-col table-container">
          <table class="filelist bg-white divide-y divide-gray">
            <tr v-for="(document,index) in documents" :key="index" :class="document.status.toLowerCase()">
              <td class="filename">{{ document.name }}</td>
              <td class="doc_type">{{ document.documentType }}</td>
              <td class="doc_size">({{ document.size | formatBytes(byteFormatUnit, byteFormatDecimals) }})</td>
              <td class="icon">
                <img class="cursor-pointer" :src="getAssetPath('upload_doc.svg')" @click="openDocumentViewer(document)">
                <div v-show="document.declarationId">
                  <router-link class="green" :to="'/declaration/edit?uid='+document.declarationId" target="_blank"><img class="cursor-pointer" :src="getAssetPath('edit_property.svg')"></router-link>
                </div>
              </td>
              <td>{{ document.declarationTitle }}</td>
              <td class="status">
                <label class="relative pl-10">
                  <div class="spin-container" v-show="document.status === 'OCR_PENDING'">
                    <div class="spin"></div>
                  </div>
                  <img :src="getAssetPath('circle_tick.svg')" class="icon" v-if="document.status === 'DOC_LINKED'">
                  <img :src="getAssetPath('warning.svg')" class="icon" v-else-if="warningIconStatus.includes(document.status)">
                  {{ document.statusTxt }}
                </label>
                <label class="cursor-pointer text-green underline" v-show="document.status === 'DECLARATION_NOT_FOUND'" @click="openManualAssignmentDialog(document)">{{ $t('bulk_notice.manual_assignment.assign') }}</label>
              </td>
            </tr>
          </table>
        </div>
        <div class="flex mt-5 justify-end space-x-6 footer">
          <Button class="w-56" :secondary="true" :text="$t('buttons.back')" @click="cancelUpload"/>
          <Button class="w-56" :text="$t('buttons.next')" :disabled="disableNext" @click="finishUpload"/>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center mt-16 w-full" v-show="step === 3">
        <div class="flex flex-col justify-center items-center w-1/2">
          <div class="finish-check-icon-wrapper">
            <img :src="getAssetPath('check_done.svg')"/>
          </div>
          <label class="mt-10 text_bold ">{{ $t('bulk_notice.success') }}</label>
          <label class="mt-5 text-center pl-10 pr-10 ">{{ $t('bulk_notice.success_info') }}</label>
          <div class="flex mt-10 space-x-6">
            <Button class="w-64" :secondary="true" :text="$t('bulk_notice.check_notice_manually')" @click="manualOverview" />
            <Button class="w-64" :text="$t('bulk_notice.check_notice_auto')" @click="autoOverview"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {uuid} from "vue-uuid";
import {ClientService} from "@/services/client.service";
import RadioButton from 'primevue/radiobutton';
import {DocumentService} from "@/services/document.service";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";

export default {
  name: "uploadNotice",
  components: {RadioButton, VuePdfEmbed},
  mixins: [pdfjsCVEMixin],
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {
      clientService: new ClientService(),
      documentService: new DocumentService(),
      breadcrumbs: [
        {
          title: this.$t('sidebar.document'),
          link: '/documents/list'
        }, {
          title: this.$t('sidebar.document_sub.upload_notice'),
          link: ''
        }
      ],
      showManualAssignmentDialog: false,
      byteFormatUnit: 'kb',
      byteFormatDecimals: 0,
      step: 1,
      maxFileSize: 20971520, // 20MB
      fileLimit: 100,
      documents: [],
      files: [],
      disableNext: true,
      manualAssignment: {
        "client": 0,
        "declarationId": 0,
        "noticeId": 0,
        "uuid": '',
        "searchTxt": '',
        "documentType": ''
      },
      showDocumentTypeSelect: false,
      clientList: [],
      loadingClient: false,
      totalClientsCount: 0,
      searchingDeclaration: false,
      filteredDeclarations: [],
      clientDeclarations: [],
      warningIconStatus: ['DUPLICATE', 'INVALID_DOCUMENT', 'UNKNOWN'],
      documentViewer: {
        show: false,
        documentName: '',
        fileSource: '',
        dataUrl: '',
        declarationId: 0,
        documentId: 0
      }
    }
  },
  filters: {
    formatBytes: function (bytes, to, decimals) {
      const byteBase = 1000;

      var regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?');

      if (to === "kb") {
        return (bytes / byteBase).toString().replace('.', ',').match(regex)[0] + "kB";
      } else if (to === "mb") {
        return (bytes / byteBase ** 2).toString().replace('.', ',').match(regex)[0] + "MB";
      }  else {
        return bytes;
      }
    }
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('sidebar.document')}`
    },
  },
  mounted() {
    this.fetchClient();
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    fetchClient() {
      this.loadingClient = true;
      this.clientService.listUserClient('current', {
        "rows": 100
      }).then((clients) => {
        if(clients && clients.list) {
          this.totalClientsCount = clients.totalRecordCount;
          this.clientList = clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid
            }
          });
          this.loadingClient = false;
        }
      })
    },
    clientListSearch(params) {
      params.rows = 100;
      return this.clientService.listUserClient('current', params).then((clients) => {
        if(clients && clients.list) {
          return clients.list.map((list) => {
            return {
              "name": list.client_id + ' - ' + list.client_name,
              "code": list.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    openManualAssignmentDialog(document) {
      this.manualAssignment = {
        "client": 0,
        "declarationId": 0,
        "noticeId": document.noticeId,
        "uuid": document.uuid,
        "searchTxt": '',
        "documentType": document.documentType
      }
      this.showDocumentTypeSelect = document.documentType === '';
      this.showManualAssignmentDialog = true;
    },
    choose() {
      this.files = [];
      this.documents = [];
      this.$refs.fileInput.click();
    },
    fileSelect(event) {
      let files = '';
      files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      if (files.length > 0) {
        if (files.length > this.fileLimit) {
          this.$api.showToast(this.$t('general.upload_file_limit_error').replace('%fileLimit%', this.fileLimit), 'error');
          return false;
        }

        // Validate files
        for (let file of files) {
          let fext = file.name.substr((file.name.lastIndexOf('.') + 1)).toLowerCase();
          if(fext !== 'pdf') {
            this.$api.showToast(this.$t('general.file_type_error', {
              "allowed_types": 'pdf'
            }), 'error');
            return false;
          }

          if (file.size > this.maxFileSize) {
            this.$api.showToast(this.$t('general.fileTooBig').replace('%fileSizeLimit%', this.$options.filters.formatBytes(this.maxFileSize, this.byteFormatUnit, this.byteFormatDecimals)), 'error');
            return false;
          }

          file.uuid = uuid.v1();
          this.files.push(file);
          this.documents.push({
            "uuid": file.uuid,
            "name": file.name,
            "size": file.size,
            "documentType": "",
            "declarationTitle": "",
            "declarationId": 0,
            "documentId": 0,
            "noticeId": 0,
            "ocrTaxValue": 0,
            "ocrDocumentDate": 0,
            "statusTxt": this.$t('bulk_notice.status.OCR_PENDING'),
            "status": 'OCR_PENDING'
          });
        }

        this.$refs.fileInput.value='';

        if(this.files.length > 0) {
          this.step = 2;
          this.$nextTick(() => this.uploadFiles());
        }
      }
    },

    finishUpload() {
      this.step = 3;
      this.$nextTick(() => {
        this.documents = [];
        this.files = [];
      });
    },
    cancelUpload() {
      this.documents = [];
      this.files = [];
      this.step = 1;
    },
    async uploadFiles() {
      this.disableNext = true;
      let promises = [];
      let counter = 0;
      for (let file of this.files) {
        counter++;
        let formData = new FormData();
        formData.append('file', file);
        formData.append('fileUuid', file.uuid)
        promises.push(this.$api.trigger('notice/upload', formData, false, 'multipart/form-data'));
        if(counter >= 15) {
          await Promise.all(promises).then((responses) => {
            for (let response of responses) {
              this.processNoticeUploadResponse(response);
            }
          });
          counter = 0;
          promises = [];
        }
      }
      if(promises.length > 0) {
        Promise.all(promises).then((responses) => {
          for (let response of responses) {
            this.processNoticeUploadResponse(response);
          }
        });
      }
      this.disableNext = false;
    },
    processNoticeUploadResponse(response) {
      if(response.data.fileUuid) {
        let index = this.documents.findIndex(o => o.uuid === response.data.fileUuid);
        if(index >= 0) {
          if(response.data.noticeId) {
            this.documents[index].noticeId = response.data.noticeId
          }
          if(response.data.documentId) {
            this.documents[index].documentId = response.data.documentId
          }
          if(response.data.declarationId > 0) {
            this.documents[index].declarationId = response.data.declarationId
          }
          if(response.data.documentType) {
            this.documents[index].documentType = response.data.documentType
          }
          if(response.data.declarationTitle) {
            this.documents[index].declarationTitle = response.data.declarationTitle
          }
          if(response.data.declarationId && parseInt(response.data.declarationId) > 0) {
            this.documents[index].status = 'DOC_LINKED';
          } else if(response.data.success) {
            this.documents[index].status = 'DECLARATION_NOT_FOUND';
          } else if(response.data.error_key) {
            if(response.data.error_key === 'duplicateDocument') {
              this.documents[index].status = 'DUPLICATE';
            } else if(response.data.error_key === 'invalidDocument') {
              this.documents[index].status = 'INVALID_DOCUMENT';
            } else {
              this.documents[index].status = 'UNKNOWN';
              this.documents[index].statusTxt = response.data.message ? response.data.message : this.$t('general.errors.unknownError');
            }
          }

          if(this.documents[index].status !== 'UNKNOWN') {
            this.documents[index].statusTxt = this.$t('bulk_notice.status.' + this.documents[index].status);
          }
        }
      }
    },
    fetchDeclarations() {
      if(this.manualAssignment.client && parseInt(this.manualAssignment.client) > 0) {
        if(!this.searchingDeclaration && this.clientDeclarations.length === 0) {
          this.searchingDeclaration = true;
          this.filteredDeclarations = [];
          this.clientDeclarations = [];
          this.$api.trigger('notice/searchDeclaration', {
            "clientId": this.manualAssignment.client
          }).then((response) => {
            this.searchingDeclaration = false;
            if (response.data.success && response.data.declarations) {
              this.filteredDeclarations = this.clientDeclarations = this.$api.parse_object_data(response.data.declarations);
              this.findDeclaration();
            }
          });
        } else {
          this.findDeclaration();
        }
      }
    },
    clientChanged() {
      this.clientDeclarations = [];
      this.filteredDeclarations = [];
      this.fetchDeclarations();
    },
    findDeclaration() {
      if(this.manualAssignment.client && parseInt(this.manualAssignment.client) > 0 && this.clientDeclarations.length > 0) {
        if(this.manualAssignment.searchTxt.trim() !== '') {
          let searchTxt = this.manualAssignment.searchTxt.trim();
          let searchTxtLower = searchTxt.toLowerCase();
          this.filteredDeclarations = this.clientDeclarations.filter((o) => {
            return o.declarationNumber.toLowerCase().indexOf(searchTxtLower) >= 0 || o.name.toLowerCase().indexOf(searchTxtLower) >= 0 || searchTxtLower == o.uid.toLowerCase() || searchTxt == o.uidInt;
          });
        } else {
          this.filteredDeclarations = this.clientDeclarations;
        }
      }
    },
    closeManualAssignmentDialog() {
      this.showManualAssignmentDialog = false;
    },
    doManualAssignment() {
      if(this.manualAssignment.declarationId && parseInt(this.manualAssignment.declarationId) > 0 && this.manualAssignment.documentType) {
        let index = this.files.findIndex(o => o.uuid === this.manualAssignment.uuid);
        if(index >= 0) {
          let formData = new FormData();
          formData.append('file', this.files[index]);
          formData.append('fileUuid', this.files[index].uuid);
          formData.append('noticeId', this.manualAssignment.noticeId);
          formData.append('declarationId', this.manualAssignment.declarationId);
          formData.append('documentType', this.manualAssignment.documentType);
          this.$api.trigger('notice/upload', formData, true, 'multipart/form-data').then((response) => {
            this.processNoticeUploadResponse(response);
            this.showManualAssignmentDialog = false;
          });
        }
      }
    },
    manualOverview() {
      this.$router.push('/declaration/notificationreceived');
    },
    autoOverview() {
      this.$router.push('/documents/review_notice');
    },
    closeDocumentViewerDialog() {
      this.documentViewer = {
        show: false,
        documentName: '',
        fileSource: '',
        dataUrl: '',
        declarationId: 0,
        documentId: 0
      }
    },
    downloadDocument() {
      if(this.documentViewer.documentId && parseInt(this.documentViewer.documentId) > 0 && this.documentViewer.declarationId && parseInt(this.documentViewer.declarationId) > 0) {
        this.documentService.setDefaultParams({
          "parentType": 'declaration',
          "parentId": this.documentViewer.declarationId
        });
        this.documentService.download(this.documentViewer.documentId);
      }
    },
    openDocumentViewer(document) {
      if(document.documentId && parseInt(document.documentId) > 0 && document.declarationId && parseInt(document.declarationId) > 0) {
        this.documentService.setDefaultParams({
          "parentType": 'declaration',
          "parentId": document.declarationId
        });

        // fetch document
        this.documentService.get(document.documentId, true).then((doc) => {
          if (doc.mime_type) {
            this.documentViewer.documentName = doc.filename;
            this.documentViewer.fileSource = "data:" + doc.mime_type + ";base64," + doc.content;
            this.documentViewer.show = true;
            this.documentViewer.documentId = document.documentId;
            this.documentViewer.declarationId = document.declarationId;
          }
        });
      } else {
        // find document
        let index = this.files.findIndex(o => o.uuid === document.uuid);
        if(index >= 0) {
          let reader = new FileReader();
          reader.readAsDataURL(this.files[index]);
          reader.onload = () => {
            this.documentViewer.documentName = document.name;
            this.documentViewer.dataUrl = reader.result;
            this.documentViewer.fileSource = reader.result;
            this.documentViewer.show = true;
          };
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-res {
  padding: 2px 2px 2px 2px;
  border: 2px dashed #c4c4c4;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 20px;

  table.filelist {
    border-spacing: 0 10px;
    border-collapse: separate !important;
    font-family: "Segoe UI", "Segoe UI Regular", sans-serif;

    tr {
      background-color: #f9f9f9;
    }
    tr.doc_linked {
      background-color: #eeffef;
    }
    tr.duplicate, tr.invalid_document, tr.unknown {
      background-color: #fff8f1;
    }
    tr td.status {
      text-align: right;
      img.icon {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 15px;
        top: 5px;
      }
    }
    tr td.filename {
      width: 10%;
      text-overflow: ellipsis;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
    }
    tr td.doc_type {
      width: 10%;
    }
    tr td.doc_size {
      width: 5%;
    }
    tr td.icon {
      width: 50px;
      padding-top: 15px;
      img, a {
        width: 16px;
        height: 16px;
        float: left;
      }
      img:first-child {
        margin-right: 5px;
      }
    }
    .spin-container {
      position: absolute;
      top: 50%;
      left: 10px;
      z-index: 9999;

      .spin::before {
        border: solid 2px transparent;
        border-left-color: $primary;
        border-bottom-color: $primary;
        height: 20px;
        width: 20px;
      }
    }
  }
}
.upload {
  height: 500px;
  padding: 2px 2px 2px 2px;
  border: 2px dashed #c4c4c4;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 20px;

  .icon-upload {
    width: 106px;
    height: 106px;
  }
}
.table-container {
  padding: 0 10px 10px;
  tr td {
    padding: 10px 5px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  tr td:last-child {
    border-right: 1px solid #dddddd;
  }
  tr td:first-child {
    border-left: 1px solid #dddddd;
  }
}
.footer {
  padding-bottom: 10px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
  padding-top: 10px;
}

.search {
  @include segoe-regular;
  height: 34px;
  border: 1px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
    background-image: none
  }

  &:hover {
    background-image: none
  }
}
.search:focus-within {
  border: 2px solid $primary;
}
.search-input {
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-left: 5px;
  font-size: 13px;
}
.search-result {
  min-height: 200px;
  padding-bottom: 10px;

  .spin-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
  }
}
.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}
</style>
