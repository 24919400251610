<template>
  <div class="">
    <div class="w-full h-full mt-4 mr-8 pb-8 ">
      <div>
        <div class="flex justify-around space-x-8 mt-3 ">
          <div class=" flex mt-2 w-full">
            <div class="flex flex-col justify-start items-start">
              <InputCheck
                  :text="$t('clients.attrs.client_portal_access')"
                  :isChecked="step4.client_portal_access"
                  @check="portalAccess"/>
            </div>
            <div class="flex justify-end">
              <VDropdown>
                <!-- This will be the popover target (for the events and position) -->
                <img class="portal_access_help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <!-- This will be the content of the popover -->
                <template #popper>
                  <div class="tooltip-content">
                    <div class="space-y-4">
                      <p class=" popover-text" v-for="text in $t('clients.client_portal.client_portal_access_help')"
                         v-html="text">
                      </p>
                      <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                       :href="$t('clients.client_portal.client_portal_access_help_link')"
                                                                       target="_blank">{{
                          $t('clients.client_portal.client_portal_access_help_link')
                        }}</a></h2>

                    </div>
                  </div>
                </template>
              </VDropdown>
            </div>
          </div>
        </div>
        <div class="flex justify-around mt-2 space-x-10" v-show="showInvite">
          <div class="w-full flex flex-col justify-start mt-2">
            <div class="mt-4 w-full">
              <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                         :label="$t('settings.user_management.personal_message')" v-model="welcomeEmailBody"
                         :isMultiline="true" :rows="20"/>
            </div>
          </div>
        </div>
        <div v-show="showInvite">
          <div class="flex justify-start space-x-8 mt-5 w-full">
            <InputCheck :text="$t('clients.attrs.client_portal_sms_mfa')" :isChecked="smsMFASelected" @check="handleMfaCheck"/>
          </div>
          <div class="flex justify-start space-x-8 mt-5 w-full">
            <InputCheck :text="$t('clients.attrs.plot_request')" @check="inviteCheck"/>
          </div>
        </div>
        <div class="flex justify-around mt-2 space-x-10" v-show="showInviteEmail">
          <div class="w-full flex flex-col justify-start mt-2">
            <label class="text-left textbold ">{{ $t('settings.user_management.attrs.email') }} : <span
                style="text-decoration: underline">{{ clientUserEmail }} </span></label>
            <div class="mt-4 w-full">
              <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                         :label="$t('settings.user_management.personal_message')" v-model="inviteEmailBody"
                         :isMultiline="true" :rows="20"/>
            </div>
          </div>
        </div>

        <div class="flex justify-end space-x-4 mt-10">
          <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
          <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {ClientService} from "@/services/client.service";

export default {
  name: "Step4",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clientData: {},
      step4: {
        client_portal_access: this.$store.state.client.clientData.client_portal_access,
        invite: this.$store.state.client.clientData.invite
      },
      welcomeEmailBody: '',
      showInvite: false,
      showInviteEmail: false,
      inviteEmailBody: '',
      smsMFASelected: false
    }
  },
  mounted() {
    this.step4.client_portal_access = false;
    this.$api.getEmailTemplate('client_user_invitation');
    this.$api.getEmailTemplate('client_user_welcome');
  },
  created() {
    this.clientData = this.getClientData();
  },
  computed: {
    getClientType() {
      return this.getClientData().clientType;
    },
    clientUserEmail() {
      return this.getClientData().contact.email ? this.getClientData().contact.email : '';
    }
  },
  methods: {
    ...mapMutations("client", ["setClientData"]),
    ...mapGetters("client", ["getClientData"]),
    ...mapGetters("email", ["getTemplates"]),
    ...mapGetters("user", ["getCurrentUser"]),
    inviteCheck(isCheck) {
      this.step4.invite = isCheck
      this.showInviteEmail = this.showInvite && isCheck;

      if(this.getTemplates()['client_user_invitation']) {
        this.inviteEmailBody = this.getTemplates()['client_user_invitation']
            .replace('%sender_name%', this.getCurrentUser().name)
            .replace('%sender_company_name%', this.getCurrentUser().company_name)
            .replace(/\n/g, "")
            .replace(/<br\s*[\/]?>/gi, "\n");
      }
    },
    handleMfaCheck(isCheck) {
      this.smsMFASelected = isCheck;
      if(isCheck) {
        this.clientData = this.getClientData();
        if (typeof (this.clientData.contact.mobile) === 'undefined' || this.clientData.contact.mobile.trim() === '') {
          this.$api.showToast(this.$t('clients.errors.required_contact_mobile'), "error");
          this.$nextTick(() => {
            this.smsMFASelected = false;
          });
        }
      }
    },
    portalAccess(isCheck) {
      this.step4.client_portal_access = isCheck
      if(isCheck) {
        if(this.getTemplates()['client_user_welcome']) {
          this.welcomeEmailBody = this.getTemplates()['client_user_welcome']
              .replace('%sender_name%', this.getCurrentUser().name)
              .replace('%sender_company_name%', this.getCurrentUser().company_name)
              .replace(/\n/g, "")
              .replace(/<br\s*[\/]?>/gi, "\n");
        }

        this.clientData = this.getClientData();
        if (typeof (this.clientData.contact.email) === 'undefined' || this.clientData.contact.email.trim() === '') {
          this.$api.showToast(this.$t('clients.errors.required_contact_email'), "error");
          this.showInvite = false;
          this.showInviteEmail = false;
          this.$nextTick(() => {
            this.step4.client_portal_access = false;
          });

          return ;
        }

        if (this.smsMFASelected && (typeof (this.clientData.contact.mobile) === 'undefined' || this.clientData.contact.mobile.trim() === '')) {
          this.$api.showToast(this.$t('clients.errors.required_contact_mobile'), "error");
          this.showInvite = false;
          this.showInviteEmail = false;
          this.$nextTick(() => {
            this.step4.client_portal_access = false;
          });

          return ;
        }

        this.showInvite = true;
      } else {
        this.showInvite = false;
        this.showInviteEmail = false;
      }
    },
    async submitNext() {
      this.clientData = this.getClientData();
      if (this.smsMFASelected && (typeof (this.clientData.contact.mobile) === 'undefined' || this.clientData.contact.mobile.trim() === '')) {
        this.$api.showToast(this.$t('clients.errors.required_contact_mobile'), "error");
        return ;
      }

      this.setClientData(this.step4)

      // Trigger save
      const service = new ClientService();
      const post_data = {...this.getClientData()};
      post_data['invite'] = this.showInviteEmail ? 1 : 0;
      post_data['invite_email'] = this.showInviteEmail ? this.inviteEmailBody : '';
      post_data['welcome_email'] = this.showInvite ? this.welcomeEmailBody : '';
      post_data['mfa_mode'] = this.smsMFASelected ? 'SMS' : 'EMAIL';

      if (await service.save(post_data)) {
        this.$emit("finish");
      }
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">


.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.rect {
  width: 100%;
  height: auto;
  padding: 10px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 5px;
}

.textbold {

  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}
.portal_access_help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
</style>
