<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('components.change_management')"/>
    <div class="mr-7 property-grid">
      <div class="flex flex-col justify-start mt-5">
        <div class="info flex justify-start items-center">
          <img class="info__icon" src="@/assets/info_gray.svg"/>
          <label class="info__text" v-html="$t('components.change_management_info')"/>
        </div>
      </div>
      <AppGrid :grid-id="gridId"
               data-endpoint="property/list"
               count-endpoint="property/list"
               :columns="columns"
               default-sort-attr="created_at"
               :default-sort-dir="1"
               :selectable="true"
               :expandable="true"
               expansionFormat="ChangeManagement"
               selection-data-key="prim_uid"
               navigation-data-key="prim_uid"
               :standard-filters="standardFilters"
               :server-params="serverParams"
               :menus="menus"
               :auto-load="autoLoad"
               :state-ful-filter="true"
               :showCreateRecord="false"
               @menu-clicked="handleMenuClick"
               @col-click="handleColClick"
               @on-data-loaded="onDataLoaded"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import AppGrid from "@/components/AppGrid"
import {mapGetters, mapMutations} from "vuex";
import {PropertyService} from "@/services/property.service";
import {UserService} from "@/services/user.service";
import {BranchService} from "@/services/branch.service";

export default {
  name: 'list',
  directives: {'tooltip': Tooltip},
  props: {
    gridId: {
      type: String,
      default: 'change_management'
    },
    parentType: {
      type: String,
      default: ''
    },
    parentId: {
      type: Number,
      default: 0
    },
    autoLoad: {
      type: Boolean,
      default: true
    }
  },
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  components: {
    AppGrid
  },
  data() {
    return {
      userService: new UserService(),
      service: new PropertyService(),
      serverParams: {
        parentType: this.parentType,
        parentId: 0,
        gridId: this.gridId,
        filters: {
          always: 'is_updated_after_submission'
        }
      },
      standardFilters: [],
      menus: [
        {
          id: 'pm.bulk_add_query',
          icon: this.getAssetPath('add_property.svg'),
          title: this.$t('components.properties_menu.bulk_add_query'),
          canShow: !this.archived,
        },
        {
          id: 'pm.bulk',
          icon: this.getAssetPath('doc_cog.svg'),
          title: this.$t('components.properties_menu.bulk'),
          sub_menu: {
            items: [
              {
                id: 'pm.declaration_relevant',
                icon: 'declaration_relevant_icon',
                label: this.$t('components.properties_menu.declaration_relevant')
              },
              {
                id: 'pm.declaration_not_relevant',
                icon: 'declaration_not_relevant_icon',
                label: this.$t('components.properties_menu.declaration_not_relevant')
              },
              {
                id: 'pm.new_declaration',
                icon: 'internal_approval_icon',
                label: this.$t('components.properties_menu.new_declaration')
              }
            ]
          }
        },
        {
          id: 'pm.export',
          icon: this.getAssetPath('export.svg'),
          icon_disabled: this.getAssetPath('export_gray.svg'),
          title: this.$t('components.properties_menu.export_property'),
          disabled: true
        },
        {
          id: 'grid_reload',
          icon: this.getAssetPath('refresh.svg'),
          title: this.$t('general.grid.refresh')
        }
      ],
      columns: [
        {
          field: 'client_name',
          header: this.$t('properties.client_name'),
          isChecked: true,
          elType: 'text',
          width: 200,
          clickable: true
        },
        {
          field: 'property_id',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          width: 180,
          elType: 'text',
          clickable: true
        },
        {
          field: 'name',
          header: this.$t('properties.step2.name'),
          isChecked: true,
          elType: 'text',
          width: 500,
          clickable: true
        },
        {
          field: 'client_id',
          header: this.$t('properties.client_number'),
          isChecked: true,
          elType: 'text',
          width: 180,
          clickable: true
        },
        {
          field: 'reference',
          header: this.$t('components.properties_table_col.col8'),
          isChecked: true,
          elType: 'text',
          width: 200,
          clickable: true
        },
        {
          field: 'elster_status',
          header: this.$t('components.properties_table_col.col25'),
          isChecked: true,
          elType: 'select',
          width: 180,
          filterOptions: this.$t('properties.elster_status_options'),
          clickable: true
        },
        {
          field: 'declaration_status',
          header: this.$t('components.properties_table_col.col26'),
          isChecked: false,
          width: 280,
          elType: 'select',
          filterOptions: this.$t('properties.property_declaration_status_options'),
          clickable: true
        },
        {
          field: 'declaration_number_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col33'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'declaration_type_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col32'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.types_old'),
          clickable: true
        },
        {
          field: 'branch_name',
          header: this.$t('components.properties_table_col.col35'),
          isChecked: false,
          elType: 'select',
          sortable: false,
          filterOptions: '',
          clickable: true
        },
        {
          field: 'street',
          header: this.$t('components.properties_table_col.col3'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'house_number',
          header: this.$t('components.properties_table_col.col22'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'zip',
          header: this.$t('components.properties_table_col.col6'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'city',
          header: this.$t('components.properties_table_col.col4'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'federal_state_name',
          header: this.$t('components.properties_table_col.col5'),
          isChecked: false,
          elType: 'state_select',
          filterableSelect: true,
          clickable: true
        },
        {
          field: 'community',
          header: this.$t('components.properties_table_col.col7'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'property_type',
          header: this.$t('components.properties_table_col.col14'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.property_type_filter'),
          clickable: true
        },
        {
          field: 'economic_entity_type',
          header: this.$t('components.properties_table_col.col23'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.step2a.economic_entity_types.default'),
          clickable: true
        },
        {
          field: 'complexity',
          header: this.$t('properties.step2a.complexity'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options'),
          clickable: true
        },
        {
          field: 'ownership_structure',
          header: this.$t('components.properties_table_col.col13'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.ownership_structure_filter'),
          clickable: true
        },
        {
          field: 'area_of_the_land_total',
          header: this.$t('components.properties_table_col.col19'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'exemption',
          header: this.$t('components.properties_table_col.col24'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        },
        {
          field: 'multi_communities',
          header: this.$t('components.properties_table_col.col17'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        },
        {
          field: 'development_state',
          header: this.$t('components.properties_table_col.col16'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        },
        {
          field: 'area_of_the_land_value_1',
          header: this.$t('components.properties_table_col.col20'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'amount_of_parcels',
          header: this.$t('components.properties_table_col.col31'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'internal_note',
          header: this.$t('properties.step2.internalNote'),
          filterLbl: this.$t('properties.step2.internalNote') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'extra_details',
          header: this.$t('properties.step2.extraDetails'),
          filterLbl: this.$t('properties.step2.extraDetails') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true
        },
        {
          field: 'declaration_relevant_marked_by_user',
          header: this.$t('components.properties_table_col.col28'),
          filterLbl: this.$t('components.properties_table_col.col28'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('components.boolean_filter'),
          clickable: true
        },
        {
          field: 'next_declaration_type',
          header: this.$t('components.properties_table_col.col29'),
          filterLbl: this.$t('components.properties_table_col.col29'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.next_declaration_type_options'),
          clickable: true
        },
        {
          field: 'last_checked_for_changes',
          header: this.$t('components.properties_table_col.col39'),
          filterLbl: this.$t('components.properties_table_col.col39'),
          isChecked: false
        },
        {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false
        },
        {
          field: 'modified_by_name',
          header: this.$t('components.properties_table_col.col34'),
          filterLbl: this.$t('components.properties_table_col.col34'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true
        },
        {
          field: 'declaration_reference_date_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col41'),
          filterLbl: this.$t('components.properties_table_col.col41'),
          isChecked: false,
          clickable: false
        },
        {
          field: 'declaration_status_of_the_last_declaration',
          header: this.$t('components.properties_table_col.col42'),
          filterLbl: this.$t('components.properties_table_col.col42'),
          isChecked: false,
          clickable: false
        },
        {
          field: 'assigned_user',
          header: this.$t('components.properties_table_col.col38'),
          filterLbl: this.$t('components.properties_table_col.col38'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true
        }
      ],
      stateList: [],
      propertiesList: [],
    }
  },
  beforeMount() {
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });

    this.userService.getCurrentUserStats().then((stats) => {
      this.menus = this.menus.map((o) => {
        if (o.id === 'pm.export') {
          o.disabled = !(stats.propertyCount > 0);
        }

        return o;
      })
    });

    (new BranchService()).listMyBranches().then((list) => {
      let branchList = list.map((o) => {
        return {
          name: o.name,
          code: o.uid
        }
      });

      if (branchList.length > 0) {
        this.columns = this.columns.map((o) => {
          if (o.field === 'branch_name') {
            o.filterOptions = branchList;
          }

          return o;
        });
      }
    });

    this.userService.listAll().then(async (list) => {
      let userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active') {
          filtered.push({
            name: item.name,
            code: item.id
          });
        }

        return filtered;
      }, []);

      userList.push({
        name: 'API',
        code: '0'
      });

      if (userList.length > 0) {
        this.columns = this.columns.map((o) => {
          if (o.field === 'modified_by_name' || o.field === 'assigned_user') {
            o.filterOptions = userList;
          }

          return o;
        });
      }
    });
  },
  computed: {
    getTitle() {
      return this.getTheme() === 'ebnerstolz'
        ? 'ES Grundsteuer'
        : `GrundsteuerDigital - ${this.$t('components.properties')}`;
    },
    showPageHeader() {
      return typeof (this.parentType) !== 'undefined' && this.parentType === '';
    },
    isReadOnly() {
      return !(
        typeof this.getCurrentUser().permissions !== 'undefined'
        && typeof this.getCurrentUser().permissions.manage_property !== 'undefined'
        && parseInt(this.getCurrentUser().permissions.manage_property) === 1
      );
    },
    hasMassUpdatePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.allow_mass_update) === 1;
    }
  },
  watch: {
    parentId() {
      if (this.parentId) {
        this.serverParams.parentId = this.parentId;
      }
    }
  },
  created() {
    this.service.setDefaultParams(this.serverParams);
  },
  mounted() {
    const localStorageKeys = ['previousPage', 'clientId', 'AddedProperty', 'assignedUser', 'flag'];

    localStorageKeys.forEach(function (item) {
      localStorage.removeItem(item);
    });

    if (this.parentId) {
      this.serverParams.parentId = this.parentId;
    }
  },
  methods: {
    ...mapMutations('grid', ['triggerReload']),
    ...mapMutations('user', ['setCurrentUserStatsDataChanged']),
    ...mapGetters('user', ['getCurrentTheme', 'getCurrentUser']),
    getTheme() {
      return this.getCurrentTheme()
    },
    onDataLoaded(gridData) {
      this.propertiesList = [];
      if (gridData.length > 0) {
        for (let k = 0; k < gridData.length; k++) {
          this.propertiesList[k] = gridData[k].prim_uid;
        }
      }
    },
    handleMenuClick(args) {
      let selectedProperties = [];
      let withoutDeclarationStatusOption = undefined;

      switch (args.menuId) {
        case 'pm.new_declaration':
          if (this.hasMassUpdatePermission) {
            if (args.data.length > 0) {
              withoutDeclarationStatusOption = this.$t('properties.property_declaration_status_options').find(o => o.code === 'without_declaration');
              let propertiesWithExistingDeclarationsSelected = false;
              args.data.forEach((property) => {
                if (property.declaration_status !== withoutDeclarationStatusOption.name) {
                  propertiesWithExistingDeclarationsSelected = true;
                }

                selectedProperties.push({
                  property_id: property.property_id,
                  name: property.name,
                  declaration_status: property.declaration_status,
                  reference: property.reference,
                  prim_uid: property.prim_uid,
                  federal_state_uid: parseInt(property.federal_state_uid),
                  client_uid: property.client_uid,
                  client_name: property.client_id + ' - ' + property.client_name,
                  declarationRelevantMarkedByUser: property.declarationRelevantMarkedByUser,
                  updatedAfterSubmission: property.updatedAfterSubmission,
                  nextDeclarationType: property.nextDeclarationType,
                  lastCheckedForChanges: property.lastCheckedForChanges,
                });
              }, this);

              if (propertiesWithExistingDeclarationsSelected) {
                this.$modal.show({
                  headerText: this.$t('properties.new_declaration_confirm.title'),
                  text: this.$t('properties.new_declaration_confirm.message'),
                  showClose: true,
                  canEscape: true,
                  buttons: {
                    ok: this.$t('properties.new_declaration_confirm.button_1'),
                    cancel: this.$t('properties.new_declaration_confirm.button_2'),
                    delete: false
                  },
                  onConfirm: () => {
                    this.goToNewDeclaration(selectedProperties, args.data);
                    this.$modal.visible = false;
                  }
                });
              } else {
                this.goToNewDeclaration(selectedProperties, args.data);
              }
            } else {
              this.$api.showToast(this.$t('properties.mass_update.empty_selection'), 'error');
            }
          } else {
            this.$api.showToast(this.$t('properties.mass_update.no_permission'), 'error');
          }
          break;

        case 'pm.declaration_relevant':
          if (this.hasMassUpdatePermission) {
            if (args.data.length > 0) {
              args.data.forEach((property) => {
                this.selectNextDeclarationType(property.prim_uid, '');
              }, this);
            } else {
              this.$api.showToast(this.$t('properties.mass_update.empty_selection'), 'error');
            }
          } else {
            this.$api.showToast(this.$t('properties.mass_update.no_permission'), 'error');
          }
          break;

        case 'pm.declaration_not_relevant':
          if (this.hasMassUpdatePermission) {
            if (args.data.length > 0) {
              args.data.forEach((property) => {
                this.cancelNextDeclarationType(property.prim_uid);
              }, this);
            } else {
              this.$api.showToast(this.$t('properties.mass_update.empty_selection'), 'error');
            }
          } else {
            this.$api.showToast(this.$t('properties.mass_update.no_permission'), 'error');
          }
          break;

        case 'pm.export':
          args.filter = {
            ...this.serverParams.filters,
            ...args.filter
          };

          let selectedRecordsStr = '';
          if (args.data.length > 0) {
            let selectedRecords = [];
            for (let item of args.data) {
              selectedRecords.push(item.prim_uid);
            }
            selectedRecordsStr = selectedRecords.join(",");
          }

          this.service.export(args.selectedColumns, args.filter, selectedRecordsStr);

          break;

        case 'pm.bulk_add_query':
          this.$router.push('/changemanagement/BulkAddQuery');
          break;

        default:
          this.$emit('click', args.menuId)
      }
    },
    goToNewDeclaration(selectedProperties, argsData) {
      if (selectedProperties.length === 0) {
        this.$api.showToast(this.$t('properties.mass_update.zero_without_declaration'), 'error');
      } else {
        if (selectedProperties.length < argsData.length) {
          this.$api.showToast(this.$t('properties.mass_update.contains_without_declaration').replace('%count1%', argsData.length - selectedProperties.length), 'warning');
        }

        this.$router.push({
          name: 'Properties',
          params: {
            comp: 'BulkAdd',
            processType: 'new_declaration',
            selectedDeclarations: [],
            selectedProperties: selectedProperties,
            fromChangeManagement: 1,
            breadcrumbs: [
              {
                title: this.$t('sidebar.properties'),
                link: '/property/list'
              },
              {
                title: this.$t('components.properties_menu.new_declaration'),
                link: ''
              }
            ],
          }
        });
      }
    },
    handleColClick(e) {
      if ((e.field === 'client_name' || e.field === 'client_id') && parseInt(e.row.client_uid) > 0) {
        this.$router.push('/client/edit?uid=' + parseInt(e.row.client_uid));
      } else {
        this.triggerEdit(e.row.prim_uid, e.row.client_uid);
      }
    },
    triggerEdit(uid, client_uid) {
      this.$router.push({
        name: 'Properties',
        query: {
          uid: uid
        },
        params: {
          comp: 'edit',
          client_uid: client_uid,
          parent_type: this.parentType,
          nav_records: this.getGridNavUids(this.gridId)
        }
      });
    },
    selectNextDeclarationType(propertyUid, nextDeclarationType) {
      this.service.selectNextDeclarationType(propertyUid, nextDeclarationType).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.$api.showToast(this.$t('properties.relevant.info-1'));

        }
      });
    },
    cancelNextDeclarationType(propertyUid) {
      this.service.cancelNextDeclarationType(propertyUid).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.$api.showToast(this.$t('properties.relevant.info-2'));
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-text {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.dialog-text1 {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 40px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.dialog-title {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 50px;
}

th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }
  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.box {
  padding: 10px;
  background-color: #fff4ce;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: table;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.chip-container {
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    height: 39px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: center;
    border-radius: 150px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  .chip-logo {
    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    font-size: 14px;
    margin-right: 5px;

    &:hover {
      color: white;
    }
  }

  .info-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

a {
  color: theme('colors.muted_black');
  text-decoration: none;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
}

.a-link {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.elster-dlg {
  label {
    color: #333333 !important;
  }

  .textbold {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 700;
    color: theme('colors.muted_black');
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 15px;
  }

  ul.ul-list {
    padding: 0;
    margin: 20px 0 0 20px;
  }

  ul.ul-list li {
    font-size: 15px;
    margin-bottom: 8px;
    list-style: disc;
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
  }
}

.elster-result-header {
  p {
    text-align: center !important;
  }
}

.property-grid::v-deep {
  .grid_action_icon {
    width: 30px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .elster_validate_icon {
    background-image: url('~@/assets/elster_validate.svg');
  }

  .internal_approval_icon {
    background-image: url('~@/assets/internal_approval.svg');
  }

  .declaration_relevant_icon {
    background-image: url('~@/assets/declaration_relevant.svg');
  }

  .declaration_not_relevant_icon {
    background-image: url('~@/assets/declaration_not_relevant.svg');
  }

  .info {
    padding: 5px 0;
    background-color: #f2f2f2;
    box-sizing: border-box;
    margin-bottom: 20px;

    &__icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      margin-left: 10px;
      margin-right: 10px;
    }

    &__text {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
      color: theme('colors.muted_black');
      text-align: left;
      line-height: 20px;
      font-size: 14px
    }
  }
}
</style>
