<template>
  <div class="step_box">
    <div class="box flex flex-col w-full mr-6">
      <div class="flex justify-start items-center h-full mr-6">
        <img class="image ml-2 mr-2" src="@/assets/info_gray.svg"/>
        <div class="flex flex-col">
          <label class="text ml-2">
            {{ $t('dms.steps.step1.info') }}
          </label>
        </div>
      </div>
    </div>
    <Button class="mt-6 w-56" :text="$t('dms.steps.step1.import_button')"
            :icon="getAssetPath('datev_import_button_icon.svg')" @click="submitNext"/>
  </div>
</template>

<script>
export default {
  name: "Step1",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    submitNext() {
      this.$emit("goToNextStep", "3");
    }
  }
}
</script>

<style scoped lang="scss">
.flex.radio_step > label {
  margin-right: 10px;
}

.step_box {
  text-align: left;
  font-size: 15px;
}
.box {
  min-height: 0 !important;
  height: auto;
  padding: 10px !important;
}
p.para_text {
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;

}


.chk {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.not-found-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.box {
  min-height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  align-self: start;
  margin-right: 100px;
  display: table;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

a {
  text-decoration: underline;
  color: theme('colors.muted_black');
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
</style>
