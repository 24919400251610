<template>
  <MainLayout value="branch">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import add from "@/components/settings/AddBranch";
import edit from "@/components/settings/EditBranch";

export default {
  name: "Branch",
  components: {MainLayout, add, edit},
  data() {
    return {}
  },
  computed: {
    comp() {

      return this.$route.params.comp
    }
  },

  methods: {
    handleComponentClick(data) {
      switch (data) {

        case 'add_branch':
          this.$router.push('/branch/add')
          break;
        case 'edit_branch':
          this.$router.push('/branch/edit')
          break;
        case 'client_add_finish':
          //this.currentComponent = 'list';
          this.$router.go(-1);


          break;


      }

    },
  }
}
</script>

<style scoped lang="scss">

</style>
