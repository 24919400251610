<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.user_management.create_new_user') " :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" w-full mr-12 ">
        <FormSteps :steps="steps"/>
      </div>
      <div class="w-full">
        <div class="mt-10 mr-6 mb-6 " v-if="completed===false">
          <component
              v-for="(item, index) in steps"
              :key="`component-${index}`"
              :is="item.component"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              @finish="finish"
              v-show="item.active === true"
          />
        </div>
        <div class="mt-10 mr-10 ml-10" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userRoles, userStatus} from "@/core/constants";
import FormSteps from "../FormSteps";
import Step1 from '@/components/user/steps/Step1.vue';
import Step2 from '@/components/user/steps/Step2.vue';
import Step3 from '@/components/user/steps/Step3.vue';
import Finish from '@/components/user/steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "adduser",
  components: {FormSteps, Step1, Step2, Step3, Finish},
  data() {
    return {
      completed: false,
      breadcrumbs: [
        {
          title: this.$t('breadcrumbs.settings'),
          link: ''
        }, {
          title: this.$t('breadcrumbs.user_management'),
          link: '/setting/usermanagement'
        }, {
          title: this.$t('breadcrumbs.add_user'),
          link: ''
        },
      ],
      roleOptions: userRoles,
      statusOptions: userStatus,
      steps: [
        {
          id: 1,
          title: this.$t('settings.user_management.steps.basic'),
          completed: false,
          active: true,
          first: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('settings.user_management.steps.permissions'),
          completed: false,
          active: false,
          component: "Step2"
        },
        {
          id: 3,
          title: this.$t('settings.user_management.steps.pwd_invite'),
          completed: false,
          active: false,
          last: true,
          component: "Step3"
        },
      ]
    }
  },
  computed: {
    ...mapGetters("sub_user", ["getCurrentStep"]),
    datevAccount() {
      return this.getCurrentUser().settings.tax_office_software === 'datev';
    }
  },
  mounted() {
    this.init();
    this.steps[2].title = this.datevAccount ? this.$t('settings.user_management.steps.invite_only') : this.$t('settings.user_management.steps.pwd_invite');
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("sub_user", ["setCurrentStep", "init"]),
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    finish() {
      this.moveStep('next', true)
      this.completed = true
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.steps.findIndex(item => item.id === step)
        this.steps = this.steps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }
        })
        this.setCurrentStep(step)
      }
    },
    handleFinishButtonClick() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.two-factor {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}
</style>
