<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{ flex: '1' }"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        {{ $t('settings.billing.cancellation.not_eligible.title') }}
      </h3>
    </template>

    <div class="text-left text-lg flex flex-col">
      <p class="mb-3">{{ $t('settings.billing.cancellation.not_eligible.line1') }}</p>
      <p>{{ $t('settings.billing.cancellation.not_eligible.line2', {date: terminationDate}) }}</p>
    </div>

    <template v-slot:footer>
      <div class="flex justify-end">
        <Button
          class="w-1/3 mb-4 mr-4"
          :text="$t('general.ok')"
          @click="isVisible = false"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
  name: 'NotEligibleForTerminationModal',
  emits: ['close'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    terminationDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
  },
  methods : {
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  max-width: 960px;
  min-height: 640px;

  .p-dialog-content {
    @include segoe-regular;
    color: theme('colors.muted_black');

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
