<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img :src="getAssetPath('check_done.svg')"/>
    </div>
    <label class="label__text ">{{ $t('settings.powers_of_attorney.power_of_attorney_added') }}</label>
    <Button @click="submit" class="mt-10 "
            :text="$t('settings.powers_of_attorney.all_powers_of_attorney')"></Button>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Finish",
  methods: {
    ...mapMutations("power_of_attorney", ["setCurrentStep"]),
    submit() {
      this.setCurrentStep(1);
      this.$router.push('/setting/powersofattorney');
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}
</style>
