<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{ flex: '1' }"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        {{ $t('billing_subscribe.booking_complete') }}
      </h3>
    </template>

    <div>
      <div>
        <img
          class="m-auto mb-4"
          :src="getAssetPath('success-1.svg')"
        />
      </div>

      <span class="font-semibold text-lg">{{$t('billing_subscribe.many_thanks')}}</span>

      <p class="mt-4">{{$t('billing_subscribe.success_note')}}</p>
    </div>

    <template v-slot:footer>
      <div class="flex justify-center">
        <Button
          class="mb-4"
          :text="$t('buttons.next')"
          @click="continueProcess"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import { mapGetters } from 'vuex';

export default {
  name: 'BillingThanksModal',
  emits: ['close', 'continue'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    continueProcess() {
      this.isVisible = false;
      this.$emit('continue');
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
