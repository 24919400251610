<template>
  <MainLayout value="powerofattorney">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>


<script>
import MainLayout from "../layouts/MainLayout";
import addPowerOfAttorney from "@/components/settings/AddPowerOfAttorney";
import editPowerOfAttorney from "@/components/settings/EditPowerOfAttorney";

export default {
  name: "powerofattorney",
  components: {MainLayout, addPowerOfAttorney, editPowerOfAttorney},
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('powers_of_attorney');
  },
  methods: {
    handleComponentClick(data) {
      switch (data) {
        case 'add_power_of_attorney':
          this.$router.push('/powerofattorney/addPowerOfAttorney')
          break;
        case 'edit_power_of_attorney':
          this.$router.push('/powerofattorney/editPowerOfAttorney')
          break;
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
