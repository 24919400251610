import {i18n} from '@/plugins/i18n';

export const importErrorCols = [{
  field: 'row_no',
  header: i18n.t('general.error_grid.row_no'),
  width: 50,
  isChecked: true
}, {
  field: 'error_text',
  header: i18n.t('general.error_grid.error_text'),
  html: true,
  isChecked: true
}];
