const state = {
    userData: { },
    currentStep: 1
};
const getters = {
    getUserData: (state) => state.userData,
    getCurrentStep: (state) => state.currentStep,
}

const mutations = {
    init: (state) => {
        state.currentStep = 1;
        state.userData = {
            salutation: '',
            name: '',
            email: '',
            role: '',
            status: '',
            phone: '',
            agent_code: '',
            two_fa: false,
            invite: false,
            all_branch_access: true,
            selected_branches: [],
            all_client_access: true,
            change_management_access: false,
            allow_mass_update: false,
            send_dispute: true,
            selected_clients: []
        };
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setUserData: (state, user) => {
        state.userData = {...state.userData, ...user};
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



