<template>
  <div>
    <div class="tabs" id="tabs">
      <div class="tabs-triggers space-x-1">
        <div class="tabs-trigger" v-for="(item, index) in getCategories" :key="index"
             :class="[item.isActive  ? 'tabs-trigger--active' : '']" @click="activate(item)">{{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    currentTab: {
      type: String,
      default: 'Basic'
    },
    slug: {
      type: String,
      default: 'basic',
    }
  },

  data() {
    return {
      active: 0,
    }
  },
  computed: {
    getCategories() {
      return this.categories.filter(o => !o.hide)
    }
  },
  methods: {
    activate(item) {
      this.$emit('click', item.slug)
    }
  }
}
</script>

<style scoped lang="scss">
$base-duration: 1250ms;

// Colors

$secondary: #EFCB68;
$accent: #686963;
$white: #fff;


// Breakpoints
$sm: 20rem;
$med: 48rem;
$lg: 64rem;

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}


.tabs {
  position: relative;
  height: 100%;
  background-color: $white;

  &-triggers {
    display: flex;
  }

  &-trigger {
    //flex: 1 0 auto;

    padding-left: 1rem;
    padding-right: 1rem;
    // background-color: $white;
    //transition: 500ms linear all;
    cursor: pointer;

    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: center;


    &--active {
      background-color: $white;
      color: theme('colors.muted_black');
      @include segoe-semi-bold;
      border-bottom-width: 3px;
      border-bottom-color: $primary;
      padding-bottom: 15px;

    }
  }

  &-content {
    padding: 1rem;
    background-color: $white;
  }
}


</style>
