<template>
  <div class="radio_wrapper flex w-full">
    <label class="radio disable" :class="getClass">
      <input type="radio" :name="name" :value="rValue" v-model="localSelectedButtonValue"   @change="$emit('input', $event.target.value)" @click="handleCheck($event)"/>
      <span></span>
    </label>
    <div class="flex textradio" v-html="text" v-if="text !==''"></div>
    <div class="flex">
      <img v-if="showHelpIcon" class="help_icon inline" :src="getAssetPath('help_green.svg')"
           v-tooltip.right="helpText"/>
      <VDropdown v-if="showHelpIconPopover" class="help_icon inline" :showTriggers="['click','hover']"
                 :popperTriggers="['hover','click']"
                 :hideTriggers="['click']">
        <!-- This will be the popover target (for the events and position) -->
        <img class="help_icon inline self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <!-- This will be the content of the popover -->
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text" v-for="text in popoverTexts" v-html="text" v-bind:key="text">
              </p>
            </div>
            <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text" :href="popoverLink"
                                                             target="_blank">{{ popoverLink }}</a></h2>
          </div>
          <!-- You can put other components too -->
        </template>
      </VDropdown>
    </div>
  </div>

</template>

<script>
export default {
  name: "InputRadio",
  props: {
    rValue: {
      type: String,
      default: ""
    },

    disabled: {
      type: Boolean,
      default: true
    },
    text:{
      type: String,
      default: ""
    },
    name:{
      type: String,
      default: ""
    },
    selectedButtonValue: {
      type: [String, Number, Boolean],
      required: true
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    showHelpIconPopover: {
      type: Boolean,
      default: false
    },
    popoverTexts: {
      type: Array,
      default: () => []
    },
    popoverLink: {
      type: String,
      default: ""
    },
  },
  model: {
    prop: 'selectedButtonValue',
    event: 'change'
  },
  computed:{
    getClass(){
      return [{'disabled': this.disabled === true},{'normal':this.disabled === false}]
    },
    localSelectedButtonValue: {
      get () {
        return this.selectedButtonValue
      },
      set () {
        this.$emit('change', event.target.value)
      }
    }
  },
  data(){
    return{
      checked:false
    }
  },
  methods:{
    handleCheck(event){
      if(this.disabled){
        e.preventDefault()
      }else{
        this.$emit('checked',{checked:event.target.checked,value:event.target.value})
     //   this.$emit('input',this.checked)
      }

    }

  }

}
</script>

<style scoped lang="scss">

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
  padding-left: 0.75rem;
}

.radio_wrapper {
  margin: 0px;
}
.radio_wrapper input {
  display: none;
}
.radio_wrapper label {
  margin-right: 1px;
  display: inline-block;
  cursor: pointer;
}

.radio_wrapper .disabled input + span:before {
  background-color: #eee;
  border-color: #eee;
}
.radio_wrapper span:before {
  content: "";
  //left: 5px;
  top: 0;
  /* transform: translatey(-0%); */
  width: 25px;
  height: 25px;
  border-radius: 100% !important;
 /// background-color: #ddd;
  transition: all 0.25s linear;
  display: block;
  padding: 5px;
  border: 1px solid #7a7a7a;
  border-radius: 100%;
  position: relative;
  vertical-align: middle;
  bottom: 0;
  margin: 0;
  //background: radial-gradient(#7a7a7a 0%, #7a7a7a 40%, transparent 50%, transparent);
}
.radio_wrapper input:checked + span {
  background: radial-gradient(#7a7a7a 0%, #7a7a7a 40%, transparent 50%, transparent);
  //box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
//.radio_wrapper .red input:checked + span {
//  color: red;
//  border-color: red;
//}
.radio_wrapper .normal input:checked + span:before {
  background: radial-gradient(#7a7a7a 0%, #7a7a7a 40%, transparent 50%, transparent);
}
.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

</style>
