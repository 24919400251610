import {ApiService} from "./api.service";

export class BillingService extends ApiService {

  constructor() {
    super();
  }

  save(data) {
    return this.trigger('billing/save', data, true).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  after_end_term(data , show_loader) {

    return this.trigger('billing/after', data, show_loader).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  updatePaymentMethod(data , show_loader) {

    return this.trigger('billing/payment_method', data, show_loader).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  get() {
    return this.trigger('billing/get', {}, true).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return response;
      }
    });
  }
  checkIban(Iban_number, bic_code) {
    return this.trigger('billing/checkIban', {'Iban_number':Iban_number, 'bic_code':bic_code}).then(response => {
      return response;
    });
  }
  getPaymentDetail(planId) {
    return this.trigger('billing/getPaymentDetail', {'plan_id':planId}).then(response => {
      return response;
    });
  }
  listAll(show_loader) {
    return this.trigger('billing/list', {}, show_loader).then((response) => {
        if (response.data.success && parseInt(response.data.total_records) > 0) {
            return this.parse_object_data(response.data.records);
        } else {
            return [];
        }
    })
  }
  getPlanList() {
    return this.trigger('billing/planList').then((response) => {
        if (response.data.success) {
            return this.parse_object_data(response.data.data);
        } else {
            return [];
        }
    })
  }
  getInvoiceList() {
    return this.trigger('billing/invoiceList').then((response) => {
        if (response.data.success) {
            return this.parse_object_data(response.data.data);
        } else {
            return [];
        }
    })
  }
  getContactDetail(contactId) {
    return this.trigger('billing/contactDetail' , {'contactId':contactId}).then((response) => {
        if (response.data.success) {
            return response.data.data;
        } else {
            return [];
        }
    })
  }
  selectedPlanDetail(planId) {
    return this.trigger('billing/selectedPlanDetail', {'prim_uid':planId}, true).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return response;
      }
    });
  }

  updateContact(data) {
    return this.trigger('billing/updateContact', data, true).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }
  extendTrial() {

    return this.trigger('billing/extendTrial' , {}, true).then(response => {
      if (response.data.success) {
        return response;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  downloadInvoice(data) {

    return this.trigger('billing/downloadInvoice', data, true, '', 'blob').then(response => {
        this.trigger_download(response);
    });
  }

  generatePdf(data) {

    return this.trigger('billing/generatePdf', data, true, '', 'blob').then(response => {
        this.trigger_download(response);
    });
  }

  checkPartnerKey(partner_key) {

    return this.trigger('billing/checkPartnerKey', {'partner_key':partner_key}).then(response => {
        if (response.data.success) {
          return response;
        } else {
          this.$toast.error(response.data.message)
          return false
        }
    });
  }

}
