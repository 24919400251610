<template>
  <transition name="slide">
    <nav class="sidebar h-screen overflow-y-scroll ">
      <div class="flex flex-col h-screen justify-between">
        <div>
          <div class="space-x-2">
            <!--     Logo for white label partner-->
            <div v-if="getTheme()==='ebnerstolz'" class="flex cursor-pointer logo-container" @click="handleLogoClick">
              <img v-show="!navClosed" class="small-logo pl-0.5 pr-8 mt-8"
                   src="@/assets/themes/ebnerstolz/ebner_sidebar_logo.png"/>

              <img :class="!navClosed?'invisible':'visible'" class="pl-16 pr-8 mt-8"
                   src="@/assets/themes/ebnerstolz/ebner_sidebar_logo.png"/>
            </div>
            <!--            Logo for  UI-TC-->
            <div v-else class="flex cursor-pointer logo-container" @click="handleLogoClick">
              <img v-show="!navClosed" class="small-logo pl-3 pr-8 mt-8"
                   :src="getAssetPath('logo_nav.svg')"/>

              <img :class="!navClosed?'invisible':'visible'" class="pl-8 pr-8 mt-8"
                   :src="getAssetPath('dashboard_logo.svg')"/>
            </div>

            <!--      Hamburger-->
            <div class="flex justify-between pl-3 pr-5 mt-10 mb-8">
              <img @click="toggle" class="h-5 w-5 cursor-pointer icon-filter ham-icon" :src="getAssetPath('menu.svg')"/>
              <div class="flex ">
                <img class="h-5 w-5 mr-4 cursor-pointer icon-filter plus-icon" :src="getAssetPath('plus_white.svg')"
                     @click="togglePanel" v-show="showQuickAdd"/>
                <div class="relative cursor-pointer" v-show="navClosed" @click="toggleNotificationPanel">
                  <img class="h-5 w-5  icon-filter bell-icon" :src="getAssetPath('bell.svg')"/>

                  <div class="notification-counter-bg child-img" v-show="newNotificationCount > 0">
                    <p class=" notification-text">{{ newNotificationCount }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--      search-->
            <img v-show="!navClosed" class=" sidebar-search pl-3" :src="getAssetPath('search.svg')" @click="toggle"
                 v-tooltip.right="$t('sidebar.search')"/>

            <div class="pl-3 pr-5  search-el-container" v-show="navClosed">
              <!-- form tag just needed to prevent auto complete -->
              <form autocomplete="off">
                <input
                    :placeholder="$t('sidebar.search')"
                    v-model="searchTxt"
                    @input="search"
                    type="text"
                    autocomplete="off"
                    :class="{searching_bg: searching}"
                    class="search-sidebar pl-3 flex justify-start items-center w-full focus:outline-none"
                    @keypress.enter="showResult"/>
                <i class="pi pi-spin pi-spinner" v-show="searching"/>
              </form>
            </div>
            <OverlayPanel ref="opsearch" appendTo="body" :showCloseIcon="false" id="overlay_panel"
                          v-show="searchCompleted">
              <div class="-ml-4 search-result">
                <!-- Files -->
                <div class="flex justify-start ml-4" v-if="searchResult['documents'].length > 0">
                  <div class="flex flex-col">
                    <label class="title text-left">{{ $t('search.tabs.tab2') }}</label>
                    <div class="flex flex-col space-y-1">
                      <FileItem v-for="item in searchResult['documents']" :key="item.prim_uid" :item="item"/>
                    </div>
                  </div>
                </div>
                <!-- Clients -->
                <div class="flex justify-start ml-4 mb-4" v-if="searchResult['clients'].length > 0">
                  <div class="flex flex-col">
                    <label class="title text-left mt-4">{{ $t('search.tabs.tab3') }}</label>
                    <div class="flex flex-col space-y-1">
                      <ClientItem v-for="item in searchResult['clients']" :key="item.prim_uid" :item="item"/>
                    </div>
                  </div>
                </div>
                <!-- properties -->
                <div class="flex justify-start ml-4 mb-6" v-if="searchResult['properties'].length > 0">
                  <div class="flex flex-col">
                    <label class="title text-left ">{{ $t('search.tabs.tab4') }}</label>
                    <div class="flex flex-col space-y-1">
                      <PropertyItem v-for="item in searchResult['properties']" :key="item.prim_uid" :item="item"/>
                    </div>
                  </div>
                </div>
                <!-- declarations -->
                <div class="flex justify-start ml-4 mb-6" v-if="searchResult['declarations'].length > 0">
                  <div class="flex flex-col">
                    <label class="title text-left ">{{ $t('search.tabs.tab5') }}</label>
                    <div class="flex flex-col space-y-1">
                      <TaxItem v-for="item in searchResult['declarations']" :key="item.prim_uid" :item="item"/>
                    </div>
                  </div>
                </div>
                <label class="all-search mt-4 cursor-pointer"
                       @click="showAllSearch">{{ $t('general.show_all_search_result') }}</label>
              </div>
            </OverlayPanel>
          </div>

          <!-- Menu items -->
          <div class="flex flex-col mt-5 space-y-4">
            <div class="flex flex-col  " v-for="(item,index) in menus" :key="index">
              <div class="flex items-center">
                <IconLabel class="nav__label" :label="item.name"
                           :icon="item.icon" :selected="item.isActive"
                           :slug="item.slug"
                           :apply-icon-filter="false"
                           :showChangeLog="navClosed"
                           @click="handleClick(item)"/>
              </div>

              <!-- Submenu-->
              <div v-show="item.showSubMenu">
                <div class="space-y-3">
                  <IconLabel v-for="(subItem,index) in item.submenu" :key="index" class="text-left mt-2 "
                             @click="handleSubmenu(subItem, item)" :label=" subItem.name " :isSubmenu="true"
                             :apply-icon-filter="false"
                             :selected="subItem.isActive"></IconLabel>

                </div>
              </div>
            </div>
            <div class="relative pl-5 mt-5" v-show="!navClosed">
              <img class="h-5 w-5 cursor-pointer icon-filter" :src="getAssetPath('plus_white.svg')" @click="togglePanel"
                   v-show="!navClosed"/>
              <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false">
                <div class="-ml-6 space-y-4">
                  <IconLabel class="-mt-1" :icon="require('@/assets/doc_black.svg')" :blackText="true"
                             :apply-icon-filter="false"
                             iconStyle="blackish"
                             :label="$t('sidebar.add_popup.document')" @click="handlePopUp"/>
                  <IconLabel :icon="require('@/assets/document_black.svg')" :blackText="true"
                             iconStyle="blackish"
                             :apply-icon-filter="false"
                             :label="$t('sidebar.add_popup.declaration')" @click="$emit('popClick','declaration')"/>
                  <IconLabel :icon="require('@/assets/popup_home.svg')" :blackText="true"
                             iconStyle="blackish"
                             :label="$t('sidebar.add_popup.property')"
                             :apply-icon-filter="false"
                             @click="$emit('popClick','properties')"/>
                  <IconLabel :icon="require('@/assets/two_user_black.svg')" :blackText="true"
                             :label="$t('sidebar.add_popup.client')"
                             iconStyle="blackish"
                             :apply-icon-filter="false"
                             @click="$emit('popClick','client')"/>
                </div>
              </OverlayPanel>
            </div>
            <div class="relative pl-5 mt-5 cursor-pointer" v-show="!navClosed" @click="toggleNotificationPanel">
              <img class="h-5 w-5 icon-filter" :src="getAssetPath('bell.svg')" v-tooltip.right="'Benachrichtigungen'"/>
              <div class="notification-counter-bg  child-img nav-bottom" v-show="newNotificationCount > 0">
                <p class=" notification-text">{{ newNotificationCount }}</p>
              </div>
              <OverlayPanel ref="notification" appendTo="body" :showCloseIcon="false" id="overlay_notification_panel">
                <div class=" ">
                  <div class="flex justify-between items-center ">
                    <div class="flex justify-start items-center">
                      <img class="bell_gray mr-3" src="@/assets/bell_gray.svg"/>
                      <label>{{ $t('dashboard.headers.notification') }}</label>
                    </div>
                    <span class="text-link cursor-pointer underline ml-6" @click="markAsRead">
                      {{ $t('dashboard.headers.mark-as-read') }}
                    </span>
                  </div>
                  <hr class=" hr-line-user -ml-4 -mr-4 mt-4"/>
                  <progress-bar class="-ml-4 -mr-4" mode="indeterminate" v-show="fetchNotifications"/>
                </div>
                <!--     notification list-->
                <div class="flex flex-col h-auto justify-between" v-if="notifications.length > 0">
                  <div class="scroll-height  overflow-y-scroll overflow-x-hidden">
                      <NotificationItem v-for="notification in notifications"
                                        :key="notification.id"
                                        :notification="notification"
                                        @notificationClicked="notificationClicked"
                      />

                  </div>
                    <div class="flex ml-8 mt-2">
                        <label class="more_btn cursor-pointer" @click="showMoreNotification">{{
                                $t('general.more')
                            }}</label>
                    </div>
                </div>
                <div class="flex -mr-4 -ml-4 flex-col justify-between" v-else>
                  <label class="text-left ml-4 text-gray mt-2">{{ $t('general.none') }}</label>
                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>

        <!--Bottom icons-->
        <div class=" self-start w-full mb-10 ">
          <div class="flex flex-col mt-10 space-y-4">
            <div class="flex cursor-pointer " @click="handleSettingClick('account_settings')">
              <div class="flex justify-center items-center">
                <img :src="getProfilePic" class="user-img" v-tooltip.right="getCurrentUserName">

                <div class="flex flex-col ml-4">
                  <label class="sidebar-user-name cursor-pointer">{{ getCurrentUserName }}</label>
                  <label class="sidebar-sub-text cursor-pointer">{{ getCurrentUserCompanyName }}</label>
                </div>
              </div>
            </div>

            <div class="overlaynew" :class="{ hidden: !showOverlay, hideBilling: hideBilling }" @click="closeMenu"
                 v-on-clickaway="onClickOutside">
              <div class="flex">
                <div class=" h-full w-full">
                  <div class="w-full ">
                    <IconLabel :label="$t('sidebar.popup.account_setting')" class="mt-5" :selected="false"
                               :blackText="true"
                               :icon="require('@/assets/acc_setting_gray.svg')"
                               :apply-icon-filter="false"
                               iconStyle="blackish"
                               @click="handleSettingClick('account_settings')"></IconLabel>

                    <IconLabel :label="$t('sidebar.popup.branches')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               :icon="require('@/assets/building_settings_gray.svg')"
                               :apply-icon-filter="false"
                               @click="handleSettingClick('branches')" v-show="!isBusiness()"></IconLabel>
                    <IconLabel :label="$t('sidebar.popup.departments')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               :icon="getAssetPath('departments.svg')"
                               :apply-icon-filter="false"
                               @click="handleSettingClick('departments')" v-show="isBusiness()"></IconLabel>

                    <IconLabel :label="$t('sidebar.popup.powers_of_attorney')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               :icon="require('@/assets/power_of_attorney.svg')"
                               :apply-icon-filter="false"
                               @click="handleSettingClick('powers_of_attorney')"></IconLabel>

                    <IconLabel
                        v-if="this.getCurrentUser().account_pay_type==='paid' || this.getCurrentUser().plan_uid >'0' "
                        :label="$t('sidebar.popup.billing')" class="mt-3" :selected="false" :blackText="true"
                        :icon="require('@/assets/billing_gray.svg')"
                        v-show="showBilling"
                        iconStyle="blackish"
                        :apply-icon-filter="false"
                        @click="handleSettingClick('billing')"></IconLabel>

                    <IconLabel v-else :label="$t('sidebar.popup.billing')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               :icon="require('@/assets/billing_gray.svg')"
                               v-show="showBilling"
                               @click="handleSettingClick('billing_trial')"></IconLabel>
                    <hr class="line" v-show="hasAccess('user_management')"/>
                    <IconLabel :label="$t('sidebar.popup.user_management')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               v-show="hasAccess('user_management')"
                               :apply-icon-filter="false"
                               :icon="require('@/assets/user_management_gray.svg')"
                               @click="handleSettingClick('user_management')"></IconLabel>
                    <IconLabel :label="$t('sidebar.popup.system_settings')" class="mt-3" :selected="false"
                               :blackText="true"
                               iconStyle="blackish"
                               v-show="hasAccess('system_settings')"
                               :apply-icon-filter="false"
                               :icon="require('@/assets/system_settings_gray.svg')"
                               @click="handleSettingClick('system_settings')"></IconLabel>
                    <IconLabel :label="$t('sidebar.popup.protocol')" class="mt-3" :selected="false" :blackText="true"
                               :icon="require('@/assets/log_protocol_gray.svg')"
                               v-show="hasAccess('protocol')"
                               :apply-icon-filter="false"
                               iconStyle="blackish"
                               @click="handleSettingClick('protocol')"></IconLabel>
                    <IconLabel :label="$t('sidebar.popup.communication')" class="mt-3" :selected="false" :blackText="true"
                               :icon="require('@/assets/letter_gray.svg')"
                               v-show="hasAccess('communication')"
                               :apply-icon-filter="false"
                               iconStyle="blackish"
                               @click="handleSettingClick('communications')"></IconLabel>
                    <hr class="line"/>
                    <IconLabel class="mb-4 mt-3" :label="$t('sidebar.popup.logout')" :selected="false" :blackText="true"
                               :icon="require('@/assets/logout_gray.svg')"
                               :apply-icon-filter="false"
                               iconStyle="blackish"
                               @click="handleSettingClick('logout')"></IconLabel>
                  </div>
                </div>
                <div class="hrline" v-show="hasAccess('billing')"/>
                <div class=" mt-4  w-full " v-show="hasAccess('billing')">
                  <div class="flex flex-col h-full  justify-between">
                    <div class="w-full h-full"
                         v-if="this.getCurrentUser().account_pay_type=='paid' || this.getCurrentUser().plan_uid >'0' ">
                      <div class="flex justify-between items-center ">
                        <IconLabel class="-mt-1 " style="cursor:initial" :label="$t('billing_trial.current_package')"
                                   :blackText="true"
                                   :apply-icon-filter="false"
                                   iconStyle="blackish"
                                   :icon="getAssetPath('card_gray.svg')"/>
                        <label class="andern mr-2 cursor-pointer "
                               @click="handleSettingClick('billing')">{{ $t('billing_subscribe.to_change') }}</label>
                      </div>
                      <div class="ml-14 -mt-2 flex flex-col justify-start items-start">
                        <label class="profesional ">{{ getCurrentPlanName }}</label>
                        <label class="profesional">{{ $t('settings.billing.bis') }} {{ getCurrentPlanEndDate }}</label>
                      </div>
                    </div>
                    <div class="w-full h-full" v-else>
                      <div class="flex justify-between items-center ">
                        <IconLabel class="-mt-1 " style="cursor:initial" :label="$t('billing_trial.current_package')"
                                   :blackText="true"
                                   :apply-icon-filter="false"
                                   iconStyle="blackish"
                                   :icon="getAssetPath('card_gray.svg')"/>
                        <label class="andern mr-2 cursor-pointer "
                               @click="handleSettingClick('billing_trial')">{{
                            $t('billing_subscribe.to_change')
                          }}</label>
                      </div>
                      <div class="ml-14 -mt-2 flex flex-col justify-start items-start">
                        <label class="profesional ">Testphase</label>
                        <label class="profesional">{{ $t('settings.billing.bis') }}
                          {{ getCurrentPlanTrialEndDate }}</label>
                      </div>
                    </div>
                    <a class="settings_link"
                       href="https://grundsteuer-digital.de/datenschutz-grundsteuerdigital-anwendung/" target="_blank">{{
                        $t('settings.privacy_link')
                      }}</a>
                    <a class="settings_link mb-2" href="https://grundsteuer-digital.de/impressum/"
                       target="_blank">{{ $t('settings.imprint_link') }}</a>
                    <hr class="line mr-1 ml-1"/>
                    <div class=" flex justify-end items-center">
                      <hr/>
                      <div class="flex justify-between w-full">
                        <label class="konto mt-1 mb-3 mr-2 ml-3">{{ getVersion }}</label>
                        <label class="konto mt-1 mb-3 mr-3">{{ $t('sidebar.popup.user_id') }}: {{
                            getCurrentAccountId
                          }}</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <IconLabel :label="$t('sidebar.settings')" :selected="settingsSelected"
                         :icon="getAssetPath('cog.svg')"
                         :apply-icon-filter="false"
                         @click="showSettingsMenu"></IconLabel>
              <img v-if="!isWeb" class="dot" src="@/assets/dot_red.svg"/>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </transition>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {directive as onClickaway} from "vue-clickaway";
import OverlayPanel from 'primevue/overlaypanel';
import IconLabel from "../labels/IconLabel";
import {appMixin} from "@/core/utils/mixin";
import {SearchService} from "@/services/search.service";
import ProgressBar from "primevue/progressbar";
import {UserService} from "@/services/user.service";
import NotificationItem from "@/components/Dashboard/Items/NotificationItem";
import Tooltip from 'primevue/tooltip';

export default {
  directives: {
    onClickaway: onClickaway,
    'tooltip': Tooltip
  }, mixins: [appMixin],
  name: "Sidebar",
  components: {IconLabel, OverlayPanel, ProgressBar, NotificationItem},
  props: {
    navClosed: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    },
    settingsSelected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new SearchService(),
      userService: new UserService(),
      clickedOutSideCount: 0,
      settingsActive: false,
      showOverlay: false,
      searchTimeout: null,
      searchTxt: '',
      searchCompleted: false,
      searchResult: {
        documents: [],
        clients: [],
        properties: [],
        declarations: []
      },
      requestCancelTokens: {
        "client": null,
        "document": null,
        "properties": null,
        "declarations": null
      },
      searching: false,
      notifications: []
    }
  },
  computed: {
    ...mapGetters("user", {
      "newNotificationCount": "getNewNotificationCount",
      "notificationCount": "getNotificationCount"
    }),
    getVersion() {
      let version = "v" + process.env.PACKAGE_VERSION;
      if (process.env.PACKAGE_REV != null) {
        version = version + '-' + process.env.PACKAGE_REV.substring(0, 8);
      }
      return version;
    },
    getCurrentUserName() {
      return this.getCurrentUser().name;
    },
    getCurrentUserCompanyName() {
      return this.getCurrentUser().company_name;
    },
    getCurrentAccountId() {
      return this.getCurrentUser().account_id;
    },
    getCurrentPlanName() {
      return this.getCurrentUser().plan_name;
    },
    getCurrentPlanEndDate() {
      return this.getCurrentUser().end_date;
    },
    getCurrentPlanTrialEndDate() {
      return this.getCurrentUser().trial_end;
    },
    getProfilePic() {
      return this.getCurrentUser().userProfilePic ? this.getCurrentUser().userProfilePic : this.getAssetPath('user.svg')
    },
    hideBilling() {
      return !this.hasAccess('billing');
    },
    showQuickAdd() {
      if (this.navClosed === false) {
        return false
      } else {
        return this.getCurrentUser.role !== 'it_administrator'
      }
    },
    fetchNotifications() {
      if (this.notifications.length === 0 && this.notificationCount > 0) {
        return true;
      }

      return false;
    },
    showBilling() {
      if (this.getCurrentTheme() === 'ebnerstolz') {
        return false
      } else {
        return this.hasAccess('billing')
      }
    }
  },
  methods: {
      ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
      ...mapMutations("search", ["setSearchTxt"]),
      ...mapMutations("user", ["setNewNotificationCount"]),
      getTheme() {
          return this.getCurrentTheme()
      },
      showMoreNotification() {
          this.$router.push('/notifications')
      },
      handleLogoClick() {
          this.$router.push("/home")
      },
      hide() {
          if (this.widget) this.widget.hide()
      },
      togglePanel(event) {
          this.$refs.op.toggle(event);
      },
      toggleNotificationPanel(event) {
      this.$refs.notification.toggle(event);
      if (this.fetchNotifications) {
        this.userService.listNotifications().then((notifications) => {
          this.notifications = notifications;
        });
      }
    },
    closeMenu() {
      this.showOverlay = false
      this.clickedOutSideCount = 0
    },
    showAllSearch() {
      this.$router.push('/search');
    },
    toggle() {
      this.$emit('toggle')
    },
    handleClick(data) {
      if(data.slug === 'deadline' && !(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_deadline_control))) {
        this.showPackageLimitWarning();
        return false;
      }

      this.$emit('click', {submenuClicked: false, data: data});
    },
    handleSubmenu(data, parent) {
      if((data.slug === 'review_notice' || data.slug === 'upload_notice') && !(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_notice_review))) {
        this.showPackageLimitWarning();
        return false;
      }

      if (parent) {
        data.parent = parent;
      }
      this.$emit('click', {submenuClicked: true, data: data})
    },
    handleSettingClick(data) {
      this.closeMenu();
      this.$emit('clickSetting', data)
    },
    onClickOutside() {
      this.closeMenu();
    },
    showSettingsMenu() {
      this.showOverlay = !this.showOverlay;
    },
    handlePopUp(event) {
      this.togglePanel(event)
      this.$emit('popClick', 'document');
    },
    showResult() {
      if (this.$route.name !== 'search') {
        this.$router.push('/search');
      }
    },
    search(event) {
      this.searchCompleted = false;

      if (this.$route.name !== 'search') {
        this.$refs.opsearch.show(event)
      }

      this.setSearchTxt(this.searchTxt);

      clearTimeout(this.searchTimeout);

      if (this.searchTxt.length >= 3) {
        this.searching = true;
        this.searchTimeout = setTimeout(() => this.triggerSearch(), 800);
      } else {
        this.searching = false;
        this.abortSearch();
      }
    },
    triggerSearch() {
      this.abortSearch();

      // Search documents
      this.requestCancelTokens.document = this.service.getCancelSource();
      this.searchResult['documents'] = [];
      this.service.searchDocuments(this.searchTxt, 2, false).then((result) => {
        if (result.length > 0) {
          this.searchResult['documents'] = result;
        }
        this.searchCompleted = true;
        this.searching = false;
      })

      // search client
      this.requestCancelTokens.client = this.service.getCancelSource();
      this.searchResult['clients'] = [];
      this.service.searchClient(this.searchTxt, 2, false).then((result) => {
        if (result.length > 0) {
          this.searchResult['clients'] = result;
        }
        this.searchCompleted = true;
        this.searching = false;
      })

      // Search properties
      this.requestCancelTokens.properties = this.service.getCancelSource();
      this.searchResult['properties'] = [];
      this.service.searchProperties(this.searchTxt, 2, false).then((result) => {
        if (result.length > 0) {
          this.searchResult['properties'] = result;
        }
        this.searchCompleted = true;
        this.searching = false;
      });

      // Search Declarations
      this.requestCancelTokens.declarations = this.service.getCancelSource();
      this.searchResult['declarations'] = [];
      this.service.searchDeclarations(this.searchTxt, 2, false).then((result) => {
        if (result.length > 0) {
          this.searchResult['declarations'] = result;
        }
        this.searchCompleted = true;
        this.searching = false;
      })
    },
    abortSearch() {
      if (this.requestCancelTokens.document) {
        this.requestCancelTokens.document.cancel();
      }
      if (this.requestCancelTokens.client) {
        this.requestCancelTokens.client.cancel();
      }
      if (this.requestCancelTokens.properties) {
        this.requestCancelTokens.properties.cancel();
      }
      if (this.requestCancelTokens.declarations) {
        this.requestCancelTokens.declarations.cancel();
      }
    },
    notificationClicked(e) {
      if (e.id) {
        this.$refs.notification.toggle(e);
      }
    },
    markAsRead() {
      this.userService.readAllNotifications().then((response) => {
        if (response.data.success) {
          this.notifications = this.notifications.map((notification) => {
            if (!notification.isRead) {
              notification.isRead = 1;
            }

            return notification;
          });
          this.setNewNotificationCount(response.data.new_notification_count);
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 250px;
  height: 281px;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.line {
  width: 93%;
  padding: 2px 2px 2px 2px;
  color: #dfdfdf;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-left: 10px;
}

.submenu {
  @include segoe-regular;
  height: 18px;
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #ffffff;
  text-align: left;
  line-height: 19px;
  cursor: pointer;

  &.text_black {
    color: #000000;
  }
}

.sidebar {
  width: 300px;
  height: 100%;
  background-color: var(--sidebar-color);
  color: $sidebar-text-color;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  z-index: 6;
}


.shadow {
  border-radius: 1px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
}

.image {
  width: 520px;
  height: 105px;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 1px solid white !important;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid white;
  }
}

.child-img {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  left: 8px;
  right: 0;

  &.nav-bottom {
    left: 29px;
  }
}

.small-logo {
  height: 30px
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.container {
  width: auto;
  height: 60px;
  background-color: $primary;
  // position: relative;
  // margin: 30px;
}

.box {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #229d56;
  margin-bottom: -55px;
}

.overlaynew {
  width: 480px;
  height: auto;
  margin-left: 25px;
  bottom: 70px;
  //top: 150px;
  text-align: center;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  display: block;
  position: fixed;
  z-index: 99;
}

.hideBilling {
  width: 240px;
}


//.showhim:hover .overlaynew {
//  display: block;
//  //position: absolute;
//}
.hidden {
  width: 0px;
  height: 0px;
  display: none;
}

.hrline {
  background-color: #dfdfdf;
  color: #dfdfdf;
  width: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.konto {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: 38px;
  font-size: 10px;
}

.andern {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 38px;
  font-size: 12px;
}

.profesional {
  background-color: rgba(255, 255, 255, 0);

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 13px;
}

.menu {
  width: 223px;
  height: 176px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #797979;
  background-color: #ffffff;
  box-sizing: border-box;
}

.bell_gray {
  width: 16px;
  height: 16px;
}

/*::-webkit-scrollbar {
  width: 1.4vw;
  height: 3.3vh;
}

::-webkit-scrollbar-track {
  //background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $gray;
  background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
  );
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ec8f02;
}*/

.title {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 17px;
}

.all-search {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: $primary;
  text-align: center;
  font-size: 15px;
}

.user-img {
  width: 23px;
  height: 23px;
  box-sizing: border-box;
  margin-left: 18px;
  border-radius: 10px;
}


.dot {
  width: 10px;
  height: 10px;
  padding: 2px 2px 2px 2px;

  box-sizing: border-box;
}

.search-result {
  min-width: 300px;
}

.search-el-container {
  position: relative;

  .pi-spinner {
    position: absolute;
    right: 25px;
    color: #fff;
    top: 8px;
  }

  .searching_bg {
    background: none;
  }
}

.scroll-height {
    height: 300px;
}

.more_btn {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: $primary;
  text-align: left;
}

.settings_link {
  font-size: 11px;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.4);
  text-align: right;
  margin-right: 6px;
  line-height: normal;
  cursor: pointer;
  margin-top: 11px;
}

.text-link {
  font-family: "Segoe UI", sans-serif;
  color: #229d56;
  font-size: 13px;
}
.submenu_icon {
  width: 16px;
  height: 9px;
  padding: 2px 2px 2px 2px;
  margin-right: 20px;
  margin-top: 5px;
}
.submenu_icon.open {
  transform: rotate(180deg);
}

</style>
