import {i18n} from '@/plugins/i18n';

const state = {
    data: {
        step3: {
            areaOfTheLand1: ''
        }
    },
    importData: [],
    importAttr: '',
    propertyUid: 0,
    flowSteps: [],
    availableFlowSteps: [],
    addFlowSteps: [],
    excelError : [],
    currentTaxModel: '',
    currentStep: 1,
    sumOfAreas: 0, // sum of parcels
    //clientUid: 0,
    filePath: '',
    propertyDataChanged: false,

    clientPersons: []
};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getCurrentTaxModel: (state) => state.currentTaxModel,
    getFlowSteps: (state) => state.flowSteps,
    getAddFlowSteps: (state) => state.addFlowSteps,
    getAvailableFlowSteps: (state) => state.availableFlowSteps,
    getSteps: (state) => state.data,
    getStepClient: (state) => state.data.step1,
    getStepAddress: (state) => state.data.step2,
    getStepDetails: (state) => state.data.step2a,
    getStepPlot: (state) => state.data.step3,
    getStepExemptionBenefit: (state) => state.data.step3a,
    getStepCommunity: (state) => state.data.step4,
    getStepOwner: (state) => state.data.stepOwner,
    getStepBuilding: (state) => state.data.step5,
    getStepResidential: (state) => state.data.step6,
    getStepNonResidential: (state) => state.data.step7,
    getStepLeasehold: (state) => state.data.step8,
    getStepAgriculture: (state) => state.data.step9,
    getStepAnimal: (state) => state.data.step10,
    getImportData: (state) => state.importData,
    getImportAttr: (state) => state.importAttr,
    getPropertyUid: (state) => state.propertyUid,
    getExcelError: (state) => state.excelError,
    getFilePath: (state) => state.filePath,
    getPropertyDataChanged: (state) => state.propertyDataChanged,
    getClientPersons: (state) => state.clientPersons
}

const mutations = {
    init: (state) => {
        state.currentStep = 1;
        state.flowSteps = [
            {
                id: 1,
                title: i18n.t('properties.steps.step1'),
                completed: false,
                active: true,
                show: true,
                first: true,
                component: "StepClient"
            }, {
                id: 2,
                title: i18n.t('properties.steps.step2'),
                completed: false,
                active: false,
                show: true,
                component: "StepAddress"
            }, {
                id: 3,
                title: i18n.t('properties.steps.step2a'),
                completed: false,
                active: false,
                show: true,
                component: "StepDetails"
            }, {
                id: 4,
                title: i18n.t('properties.steps.step3'),
                completed: false,
                active: false,
                show: true,
                last: false,
                component: "StepPlot"
            }, {
                id: 5,
                title: i18n.t('properties.steps.step3a'),
                completed: false,
                active: false,
                show: true,
                last: false,
                component: "StepExemptionBenefit"
            }, {
                id: 6,
                title: i18n.t('properties.steps.step4'),
                completed: false,
                active: false,
                show: true,
                component: "StepCommunity"
            }, {
                id: 7,
                title: i18n.t('properties.steps.step_owner'),
                completed: false,
                active: false,
                show: true,
                component: "StepOwner"
            }, {
                id: 8,
                title: i18n.t('properties.steps.step5'),
                completed: false,
                active: false,
                show: true,
                component: "StepBuilding"
            }, {
                id: 9,
                title: i18n.t('properties.steps.step6'),
                completed: false,
                active: false,
                show: true,
                component: "StepResidential"
            }, {
                id: 10,
                title: i18n.t('properties.steps.step7'),
                completed: false,
                active: false,
                show: true,
                component: "StepNonResidential"
            }, {
                id: 11,
                title: i18n.t('properties.steps.step8'),
                completed: false,
                active: false,
                show: true,
                component: "StepLeasehold"
            }, {
                id: 12,
                title: i18n.t('properties.steps.step9'),
                completed: false,
                active: false,
                show: true,
                component: "StepAgriculture"
            }, {
                id: 13,
                title: i18n.t('properties.steps.step10'),
                completed: false,
                active: false,
                show: true,
                component: "StepAnimal"
            }
        ];

        state.addFlowSteps = state.flowSteps.filter((o) => {
            return o.id < 5;
        });

        state.addFlowSteps = state.addFlowSteps.map((x, i) => {
            if (i === state.addFlowSteps.length - 1) {
                return {...x, last: true}
            } else {
                return {...x, last: false}
            }
        });

        state.availableFlowSteps = state.flowSteps.filter((o) => {
            return typeof o.show === 'undefined' || o.show === true;
        });

        state.availableFlowSteps = state.availableFlowSteps.map((x, i) => {
            if (i === state.availableFlowSteps.length - 1 || i === 2) {
                return {...x, id: i + 1, last: true}
            } else if (i === 0) {
                return {...x, id: i + 1, first: true, last: false}
            } else {
                return {...x, id: i + 1, last: false}
            }
        });

        state.data = {
            id: "0",
            step1: {
                clientUid: "0"
            },
            step2: {
                federalStateUid: '',
                community: '',
                street: '',
                houseNumber: '',
                zip: '',
                city: '',
            },
            step2a: {
                reference: '',
                name: '',
                economicEntityType: '',
                exemption: "0",
                extraDetails: '',
                internalNote: ''
            },
            step3: {
                ownershipStructure: "-1",
                propertyType: "0",
                developmentState: '',
                multiCommunities: "0",
                leasehold: "0",
                buildingOnForeignGround: "0",
                buildingsOnThirdPartyOwners: "0",
                propertyResidentialPurposes: "0",
                totalPropertyPart: "0",
                registrationLandRegistry: "",
                areaOfTheLand1: '',
                areaOfTheLandValue1: '',
                areaOfTheLandTotal: '',
                locatedInOutdoorArea1: '0',
                areaOfTheLand2: '',
                areaOfTheLandValue2: '',
                locatedInOutdoorArea2: '0',
                liftingRate: '',
                parcels: []
            },
            step3a: {
                taxExemption: '0',
                benefit: '0',
                monument_identification : false,
                nonSeparablePartRealEstate: '0',
                expirationFirstGrantPeriod: '',
                expirationLastGrantPeriod: '',
                expectedExemptPurposes: '',
                taxPrivilegedPurposes: '0',
                unbuiltRealEstateExemptions: [{
                    primUid: '0',
                    unbuiltRealEstateExemptionDescription: '',
                    taxFreeArea: '',
                    unbuiltRealEstateTaxExemption: '0'
                }],
                useOfDefinableParts: [{
                    primUid: '0',
                    spatiallyDefinableExemptionPartDescription: '',
                    spatiallyDefinableExemptionArea: '',
                    spatiallyDefinableExemptionLandOrLiving: '',
                    spatiallyDefinableTaxExemptionPart: ''

                }],
                taxBreakConditions: [{
                    primUid: '0',
                    spatiallyDefinableExemptionPartDescription: '',
                    spatiallyDefinableExemptionArea: '',
                    spatiallyDefinableExemptionLandOrLiving: '',
                    spatiallyDefinableTaxExemptionPart: ''
                }]
            },
            step4: {
                salutation: '0',
                name: '',
                street: '',
                houseNumber: '',
                zip: '',
                postbox: '',
                city: '',
                countryUid: '0'
            },
            stepOwner: [{
                salutation: '0',
                title: '',
                dateOfBirth: null,
                firstName: '',
                lastName: '',
                street: '',
                houseNumber: '',
                phone: '',
                zip: '',
                postbox: '',
                city: '',
                countryUid: '0',
                financeAgency: '',
                taxNumber: '',
                taxIdentificationNumber: '',
                area_of_the_land_counter: '',
                area_of_the_land_denominator: '',
                representative: {
                    salutation: '0',
                    title: '',
                    dateOfBirth: null,
                    firstName: '',
                    lastName: '',
                    street: '',
                    houseNumber: '',
                    phone: '',
                    zip: '',
                    postbox: '',
                    city: '',
                    countryUid: '0'
                }
            }],
            step5: [{
                livingSpaceDescription: '',
                livingSpaceArea: '',
                usableSpaceArea: '',
                benefitDescription: '',
                benefitLivingSpaceArea: '',
                benefitUsableSpaceArea: '',
                benefit: '0',
                exemptionDescription: '',
                exemptionLivingSpaceArea: '',
                exemptionUsableSpaceArea: '',
                exemption: '0',
                areaOfTheLandCivilDefense: ''
            }],
            step6: [{
                residential: {
                    yearOfConstruction: '',
                    coreRenovationYear: '',
                    demolitionObligationYear: ''
                },
                garageUnderground: {
                    amountGarages: '',
                    garageUndergroundExemption: [{
                        locationOfTheRoomsDesignation: '',
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    garageUndergroundBenefit: [{
                        locationOfTheRoomsDesignation: '',
                        benefit: '0',
                        discountedArea: ''
                    }]
                },
                apartmentsBelow60: {
                    counter: '',
                    totalLivingSpace: '',
                    apartmentsBelow60Exemption: [{
                        locationOfTheRoomsDesignation: '',
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    apartmentsBelow60Benefit: [{
                        locationOfTheRoomsDesignation: '',
                        benefit: '0',
                        discountedArea: ''
                    }]
                },
                apartmentsBetween60100: {
                    counter: '',
                    totalLivingSpace: '',
                    apartmentsBetween60100Exemption: [{
                        locationOfTheRoomsDesignation: '',
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    apartmentsBetween60100Benefit: [{
                        locationOfTheRoomsDesignation: '',
                        benefit: '0',
                        discountedArea: ''
                    }]
                },
                apartmentsAbove100: {
                    counter: '',
                    totalLivingSpace: '',
                    apartmentsAbove100Exemption: [{
                        locationOfTheRoomsDesignation: '',
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    apartmentsAbove100Benefit: [{
                        locationOfTheRoomsDesignation: '',
                        benefit: '0',
                        discountedArea: ''
                    }]
                },
                apartmentsOther: {
                    counter: '',
                    totalLivingSpace: '',
                    apartmentsOtherExemption: [{
                        locationOfTheRoomsDesignation: '',
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    apartmentsOtherBenefit: [{
                        locationOfTheRoomsDesignation: '',
                        benefit: '0',
                        discountedArea: ''
                    }]
                },
                usableSpaces: {
                    usageType: '',
                    totalSpace: '',
                    locationOfTheRoomsDesignation: '',
                    usableSpacesExemption: [{
                        exemption: '0',
                        taxFreeArea: ''
                    }],
                    usableSpacesBenefit: [{
                        benefit: '0',
                        discountedArea: ''
                    }]
                }
            }],
            step7: [{
                locationPlanNumber: '',
                buildingType: '0',
                yearOfConstruction: '',
                coreRenovationYear: '',
                demolitionObligationYear: '',
                areaOfTheLand: '',
                areaOfTheLandCivilDefense: '',
                exemption: '0',
                taxFreeArea: '',
                benefit: '0',
                discountedArea: ''
            }],
            step8: [{
                formOfAddress: '0',
                titleAcademicDegree: '',
                telephoneNumber: '',
                firstName: '',
                lastName: '',
                companyName: '',
                street: '',
                houseNumber: '',
                zip: '',
                city: '',
                postbox: '',
                countryUid: '0'
            }],
            step9: [{
                corridor: '',
                counter: '',
                denominator: '',
                areaOfTheLand: '',
                usageTypes: [{
                    usageType: '0',
                    areaOfTheLand: '',
                    yieldMeasurementNumber: '',
                    areaOfFarmBuildings: '',
                    flowRate: ''
                }]
            }],
            step10: {
                ownedSpace: '',
                minusLeasedAreas: '',
                plusLeasedAreas: '',
                selfCultivatedAreas: '',
                alpacas: '',
                damAnimal_below1Year: '',
                damAnimal_above1Year: '',
                layingHens_layingHensIncludeBreeding: '',
                layingHens_purchasedLayingHens: '',
                poultry: '',
                breedingRabbits: '',
                llamas: '',
                horses_below3YearsOrSmallHorses: '',
                horses_horses: '',
                beef_below1Year: '',
                beef_1to2Year: '',
                beef_above2Year: '',
                fatteningAnimalsLessThan1Year: '',
                cows: '',
                breedingBulls: '',
                sheep_below1Year: '',
                sheep_above1Year: '',
                breedingPigs: '',
                ostriches_above14Months: '',
                ostriches_below14Months: '',
                goats: '',
                youngChickensForFattening_below6Passes: '',
                youngChickensForFattening_above6Passes: '',
                pullets: '',
                fatteningDucks_count: '',
                fatteningDucks_raisingPhase: '',
                fatteningDucks_fatteningPhase: '',
                turkeys_selfRaised: '',
                turkeys_bought: '',
                turkeys_youngTurkeys: '',
                fattenedGeese: '',
                mastRabbit: '',
                beefCattle: '',
                pigs_lightPiglets: '',
                pigs_piglets: '',
                pigs_heavyPiglets: '',
                pigs_runner: '',
                pigs_heavyRunner: '',
                pigs_fatteningPigs: '',
                pigs_youngBreedingPigs: '',
                boughtPigs_lightPiglets: '',
                boughtPigs_piglets: '',
                boughtPigs_heavyPiglets: '',
                boughtPigs_runner: '',
                boughtPigs_heavyRunner: ''
            }
        };
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setCurrentTaxModel: (state, model) => {
        state.currentTaxModel = model
    },
    moveStep(state, direction) {
        if (direction === 'finish') {
            state.addFlowSteps = state.addFlowSteps.map(x => {
                return {...x, completed: true, active: false}
            })
        } else {
            let step = direction === 'next' ? state.currentStep + 1 : state.currentStep - 1;
            let index = state.addFlowSteps.findIndex(item => item.id === step);
            state.addFlowSteps = state.addFlowSteps.map((x, i) => {
                if (i === index) {
                    return {...x, completed: false, active: true}
                } else if (i > index) {
                    return {...x, completed: false, active: false}
                } else if (i < index) {
                    return {...x, completed: true, active: false}
                }
            });

            state.currentStep = step;
        }
    },
    refreshSteps(state) {
        state.availableFlowSteps = state.flowSteps.filter((o) => {
            return typeof o.show === 'undefined' || o.show === true;
        });

        let index = state.availableFlowSteps.findIndex(item => item.id === state.currentStep);
        state.availableFlowSteps = state.availableFlowSteps.map((x, i) => {
            if (i === index) {
                return {...x, id: i + 1, completed: false, active: true}
            } else if (i > index) {
                return {...x, id: i + 1, completed: false, active: false}
            } else if (i < index) {
                return {...x, id: i + 1, completed: true, active: false}
            }
        });
        state.availableFlowSteps = state.availableFlowSteps.map((x, i) => {
            if (i === state.availableFlowSteps.length - 1) {
                return {...x, last: true}
            } else if (i === 0) {
                return {...x, first: true, last: false}
            }  else {
                return {...x, last: false}
            }
        });
    },
    setStepExemptionBenefitShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepExemptionBenefit') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepCommunityShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepCommunity') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepOwnerShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepOwner') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepBuildingShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepBuilding') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepResidentialShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepResidential') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepNonResidentialShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepNonResidential') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepLeaseholdShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepLeasehold') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepAgricultureShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepAgriculture') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    setStepAnimalShow: (state, show) => {
        state.flowSteps = state.flowSteps.map((x) => {
            if (x.component === 'StepAnimal') {
                return {...x, show: show}
            } else {
                return {...x}
            }
        });
    },
    saveSubArea: (state, subArea) => {
        state.data.subArea = subArea;
    },
    saveDeviatingOwner: (state, deviatingOwner) => {
        state.data.deviatingOwner = deviatingOwner;
    },
    saveClientPersons: (state, persons) => {
        state.clientPersons = persons;
    },
    saveStepClient: (state, step1) => {
        state.data.step1 = step1;
    },
    saveStepAddress: (state, step2) => {
        state.data.step2 = step2;
    },
    saveStepDetails: (state, step2a) => {
        state.data.step2a = step2a;
    },
    saveStepPlot: (state, step3) => {
        state.data.step3 = step3;
    },
    saveStepPlotParcels: (state, parcels) => {
        state.data.step3.parcels = parcels;
    },
    saveStepAnimal: (state, step10) => {
        state.data.step10 = step10;
    },
    setProperty: (state, data) => {
        state.data = data;
    },
    setImportData: (state, rows) => {
        state.importData = rows;
    },
    setImportAttr: (state, attr) => {
        state.importAttr = attr;
    },
    setPropertyUid: (state, uid) => {
        state.propertyUid = uid;
    },
    setExcelError: (state, rows) => {
        state.excelError = rows;
    },
    setFilePath: (state, data) => {
        state.filePath = data;
    },
    setPropertyDataChanged: (state, data) => {
        state.propertyDataChanged = data;
    }
};

const actions = {};
export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
