const state = {
    list: [],
    myList: [],
    branchData: { },
    currentStep: 1
};
const getters = {
    getBranchData: (state) => state.branchData,
    getCurrentStep: (state) => state.currentStep,
    getBranchList: (state) => state.list,
    getMyBranchList: (state) => state.myList
}

const mutations = {
    init: (state) => {
        state.branchData = {
            uid: 0,
            name: '',
            email: '',
            street: '',
            zip: '',
            city: '',
            power_of_attorney: '',
            selected_users: []
        };
        state.currentStep = 1;
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    setBranchData: (state, branch) => {
        state.branchData = {...state.branchData, ...branch};
    },
    setBranchList: (state, branches) => {
        state.list = branches;
    },
    setMyBranchList: (state, branches) => {
        state.myList = branches;
    },
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



