<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label class="w-28">{{ $t('declarations.mass_update.steps.process_type') }}:</label>
          <label class="ml-5">{{ processType }}</label>
        </div>
      </div>
      <div v-if="processTypeVal === 'request_internal_approval'">
        <div v-if="fromGrid" class="flex justify-around space-x-1 mt-2">
          <div class="w-full text-left flex">
            <label class="w-28">{{ $t('settings.user_management.user') }}:</label>
            <InputSelect class="ml-11 w-full"
                         :options="usersCanRelease"
                         v-model="assigneeForRelease"
                         :selected-value="assigneeForRelease"
                         :filterable="true"
            />
          </div>
          <div class="w-full pr-6 invisible"/>
        </div>
        <div v-else class="flex justify-around space-x-1">
          <div class="w-full text-left flex">
            <label class="w-28">{{ $t('settings.user_management.user') }}:</label>
            <label class="ml-5">{{ assigneeNameForRelease }}</label>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex w-full">
          <label class="text ">{{ $t("declarations.mass_update.selected_declarations_list") }}</label>
        </div>
        <div class="flex justify-around space-x-1 mt-2">
          <DataGrid grid-id="grid-selected-declarations"
                  :local-data="selectedDeclarations"
                  :columns="columns"
                  :pagination="false"
                  default-sort-attr="name"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
          />
        </div>
        <div class="box flex justify-start items-center mb-10">
          <img class="image ml-2" :src="getAssetPath('warning.svg')"/>
          <label class="text-left ml-2">{{ $t('declarations.mass_update.save_warning_info') }}</label>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('declarations.mass_update.execute')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import {UserService} from "@/services/user.service";

export default {
  name: "Step7",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fromGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDeclarations: [],
      columns: [
        {
          field: 'declarationId',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          sortable: false,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        }, {
          field: 'name',
          header: this.$t('declarations.mass_update.declaration'),
          isChecked: true,
          sortable: false
        }
      ],
      usersCanRelease: [],
      assigneeForRelease: "0"
    }
  },
  watch: {
    active(newValue) {
      if(newValue) {
        if (this.getSteps().selectedDeclarations && this.getSteps().selectedDeclarations.length > 0) {
          this.selectedDeclarations = JSON.parse(JSON.stringify(this.getSteps().selectedDeclarations));
          this.triggerReload('grid-selected-declarations');
        }
      }
    }
  },
  computed: {
    processType() {
      return this.getSteps().processType ? this.$t('declarations.mass_update.process')[this.getSteps().processType] : '';
    },
    processTypeVal() {
      return this.getSteps().processType ? this.getSteps().processType : '';
    },
    assigneeNameForRelease() {
      return this.processTypeVal === 'request_internal_approval' && this.getSteps().assigneeNameForRelease ? this.getSteps().assigneeNameForRelease : '';
    }
  },
  mounted() {
    if (this.getSteps().selectedDeclarations && this.getSteps().selectedDeclarations.length > 0) {
      this.selectedDeclarations = JSON.parse(JSON.stringify(this.getSteps().selectedDeclarations));
      this.triggerReload('grid-selected-declarations');
    }

    if(this.fromGrid) {
      let userService = new UserService();
      userService.listAll().then((list) => {
        this.usersCanRelease = list.filter((o) => {
          return o.permissions.release_declaration && o.permissions.release_declaration === "1";
        }).map((item) => {
          return {
            "name": item.name,
            "code": item.id
          };
        });

        if(this.usersCanRelease.length === 1) {
          this.assigneeForRelease = this.usersCanRelease[0].code;
        } else {
          this.assigneeForRelease = "0";
        }
      });
    }
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    submitNext() {
      if(this.fromGrid && this.processTypeVal === 'request_client_approval') {
        let invalid = [];
        for (let item in this.selectedDeclarations) {
          if(this.selectedDeclarations[item].elsterStatus === "2") {
            invalid.push(this.selectedDeclarations[item]);
          }
        }

        if(invalid.length > 0) {
          this.$api.showToast(this.$t('declarations.mass_update.invalid_elster_status_all') + ': ' + invalid.map(items => items.declarationId).join(', '), "error");
          return;
        }
      }

      if(this.fromGrid && this.processTypeVal === 'request_internal_approval') {
        if(this.assigneeForRelease === '0') {
          return ;
        }

        this.saveSteps({
          "assigneeForRelease": this.assigneeForRelease,
          "assigneeNameForRelease" : this.assigneeNameForRelease
        })
      }

      this.$emit("finish")
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
::v-deep {
  .property-type-cell {
    padding-left: 0px !important;
  }
}
</style>
