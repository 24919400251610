<template>
  <div class=" flex flex-col justify-start rectangle ">
    <!-- header -->
    <Header :title="$t('dashboard.headers.account_setup')" :showDropDown="true" @popupclick="remove"/>
    <div class=" account pl-2 pr-2">
      <p class="bold-header ml-4 mt-6"> {{ $t('dashboard.headings.todo').replace('3', this.total_remaining) }}</p>
      <EmptyList v-if="total_records===0"/>
      <div v-else>
        <div class="progress-bar ml-5 mt-3 mb-5">
          <progress class="w-full" max="100" :value="this.percentageResult"></progress>
        </div>
        <div class="ml-5 space-y-4">
          <InputCheck v-for="todo in this.todosList" :isChecked="todo.isChecked" :isstrike="todo.isChecked"
                      :key="todo.prim_uid" :checkable="!todo.isChecked"
                      @click="clickCheckLabel(todo)" :Routetype="todo.type" :text="$t('dashboard.account_setup.'+todo.type)"
                      @check="updateCheck" :dataTableItem="todo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Dashboard/Header"
import {DashboardService} from "@/services/dashboard.service";
import {mapGetters} from "vuex";

export default {
  name: "ToDoWidget",
  components: {Header},
  props: {
    config: {
      type: Object,
      default: () => {
      }
    },
    widgetId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      todosList: {},
      total_remaining: '',
      total_records: '',
      percentageResult: ''
    }
  },
  mounted() {
    this.todolist()
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    todolist() {
      let service = new DashboardService()
      service.trigger('dashboard/list_todos', {
        'sortField': 'name',
        'sortOrder': 1
      }).then((response) => {
        if (response.data.success) {
          this.total_remaining = response.data.total_remaining
          this.todosList = response.data.records
          this.total_records = response.data.total_records
          this.calculatePercentage(this.total_records, this.total_remaining)
        }
      });
    },
    calculatePercentage(total_records, total_remaining) {
      let completed_records = (total_records - total_remaining);
      this.percentageResult = ((completed_records / total_records) * 100);
    },
    clickCheckLabel(todo) {
      if(todo.isChecked) {
        return false;
      }

      if (todo.type === 'account_setup') {
        this.$router.push('/setting/accountsettings')
      }
      if (todo.type === 'add_user') {
        this.$router.push('/setting/usermanagement')
      }
      if (todo.type === 'add_property') {
        this.$router.push('/property/add')
      }
      if (todo.type === 'booking') {
        this.$router.push('/setting/billing')
      }
      if (todo.type === 'add_branch') {
        if(this.getCurrentUser().settings.tax_office_software && this.getCurrentUser().settings.tax_office_software === 'datev') {
          this.$router.push('/datev/setup_branches');
        } else {
          this.$router.push('/setting/branches')
        }
      }
      if (todo.type === 'tax_software') {
        this.$router.push('/setting/systemsettings')
      }
      if (todo.type === 'data_acquisitions') {
        this.$router.push('/setting/systemsettings/?tab=data_acquisition')
      }
      if (todo.type === 'add_client') {
        this.$router.push('/client/add')
      }
      if (todo.type === 'connect_datev') {
        this.$router.push('/setting/setupDATEV')
      }
    },
    updateCheck(obj) {
      let service = new DashboardService()
      service.trigger('dashboard/update_todos', obj).then((response) => {
        if (response.data.success) {
          this.total_remaining = response.data.data.total_remaining
          this.calculatePercentage(this.total_records, this.total_remaining)
        }
      });
    },
    remove(){
        this.$emit('click', "removeWidget")
    },
  }
}
</script>

<style scoped lang="scss">
.progress-bar {
  width: 90%;
  height: 18px;
  border-radius: 10px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

progress::-webkit-progress-bar {
  background-color: #f2f2f2;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: $primary;
  border-radius: 10px 0px 0px 10px;
}

progress::-moz-progress-bar {
  background-color: $primary;
  border-radius: 10px 0px 0px 10px;
}

.account {
  height: 448px;
  max-height: 561px;

}
</style>
