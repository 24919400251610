const state = {
    currentStep: 1,
    result: {},
};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getResult: (state) => state.result,
    getClients: (state) => state.clients,
}

const mutations = {
    init: (state) => {
        state.result = {
            "imported": 0,
            "failed": 0
        }
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    saveResult: (state, result) => {
        state.result = {...state.result, ...result};
    }
};

const actions = {};


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};



