<template>
  <Dialog :modal="true" :visible.sync="isVisible" :contentStyle="{ flex: '1' }">
    <template #header>
      <h3 class="text-xl font-bold">
        {{ $t("general.automated_invoice_delivery") }}
      </h3>
    </template>

    <div>
      <p>{{ $t('connect_gmi.introduction_modal.introduction_text.line1') }}</p>
      <p>{{ $t('connect_gmi.introduction_modal.introduction_text.line2') }}</p>
      <p>{{ $t('connect_gmi.introduction_modal.introduction_text.line3') }}</p>

      <div class="flex my-8">
        <div class="m-auto">
          <img
            class="w-160"
            :src="getAssetPath('grst_connect_logo.svg')"
          />
        </div>

        <div class="m-auto">
          <img :src="getAssetPath('green_plug_icon.svg')"/>
        </div>

        <div class="m-auto">
          <img
            v-if="usingDatevTaxSoftware"
            :src="getAssetPath('connect_gmi_datev.png')"
          />
          <img
            v-else
            class="w-16"
            :src="getAssetPath('gmi_logo.png')"
          />
        </div>
      </div>

      <p>{{ $t('connect_gmi.others.step1_info') }}</p>
    </div>

    <template v-slot:footer>
      <div class="flex justify-center mx-4">
        <Button
          class="mb-4 mr-4"
          :text="$t('connect_gmi.introduction_modal.connect_later')"
          :secondary="true"
          @click="connectLater"
        />

        <Button
          class="mb-4"
          :icon="getConnectGmiButtonIcon"
          :text="getConnectGmiButtonLabel"
          @click="connectGmi"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import { mapGetters } from 'vuex';

export default {
  name: 'GmiIntroductionModal',
  emits: ['close'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
    getConnectGmiButtonIcon() {
      return this.isGetMyInvoicesConnected
        ? this.getAssetPath('connected_gmi.svg')
        : this.getAssetPath('White_Plug_Icon.svg');
    },
    getConnectGmiButtonLabel() {
      if (this.usingDatevTaxSoftware) {
        return this.isGetMyInvoicesConnected
          ? this.$t('connect_gmi.datev.connected_button_label')
          : this.$t('connect_gmi.datev.button_label');
      }

      return this.isGetMyInvoicesConnected
        ? this.$t('connect_gmi.others.connected_button_label')
        : this.$t('connect_gmi.others.button_label');
    },
    usingDatevTaxSoftware() {
      return this.getCurrentUser().settings.tax_office_software === 'datev';
    },
    isGetMyInvoicesConnected() {
      return this.getCurrentUser().gmi_connected && parseInt(this.getCurrentUser().gmi_connected) === 1;
    },
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    connectGmi() {
      this.isVisible = false;
      this.$router.push('/setting/ConnectGetMyInvoices?step=2');
    },
    connectLater() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
  :deep(.p-dialog) {
    max-width: 700px;

    .p-dialog-content {
      @include segoe-regular;

      height: 100%;
      width: 100%;
      overflow: scroll;
    }

    .p-dialog-footer {
      @apply p-3;
    }
  }
</style>
