<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="this.$t('components.change_management_bulk_add_query_transfer')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class=" px-5 w-full">
        <FormSteps :steps="availableSteps"/>
      </div>
      <div class=" w-full">
        <div class="mt-10 wiz" v-if="completed===false">
          <component
              v-for="(item, index) in availableSteps"
              v-show="item.component && item.active === true"
              :key="`component-${index}`"
              :is="item.component"
              :fromGrid="fromGrid"
              @changeWizard="updateWizard"
              @goToNextStep="goToNextStep"
              @goBack="goBack"
              :active="item.active"
          />
        </div>
      </div>
    </div>
    <div class="w-full mt-8 mr-8">
    </div>
  </div>
</template>

<script>
import FormSteps from "../FormSteps";
import Step1 from '@/components/changemanagement/bulk_add_steps/Step1';
import Step2 from '@/components/changemanagement/bulk_add_steps/Step2';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "BulkAddQuery",
  components: {
    FormSteps,
    Step1,
    Step2,
  },
  data() {
    return {
      breadcrumbs: [{
        title: this.$t('components.change_management_data_query.title'),
        link: ''
      }, {
        title: this.$t('components.change_management_bulk_add_query_transfer'),
        link: ''
      }],
      completed: false,
      fromGrid: false,
      availableSteps: [],
      stepsOfUpdateDeclaration: [
        {
          id: 1,
          title: this.$t('components.change_management_data_query.process.steps.select_properties'),
          completed: false,
          active: true,
          first: true,
          component: 'Step1'
        }, {
          id: 2,
          title: this.$t('components.change_management_data_query.process.steps.control'),
          completed: false,
          active: false,
          last: true,
          component: 'Step2'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('data_query_bulk_add', ['getCurrentStep'])
  },
  mounted() {
    if (
      typeof this.getCurrentUser().permissions !== 'undefined'
      && typeof this.getCurrentUser().permissions.change_management_access !== 'undefined'
      && parseInt(this.getCurrentUser().permissions.change_management_access) === 1
    ) {
      this.init();
      this.availableSteps = this.stepsOfUpdateDeclaration;
      this.setCurrentStep(1);
    } else {
      this.$router.push('/home');
    }
  },
  methods: {
    ...mapMutations('data_query_bulk_add', ['setCurrentStep', 'init', 'saveSteps', 'setNotificationId']),
    ...mapGetters('data_query_bulk_add', ['getSteps']),
    ...mapGetters('user', ['getCurrentUser']),
    updateWizard(e) {
      this.setCurrentStep(1);
      this.availableSteps = this.stepsOfUpdateDeclaration;
    },
    goToNextStep() {
      this.moveStep('next')
    },
    goBack() {
      this.moveStep('back')
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.availableSteps = this.availableSteps.map(x => {
          return {
            ...x,
            completed: true,
            active: false
          }
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = this.availableSteps.findIndex(item => item.id === step)

        this.availableSteps = this.availableSteps.map((x, i) => {
          if (i === index) {
            return {
              ...x,
              completed: false,
              active: true
            }
          } else if (i > index) {
            return {
              ...x,
              completed: false,
              active: false
            }
          } else if (i < index) {
            return {
              ...x,
              completed: true,
              active: false
            }
          }
        });

        this.setCurrentStep(step);
      }
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.wiz ::v-deep .footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
</style>
