import { ApiService } from "./api.service";

export class AppendixService extends ApiService {
    constructor() {
        super();
    }

    upload(formData, is_tax_office_software_datev) {
        return this.trigger(is_tax_office_software_datev ? 'rtfdatev/post' : 'appendix/post', formData, false, 'multipart/form-data');
    }

    get(documentId, is_tax_office_software_datev) {
        return this.trigger(is_tax_office_software_datev ? 'rtfdatev/get' : 'appendix/get', {
            uid: documentId
        }).then((response) => {
            if (response.data.success) {
                return response.data.file;
            } else {
                this.showToast(response.data.message, "error");
                return [];
            }
        })
    }
}
