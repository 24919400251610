<template>
  <div>
    <div class="flex justify-around space-x-10 mt-10">
      <InputCheck :text="$t('properties.sub_area.title')" :isDisabled="isReadOnly" :isChecked="showSubAreaSection"
                  @check="checkedSubArea"/>
    </div>
    <div v-show="showSubAreaSection" class="mt-10">
      <div class="box flex justify-start items-center mb-10 h-auto leading-6 py-5">
        <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
        <label class="text-left ml-2">{{ $t('properties.sub_area.info') }}</label>
      </div>
      <div class="space-y-4">
        <div class="flex justify-around space-x-8 mt-4 ">
          <InputText class="w-full" :label="$t('properties.sub_area.area_of_total_subarea')"
                     v-model="subArea.areaOfTotalSubarea"
                     :disabled="isReadOnly"
                     :isOnlyNumber="true"
                     :showHelpIconPopover="true"
                     :popoverTexts="[$t('properties.sub_area.help.area_of_total_subarea')]"
          />
          <InputText class="w-full" :label="$t('properties.sub_area.land_value_of_subarea')"
                     v-model="subArea.landValueOfSubarea"
                     :disabled="isReadOnly"
                     :isFloat="true"
                     :decimalNumber="2"
                     :maxValue="maxAreaOfLandValue"
                     :showHelpIconPopover="true"
                     :popoverTexts="[$t('properties.sub_area.help.land_value_of_subarea')]"
          />
        </div>
        <div class="flex justify-around space-x-8">
          <InputSelect class="w-full" :label="$t('properties.sub_area.included_in_which_area')"
                       :options="$t('properties.sub_area.included_in_which_area_options')"
                       v-model="subArea.includedInWhichArea"
                       :selected-value="subArea.includedInWhichArea"
                       :disabled="isReadOnly"
                       :showHelpIconPopover="true"
                       :popoverTexts="[$t('properties.sub_area.help.included_in_which_area')]"
          />
          <div class=" flex flex-col  w-full">
            <div class="flex">
              <label class="text mb-2">{{ $t('properties.sub_area.buildable') }}</label>
              <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                         :hideTriggers="['click']">
                <img class="help_icon self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text">
                      {{ $t('properties.sub_area.help.buildable') }}
                    </p>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center">
                <InputRadio type="radio" :disabled="isReadOnly" v-model="subArea.buildable"
                            name="group2"
                            :selectedButtonValue="subArea.buildable"
                             rValue="1" @checked="handleCheck"/>
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center ">
                <InputRadio type="radio" :disabled="isReadOnly" v-model="subArea.buildable"
                            name="group2"
                            :selectedButtonValue="subArea.buildable"
                            rValue="0" @checked="handleCheck"/>
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-around space-x-8">
          <div class=" flex flex-col  w-full">
            <div class="flex">
              <label class="text mb-2">{{ $t('properties.sub_area.land_value_for_building_land') }}</label>
              <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                         :hideTriggers="['click']">
                <img class="help_icon self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <template #popper>
                  <div class="tooltip-content">
                    <p class="space-y-2 mb-1 popover-text">
                      {{ $t('properties.sub_area.help.land_value_for_building_land') }}
                    </p>
                  </div>
                </template>
              </VDropdown>
            </div>
            <div class="flex space-x-4  mb-6">
              <div class="flex justify-start items-center">
                <InputRadio type="radio" :disabled="isReadOnly" v-model="subArea.landValueForBuildingLand"
                            name="group1"
                            :selectedButtonValue="subArea.landValueForBuildingLand"
                            rValue="1"/>
                <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
              </div>
              <div class="flex justify-start items-center ">
                <InputRadio type="radio" :disabled="isReadOnly" v-model="subArea.landValueForBuildingLand"
                            name="group1"
                            :selectedButtonValue="subArea.landValueForBuildingLand"
                            rValue="0"/>
                <span class="ml-3 textradio">{{ $t('general.no') }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full invisible" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _clone from "lodash/cloneDeep";
import RadioButton from "primevue/radiobutton";

export default {
  name: 'subArea',
  components: {RadioButton},
  props: {
    propertyData: Object,
    isReadOnly: {
      type: Boolean,
      default: false
    },
    federalStateUid : {
      type : String,
      default: '0'
    }

  },
  data() {
    return {
      showSubAreaSection: false,
      subArea: {
        'areaOfTotalSubarea': '',
        'landValueOfSubarea': '',
        'includedInWhichArea': '0',
        'buildable': '0',
        'landValueForBuildingLand': '0'
      },
      maxAreaOfLandValue : 999999.99
    }
  },
  mounted() {
    if (this.propertyData) {
      this.setData(this.propertyData);
    }

    if (this.federalStateUid === '1') {
      this.maxAreaOfLandValue = 999999999999.99;
    }
  },
  methods: {
    checkedSubArea(checked) {
      if (!this.isReadOnly) {
        this.showSubAreaSection = checked;
      }
    },
    setData(data) {
      if (data.subArea && data.subArea.id) {
        this.subArea = _clone(data.subArea);
      }
      this.showSubAreaSection = this.subArea.id && parseInt(this.subArea.id) > 0;
    },
    getData() {
      return this.showSubAreaSection ? this.subArea : {};
    },
    handleCheck(event){
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
}
.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
</style>
