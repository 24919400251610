<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <div class="w-full text-left flex">
          <label :class="$isBusiness ? 'w-36' : 'w-28'">{{ $t('declarations.add_declaration.steps.step1.assignedUser') }}:</label>
          <label class="ml-5">{{ assignedUserName }}</label>
        </div>
      </div>
      <div v-show="!fromPropertiesActive" class="flex justify-around space-x-1 mt-2">
        <div class="w-full text-left flex">
          <label :class="$isBusiness ? 'w-36' : 'w-28'">{{ $t('declarations.add_declaration.steps.step1.client') }}:</label>
          <label class="ml-5">{{ clientName}}</label>
        </div>
      </div>
      <div class="rectangle mt-10">
        <div class="flex w-full pl-3">
          <label class="text ">{{ $t("declarations.mass_update.selected_properties") }}</label>
        </div>
        <div class="flex justify-around space-x-1 mt-2" v-if="!renderingGrid">
          <DataGrid grid-id="grid-selected-properties-2"
                   ref="grid-selected-properties-2"
                  :local-data="selectedProperties"
                  :columns="columns"
                  :pagination="false"
                  default-sort-attr="propertyTitle"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
                  @on-item-selected="handleRecipientTypeSelected"
                  @col-header-check="handleColHeaderChecked"
                  @col-check="handleColChecked"
        />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import _orderBy from "lodash/orderBy"

export default {
  name: "Step4",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  businessDidConfirmParticipationDialogOnce: false,
  data() {
    return {
      renderingGrid: true,
      selectedProperties: [],
      firstRecipientType: 'none',
      columns: [
        {
          field: 'propertyTitle',
          header: this.$t('declarations.mass_update.property'),
          isChecked: true,
          sortable: false,
          autoWidth: true
        }, {
          field: 'clientName',
          header: this.$t('declarations.mass_update.client'),
          isChecked: false, // only set true for bulk add from properties
          sortable: false,
          autoWidth: true
        }, {
          field: 'recipientType',
          model: 'recipientType',
          width: 300,
          header: this.$t('declarations.add_declaration.steps.step3.title'),
          isChecked: true,
          colElType: "poa-select",
          sortable: false,
          dataSource: []
        }, {
          field: 'participation',
          model: 'participation',
          width: 300,
          header: this.$t('declarations.add_declaration.steps.step3.participation'),
          isChecked: this.shouldParticipationBeChecked(),
          colElType: 'checkbox',
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.participation_popoverTexts')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/mitwirkung/",
          dataSource: [],
          showHeaderCheckbox: true,
          headerCheckboxValue: undefined //initial values requirement: true for TC, false for Business
        }
      ],
      fromPropertiesActive: false
    }
  },
  watch: {
    active(newValue) {
      if(newValue) {
        if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
          this.fromPropertiesActive = this.getSteps().fromPropertiesActive === true;
          if (!this.fromPropertiesActive) {
            var powerOfAttorneyFromClient = this.getSteps().powerOfAttorneyFromClient ? parseInt(this.getSteps().powerOfAttorneyFromClient) : 0;
            var powerOfAttorneyFromBranch = this.getSteps().powerOfAttorneyFromBranch ? parseInt(this.getSteps().powerOfAttorneyFromBranch) : 0;
          }
          let colParticipation = this.columns.find( o => o.field === 'participation');
          if (typeof colParticipation !== 'undefined' && colParticipation.headerCheckboxValue === undefined) {
            colParticipation.headerCheckboxValue = this.shouldParticipationBeChecked();
          }

          this.selectedProperties = JSON.parse(JSON.stringify(this.getSteps().selectedProperties));
          this.selectedProperties = this.selectedProperties.map((o) => {
            o.propertyTitle = o.property_id + ' - ' + o.name;
            if(typeof o.recipientType === 'undefined') {
              o.recipientType = 'none';
            }
            if(typeof o.participation === 'undefined') {
              o.participation = this.shouldParticipationBeChecked();
            }

            o.dataSources['recipientType'] = [];
            if(this.$isBusiness) {
              o.dataSources['recipientType'] = [{
                code: "none",
                name: this.$t('declarations.add_declaration.steps.step3.none')
              }];
            } else {
              o.dataSources['recipientType'] = [{
                code: "none",
                name: this.$t('declarations.add_declaration.steps.step3.none')
              }, {
                code: "tax_consultant",
                name: this.$t('declarations.add_declaration.steps.step3.tax_consultant')
              }];
            }

            // when bulk process comes from property grid, there can be multiple clients
            if (this.fromPropertiesActive) {
              let multiClient = this.getSteps().multiClients.find(c => c.code === o.client_uid);
              if (typeof multiClient !== 'undefined') {
                powerOfAttorneyFromBranch = multiClient.powerOfAttorneyFromBranch;
                powerOfAttorneyFromClient = multiClient.powerOfAttorneyFromClient;
              }
            }

            if(powerOfAttorneyFromBranch > 0) {
              o.dataSources['recipientType'].push({
                code: "power_of_attorney_from_branch",
                name: this.$t('declarations.add_declaration.steps.step3.power_of_attorney_from_branch')
              });
            }

            if(powerOfAttorneyFromClient > 0) {
              o.dataSources['recipientType'].push({
                code: "power_of_attorney_from_client",
                name: this.$t('declarations.add_declaration.steps.step3.power_of_attorney_from_client')
              });
              o.recipientType = this.firstRecipientType = 'power_of_attorney_from_client';
            }

            if (this.fromPropertiesActive) {
              o.clientName = o.client_name;
            }

            return o;
          });
          this.selectedProperties = _orderBy(this.selectedProperties, 'propertyTitle', 'asc');
          this.renderingGrid = false;
        }
        this.triggerReload('grid-selected-properties-2');
      }
    }
  },
  computed: {
    clientName() {
      return this.getSteps().clientName ? this.getSteps().clientName : '';
    },
    assignedUserName() {
      return this.getSteps().assignedUserName ? this.getSteps().assignedUserName : '';
    }
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    submitNext() {
      this.saveSteps({
        "selectedProperties": this.selectedProperties
      });

      this.$emit("goToNextStep")
    },
    goBack() {
      this.$emit("goBack");
    },
    handleRecipientTypeSelected(args) {
      if(args.col && args.col.field === "recipientType" && args.row && args.row.prim_uid === this.selectedProperties[0].prim_uid && this.selectedProperties.length > 1) {
        if(args.item.code !== 'none' && args.item.code !== this.firstRecipientType) {
          this.$modal.show({
            headerText: this.$t('declarations.mass_update.steps.power_of_attorney'),
            text: this.$t('declarations.mass_update.confirmation_update_recipient_type'),
            showClose: true,
            canEscape: false,
            canCloseOutsideClick: false,
            buttons: {
              ok: this.$t('buttons.yes'),
              cancel: this.$t('buttons.no'),
              delete: false
            },
            onConfirm: () => {
              this.renderingGrid = true;
              this.selectedProperties = this.selectedProperties.map((o) => {
                o.recipientType = args.item.code
                return o;
              });
              this.triggerReload('grid-selected-properties-2');
              this.$nextTick(() => this.renderingGrid = false);
            }
          });
        }
        this.firstRecipientType = args.item.code;
      }
    },
    handleColChecked(record) {
      let col = this.columns.find( o => o.field === 'participation');

      if (record.participation === false) {
        if (col.headerCheckboxValue === true) {
          col.headerCheckboxValue = false;
        }
      } else {
        let uncheckedProperty = this.selectedProperties.find ( o => o.participation === false);
        if (typeof uncheckedProperty === 'undefined') {
          col.headerCheckboxValue = true;
        }
      }
    },
    handleColHeaderChecked(col) {
      if (col.field === 'participation') {
        if (col.headerCheckboxValue) {
          if (this.$isBusiness && !this.businessDidConfirmParticipationDialogOnce) {
            this.$modal.show({
              headerText: this.$t('declarations.add_declaration.steps.step4.checkParticipationHeaderDialog.title'),
              textHtml: this.$t('declarations.add_declaration.steps.step4.checkParticipationHeaderDialog.textHtml'),
              buttons: {
                ok: this.$t('declarations.add_declaration.steps.step4.checkParticipationHeaderDialog.ok'),
                cancel: this.$t('declarations.add_declaration.steps.step4.checkParticipationHeaderDialog.cancel'),
                delete: false
              },
              showClose: false,
              canEscape: false,
              canCloseOutsideClick: false,
              onConfirm: () => {
                this.businessDidConfirmParticipationDialogOnce = true;
                this.checkHeaderAndRowsParticipation(true);
              },
              onCancel: () => {
                this.checkHeaderAndRowsParticipation(false);
              }
            });
          } else {
            this.checkHeaderAndRowsParticipation(true);
          }
        } else {
          this.checkHeaderAndRowsParticipation(false);
        }
      }
    },
    checkHeaderAndRowsParticipation(value) {
      if (typeof value === 'boolean') {
        this.renderingGrid = true;
        this.columns.find( o => o.field === 'participation').headerCheckboxValue = value;
        this.selectedProperties = this.selectedProperties.map((o) => {
          o.participation = value;
          return o;
        });
        this.triggerReload('grid-selected-properties-2');
        this.$nextTick(() => this.renderingGrid = false);
      }
    },
    shouldParticipationBeChecked() {
      if (!this.$isBusiness) {
        return true;
      }

      if (this.getCurrentUser().partner === 'wts') {
        return true;
      }

      return false;
    }
  },
  beforeMount() {
    if (this.$route.name === 'Properties') {
      this.columns.find(c => c.field === 'clientName').isChecked = true;
      this.columns.find(c => c.field === 'clientName').header =
          this.$isBusiness ? this.$t('declarations.mass_update.clients_business') : this.$t('declarations.mass_update.client');
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}
.rectangle {
  padding-top: 10px;
  border: 1px solid #dfdfdf;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
</style>
