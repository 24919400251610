<template>
  <div class=" flex flex-col justify-center items-center ml-15 mr-15">
    <div class="finish-check-icon-wrapper">
      <img :src="getAssetPath('check_done.svg')"/>
    </div>
    <label class="label__text text_bold mt-10 ">{{ $t('declarations.mass_update.finish.title') }}</label>
    <p class="mt-5 w-1/2">{{ $t('declarations.mass_update.finish.info') }}</p>
    <Button @click="submit" class="mt-10 w-44" :text="$t('buttons.next')"></Button>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Finish",
  methods: {
    ...mapMutations("declaration_bulk_add", ["setCurrentStep"]),
    ...mapGetters("declaration_bulk_add", ["getNotificationId"]),
    submit() {
      this.setCurrentStep(1);
      if(this.getNotificationId()) {
        this.$router.push('/declaration/BulkAddStatus?uid=' + this.getNotificationId());
      } else {
        this.$router.push('/declaration/declarations');
      }
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  font-family: 'Segoe UI', sans-serif;
  color: white;
  font-size: 15px;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}
</style>
