<template>
  <div class="">
    <div class="mt-18 flex flex-col mr-6">
      <DualListSelect v-if="!userLoading" :list="users" v-model="step3.selected_users" :columns="columns"
                      :leftHeader="$t('settings.branches.attrs.all_users')"
                      :rightHeader="$t('settings.branches.attrs.selected_users')"
                      :showLeftHelpIcon="true"
                      :leftTooltip="$t('settings.branches.dualListTooltip.left')"
                      :showRightHelpIcon="true"
                      :rightTooltip="$t('settings.branches.dualListTooltip.right')"
      />
      <div class="flex justify-end  mt-6 space-x-4 mb-6  ">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {UserService} from "../../../services/user.service";
import {BranchService} from "../../../services/branch.service";

export default {
  name: "Step2",
  components: {},
  data() {
    return {
      userService: new UserService(),
      userLoading: true,
      columns: [{
        field: 'name',
        label: this.$t('settings.user_management.attrs.user_id'),
        search: true
      }, {
        field: 'role',
        label: this.$t('settings.user_management.attrs.name')
      }],
      users: [],
      step3: {
        selected_users: []
      }
    }
  },
  created() {
    this.userService.listAll().then((userList) => {
      this.step3.selected_users = userList.filter(o => o.allBranchAccess === '1').map((user) => {
        user.disableDelete = true;
        return user;
      });
      if(this.step3.selected_users.length > 0) {
        this.users = userList.filter(o => o.allBranchAccess === '0');
      } else {
        this.users = userList;
      }
      this.userLoading = false;
    });
  },
  computed: {
    ...mapGetters("branch", ["getBranchData"]),
  },
  methods: {
    ...mapMutations("branch", ["setBranchData"]),
    submitNext() {
      this.setBranchData(this.step3);

      if(this.$isBusiness && this.getCurrentUser().plan_details && this.getCurrentUser().plan_details.location != '' && this.getCurrentUser().plan_details.location != 'Unlimited') {
        this.userService.getCurrentUserStats().then((stats) => {
          if(stats.branchCount >= this.getCurrentUser().plan_details.location) {
            this.$api.showToast(this.$t('general.errors.packageLimitReached'), "error");
            return false;
          } else {
            this.triggerSave();
          }
        })
      } else {
        this.triggerSave();
      }
    },
    triggerSave() {
      const branch = new BranchService();
      const post_data = this.getBranchData;
      branch.save(post_data).then((res) => {
        if(res) {
          this.$emit("finish");
        }
      })
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">


.table_flex {
  display: flex;
  margin: 0 -15px;
}

.inp_holder input {
  height: 32px;
  border: 1px solid;
}


.tb_50 {
  padding: 0 15px;
  max-width: 50%;
  width: 100%;
}

.inp_holder img {
  position: absolute;
  left: 23px;
  top: 16px;
  transform: rotate(
          90deg);
}

.table_50 tr td:nth-child(even) img {
  display: block;
  margin: 0 auto;
}

.inp_holder input {
  padding-left: 25px;
}

.table_50 td, .table_50 th {
  font-size: 15px;
  padding: 12px;
}

.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.table_50 img {
  max-width: 20px;
}

.table_50 {
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.box {

  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text-info {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.lbl {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
}
</style>
