<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.mailbook.title') " :breadcrumbs="breadcrumbs"/>
    <ModalDialog v-show="isModalone" @close="handleModalCloseone" :showClose="true"
                 headerText="Email">
      <template v-slot:body>
        <div class=" flex flex-col justify-start mt-3   space-y-4">
          <div class="flex   row">
            <label class="label column">Absender: </label>
            <label class="label column">karl@yahoo.com</label>
          </div>
          <div class="flex row">
            <label class="label column">Datum:</label>
            <label class="label column">04.09.2021</label>
          </div>
          <div class="flex row">
            <label class="label column">Empfänger:</label>
            <label class="label column">timo.brade@gmail.com</label>
          </div>
          <div class="flex row">
            <label class="label column">Betreff:</label>
            <label class="label column">Hallo</label>
          </div>
          <label class="label">Nachricht:</label>
          <div class="rect">
            <div class="flex flex-col  pl-3 mt-3">
              <label class="label">Sehr geehrter Herr Timo,</label>
              <label class="label mt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.</label>
              <label class="label mt-4">is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                more-or-less normal distribution of letters,</label>
              <label class="label mt-6">Mit freundlichen Grüßen</label>
              <label class="label mb-2">Karl Ulrich</label>
            </div>

          </div>

          <div class="flex justify-end space-x-10 mt-2 mr-6 w-full">
            <div class="flex mt-2 mb-6 justify-end ">
              <Button class=" " :secondary="true" @click="handleModalCloseone" :text="$t('buttons.cancel')">

              </Button>
            </div>
          </div>
        </div>
      </template>

    </ModalDialog>
    <ModalDialog v-show="isModaltwo" @close="handleModalClosetwo" :showClose="true"
                 headerText="Pdf">
      <template v-slot:body>
        <div class=" flex flex-col justify-start mt-3 mr-4 ml-4 space-y-4">
          <img class="rect-img" src="@/assets/pdf.png"/>
          <div class="flex justify-end space-x-10 mt-2 mr-6 w-full">
            <div class="flex mt-2 mb-6 justify-end ">
              <Button class=" " :secondary="true" @click="handleModalClosetwo" :text="$t('buttons.cancel')">

              </Button>
            </div>
          </div>
        </div>
      </template>

    </ModalDialog>
    <div class="w-full h-full mt-4 mr-8 pb-8">
      <AppTabs :categories="categories" :no-footer="true">
        <!-- tab1 client_portal -->
        <template #tab-pane-client-portal>
          <div class="flex flex-row justify-end">
            <div class="mr-7">
              <FilterInput v-model="filters['global']" @input="performSearch($event)" @filterClick="handleFilterClick"/>
            </div>
          </div>
          <div class="w-full mt-2">
            <!--table-->
            <DataTable :value="clients" :filters="filters"
                       :scrollable="scrollable"
                       :resizableColumns="resizableColumns" :paginator="pagination"
                       :rows="rows"
                       :reorderableColumns="reorderableColumns" @selectedColumns="handleSelectedColumn"
            >
              <Column v-for="(col,index) of selectedColumns" :field="col.field" :header="col.header"
                      :key="col.field + '_' + index"
                      sortable>
                <template #body="slotProps" v-if="index===0">
                  <div @click="handleModalOpenOne" class="cursor-pointer underline">{{ slotProps.data.id }}</div>
                </template>

                <template #body="slotProps" v-else-if="index===2">
                  <div @click="handleModalOpenOne" class="cursor-pointer underline">{{ slotProps.data.type }}</div>
                </template>

                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.name }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.street }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===3">-->
                <!--            <label>{{ slotProps.data.status }}</label>-->
                <!--          </template>-->

              </Column>

              <Column :reorderableColumn="false" field="action" header="">
                <template #header>
                  <div class="flex justify-center items-center cursor-pointer" @click="selectColumns">
                    <img class="column_icon cursor-pointer" src="@/assets/select_column_green.svg"/>
                    <p> {{ $t('general.grid.select_col') }}</p>
                  </div>
                </template>

              </Column>
            </DataTable>

          </div>
        </template>

        <!-- tab 2 postal -->
        <template #tab-pane-postal>
          <div class="flex flex-row justify-end">
            <div class="mr-7">
              <FilterInput v-model="filters['global']" @input="performSearch($event)" @filterClick="handleFilterClick"/>
            </div>
          </div>
          <div class="w-full  mt-2">
            <!--table-->
            <DataTable :value="table2" :filters="filters"
                       :scrollable="scrollable"
                       :resizableColumns="resizableColumns" :paginator="pagination"
                       :rows="rows"
                       :reorderableColumns="reorderableColumns" @selectedColumns="handleSelectedColumn"
            >


              <Column v-for="(col,index) of columns2" :field="col.field" :header="col.header"
                      :key="col.field + '_' + index"
                      sortable>
                <!--          <template #body="slotProps" v-if="index===0">-->
                <!--            <label>{{ slotProps.data.type }}</label>-->
                <!--          </template>-->
                <template #body="slotProps" v-if="index===0">
                  <label @click="handleModalOpentwo" class="cursor-pointer underline">{{ slotProps.data.id }}</label>
                </template>
                <template #body="slotProps" v-if="index===2">
                  <label class="cursor-pointer underline">{{ slotProps.data.type }}</label>
                </template>

                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.name }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.street }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===3">-->
                <!--            <label>{{ slotProps.data.status }}</label>-->
                <!--          </template>-->

              </Column>

              <Column :reorderableColumn="false" field="action" header="">
                <template #header>
                  <div class="flex justify-center items-center cursor-pointer" @click="selectColumns">
                    <img class="column_icon cursor-pointer" src="@/assets/select_column_green.svg"/>
                    <p> {{ $t('general.grid.select_col') }}</p>
                  </div>
                </template>

              </Column>
            </DataTable>

          </div>
        </template>

        <!-- tab 3 user_invites-->
        <template #tab-pane-user-invites>
          <div class="flex flex-row justify-end">
            <div class="mr-7">
              <FilterInput v-model="filters['global']" @input="performSearch($event)" @filterClick="handleFilterClick"/>
            </div>
          </div>
          <div class="w-full  mt-2">
            <!--table-->
            <DataTable :value="table4" :filters="filters"
                       :scrollable="scrollable"
                       :resizableColumns="resizableColumns" :paginator="pagination"
                       :rows="rows"
                       :reorderableColumns="reorderableColumns" @selectedColumns="handleSelectedColumn"
            >


              <Column v-for="(col,index) of columns4" :field="col.field" :header="col.header"
                      :key="col.field + '_' + index"
                      sortable>
                <!--          <template #body="slotProps" v-if="index===0">-->
                <!--            <label>{{ slotProps.data.type }}</label>-->
                <!--          </template>-->
                <template #body="slotProps" v-if="index===2">
                  <label @click="handleModalOpentwo" class="cursor-pointer underline">{{ slotProps.data.type }}</label>
                </template>
                <!--              <template #body="slotProps" v-if="index===2">-->
                <!--                <label class="cursor-pointer underline">{{ slotProps.data.type }}</label>-->
                <!--              </template>-->

                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.name }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===2">-->
                <!--            <label>{{ slotProps.data.street }}</label>-->
                <!--          </template>-->
                <!--          <template #body="slotProps" v-if="index===3">-->
                <!--            <label>{{ slotProps.data.status }}</label>-->
                <!--          </template>-->

              </Column>

              <Column :reorderableColumn="false" field="action" header="">
                <template #header>
                  <div class="flex justify-center items-center cursor-pointer" @click="selectColumns">
                    <img class="column_icon cursor-pointer" src="@/assets/select_column_green.svg"/>
                    <p> {{ $t('general.grid.select_col') }}</p>
                  </div>
                </template>

              </Column>
            </DataTable>

          </div>
        </template>
      </AppTabs>
    </div>
  </div>
</template>

<script>
import AppTabs from "@/components/AppTabs";
import {mapMutations} from "vuex";
import DataTable from "primevue/datatable";
import Column from 'primevue/column';

export default {
  name: "mailbook",
  components: {DataTable, Column, AppTabs},

  data() {
    return {
      isModalone: false,
      isModaltwo: false,
      columns: [
        {field: 'id', header: "Datum & Uhrzeit", isChecked: true},
        {field: 'name', header: "Versender", isChecked: true},
        {field: 'type', header: "Empfänger E-Mail", isChecked: true},
        {field: 'status', header: "Art ", isChecked: true},
        {field: 'memory', header: "Erinnerung  ", isChecked: true}
      ],
      columns2: [
        {field: 'id', header: "Datum & Uhrzeit", isChecked: true},
        {field: 'name', header: "Versender", isChecked: true},
        {field: 'type', header: "Mandant", isChecked: true},
        {field: 'status', header: "Bundesland ", isChecked: true}
      ],
      columns3: [
        {field: 'id', header: "Datum & Uhrzeit", isChecked: true},
        {field: 'name', header: "Versender", isChecked: true},
        {field: 'type', header: "Mandant", isChecked: true},
        {field: 'status', header: "Antwort erhalten ", isChecked: true}
      ],
      columns4: [
        {field: 'id', header: "Datum & Uhrzeit", isChecked: true},
        {field: 'name', header: "Versender", isChecked: true},
        {field: 'type', header: "Empfänger E-Mail", isChecked: true},
        {field: 'status', header: "Angenommen", isChecked: true}
      ],
      selectedColumns: null,
      tempselectedColumns: [],
      selectedProducts: null,
      columnResizeMode: 'fit',
      reorderableColumns: true,
      resizableColumns: true,
      scrollable: false,
      filters: {},
      rows: 10,
      pagination: true,
      categories: [
        {
          name: this.$t('settings.mailbook.tabs.tab1'),
          showTopLine: true,
          slug: 'client-portal'
        }, {
          name: this.$t('settings.mailbook.tabs.tab3'),
          showTopLine: true,
          slug: 'postal'
        }, {
          name: this.$t('settings.mailbook.tabs.tab4'),
          showTopLine: true,
          slug: 'user-invites'
        },
      ],
      clients: [
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "#1"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "#2"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "#3"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "#4"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "karl@gmail.com",
          status: "Portaleinladung",
          memory: "Nein"
        },


      ],
      table2: [
        {
          id: '04.09.2021 11:32ssss',
          name: 'Frida Versessss',
          type: "Daniela Heybey",
          status: "Bayern",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32sss',
          name: 'Timo Tauschersss',
          type: "Daniela Heybey",
          status: "Bayern",
          memory: "#1"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "Leonard Brix",
          status: "Hessen",
          memory: "#2"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "Leonard Brix",
          status: "Hessen",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "Leonard Brix",
          status: "Hessen",
          memory: "#3"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "Leonard Brix",
          status: "Hamburg",
          memory: "#4"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "Leonard Brix",
          status: "Hamburg",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "Leonard Brix",
          status: "Hamburg",
          memory: "Nein"
        },


      ],
      table3: [
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "Daniela Heybey",
          status: "Nein",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "Daniela Heybey",
          status: "Nein",
          memory: "#1"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "Leonard Brix",
          status: "Nein",
          memory: "#2"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "Leonard Brix",
          status: "Nein",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "Leonard Brix",
          status: "Nein",
          memory: "#3"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "Leonard Brix",
          status: "Nein",
          memory: "#4"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "Leonard Brix",
          status: "Nein",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "Leonard Brix",
          status: "Nein",
          memory: "Nein"
        },


      ],
      table4: [
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "ewad@yahoo.com",
          status: "Nein",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "karl@yahoo.com",
          status: "Nein",
          memory: "#1"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "karl@yahoo.com",
          status: "Ja",
          memory: "#2"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "karl@yahoo.com",
          status: "Ja",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Frida Verse',
          type: "karl@yahoo.com",
          status: "Ja",
          memory: "#3"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Timo Tauscher',
          type: "karl@yahoo.com",
          status: "Nein",
          memory: "#4"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Hannes Bähr',
          type: "karl@yahoo.com",
          status: "Ja",
          memory: "Nein"
        },
        {
          id: '04.09.2021 11:32',
          name: 'Friedrich Brantner',
          type: "karl@yahoo.com",
          status: "Nein",
          memory: "Nein"
        },


      ],

      breadcrumbs: [
        {
          title: this.$t('settings.protocol.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.mailbook.title'),
          link: ''
        }
      ]
    }
  },
  created() {
    // this.columns = [
    //   {field: 'date', header: this.$t('settings.protocol.table.col1'), isChecked: true},
    //   {field: 'user', header: this.$t('settings.protocol.table.col2'), isChecked: true},
    //   {field: 'description', header: this.$t('settings.protocol.table.col3'), isChecked: true},
    //
    // ];
    this.selectedColumns = this.columns;
    this.tempselectedColumns = this.columns;

  },
  methods: {
    ...mapMutations('grid', ["triggerReload"]),
    handleModalCloseone() {
      this.isModalone = false
    },
    handleModalClosetwo() {
      this.isModaltwo = false
    },
    handleModalOpenOne() {
      this.isModalone = true
    },
    handleModalOpentwo() {
      this.isModaltwo = true
    },
    reloadGrid() {
      this.triggerReload(this.gridId);
    }
  }
}
</script>

<style scoped lang="scss">
.vertical-dots {
  height: 18px;
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  line-height: normal;
}


.column_icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  color: #333333;
}


.label {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.column {
  float: left;
  width: 20%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.rect {
  width: 100%;
  padding: 2px 2px 2px 2px;
  border: 2px solid #eeeeee;
  background-color: #ffffff;

}


.rect-img {
  width: 100%;

  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
}
</style>
