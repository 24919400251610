import {ApiService} from "./api.service";

export class CommunicationsService extends ApiService {
  get(uid, logType) {
    return this.trigger('email_log/get', {
      "uid": uid,
      "logType": logType
    }, true).then((response) => {
      if (response.data.success) {
        return response.data.emailDetail;
      } else {
        this.$toast.error(response.data.message);
        return [];
      }
    });
  }
}
