<template>
  <div class="step_box">
    <div v-show="fetchingBranches">
      <div class="mt-20 flex flex-col container h-150">
        <div class="flex flex-col w-full loading-container">
          <div class="spin"></div>
        </div>
      </div>
      <div class="flex flex-col w-full h-full">
        <p class="loading-text" v-show="!savingBranches">{{ $t('clients.datev_import.steps.step4.fetching_branches') }}</p>
      </div>
    </div>
    <div v-if="!fetchingBranches">
      <div class="mr-4 datev_client_import">
        <DataTable :value="branchList" responsiveLayout="scroll" dataKey="id" class="row" :scrollable="true" scrollHeight="flex">
          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                  :headerStyle="{'background-color':'#f2f2f2', 'width': col.width ? col.width : ''}"
                  :body-style="{'width': col.width}"
          >
            <template #header="slotProps" v-if="col.field === 'select'">
              <div class="select-all">
                <InputCheck :isChecked="selectAll" @check="handleSelectAll"/>
              </div>
            </template>
            <template #body="slotProps">
              <div>
                <InputText v-if="col.type==='input'" :disabled="!slotProps.data.selected" v-model="slotProps.data.branch_name"/>
                <InputCheck v-else-if="col.type==='check'" :isChecked="slotProps.data.selected" class="-mb-3" @check="handleCheck($event, slotProps)"/>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex justify-around space-x-10 mt-2 "/>
      <div class="flex justify-end space-x-4 mt-6 mr-6 mt-10 mb-20">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _chunk from "lodash/chunk"
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step4",
  components: {DataTable, Column},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(nv) {
      if (nv) {
        this.branchList = [];
        this.fetchBranchList();
      }
    }
  },
  data() {
    return {
      service: new BranchService(),
      fetchingBranches: false,
      savingBranches: false,
      chunkSize: 50,
      columns: [
        {field: 'select', type: 'check', width: '50px'},
        {field: 'branch_name', header: this.$t('dms.steps.step4.table_header1'), type: 'input'}
      ],
      branchList: [],
      selectedBranches: [],
      selectAll: true
    }
  },
  methods: {
    ...mapGetters("datev_import", ["getSelectedEnvironments", "getDataEnvironments"]),
    async fetchBranchList() {
      if(this.getSelectedEnvironments().length > 0) {
        this.fetchingBranches = true;
        this.branchList = [];
        let savedDatevIds = await this.service.get_saved_datev_ids();
        for(let k of this.getSelectedEnvironments()) {
          let dataEnv = this.getDataEnvironments().filter(o => o.id === k.id);
          if(dataEnv.length > 0 && dataEnv[0].establishments) {
            let branches = this.$api.parse_object_data(dataEnv[0].establishments).map((b) => {
              if(savedDatevIds[b.id]) {
                b.branch_name = savedDatevIds[b.id].name;
              } else if(parseInt(dataEnv[0].establishments_count) === 1) {
                b.branch_name = b.name;
              } else {
                b.branch_name = k.label + ' - ' + b.name;
              }
              b.selected = true;
              return b;
            });

            if(branches.length > 0) {
              for(let branch of branches) {
                this.branchList.push(branch);
              }
            }
          }
        }

        if(this.branchList.length === 0) {
          this.$api.trigger('dashboard/update_todos', {
            'todo_key': 'add_branch',
            'isChecked': 1
          }, true).then(() => {
            this.$api.showToast(this.$t('clients.datev_import.no_branch_found'), "info");
            this.$router.push('/home');
          });

          return;
        }
        this.fetchingBranches = false;
      }
    },
    submitNext() {
      this.selectedBranches = this.branchList.filter(o => o.selected);

      if (this.selectedBranches.length > 0) {
        this.savingBranches = true;
        this.fetchingBranches = true;
        this.saveDatevBranches();
      } else {
        this.$api.showToast(this.$t('clients.datev_import.steps.step4.no_branch_selected'), "error")
      }
    },
    handleSelectAll(checked) {
      this.selectAll = checked;
      this.branchList = this.branchList.map((o) => {
        o.selected = checked;
        return o;
      });
    },
    handleCheck(e, args) {
      this.branchList = this.branchList.map((o) => {
        if(o.id === args.data.id) {
          o.selected = e;
        }
        return o;
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    async saveDatevBranches() {
      let results = {
        "success": 0,
        "error": ""
      }

      let selectedBranchChunks = _chunk(this.selectedBranches, this.chunkSize);
      for (let k = 0; k < selectedBranchChunks.length; k++) {
        await this.service.import_datev_branch({
          "selected_branches": selectedBranchChunks[k]
        }).then((response) => {
          if (response.data.success) {
            results.success = 1;
          } else {
            results.error = response.data.message;
          }
        })
      }

      if (results.success) {
        this.$api.get_user();
        this.$router.push('/setting/branches');
      } else {
        this.$api.showToast(results.error, "error");
        this.savingBranches = false;
        this.fetchingBranches = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.datev_client_import::v-deep {
  td.client_type div.cell-content img.column_icon {
    margin-left: auto;
    margin-right: auto;
  }
}
.datev_client_import {
  height: calc(100vh - 370px);
}

.container {
  position: relative;
}

.loading-text {
  color: #9d9d9d;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.row {
  border: 1px solid #dfdfdf;

  ::v-deep {
    .p-datatable-wrapper td:not(:first-child) {
      text-align: left !important;
    }
  }
}
.select-all {
  background: #f2f2f2;
  padding-bottom: 15px;
}
</style>
