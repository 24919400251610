<template>
  <div class="flex flex-row w-full">
    <div class="mr-7 w-full">
      <div class="flex flex-col w-full">
        <label class="text  mb-8">{{ $t("declarations.mass_update.select_process") }}<span
            style="color:red">*</span></label>
        <div class="flex flex-col space-y-5  ">
          <div class="flex justify-start items-center cursor-pointer" v-for="(label, opt) in processTypes " @click="createOptionSelected(opt)">
            <RadioButton type="radio" v-model="radio" name="radio-button" :value="opt"/>
            <span class="ml-3 textradio">{{ label }}</span>
          </div>
        </div>
      </div>
      <div class="flex mt-10 mb-10  justify-end space-x-4">
        <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>

    </div>


  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import RadioButton from 'primevue/radiobutton';

export default {
  name: "Step2",
  components: {RadioButton},
  data() {
    return {
      radio: 'new_declaration',
    }
  },
  mounted() {
    if(this.$route.params.processType && this.$t('declarations.mass_update.process')[this.$route.params.processType]) {
      this.radio = this.$route.params.processType;
    }
  },
  computed: {
    hasReleasePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.release_declaration) === 1;
    },
    hasElsterPermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.elster_transaction) === 1;
    },
    hasSendAppealPermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.send_dispute) === 1;
    },
    processTypes() {
      let allowedProcessTypes = {};
      let businessProcessTypes = ['new_declaration', 'internal_approval', 'request_internal_approval', 'elster_submission', 'document_creation'];
      for(let k in this.$t('declarations.mass_update.process')) {
        if(
          (k === 'internal_approval' && this.hasReleasePermission)
          || (k === 'elster_submission' && this.hasElsterPermission)
          || (k === 'mass_appeal' && this.hasSendAppealPermission)
        ) {
          allowedProcessTypes[k] = this.$t('declarations.mass_update.process')[k];
        } else if (
          k !== 'internal_approval'
          && k !== 'elster_submission'
          && k !== 'mass_appeal'
        ) {
          if (this.$isBusiness) {
            if (businessProcessTypes.includes(k)) {
              allowedProcessTypes[k] = this.$t('declarations.mass_update.process')[k];
            }
          } else {
            allowedProcessTypes[k] = this.$t('declarations.mass_update.process')[k];
          }
        }
      }
      return allowedProcessTypes;
    }
  },
  methods: {
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapGetters("user", ["getCurrentUser"]),
    createOptionSelected(e) {
      this.radio = e
      this.$emit('changeWizard', this.radio)
    },
    submitNext() {
      this.saveSteps({
        "processType": this.radio
      });
      this.$emit("goToNextStep", this.step1);
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 15px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}
</style>
