<template>
  <div class="">
    <div class="flex justify-around space-x-8">
      <InputText class="w-full"
                 :label="$t('settings.branches.attrs.name')"
                 :isError="isNameError"
                 :errorMessage="nameError" v-model="step1.name"
                 :isRequired="true" :placeholder="$t('settings.branches.attrs.name')"/>
      <InputText class="w-full pr-6"
                 :label="$t('settings.branches.attrs.street_and_house_number')"
                 :isError="isStreetError"
                 :errorMessage="streetError" v-model="step1.street"
                 :isRequired="false" :placeholder="$t('settings.branches.attrs.street_and_house_number')"/>
    </div>
    <div class="flex justify-around space-x-8 mt-4">
      <InputText class="w-full"
                 :label="$t('settings.branches.attrs.zip')"
                 :isError="isZipError"
                 :errorMessage="zipError"
                 v-model="step1.zip"
                 @blur="searchZip"
                 :isRequired="false"
                 :placeholder="$t('settings.branches.attrs.zip')"
                />
      <InputSelect
                class="w-full pr-6"
                :label="$t('settings.branches.attrs.city')"
                :options="cityList"
                v-model="step1.city"
                :selected-value="step1.city"
                :loading="zipSearch"
                :filterable="true"
                :auto-complete="true"
                :placeholder="$t('settings.branches.attrs.city')"
              />
    </div>
    <div class="flex justify-around space-x-8 mt-4">
      <InputText class="w-full"
                 :label="$t('settings.branches.attrs.phone')"
                 :isError="isPhoneError"
                 :errorMessage="phoneError"
                 v-model="step1.phone"
                 :isRequired="false"
                 :maxLength="16"
                 :placeholder="$t('settings.branches.attrs.phone')"/>
      <InputText class="w-full pr-6"
                 :label="$t('settings.branches.attrs.email')"
                 v-model="step1.email"
                 :placeholder="$t('settings.branches.attrs.email')"/>
    </div>
    <div class="flex justify-around space-x-8 mt-4">
      <InputSelect
          class="w-full"
          :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
          :options="powersOfAttorneyList"
          v-model="step1.power_of_attorney"
          :placeholder="$t('general.please_select')"
      />
      <div class="w-full invisible pr-6"/>
    </div>
    <div class="flex justify-end mt-12 space-x-4 mr-6">
      <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="cancel"/>
      <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import {userRoles, userStatus} from "../../../core/constants";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";

export default {
  name: "Step1",
  components: {},
  data() {
    return {
      roleOptions: userRoles,
      statusOptions: userStatus,
      step1: {
        name: this.$store.state.branch.name,
        street: this.$store.state.branch.street,
        zip: this.$store.state.branch.zip,
        city: this.$store.state.branch.city,
        email: this.$store.state.branch.email,
        power_of_attorney: this.$store.state.branch.power_of_attorney
      },
      isNameError: false,
      nameError: '',
      isStreetError: false,
      streetError: '',
      isZipError: false,
      zipError: '',
      isCityError: false,
      cityError: '',
      isPhoneError: false,
      phoneError: '',
      powersOfAttorneyList: [],
      cityList: [],
      zipSearch: false,
    }
  },
  validations: {
    step1: {
      name: {
        required
      }
    }
  },
  mounted() {
    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
    });
  },
  methods: {
    ...mapMutations("branch", ["setBranchData"]),
    searchZip() {
      this.zipSearch = true;
      if ((typeof this.step1.city === 'undefined' || this.step1.city === '') && this.step1.zip !== '') {
        this.cityList = [];
        this.$api.lookup_city(this.step1.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.step1.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    submitNext() {
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isNameError = this.$v.step1.name.$error
        this.nameError = this.$v.step1.name.$error ? this.$t('settings.branches.errors.required_name') : ""
        this.isStreetError = this.$v.step1.street.$error
        this.streetError = this.$v.step1.street.$error ? this.$t('settings.branches.errors.required_street') : ""
        this.isZipError = this.$v.step1.zip.$error
        this.zipError = this.$v.step1.zip.$error ? this.$t('settings.branches.errors.required_zip') : ""
        this.isCityError = this.$v.step1.city.$error
        this.cityError = this.$v.step1.city.$error ? this.$t('settings.branches.errors.required_city') : ""
        this.isPhoneError = this.$v.step1.phone.$error
        this.phoneError = this.$v.step1.phone.$error ? this.$t('settings.branches.errors.required_phone') : ""

        return; // if any of the validations fail, we don't move on
      }

      this.setBranchData(this.step1)
      this.$emit("goToNextStep", this.step1);
    },
    cancel() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">


.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

.two-factor {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  font-size: 15px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

.help_icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

</style>
