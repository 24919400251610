

export const changeTheme = (colors) => {
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('--primary-color', colors.primary);
    bodyStyles.setProperty('--sidebar-color', colors.secondary);
}
export const changeSidebarTheme = (color) => {
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('--sidebar-color', color);
}

