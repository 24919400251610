import { render, staticRenderFns } from "./PoaSelect.vue?vue&type=template&id=65923d61&scoped=true"
import script from "./PoaSelect.vue?vue&type=script&lang=js"
export * from "./PoaSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65923d61",
  null
  
)

export default component.exports