<template>
  <div>
    <div class="mb-8 mt-8" style="min-height: 300px;">
      <div class="flex justify-around space-x-1">
        <InputSelect class="w-full"
                     :isError="errors.federalStateUid.invalid"
                     :errorMessage="$t('general.errors.required')"
                     :label="$t('properties.step2.bundesland')"
                     v-model="federalStateUid"
                     :filterable="true"
                     :options="stateList"
                     :isRequired="true"
                     :placeholder="$t('general.please_select')"
                     @on-item-selected="selectState"
        />
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-around space-x-1 mt-4" v-show="showCommunity">
        <InputSelect class="w-full"
                     :isError="errors.community.invalid"
                     :errorMessage="$t('general.errors.required')"
                     :label="$t('properties.step2.community')"
                     :options="communityList"
                     v-model="tax_community"
                     :filterable="true"
                     :auto-complete="false"
                     :isRequired="true"
                     :search-fn="communitytListSearch"
                     :total-record-count="12000"
                     :selected-value="tax_community"
                     overlay-append-to="body"
                     @on-item-selected="selectCommunity"
        />
        <div class="w-full pr-6 invisible"/>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end  space-x-4 mr-6">
        <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {CommunityService} from "@/services/community.service";

export default {
  name: 'StepTaxCalculationCommunity',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: new CommunityService(),
      federalStateUid: '',
      tax_community: '',
      errors: {
        'federalStateUid': {
          invalid: false
        },
        'community': {
          invalid: false
        }
      },

      stateList: [],
      showCommunity: false,
      loadingCommunity: false,
      communityList: [],
    }
  },
  mounted() {
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });
  },
  methods: {
    ...mapMutations('declaration_bulk_add', ['saveSteps']),

    selectState(item) {
      if (item.code === '0' || item.code === '') {
        this.showCommunity = false;
      } else {
        this.showCommunity = true;
        this.errors.federalStateUid.invalid = false;
      }
    },

    selectCommunity(item) {
      if (item.code === '0' || item.code === '') {
        this.errors.community.invalid = true;
      } else {
        this.errors.community.invalid = false;
      }
    },

    communitytListSearch(search) {
      const params = {federalStateUid: this.federalStateUid, community: search.txtFilter}
      if (search.uid) {
        params.communityUid = search.uid;
      }

      return this.service.get_communities(params).then((data) => {
        const communities = Object.keys(data.data.communities).map((key) => data.data.communities[key]);
        if (!communities) {
          return false;
        }

        return communities.map((community) => {
          let name = community['community'];
          let code = community['agn'];

          if (this.federalStateUid == 9 || this.federalStateUid == 7) {
            name = name + ' -  Ø Bodenrichtwert: ' + community['land_value'] + ' €/m²';
          } else {
            name = name + ' -  Mietniveaustufe: ' + community['rent_level'];
          }

          return {
            name: name,
            code: code
          };
        });
      });
    },

    submitNext() {
      if (this.federalStateUid === '0' || this.federalStateUid === '') {
        this.errors.federalStateUid.invalid = true
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.federalStateUid.invalid = false
      }

      if (this.tax_community === '0' || this.tax_community === '') {
        this.errors.community.invalid = true
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.community.invalid = false
      }

      this.saveSteps({
        calculationCommunity: this.tax_community
      });

      this.$emit('goToNextStep')
    },
    goBack() {
      this.$emit('goBack');
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
</style>
