import {i18n} from '@/plugins/i18n';
import {userRoles, userStatus} from "@/core/constants";

export const userColumns = [
  {
    field: 'name',
    header: i18n.t('settings.user_management.attrs.name'),
    filterLbl: i18n.t('settings.user_management.attrs.name_contains'),
    isChecked: true,
    elType: 'text',
    operator: 'contains',
    clickable: true,
    menus: [
      {
        id: 'um.ga.change_password', // user management grid action change password
        icon: 'key_green.svg',
        visibility: (row) => {
          return row.invited !== "1" && (row.taxOfficeSoftware === 'none' || row.taxOfficeSoftware === 'andere' || process.env.VUE_APP_DEV_VARIANT === 'business');
        }
      }, {
        id: 'um.ga.options', // user management grid action options
        icon: 'verticle-dots.svg',
        class: 'ellipsis-v',
        visibility: (row) => {
          return row.editable && row.editable === 1;
        },
        items: [
          {
            id: 'um.ga.options.edit_user',
            icon: 'edit_user_icon',
            label: i18n.t('settings.user_management.menu.edit')
          }, {
            id: 'um.ga.options.enable_user',
            icon: 'enable_icon',
            label: i18n.t('settings.user_management.menu.enable'),
            "action_endpoint": 'user/update_status',
            "action_confirmation": {
              show: true,
              title: i18n.t('settings.user_management.confirmations.enable.single.title'),
              message: i18n.t('settings.user_management.confirmations.enable.single.text'),
              button_ok: i18n.t('general.yes'),
              button_cancel: i18n.t('general.no')
            },
            visibility: (row) => {
              return row.statusKey.toLowerCase() !== 'active';
            }
          }, {
            id: 'um.ga.options.disable_user',
            icon: 'disable_icon',
            label: i18n.t('settings.user_management.menu.disable'),
            "action_endpoint": 'user/update_status',
            "action_confirmation": {
              show: true,
              title: i18n.t('settings.user_management.confirmations.disable.single.title'),
              message: i18n.t('settings.user_management.confirmations.disable.single.text'),
              button_ok: i18n.t('general.yes'),
              button_cancel: i18n.t('general.no')
            },
            visibility: (row) => {
              return row.statusKey.toLowerCase() === 'active';
            }
          }, {
            id: 'um.ga.options.resend_invite',
            icon: 'resend_invite_icon',
            label: i18n.t('settings.user_management.menu.resend_invite'),
            "action_endpoint": 'user/resend_invite',
            "action_confirmation": {
              show: false
            },
            visibility: (row) => {
              return row.invited === '1';
            }
          }, {
            id: 'um.ga.options.delete_user',
            icon: 'delete_icon',
            label: i18n.t('settings.user_management.menu.delete'),
            "action_endpoint": 'user/delete',
            "action_confirmation": {
              show: true
            }
          }
        ]
      }
    ]
  },
  {
    field: 'userId',
    header: i18n.t('settings.user_management.attrs.user_id'),
    hideInFilter: true,
    isChecked: true,
    elType: 'text',
    clickable: true
  }, {
    field: 'email',
    header: i18n.t('settings.user_management.attrs.email'),
    filterLbl: i18n.t('settings.user_management.attrs.email_contains'),
    isChecked: true,
    elType: 'text',
    operator: 'contains',
    href: 'mailto'
  }, {
    field: 'role',
    header: i18n.t('settings.user_management.attrs.role'),
    isChecked: true,
    elType: 'select',
    filterOptions: userRoles
  }, {
    field: 'status',
    header: i18n.t('settings.user_management.attrs.status'),
    isChecked: true,
    elType: 'select',
    filterOptions: userStatus
  }, {
    field: 'phone',
    header: i18n.t('settings.user_management.attrs.phone'),
    isChecked: false,
    elType: 'text'
  }, {
    field: 'agentCode',
    header: i18n.t('settings.user_management.attrs.agent_code'),
    isChecked: false,
    elType: 'text',
    operator: 'contains',
    sortable: false,
    filterLbl: i18n.t('settings.user_management.attrs.agent_code') + ' ' + i18n.t('general.contains'),
  }, {
    field: 'allClientAccess',
    header: i18n.t('settings.user_management.permissions.all_clients'),
    isChecked: false,
    sortable: false,
    elType: 'select',
    filterOptions: [{
      "name": i18n.t('general.yes'),
      "code": "1"
    }, {
      "name": i18n.t('general.no'),
      "code": "0"
    }]
  }, {
    field: 'branch',
    header: i18n.t('settings.user_management.attrs.branch'),
    isChecked: false,
    hideInChecked: true,
    elType: 'select',
    filterOptions: []
  }
];
