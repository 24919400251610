<template>
  <div class="ml-8 mr-10">

    <div class="mt-5">
      <InputText type="password"
                 :isRequired="true" label="Passwort"
                 placeholder="Enter Password"
                 class="mt-5 mb-1"/>
      <InputCheck class="mt-4 check" checked>Der Benutzer ändert sein Passwort bei der ersten Anmeldung</InputCheck>


    </div>

  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "ResetPassword",
  methods: {
    ...mapMutations("user", ["setModalVisible"]),
    handleSubmit() {

      this.setModalVisible(false)
    }
  }
}
</script>

<style scoped lang="scss">
.header-password {

  height: 25px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  font-size: 25px;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
}

.check {

  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 40px;
}
</style>
