<template>
  <section class="rating">
    <div class="intro">
      <p class="text-left">
        {{ $t('general.nps.rating_text') }}
      </p>
    </div>
    <div class="traffic-bar">
      <div class="unlike-bar"></div>
      <div class="normal-bar"></div>
      <div class="like-bar"></div>
    </div>
    <form name="select-rating" class="select-rating">
      <fieldset>
        <div v-for="formGroup in formGroups" :key="formGroup" class="form-group">
          <input type="radio" name="rating" :id="formGroup" :value="formGroup" v-model="rating_pick" @change="handleInput" />
          <label :for="formGroup">{{ formGroup }}</label>
        </div>
      </fieldset>
      <div class="flex justify-between">
        <span>{{ $t('general.nps.very_unlikely') }}</span>
        <span>{{ $t('general.nps.most_likely') }}</span>
      </div>
    </form>

    <p class="text-left" v-html="$t('general.nps.rating_desc')"></p>
  </section>
</template>

<script>

export default {
  name: "NetPromoterScoreRating",
  data() {
    return {
      formGroups: [],
      rating_pick: '',
    };
  },
  mounted() {
    this.arrayFromNumber(this.range)
  },
  watch: {
    rating_pick: {
      immediate: true, // To emit the initial value when the component mounts
      handler(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    onsubmit(e) {
      e.preventDefault();
      this.$emit('choose-rating', this.rating_pick);
    },
    handleInput(e){
      this.rating_pick = e.target.value;
      this.$emit('chooseRating', this.rating_pick);
    },
    arrayFromNumber(data){
      let res = Array.from({length: data}, (_, index) => index);
      this.formGroups = res
    },
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    range:{
      type:Number,
      default:11,
    }
  },
}
</script>

<style lang="scss" scoped>

.rating {
  height: 100%;
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  .intro {
    p {
      line-height: 1.6;
      color: theme('colors.muted_black');
      font-size: 1em;
    }
  }

  .traffic-bar {
    display: flex;
    align-items: center;

    .unlike-bar {
      width: 63.6%;
      height: 7px;
      background-color: #e0514b;
    }

    .normal-bar {
      width: 18.2%;
      height: 7px;
      background-color: #f6c602;
    }

    .like-bar {
      width: 18.2%;
      height: 7px;
      background-color: #249e55;
    }
  }

  form.select-rating {
    display: grid;
    gap: 24px;

    fieldset {
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      div.form-group {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        display: grid;
        place-items: center;

        label {
          color: #b1b5ab;
          font-size: 1em;
          font-weight: 700;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background: rgba(208, 212, 202, 0.4);
          transition: 0.2s ease-in-out;
          cursor: pointer;
        }

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0;
          cursor: pointer;

          &:checked + label {
            background: #FFB000;
            color: #ffffff;
          }
        }

        &:hover label {
          background: #b1b5ab;
          color: #ffffff;
        }
      }
    }

    button[type='submit'] {
      outline: none;
      border: none;
      background: #FFB000;
      color: #ffffff;
      padding: 15px 0;
      border-radius: 99rem;
      font-size: 0.9em;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-weight: 700;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      &:hover {
        background: #ffffff;
        color: #FFB000;
      }
    }
  }
}
</style>
