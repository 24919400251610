import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
import ForgotPassword from '../pages/ForgotPassword.vue'
import Register from '../pages/Register.vue'
import Home from '../pages/Home.vue'
import User from "../pages/UserManagement";
import AddUser from "../components/user/AddUser";
import EditUser from "../components/user/EditUser";
import Declaration from "../pages/Declaration";
import Client from "../pages/Client";
import Property from "../pages/Property";
import Report from "../pages/Report";
import Deadline from "@/pages/Deadline";
import Datev from "../pages/Datev";
import Addison from "../pages/Addison";
import AddisonGgw from "../pages/AddisonGgw";
import Ggw from "../pages/Ggw";
import Settings from "../pages/Settings";
import Search from "../pages/Search";
import ResetPassword from "../pages/ResetPassword";
import Branch from "../pages/Branch";
import PowerOfAttorney from "../pages/PowerOfAttorney";
import DocumentViewer from "../pages/DocumentViewer";
import NotFound from "../pages/errors/NotFound";
import LinkDatevDevice from "@/pages/LinkDatevDevice";
import datevLoginRecovery from "@/pages/DatevLoginRecovery";
import datevDataUpdate from "@/pages/DatevDataUpdate";
import Notifications from "@/components/Notifications";
import Documents from "@/pages/Documents";
import ChangeManagement from "@/pages/ChangeManagement";
import dataExportStatus from "@/pages/dataExportStatus";

// Below block of code handles duplicated navigation failure
// Destructuring to extract the push method from the VueRouter prototype
const { push } = VueRouter.prototype;
// Destructuring to extract isNavigationFailure and NavigationFailureType from VueRouter
const { isNavigationFailure, NavigationFailureType } = VueRouter;
// Override the push method in the VueRouter prototype
VueRouter.prototype.push = function (location) {
  // Call the original push method and handle errors
  return push.call(this, location).catch(error => {
    // Check if the error is not a duplicated navigation failure
    if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
      // If not a duplicated failure, rethrow the error
      throw Error(error);
    }
    // If it's a duplicated failure, handle it gracefully without throwing an error
  });
};

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  //Register & Login
  {
    path: '/login',
    name: 'LoginPage',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/linkdatevdevice',
    name: 'LinkDatevDevice',
    component: LinkDatevDevice
  },
  {
    path: '/datevLoginRecovery',
    name: 'datevLoginRecovery',
    component: datevLoginRecovery
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Home
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
  },
  {
    path: '/declaration/:comp',
    name: 'Declaration',
    component: Declaration
  },
  {
    path: '/document/:id',
    name: 'DocumentViewer',
    component: DocumentViewer
  },
  {
    path: '/document/:parentType/:parentId/:id',
    name: 'DocumentViewerNewTab',
    component: DocumentViewer
  },
  {
    path: '/dataExportStatus/',
    name: 'dataExportStatus',
    component: dataExportStatus
  },
  {
    path: '/setting/:comp',
    name: 'Setting',
    component: Settings
  },
  {
    path: '/branch/:comp',
    name: 'Branch',
    component: Branch
  },
  {
    path: '/powerofattorney/:comp',
    name: 'PowerOfAttorney',
    component: PowerOfAttorney
  },
  {
    path: '/user/:comp',
    name: 'UserManagement',
    component: User
  },
  {
    path: '/client/:comp',
    name: 'client',
    component: Client
  },
  {
    path: '/documents/:comp',
    name: 'documents',
    component: Documents
  },
  {
    path: '/changemanagement/:comp',
    name: 'ChangeManagement',
    component: ChangeManagement
  },
  {
    path: '/property/:comp',
    name: 'Properties',
    component: Property
  },
  {
    path: '/report/:comp',
    name: 'Reports',
    component: Report
  },
  {
    path: '/deadline/:comp',
    name: 'Deadline',
    component: Deadline
  },
  {
    path: '/datevDataUpdate',
    name: 'DatevDataUpdate',
    component: datevDataUpdate
  },
  {
    path: '/datev/ggw/:comp',
    name: 'Ggw',
    component: Ggw
  }, {
    path: '/datev/:comp',
    name: 'Datev',
    component: Datev
  },
  {
    path: '/addison/:comp',
    name: 'Addison',
    component: Addison
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: ':id',
        // path: '/usermanagement',
        name: 'add',
        component: AddUser,
        meta: {
          breadcrumbs: true,

        },
      },
      {
        path: ':id',
        name: 'EditUser',
        component: EditUser,
        props: true,
        meta: {
          breadcrumbs: true,
        },
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true
  },
  {
    path: '*',
    redirect: {name: '404', params: {resource: 'Page'}}

  }
]

const router = new VueRouter({
  routes
})

export default router
