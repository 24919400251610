<template>
  <div class="h-full bg-white pl-7 w-full">
    <PageHeader :title="$t('clients.lexware_import.title')"/>
    <div class="w-full flex flex-col pr-7 mt-8" v-if="!uploadSuccess">
      <div class="box flex justify-start items-center">
        <img class="image ml-2" src="@/assets/info_gray.svg"/>
        <label class="text ml-2">
          {{ $t('clients.lexware_import.info') }}
        </label>
      </div>
      <div class="flex flex-col w-1/2 mt-10">
        <InputSelect :label="$t('clients.add.branch')" :isRequired="true" :options="branchList"
                     :isError="isBranchError" :errorMessage="branchError"
                     :selectedValue="branch_uid"
                     :showHelpIconPopover="true"
                     :popoverTexts="[$t('clients.add.tooltip')]"
                     :filterable="true" v-model="branch_uid"/>
      </div>
      <div class="space-y-4 mt-6">
        <InputUpload class="w-1/2" :label="$t('clients.lexware_import.select')" @select="fileSelect" accept=".xml"/>
      </div>
      <div class="flex justify-end mt-10 mb-6 space-x-4 ">
        <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="cancelImport" v-if="!uploadRunning"/>
        <Button class="w-56" :text="$t('clients.import.upload_review')" @click="submitUpload" v-if="!uploadRunning"/>
        <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" :disabled="true" @click="cancelImport" v-if="uploadRunning"/>
        <Button class="w-56" :text="$t('clients.import.upload_review')" :disabled="true" @click="submitUpload" v-if="uploadRunning"/>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center" v-if="uploadSuccess">
      <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex m-2">
        <img src="@/assets/check_done.svg"/>
      </div>
      <div class="top-center mt-6">
        <label class="text self-start ">{{ $t('clients.lexware_import.finished') }}</label>
        </div>

        <Button class="w-1/2 mt-10" :text="$t('register.next')" @click="cancelImport" :marginauto="true"/>
      </div>
    </div>
  </div>
</template>
<script>
import InputUpload from "../inputs/InputUpload";
import {i18n} from "@/plugins/i18n";
import Vue from "vue";
import {BranchService} from "@/services/branch.service";

export default {
  name: "LexwareImport",
  components: {InputUpload},
  data() {
    return {
      uploadRunning: false,
      uploadSuccess: false,
      selectedFile: [],
      branchList: [],
      branch_uid: "0",
      isBranchError: false,
      branchError: ''
    }
  },
  created() {
    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });
      if (this.branchList.length === 1) {
        this.branch_uid = this.branchList[0].code;
      }
    });
  },
  methods: {

    submitUpload() {
      if(this.branch_uid === '0') {
        this.$api.showToast(i18n.t('clients.lexware_import.error_branch_missing'), 'error');
        return;
      }

      if (this.selectedFile.length === 0) {
        this.$api.showToast(i18n.t('clients.import.choose_file'), 'error');
        return;
      }

      this.uploadRunning = true;
      let formData = new FormData();
      formData.append('file', this.selectedFile[0]);
      formData.append('branch', this.branch_uid);
      this.$api.trigger('addison/lexware', formData, true, 'multipart/form-data').then((response) => {
        if(response.data.success) {
          this.uploadSuccess = true;
        } else {
          if(response.data.errorKey) {
            this.$api.showToast(i18n.t('clients.addison_import.errors.' + response.data.errorKey), 'error');
          } else {
            this.$api.showToast(i18n.t('clients.addison_import.errors.other'), 'error');
          }
          this.uploadRunning = false;
        }
      });
    },
    cancelImport() {
      this.$router.push('/client/list')
    },
    fileSelect(e) {
      if (e.files.length > 0) {
        let file = null;
        file = e.files[0];
        this.selectedFile = [];
        this.selectedFile.push(file);
      }
    }
  }
}


</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.text-link {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}
</style>
