<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component mt-4">{{ $t('properties.title') }}</p>
    <hr class=" hr-line-user  mr-7 mb-1 mt-8 "/>
    <div class="w-full flex flex-col pr-7 mt-8"/>

    <!-- Import Error Dialog -->
    <ModalDialog v-show="showImportError" :headerText="$t('general.import_error')" @close="closeImportErrorDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <DataGrid
              grid-id="property_import_errors"
              :local-data="importErrors"
              grid-type="importError"
              default-sort-attr="row_no"
              :default-sort-dir="1"
              :show-select-col="false"
              :use-local-data="true"
          />
          <div class="flex mt-10 mb-4  mr-6 justify-end   space-x-4">
            <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeImportErrorDlg"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="tb_data">
      <p class="header1 ">{{ $t('clients.import.title') }}</p>
      <table>
        <thead>
        <tr class="pd_big">
          <th>{{ $t('clients.import.records') }}</th>
          <th>
            <div class="arw_box"><span class="cursor-pointer" @click="prevRow"><img src="@/assets/arrow-right.svg"></span>
              <p class="head">{{ getPageText }}</p><span class="cursor-pointer" @click="nextRow"><img src="@/assets/arrow-left.svg"></span></div>
          </th>
          <th></th>
        </tr>
        <tr class="tb_head">
          <th>{{ $t('clients.import.col_header') }}</th>
          <th>{{ $t('clients.import.value') }}</th>
          <th class=" text-left">{{ $t('clients.import.choose_attr') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr class="tb_dataone" v-for="item in getRow" :key="item.row_index">
          <td>{{ item.header }}</td>
          <td>{{ item.value }}</td>
          <td>
            <div class="select_bx" >
              <InputSelect  v-model="mapped_attrs[item.col_index]" :selectedValue="mapped_attrs[item.col_index]"
                           :options="attrs" placeholder=""></InputSelect>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="flex justify-end space-x-4 mt-6 mr-6 pb-20">
        <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="reImport"/>
        <Button class="w-40" :text="$t('buttons.import')" @click="importProperties"/>
      </div>
    </div>
  </div>
</template>

<script>

import {PropertyService} from "@/services/property.service";
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "../DataGrid";

export default {
  name: "csvimportierenproperty",
  components: {DataGrid},

  data() {
    return {
      service: new PropertyService(),
      excelData: [],
      excelError: [],
      totalLines: 0,
      clientUid: '',
      currentLine: 1,
      currentRow: {},
      attrs: [],
      breadcrumbs: [
        {
          title: this.$i18n.t('breadcrumbs.land_units'),
          link: ''
        }, {
          title: this.$i18n.t('breadcrumbs.add_property'),
          link: ''
        }
      ],
      colHeaders: [],
      mapped_attrs: [],
      importErrors: [],
      showImportError: false,
      /*step1: {
        clientUid: ""
      }*/
    }
  },
  computed: {
    getPageText() {
      return this.$t('clients.import.pagination', {
        "current": this.currentLine,
        "total": this.totalLines
      })
    },
    getRow() {
      if (this.excelData.length > 0) {
        let data = this.excelData[this.currentLine];
        let colCount = this.colHeaders.length;
        let rows = [];
        for (let k = 0; k < colCount; k++) {
          rows.push({
            "header": this.colHeaders[k],
            "value": data[k] ? data[k] : '',
            "row_index": this.currentLine + '-' + k,
            "col_index": k
          });
        }
        return rows;
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.excelData = this.getImportData();
    this.excelError = this.getExcelError();
    this.clientUid = this.excelError[0].client_uid

    this.attrs = Object.values(this.excelError[0].first_sheet_headers).map(function(value) { return value; });

    if(this.excelData.length === 0) {
      this.cancelImport();
    } else {
      this.colHeaders = this.excelData[0];
      this.totalLines = this.excelData.length - 1; // First row will be header

      // Detect mapping
      let colLength = this.colHeaders.length;

      for(let k=0; k<colLength; k++) {
        let attr = this.attrs.filter(item => {

          if(item.name.toLowerCase() === this.colHeaders[k].toLowerCase()) {
            return item;
          }
        })
        if(attr.length > 0) {
          this.mapped_attrs[k] = attr[0]['code'];
        }
      }
    }
  },
  methods: {
    ...mapGetters("property", ["getImportData", "getExcelError", "setImportData"]),
    ...mapMutations("grid", ["triggerReload"]),
    cancelImport() {
      this.$router.push('/property/list');
    },
    reImport() {
      this.$router.go(-1);
    },
    importProperties() {

      if (this.getExcelError()){
        this.clientUid = this.$store.state.clientUid
      }

      this.service.import(this.excelData, this.mapped_attrs, this.clientUid).then((response) => {
        if (response.data.success) {
          var errorMessage = '';
          if (typeof response.data.invalid_rows !== 'undefined') {
            var invalidRowsObj = response.data.invalid_rows;
            if (typeof invalidRowsObj === 'object') {
              for (var key in invalidRowsObj) {
                if (invalidRowsObj.hasOwnProperty(key)) {
                  var valObj = invalidRowsObj[key];
                  if (typeof valObj === 'object') {
                    var valStr = '';
                    var valArray = [];
                    for (var key1 in valObj) {
                      if (valObj.hasOwnProperty(key1)) {
                        valArray.push(valObj[key1]);
                      }
                    }

                    if (valArray.length > 0) {
                      valStr = valArray.join(", ");
                    }

                    if (valStr !== '') {
                      errorMessage += this.$t('properties.excel_import_invalid').replace('%fieldName%', valStr).replace('%rowNumber%', key) + "\n";
                    }
                  }
                }
              }
            }
          }

          if (errorMessage !== '') {
            this.$toast.error(errorMessage);
          } else {
            this.$toast.success(this.$i18n.t('clients.import.success', {
              "count": response.data.count
            }));
            this.setImportData([]);
            this.$router.push('/property/list');
          }
        } else if (response.data.importErrors) {
          this.showImportError = true;
          this.importErrors = this.service.parse_object_data(response.data.importErrors);
          this.triggerReload('property_import_errors')
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    nextRow() {
      if(this.totalLines > this.currentLine) {
        this.currentLine++;
      }
    },
    prevRow() {
      if(this.currentLine > 1) {
        this.currentLine--;
      }
    },
    closeImportErrorDlg() {
      this.showImportError = false;
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.tb_data {
  font-size: 15px;

}

.tb_dataone {
  font-size: 15px;

}

.tb_data td, .tb_data th {
  padding: 12px;
}

.tb_dataone td {
  padding: 5px;
}

.tb_data > table {
  width: 100%;

}

.tb_data table tr {
  border-top: 1px solid rgba(0, 0, 0, .2);
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.tb_data tr th:nth-child(3) {
  width: 65%;
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, .2);
}

.tb_data tr td:nth-child(3) {
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, .2);
}

tr.tb_head {
  background: #f2f2f2;
}

.select_bx {
  width: 250px;
}

.btn_row {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.btn_row > button {
  max-width: 260px;
}

.select_bx span {
  font-size: 14px;
}

.tb_data {
  padding-right: 20px;
  display: block;
}

tr.pd_big th {
  // padding: 24px 12px !important;
}

.arw_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;

}

.arw_box > span {

  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 25px;
  height: 23px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #797979;
  background-color: #ffffff;
  // box-sizing: border-box;
}

.arw_box > span img {
  width: 7px;
}

.arw_box > span:nth-child(1) {
  transform: rotate(
          180deg);
}

th {

  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  line-height: normal;
  font-size: 15px;
  text-align: left;

}

td {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  // line-height: 50px;
  font-size: 15px;
  text-align: left;
}

.header1 {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-bottom: 20px;
}
</style>
