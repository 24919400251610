<template>
  <div class="flex flex w-full h-screen justify-center bg-white items-center">
    <div class=" left lg:w-1/2 sm:w-full h-full py-20      p-5">
      <div class="flex flex-col items-center  justify-center    bg-white-200">
        <div v-if="getTheme()==='ebnerstolz'" class="flex flex-col mt-20 items-center">
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img class="mb-4  " src="@/assets/themes/ebnerstolz/dashboard_header.svg">
          </a>
          <label class="mb-10 sub">{{ getVersion }}</label>

        </div>
        <div v-else class="flex flex-col mt-20 justify-center items-center">
          <a href="https://grundsteuer-digital.de/" target="_blank">
            <img class="  " :src="getAssetPath('logo.svg')">
          </a>
          <label class="mb-10 sub">{{ $t('login.version') }} {{ getVersion }}</label>
        </div>

        <!--        <img class=" mt-10" :src="getAssetPath('logo.svg')">-->
        <!--        <label class="mb-10 sub">{{ getVersion }}</label> -->
        <div class="OrdnerText">
          <label class=" text ml-10 mr-10">{{ $t('forgotPassword.headerPart1') }}</label>
          <label class=" text ml-10 mr-10 mt-4">{{ $t('forgotPassword.headerPart3') }}</label>
          <label class=" text ml-10 mr-10 mt-4">{{ $t('forgotPassword.headerPart2') }}</label>
        </div>

        <div class="py-10 px-5 w-full">
          <div class="OrdnerText">
            <InputText @keyup="submit" v-model="username" class="mt-4 mb-4" type="email"
                       :label="$t('login.usernameLabel')"
                       :isRequired="true"
                       :placeholder="$t('login.usernamePlaceholder')"/>

            <div>
              <Button @click="submit" :fullwidth="true" class="w-full"
                      :text="$t('forgotPassword.button')"></Button>
            </div>
            <div class="mt-5">
              <span class="label__link ml-1" @click="goToLogin">{{ $t('forgotPassword.signup') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="right img-illustration lg:w-1/2 lg:visible sm:invisible sm:w-0 "
         src="@/assets/login_illustration.png"/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      username: ''
    }
  },
  computed: {
    getVersion() {
      return `${this.$t('login.version')}${process.env.PACKAGE_VERSION}`
    },
  },
  methods: {
    ...mapGetters("user", ["getCurrentTheme"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    goToLogin() {
      this.$router.go(-1);
    },
    submit() {
      //this.$recaptcha('forgot_password').then((recaptcha_token) => {
      this.$api.trigger('forgot_password', {
        'email': this.username
        //'recaptcha_token': recaptcha_token
      }, true).then((response) => {
        if (response.data.success) {
          this.$api.showToast(response.data.info);
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      })
      //});
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  @include segoe-regular;

  &__link {
    color: $primary;
    cursor: pointer;
  }
}

.sub {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: left;
  line-height: 20px;
  font-size: 14px;

}

.enter-login {

  width: 419px;
  height: 32px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: black;
  text-align: left;

  &:focus {
    border: 2px solid green;
  }

}

.text {
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: normal;
}


/* ForGot Password  */
.OrdnerText label {
  display: block;
}

.OrdnerText {
  max-width: 500px;
  margin: 0 auto;
}

@media only screen and (min-width: 500px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 400px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 300px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 200px) {
  /* For tablets: */
  .right {
    width: 0px;
    visibility: hidden;
  }
  .left {
    width: 100%;

  }
}

@media only screen and (min-width: 1024px) {
  /* For tablets: */
  .right {
    width: 50%;
    visibility: visible;
  }
  .left {
    width: 50%;

  }
}

.img-illustration {

  height: 100%;

}
</style>
