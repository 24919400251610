<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.popup.departments')" :breadcrumbs="breadcrumbs"/>

    <div class="mr-7">
      <AppGrid :grid-id="gridId"
             data-endpoint="branch/list"
             :columns="columns"
             default-sort-attr="name"
             :default-sort-dir="1"
             :selectable="true"
             selection-data-key="uid"
             :show-filter="false"
             :menus="menus"
             @col-click="handleColClick"
             @menu-clicked="handleUserMenuClick"
             @create-new="createNew"
    />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {BranchService} from "../../services/branch.service";
import {mapGetters, mapMutations} from "vuex";
import {appMixin} from "@/core/utils/mixin";
import {UserService} from "@/services/user.service";

export default {
  name: "departments",
  components: {
    AppGrid
  },
  mixins: [appMixin],
  data() {
    return {
      branchService: new BranchService(),
      userService: new UserService(),
      gridId: "department",
      standardFilters: [],
      columns: [{
        field: 'name',
        header: this.$t('settings.branches.table.name'),
        filterLbl: this.$t('settings.branches.table.name'),
        isChecked: true,
        clickable: true,
        elType: 'text'
      }, {
        field: 'street',
        header: this.$t('settings.branches.table.street'),
        filterLbl: this.$t('settings.branches.table.street'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'zip',
        header: this.$t('settings.branches.table.zip'),
        filterLbl: this.$t('settings.branches.table.zip'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'city',
        header: this.$t('settings.branches.table.city'),
        filterLbl: this.$t('settings.branches.table.city'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'phone',
        header: this.$t('settings.branches.table.phone'),
        filterLbl: this.$t('settings.branches.table.phone'),
        isChecked: true,
        elType: 'text'
      }],
      breadcrumbs: [
        {
          title: this.$t('settings.branches.menu.setting'),
          link: ''
        },
        {
          title: this.$t('sidebar.popup.departments'),
          link: ''
        }
      ],
      menus: [
        {
          id: "branch.add",
          "icon": this.getAssetPath('document_add.svg'),
          "title": this.$t('settings.departments.menu.department_add'),
        },
        {
          id: "branch.edit",
          "icon": this.getAssetPath('document_edit.svg'),
          "title": this.$t('settings.departments.menu.department_edit'),
          "icon_disabled": this.getAssetPath('document_edit_disabled.svg'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": false,
        },
        {
          id: "branch.delete",
          "icon": this.getAssetPath('document_delete.svg'),
          "icon_disabled": this.getAssetPath('delete_gray.svg'),
          "title": this.$t('settings.departments.menu.department_delete'),
          "action_endpoint": 'branch/delete',
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": false,
          "callback": () => {
            this.setCurrentUserStatsDataChanged(true);
          }
        },
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh'),
        }
      ]
    }
  },
  mounted() {
    this.init();
    this.userService.getCurrentUserStats();
  },
  methods: {
    ...mapMutations('grid', ["triggerReload"]),
    ...mapMutations('branch', ["init"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
    handleUserMenuClick(args) {
      switch (args.menuId) {
        case 'branch.add':
          if(this.$isBusiness && this.getCurrentUser().plan_details && this.getCurrentUser().plan_details.location != '' && this.getCurrentUser().plan_details.location != 'Unlimited') {
            this.userService.getCurrentUserStats().then((stats) => {
              if(stats.branchCount >= this.getCurrentUser().plan_details.location) {
                this.$api.showToast(this.$t('general.errors.packageLimitReached'), "error");
              } else {
                this.$router.push('/branch/add');
              }
            })
          } else {
            this.$router.push('/branch/add');
          }
          break;

        case 'branch.edit':
          if (args.data[0].uid) {
            this.$router.push("/branch/edit?uid=" + args.data[0].uid)
          }
          break;

        default:
          this.$emit('click', args.menuId)
      }
    },
    handleColClick(e) {
      if (e.field === 'name' && parseInt(e.row.uid) > 0) {
        this.$router.push("/branch/edit?uid=" + parseInt(e.row.uid));
      }
    },
    createNew() {
      this.$router.push('/branch/add');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

</style>
