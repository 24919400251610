<template>
  <div class=" flex flex-col justify-start rectangle widget-list-container ">
    <!-- header -->
    <Header :title="config.title" :showDropDown="true" :showSearch="config.showSearch" @searchWidget="searchWidget" @popupclick="remove"/>
    <div class="w-full h-full flex flex-col pr-2 pb-5 overflow-y-hidden cursor-default">
      <DataGrid
        :grid-id="config.gridId"
        :state-ful="false"
        :default-sort-attr="config.defaultSortAttr"
        :default-sort-dir="config.defaultSortDir"
        :data-endpoint="config.dataEndpoint"
        :server-params="config.serverParams"
        :columns="config.columns"
        :page-length="config.pageLength ? config.pageLength : 10"
        :pagination="false"
        :show-select-col="false"
        :resizable-columns="false"
        :reorderable-columns="false"
        :show-create-record="false"
        @col-click="handleColClick"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Dashboard/Header"
import DataGrid from "@/components/DataGrid";
import {mapMutations} from "vuex";

export default {
  name: "ListWidget",
  components: {Header, DataGrid},
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    widgetId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations("grid", ["triggerReload", "setFilter"]),
    searchWidget(txtFilter) {
      this.setFilter({
        'gridId': this.config.gridId,
        'txt': txtFilter
      });
      this.triggerReload(this.config.gridId);
    },
    handleColClick(e) {
      e.widgetId = this.widgetId
      this.$emit('col-click', e)
    },
    remove(){
        this.$emit('click', "removeWidget")
    }
  }
}
</script>

<style scoped lang="scss">
 .widget-list-container::v-deep .table-container {
   height: 100%;
   th:first-child, td:first-child {
     padding-left: 20px !important;
   }
 }
</style>
