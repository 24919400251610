const state = {
  currentStep: 1,
  result: {},
  clients: [],
  checkLocalConnection: true,
  datevLocalParams: {},
  org: [],
  businessPartnerId: '',
  preSelectBranch: 0
};
const getters = {
  getCurrentStep: (state) => state.currentStep,
  getResult: (state) => state.result,
  getClients: (state) => state.clients,
  lookupLocalConnection: (state) => state.checkLocalConnection,
  getDatevLocalParams: (state) => state.datevLocalParams,
  getOrgInfo: (state) => state.org,
  getSelectedBusinessPartner: (state) => state.businessPartnerId,
  getPreSelectBranch: (state) => state.preSelectBranch
}

const mutations = {
  init: (state) => {
    state.result = {
      "added": 0,
      "updated": 0,
      "failed": 0,
      "unchanged": 0
    }
    state.clients = []
    state.checkDatevLocalConnection = true;
    state.org = [];
    state.businessPartnerId = '';
    state.preSelectBranch = 0;
  },
  setCurrentStep: (state, step) => {
    state.currentStep = step
  },
  saveResult: (state, result) => {
    state.result = {...state.result, ...result};
  },
  saveClients: (state, clients) => {
    for (let k = 0; k < clients.length; k++) {
      state.clients.push(clients[k]);
    }
  },
  setDatevLocalParams: (state, localParams) => {
    state.datevLocalParams = localParams
    state.checkLocalConnection = false;
  },
  saveOrgInfo: (state, orgInfo) => {
    state.org = orgInfo
  },
  setBusinessPartner: (state, businessPartnerId) => {
    state.businessPartnerId = businessPartnerId;
  },
  resetBusinessPartner: (state) => {
    state.businessPartnerId = '';
    state.clients = [];
  },
  setPreSelectBranch: (state, branch) => {
    state.preSelectBranch = branch;
  }
};

const actions = {};


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};



