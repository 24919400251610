<template>
  <div class="flex  flex-col items-center  ">
    <div class="flex items-center self-start ml-4 mt-2">
      <div class="avatar-wrapper rounded-full flex items-center bg-green justify-center">
        <p class="avatar">{{ getInitials }}</p>
      </div>

      <div class="flex flex-col flex-auto ml-3 mb-1 items-center">
        <p class="name self-start">{{ nameText }} </p>
        <p class="message self-start">{{ messageText }} </p>
        <p class="date self-start">{{ date }}</p>

      </div>
    </div>
    <hr class=" line   mb-1 mt-2"/>
  </div>
</template>

<script>
export default {
  name: "MessageItem",
  props: {
    nameText: {
      type: String,
      default: ""
    },
    messageText: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    boldText: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    getInitials() {
      var matches = this.nameText.match(/\b(\w)/g);
      var acronym = matches.join('');
      return acronym
    }
  }
}
</script>

<style scoped lang="scss">
.line {
  width: 95%;
}

.notification-bold-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
}

.avatar-wrapper {
  width: 42px;
  height: 42px;
  padding: 20px;
}

.avatar {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.message {
  display: block;
  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 16px;

  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.name {
  width: 80%;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 2px;
}

.date {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
}
</style>
