<template>
  <MainLayout value="properties">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MainLayout from "../layouts/MainLayout";
import edit from '@/components/properties/EditProperty'
import ArchivedProperties from '@/components/properties/ArchivedProperties'

export default {
  name: "Property",
  components: {MainLayout, edit, ArchivedProperties},
  data() {
    return {}
  },
  computed: {
    ...mapGetters("property", ["getPropertyDataChanged"]),
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    this.checkAccess('properties');
  },
  methods: {
    ...mapMutations("property", ["setPropertyDataChanged"]),
    handleComponentClick(data) {
      switch (data) {

          ///Property menu
        case 'add_property':
          this.$router.push('/property/add')
          break;
        case 'edit_property':
          this.currentComponent = 'edit';
          this.$router.push('/property/edit')
          break;
        case 'property_add_finish':
          this.currentComponent = 'list';
          this.$router.go(-1);

          break;
      }
    }
  },
  beforeRouteLeave (to, from, next)
  {
    if (this.getPropertyDataChanged) {
      this.$modal.show({
        headerText: this.$t('general.unsaved-change-exist-header'),
        text: this.$t('general.unsaved-change-exist-info'),
        showClose: true,
        canEscape: true,
        inverseBtnColor: true,
        buttons: {
          ok: this.$t('general.leave-page'),
          cancel: this.$t('general.cancel'),
          delete: false
        },
        onConfirm: () => {
          this.setPropertyDataChanged(false);
          next();
        },
        onCancel: () => {
          return;
        },
        onHide: () => {
          return;
        }
      });
    } else {
      next();
    }
  }
}
</script>
