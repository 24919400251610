<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.clients')"/>

    <!--    Stotax Import Dialog-->
    <ModalDialog v-show="isStotaxModalVisible" :headerText="headerTextStotax" @close="handleStotaxModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <label v-show="headerText!=='Jetzt synchronisieren'" class=" label text-left  mt-4">Letzte Aktualisierung von
            Stotax Basisdaten online: <span class="bold">{{ lastsyncstotax }}</span> </label>
          <img class="qr self-center mb-10 mt-10" src="@/assets/qr.png" v-show="headerText==='Jetzt synchronisieren'"/>

          <div class="flex mt-20 mb-4  mr-6 justify-end   space-x-4" v-show="headerText!=='Jetzt synchronisieren'">
            <Button class="" text="Jetzt synchronisieren" @click="saveSettingLastSyncStotax"/>
            <Button class="" text="Einstellungen " @click="goBack"/>
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="handleStotaxModalClose"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Modal: Delete multiple - clients with properties not deleted -->
    <ModalDialog
      v-show="isDeletionPreventedModalVisible"
      :headerText="$t('clients.deletionPrevented.header')"
      @close="handlePreventedDeletionModalClose"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-8">
            {{ $t('clients.deletionPrevented.text') }}
          </div>

          <div class="mt-8">
            {{ $t('clients.deletionPrevented.affected') }}: {{ deletionPreventedUids }}
          </div>

          <div class="flex mt-10 mb-8 mr-4 justify-end space-x-4 ">
            <Button
              class="w-40"
              :text="$t('clients.confirmDelete.buttons.ok')"
              @click="handlePreventedDeletionModalClose"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Modal: Confirm deletion of client with properties / offer download -->
    <ModalDialog
      v-show="isConfirmDeletionModalVisible"
      :headerText="$t('clients.confirmDelete.header')"
      @close="handleDownloadModalClose"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-8">
            {{ $t('clients.confirmDelete.text1') }}
            <span v-if="confirmDeletionHasDocuments">{{ $t('clients.confirmDelete.text2') }}</span>
          </div>

          <div class="flex mt-10 mb-8 justify-end space-x-4">
            <Button
              class="w-40"
              :text="$t('clients.confirmDelete.buttons.cancel')"
              :secondary="true"
              @click="handleDownloadModalClose"
            />

            <Button v-if="confirmDeletionHasDocuments"
              class="w-80"
              :text="$t('clients.confirmDelete.buttons.download')"
              :secondary="true"
              @click="handleDownload"
              :delete="true"
            />

            <Button
              class="w-40"
              :text="$t('clients.confirmDelete.buttons.confirm')"
              @click="handleConfirmDelete"
              :delete="true"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Modal: Download timed out - offer background job -->
    <ModalDialog
      v-show="isDownloadTimeoutModalVisible"
      :headerText="$t('clients.downloadTimeout.header')"
      @close="handleDownloadTimeoutModalClose"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-8">
            {{ $t('clients.downloadTimeout.text') }}
          </div>

          <div class="flex mt-10 mb-8 justify-center space-x-4 ">
            <Button
              class="w-40"
              :text="$t('clients.confirmDelete.buttons.cancel')"
              :secondary="true"
              @click="handleDownloadTimeoutModalClose"
            />

            <Button
              class="w-80"
              :text="$t('clients.downloadTimeout.yes')"
              @click="handleBackgroundDownload"
              :delete="true"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="mr-7 client_grid">
      <AppGrid :grid-id="gridId"
               data-endpoint="client/list"
               count-endpoint="client/list"
               :columns="columns"
               default-sort-attr="name"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="prim_uid"
               navigation-data-key="prim_uid"
               :state-ful-filter="true"
               :standard-filters="standardFilters"
               :server-params="serverParams"
               :menus="menus"
               :show-create-record="true"
               @menu-clicked="handleMenuClick"
               @col-click="handleColClick"
               @create-new="createNew"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {ClientService} from "@/services/client.service";
import {mapGetters, mapMutations} from "vuex";
import _find from "lodash/find";
import {BranchService} from "@/services/branch.service";
import {UserService} from "@/services/user.service";

export default {
  name: "list",
  components: {AppGrid},
  metaInfo() {
    return {
      title: this.getTitle,
    }
  },
  data() {
    return {
      service: new ClientService(),
      serverParams: {
        "parentType": this.parentType,
        "parentId": 0
      },
      gridId: "clients",
      selectedColumns: null,
      lastsyncdatev: this.getCurrentUser().settings.datev_last_synced,
      lastsyncstotax: this.getCurrentUser().settings.stotax_last_synced,
      standardFilters: [
        {
          selected: false,
          default: true,
          name: this.$t('clients.standard_filters.all_clients'),
          id: 'all_clients'
        }, {
          selected: false,
          default: false,
          name: this.$t('clients.standard_filters.active_clients'),
          id: 'active_clients'
        }, {
          selected: false,
          default: false,
          name: this.$t('clients.standard_filters.inactive_clients'),
          id: 'inactive_clients'
        }
      ],
      columns: [
        {
          field: 'client_id',
          header: this.$t('clients.attrs.id'),
          isChecked: true,
          elType: 'text',
          clickable: true,
          width: 180
        }, {
          field: 'client_type_icon',
          header: this.$t('clients.attrs.type'),
          isChecked: true,
          elType: 'select',
          image: true,
          bodyClass: 'client_type',
          headerClass: 'text-center-imp',
          filterOptions: this.$t('clients.client_types'),
          tooltip: (row) => {
            return this.getColTip('client_type', row);
          }
        }, {
          field: 'client_name',
          header: this.$t('clients.attrs.name'),
          filterLbl: this.$t('general.attrs.name_contains'),
          isChecked: true,
          elType: 'text',
          autoWidth: true,
          clickable: true
        }, {
          field: 'status',
          header: this.$t('clients.attrs.status'),
          isChecked: true,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('clients.client_status_options')
        }, {
          field: 'client_title',
          header: this.$t('clients.attrs.title'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'email',
          header: this.$t('clients.attrs.email'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'branch',
          header: this.$t('clients.attrs.branch'),
          isChecked: false,
          elType: 'select',
          sortable: false,
          filterOptions: '',
          clickable: true
        }, {
          field: 'assignee',
          header: this.$t('clients.attrs.assignee'),
          isChecked: false,
          elType: 'user_select',
          clickable: true
        }, {
          field: 'street',
          header: this.$t('clients.attrs.street'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'house_number',
          header: this.$t('clients.attrs.house_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'po_box',
          header: this.$t('clients.attrs.po_box'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'zip_code',
          header: this.$t('clients.attrs.postcode'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'city',
          header: this.$t('clients.attrs.location'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'country_uid',
          header: this.$t('clients.attrs.country'),
          isChecked: false,
          elType: 'select',
          filterableSelect: true,
          filterOptions: '',
          clickable: true
        }, {
          field: 'mobile',
          header: this.$t('clients.attrs.mobile'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'phone',
          header: this.$t('clients.attrs.phone'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'fax',
          header: this.$t('clients.attrs.fax'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'dob',
          header: this.$t('clients.attrs.dob'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between',
          clickable: true
        }, {
          field: 'vat_id',
          header: this.$t('clients.attrs.tax_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'client_number',
          header: this.$t('clients.attrs.client_number'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'vat_number_wot_code',
          header: this.$t('clients.attrs.vat_number'),
          isChecked: false
        }, {
          field: 'legal_form',
          header: this.$t('clients.attrs.legal_form'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('clients.legal_forms'),
          clickable: true
        }, {
          field: 'client_portal_access',
          header: this.$t('clients.edit.tabs.client_portal'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: [{
            "code": '',
            "name": this.$t('general.please_select')
          }, {
            "code": '1',
            "name": this.$t('general.yes')
          }, {
            "code": '2',
            "name": this.$t('general.no')
          }]
        }, {
          field: 'standard_power_of_attorney',
          header: this.$t('settings.powers_of_attorney.default_power_of_attorney'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          sortable: false,
          filterOptions: [{
            "code": '1',
            "name": this.$t('general.present')
          }, {
            "code": '2',
            "name": this.$t('general.none')
          }]
        }, {
          field: 'note',
          header: this.$t('clients.attrs.internal_note'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }
      ],

      selectedRecords: [],
      menus: [
        {
          id: "cm.add_client",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('clients.menu.add')
        }, {
          id: "cm.edit_client",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('clients.menu.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_client",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('clients.menu.delete'),
          "action_endpoint": 'client/delete',
          "callback": (response) => {
            this.setClientList([]);
            this.setCurrentUserStatsDataChanged(true);
            this.deleteCallback(response);
          },
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.export",
          "icon": this.getAssetPath('export.svg'),
          "icon_disabled": this.getAssetPath('export_gray.svg'),
          "title": this.$t('clients.menu.export'),
          "disabled": true
        }, {
          id: "cm.datev_import",
          "icon": require('@/assets/datev_green.svg'),
          "title": this.$t('clients.menu.datev_import'),
          "canShow": this.getCurrentUser().settings.tax_office_software === 'datev'
        }, {
          id: "cm.stotax_import",
          "icon": require('@/assets/StotaxLogoButton.png'),
          "title": this.$t('clients.menu.stotax_import'),
          "canShow": this.getCurrentUser().settings.tax_office_software === 'stotax'
        }, {
          id: "cm.addison_import",
          "icon": require('@/assets/StotaxLogoButton.png'),
          "title": this.$t('clients.menu.addison_import'),
          "canShow": this.getCurrentUser().settings.tax_office_software === 'addison'
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      countries: [],
      datevClientAdded: 0,
      datevClientUpdated: 0,
      datevClientFailed: 0,

      isStotaxModalVisible: false,
      headerTextStotax: "Stotax-Import",
      headerText: '',

      isDeletionPreventedModalVisible: false,
      deletionDeletedCount: 0,
      deletionPreventedUids: '',

      isConfirmDeletionModalVisible: false,
      confirmDeletionUid: 0,
      confirmDeletionId: 0,
      confirmDeletionHasDocuments: false,

      isDownloadTimeoutModalVisible: false,

      canForceDeleteClients: false
    }
  },
  computed: {
    getTitle() {
        return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer' : `GrundsteuerDigital - ${this.$t('components.clients')}`
    }
  },
  beforeMount() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field === 'country_uid') {
          this.columns[i].filterOptions = this.countries;
        }
      }
    });

    (new BranchService()).listMyBranches().then((list) => {
      let branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });

      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field === 'branch') {
          this.columns[i].filterOptions = branchList;
        }
      }
    });

    (new UserService()).getCurrentUserStats().then((stats) => {
      this.menus = this.menus.map((o) => {
        if(o.id === 'cm.export') {
          o.disabled = !(stats.clientCount > 0);
        }
        return o ;
      })
    });
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
    ...mapMutations("client", ['setClientList']),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
    ...mapMutations("grid", ["triggerReload"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    saveSettingLastSyncDatev() {
      this.$api.trigger('setting/save', {
        "datev_last_synced": 1
      }, true).then((response) => {
        if (response.data.success) {
          this.$api.get_user();
        } else {
          this.$api.showToast(this.$t('settings.taxSetting.error'), 'error');
        }
      })
    },
    saveSettingLastSyncStotax() {
      this.$api.trigger('setting/save', {
        "stotax_last_synced": 1
      }, true).then((response) => {
        if (response.data.success) {
          this.$api.get_user();
          this.isStotaxModalVisible = false;
        } else {
          this.$api.showToast(this.$t('settings.taxSetting.error'), 'error');
        }
      })
    },
    canShowMenuItem() {
      return process.env.VUE_APP_DEV_VARIANT !== 'business'
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_client':
          this.$router.push('/client/add')
          break;
        case 'cm.edit_client':
          if (args.data[0].prim_uid) {
            this.triggerEdit(args.data[0].prim_uid);
          }
          break;
        case 'cm.datev_import':
          this.$router.push('/datev/ImportData')
          break;
        case 'cm.stotax_import':
          this.isStotaxModalVisible = true
          break;
        case 'cm.export':

          let selectedRecordsStr = '';
          if (args.data.length > 0) {
            let selectedRecords = [];
            for (let item of args.data) {
              selectedRecords.push(item.prim_uid);
            }
            selectedRecordsStr = selectedRecords.join(",");
          }

          this.service.export(args.selectedColumns, args.filter, selectedRecordsStr);

          break;
        default:
          this.$emit('click', args.menuId)
      }
    },
    handleColClick(e) {
      if(parseInt(e.row.branch_id) > 0 && e.field === 'branch') {
        this.$router.push("/branch/edit?uid=" + parseInt(e.row.branch_id));
      } else if (parseInt(e.row.prim_uid) > 0) {
        this.triggerEdit(parseInt(e.row.prim_uid))
      }
    },
    triggerEdit(uid) {
      this.$router.push({
        name: "client",
        query: {
          'uid': uid
        },
        params: {
          'comp': 'edit',
          'nav_records': this.getGridNavUids(this.gridId)
        }
      });
    },
    handleStotaxModalClose() {
      this.isStotaxModalVisible = false
    },
    show_datev_settings() {
      this.handleDatevModalClose();
      this.$router.push('/datev/ImportData');
    },
    getColTip(col, row) {
      if (col === 'client_type' && row.client_type) {
        let found = _find(this.$t('clients.client_types'), {"code": row.client_type});
        if (found) {
          return found.name;
        }
      }
      return '';
    },
    deleteCallback(response) {
      this.canForceDeleteClients = this.getCurrentUser().permissions.can_force_delete_clients;

      if (typeof response.data.preventUids !== 'undefined') {
        if (response.data.clientCount > 1) {
          this.deletionPreventedUids = response.data.preventUids;
          this.deletionDeletedCount = response.data.deletedCount;
          this.isDeletionPreventedModalVisible = true;
        } else {
          if (this.canForceDeleteClients === '1') {
            this.confirmDeletionUid = response.data.preventUids;
            this.confirmDeletionId = response.data.preventedId;
            this.confirmDeletionHasDocuments = response.data.hasDocuments;
            this.isConfirmDeletionModalVisible = true;
          } else {
            this.$api.showToast(this.$t('clients.confirmDelete.deletionDenied'), 'error')
          }
        }
      }
    },
    createNew() {
      this.$router.push('/client/add');
    },
    handlePreventedDeletionModalClose() {
      this.isDeletionPreventedModalVisible = false;
    },
    handleDownloadModalClose() {
      this.isConfirmDeletionModalVisible = false;
    },
    handleDownloadTimeoutModalClose() {
      this.isDownloadTimeoutModalVisible = false;
      this.isConfirmDeletionModalVisible = true;
    },
    handleDownload() {
      this.$api.trigger('client/download', {uid: this.confirmDeletionId}, true, '', 'blob', true)
        .then((response) => {
          if (response.headers['downloadtimeout'] || [504, 524].includes(response.status)) {
            this.isConfirmDeletionModalVisible = false;
            this.isDownloadTimeoutModalVisible = true;
          } else if (response.headers['downloadexpired']) {
            this.$api.showToast(this.$t('clients.confirmDelete.errorExpired'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else if (response.headers['downloadpending']) {
            this.$api.showToast(this.$t('clients.confirmDelete.errorAlreadyQueued'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else if (response.headers['permissiondenied']) {
            this.$api.showToast(this.$t('clients.confirmDelete.permissionDenied'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else if (response.headers['downloaderror']) {
            this.$api.showToast(this.$t('clients.confirmDelete.errorDownload'), 'error')
            this.isConfirmDeletionModalVisible = false;
          } else {
            this.$api.trigger_download(response);
          }
        });
    },
    handleBackgroundDownload() {
      this.$api.trigger('client/download', {uid: this.confirmDeletionId, inBackground: 1}, true, '', 'json', true)
        .then((response) => {
          if (response.data.success) {
            this.isDownloadTimeoutModalVisible = false;
            this.triggerReload(this.gridId);
            this.$api.showToast(this.$t('clients.confirmDelete.backgroundConfirm'));
          } else {
            if (response.data.errorKey == 'downloadExpired' || response.data.errorKey == 'downloadPending') {
              this.isDownloadTimeoutModalVisible = false;
              this.$api.showToast(this.$t('clients.confirmDelete.errorAlreadyQueued'), 'error')
            } else if (response.data.errorKey == 'permissionDenied') {
              this.isDownloadTimeoutModalVisible = false;
              this.$api.showToast(this.$t('clients.confirmDelete.permissionDenied'), 'error')
            } else {
              this.$api.showToast(this.$t('general.errors.unknownError'), 'error')
            }
          }
        });
    },
    handleConfirmDelete() {
      this.$api.trigger('client/delete', {uid: this.confirmDeletionId, force: '1'}, true, '', 'json', true)
        .then((response) => {
          if (response.data.success) {
            this.triggerReload(this.gridId);
            this.$api.showToast(this.$t('clients.confirmDelete.deletionConfirmed'));
            this.isConfirmDeletionModalVisible = false;
          } else {
            if (response.data.errorKey == 'permissionDenied') {
              this.$api.showToast(this.$t('clients.confirmDelete.permissionDenied'), 'error');
              this.isConfirmDeletionModalVisible = false;
            } else {
              this.$api.showToast(this.$t('general.errors.unknownError'), 'error')
            }
          }
        });
    },
  }
}
</script>

<style scoped lang="scss">
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.client_grid::v-deep {
  td.client_type div.cell-content img.column_icon {
    margin-left: auto;
    margin-right: auto;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.icon-table {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.hr-line-clients {
  color: #dfdfdf;
  background-color: #dfdfdf;
  box-sizing: border-box;
}

.bold {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.qr {
  width: 251px;
  height: 220px;
  box-sizing: border-box;
}
.text {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

</style>
