<template>

    <div class="container-main">

      <div class=" flex flex-row justify-center items-center  ">
        <div v-for="step in availableSteps" :key="step.id" class=" flex justify-center items-center w-full flex step_div" :class="stepCustomLClass(step)">
          <div class="flex flex-row justify-center items-center w-full">
            <hr class="line" v-if="typeof step.first === 'undefined' || step.first === false"
                :class="customLClasses(step) "/>
            <div class="flex flex-col justify-center items-center w-10">
              <!--              step circle-->
              <div :class="getCircleClass(step)" class=" circle ">
                <img class="form_step_check" src="@/assets/check.svg" v-show="step.completed==true"/>
              </div>
              <!--              step label-->

              <span :class="getTitle(step)"
                    class="text flex justify-center  h-11 w-20 flex-wrap">{{ step.title }}  </span>
            </div>
<!--            line between steps-->
            <hr class="line" v-if="typeof step.last === 'undefined' || step.last === false"
                :class="customRClasses(step) "/>
          </div>

        </div>

      </div>

    </div>

</template>

<script>
export default {
  name: "FormSteps",
  props:{
    steps:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      availableSteps: []
    }
  },
  mounted() {
    this.availableSteps = this.steps;
  },
  computed: {

  },
  watch: {
    steps(newValue, oldValue) {
      if (newValue !== oldValue && newValue.length > 0) {
        this.availableSteps = newValue;
      }
    }
  },
  methods: {
    getCircleClass(step){
      return [{'completed':step.completed===true||step.active===true},
        {'bg-gray':step.completed===false||step.active===false}]
    },
    getTitle(step){

      return [{

        'active-step': step.active === true,
        'complete': step.completed === true,

      },
      ]
     //step.completed===true?'bg-green':'bg-gray';
    },
    stepCustomLClass(step) {
      let stepClass = step.id + 'step step_' + step.id;

      if (step.first === true || step.last === true) {
        stepClass += ' half-width';
      }

      return stepClass;
    },
    customLClasses(step) {
      return [
        {"completed": step.completed || step.active},
        {"pending": !(step.completed || step.active)},
        {"active-step": step.active}
      ]
    },
    customRClasses(step) {
      return [
        {"completed": step.completed},
        {"pending": !step.completed},
        {"active-step": step.active}
      ]
    }
  }

}
</script>

<style scoped lang="scss">
.completed {
  background-color: $primary;
  color: $primary;
}

.nowrap {
  white-space: nowrap;
}

.active-step {
  background-color: $primary;
}

.pending {
  background-color: $gray-800;
}
.circle {
  @apply rounded-full flex items-center justify-center    flex    m-2;
  width: 23px;
  height: 23px;
}
.line {
  @apply flex mb-11 justify-center w-full h-0    border-2 border border-green;
  margin-right: -1px;

  &.completed {
    border-color: $primary;
  }

  &.pending {
    border-color: #d3d3d3;
    background-color: #d3d3d3;
  }
}
.text {


  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #7b7c7c;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  text-overflow: ellipsis;

  &.active-step {
    @apply font-bold;
    background-color: transparent;
    font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 600;
    color: $primary; //$form-active-color
  }

  &.complete {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    color: $primary; //$form-active-color
    text-align: center;
    line-height: 20px;

  }

}

.container-main {
  margin-top: 20px;
  background-color: transparent;
}
.step_div  {
  max-width: 140px;
  &.half-width {
    width: 50%;
    max-width: 100px;
  }
  .text {
    font-size: 15px !important;
  }
}
</style>
