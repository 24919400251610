const state = {
    currentStep: 1,
    currentClientId: '0',
    currentPropertyId: '0',
    declarationRelevantMarkedByUser: 0,
    updatedAfterSubmission: 0,
    nextDeclarationType: '',
    nextDeclarationReferenceDate: '',
    lastCheckedForChanges: 0,
    newStatus: '',
    oldStatus: '',
    addedElement: {},
    removedElement: {},
    data: {},
    declarationDataChanged: false,
    declarationClientEmail: ''
};
const getters = {
    getCurrentStep: (state) => state.currentStep,
    getCurrentClientId: (state) => state.currentClientId,
    getCurrentPropertyId: (state) => state.currentPropertyId,
    getDeclarationRelevantMarkedByUser: (state) => state.declarationRelevantMarkedByUser,
    getUpdatedAfterSubmission: (state) => state.updatedAfterSubmission,
    getNextDeclarationType: (state) => state.nextDeclarationType,
    getNextDeclarationReferenceDate: (state) => state.nextDeclarationReferenceDate,
    getLastCheckedForChanges: (state) => state.lastCheckedForChanges,
    getFederalStateId: (state) => state.federalStateUid,
    getNewStatus: (state) => state.newStatus,
    getOldStatus: (state) => state.oldStatus,
    getAddedElement: (state) => state.addedElement,
    getRemovedElement: (state) => state.removedElement,
    getSteps: (state) => state.data,
    getDeclarationDataChanged: (state) => state.declarationDataChanged,
    getDeclarationClientEmail: (state) => state.declarationClientEmail
}

const mutations = {
    init: (state) => {
        state.currentStep = 1;
        state.data = {};
    },
    setCurrentStep: (state, step) => {
        state.currentStep = step
    },
    saveSteps: (state, data) => {
        state.data = {...state.data, ...data};
    },
    setClientId: (state, val) => {
        state.currentClientId = val
    },
    setPropertyId: (state, val) => {
        state.currentPropertyId = val
    },
    setPropertyDeclarationRelevantMarkedByUser: (state, val) => {
        state.declarationRelevantMarkedByUser = val
    },
    setPropertyUpdatedAfterSubmission: (state, val) => {
        state.updatedAfterSubmission = val
    },
    setPropertyNextDeclarationType: (state, val) => {
        state.nextDeclarationType = val
    },
    setPropertyNextDeclarationReferenceDate: (state, val) => {
        state.nextDeclarationReferenceDate = val
    },
    setLastCheckedForChanges: (state, val) => {
        state.lastCheckedForChanges = val
    },
    setFederalStateId: (state, val) => {
        state.federalStateUid = val
    },
    setNewStatus: (state, val) => {
        state.newStatus = val
    },
    setOldStatus: (state, val) => {
        state.oldStatus = val
    },
    setAddedElement: (state, val) => {
        state.addedElement = val
    },
    setRemovedElement: (state, val) => {
        state.removedElement = val
    },
    setAddElementStatus: (state, val) => {
        state.addedElement.status = val
    },
    setDeclarationDataChanged: (state, payload) => {
        state.declarationDataChanged = payload
    },
    setDeclarationClientEmail: (state, val) => {
        state.declarationClientEmail = val
    }
};

const actions = {};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
