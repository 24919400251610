<template>
  <div>
    <div class="flex flex-row justify-between" v-if="showSearch">
      <div class="flex mt-2">
      </div>
      <div class="mt-2" v-if="showSearch">
        <FilterInput :grid-id=gridId :standard-filters="standardFilters" :columns="columns" :show-filter="showFilter"
                     :show-view-menu="showViewMenu"
                     :state-ful="stateFulFilter"
                     :initial-search="initialSearch"
                     @isSearchActive="searchActive"/>
      </div>
    </div>
    <div :class="showSearch ? 'w-full mt-8' : ''">
      <DataGrid :grid-id="gridId"
                data-endpoint="version/list"
                default-sort-attr="version_number"
                :default-sort-dir="-1"
                :columns="columns"
                :server-params="serverParams"
                :initial-txt-filter="initialSearch"
                selection-data-key="prim_uid"
                :show-select-col="false"
                :auto-load="false"
                :expandable="true"
                :state-ful="false"
                :show-create-record="false"
                :show-info-on-load="true"
                v-observe-visibility="{
                callback: loadVersions
               }"
      />
    </div>
  </div>
</template>

<script>
import DataGrid from "./DataGrid";
import {ApiService} from "../services/api.service";
import {mapMutations} from "vuex";
import FilterInput from "@/components/inputs/FilterInput";

export default {
  name: "VersionGrid",
  components: {
    DataGrid, FilterInput
  },
  props: {
    parentType: {
      type: String,
      default: ""
    },
    parentId: {
      type: Number,
      default: 0
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    standardFilters: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    showViewMenu: {
      type: Boolean,
      default: true
    },
    stateFulFilter: {
      type: Boolean,
      default: false
    },
    initialSearch: {
      type: String,
      default: ""
    }

  },
  data() {
    return {
      isSearchActive: false,
      service: new ApiService(),
      gridId: 'version_grid',
      triggered: 0,
      columns: [
        {
          field: 'version_number',
          header: this.$t('versions.attrs.version_number'),
          isChecked: true
        }, {
          field: 'created_at',
          header: this.$t('versions.attrs.created_at'),
          isChecked: true,
          elType: 'date_range',
          operator: 'between'
        }, {
          field: 'created_by',
          header: this.$t('versions.attrs.created_by'),
          isChecked: true,
          elType: 'text'
        }
      ],
      serverParams: {
        "parentType": this.parentType,
        "parentId": this.parentId
      }
    }
  },
  watch: {
    parentId(nv) {
      this.serverParams.parentId = nv;
      if (parseInt(this.serverParams.parentId) > 0 && this.serverParams.parentType !== '') {
        this.service.setDefaultParams(this.serverParams)
      }
    }
  },
  created() {
    if (parseInt(this.serverParams.parentId) > 0 && this.serverParams.parentType !== '') {
      this.service.setDefaultParams(this.serverParams);
      this.gridId = this.serverParams.parentType + '_versions';
    }
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    searchActive(status) {
      this.isSearchActive = status
    },
    loadVersions(visible) {
      if (visible) {
        if(this.triggered === 1) {
          this.triggerReload(this.gridId);
        } else {
          this.triggered++;
        }
      }
    },
  }
}
</script>
