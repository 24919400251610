<template>
  <div class="flex items-center justify-center">
    <div class="flex cursor-pointer" v-show="showFilter" @click="togglePanel">
      <img class="icon-filter1 self-center " :src="getAssetPath('filter.svg')"/>
      <div class="text-filter flex-auto filter_lbl self-center">{{ filter_lbl }}</div>
    </div>
    <OverlayPanel v-if="showFilter" class="overlaynew" ref="op" appendTo="body" :showCloseIcon="false"
                  id="overlay_panel">
      <div class="-ml-6  mr-2 ">
        <div class="space-y-2">
<!--          <IconLabel class="-mt-1 " :icon="require('@/assets/circle_gray.svg')"-->
<!--                     iconStyle="blackish" :blackText="true"-->
<!--                     :label="$t('general.grid.new_filter')" @click="newFilter"/>-->
          <div class=" main justify-center items-center flex  cursor-pointer ml-6 " @click="newFilter">
            <div class="mr-2">
              <img class="icon-add" src="@/assets/circle_gray.svg"/>
            </div>
            <div class="icon_text flex-auto text_black" style="margin-left:2px; font-size:15px">
              {{ $t('general.grid.new_filter') }}
            </div>
          </div>
          <div class=" main justify-center items-center flex  cursor-pointer ml-6 " @click="resetFilter">
            <div class="mr-3 ml-1">
              <img class="icon-close" src="@/assets/cross_filter_gray.svg"/>
            </div>
            <div class="icon_text flex-auto text_black" style="margin-left:2px; font-size:15px">
              {{ $t('general.grid.reset_filter') }}
            </div>
          </div>
        </div>

        <!-- Custom Filter Menu -->
        <div class="flex flex-col justify-start mt-1  " v-if="customFilters.length">
          <div class="flex justify-between items-center w-full">
            <label class="filter_header ml-6 ">{{ $t('general.grid.custom_filters') }} </label>
            <img src="@/assets/cog_gray.svg" class="icon -mr-4 cursor-pointer" @click="manageFilters"/>
          </div>
          <div class="max-h-24 overflow-y-scroll space-y-1">
            <div v-for="(filter) of customFilters" :key="filter.id">
              <IconLabel :icon="filter.selected ? 'selected' : ''" :blackText="true"
                         :label="filter.name" :key="filter.id" @click="filterClickHandler(filter, true)"/>
            </div>
          </div>
        </div>

        <!-- Standard Filter Menu -->
        <div class="flex flex-col justify-start mt-1" v-if="standardFilters.length">
          <div class="flex justify-between items-center">
            <label class="filter_header ml-6">{{ $t('general.grid.regular_filter') }}</label>
          </div>
        </div>
        <div class="max-h-52 overflow-y-scroll space-y-1">
          <div v-for="(filter) of standardFilters" :key="filter.id">
            <IconLabel :icon="filter.selected ? 'selected' : ''" :blackText="true" :label="filter.name"
                       @click="filterClickHandler(filter, true)"/>
          </div>
        </div>
      </div>
    </OverlayPanel>
    <OverlayPanel v-if="showViewMenu" ref="list_views" append-to="body" :show-close-icon="false" id="list_views_pnl">
      <div class="-ml-6">
        <div class="space-y-1">
          <IconLabel :withSelectedIcon="true" iconStyle="blackish" :icon="getAssetPath('filter_right_green.svg')"
                     :blackText="true"
                     :label="$t('general.grid.normal_list')" :showSelectedIcon="currentView==='normal'"
                     @click="changeGridView('normal')"/>
          <IconLabel :withSelectedIcon="true" class="mt-1" iconStyle="blackish" :icon="getAssetPath('compact.svg')"
                     :blackText="true"
                     :label="$t('general.grid.compact_list')" :showSelectedIcon="currentView==='compact'"
                     @click="changeGridView('compact')"/>
        </div>
      </div>
    </OverlayPanel>

    <div class="flex items-center justify-between search pl-3 mt-1 ">
      <transition name="slide-fade" mode="out-in">
        <img :src="getAssetPath('search-green.svg')" v-show="!hasFocus" class="image-filter-input-search">
      </transition>
      <form autocomplete="off" @submit.prevent="txtFilterHandler" class="w-full">
        <input class="input focus:outline-none" @focus="handleFocus"
               autocomplete="off" type="search"
               @blur="handleBlur" @hover="handleHover" v-model="search" :placeholder="$t('general.grid.search')"
        />
      </form>
      <transition name="fade" mode="out-in">
        <img class="close-icon " v-show="hasFocus" src="@/assets/close_thin.png" @click="handleCancel"/>
      </transition>
    </div>
    <div class="ml-2" @click="toggleView" v-if="showViewMenu">
      <img class="icon-filter icon-filter1" :src="this.getAssetPath('filter_right_green.svg')"/>
    </div>

    <!-- New filter dialog -->
    <ModalDialogTable v-if="showFilter" v-show="isNewFilterDialogVisible" :headerText="customFilterDialoglbl"
                      :canCloseOutsideClick="false"
                      :loading="loadingFilter"
                      :canEscape="true" @close="newFilterDialogClose">
      <template v-slot:body>
        <div class="flex flex-col h-full overflow-y-scroll custom-filters-frm justify-start space-y-4 ml-4 pr-4  mt-4 ">
          <InputText :label="$t('general.grid.filter_name')" :isRequired="true"
                     v-model="customFilter['filter_name']"/>
          <span v-for="(item) in filterColumns" :key="'pnl_' + gridId + '_' + item.field">
            <!-- DateRange field -->
            <div class="flex flex-col justify-start" v-if="item.elType === 'date_range'">
              <div class="text-left label mb-1">{{ item.filterLbl ? item.filterLbl : item.header }}</div>
              <InputDate v-model="customFilter[item.filterField ? item.filterField : item.field].value"
                         selectionMode="range" :manualInput="false"
                         :placeholder="$t('primevue.placeholderRange')"
                         :show-icon="true"/>
            </div>

            <!-- Input field -->
            <InputText v-if="item.elType === 'text'" :label="(item.filterLbl ? item.filterLbl : item.header)"
                       v-model="customFilter[item.filterField ? item.filterField : item.field].value"/>

            <!-- label field -->
            <div v-if="item.elType === 'label'" class="text-left label mb-1">{{ (item.filterLbl ? item.filterLbl : item.header) }}</div>

            <!-- label with font 600 text field -->
            <div v-if="item.elType === 'labelBold'" class="text-left label font-600 mb-1">{{ (item.filterLbl ? item.filterLbl : item.header) }}</div>

            <!-- Dropdown field -->
            <InputSelect
                v-if="item.elType === 'select' || item.elType === 'user_select' || item.elType === 'state_select'"
                :label="item.header"
                v-model="customFilter[item.filterField ? item.filterField : item.field].value"
                :selected-value="customFilter[item.filterField ? item.filterField : item.field].value"
                :filterable="item.filterableSelect"
                :total-record-count="item.filterOptionsCount ? item.filterOptionsCount : 0"
                :sort="item.filterOptionsSort ? item.filterOptionsSort : false"
                :search-fn="item.filterOptionsSearchFn ? item.filterOptionsSearchFn : null"
                :showHelpIconPopover="item.showHelpIconPopover ? item.showHelpIconPopover : false"
                :popoverTexts="[item.popoverTexts ? item.popoverTexts : '']"
                overlay-append-to="body"
                :options="getFilterOptions(item)"/>
          </span>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end space-x-4 mt-10 mr-6 pb-20">
          <Button class="w-40" :text="$t('buttons.cancel')" @click="newFilterDialogClose" :secondary="true"/>
          <Button class="w-40" :text="$t('buttons.save')" @click="saveFilter"/>
        </div>
      </template>
    </ModalDialogTable>

    <!-- Manage Custom Filters -->
    <ModalDialogTable v-if="showFilter" v-show="isManageFilterDialogVisible"
                      :headerText="$t('general.grid.manage_custom_filters')"
                      :canCloseOutsideClick="false"
                      :canEscape="true" @close="closeManageFilters">
      <template v-slot:body>

        <div class="flex flex-col h-full overflow-y-scroll  justify-start space-y-4 ml-4 pr-4  mt-4 ">
          <!--filter list-->
          <div class="flex justify-between items-center mt-3" v-for="(item) in customFilters" :key="item.id">
            <label class="label">{{ item.name }}</label>
            <div class="flex justify-end space-x-2">
              <img src="@/assets/doc_pen_gray.svg" class="icon cursor-pointer" @click="editFilter(item.prim_uid)"/>
              <img src="@/assets/delete_black.svg" class="icon cursor-pointer" @click="deleteFilter(item.prim_uid)"/>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  items-center pb-20 mr-6 ">
          <div class="flex mt-10 space-x-6">
            <Button class=" button-label  text-center   label__text-black " @click="closeManageFilters"
                    :text="$t('buttons.cancel')"></Button>
          </div>
        </div>
      </template>
    </ModalDialogTable>
  </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
import InputSelect from "./InputSelect";
import {GridService} from "@/services/grid.service";
import {mapMutations} from "vuex";
import {UserService} from "@/services/user.service";
import _clone from "lodash/cloneDeep"

export default {
  name: "FilterInput",
  components: {InputSelect, OverlayPanel},
  props: {
    gridId: {
      type: String,
      default: ""
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => [],
    },
    standardFilters: {
      type: Array,
      default: () => [],
    },
    showViewMenu: {
      type: Boolean,
      default: true
    },
    stateFul: {
      type: Boolean,
      default: false
    },
    initialSearch: {
      type: String,
      default: ""
    }
  },
  computed: {
    filterColumns() {
      return this.columns.filter(o => {
        if (!o.hideInFilter && o.elType) {
          return o;
        }
      })
    }
  },
  data() {
    return {
      hasFocus: false,
      gridService: null,
      search: "",
      showClose: false,
      customFilters: [],
      filter_lbl: this.$t('general.grid.filter'),
      customFilterDialoglbl: this.$t('general.grid.new_filter'),
      isNewFilterDialogVisible: false,
      isManageFilterDialogVisible: false,
      editFilerMode: false,
      customFilter: null,
      loadingFilter: false,
      currentView: 'normal',
      userSelectOptions: [],
      federalStateSelectOptions: []
    }
  },
  watch: {
    search(newVal) {
      this.showClose = newVal !== undefined && newVal !== '';
      this.$emit("isSearchActive", newVal !== undefined && newVal !== '');
    },
    '$store.state.grid.gridState': {
      deep: true,
      handler: function () {
        this.currentView = this.getGridState(this.gridId, 'view', this.currentView);
      }
    },
  },
  created() {
    this.customFilter = this.initFilter();
    this.gridService = new GridService(this.gridId);
    if (this.showFilter && this.gridId) {
      if(!this.gridFiltersFetched(this.gridId)) {
        this.gridService.getFilters().then((response) => {
          if (response.data.success) {
            this.customFilters = this.gridService.parse_object_data(response.data.filters).map(o => {
              let a = o;
              a.id = 'custom:' + a.prim_uid;
              a.selected = false;
              return a;
            });
            this.saveGridFilters({
              "gridId": this.gridId,
              "customFilters": _clone(this.customFilters)
            });
            this.applySavedSelectedFilter();
          }
        });
      } else {
        this.customFilters = this.getGridFilters(this.gridId);
        this.applySavedSelectedFilter();
      }
    }
  },
  mounted() {
    let userService = new UserService();
    userService.listAll().then((userList) => {
      if (userList.length > 0) {
        this.userSelectOptions = userList.map((o) => {
          return {
            "name": o.firstName + ' ' + o.lastName,
            "code": o.id
          }
        });
      }
    });

    this.$api.get_state_list().then((list) => {
      this.federalStateSelectOptions = list;
    });

    this.currentView = this.getGridState(this.gridId, 'view', this.currentView);

    if (this.initialSearch === "") {
      this.search = this.value;
    } else {
      this.search = this.initialSearch;
      this.setFilter({
        'gridId': this.gridId,
        'txt': this.search
      });
    }

  },
  methods: {
    ...mapMutations("grid", ["triggerReload", "setFilter", "saveGridState", "triggerFetchCount", "initGridFilter", "saveGridFilters"]),
    initFilter() {
      this.initGridFilter(this.gridId);
      let customFilter = {
        'filter_uid': 0,
        'filter_name': ''
      };

      this.filterColumns.map(o => {
        customFilter[o.filterField ? o.filterField : o.field] = {
          'operator': o.operator ? o.operator : 'is',
          'value': ''
        };
      });

      return customFilter;
    },
    applySavedSelectedFilter() {
      if(this.stateFul) {
        let selectedSavedFilter = this.getSelectedFilterState(this.gridId);
        if (selectedSavedFilter) {
          let filter = {
            'gridId': this.gridId,
            'txt': '',
            'selected': ''
          };

          if (selectedSavedFilter.filterId) {
            let found = false;
            if (this.customFilters.length > 0) {
              let index = this.customFilters.findIndex(o => o.id === selectedSavedFilter.filterId);
              if (index >= 0) {
                let selectedIndex = this.customFilters.findIndex(o => o.selected);
                if (selectedIndex >= 0) {
                  this.customFilters[selectedIndex]['selected'] = false;
                }
                this.customFilters[index]['selected'] = true;
                this.filter_lbl = this.customFilters[index]['name'];
                filter.selected = this.customFilters[index]['id'];
                found = true;
              }
            }

            if (!found && this.standardFilters.length > 0) {
              let index = this.standardFilters.findIndex(o => o.id === selectedSavedFilter.filterId);
              if (index >= 0) {
                let selectedIndex = this.standardFilters.findIndex(o => o.selected);
                if (selectedIndex >= 0) {
                  this.standardFilters[selectedIndex]['selected'] = false;
                }
                this.standardFilters[index]['selected'] = true;
                this.filter_lbl = this.standardFilters[index]['name'];
                filter.selected = this.standardFilters[index]['id'];
              }
            }
          }

          if (selectedSavedFilter.txt) {
            filter.txt = selectedSavedFilter.txt;
            this.$nextTick(() => this.search = selectedSavedFilter.txt);
          }

          this.setFilter(filter);
        }
      }
    },
    getFilterOptions(item) {
      if (item.filterOptions) {
        return item.filterOptions;
      } else if (item.elType === 'user_select') {
        return this.userSelectOptions;
      } else if (item.elType === 'state_select') {
        return this.federalStateSelectOptions;
      }
    },
    handleFocus(event) {
      this.hasFocus = true
      this.$emit("isSearchActive", event.target.value !== '')
    },
    handleBlur(event) {
      this.hasFocus = false
      this.$emit("isSearchActive", event.target.value !== '')
    },
    handleHover() {
      this.hasFocus = false
    },
    resetCustomFilter() {
      this.customFilter = {
        'filter_uid': 0,
        'name': ''
      };
      this.filterColumns.map(o => {
        this.customFilter[o.filterField ? o.filterField : o.field] = {
          'operator': o.operator ? o.operator : 'is',
          'value': ''
        };
      });
    },
    newFilterDialogClose() {
      if (this.editFilerMode === true) {
        this.isManageFilterDialogVisible = true;
      }
      this.isNewFilterDialogVisible = false;
    },
    // sets time for upper date range values to end of day
    extendDateRangeFiltersToEndOfDay() {
      this.columns.forEach((column) => {
        if (column.elType === 'date_range') {
          let customFilterItem = this.customFilter[column.filterField ? column.filterField : column.field];
          if (typeof customFilterItem === 'object' && Array.isArray(customFilterItem.value) && customFilterItem.value.length === 2) {
            let upperDate = customFilterItem.value[1];
            if (typeof upperDate.getMonth === 'function') {
              upperDate.setHours(upperDate.getHours()+23, upperDate.getMinutes()+59, upperDate.getSeconds()+59, upperDate.getMilliseconds()+999);
              customFilterItem.value = [
                  customFilterItem.value[0],
                  upperDate
              ];
            }
          }
        }
      }, this);
    },
    saveFilter() {
      this.extendDateRangeFiltersToEndOfDay();
      this.gridService.saveFilter(this.customFilter).then(response => {
        if (response.data.success) {
          let new_filter = {
            selected: false,
            name: response.data.name,
            id: 'custom:' + response.data.uid,
            prim_uid: response.data.uid
          }

          if (this.editFilerMode === true) {
            this.isManageFilterDialogVisible = true
            this.isNewFilterDialogVisible = false
            this.customFilters = this.customFilters.map(o => {
              return o.id === new_filter.id ? new_filter : o;
            });
          } else {
            this.customFilters.push(new_filter);
            this.filterClickHandler(new_filter, false);
            this.newFilterDialogClose()
          }

          this.saveGridFilters({
            "gridId": this.gridId,
            "customFilters": _clone(this.customFilters)
          });
          this.editFilerMode = false;
          this.triggerFetchCount(this.gridId);
          this.triggerReload(this.gridId);
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    newFilter() {
      this.customFilter = this.initFilter();
      this.customFilterDialoglbl = this.$t('general.grid.new_filter');
      this.isNewFilterDialogVisible = true;
      this.editFilerMode = false;
      this.$refs.op.toggle();
    },
    resetFilter() {
      this.resetSelectedFilter();

      let default_filter = this.standardFilters.filter(obj => typeof (obj.default) !== 'undefined' && obj.default);
      if (default_filter.length > 0) {
        default_filter[0].selected = true;
      }
      this.filter_lbl = this.$t('general.grid.filter');

      let filter = {
        'gridId': this.gridId,
        'txt': this.search,
        'selected': ''
      };

      if (default_filter.length > 0) {
        // filter.selected = default_filter[0].id;
      }

      this.setFilter(filter);
      this.triggerFetchCount(this.gridId);
      this.triggerReload(this.gridId);

      this.$refs.op.toggle();
    },
    resetSelectedFilter() {
      if (this.customFilters.length > 0) {
        this.customFilters.map(function (value) {
          value.selected = false;
        });
      }
      if (this.standardFilters.length > 0) {
        this.standardFilters.map(function (value) {
          value.selected = false;
        });
      }
      this.search = '';
    },
    togglePanel(event) {
      this.$refs.op.toggle(event);
    },
    toggleView(event) {
      this.$refs.list_views.toggle(event);
    },
    manageFilters() {
      this.$refs.op.toggle();
      this.isManageFilterDialogVisible = true;
    },
    closeManageFilters() {
      this.isManageFilterDialogVisible = false;
    },
    editFilter(uid) {
      this.customFilterDialoglbl = this.$t('general.grid.edit_filter');
      this.isManageFilterDialogVisible = false;
      this.isNewFilterDialogVisible = true;
      this.editFilerMode = true;
      this.resetCustomFilter();
      this.loadingFilter = true;

      this.gridService.getFilter(uid).then((response) => {
        if (response.data.success) {
          this.filterColumns.map(o => {
            let attr = o.filterField ? o.filterField : o.field;
            if (typeof response.data.filter[attr] !== 'undefined') {
              if (o.elType === 'date_range') {
                if (response.data.filter[attr] !== '') {
                  this.customFilter[attr].value = [];
                  let x = JSON.parse(response.data.filter[attr]);
                  if (x[0] && x[0] !== '') {
                    this.customFilter[attr].value.push(new Date(x[0]));
                  }
                  if (x[1] && x[1] !== '') {
                    this.customFilter[attr].value.push(new Date(x[1]));
                  }
                }
              } else {
                this.customFilter[attr].value = response.data.filter[attr];
              }
            }
          });
          this.customFilter['filter_name'] = response.data.filter['filter_name'];
          this.customFilter['filter_uid'] = uid;
        } else {
          this.$toast.error(response.data.message);
        }
        this.loadingFilter = false;
      })
    },
    deleteFilter(uid) {
      this.$modal.show({
        headerText: this.$t('general.grid.delete_filter'),
        text: this.$t('general.grid.delete_filter_conf'),
        showClose: true,
        canEscape: true,
        onConfirm: () => {
          this.gridService.deleteFilter(uid).then(response => {
            if (response.data.success) {
              this.customFilters = this.customFilters.filter(o => o.prim_uid !== uid)
              this.saveGridFilters({
                "gridId": this.gridId,
                "customFilters": _clone(this.customFilters)
              });
            } else {
              this.$api.showToast(response.data.message, "error");
            }
          });
        }
      });
    },
    filterClickHandler(filter, toggle) {
      this.resetSelectedFilter();
      filter.selected = true;
      this.filter_lbl = filter.name;
      this.setFilter({
        'gridId': this.gridId,
        'selected': filter.id
      });
      this.triggerFetchCount(this.gridId);
      this.triggerReload(this.gridId);

      if (toggle) {
        this.$refs.op.toggle();
      }
    },
    txtFilterHandler() {
      this.setFilter({
        'gridId': this.gridId,
        'txt': this.search
      });
      this.triggerFetchCount(this.gridId);
      this.triggerReload(this.gridId);
      this.$emit("search", this.search)
    },
    handleCancel() {
      this.search = ''
      this.showClose = false
      this.txtFilterHandler();
    },
    changeGridView(view) {
      if (this.currentView !== view) {
        this.currentView = view;
        this.saveGridState({
          "persistent": true,
          "gridId": this.gridId,
          "view": view
        });
      }
      this.$refs.list_views.toggle();
    }
  },

}
</script>

<style scoped lang="scss">
.icon-filter {
  width: 19px;
  height: 10px;
  cursor: pointer;
}

.icon-filter1 {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 2px;
}

.text-filter {

  margin-right: 6px;
  margin-left: 6px;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 50px;

}

.search {
  @include segoe-regular;
  width: 200px;
  height: 34px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 1px solid $primary;
    background-image: none
  }

  &:hover {
    background-image: none
  }
}

.search:focus-within {
  border: 1px solid $primary;

  input {
    // margin-left: -12px
  }
}


.input {
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-left: 5px;
  font-size: 13px;


}

.filter_header {
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 38px;
}

.filter_lbl {
  //text-overflow: ellipsis;
  //overflow: hidden;
  //white-space: nowrap;
  //min-width: 50px;
}


.close-icon {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(-10px);
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  opacity: 0;
}

#list_views_pnl {
  right: 30px !important;
  left: auto !important;
}

.custom-filters-frm {
  height: calc(100vh - 160px);
}
.icon-add{
  width: 21px;
  height: 23px;
}
.font-600 {
  font-weight: 600;
}
</style>
