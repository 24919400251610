<template>
  <div >
    <draft :title="$t('declarations.stages.missing_data')"
           :server-params="serverParams"
           :breadcrumbs="breadcrumbs"
           :menus="menus"
           grid-id="data_requested_declaration"
           :grid-id="gridId"
           :columns="getColumns"
    />
  </div>
</template>

<script>
import Draft from "@/components/declarations/Draft";

export default {
  name: "requested",
  components: {Draft},
  data() {
    return {
      gridId: 'missing_data_declarations',
      serverParams: {
        'filters': {
          'status': "missing_data"
        }
      },
      breadcrumbs: [
        {
          title: this.$t('declarations.draft.breadcrumbs.link1'),
          link: '/declaration/declarations'
        }, {
          title: this.$t('declarations.stages.missing_data')
        }
      ],
      menus: [
        {
          id: "dec.add",
          "icon": this.getAssetPath('add_declaration_green.svg'),
          "title": this.$t('declarations.menus.add')
        }, {
          id: "dec.edit",
          "icon": this.getAssetPath('edit_declaration_green.svg'),
          "icon_disabled": this.getAssetPath('edit-declaration-gray.svg'),
          "title": this.$t('declarations.menus.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('declarations.menus.delete'),
          "action_endpoint": 'declaration/delete',
          "action_confirmation": {
            show: true,
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.export",
          "icon": this.getAssetPath('export.svg'),
          "title": this.$t('declarations.menus.export')
        }, {
          id: "dec.bulk_processing",
          "icon": this.getAssetPath('transfer.svg'),
          "icon_disabled": this.getAssetPath('transfer-gray.svg'),
          "title": this.$t('declarations.menus.bulk_processing'),
          "canShow": false,
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true,
          "sub_menu": {
            "items": [{
              id: "dec.mass_update.client_data_request",
              "icon": 'dec_client_data_request_icon',
              "label": this.$t('declarations.menus.request_data_from_client')
            }, {
              id: "dec.mass_update.internal_approval",
              "icon": 'dec_internal_approval_icon',
              "label": this.$t('declarations.menus.internal_approval')
            }, {
              id: "dec.mass_update.document_creation",
              "icon": 'dec_document_creation',
              "label": this.$t('declarations.mass_update.process.document_creation')
            }]
          }
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      defaultColumns: [
        {
          field: 'client_uid',
          header: this.$t('clients.attrs.id'),
          isChecked: false,
          clickable: true,
          width: 180
        }, {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        }, {
          field: 'client',
          header: this.$t('clients.attrs.name'),
          filterLbl: this.$t('clients.attrs.name') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          filterLbl: this.$t('declarations.grid.address') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          isChecked: true,
          elType: 'user_select'
        }, {
          field: 'client_data_requested_at',
          header: this.$t('declarations.grid.data_request_at'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.type_options.filter_types')
        }, {
          field: 'reference_date',
          header: this.$t('declarations.grid.reference_date'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'fee_invoice_sent',
          header: this.$t('declarations.grid.fee_invoice_sent'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.grid.fee_invoice_sent_options'),
          clickable: true,
        }, {
          field: 'branch_name',
          header: this.$t('declarations.grid.branch'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'complexity',
          header: this.$t('declarations.grid.complexity'),
          isChecked: false,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options')
        }, {
          field: 'internal_note',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_property',
          header: this.$t('declarations.grid.internal_note_property'),
          filterLbl: this.$t('declarations.grid.internal_note_property') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_client',
          header: this.$t('declarations.grid.internal_note_client'),
          filterLbl: this.$t('declarations.grid.internal_note_client') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'extra_details',
          header: this.$t('declarations.grid.extra_details'),
          filterLbl: this.$t('declarations.grid.extra_details') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'elster_status',
          header: this.$t('components.properties_table_col.col25'),
          isChecked: false,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('properties.elster_status_options')
        }, {
          field: 'created_at',
          header: this.$t('declarations.grid.created_at'),
          isChecked: false,
          clickable: true,
          sortable: false
        }, {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between'
        }
      ],
    }
  },

  computed: {
    getColumns() {
      let columns = [...this.defaultColumns];

      columns = columns.map((o) => {
        if (o.field === 'reference_date') {
          let declarationReferenceDateOptions = [];
          for (var i = 2022; i <= 2050; i++) {
            declarationReferenceDateOptions.push({
              'name': i,
              'code': i
            });
          }

          o.filterOptions = declarationReferenceDateOptions;
        }

        return o;
      });

      return columns;
    }
  }
}
</script>
