<template>
  <div class="h-full bg-white px-7 w-full ">
    <PageHeader :title="title" :breadcrumbs="breadcrumbs"/>

    <div
      v-if="infoText.length > 0"
      class="flex justify-start items-center text-sm p-3 my-4 bg-gray_light rounded"
    >
      <img
        class="image"
        :src="getAssetPath('info_gray.svg')"
      />
      <label
        class="text-left ml-3"
        v-html="infoText"
      />
    </div>

    <div class="declaration-grid">
      <AppGrid
        :grid-id="gridId"
        data-endpoint="declaration/list"
        count-endpoint="declaration/list"
        :columns="getColumns"
        default-sort-attr="uid"
        :default-sort-dir="1"
        :selectable="true"
        selection-data-key="prim_uid"
        navigation-data-key="prim_uid"
        :state-ful-filter="true"
        :server-params="serverParams"
        :menus="getMenus"
        :standard-filters="standardFilters"
        :show-create-record="false"
        @menu-clicked="handleMenuClick"
        @col-click="handleColClick"
        @create-new="createNew"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "@/components/AppGrid";
import {DeclarationService} from "@/services/declaration.service";
import {mapGetters, mapMutations} from "vuex";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Draft",
  components: {
    AppGrid
  },
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('declarations.stages.draft')
      }
    },
    infoText: {
      type: String,
      default: ''
    },
    gridId: {
      type: String,
      default: "draft_declarations"
    },
    breadcrumbs: {
      type: Array,
      default: function () {
        return [
          {
            title: this.$t('declarations.draft.breadcrumbs.link1'),
            link: '/declaration/declarations'
          }, {
            title: this.$t('declarations.draft.breadcrumbs.link2')
          }
        ];
      }
    },
    menus: {
      type: Array,
      default: () => {
      }
    },
    columns: {
      type: Array,
      default: () => {
      }
    },
    serverParams: {
      type: Object,
      default: () => {
        return {
          'filters': {
            'status': "draft"
          }
        }
      }
    }
  },
  data() {
    return {
      service: new DeclarationService(),
      defaultMenus: [
        {
          id: "dec.add",
          "icon": this.getAssetPath('add_declaration_green.svg'),
          "title": this.$t('declarations.menus.add')
        }, {
          id: "dec.edit",
          "icon": this.getAssetPath('edit_declaration_green.svg'),
          "icon_disabled": this.getAssetPath('edit-declaration-gray.svg'),
          "title": this.$t('declarations.menus.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('declarations.menus.delete'),
          "action_endpoint": 'declaration/delete',
          "action_confirmation": {
            show: true,
          },
          "callback": () => {
            this.setCurrentUserStatsDataChanged(true);
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "dec.export",
          "icon": this.getAssetPath('export.svg'),
          "title": this.$t('declarations.menus.export')
        }, {
          id: "dec.bulk_processing",
          "icon": this.getAssetPath('transfer.svg'),
          "icon_disabled": this.getAssetPath('transfer-gray.svg'),
          "title": this.$t('declarations.menus.bulk_processing'),
          "canShow": false,
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true,
          "sub_menu": {
            "items": [{
              id: "dec.mass_update.client_data_request",
              "icon": 'dec_client_data_request_icon',
              "label": this.$t('declarations.menus.request_data_from_client')
            }, {
              id: "dec.mass_update.request_internal_approval",
              "icon": 'dec_internal_approval_icon',
              "label": this.$t('declarations.mass_update.process.request_internal_approval')
            }, {
              id: "dec.mass_update.internal_approval",
              "icon": 'dec_internal_approval_icon',
              "label": this.$t('declarations.mass_update.process.internal_approval')
            }, {
              id: "dec.mass_update.document_creation",
              "icon": 'dec_document_creation',
              "label": this.$t('declarations.mass_update.process.document_creation')
            }]
          }
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      defaultColumns: [
        {
          field: 'client_uid',
          header: this.$t('clients.attrs.id'),
          filterLbl: this.$t('clients.attrs.id') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
          width: 180
        }, {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        }, {
          field: 'client',
          header: this.$t('clients.attrs.name'),
          filterLbl: this.$t('clients.attrs.name') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          filterLbl: this.$t('declarations.grid.address') + ' ' + this.$t('general.contains'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          isChecked: true,
          elType: 'user_select',
          clickable: true,
        }, {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.add_declaration.type_options.filter_types'),
          clickable: true,
        }, {
          field: 'reference_date',
          header: this.$t('declarations.grid.reference_date'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'fee_invoice_sent',
          header: this.$t('declarations.grid.fee_invoice_sent'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('declarations.grid.fee_invoice_sent_options'),
          clickable: true,
        }, {
          field: 'branch_name',
          header: this.$t('declarations.grid.branch'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'complexity',
          header: this.$t('declarations.grid.complexity'),
          isChecked: false,
          elType: 'select',
          filterOptions: this.$t('properties.complexity_options'),
          clickable: true,
        }, {
          field: 'internal_note',
          header: this.$t('declarations.grid.internal_note'),
          filterLbl: this.$t('declarations.grid.internal_note') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_property',
          header: this.$t('declarations.grid.internal_note_property'),
          filterLbl: this.$t('declarations.grid.internal_note_property') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'internal_note_client',
          header: this.$t('declarations.grid.internal_note_client'),
          filterLbl: this.$t('declarations.grid.internal_note_client') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'extra_details',
          header: this.$t('declarations.grid.extra_details'),
          filterLbl: this.$t('declarations.grid.extra_details') + ' ' + this.$t('general.contains'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'elster_status',
          header: this.$t('components.properties_table_col.col25'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('properties.elster_status_options')
        }, {
          field: 'created_at',
          header: this.$t('declarations.grid.created_at'),
          isChecked: false,
          clickable: true,
          sortable: false
        }, {
          field: 'modified_at',
          header: this.$t('components.properties_table_col.col27'),
          filterLbl: this.$t('components.properties_table_col.col27'),
          isChecked: false,
          elType: 'date_range',
          operator: 'between'
        }
      ],
      propertyColumns: [
        {
          field: 'federal_state_name',
          header: this.$t('properties.step2.bundesland'),
          isChecked: false,
          elType: 'select',
          filterOptions: [],
          clickable: true,
        }, {
          field: 'community',
          header: this.$t('properties.step2.community'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'street',
          header: this.$t('properties.step2.street'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'zip',
          header: this.$t('properties.step2.zip'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'city',
          header: this.$t('properties.step2.city'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'central_no',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'economic_entity_type',
          header: this.$t('properties.step2a.economic_entity_type'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('properties.step2a.economic_entity_types.default')
        }, {
          field: 'exemption',
          header: this.$t('properties.step2a.exemption_present'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: [
            {
              "code": "1",
              "name": this.$t('general.yes')
            },
            {
              "code": "0",
              "name": this.$t('general.no')
            }
          ]
        }, {
          field: 'ownership_structure',
          header: this.$t('properties.step3.ownership_structure'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('properties.step3.ownership_structure_options')
        }, {
          field: 'property_type',
          header: this.$t('properties.step3.property_type'),
          isChecked: false,
          elType: 'select',
          clickable: true,
          filterOptions: this.$t('properties.step3.property_type_options.Bundesmodell')
        }, {
          field: 'area_of_the_land',
          header: this.$t('properties.step3.area_of_the_land_property'),
          isChecked: false,
          sortable: false,
        }, {
          field: 'district',
          header: this.$t('properties.step3.district'),
          isChecked: false,
          sortable: false,
        }
      ],
      standardFilters: [
        {
          selected: false,
          default: false,
          name: this.$t('declarations.standard_filters.my_declarations'),
          id: 'my_declarations'
        }
      ],
      branchList: [],
      stateList: []
    }
  },
  created() {
    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });
    });

    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });
  },
  computed: {
    getMenus() {
      let availableMenus = [];
      if (this.menus) {
        availableMenus = this.menus
      } else {
        availableMenus = this.defaultMenus
      }

      if(availableMenus.length > 0) {
        availableMenus = availableMenus.map((o) => {
          if(o.id === 'dec.mass_update.elster_submission') {
            o.canShow = this.hasElsterPermission;
          } else if(o.id === 'dec.bulk_processing' || o.id === 'dec.mass_update.internal_approval') {
            o.canShow = this.hasMassUpdatePermission;
            if(o.id === 'dec.mass_update.internal_approval') {
              o.canShow = this.hasMassUpdatePermission && this.hasReleasePermission;
            }
            if(o.canShow && o.sub_menu && o.sub_menu.items) {
              o.sub_menu.items = o.sub_menu.items.map((subMenu) => {
                if (subMenu.id === 'dec.mass_update.internal_approval') {
                  subMenu.visible = this.hasReleasePermission;
                } else if (subMenu.id === 'dec.mass_update.client_data_request' && this.$isBusiness) {
                  subMenu.visible = false;
                } else if (subMenu.id === 'dec.mass_update.mark_mass_appeal') {
                  subMenu.visible = this.hasMassUpdatePermission && this.hasSendAppealPermission;
                } else if (subMenu.id === 'dec.mass_update.mass_appeal') {
                  subMenu.visible = this.hasMassUpdatePermission && this.hasSendAppealPermission;
                }
                return subMenu;
              })
            }
          }

          return o;
        });
      }

      return availableMenus;
    },
    getColumns() {
      if (this.columns) {
        return this.updateColumns(this.columns);
      } else {
        return this.updateColumns(this.defaultColumns);
      }
    },
    hasMassUpdatePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.allow_mass_update) === 1;
    },
    hasSendAppealPermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.send_dispute) === 1;
    },
    hasReleasePermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.release_declaration) === 1;
    },
    hasElsterPermission() {
      return this.getCurrentUser() && parseInt(this.getCurrentUser().permissions.elster_transaction) === 1;
    }
  },
  methods: {
    ...mapMutations("declaration", ["setClientId", "setPropertyId"]),
    ...mapMutations("user", ["setCurrentUserStatsDataChanged"]),
    ...mapGetters("user", ["getCurrentUser"]),
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'dec.add':
          this.setClientId("0");
          this.setPropertyId("0");
          this.$router.push('/declaration/AddDeclaration')
          break;
        case 'dec.attachment_mass_elster_message_type':
          this.$emit('get-method', args)
          break;
        case 'dec.edit':
          if (args.data[0].prim_uid) {
            this.triggerEdit(args.data[0].prim_uid)
          }
          break;
        case 'dec.export':
          if (this.serverParams.filters && Object.keys(this.serverParams.filters).length > 0) {
            args.filter = {
              ...args.filter,
              ...this.serverParams.filters
            }
          }

          let selectedRecordsStr = '';
          if (args.data.length > 0) {
            let selectedRecords = [];
            for (let item of args.data) {
              selectedRecords.push(item.prim_uid);
            }
            selectedRecordsStr = selectedRecords.join(",");
          }

          this.service.export(args.selectedColumns, args.filter, selectedRecordsStr);
          break;
        case 'dec.mass_update.client_data_request':
        case 'dec.mass_update.request_internal_approval':
        case 'dec.mass_update.internal_approval':
        case 'dec.mass_update.client_approval_available':
        case 'dec.mass_update.request_client_approval':
        case 'dec.mass_update.elster_submission':
        case 'dec.mass_update.document_creation':
        case 'dec.mass_update.mark_mass_appeal':
        case 'dec.mass_update.mass_appeal':
          if(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_ops)) {
            if (args.data.length > 0) {
              let processType = '';
              if (args.menuId === 'dec.mass_update.client_data_request') {
                processType = 'request_data';
              } else if (args.menuId === 'dec.mass_update.request_internal_approval') {
                processType = 'request_internal_approval';
              } else if (args.menuId === 'dec.mass_update.internal_approval') {
                processType = 'internal_approval';
              } else if (args.menuId === 'dec.mass_update.client_approval_available') {
                processType = 'client_release';
              } else if (args.menuId === 'dec.mass_update.request_client_approval') {
                processType = 'request_client_approval';
              } else if (args.menuId === 'dec.mass_update.elster_submission') {
                processType = 'elster_submission';
              } else if (args.menuId === 'dec.mass_update.document_creation') {
                processType = 'document_creation';
              } else if (args.menuId === 'dec.mass_update.mark_mass_appeal') {
                processType = 'mark_mass_appeal';
              } else if (args.menuId === 'dec.mass_update.mass_appeal') {
                processType = 'mass_appeal';
              }

              if(processType !== '') {
                let alreadyActivatedUids = [];
                let selectedDeclarations = [];
                for (let item of args.data) {
                  if (processType === 'elster_submission' && item.elster_submission_approved === 'Aktiviert') {
                    alreadyActivatedUids.push(item.uid);
                  }
                  selectedDeclarations.push({
                    "id": item.prim_uid,
                    "declarationId": item.uid,
                    "name": item.address,
                    "clientName": item.client,
                    "elsterStatus": this.convertElsterStatus(item.elster_status),
                    "massAppealActDate": item.mass_appeal_act_date,
                    "massAppealType": item.mass_appeal_type,
                    "federalStateUid": item.federal_state_uid
                  });
                }

                if (alreadyActivatedUids.length > 0) {
                  let transmittedMessage = this.$t('general.grid.cannot_delete_activated_items').replace('%declaration_uids%', alreadyActivatedUids.join(", "));
                  this.$api.showToast(transmittedMessage, 'error');
                } else {
                  this.$router.push({
                    'name': 'Declaration',
                    params: {
                      'comp': 'BulkAdd',
                      'selectedDeclarations': selectedDeclarations,
                      'processType': processType,
                      'fromGrid': true
                    },
                  });
                }
              }
            }
          } else {
            this.showPackageLimitWarning();
          }
          break;
        default:
          this.$emit('menu-clicked', args)
      }
    },
    convertElsterStatus(str) {
      if(str === 'Validierung fehlgeschlagen') {
        return "2";
      }
      if(str === 'Validierung erfolgreich') {
        return "1";
      }
      return "0";
    },
    handleColClick(e) {
      if (e.field === 'client' && parseInt(e.row.client_id) > 0) {
        this.$router.push("/client/edit?uid=" + e.row.client_id);
      } else if (e.field === 'address') {
        this.$router.push({
          name: "Properties",
          query: {
            'uid': e.row.property_id
          },
          params: {
            'comp': 'edit',
            'client_uid': e.row.client_id
          }
        });
      } else if (e.field === 'assigned_user_name' && parseInt(e.row.assigned_user_id) > 0) {
        this.$router.push("/user/edit?uid=" + e.row.assigned_user_id);
      } else if (parseInt(e.row.prim_uid) > 0) {
        this.triggerEdit(e.row.prim_uid);
      }
    },
    triggerEdit(uid) {
      this.$router.push({
        name: "Declaration",
        query: {
          'uid': uid
        },
        params: {
          'comp': 'edit',
          'nav_records': this.getGridNavUids(this.gridId)
        }
      });
    },
    createNew() {
      this.setClientId("0");
      this.setPropertyId("0");
      this.$router.push('/declaration/AddDeclaration');
    },
    updateColumns(columns) {
      columns = [...columns, ...this.propertyColumns];

      if (this.branchList.length > 0) {
        columns = columns.map((o) => {
          if(o.field === 'branch_name') {
            o.filterOptions = this.branchList;
          }

          return o;
        });
      }

      if (this.stateList.length > 0) {
        columns = columns.map((o) => {
          if(o.field === 'federal_state_name') {
            o.filterOptions = this.stateList;
          }

          return o;
        });
      }

      columns = columns.map((o) => {
        if (o.field === 'reference_date') {
          let declarationReferenceDateOptions = [];
          for (var i = 2022; i <= 2050; i++) {
            declarationReferenceDateOptions.push({
              'name': i,
              'code': i
            });
          }

          o.filterOptions = declarationReferenceDateOptions;
        }

        return o;
      });

      if (this.$isBusiness) {
        // unset fee_invoice_sent in business solution
        columns = columns.filter(o => o.field !== 'fee_invoice_sent');
      }

      return columns;
    }
  }
}
</script>

<style scoped lang="scss">
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.declaration-grid::v-deep {
  .grid_action_icon {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .dec_client_data_request_icon {
    background-image: url('~@/assets/client_data_request.svg');
  }

  .dec_internal_approval_icon {
    background-image: url('~@/assets/internal_approval.svg');
  }

  .dec_document_creation {
    background-image: url('~@/assets/document_creation.svg');
  }

  .dec_elster_submission_icon {
    background-image: url('~@/assets/elster_validate.svg');
    background-position-y: bottom;
  }

  .dec_mass_appeal_icon {
    background-image: url('~@/assets/dec_mass_appeal.svg');
  }
}

</style>
