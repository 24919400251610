<template>
  <div class="ml-15 mr-15">
    <div class="mt-20 flex flex-col container h-150">
      <div class="flex flex-col w-full loading-container" :style="showloader">
        <div class="spin"></div>
      </div>
    </div>
    <div class="flex flex-col w-full h-full">
      <p class="loading-text">{{ $t('clients.datev_import.steps.step2.loading') }}</p>
      <p class="loading-text" v-show="clientCount > 0">{{ $t('clients.datev_import.steps.step2.client_found') }} {{ clientCount }}</p>
    </div>
    <div class="h-full bg-white pl-7 w-full ">
      <ModalDialog v-show="isDatevErrorModalVisible" :headerText="$t('clients.datev_import.dialog.error_text_header')" @close="handleDatevErrorModalClose">
        <template v-slot:body>
          <div class="flex flex-col justify-start ">
            <label class=" label text-left  mt-4">
              {{ datevImpoertErrorText }}
              <div class="mt-1" v-if="datevImpoertErrorDocumentPresent">
                {{$t('clients.datev_import.dialog.error_datev_help_document1')}}<a :href="datevImpoertErrorDocument" target="_blank">{{$t('clients.datev_import.dialog.error_datev_help_document2')}}</a>{{$t('clients.datev_import.dialog.error_datev_help_document3')}}
              </div>
            </label>
            <div class="flex mt-20 mb-4   justify-end space-x-4">
              <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="handleDatevErrorModalClose"/>
            </div>
          </div>
        </template>
      </ModalDialog>
    </div>
  </div>
</template>


<script>
import {ClientService} from "@/services/client.service";
import {mapGetters, mapMutations} from "vuex";
import {BranchService} from "@/services/branch.service";

export default {
  name: "Step2",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async active(nv) {
      if (nv) {
        let branchService = new BranchService();
        await branchService.listMyBranches().then((list) => {
          this.branchList = list;

          if(list.length === 1) {
            this.setPreSelectBranch(list[0].uid)
          }
        });

        this.clientCount = 0;
        this.totalClientFound = 0;
        this.offset = 0;
        this.$api.get_datev_org_info().then((response) => {
          if(response.oauth_url) {
            window.location.href = response.oauth_url;
          } else if(response.org_data.id || response.org_data.business_partners) {
            this.saveOrgInfo(response.org_data);
            if(response.org_data.id || (response.org_data.business_partners && response.org_data.business_partners.length === 1)) {
              this.fetchDatevClient();
            } else if(response.org_data.business_partners) {
              this.$emit('updateSteps', {
                "show": 3
              });
              this.$nextTick(() => {
                this.$emit("goToNextStep");
              })
            }
          } else {
            this.goBack();
          }
        })
      }
    }
  },
  data() {
    return {
      service: new ClientService(),
      totalClientFound: 0,
      clientCount: 0,
      offset: 0,
      branchList: [],
      isDatevErrorModalVisible: false,
      datevImpoertErrorText: '',
      datevImpoertErrorDocument: '',
      datevImpoertErrorDocumentPresent: false,
      showloader: ''
    }
  },
  mounted() {
    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list;
    });
  },
  methods: {
    ...mapMutations("datev_import", ["saveResult", "saveClients", "init", "saveOrgInfo", "setPreSelectBranch"]),
    ...mapGetters("datev_import", ["getOrgInfo", "getSelectedBusinessPartner", "getPreSelectBranch", "getImportMode", "getImportKey", "showInactiveClients"]),
    ...mapGetters("user", ["getCurrentUser"]),
    handleDatevErrorModalClose() {
      this.isDatevErrorModalVisible = false;
      this.$router.push('/client/list');
    },
    fetchDatevClient() {
      let params = {};
      let business_partner_id = this.getSelectedBusinessPartner();
      if (business_partner_id) {
        params['business_partner_id'] = business_partner_id;
      } else {
        let org_info = this.getOrgInfo();
        if(org_info.business_partners && org_info.business_partners.length === 1) {
          params['business_partner_id'] = org_info.business_partners[0]['number'];
        } else if(org_info.id) {
          params['datev_org_id'] = org_info.id
        }
      }
      if(this.showInactiveClients()) {
        params['include_inactive_clients'] = 1;
      }
      params['importKey'] = this.getImportKey();
      params['importMode'] = this.getImportMode();

      this.service.fetch_datev_clients(params, this.offset).then((response) => {
        if (response.data.success) {
          if (response.data.oauth_url) {
            window.location.href = response.data.oauth_url;
          } else if (response.data.clients) {
            response.data.clients = this.service.parse_object_data(response.data.clients);
            this.totalClientFound = response.data.total_count;
            this.offset += response.data.record_count;
            this.clientCount += response.data.clients.length;
            if(response.data.clients.length > 0) {
              response.data.clients = response.data.clients.map((o) => {
                if(o.prim_uid === 0) {
                  if(parseInt(this.getPreSelectBranch()) > 0) {
                    o.branch_uid = this.getPreSelectBranch();
                  } else if(o.establishment_id) {
                    let found = this.branchList.filter((b) => {
                      return b.external_name === 'datev' && b.external_id === o.establishment_id;
                    });
                    if(found.length > 0) {
                      o.branch_uid = found[0].uid;
                    }
                  }
                }

                return o;
              });

              this.saveClients(response.data.clients)
            }

            if(this.totalClientFound > this.offset) {
              this.fetchDatevClient();
            } else {
              let _this = this;
              setTimeout(function() {
                _this.$emit("goToNextStep");
              }, 500);

              this.service.trigger('client/datev_clients_fetched', {
                "importKey": this.getImportKey()
              });
            }
          }
        } else {
          this.$api.showToast(response.data.message, 'error');
          if (response.data.error_key == 'nodataenvironments') {
            this.showloader ="display:none";
            this.isDatevErrorModalVisible = true;
            this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_data_environment');
            if(response.data.document != '') {
              this.datevImpoertErrorDocument = response.data.document;
              this.datevImpoertErrorDocumentPresent = true;
            }
          }
          if (response.data.error_key == 'nouserpermissions') {
            this.showloader ="display:none";
            this.isDatevErrorModalVisible = true;
            this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_user_permissions');
            if(response.data.document != '') {
              //this.datevImpoertErrorDocument = response.data.document;
              this.datevImpoertErrorDocument = '<a :href="'+response.data.document+'" target="_blank">hier</a>';
              this.datevImpoertErrorDocumentPresent = true;
            }
          }
          if (response.data.error_key == 'noappgrants') {
            this.showloader ="display:none";
            this.isDatevErrorModalVisible = true;
            this.datevImpoertErrorText = this.$t('clients.datev_import.dialog.error_text_no_app_grants');
            if(response.data.document != '') {
              this.datevImpoertErrorDocument = response.data.document;
              this.datevImpoertErrorDocumentPresent = true;
            }
          }
          if(!response.data.error_key){
            this.$api.showToast(response.data.message, 'error');
            this.$router.push('/client/list');
          }
        }
      }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.loading-text {
  color: #9d9d9d;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.label {

  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}
</style>
