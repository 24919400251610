<template>
  <div>
    <div class="flex">
      <label class="label">{{ label }}</label><span v-if='isRequired' style="color:red"> *</span>


      <label class="error-label " :class="isError===true?'ml-1 visible':'invisible'" style="color:red">{{
          errorMessage
        }}</label>

    </div>
    <div>
      <textarea :type="type" v-model="field"
                class="enter-login pl-3 mt-1  focus:outline-none focus:ring-offset-1 focus:ring-green-600 "
                :placeholder="placeholder"/>
      <!--<div :class="isError===true?'mb-8':'invisible'">-->

    </div>


  </div>
</template>

<script>
export default {
  name: "InputTextView",
  props: {
    value: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: 'email'
    },
    label: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'type here'
    }
  },
  data() {
    return {
      field: "",
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.field = this.value;
      }
    },
    field(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
  },
  mounted() {
    this.field = this.value;
  },
}
</script>

<style scoped lang="scss">


.enter-login {
  @include segoe-regular;
  width: 100%;
  height: 70px;
  padding-top: 10px;
  border-bottom: 2px solid $border-color;
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  background-color: #ffffff;
  box-sizing: border-box;
  color: black;
  text-align: left;

  &:focus {
    border-bottom: 2px solid $primary;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

}

.label {
  @include segoe-regular;
}

.error-label {
  @include segoe-regular;
  float: left;
}

</style>
