<template>
  <div>
    <div class="flex flex-col justify-start mt-8">
      <label class="title">{{ $t('properties.deviating_owner.heading') }}</label>
      <hr class=" mt-3 mb-4 hr-line-user"/>
    </div>
    <div class="box flex justify-start items-center mb-10">
      <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
      <label class="text-left ml-2">{{ $t('properties.deviating_owner.info') }}</label>
    </div>
    <div class="flex justify-around space-x-10 mt-10">
      <InputCheck :text="$t('properties.deviating_owner.title')"
                  :isDisabled="isReadOnly"
                  :isChecked="showDeviatingOwnerSection"
                  @check="checkDeviatingOwner"/>
      <InputCheck v-show="!$isBusiness && showDeviatingOwnerSection"
                  :text="$t('properties.edit.client_Legal_rep')"
                  :isDisabled="isReadOnly"
                  :isChecked="deviatingOwner.legalRepresentative === '1'"
                  @check="checkLegalRepresentative"/>
    </div>
    <div v-show="showDeviatingOwnerSection" class="mt-10">
      <div class="flex justify-around space-x-8 mb-8">
        <InputSelect class="w-full"
                     :label="$t('clients.edit.tabs.peoplepool')"
                     :options="getClientPersons"
                     v-model="deviatingOwner.person"
                     :selected-value="deviatingOwner.person"
                     placeholder=""
                     :disabled="isReadOnly || getClientPersons.length === 0"
                     :showHelpIconPopover="true"
                     :popoverTexts="$t('properties.step4.people_pool_select_help')"
                     @on-item-selected="selectClientPerson"
        />
        <div class="w-full mr-6 invisible"/>
      </div>
      <div class="flex justify-around space-x-8">
        <InputSelect class="w-full"
                     :label="$t('general.attrs.salutation')"
                     :options="$t('general.salutation_with_none')"
                     v-model="deviatingOwner.salutation"
                     :selected-value="deviatingOwner.salutation"
                     :isRequired="true"
                     :isError="errors.salutation.invalid"
                     :errorMessage="$t('general.errors.required')"
                     placeholder=""
                     :disabled="isReadOnly"
        />
        <InputText class="w-full"
                   :label="$t('clients.attrs.title')"
                   v-model="deviatingOwner.title"
                   :disabled="isReadOnly"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 mt-4 ">
        <InputText class="w-full"
                   :label="$t('general.attrs.first_name')"
                   v-model="deviatingOwner.firstName"
                   :isRequired="true"
                   :isError="errors.firstName.invalid"
                   :errorMessage="$t('general.errors.required')"
                   :disabled="isReadOnly"/>
        <InputText class="w-full"
                   :label="$t('general.attrs.last_name')"
                   v-model="deviatingOwner.lastName"
                   :isRequired="true"
                   :isError="errors.lastName.invalid"
                   :errorMessage="$t('general.errors.required')"
                   :disabled="isReadOnly"/>
      </div>
      <div class="flex justify-around space-x-8 mt-4">
        <InputText class=" w-full"
                   :label="$t('clients.attrs.street')"
                   v-model="deviatingOwner.street"
                   :disabled="isReadOnly"
                   placeholder=""/>
        <InputText class="w-full "
                   :label="$t('clients.attrs.house_number')"
                   v-model="deviatingOwner.houseNumber"
                   :disabled="isReadOnly"
                   placeholder=""/>
        <InputText class="w-full"
                   :label="$t('clients.attrs.po_box')"
                   v-model="deviatingOwner.poBox"
                   :disabled="isReadOnly"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 mt-4 ">
        <InputText class=" w-full"
                   :label="$t('clients.attrs.postcode')"
                   v-model="deviatingOwner.zipCode"
                   :isZip="true"
                   :zipCountry="deviatingOwner.countryUid"
                   :disabled="isReadOnly"
                   @blur="searchZip"
                   placeholder=""/>
        <InputText class="w-full "
                   :label="$t('clients.attrs.city')"
                   v-model="deviatingOwner.city"
                   :disabled="isReadOnly"
                   :loading="zipSearch"
                   placeholder=""/>
        <InputSelect class="w-full"
                     :options="countries"
                     :label="$t('clients.attrs.country')"
                     :disabled="isReadOnly"
                     :filterable="true"
                     v-model="deviatingOwner.countryUid"
                     :selected-value="deviatingOwner.countryUid"
                     placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 mt-4 ">
        <InputText class="w-full"
                   :label="$t('clients.attrs.tax_number')"
                   :disabled="isReadOnly"
                   v-model="deviatingOwner.taxNumber"
                   @input="formatTaxNumber"/>
        <InputText class="w-full"
                   :label="$t('clients.attrs.client_number')"
                   :isOnlyNumber="true"
                   :maxLength="11"
                   :disabled="isReadOnly"
                   v-model="deviatingOwner.identificationNumber"/>
        <InputDate class="w-full"
                   dateId="deviatingOwnerDob"
                   v-model="deviatingOwner.dob"
                   :label="$t('clients.attrs.dob')"
                   :dob="true"
                   placeholder=""
                   :disabled="isReadOnly"/>
      </div>
    </div>
  </div>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";
import _clone from "lodash/clone";
import {mapGetters} from "vuex";

export default {
  name: "deviatingOwner",
  emits: ["changeOwnershipStructure"],
  props: {
    propertyData: Object,
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDeviatingOwnerSection: false,
      deviatingOwner: {
        "person": "",
        "salutation": "",
        "title": "",
        "firstName": "",
        "lastName": "",
        "street": "",
        "houseNumber": "",
        "poBox": "",
        "zipCode": "",
        "city": "",
        "countryUid": "",
        "taxNumber": "",
        "identificationNumber": "",
        "dob": "",
        "legalRepresentative": '0'
      },
      countries: [],
      zipSearch: false,
      errors: {
        "salutation": {
          invalid: false
        },
        "firstName": {
          invalid: false
        },
        "lastName": {
          invalid: false
        }
      }
    }
  },
  validations: {
    deviatingOwner: {
      firstName: {
        required: requiredIf(function () {
          return this.showDeviatingOwnerSection;
        })
      },
      lastName: {
        required: requiredIf(function () {
          return this.showDeviatingOwnerSection;
        })
      }
    }
  },
  mounted() {
    this.$api.get_country_list().then((list) => {
      this.countries = list;
    });

    if(this.propertyData) {
      this.setData(this.propertyData);
    }
  },
  computed: {
    ...mapGetters("property", ["getClientPersons"]),
  },
  methods: {
    hideDeviatingOwnerSection() {
      this.showDeviatingOwnerSection = false;
    },
    checkLegalRepresentative(checked) {
      if(!this.isReadOnly && this.showDeviatingOwnerSection) {
        this.deviatingOwner.legalRepresentative = checked ? '1' : '0';
      }
    },
    checkDeviatingOwner(checked) {
      if(!this.isReadOnly) {
        this.showDeviatingOwnerSection = checked;
        if (checked) {
          let ownershipStructure = this.propertyData && this.propertyData.step3.ownershipStructure ? parseInt(this.propertyData.step3.ownershipStructure) : -1;
          if (ownershipStructure === -1) {
            ownershipStructure = 0;
            this.$emit('changeOwnershipStructure', ownershipStructure);
          }
          if (ownershipStructure !== 0) {
            this.$api.showToast(this.$t('properties.deviating_owner.ownership_structure_message'), "info");
            this.$nextTick(() => this.showDeviatingOwnerSection = false);
          }
        }
      }
    },
    searchZip() {
      if (this.deviatingOwner.zipCode !== '' && (typeof(this.deviatingOwner.city) === 'undefined' || this.deviatingOwner.city === '')) {
        this.zipSearch = true;
        this.$api.lookup_city(this.deviatingOwner.zipCode).then((cities) => {
          this.zipSearch = false;
          if (cities.length > 0 && (typeof(this.deviatingOwner.city) === 'undefined' || this.deviatingOwner.city === '')) {
            this.deviatingOwner.city = cities[0].code;
          }
        })
      }
    },
    formatTaxNumber(tax_number) {
      if (this.deviatingOwner.taxNumber) {
        this.deviatingOwner.taxNumber = String(tax_number).substring(0, 13).replace(/[A-Z]/gi, '')
      }
    },
    isValid() {
      // Reset validation status
      for (let attr in this.errors) {
        this.errors[attr].invalid = false;
      }

      if (!this.showDeviatingOwnerSection) {
        return true;
      }

      if (typeof this.deviatingOwner.salutation === 'undefined' || parseInt(this.deviatingOwner.salutation) === 0 || this.deviatingOwner.salutation === '') {
        this.errors.salutation.invalid = true;
        return false;
      }

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        for (let attr in this.errors) {
          if(this.$v.deviatingOwner[attr]) {
            this.errors[attr].invalid = this.$v.deviatingOwner[attr].$error;
          }
        }
        return false;
      }

      return true;
    },
    setData(data) {
      if(data.deviatingOwner) {
        this.deviatingOwner = _clone(data.deviatingOwner);
      }

      if (!(this.deviatingOwner.id && parseInt(this.deviatingOwner.id) > 0) && (this.deviatingOwner.countryUid === '' || this.deviatingOwner.countryUid === '0')) {
        this.deviatingOwner.countryUid = this.defaultCountryId;
      }

      this.showDeviatingOwnerSection = this.deviatingOwner.id && parseInt(this.deviatingOwner.id) > 0;
    },
    getData() {
      return this.showDeviatingOwnerSection ? this.deviatingOwner : {};
    },
    getError() {
      return this.showDeviatingOwnerSection ? this.errors : {};
    },

    selectClientPerson(item) {
      this.deviatingOwner.salutation = item.data.salutation;
      this.deviatingOwner.title = item.data.title;
      this.deviatingOwner.firstName = item.data.first_name;
      this.deviatingOwner.lastName = item.data.last_name;
      this.deviatingOwner.street = item.data.street;
      this.deviatingOwner.houseNumber = item.data.house_number;
      this.deviatingOwner.poBox = item.data.postbox;
      this.deviatingOwner.zipCode = item.data.zip;
      this.deviatingOwner.city = item.data.city;
      this.deviatingOwner.countryUid = item.data.country_uid;
      this.deviatingOwner.taxNumber = item.data.tax_number;
      this.deviatingOwner.identificationNumber = item.data.identification_number;
      this.deviatingOwner.dob = item.data.date_of_birth;
    },
  }
}
</script>

<style scoped lang="scss">
  .title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;

}
</style>
