<template>
  <div >
    <properties
      :title="$t('components.archived_properties')"
      :archived="archived"
      :grid-id="gridId"
    />
  </div>
</template>

<script>

import Properties from "@/components/properties/Properties";

export default {
  name: "ArchivedProperties",
  components: {Properties},
  data() {
    return {
      gridId: 'archived_properties',
      archived: true
    }
  }
}
</script>
