const state = {
    list: []
};
const getters = {
    getCommunicationsList: (state) => state.list
}

const mutations = {
    setCommunicationsList: (state, communications) => {
        state.list = communications;
    },
};

const actions = {};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
