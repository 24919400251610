<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.popup.branches')" :breadcrumbs="breadcrumbs"/>

    <div class="mr-7">
      <AppGrid :grid-id="gridId"
               data-endpoint="branch/list"
               :columns="columns"
               default-sort-attr="name"
               :default-sort-dir="1"
               :selectable="true"
               selection-data-key="uid"
               :standard-filters="standardFilters"
               :server-params="serverParams"
               :show-filter="true"
               :menus="menus"
               @col-click="handleColClick"
               @menu-clicked="handleUserMenuClick"
               @create-new="createNew"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {BranchService} from "../../services/branch.service";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "branches",
  components: {
    AppGrid
  },
  data() {
    return {
      branchService: new BranchService(),
      serverParams: {
        "parentType": this.parentType,
        "parentId": 0
      },
      gridId: "branches",
      selectedColumns: null,
      standardFilters: [],
      columns: [{
        field: 'name',
        header: this.$t('settings.branches.table.name'),
        isChecked: true,
        clickable: this.getUserRole() !== 'user',
        elType: 'text'
      }, {
        field: 'street',
        header: this.$t('settings.branches.table.street_and_house_number'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'zip',
        header: this.$t('settings.branches.table.zip'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'city',
        header: this.$t('settings.branches.table.city'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'phone',
        header: this.$t('settings.branches.table.phone'),
        isChecked: true,
        elType: 'text'
      }, {
        field: 'email',
        header: this.$t('settings.branches.attrs.email'),
        isChecked: false,
        elType: 'text'
      }],
      breadcrumbs: [
        {
          title: this.$t('settings.branches.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.branches.menu.branches'),
          link: ''
        }
      ],
      menus: [
        {
          id: "branch.add",
          "icon": this.getAssetPath('add_branch_green.svg'),
          "icon_disabled": this.getAssetPath('add_branch_gray.svg'),
          "title": this.$t('settings.branches.menu.add_branch'),
          disabled: this.getUserRole() === 'user'
        },
        {
          id: "branch.edit",
          "icon": this.getAssetPath('edit_branch_green.svg'),
          "icon_disabled": this.getAssetPath('edit_branch_gray.svg'),
          "title": this.$t('settings.branches.menu.edit_branch'),
          "disable_on_multi": this.getUserRole() !== 'user',
          "enable_on_select": this.getUserRole() !== 'user',
          "disabled": true,
        },
        {
          id: "branch.delete",
          "icon": this.getAssetPath('delete_green.svg'),
          "icon_disabled": this.getAssetPath('delete_gray.svg'),
          "title": this.$t('settings.branches.menu.delete_branch'),
          "action_endpoint": 'branch/delete',
          "callback": (response) => {
            this.setBranchList([]);
            this.setMyBranchList([]);
            this.deleteCallback(response);
          },
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": this.getUserRole() === 'user',
          "enable_on_select": this.getUserRole() !== 'user',
          "disabled": true,
        }, {
          id: "branch.datev_import",
          "icon": require('@/assets/datev_green.svg'),
          "title": this.$t('clients.menu.datev_import'),
          "canShow": this.getCurrentUser().settings.tax_office_software === 'datev'
        },
        /*        {
                  id: "br.export",
                  "icon": this.getAssetPath('export.svg'),
                  "icon_disabled": this.getAssetPath('export_gray.svg'),
                  "title": this.$t('settings.branches.menu.export'),
                  disabled: this.getUserRole() === 'user'
                }, */
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh'),
        }
      ]
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapGetters("user", ["getUserRole"]),
    ...mapMutations('grid', ["triggerReload"]),
    ...mapMutations('branch', ["init", "setBranchList", "setMyBranchList"]),
    handleUserMenuClick(args) {
      switch (args.menuId) {
        case 'branch.add':
          this.$router.push('/branch/add')
          break;
        case 'branch.edit':
          if (args.data[0].uid) {
            this.$router.push("/branch/edit?uid=" + args.data[0].uid)
          }
          break;
        case 'branch.datev_import':
          this.$router.push('/datev/setup_branches');
          break;
        case 'br.export':
          this.branchService.export(args.selectedColumns, args.filter);
          break;

        default:
          this.$emit('click', args.menuId)
      }
    },
    handleColClick(e) {
      if (e.field === 'name' && parseInt(e.row.uid) > 0) {
        this.$router.push("/branch/edit?uid=" + parseInt(e.row.uid));
      }
    },
    deleteCallback(response) {
      if (typeof response.data.prevent_uids !== 'undefined') {
        this.$toast.error(this.$t('settings.branches.delete_prevent_msg').replace('%prevent_uids%', response.data.prevent_uids));
      }
    },
    createNew() {
      this.$router.push('/branch/add');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

</style>
