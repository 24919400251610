<template>
  <div class="">
    <div class="mt-18 flex flex-col">
      <div class="flex flex-col justify-start">
        <label class="title">{{ $t('properties.steps.step2a') }}</label>
        <hr class=" mt-3 mb-4 hr-line-user"/>
      </div>
      <div class="flex justify-around space-x-10 mt-3">
        <InputText
            class="mt-2 w-full "
            v-model="step2a.reference"
            :label="$t('properties.step2.reference')"
            placeholder=""
            :showHelpIconPopover="true"
            :popoverTexts="popoverTexts"
            :popoverLink="popoverLink"
            :is-error="errors.reference.invalid"
            :maxLength="30"
        />
        <InputText
            class="mt-2 w-full "
            v-model="step2a.name"
            :label="$t('properties.step2.name')"
            :is-required="true"
            :is-error="errors.name.invalid"
            :error-message="$t('general.errors.required')"
            placeholder=""
            :showHelpIconPopover="true"
            :popoverTexts="[$t('properties.step2.name_help')]"

        />
      </div>
      <div class="flex justify-around space-x-10 mt-2">
        <InputSelect
            class="mt-2 w-full"
            :label="$t('properties.step2a.economic_entity_type')"
            v-model="step2a.economicEntityType"
            :options="economic_entity_type_options"
            :placeholder="$t('general.please_select')"
            :is-required="true"
            :is-error="errors.economicEntityType.invalid"
            :error-message="$t('general.errors.required')"
            :showHelpIconPopover="true"
            :popoverTexts="popoverTexts_economic_entity_type"
            :popoverLink="popoverLink_economic_entity_type"
        />
        <div class=" flex flex-col  w-full">
          <div class="flex">
            <label class="text mt-2 lbl-exemption">{{ $t('properties.step2a.exemption_present') }}</label>
            <!--      help icon with HTML tooltip/popover-->
            <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']" :hideTriggers="['click']">
              <!-- This will be the popover target (for the events and position) -->
              <img class="help_icon self-center mt-2  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
              <!-- This will be the content of the popover -->
              <template #popper>
                <div class="tooltip-content">
                  <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_exemption_present"
                     v-bind:key="text">
                    {{ text }}
                  </p>
                  <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                   :href="popoverLink_exemption_present"
                                                                   target="_blank">{{
                      popoverLink_exemption_present
                    }}</a></h2>
                </div>
                <!-- You can put other components too -->

              </template>
            </VDropdown>
          </div>
          <div class="flex space-x-4">
            <div class="flex justify-start items-center">
              <RadioButton type="radio" v-model="step2a.exemption" name="radio-button" value="1"/>
              <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
            </div>
            <div class="flex justify-start items-center ">
              <RadioButton type="radio" v-model="step2a.exemption" name="radio-button" value="0"/>
              <span class="ml-3 textradio">{{ $t('general.no') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-around space-x-10 mt-5">
        <InputSelect
            class="mt-2 w-full"
            :label="$t('properties.step2a.complexity')"
            v-model="step2a.complexity"
            :options="$t('properties.complexity_options')"
            :placeholder="$t('general.please_select')"
            :showHelpIconPopover="true"
            :popoverTexts="[$t('properties.step2a.complexity_help')]"
        />
        <div class="w-full invisible"/>
      </div>
      <div class="flex justify-around space-x-10 mt-5">
        <InputText
            class="mt-2 w-full "
            bodyClass="non-resize-textarea w-full"
            v-model="step2a.extraDetails"
            :label="$t('properties.step2.extraDetails')"
            :isMultiline="true"
            :maxLimit="999"
            :errorMessage="$t('general.errors.limitDesc').replace('%length%', step2a.extraDetails.length).replace('%maxLimit%', 999)"
            placeholder=""
        />
        <InputText
            class="mt-2 w-full "
            bodyClass="non-resize-textarea w-full"
            v-model="step2a.internalNote"
            :label="$t('properties.step2.internalNote')"
            :isMultiline="true"
            :maxLimit="999"
            :errorMessage="$t('general.errors.limitDesc').replace('%length%', step2a.internalNote.length).replace('%maxLimit%', 999)"
            placeholder=""
        />
      </div>

      <div class="flex justify-around space-x-10 mt-6 ">
        <div class="w-full invisible"/>
        <div class="flex justify-end space-x-4 mt-6 mr-6 pb-20">
          <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
          <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import InputText from "../../inputs/InputText";
import InputSelect from "../../inputs/InputSelect";
import RadioButton from 'primevue/radiobutton';

export default {
  name: "StepDetails",
  components: {InputSelect, InputText, RadioButton},
  props: {
    stepIndex: {
      type: Number,
      default: 3
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      popoverTexts_exemption_present: [
        this.$t('properties.step2a.exemption_present_tooltip'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts: [
        this.$t('properties.step3.tooltip.tooltip_client_text1'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_economic_entity_type: [
        this.$t('properties.step2a.tooltip.economic_entity_type_1'),
        this.$t('properties.step2a.tooltip.economic_entity_type_2'),
        this.$t('properties.step2a.tooltip.economic_entity_type_3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],

      popoverLink: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
      popoverLink_exemption_present: "https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/",
      popoverLink_economic_entity_type: "https://hilfe.grundsteuer-digital.de/faq/art-der-wirtschaftlichen-einheit/",
      step2a: {
        reference: '',
        name: '',
        economicEntityType: '',
        exemption: "0",
        extraDetails: '',
        internalNote: '',
        complexity: '',
      },
      errors: {
        name: {
          invalid: false
        },
        reference: {
          invalid: false
        },
        economicEntityType: {
          invalid: false
        }
      },
      lastStep: false,
      step3aShow: true,

      economic_entity_type_options: this.$t('properties.step2a.economic_entity_types.default'),
    }
  },
  computed: {
    ...mapGetters("property", ["getStepAddress"]),
  },
  watch: {
    getStepAddress: {
      handler(){
        if (this.getStepAddress.street !== '' && this.getStepAddress.houseNumber !== '' && this.getStepAddress.zip !== '' && this.getStepAddress.city !== '') {
          this.step2a.name = this.getStepAddress.street + ' ' + this.getStepAddress.houseNumber + ', ' + this.getStepAddress.zip + ' ' + this.getStepAddress.city;
        } else {
          this.step2a.name = '';
        }

        if (this.getStepAddress.federalStateUid === '1') {
          this.economic_entity_type_options = this.$t('properties.step2a.economic_entity_types.baden-württemberg');
        } else {
          this.economic_entity_type_options = this.$t('properties.step2a.economic_entity_types.default');
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations("property", ["saveStepDetails"]),
    submitNext() {
      this.errors.name.invalid = false;
      this.errors.economicEntityType.invalid = false;

      if (this.step2a.name.trim() === '') {
        this.errors.name.invalid = true;
        return false;
      }

      if (this.step2a.economicEntityType === '' || this.step2a.economicEntityType === '0') {
        this.errors.economicEntityType.invalid = true;
        return false;
      }

      if (this.step2a.reference.length > 30) {
        this.errors.reference.invalid = true;
        return;
      } else {
        this.errors.reference.invalid = false;
      }

      if (this.step2a.extraDetails.length > 999 || this.step2a.internalNote.length > 999) {
        return;
      }

      this.saveStepDetails(this.step2a);
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">

.import-label {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  text-decoration: underline;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.info-msg {
  background-color: #f2f2f2;
  display: flex;
  align-items: flex-start;
}

.add-more-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.delete-icon-add-property-step2a {
  cursor: pointer;
  height: 25px;
}

.title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;

}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
  margin-bottom: 15px;
}

.sub-heading {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.plus-icon-add-property-step2a {
  width: 29px;
  height: 29px;
  box-sizing: border-box;

}

.btext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: right;
  line-height: 20px;
  font-size: 15px;
}

.max-width-half {
  max-width: calc(50% - (20px));
}

.labelBoris {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 14px;
}

a:link {
  color: theme('colors.muted_black') !important;
  text-decoration: underline;
}

.tooltip-content {
  background-color: $primary;
  border-radius: 5px;
  padding: 10px;
  max-width: 350px;
  display: inline-table;
  text-align: left;
  color: white;
}


.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.popover-text {
  font-size: 13px;
  font-family: 'Segoe UI', sans-serif;
  color: #ffffff;
  text-align: left;
}

.lbl-exemption {
  margin-bottom: 7px;
}

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
</style>
