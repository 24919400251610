<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('sidebar.deadline')"/>
    <ModalDialog v-show="deadlineAddEditDialog" :headerText="$t('deadline.edit_title', {'documentType':deadline.document_type_label})"
                 @close="deadlineAddEditDialog = false">
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="flex">
            <img @click="openDocumentViewer" :src="deadline.document_icon" class="icon flex flex-col mr-2 cursor-pointer">
            <label @click="openDocumentViewer" class="text-left cursor-pointer">{{ deadline.document_name }}</label>
          </div>
          <InputDate
              class="w-full"
              v-model="deadline.document_date"
              :label="$t('deadline.attrs.document_date')"
              appendTo="body"
              :showHelpIcon="true"
              :tooltip="$t('deadline.attrs_help.document_date')"
              @date-selected="calculateDeadlineDate($event, -1, 'document_date')"
          />
          <InputDate
              class="w-full"
              v-model="deadline.announcement_date"
              :label="$t('deadline.attrs.announcement_date')"
              appendTo="body"
              :showHelpIconPopover="true"
              :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
              @date-selected="calculateDeadlineDate($event, -1, 'announcement_date')"
          />
          <InputDate
              class="w-full"
              v-model="deadline.deadline_reminder"
              :label="$t('deadline.attrs.deadline_reminder')"
              appendTo="body"
              :showHelpIcon="true"
              :tooltip="$t('deadline.attrs_help.deadline_reminder')"
          />
          <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                     v-model="deadline.deadline"
                     :disabled="true"
                     :showHelpIconPopover="true"
                     :popoverTexts="[$t('deadline.attrs_help.deadline')]"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <div class="flex space-x-4 mt-4 mb-4 ">
            <Button class="w-44" @click="deadlineAddEditDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button class="w-44" :text="$t('buttons.save')" @click="saveDeadline"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog v-show="documentViewer.show" @close="closeDocumentViewerDialog"
                 :headerText="documentViewer.documentName">
      <template v-slot:body>
        <div class="flex flex-col w-full">
          <vue-pdf-embed :source="sourceObjectForPdfjs(documentViewer.fileSource)" />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
          <Button :secondary="true" @click="downloadDocument"  :text="$t('documents.download')"></Button>
          <Button :secondary="true" :text="$t('buttons.cancel')" @click="closeDocumentViewerDialog"/>
        </div>
      </template>
    </ModalDialog>
    <AppTabs :categories="tabs"
             :selected-tab="activeTab"
             :no-footer="true"
             class="deadline-tab-container"
    >
      <template #tab-pane-open-deadline>
        <AppGrid :grid-id="openDeadlineGridId"
                 :show-select-col="false"
                 :show-search="true"
                 dataEndpoint="deadline/list"
                 :columns="getColumns"
                 default-sort-attr="deadline"
                 :default-sort-dir="1"
                 :selectable="true"
                 selection-data-key="id"
                 :menus="menus"
                 :auto-load="false"
                 :show-create-record="false"
                 :pagination="true"
                 :server-params="{'filters': {'state': 'open'}}"
                 @menu-clicked="handleMenuClick"
                 @col-click="handleColClick"
                 @on-data-loading="openDeadlineGridDataLoaded=false"
                 @on-data-loaded="onOpenDeadlineGridDataLoaded"
                 @on-item-selected="statusChanged($event, 'open-deadline')"
        />
      </template>
      <template #tab-pane-archives>
        <AppGrid :grid-id="archiveDeadlineGridId"
                 dataEndpoint="deadline/list"
                 :show-select-col="false"
                 :show-search="true"
                 :columns="getColumns"
                 :auto-load="false"
                 edit-mode="cell"
                 default-sort-attr="deadline"
                 :default-sort-dir="1"
                 :selectable="true"
                 selection-data-key="id"
                 :menus="menus"
                 :show-create-record="false"
                 :pagination="true"
                 :server-params="{'filters': {'state': 'archived'}}"
                 @menu-clicked="handleMenuClick"
                 @col-click="handleColClick"
                 @on-data-loading="archiveDeadlineGridDataLoaded=false"
                 @on-data-loaded="onArchiveDeadlineGridDataLoaded"
                 @on-item-selected="statusChanged($event, 'archive-deadline')"
        />
      </template>
    </AppTabs>
  </div>
</template>

<script>
import AppGrid from "@/components/AppGrid";
import AppTabs from "@/components/AppTabs";
import PageHeader from "@/components/PageHeader";
import {mapGetters, mapMutations} from "vuex";
import _clone from "lodash/clone";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {DocumentService} from "@/services/document.service";
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";

export default {
  name: "list",
  components: {AppGrid, PageHeader, AppTabs, VuePdfEmbed},
  mixins: [pdfjsCVEMixin],
  data() {
    return {
      documentService: new DocumentService(),
      openDeadlineGridId: 'open-deadline',
      archiveDeadlineGridId: 'archived-deadline',
      tabs: [{
        name: this.$t('deadline.tabs.open_deadline'),
        slug: 'open-deadline'
      }, {
        name: this.$t('deadline.tabs.archives'),
        slug: 'archives'
      }],
      activeTab: 'open-deadline',
      columns: [
        {
          field: 'deadline',
          header: this.$t('deadline.cols.deadline'),
          isChecked: true,
          clickable: true,
          elType: 'date_range'
        }, {
          field: 'documentType',
          header: this.$t('deadline.cols.document_type'),
          isChecked: true,
          clickable: true,
          elType: 'select',
          filterOptions: this.$t('deadline.document_types')
        }, {
          field: 'declarationUid',
          header: this.$t('deadline.cols.declaration_uid'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        }, {
          field: 'clientName',
          header: this.$t('deadline.cols.client_name'),
          filterLbl: this.$t('deadline.cols.client_name') + ' ' + this.$t('general.contains'),
          isChecked: true,
          width: 200,
          clickable: true,
          elType: 'text'
        }, {
          field: 'propertyName',
          header: this.$t('deadline.cols.property'),
          filterLbl: this.$t('deadline.cols.property') + ' ' + this.$t('general.contains'),
          isChecked: true,
          clickable: true,
          elType: 'text'
        }, {
          field: 'declarationStatus',
          header: this.$t('deadline.cols.statusDeclaration'),
          isChecked: true,
          clickable: true,
          elType: 'select',
          filterOptions: []
        }, {
          field: 'statusTxt',
          header: this.$t('deadline.cols.status'),
          isChecked: true,
          colElType: 'select',
          dataSource: this.$t('deadline.status'),
          model: 'status',
          elType: 'select',
          filterOptions: this.$t('deadline.status')
        }
      ],
      menus: [
        {
          id: 'deadline.edit',
          "icon": this.getAssetPath('edit_green.svg'),
          "icon_disabled": this.getAssetPath('edit_gray.svg'),
          "title": this.$t('deadline.menus.edit'),
          "slug": "edit",
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "deadline.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('deadline.menus.delete'),
          "action_endpoint": 'deadline/delete',
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      deadlineAddEditDialog: false,
      deadline: {
        "declarationId": 0,
        "documentId": 0,
        "deadlineId": 0,
        "document_icon": "",
        "document_name": "",
        "document_type_label": "",
        "document_date": "",
        "announcement_date": "",
        "deadline_reminder": "",
        "deadline": "",
        "federal_state": 0
      },
      openDeadlineGridDataLoaded: false,
      archiveDeadlineGridDataLoaded: false,
      documentViewer: {
        show: false,
        documentName: '',
        fileSource: ''
      }
    }
  },
  mounted() {
    // Fetch list of holidays from api and cache
    this.$api.get_state_holidays();
  },
  computed: {
    getColumns() {
      let columns = [...this.columns];

      let stages = this.$isBusiness ? this.$t('declarations.business_stages') : this.$t('declarations.stages_board');
      let declarationStatusOpts = [];
      for (let stage in stages) {
        declarationStatusOpts.push({
          name: stages[stage],
          code: stage
        });
      }

      columns = columns.map((o) => {
        if (o.field === 'declarationStatus') {
          o.filterOptions = declarationStatusOpts;
        }

        return o;
      });

      return columns;
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentTheme"]),
    ...mapMutations("grid", ["triggerReload"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    handleMenuClick(args) {
      if(args.menuId && args.menuId === 'deadline.edit') {
        this.editDeadline(args.data[0].id);
      }
    },
    handleColClick(e) {
      if(e.field === 'declarationUid') {
        this.$router.push('/declaration/edit?uid=' + e.row.declarationId);
      } else if(e.field === 'clientName') {
        this.$router.push('/client/edit?uid=' + e.row.clientId);
      } else if(e.field === 'propertyName') {
        this.$router.push('/property/edit?uid=' + e.row.propertyId);
      } else if(e.row.id) {
        this.editDeadline(e.row.id);
      }
    },
    onArchiveDeadlineGridDataLoaded() {
      this.$nextTick(() => this.archiveDeadlineGridDataLoaded = true)
    },
    onOpenDeadlineGridDataLoaded() {
      this.$nextTick(() => this.openDeadlineGridDataLoaded = true)
    },
    statusChanged(e, grid) {
      if((grid === 'open-deadline' && this.openDeadlineGridDataLoaded) || (grid === 'archive-deadline' && this.archiveDeadlineGridDataLoaded)) {
        if(e.row) {
          let postData = {
            deadlineId: e.row.id,
            status: e.row.status
          }
          this.$api.trigger('deadline/save', postData, true).then((response) => {
            if (response.data.success) {
              this.triggerReload(this.openDeadlineGridId);
              this.triggerReload(this.archiveDeadlineGridId);
            } else {
              this.$api.showToast(response.data.message, "error");
            }
          });
        }
      }
    },
    editDeadline(deadlineId) {
      this.$api.trigger('deadline/get', {
        uid: deadlineId
      }, true).then((response) => {
        if(response.data.success) {
          let documentIcon = '';
          switch (response.data.deadlineData.fileExt) {
            case "xls,xlsx":
              documentIcon =  require('@/assets/document_xls.svg');
              break;
            case "doc,docx":
              documentIcon =  require('@/assets/document_doc.svg');
              break;
            case "pdf":
              documentIcon = require('@/assets/document_pdf.svg');
              break;
            default:
              documentIcon = require('@/assets/document_generic.svg')
          }
          this.deadline = {
            "declarationId": response.data.deadlineData.declarationId,
            "documentId": response.data.deadlineData.documentUid,
            "deadlineId": response.data.deadlineData.id,
            "document": '',
            "document_type": "",
            "document_icon": documentIcon,
            "document_name": response.data.deadlineData.filename,
            "document_type_label": response.data.deadlineData.documentType,
            "document_date": response.data.deadlineData.documentDate,
            "announcement_date": response.data.deadlineData.announcementDate,
            "deadline_reminder": response.data.deadlineData.deadlineReminder,
            "deadline": new Date(response.data.deadlineData.deadline),
            "federal_state": response.data.deadlineData.federalStateUid
          }
          this.deadlineAddEditDialog = true;
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    calculateDeadlineDate(val, index, elName) {
      let date = null

      if (val !== '') {
        date = new Date(val);
      }

      if (date instanceof Date) {
        if (elName === 'document_date') {
          this.deadline.announcement_date = this.calculateAnnouncementDate(date, this.deadline.federal_state, 3, true);
          this.deadline.deadline = this.calculateAnnouncementDate(_clone(this.deadline.announcement_date), this.deadline.federal_state, '1M', true);
          this.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline.deadline), this.deadline.federal_state, -7, false);
        } else if (elName === 'announcement_date') {
          this.deadline.deadline = this.calculateAnnouncementDate(date, this.deadline.federal_state, '1M', true);
          this.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline.deadline), this.deadline.federal_state, -7, false);
        }
      }
    },
    saveDeadline() {
      if((this.deadline.document && this.deadline.document_type) || parseInt(this.deadline.deadlineId) > 0) {
        this.$api.trigger('deadline/save', this.deadline, true).then((response) => {
          if (response.data.success) {
            this.deadlineAddEditDialog = false;
            if(this.activeTab === 'open-deadline') {
              this.triggerReload(this.openDeadlineGridId);
            } else {
              this.triggerReload(this.archiveDeadlineGridId);
            }
          } else {
            this.$api.showToast(response.data.message, "error");
          }
        });
      }
    },
    closeDocumentViewerDialog() {
      this.documentViewer = {
        show: false,
        documentName: '',
        fileSource: ''
      }
    },
    downloadDocument() {
      this.documentService.setDefaultParams({
        "parentType": 'declaration',
        "parentId": this.deadline.declarationId
      });
      this.documentService.download(this.deadline.documentId)
    },
    openDocumentViewer() {
      this.documentService.setDefaultParams({
        "parentType": 'declaration',
        "parentId": this.deadline.declarationId
      });

      // fetch document
      this.documentService.get(this.deadline.documentId, true).then((doc) => {
        if (doc.mime_type) {
          this.documentViewer.documentName = doc.filename;
          this.documentViewer.fileSource = "data:" + doc.mime_type + ";base64," + doc.content;
          this.documentViewer.show = true;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.deadline-tab-container ::v-deep .tab-content {
  padding-top: 10px;
}

</style>
