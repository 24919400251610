const state = {
  currentStep: 1,
  data: {},
  notificationId: 0
};

const getters = {
  getCurrentStep: (state) => state.currentStep,
  getSteps: (state) => state.data,
  getNotificationId: (state) => state.notificationId
}

const mutations = {
  init: (state) => {
    state.currentStep = 1;
    state.data = {};
    state.notificationId = 0;
  },
  setCurrentStep: (state, step) => {
    state.currentStep = step
  },
  saveSteps: (state, data) => {
    state.data = {...state.data, ...data};
  },
  setNotificationId: (state, notificationId) => {
    state.notificationId = notificationId;
  }
};

const actions = {};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
