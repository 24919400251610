<template>
  <div class="h-full bg-white pl-7 w-full " v-show="status !== 'none'">
    <PageHeader :title="getPageTitle"/>
    <div class="flex flex-col justify-center items-center">
      <div class="w-full">
        <div class="mt-10 mr-6 text-left" v-if="status === 'inProcess'">
          <div class="box flex justify-start items-center mt-3">
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <label class="text-info ml-2">
              {{ $t('declarations.mass_update.process_started_info') }}
            </label>
          </div>
          <div class="flex justify-around space-x-1 mt-5">
            <div class="w-full text-left flex">
              <label class="w-28 text_bold">{{ $t('declarations.mass_update.steps.process_type') }}:</label>
              <label class="ml-5">{{ processType }}</label>
            </div>
          </div>
          <div class="flex justify-around space-x-1 mt-1">
            <div class="w-full text-left flex">
              <label class="w-28 text_bold">{{ $t('declarations.mass_update.data_set_count') }}:</label>
              <label class="ml-5">{{ dataSetCount }}</label>
            </div>
          </div>

          <div class="flex justify-end space-x-6 mt-20 mr-3">
            <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="backToDashboard"/>
            <Button class="w-40" :text="$t('buttons.refresh')" @click="fetchNotification"/>
          </div>
        </div>
        <div class="mt-10 mr-6 text-left" v-else>
          <div class="flex justify-around space-x-1 mt-5">
            <div class="w-full text-left flex">
              <label class="w-28 text_bold">{{ $t('declarations.mass_update.steps.process_type') }}:</label>
              <label class="ml-5">{{ processType }}</label>
              <div class="text-left flex-col" v-if="notification.processType === 'document_creation' && notification.hasDocument">
                <div class="flex justify-end space-x-6 mr-3 ml-20">
                <Button class="w-80" @click="downloadZip" :text="$t('declarations.mass_update.download_zip')"></Button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-around space-x-1 mt-1">
            <div class="w-full text-left flex">
              <label class="w-28 text_bold">{{ $t('declarations.mass_update.data_set_count') }}:</label>
              <label class="ml-5">{{ dataSetCount }}</label>
            </div>
          </div>
          <div class="flex justify-start space-x-1 mt-2" v-if="notification.processType === 'document_creation' && !notification.hasDocument">
            <label>{{ $t('declarations.mass_update.document_expired') }}</label>
          </div>
          <div class="flex justify-around space-x-1 mt-5" v-if="renderGrid">
            <DataGrid grid-id="grid-mass-update-result"
                      :local-data="massUpdateResult"
                      :columns="getColumns"
                      :pagination="false"
                      selection-data-key="taskId"
                      :show-select-col="false"
                      :use-local-data="true"
                      :expandable="rowsExpandable"
                      expansionFormat="keyValuePair"
                      expandableDataKey="documentStatus"
                      :resizable-columns="false"
                      :reorderable-columns="false"
                      :show-create-record="false"
                      :state-ful="false"
                      @col-click="handleColClick"
            />
          </div>
          <div class="flex w-full mr-3 mt-10 footer items-center">
            <div class="flex flex-col justify-start w-full invisible" />
            <div class="flex flex-col justify-end">
              <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="backToDashboard"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {UserService} from "@/services/user.service";
import DataGrid from "@/components/DataGrid";
import {mapMutations} from "vuex";

export default {
  name: "BulkAddStatus",
  components: {DataGrid},
  data() {
    return {
      userService: new UserService(),
      status: "none",
      notification: {},
      massUpdateResult: [],
      columnsNewProcess: [
        {
          field: 'propertyTitle',
          header: this.$t('declarations.mass_update.property'),
          isChecked: true,
          sortable: false,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell',
          clickable: true
        },
        {
          field: 'declarationNumber',
          header: this.$t('declarations.add_declaration.steps.step2.declaration_number'),
          isChecked: true,
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.tooltip.type'),
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/"
        },
        {
          field: 'declarationTypeLabel',
          header: this.$t('declarations.add_declaration.steps.step2.type'),
          isChecked: true,
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.tooltip.declaration_number'),
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/grund-der-feststellung/"
        },
        {
          field: 'recipientTypeLabel',
          header: this.$t('declarations.add_declaration.steps.step3.title'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'participation',
          header: this.$t('declarations.add_declaration.steps.step3.participation'),
          isChecked: true,
          colElType: 'checkbox',
          disabledEl: 'true',
          enableDisabledChecked: 'true',
          sortable: false,
        },
        {
          field: 'status',
          header: this.$t('declarations.mass_update.declaration_task_status'),
          isChecked: true,
          sortable: false
        }
      ],
      columnsRequestDataProcess: [
        {
          field: 'declarationId',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          sortable: false,
          clickable: true,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        },
        {
          field: 'clientName',
          header: this.$t('declarations.grid.client'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'name',
          header: this.$t('declarations.mass_update.declaration'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'status',
          header: this.$t('declarations.mass_update.declaration_task_status'),
          isChecked: true,
          sortable: false
        }
      ],
      columnsElsterSubmissionProcess: [
        {
          field: 'declarationId',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          sortable: false,
          clickable: true,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        },
        {
          field: 'name',
          header: this.$t('declarations.mass_update.declaration'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'status',
          header: this.$t('declarations.mass_update.declaration_task_status'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'errorMessage',
          header: this.$t('clients.attrs.status'),
          isChecked: true,
          sortable: false
        }
      ],
      columnsMassAppealProcess: [
        {
          field: 'declarationId',
          header: this.$t('components.properties_table_col.col1'),
          isChecked: true,
          sortable: false,
          clickable: true,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        },
        {
          field: 'name',
          header: this.$t('declarations.mass_update.declaration'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'status',
          header: this.$t('declarations.mass_update.declaration_task_status'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'errorMessage',
          header: this.$t('declarations.mass_update.declaration_task_error_message'),
          isChecked: true,
          sortable: false
        }
      ],
      columnsOtherProcess: [
        {
          field: 'declarationId',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          sortable: false,
          clickable: true,
          headerClass: 'property-type-cell',
          bodyClass: 'property-type-cell'
        },
        {
          field: 'name',
          header: this.$t('declarations.grid.address'),
          isChecked: true,
          sortable: false
        },
        {
          field: 'status',
          header: this.$t('declarations.mass_update.declaration_task_status'),
          isChecked: true,
          sortable: false
        }
      ],
      renderGrid: false
    }
  },
  computed: {
    getColumns() {
      if (this.notification.extra_details && this.notification.extra_details.processType) {
        if (this.notification.extra_details.processType === 'new_declaration') {
          return this.columnsNewProcess;
        } else if (this.notification.extra_details.processType === 'request_data') {
          return this.columnsRequestDataProcess;
        } else if (this.notification.extra_details.processType === 'elster_submission') {
          return this.columnsElsterSubmissionProcess;
        } else if (this.notification.extra_details.processType === 'mass_appeal') {
          return this.columnsMassAppealProcess;
        } else {
          return this.columnsOtherProcess;
        }
      }

      return [];
    },
    getPageTitle() {
      return this.notification.description ? this.notification.description : '';
    },
    processType() {
      return this.notification.extra_details && this.notification.extra_details.processType ? this.$t('declarations.mass_update.process')[this.notification.extra_details.processType] : '';
    },
    dataSetCount() {
      return this.notification.extra_details && this.notification.extra_details.totalRecords ? this.notification.extra_details.totalRecords : 0;
    },
    serverParams() {
      if (this.notification.extra_details && this.notification.extra_details.jobId) {
        return {
          "jobId": this.notification.extra_details.jobId
        }
      }
      return {}
    },
    rowsExpandable() {
      return this.notification.extra_details
        && ['document_creation', 'mass_appeal'].includes(this.notification.extra_details.processType)
        && this.notification.gridRowExpandable;
    }
  },
  watch: {
    '$route'(to) {
      if (typeof this.notification.prim_uid === 'undefined' || (this.notification.prim_uid && this.notification.prim_uid !== to.query.uid)) {
        this.fetchNotification();
      }
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    backToDashboard() {
      this.$router.push('/home');
    },
    fetchNotification() {
      this.renderGrid = false;
      this.status = 'none';
      this.userService.getNotification(this.$route.query.uid).then((response) => {
        if (response.data.success && response.data.notification && response.data.notification.prim_uid) {
          if (response.data.notification.extra_details) {
            response.data.notification.extra_details = JSON.parse(response.data.notification.extra_details);
          }
          this.notification = response.data.notification;
          this.status = this.notification.notification_type === "DECLARATION_JOB_COMPLETED" ? "completed" : "inProcess";
          if(this.notification.declarationTaskStatus) {
              this.renderGrid = true;
              this.massUpdateResult = this.$api.parse_object_data(this.notification.declarationTaskStatus);
              this.triggerReload('grid-mass-update-result')
          }
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      })
    },
    handleColClick(args) {
      if (args.row.declarationUid) {
        if (
          args.row.errorKey
          && args.row.errorKey === 'elsterValidationError'
          && !['mass_appeal'].includes(this.notification.extra_details.processType)
        ) {
          this.$router.push({
            name: "Declaration",
            params: {
              'comp': 'edit',
              'showSavedElsterResponse': true
            },
            query: {
              'uid': args.row.declarationUid
            }
          });
        } else {
          this.$router.push('/declaration/edit?uid=' + args.row.declarationUid);
        }
      }
    },
    downloadZip() {
      this.$api.trigger('declaration/download_mass_job_document', {
        "jobId": this.notification.jobId
      }, true).then((response) => {
        if(response.data.success && response.data.token) {
          const downloadLink = document.createElement("a");
          downloadLink.href = '/download?token=' + response.data.token;
          downloadLink.click();
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding-bottom: 10px;
  padding-top: 10px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
</style>
