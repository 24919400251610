import {ApiService} from "./api.service";
import Vue from "vue";

export class ClientService extends ApiService {

  constructor() {
    super();
  }

  // List all clients
  listAll(params) {
    if (this.$store.getters["client/getClientList"].length > 0 && typeof (params) === 'undefined') {
      return new Promise((resolve) => {
        resolve(this.$store.getters["client/getClientList"]);
      });
    } else {
      if (typeof (params) !== 'undefined') {
        if (this.requestToken) {
          this.requestToken.cancel();
        }

        this.requestToken = this.getCancelSource();
      }

      return this.trigger('client/list_all', {
        'sortField': 'name',
        'sortOrder': 1,
        'rows': params && params.rows ? params.rows : 100,
        'filters': {
          'txt_filter': params && params.txtFilter ? params.txtFilter : '',
          'uid': params && params.uid ? params.uid : 0,
          'uids': params && params.uids ? params.uids : [],
          'dropdownSearch': params && params.dropdownSearch ? params.dropdownSearch : 0
        }
      }).then((response) => {
        if (response) {
          if (response.data.success && parseInt(response.data.total_records) > 0) {
            let ret = {
              "totalRecordCount": response.data.total_records,
              "list": this._parse_client_response(response)
            };
            if (typeof (params) === 'undefined') {
              this.$store.commit('client/setClientList', ret);
              return this.$store.getters["client/getClientList"];
            } else {
              return ret;
            }
          } else {
            return {
              "totalRecordCount": 0,
              "list": []
            };
          }
        } else {
          return [];
        }
      })
    }
  }

  listUserClient(user_uid, params) {
    if (params) {
      if (this.requestToken) {
        this.requestToken.cancel();
      }

      this.requestToken = this.getCancelSource();
    }
    return this.trigger('client/list_all', {
      'sortField': 'name',
      'sortOrder': 1,
      'rows': params && params.rows ? params.rows : 10,
      'filters': {
        "userId": user_uid,
        'txt_filter': params && params.txtFilter ? params.txtFilter : '',
        'uid': params && params.uid ? params.uid : 0,
        'dropdownSearch': params && params.dropdownSearch ? params.dropdownSearch : 0,
        'excludeInvitedClients': params && params.excludeInvitedClients ? params.excludeInvitedClients : 0
      }
    }).then((response) => {
      if (response) {
        if (response.data.success && parseInt(response.data.total_records) > 0) {
          return {
            "totalRecordCount": response.data.total_records,
            "list": this._parse_client_response(response)
          };
        } else {
          return {
            "totalRecordCount": 0,
            "list": []
          };
        }
      } else {
        return [];
      }
    })
  }

  // list my clients
  listMyClients(params) {
    if (this.$store.getters["client/getMyClientList"].length > 0 && typeof (params) === 'undefined') {
      return new Promise((resolve) => {
        resolve(this.$store.getters["client/getMyClientList"]);
      });
    } else {
      return this.listUserClient('current', params).then((clients) => {
        if (clients && clients.list) {
          if (clients.list.length > 0) {
            if (typeof (params) === 'undefined') {
              this.$store.commit('client/setMyClientList', clients)
              return this.$store.getters["client/getMyClientList"];
            } else {
              return clients;
            }
          } else {
            return {
              "totalRecordCount": 0,
              "list": []
            };
          }
        } else {
          return [];
        }
      })
    }
  }

  _parse_client_response(response) {
    return this.parse_object_data(response.data.records).map(o => {
      if (o.client_type === 'private') {
        o.client_type_icon = 'icon_user_green.svg';
      } else {
        o.client_type_icon = 'properties_green.svg';
      }
      return o;
    })
  }

  // Save client
  save(data) {
    data['client_portal_access'] = data['client_portal_access'] ? 1 : 0;
    data['use_datev_login'] = data['use_datev_login'] ? 1 : 0;
    data['invite'] = data['invite'] ? 1 : 0;
    return this.trigger('client/save', data, true).then(response => {
      if (response.data.success) {
        this.$store.commit('client/resetClientList')
        this.$store.commit("user/setCurrentUserStatsDataChanged", true);
        return response.data;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }

  // Change selected attribute
  update(data) {
    return this.trigger('client/update', data, true).then(response => {
      if (response.data.success) {
        this.$store.commit('client/setClientList', [])
        return true;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  getNextId() {
    return this.trigger('client/get_next_client_id', null).then((response) => {
      if (response.data.success) {
        return response.data.clientId;
      } else {
        return '';
      }
    });
  }

  get(uid, show_loader) {
    if(typeof show_loader === 'undefined') {
      show_loader = true;
    }
    return this.trigger('client/get', {
      uid: uid
    }, show_loader).then((response) => {
      if (response.data.success) {
        response.data.clientData.client_portal_access = parseInt(response.data.clientData.client_portal_access) === 1;
        response.data.clientData.use_datev_login = parseInt(response.data.clientData.use_datev_login) === 1;
        response.data.clientData.prim_uid = parseInt(response.data.clientData.prim_uid);
        if (response.data.clientData['users']) {
          response.data.clientData['users'] = this.parse_object_data(response.data.clientData['users']);
        }
        return response.data.clientData;
      } else {
        this.$toast.error(response.data.message);
        return [];

      }
    })
  }

  import(branch, branchIncluded, s3Path) {
    return this.trigger('client/import', {
      "branch_uid": branch,
      "branchIncluded": branchIncluded ? 1 : 0,
      "s3Path": s3Path
    }).then((response) => {
      if(response.data.newNotificationCount) {
        this.$store.commit('user/setNewNotificationCount', response.data.newNotificationCount);
      }
      return response;
    })
  }

  // Export client
  export(columns, filters, selectedRecords) {
    let column_data = [];
    columns !== null && columns.map(column => {
      column_data.push({columnOrder: column.columnOrder, field: column.field});
    })
    return this.trigger('client/export', {
      export_filters: filters,
      columns: column_data,
      selectedRecords
    }, true, '', 'blob').then((response) => {
      if (response.headers['download_later']) {
        this.showToast(this.$i18n.t('general.export_in_background'))
      } else {
        this.trigger_download(response);
      }
    });
  }

  // LIst client users
  listClientUsers(client_uid, loader) {
    return this.trigger('client/get_users', {
      "client_uid": client_uid
    }, loader).then((response) => {
      if (response.data.success && parseInt(response.data.total_records) > 0) {
        return response.data.records;
      } else {
        return [];
      }
    })
  }

  // setup new client user
  saveClientUser(data, loader) {
    return this.trigger('client/save_user', data, loader).then((response) => {
      if (response.data.success) {
        return response.data.client_user;
      } else if(response.data.error_key === 'clientAlreadyHavePortalAccess') {
        return {
          "error_key": "clientAlreadyHavePortalAccess"
        };
      } else {
        this.$toast.error(response.data.message)
        return {};
      }
    });
  }

  // Enable/Disable MFA of client user
  toggleMFA(data, loader) {
    return this.trigger('client/toggle_mfa', data, loader).then((response) => {
      if (response.data.success) {
        return true;
      } else {
        return false;
      }
    });
  }

  // Send invitation email to client user
  sendUserInvite(data) {
    return this.trigger('client/invite_user', data, true).then((response) => {
      if (response.data.success) {
        this.showToast(response.data.message)
        return true;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }

  // Send query clients to change property email to client user
  sendQueryClientsToChangeProperty(data) {
    return this.trigger('client/query_clients_to_change_property', data, true).then((response) => {
      if (response.data.success) {
        if (typeof response.data.emailPreview !== 'undefined') {
          return response.data.emailPreview;
        }

        this.showToast(response.data.message);
        return true;
      }

      this.showToast(response.data.message, 'error');
      return false;
    });
  }

  // Get query clients to change property email preview
  getQueryClientsToChangeProperty(data) {
    data.preview = 1;
    return this.trigger('client/query_clients_to_change_property', data, true).then((response) => {
      if (response.data.success) {
        return response;
      }

      this.showToast(response.data.message, 'error');
      return [];
    });
  }

  // Get Invite email preview
  getUserInviteEmailPreview(data) {
    data.preview = 1;
    return this.trigger('client/invite_user', data, true).then((response) => {
      if (response.data.success) {
        return response;
      } else {
        this.showToast(response.data.message, "error")
        return [];
      }
    });
  }

  // Delete client user
  delete_user(client_uid, data, loader) {
    return this.trigger('client/delete_user', {
      client_uid: client_uid,
      records: data
    }, loader).then((response) => {
      if (response.data.success) {
        return true;
      } else {
        this.$toast.error(response.data.message)
        return false;
      }
    });
  }

  // Reset password of specific client user
  reset_pwd(client_uid, user_uid) {
    return this.trigger('client/reset_pwd', {
      client_uid: client_uid,
      user_uid: user_uid
    }, true).then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }

  csvUpload(files) {
    if (files.length > 0) {
      Vue.prototype.$loading.show();
      let promises = [];
      for (let file of files) {
        let formData = new FormData();
        formData.append('file', file);
        promises.push(this.trigger('client/uploadFile', formData, false, 'multipart/form-data'));
      }

      return Promise.all(promises).then((responses) => {
        Vue.prototype.$loading.hide();
        let rows = [];
        for (let i = 0; i < responses.length; i++) {
          let fileRows = [];

          if (typeof (responses[i].data.rows) === 'object') {
            let arrRows = Object.values(responses[i].data.rows);

            for (let j = 0; j < arrRows.length; j++) {
              let arrRow = Object.values(arrRows[j]);
              fileRows.push(arrRow);
            }
          }

          rows.push(fileRows);
        }

        return {
          'success': !!responses[0].data.success,
          'rows': rows,
        }
      });
    } else {
      return {
        'success': false,
      };
    }
  }

  // import Datev
  async fetch_datev_clients(params, offset, show_loader) {
    let data = {
      offset: offset
    };
    if (params) {
      data = {...data, ...params}
    }
    if (this.$store.getters['datev_import/lookupLocalConnection']) {
      this.$store.commit("datev_import/setDatevLocalParams", await this.getDatevLocalParams());
    }
    if (this.$store.getters['datev_import/getDatevLocalParams']) {
      data = {...data, ...this.$store.getters['datev_import/getDatevLocalParams']};
    }

    return this.trigger('client/fetch_datev_clients', data, show_loader);
  }

  start_datev_client_import(data) {
    return this.trigger('client/datev_import', data).then((response) => {
      if(response.data.newNotificationCount) {
        this.$store.commit('user/setNewNotificationCount', response.data.newNotificationCount);
      }
      return response;
    })
  }

  get_saved_datev_ids(importKey) {
    return this.trigger('client/get_datev_ids', {
      importKey: importKey
    }).then((response) => {
      if (response.data.success && response.data.datevIds) {
        return response.data.datevIds;
      } else {
        return [];
      }
    });
  }

  // Get login as token for client user
  get_user_login_token(client_uid, user_uid) {
    return this.trigger('client/get_user_login_token', {
      client_uid: client_uid,
      user_uid: user_uid
    }, true).then((response) => {
      if (!response.data.success) {
        this.showToast(response.data.message, "error")
      }
      return response;
    });
  }

  fetch_addison_clients(offset, show_loader) {
    let data = {
      offset: offset
    };

    return this.trigger('addison/clients', data, show_loader);
  }

  update_from_datev(external_id, external_etag, branch_uid) {
    return this.trigger('client/update_from_datev', {
      externalId: external_id,
      externalEtag: external_etag,
      branchUid: branch_uid
    }, true);
  }

  connect_with_datev(external_id, client_uid) {
    return this.trigger('client/update_from_datev', {
      externalId: external_id,
      clientUid: client_uid,
      connectDatev: 1
    }, true);
  }

  find_datev_client(params) {
    return this.trigger('client/find_datev_clients', params, false);
  }

  // Get Client Person data of given client
  getPersonData(data, loader) {
      return this.trigger('client_person/get_persons', data, loader).then((response) => {
        if (response.data.success) {
          return this.parse_object_data(response.data.list);
        } else {
          return [];
        }
      });
  }

  // Get Modal data of Client Person
  getPersonModalData(data, loader) {
      return this.trigger('client_person/get_person_modal', data, loader).then((response) => {
          if (response.data.success) {
              return response.data;
          } else {
              this.$toast.error(response.data.message)
              return [];
          }
      });
  }

  // Save Modal data of Client Person
  savePersonModalData(data, loader) {
      return this.trigger('client_person/save_person_modal', data, loader).then((response) => {
          if (response.data.success) {
              return response.data;
          } else {
              this.$toast.error(response.data.message)
              return [];
          }
      });
  }
}
