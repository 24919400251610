<template>
  <div class="step_box">
    <div v-show="renderGrid">
      <div class="mr-7 datev_client_import">
        <DataGrid v-if="renderGrid"
                  :grid-id=gridId
                  :local-data="addisonClients"
                  :columns="columns"
                  default-sort-attr="name"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
        />
      </div>

      <div class="flex justify-around space-x-10 mt-2 "/>
      <div class="flex justify-end space-x-4 mt-6 mr-6 mt-10 mb-20">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="goBack" v-if="!isImportRunning"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="importClients" v-if="!isImportRunning"/>
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" :disabled="true" v-if="isImportRunning"/>
        <Button class="w-40" :text="$t('buttons.next')" :disabled="true" v-if="isImportRunning"/>
      </div>
    </div>
  </div>
</template>


<script>
import {ClientService} from "@/services/client.service";
import {mapGetters, mapMutations} from "vuex";
import {BranchService} from "@/services/branch.service";
import DataGrid from "@/components/DataGrid";

export default {
  name: "Step2",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(nv) {
      if (nv) {
        this.init();
        let branchService = new BranchService();
        branchService.listMyBranches().then((list) => {
          let branchList = list.map((o) => {
            return {
              "name": o.name,
              "code": o.uid
            }
          });
          branchList.unshift({
            "name": this.$t('clients.datev_import.do_not_import'),
            "code": "0"
          })
          this.columns = this.columns.map((o) => {
            if (o.field === 'branch') {
              o.dataSource = branchList;
            }

            return o;
          });
        });

        this.fetchAddisonClient();
      }
    }
  },
  data() {
    return {
      service: new ClientService(),
      totalClientFound: 0,
      gridId: "addison-client-import",
      renderGrid: false,
      selectedClients: [],
      branchList: [],
      clientCount: 0,
      isAddisonErrorModalVisible: false,
      isImportRunning: false,
      addisonImportErrorText: '',
      addisonImportErrorDocument: '',
      addisonImportErrorDocumentPresent: false,
      showloader: '',
      columns: [
        {
          field: 'key',
          header: this.$t('clients.addison_import.steps.step2.columns.client_key'),
          isChecked: true
        }, {
          field: 'name',
          header: this.$t('clients.addison_import.steps.step2.columns.client_name'),
          isChecked: true
        }, {
          field: 'branch',
          header: this.$t('clients.addison_import.steps.step2.columns.branch'),
          isChecked: true,
          sortable: false,
          colElType: "select",
          dataSource: [],
          model: 'branch_uid'
        }
      ],
      addisonClients: []
    }
  },
  methods: {
    ...mapMutations("addison_import", ["saveResult", "init"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations("grid", ["triggerReload"]),
    handleAddisonErrorModalClose() {
      this.isAddisonErrorModalVisible = false;
      this.$router.push('/client/list');
    },
    fetchAddisonClient() {
      this.service.fetch_addison_clients(this.clientCount).then((response) => {
            if (response.data.success) {
              if (response.data.oauth_url) {
                window.location.href = response.data.oauth_url;
              } else if (response.data.clients) {
                response.data.clients = this.service.parse_object_data(response.data.clients);
                this.totalClientFound = response.data.total_count;
                this.clientCount += response.data.clients.length;

                if (response.data.clients.length > 0) {
                  response.data.clients = response.data.clients.map((o) => {
                    o.new = this.$t('general.yes');
                    o.prim_uid = 0;
                    o.branch_uid = "0";
                    o.branch = '';

                    return o;
                  });
                }

                this.addisonClients = response.data.clients;

                if (response.data.clients.length > 0) {
                  this.doGrid();
                } else {
                  this.showloader = "display:none";
                  this.isAddisonErrorModalVisible = true;
                  this.addisonImpoertErrorText = this.$t('clients.addison_import.dialog.error_text_no_clients_found');
                }
              }
            } else {
              this.$api.showToast(response.data.message, 'error');
              if (response.data.error_key === 'NoClientsFound') {
                this.showloader = "display:none";
                this.isAddisonErrorModalVisible = true;
                this.addisonImpoertErrorText = this.$t('clients.addison_import.dialog.error_text_no_clients_found');
              }
              //TODO: implement other errors
              else {
                this.$api.showToast(response.data.message, 'error');
                this.$router.push('/client/list');
              }
            }
          }
      )
    },
    doGrid: function () {
      this.renderGrid = true;
    },
    importClients() {
      let importTheseIds = [];

      for (let i = 0; i < this.addisonClients.length; i++) {
        if (this.addisonClients[i].branch_uid !== "0") {
          const importData = {
            'id': this.addisonClients[i].id,
            'branch_uid': this.addisonClients[i].branch_uid,
            'key': this.addisonClients[i].key
          };
          importTheseIds.push(importData);
        }
      }

      if (importTheseIds.length > 0) {
        this.isImportRunning = true;
        this.$api.trigger('/addison/fetch', {'ids': importTheseIds}).then((response) => {
          if (response.data.success === true) {
            this.saveResult({
              "imported": response.data.imported,
              "failed": response.data.failed
            });
            this.$emit('finish');
          } else {
            this.showloader = "display:none";
            this.isAddisonErrorModalVisible = true;
            //TODO: Implement error message
            this.addisonImpoertErrorText = this.$t('clients.addison_import.dialog.error_during_import');
          }
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.loading-text {
  color: #9d9d9d;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.label {

  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}
</style>
