import { render, staticRenderFns } from "./AppealTypeSelectionModal.vue?vue&type=template&id=3d61de7c&scoped=true"
import script from "./AppealTypeSelectionModal.vue?vue&type=script&lang=js"
export * from "./AppealTypeSelectionModal.vue?vue&type=script&lang=js"
import style0 from "./AppealTypeSelectionModal.vue?vue&type=style&index=0&id=3d61de7c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d61de7c",
  null
  
)

export default component.exports