<template>
  <div class=" flex flex-col justify-center items-center ">
    <div class="finish-check-icon-wrapper">
      <img :src="getAssetPath('check_done.svg')"/>
    </div>
    <div class="flex flex-col justify-center items-center mt-6">
      <label class="text">{{ $t('clients.datev_import.steps.finish.success') }}</label>
      <p class="text w-1/2 mt-2">{{ $t('clients.datev_import.steps.finish.info', {"count": selectedClientCount, "mins": estimatedTime}) }}</p>
    </div>
    <Button class=" w-96 mt-10" :text="$t('buttons.next')" @click="submit"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import _ceil from "lodash/ceil"

export default {
  name: "Finish",
  computed: {
    ...mapGetters("datev_import", ["getResult"]),
    selectedClientCount() {
      return this.getResult.selectedClientCount;
    },
    estimatedTime() {
      // With 8 parallel I assume we can easily import 160 records per minute( 60 / 3 secs per record * 8 parallel )
      return _ceil(this.getResult.selectedClientCount / 100) + 1;
    }
  },
  methods: {
    ...mapMutations("datev_import", ["setCurrentStep", "init"]),
    submit() {
      let notificationId = this.getResult.notificationId ? this.getResult.notificationId : 0;
      this.setCurrentStep(1);
      this.init();
      if(notificationId > 0) {
        this.$router.push('/datev/ImportStatus?uid=' + notificationId);
      } else {
        this.$router.push('/client/list')
      }
    }
  }
}
</script>

<style scoped lang="scss">


.label {

  font-family: 'Segoe UI', sans-serif;
  text-align: left;

  color: white;

  &__link {
    color: $primary;
  }

  &__text {
    color: theme('colors.muted_black');
  }
}

.text {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 15px;
}
</style>
