<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('clients.add.title')"
                :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-row justify-between">
      <div class="mr-7 w-full">
        <div class="flex flex-col w-full">
          <label class="text mt-8 mb-8">{{ $t('clients.add.add_options') }} <span style="color:red">*</span></label>
          <div class="flex flex-col space-y-6 mt-1">

            <div class="flex justify-start items-center mr-6 cursor-pointer"
                 v-show="!$isBusiness && this.getCurrentUser().settings.tax_office_software === 'datev'"
                 @click="createOptionSelected('import')">
              <RadioButton v-model="radio" name="radio-button" value="import"
                           @click="createOptionSelected"/>
              <span class="ml-4 textradio cursor-pointer"> {{ $t('clients.menu.datev_import') }}</span>
            </div>

            <div v-show="manualAddClient" class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('manual')">
              <RadioButton name="radio-button" v-model="radio" value="manual"/>
              <span class="ml-4 textradio"> {{ $t('clients.add.manual') }}</span>
            </div>
            <div v-show="csvClientImport" class="flex justify-start items-center mr-6 cursor-pointer" @click="createOptionSelected('csvimport')">
              <RadioButton ref="radio1" name="radio-button" v-model="radio" value="csvimport"/>
              <span class="ml-4 textradio"> {{ $t('clients.add.csv_excel_import') }}</span>
            </div>
            <div class="flex justify-start items-center mr-6 cursor-pointer"
                 v-show="isAddisonEnabled"
                 @click="createOptionSelected('addison')">
              <RadioButton v-model="radio" name="radio-button" value="addison"
                           @click="createOptionSelected"/>
              <span class="ml-4 mr-2 textradio cursor-pointer"> {{ $t('clients.menu.addison_import') }}</span>
              <img class="help" :src="getAssetPath('help_green.svg')"
                   v-tooltip.right="$t('clients.add.addison_import')"/>
            </div>
            <div class="flex justify-start items-center mr-6 cursor-pointer"
                 v-show="isLexwareEnabled"
                 @click="createOptionSelected('lexware')">
              <RadioButton v-model="radio" name="radio-button" value="lexware"
                           @click="createOptionSelected"/>
              <span class="ml-4 mr-2 textradio cursor-pointer"> {{ $t('clients.lexware_import.name') }}</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-1/2 mt-10" v-show="showBranch">
          <InputSelect :label="$t('clients.add.branch')" :isRequired="true" :options="branchList"
                       :isError="isBranchError" :errorMessage="branchError"
                       :selectedValue="branch_uid"
                       :showHelpIconPopover="true"
                       :popoverTexts="[$t('clients.add.tooltip')]"
                       :disabled="disableBranch"
                       :filterable="true" v-model="branch_uid"/>
        </div>
        <div class="flex flex-col w-1/2 mt-10" v-show="showBranch && radio === 'csvimport'">
          <input-check :text="$t('clients.add.csv_excel_includes_branch')" @check="checkedIncludedBranch" />
        </div>
        <div class="flex justify-end space-x-4 mt-6 ">
          <Button class="w-52" :text="$t('buttons.cancel')" @click="cancelAddClient" :secondary="true"/>
          <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
        </div>
      </div>
    </div>
    <div class="w-full pr-7 mt-2"/>
  </div>
</template>

<script>
import {BranchService} from "../../services/branch.service";
import {required} from "vuelidate/lib/validators";
import {mapGetters, mapMutations} from "vuex";
import {ClientService} from "../../services/client.service";
import RadioButton from 'primevue/radiobutton';
import Tooltip from 'primevue/tooltip';
import InputCheck from "@/components/inputs/InputCheck";


export default {
  name: "add",
  components: {InputCheck, RadioButton},
  directives: {'tooltip': Tooltip},
  data() {
    return {
      radio: '',
      radios: [
        {name: this.$t('clients.menu.datev_import'), key: 'A'},
        {name: this.$t('clients.add.csv_excel_import'), key: 'M'},
        {name: this.$t('clients.add.manual'), key: 'S'},
        {name: this.$t('clients.menu.addison_import'), key: 'O'}
      ],
      csvClientImport: true,
      manualAddClient: true,

      branchList: [],
      showBranch: false,
      branchIncludedInCsv: false,
      branch_uid: "0",
      isBranchError: false,
      branchError: '',
      breadcrumbs: [{
        title: this.$t('clients.title'),
        link: '/client/list'
      }, {
        title: this.$t('clients.add.title'),
        link: ''
      }]
    }
  },
  validations: {
    branch_uid: {
      required
    }
  },
  computed: {
    isAddisonEnabled() {
      return this.getCurrentUser().settings.tax_office_software === 'addison-oneclick' && process.env.VUE_APP_ADDISON_IMPORT_ENABLED === 'true';
    },
    isLexwareEnabled() {
      return this.getCurrentUser().settings.tax_office_software === 'lexware' && process.env.VUE_APP_LEXWARE_IMPORT_ENABLED === 'true';
    },
    disableBranch() {
      return this.showBranch && this.radio === 'csvimport' && this.branchIncludedInCsv;
    }
  },
  created() {
    let branchService = new BranchService();
    branchService.listMyBranches().then((list) => {
      this.branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });
      if(this.branchList.length === 1) {
        this.branch_uid = this.branchList[0].code;
      }
    });
  },
  mounted() {
    this.init();

    if(this.getCurrentUser().settings.auto_generate_client_numbers === 1) {
      let service = new ClientService();
      service.getNextId().then(id => {
        this.setClientData({
          private: {
            client_id: id
          },
          business: {
            client_id: id
          }
        })
      });
    }

    if (this.getCurrentUser().settings.tax_office_software === 'datev') {
      this.radio = 'import';
      if(this.getCurrentUser().settings.only_datev_client_import) {
        this.manualAddClient = false;
        this.csvClientImport = false;

        this.submitNext();
      }
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapMutations('client', ["init", "setClientData"]),
    checkIsBusiness() {
      return process.env.VUE_APP_DEV_VARIANT === 'business'
    },
    checkedIncludedBranch(checked) {
      this.branchIncludedInCsv = checked
    },
    submitNext() {
      this.isBranchError = false;
      this.branchError = '';
      if (((this.radio === 'csvimport' && !this.branchIncludedInCsv) || this.radio === 'manual') && parseInt(this.branch_uid) === 0) {
        this.isBranchError = true;
        this.branchError = this.$t('clients.errors.required_branch')
        return;
      }

      this.setClientData({
        branch: this.branch_uid,
        branchIncludedInCsv: this.branchIncludedInCsv
      });
      switch (this.radio) {
        case "import":
          this.$router.push('/datev/ImportData')
          break;
        case "csvimport":
          this.$router.push('/client/csvimport')
          break;
        case "manual":
          this.$router.push('/client/manual')
          break;
          case "addison":
          this.$router.push('/addison/AddisonImport')
          break;
          case "lexware":
          this.$router.push('/addison/LexwareImport')
          break;
      }
    },
    cancelAddClient() {
      this.$router.push('/client/list')
    },
    createOptionSelected(e) {
      this.radio = e
      this.showBranch = e === 'manual' || e === 'csvimport';
      if(this.showBranch && this.branch_uid > 0) {
        this.submitNext();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 15px;
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

</style>
