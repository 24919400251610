<template>
  <MainLayout value="addison">
    <template>
      <component :is="comp"/>
    </template>
  </MainLayout>
</template>

<script>
export default {
  name: "Addison",
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
