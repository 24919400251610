<template>
  <div class="flex flex-col">
    <div class="flex mb-1" v-if='isLabelEnabled'>
      <div :class="CustomClasses" class="label">{{ label }}</div>
      <span v-if='isRequired' class="" style="color:red">*</span>
      <!--      help icon with Text tooltip-->
      <img v-if="showHelpIcon" class="help_icon" :src="getAssetPath('help_green.svg')" v-tooltip.right="tooltip"/>
      <!--      help icon with HTML tooltip/popover-->
      <VDropdown offset="2" v-if="showHelpIconPopover" :showTriggers="['click','hover']"
                 :popperTriggers="['hover','click']"
                 :hideTriggers="['click']">
        <!-- This will be the popover target (for the events and position) -->
        <img class="help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <!-- This will be the content of the popover -->
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text" v-for="(text,index) in popoverTexts" :key="index" v-html="text">
              </p>
            </div>
            <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text " :href="popoverLink" target="_blank">{{
                popoverLink
              }}</a></h2>
          </div>
          <!-- You can put other components too -->

        </template>
      </VDropdown>
    </div>
    <div class=" " v-if=disabled>
      <div class="flex justify-between input-wrapper-disabled rounded" v-if="autoComplete">
        <input v-model="selectedSuggestion" class="dropdown-input-disabled " :disabled="disabled" type="text"/>
      </div>
      <div class="flex justify-between input-wrapper-disabled rounded" v-else>
        <input v-model="field" class="dropdown-input-disabled "
               :disabled="disabled" type="text" ref="dropdowninput" :placeholder="placeholder"/>
        <img class="image-disabled" src="@/assets/right_arrow_gray.svg"/>
      </div>
    </div>
    <div class="" v-else-if="multiselect">
      <MultiSelect
        class="dropdown"
        :filter="filterable"
        v-model="selected"
        :options="optionsList"
        optionLabel="name"
        optionValue="code"
        :dataKey="dataKey"
        :placeholder="placeholder"
        display="chip"
      />
    </div>
    <div class="" v-else-if="autoComplete">
      <AutoComplete
          ref="autoComplete"
          v-model="selectedSuggestion"
          :multiple="false"
          field="name"
          :suggestions="suggestions"
          :auto-highlight="true"
          :maxlength="maxLength"
          :append-to="overlayAppendTo"
          @item-select="itemSelected"
          @complete="searchSuggestion"
          @focus="showAutocompleteOverlay"
          @blur="handleBlur"
          :class="CustomClasses"
      />
    </div>
    <div class="" v-else :class="loadingOptions ? 'loadingOption' : ''">
      <form autocomplete="off" class="w-full">
      <Dropdown :class="CustomClasses" class="dropdown" :filter="filterable" :disabled="loading" v-model="selected"
                :options="optionsListWithPossiblyDisabledEntries" optionLabel="name"
                :dataKey="dataKey"
                :emptyFilterMessage="$t('general.grid.nothing_found')"
                :append-to="overlayAppendTo"
                :show-clear=showClear
                :option-disabled="isOptionDisabled"
                :placeholder="placeholder"
                @filter="filterDropDown"
      >
        <template #option="slotProps" v-if="this.isCategory">
          <div :class="isOptionDisabled(slotProps.option) ? 'disable' :'enable' ">
            <span>{{ slotProps.option.name }}</span>
          </div>
        </template>
      </Dropdown>
      </form>
      <div class="loading_container" v-if="showLoader">
        <i class="pi pi-spin pi-spinner"/>
      </div>
    </div>

    <!-- Infotext label -->
    <label
        v-show="!disabled && infoText !== ''"
        class="info-label self-start mt-1"
    >
      {{ infoText }}
    </label>

    <!-- Error Message label-->
    <label
      class="error-label self-start mt-1"
      v-show="errorShow"
      :class="errorShow === true ? 'visible' : 'invisible'"
      style="color:#a4262c"
    >
      {{ errorMessage }}
    </label>
  </div>
</template>

<script>
import {i18n} from "@/plugins/i18n";
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from "primevue/autocomplete";
import _clone from 'lodash/clone';
import _order from "lodash/orderBy"

export default {
  name: "InputSelect",
  components: {
    Dropdown, MultiSelect, AutoComplete
  },
  directives: {'tooltip': Tooltip},
  props: {
    value: {
      type: [String, Number, Array],
      default: "",
    },
    maxLength: {
      type: Number,
      default: -1
    },
    isCategory: {
      type: Boolean,
      default: false
    },
    selectedValue: {
      type: [String, Number, Array],
      default: ""
    },
    filterable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isLabelEnabled: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: i18n.t('general.please_select')
    },
    infoText: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    autoComplete: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    showHelpIconPopover: {
      type: Boolean,
      default: false
    },
    popoverTexts: {
      type: Array,
      default: () => []
    },
    popoverLink: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: 'No tool tip provided'
    },
    overlayAppendTo: {
      type: String,
      default: null
    },
    colData: {
      required: false,
      default: null
    },
    searchFn: {
      type: Function,
      default: null
    },
    totalRecordCount: {
      type: Number,
      default: 0
    },
    sort: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: false
    },
    dataKey: {
      type: String,
      default: 'code'
    }
  },
  data() {
    return {
      field: "",
      hasFocus: false,
      selectedItem: {},
      inputValue: '',
      selected: null,
      suggestions: [],
      selectedSuggestion: '',
      optionsList: [],
      loadingOptions: false,
      showLoader: false,
      errorShow: false,
      searchTimeout: null,
    }
  },
  watch: {
    loading(nv) {
      this.showLoader = nv;
    },
    selectedValue: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (newValue !== oldValue) {
            if(this.autoComplete) {
              this.selectedSuggestion = newValue;
            } else if (this.multiselect) {
              this.selected = newValue;
            } else {
              this.selected = this.optionsList.find(item => item.code == newValue)
              this.field = this.value.name;

              if(!this.selected && this.doDynamicSearch()) {
                this.updateOptionList({
                  "uid": newValue
                }, newValue);
              }
            }
          }
        } else {
          if(this.autoComplete) {
            this.selectedSuggestion = '';
          } else {
            this.selected = {};
          }
        }
      },
      deep: true,
      immediate: true
    },
    selectedSuggestion(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue.name ? newValue.name : newValue);
      }
    },
    selected(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (this.multiselect) {
          this.$emit("input", newValue);
        } else {
          this.$emit("input", newValue.code);
          if (newValue.code === '') {
            this.selectItem({
              name: "",
              code: ""
            });
          } else {

            let o = this.optionsList.find(item => item.code == newValue.code);
            if (typeof (o) !== "undefined") {
              this.selectItem(o)
            } else {
              this.selectItem({
                name: "",
                code: ""
              });
            }
          }
        }
      } else if(this.showClear && oldValue && newValue !== oldValue) {
        // selected value reset and showClear is enabled
        this.selectItem({
          name: "",
          code: ""
        });
        this.field = '';
        this.$emit("input", '');
      }
    },
    options: {
      deep: true,
      handler: function () {
        this.optionsList = _clone(this.options);
        if(this.sort) {
          this.optionsList = _order(this.optionsList, "name")
        }

        let foundItem = this.optionsList.find(item => item.code == this.selectedValue)
        if (foundItem) {
          this.selected = foundItem
        } else if(this.doDynamicSearch()) {
          this.updateOptionList({
            "uid": this.selectedValue
          }, this.selectedValue);
        }

        if(this.autoComplete) {
          this.suggestions = this.optionsList;
          if(this.selectedValue !== this.selectedSuggestion && this.selectedSuggestion !== '') {
            this.selectedSuggestion = this.selectedValue;
          }
        }
      }
    },
    isError(newValue) {
      this.errorShow = newValue;
    }
  },
  mounted() {
    this.optionsList = _clone(this.options);
    if(this.sort) {
      this.optionsList = _order(this.optionsList, "name")
    }

    this.showLoader = this.loading;

    if (this.selectedValue) {
      let foundItem = this.optionsList.find(item => item.code == this.selectedValue)
      if (foundItem) {
        this.selected = foundItem
      } else if(this.doDynamicSearch()) {
        this.updateOptionList({
          "uid": this.selectedValue
        }, this.selectedValue);
      }

      this.field = this.value.name;
    }

    if(this.autoComplete) {
      this.suggestions = this.optionsList;
      if(this.selectedValue) {
        this.selectedSuggestion = this.selectedValue;
      }
    }

    this.errorShow = this.isError;
  },
  computed: {
    getOptions() {
      return this.optionsList.map(({value: code, label: name}) => ({code, name}));
    },
    CustomClasses() {
      return [
        {'disabled': this.disabled},
        {'error': this.errorShow}
      ]
    },
    filterOptions: function () {
      if (this.field !== undefined) {
        return this.optionsList.filter(item => {
          return this.field
              .toLowerCase()
              .split(" ")
              .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return []
      }
    },
    optionsListWithPossiblyDisabledEntries() {
      return this.optionsList.map((option) => {
        if (!this.isCategory) {
          return option;
        }

        if (option.code !== '') {
          return option;
        }

        return {
          ...option,
          code: this.$uuid.v4(),
          disabled: (option.code === ''),
        };
      });
    }
  },
  methods: {
    clearSelection() {
      this.selectedItem = {}
      this.selected = null;
    },
    resetSelection() {
      this.selectedItem = {};
      this.$nextTick(() => this.$refs.dropdowninput.focus())
      this.$emit('on-item-reset')
    },
    selectItem(theItem) {
      this.field = theItem.name
      if(this.colData !== null && typeof this.colData['prim_uid'] !== 'undefined') {
        theItem.primUid = this.colData['prim_uid'];
      }
      this.$emit('on-item-selected', theItem)
    },
    handleFocus() {
      this.hasFocus = true
    },
    handleBlur() {
      this.$emit('blur')

      setTimeout(() => {
        this.hasFocus = false
      }, 300)
    },
    itemVisible(item) {
      let currentName = item.name.toLowerCase()
      let currentInput = this.field.toLowerCase()
      return currentName.includes(currentInput)
    },
    isOptionDisabled(option) {
      if (this.isCategory) {
        return option.disabled ?? false;
      }
      return false;
    },
    itemSelected(e) {
      this.$emit('on-item-selected', e.value);
      this.$emit('change');
    },
    showAutocompleteOverlay(e) {
      this.$refs.autoComplete.showOverlay();
    },
    searchSuggestion(event) {
      if (!event.query.trim().length) {
        this.suggestions = this.optionsList
      } else {
        if(this.searchFn) {
          this.searchFn(event.query.toLowerCase());
        } else {
          this.suggestions = this.optionsList.filter((o) => {
            return o.name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }
    },
    doDynamicSearch() {
      return this.searchFn && this.totalRecordCount > this.options.length;
    },
    filterDropDown(e) {
      if (!this.doDynamicSearch()) {
        return;
      }

      if (e.value.length > 0) {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
          this.updateOptionList({
            "txtFilter": e.value
          });
        }, 800);
      } else {
        this.optionsList = _clone(this.options);
        if(this.sort) {
          this.optionsList = _order(this.optionsList, "name")
        }
      }
    },
    updateOptionList(params, selectedVal) {
      if(selectedVal && (parseInt(selectedVal) === 0 || selectedVal === '')) {
        return ;
      }

      if(selectedVal) {
        this.showLoader = true;
      }
      this.loadingOptions = true;
      this.optionsList = [];
      params.dropdownSearch = 1;
      this.searchFn(params).then((list) => {
        if(list) {
          this.optionsList = list;
          if(this.sort) {
            this.optionsList = _order(this.optionsList, "name")
          }
          this.loadingOptions = false;

          if(selectedVal) {
            let foundItem = this.optionsList.find(item => item.code == selectedVal)
            if (foundItem) {
              this.selected = foundItem
            }
            this.showLoader=false;
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

.input-wrapper {
  width: 100%;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: black;
  text-align: left;

  &:focus {
    border: 1px solid $primary;
  }

}

.p-autocomplete.p-inputwrapper {
  &.error {
    border: 1px solid $error-color;
  }
}

.input-wrapper-disabled {
  height: 32px;
  background: #f4f1f3;
}

.dropdown-list .dropdown-item {
  line-height: 1.4 !important;
}

.label {


  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;

  &.disabled {
    color: #797979;
  }
}

.error-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
  text-align: left;
}

.info-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
  background-color: #f2f2f2;
  color: #333333;
  text-align: left;
  line-height: normal;
  padding: 8px;
}

.enter-login:focus-within {
  border: 1px solid $primary;
}

.image {
  height: 30px;
  width: 30px;
  padding: 7px;
  transform: rotate(90deg);
}

.image-disabled {
  width: 28px;
  padding: 10px;
  transform: rotate(90deg);
}

.dropdown {
  position: relative;
  height: 32px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  border-radius:4px;
}

.dropdown-input, .dropdown-selected {
  width: 100%;
  padding: 10px 8px;
  background: white;
  border-radius: 0px;

}

.dropdown-input-disabled {
  width: 100%;
  border: 0px solid #d1d1d1;
  background: #f4f1f3;
  border-radius: 0px;
  padding-left: 10px;
}

.dropdown-input:focus, .dropdown-selected:hover {
  background: #fff;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  z-index: 1;
}

.dropdown-item {
  display: flex;
  width: 100%;

  padding-left: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  @include segoe-regular;

  background-color: rgba(255, 255, 255, 0);

  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
}

.dropdown-item:hover {
  background: $gray;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee;
}

.no-outline:focus {
  outline: none;
}

.loading_container {
  position: absolute;
  right: 1px;
  top: 1px;
  background: #f3f2f1;
  width: 30px;
  height: 90%;
  padding-top: 5px;
  margin: 1px;
}

::v-deep .p-dropdown-items .p-dropdown-item {
  padding-left: 10px !important;
  white-space: normal;
}
:deep(.p-dropdown:not(.p-disabled).p-focus){
  border: 1px solid $primary;
}
.disable {
  pointer-events: none;
  cursor: default;
}

.enable {
  padding-left: 10px;
}
a {
  color: white;
  text-decoration: underline;
}
.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}
.p-autocomplete.p-inputwrapper {
  width: 100%;
  border-radius:4px;
}
.loadingOption ::v-deep .p-dropdown-empty-message {
  display: none;
}
.loadingOption ::v-deep .pi-search {
  animation: fa-spin 2s infinite linear;
}
.loadingOption ::v-deep .pi-search:before {
  content: "\e926";
}
:deep(.p-dropdown-filter){
  border-radius: 4px;
  border: 1px solid $border-color;

}
:deep(input.p-inputtext:enabled:focus){
  border-radius: 4px;
  border: 1px solid $primary;
}
</style>
