<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('components.ggw.title')" :logo="require('@/assets/datev_ggw_title.svg')"/>

    <div v-if="renderStart">
      <div class="p-3 mt-2 mb-2 info-msg flex">
        <img src="@/assets/note.svg" width="15px">
        <label class="pl-4 text-left">
          {{ $t('components.ggw.datev_intro1') }} <a href="https://apps.datev.de/help-center/documents/1024112" target="_blank">{{ $t('components.ggw.datev_intro2') }}</a>.
        </label>
      </div>
      <div class="align-left">
        <Button :text="$t('components.ggw.button')" @click="handleStartClick"
                :icon="getAssetPath('doc_ggw.svg')"></Button>
      </div>
    </div>
    <div class="mr-7 ggw-transfer">
      <AppGrid grid-id="datev-ggw-transfer"
               data-endpoint="datev/ggw_list"
               v-if="renderGrid"
               :standard-filters="standardFilters"
               default-sort-attr="client"
               :default-sort-dir="1"
               :columns="columns"
               :selectable="true"
               :show-select-col="true"
               :show-filter="true"
               selection-data-key="prim_uid"
               :use-local-data="false"
               :resizable-columns="true"
               :reorderable-columns="true"
               :auto-load="true"
               :show-create-record="false"
               :menus="menus"
               :stateFul="false"
               @menu-clicked="handleMenuClick"
               @row-selection="handleRowSelect"
               @col-click="handleColClick"
               @row-select-all="handleRowSelect"
               @row-unselect="handleRowSelect"
               @row-unselect-all="handleRowSelect"
               @on-data-loaded="handleDataLoaded"
               @on-community-save="handleCommunitySave"
      />
    </div>
  </div>
</template>
<script>
import AppGrid from "../AppGrid";
import {i18n} from "@/plugins/i18n";
import {BranchService} from "@/services/branch.service";
import {mapMutations} from "vuex";

export default {
  name: "Ggw",
  components: {AppGrid},
  data() {
    return {
      ggwDocuments: {},
      ggwMapper: {},
      selected: [],
      branches: [],
      taxSoftware: '',
      renderGrid: false,
      renderStart: true,
      standardFilters: [
        {
          selected: true,
          default: true,
          name: this.$t('components.ggw.filters.transferred_pending'),
          id: 'transferred_pending'
        },
        {
          selected: false,
          default: false,
          name: this.$t('components.ggw.filters.transferred_transferred'),
          id: 'transferred_transferred'
        },
        {
          selected: false,
          default: false,
          name: this.$t('components.ggw.filters.pending_pending'),
          id: 'pending_pending'
        },
        {
          selected: false,
          default: false,
          name: this.$t('components.ggw.filters.pending_transferred'),
          id: 'pending_transferred'
        },
      ],
      columns: [
        {
          field: 'client',
          header: i18n.t('components.ggw.columns.client'),
          isChecked: true,
          sortable: true
        },
        {
          field: 'clientId',
          header: i18n.t('components.ggw.columns.clientId'),
          isChecked: true,
          sortable: true
        },
        {
          field: 'property',
          header: i18n.t('components.ggw.columns.property'),
          isChecked: true,
          sortable: true,
        },
        {
          field: 'propertyType',
          header: i18n.t('components.ggw.columns.propertyType'),
          isChecked: true,
          elType: 'select',
          sortable: true,
          filterOptions: this.$t('components.ggw.filters.economic_entity_type_filter')
        },
       {
          field: 'uid_display',
          header: i18n.t('components.ggw.columns.declaration_number'),
          isChecked: true,
          clickable: true,
          sortable: true
        },{
          field: 'reference_date',
          header: i18n.t('components.ggw.columns.assessmentYear'),
          isChecked: true,
          clickable: true,
          sortable: true
        },
        {
          field: 'property_value_display',
          fieldFederalState: 'federal_state_uid',
          fieldDeclarationUid: 'prim_uid',
          header: i18n.t('components.ggw.columns.property_value'),
          isChecked: true,
          sortable: true,
          colElType: 'propertyvalue'
        },
        {
          field: 'branch',
          header: this.$t('clients.attrs.branch'),
          isChecked: true,
          elType: 'select',
          sortable: true,
          dataSource: [],
          filterOptions: '',
          clickable: true
        },
        {
          field: 'transferstatus',
          header: i18n.t('components.ggw.columns.status'),
          isChecked: true,
          colElType: 'transferstatus',
          idField: 'prim_uid',
          width: '200px',
          sortable: false,
          bodyClass: 'unwrap_row'
        }
      ],
      menus: [
        {
          id: "ggw.back",
          "icon": this.getAssetPath('arrow-back.svg'),
          "icon_disabled": this.getAssetPath('arrow-back-gray.svg'),
          "title": this.$t('documents.attrs.datev_dms.back')
        }, {
          id: "ggw.transfer",
          "icon": this.getAssetPath('upload.svg'),
          "icon_disabled": this.getAssetPath('upload_gray.svg'),
          "title": this.$t('documents.attrs.datev_dms.transfer'),
          "enable_on_select": true,
          "disabled": true,
          "action_per_record": "transfer-record"
        }
      ]
    }
  },
  computed: {},
  beforeMount() {
    (new BranchService()).listMyBranches().then((list) => {
      let branchList = list.map((o) => {
        return {
          "name": o.name,
          "code": o.uid
        }
      });
      branchList.unshift({ "name": i18n.t('components.ggw.filters.optionAll'), "code": "0"});
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field === 'branch') {
          this.columns[i].filterOptions = branchList;
        }
      }
    });
  },
  mounted() {
    if (sessionStorage.getItem('datevGgw') ==='1') {
      this.handleStartClick();
    }
    this.taxSoftware = this.getCurrentUser().settings.tax_office_software;
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    handleStartClick() {
      this.renderStart = false;

      this.$api.trigger('/datev/ggw').then((response) => {
        if(response.data.oauthUrl) {
          sessionStorage.setItem('deepLink', '/datev/ggw');
          sessionStorage.setItem('datevGgw', '1');
          window.location.href = response.data.oauthUrl;
        } else {
          if (sessionStorage.getItem('datevGgw') ==='1') {
            sessionStorage.removeItem('datevGgw');
          }
          this.renderGrid = true;
        }
      });
    },
    handleBackClick() {
      this.renderGrid = false;
      this.ggwDocuments = {};
      this.ggwMapper = [];
      this.renderStart = true;
    },
    handleRowSelect(rows) {
      this.selected = rows;
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'ggw.back':
          this.handleBackClick();
          break;
      }
    },
    handleColClick(e) {
      if(parseInt(e.row.branch_uid) > 0 && e.field === 'branch') {
        this.$router.push("/branch/edit?uid=" + parseInt(e.row.branch_uid));
      } else if (e.field === 'uid_display') {
        this.$router.push("/declaration/edit?uid=" + parseInt(e.row.prim_uid));
      }
    },
    handleDataLoaded(data) {
      data.map((o) => {
        return o;
      });
    },
    handleCommunitySave({communityId, declarationId, save}) {
      if (save === false) {
        this.$api.showToast(this.$t('declarations.tax_calculation.errors.mustSave'), 'error');
        return;
      }

      this.$api.trigger('/addison/community', {
        'communityId': communityId,
        'declarationId': declarationId,
        'save': save
      }).then((response) => {
        if (response.data.success !== true) {
          this.$api.showToast(this.$t('declarations.tax_calculation.errors.unknownError'), 'error');
        } else {
          this.triggerReload('datev-ggw-transfer');
          this.$root.$emit('updated-community-' + declarationId);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.info-msg {
  background-color: #f2f2f2;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;

    &.underline {
      text-decoration: underline;

    }
  }

  .border-left {
    border-left: 1px solid #dbdbdb;
  }
}

.align-left{
  text-align: left;
}

.ggw-transfer ::v-deep .unwrap_row {
  white-space: normal !important;
 }
</style>
