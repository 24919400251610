<template>
  <div class="flex w-full justify-center space-x-4">
    <!--        col1 -->
    <div class="w-full flex flex-col justify-between rectangle rounded">
      <div>
        <div class="flex justify-between items-center ml-2 mr-2 mt-4 mb-2">
          <div class="flex w-full">
            <label class="text ">{{ leftHeader }} <span v-if="countShow">({{ filterOptionsLeft.length }})</span></label>
            <!--      help icon with Text tooltip-->
            <img v-if="showLeftHelpIcon" class="help_icon" :src="getAssetPath('help_green.svg')"
                 v-tooltip.right="leftTooltip"/>
          </div>

          <div class="duallist-search pl-3 mt-1 rounded focus:outline-none">
            <input class="input focus:outline-none" v-model="filterLeft" :placeholder="$t('general.grid.search')"
                   @input="filterDataList"/>
          </div>
        </div>
        <!--table-->
        <div class="flex flex-col align-left  w-auto border-gray h-500 ">
          <div class="overflow-scroll h-full relative">
            <div class="flex flex-col w-full loading-container" v-show="showloader">
              <div class="spin"></div>
            </div>
            <table class=" table-fixed min-w-full divide-y divide-gray tbl">
              <thead class="">
              <tr>
                <th v-for="col in columns" :key="col['field']" scope="col" class="sticky py-3 pr-3 text-left pl-2 header-column " @click="sortLeftColumns(col)" :class="sortLeftColumnAttr === col.field ? 'active-header-column' : ''">
                  {{ col['label'] }}
                  <span class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt" v-if="sortLeftColumnAttr === col.field && sortLeftColumnDir === 'asc'"></span>
                  <span class="p-sortable-column-icon pi pi-fw pi-sort-amount-down" v-else-if="sortLeftColumnAttr === col.field && sortLeftColumnDir === 'desc'"></span>
                  <span class="p-sortable-column-icon pi pi-fw pi-sort-alt" v-else></span>
                </th>
                <th class="sticky"></th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray overflow-y-scroll"
                     v-observe-visibility="handleInfinityScroll">
              <tr v-for="(person,index) in filterOptionsLeft" :key="index">
                <td class="py-4  pr-2 text-left pl-2" v-for="col in columns" :key="person[col.field]">
                  <img class="icon-table icon-dual-list" v-if="col.type==='icon'"
                       :src="require('@/assets/' + person[col.field])"
                       @click="addItem(index,person)"/>
                  <p v-else> {{ person[col.field] }}</p>
                </td>
                <td class="pr-3 pl-2 py-4  ">
                  <img class="icon-table cursor-pointer icon" :src="getAssetPath('circle_green.svg')"
                       @click="addItem(index,person)"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="sticky bottom-0 text-left bg-white ml-2 pt-2" v-if="enableAllSelect">
            <label class="text-left limit-info-text" v-if="showLimitInfo">{{ $t('general.limit_reached_duallist', {"recordLimit": recordLimit, "totalRecords": totalRecordCount}) }}</label>
            <Button class="w-40 mt-2" :text="$t('general.all_select')" @click="allSelect()"/>
          </div>
          <div class="sticky bottom-0 text-left bg-white ml-2" v-else>
            <slot name="left-footer"/>
          </div>
        </div>
      </div>
    </div>
    <!--        col2-->
    <div class="w-full rectangle rounded">
      <div class="flex justify-between items-center ml-2 mr-2 mt-4 mb-2 ">
        <div class="flex w-full">
          <label class="text ">{{ rightHeader }} <span v-if="countShow">({{ filterOptionsRight.length }})</span></label>
          <!--      help icon with Text tooltip-->
          <img v-if="showRightHelpIcon" class="help_icon" :src="getAssetPath('help_green.svg')"
               v-tooltip.right="rightTooltip"/>
        </div>

        <div class="duallist-search pl-3 mt-1 rounded focus:outline-none">
          <input class="input focus:outline-none" v-model="filterRight" :placeholder="$t('general.grid.search')"/>
        </div>
      </div>
      <!--table-->
      <div class="flex flex-col align-left  w-auto border-gray h-500 ">
        <div class="overflow-scroll border-b border-gray ">
          <table class=" table-fixed min-w-full divide-y divide-gray tbl">
            <thead class="">
            <tr>
              <th v-for="col in columns" :key="col['field']" scope="col" class=" py-3 pr-6 text-left pl-2 header-column" @click="sortRightColumns(col)" :class="sortRightColumnAttr === col.field ? 'active-header-column' : ''">
                {{ col['label'] }}
                <span class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt" v-if="sortRightColumnAttr === col.field && sortRightColumnDir === 'asc'"></span>
                <span class="p-sortable-column-icon pi pi-fw pi-sort-amount-down" v-else-if="sortRightColumnAttr === col.field && sortRightColumnDir === 'desc'"></span>
                <span class="p-sortable-column-icon pi pi-fw pi-sort-alt" v-else></span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray">
            <tr v-for="(person,index) in filterOptionsRight" :key="index">
              <td class="py-4  pr-2 text-left pl-2" v-for="col in columns" :key="person[col.field]">
                <img class="icon-table icon" v-if="col.type==='icon'" :src="require('@/assets/' + person[col.field])"
                     @click="removeItem(index,person)"/>
                <p v-else> {{ person[col.field] }}</p>
              </td>
              <td class="pr-3 pl-2 py-4  ">
                <img v-if="person.disableDelete" class="icon-close icon" :src="getAssetPath('cross_gray.svg')"/>
                <img v-else class="icon-close cursor-pointer icon" :src="getAssetPath('cross_green.svg')"
                     @click="removeItem(index,person)"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import _orderBy from "lodash/orderBy"
import _clone from "lodash/clone"

export default {
  name: "DualListSelect",
  directives: {'tooltip': Tooltip},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    leftHeader: {
      type: String,
      default: "",
    },
    rightHeader: {
      type: String,
      default: "",
    },
    dataKey: {
      type: String,
      default: "",
    },
    sortAttr: {
      type: String,
      default: "",
    },
    validator: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: (item) => {
        return true;
      }
    },
    showLeftHelpIcon: {
      type: Boolean,
      default: false,
    },
    leftTooltip: {
      type: String,
      default: 'No tool tip provided'
    },
    showRightHelpIcon: {
      type: Boolean,
      default: false,
    },
    rightTooltip: {
      type: String,
      default: 'No tool tip provided'
    },
    enableAllSelect: {
      type: Boolean,
      default: false
    },
    totalRecordCount: {
      type: Number,
      default: 0
    },
    recordLimit: {
      type: Number,
      default: -1
    },
    countShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dataList: [],
      searchAttr: [],
      filterLeft: '',
      filterRight: '',
      selectedList: [],
      intiFiltered: false,
      showloader: false,
      sortLeftColumnAttr: '',
      sortLeftColumnDir: '',
      sortRightColumnAttr: '',
      sortRightColumnDir: '',
    }
  },
  mounted() {
    this.dataList = _clone(this.list)
    this.selectedList = this.value;
    if (this.selectedList.length > 0 && this.dataList.length > 0 && this.dataKey) {
      this.dataList = this.dataList.filter(item => {
        return !this.selectedList.some(v => item[this.dataKey].includes(v[this.dataKey]))
      })
    }
  },
  watch: {
    value(newValue) {
      if (typeof (newValue) !== 'undefined') {
        this.selectedList = newValue;
      }
    },
    selectedList() {
      this.$emit("input", this.selectedList);
    },
    list: {
      deep: true,
      handler: function () {
        this.dataList = _clone(this.list);
        this.selectedList = this.value;
        if (this.selectedList.length > 0 && this.dataList.length > 0 && this.dataKey) {
          this.dataList = this.dataList.filter(item => {
            return !this.selectedList.some(v => item[this.dataKey].includes(v[this.dataKey]))
          })
        }
        this.showloader = false;
      }
    }
  },
  computed: {
    filterOptionsLeft: function () {
      if (this.filterLeft !== undefined && this.searchAttr.length > 0) {
        if (this.totalRecordCount > this.dataList.length) {
          if (this.sortAttr) {
            return _orderBy(this.dataList, this.sortAttr, "asc");
          } else if (this.sortLeftColumnAttr !== '') {
            return _orderBy(this.dataList, this.sortLeftColumnAttr, this.sortLeftColumnDir);
          } else {
            return this.dataList;
          }
        } else {
          let leftList = this.searchArray(this.dataList, this.searchAttr, this.filterLeft);
          if (this.sortAttr) {
            leftList = _orderBy(leftList, this.sortAttr, "asc");
          } else if (this.sortLeftColumnAttr !== '') {
            leftList = _orderBy(leftList, this.sortLeftColumnAttr, this.sortLeftColumnDir);
          }
          return leftList;
        }
      } else {
        return []
      }
    },
    filterOptionsRight: function () {
      if (this.filterRight !== undefined && this.searchAttr.length > 0) {
        let rightList = this.searchArray(this.selectedList, this.searchAttr, this.filterRight);

        if (this.sortRightColumnAttr !== '') {
          rightList = _orderBy(rightList, this.sortRightColumnAttr, this.sortRightColumnDir);
        }
        return rightList;
      } else {
        return []
      }
    },
    showLimitInfo: function() {
      return this.recordLimit > 0 && this.totalRecordCount > this.recordLimit;
    },
  },
  created() {
    let searchableCol = this.columns.filter(o => {
      if (o.search) {
        return o;
      }
    });
    this.searchAttr = searchableCol.length > 0 ? searchableCol.map((o) => {
      return o.field;
    }) : [];
  },
  methods: {
    filterDataList() {
      if (this.totalRecordCount > this.dataList.length) {
        this.showloader = true;
        this.dataList = [];
        this.$emit('filterList', {
          "dropdownSearch": 1,
          "txtFilter": this.filterLeft
        });
      }
    },
    addItem(index, item) {
      if (this.validator(item)) {
          this.selectedList.push(item)

          if (this.dataKey) {
            index = this.dataList.findIndex(o => o[this.dataKey] == item[this.dataKey]);
            if (index >= 0) {
              this.dataList.splice(index, 1);
            }
          } else {
            this.dataList.splice(index, 1)
          }
        } else {
        this.$emit("validatorFail", item);
      }
    },
    removeItem(index, item) {
      if (this.dataKey) {
        index = this.selectedList.findIndex(o => o[this.dataKey] == item[this.dataKey]);
        if (index >= 0) {
          this.selectedList.splice(index, 1);
        }
      } else {
        this.selectedList.splice(index, 1)
      }
      this.dataList.push(item)
    },
    allSelect() {
      let removeIndexes = [];
      let invalid = [];
      for (let k = 0; k < this.dataList.length; k++) {
        if (this.validator(this.dataList[k], true)) {
          this.selectedList.push(this.dataList[k]);
          removeIndexes.push(k);
        } else {
          invalid.push(this.dataList[k]);
        }
      }

      removeIndexes.reverse().forEach((index) => {
        this.dataList.splice(index, 1);
      });

      if(invalid.length > 0) {
        this.$emit("validatorFailAll", invalid);
      }
    },
    handleInfinityScroll(isVisible) {
      if (!isVisible) {
        return;
      }
    },
    sortLeftColumns(col) {
      this.sortLeftColumnAttr = col.field;
      if (this.sortLeftColumnDir === 'asc') {
        this.sortLeftColumnDir = 'desc'
      } else {
        this.sortLeftColumnDir = 'asc'
      }
    },
    sortRightColumns(col) {
      this.sortRightColumnAttr = col.field;
      if (this.sortRightColumnDir === 'asc') {
        this.sortRightColumnDir = 'desc'
      } else {
        this.sortRightColumnDir = 'asc'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tbl td img.icon-dual-list {
  // margin-left: auto;
  margin-right: auto;
}

.overflow-scroll {
  overflow-y: auto;
  max-height: 503px;
  overflow-x: hidden;
}

.icon-table {
  width: 22px;
  height: 22px;
  box-sizing: border-box;
}

.icon-close {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
}

th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.rectangle {
  //width: 750px;
  height: 575px;
  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
}


.duallist-search:focus-within {
  border: 2px solid $primary;
  //.image {
  //    animation: slide-out .5s forwards ease-out;
  //    -webkit-animation: slide-out .5s forwards ease-out;
  //}
  input {
    // margin-left: -12px
  }
}

.input {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-top: -15px;
  margin-left: 25px;
  font-size: 13px;
}

.icon-table-cross {
  width: 15px;
  height: 15px;
}

.box {

  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text-info {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.lbl {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
}

.help_icon {
  margin-top: 4px;
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
}

.spin {
  margin: auto;
  position: relative;
}

table tr td img.icon {
  min-width: 22px;
}
.limit-info-text {
  font-size: 12px;
  color: #666464;
}

.header-column {
  cursor: pointer;
  min-width: 180px;
}

.active-header-column,
.header-column:hover {
  background-color: #f3f2f1;
}
</style>
