<template>
  <MainLayout value="dms">
    <template>
      <component :is="comp"/>
    </template>
  </MainLayout>
</template>

<script>
import Dms from '../components/dms/Dms'

export default {
  name: "Datev",
  components: {"setup_branches": Dms},
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
