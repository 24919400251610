import {ApiService} from "./api.service";
import Vue from "vue";

export class DocumentService extends ApiService {

  constructor() {
    super();
  }

  upload(files, datevDms = false) {
    if (files.length > 0) {
      let uploadResult = this.$store.getters["documents/getUploadResult"];

      // Excluded already processed files
      if(uploadResult.length > 0) {
        for(let x of uploadResult) {
          files = files.filter((o) => {
            return o.uuid !== x.fileUuid;
          });
        }
      }

      if(files.length > 0) {
        Vue.prototype.$loading.show();
        let promises = [];
        for (let file of files) {
          let formData = new FormData();
          formData.append('file', file);
          formData.append('fileUuid', file.uuid)
          formData.append('forDatev', Number(datevDms).toString());
          promises.push(this.trigger('document/upload', formData, false, 'multipart/form-data'));
        }

        return Promise.all(promises).then((responses) => {
          let uploadedCount = 0
          let failedUpload = 0;
          let uploadResult = [];
          for (let response of responses) {
            if (response.data.success) {
              uploadedCount++;
              if (response.data.fileUuid) {
                uploadResult.push({
                  fileUuid: response.data.fileUuid,
                  uploaded: true
                });
              }
            } else {
              failedUpload++;
              if (response.data.fileUuid && response.data.message) {
                uploadResult.push({
                  fileUuid: response.data.fileUuid,
                  errorMessage: response.data.message
                });
              }
            }
          }

          if (uploadResult.length > 0) {
            this.$store.commit('documents/setUploadResult', uploadResult);
          }

          Vue.prototype.$loading.hide();
          if (failedUpload > 1) {
            this.showToast(this.$i18n.t('documents.x_docs_upload_failed', {
              "count": failedUpload
            }), "error")
            return false;
          } else if (failedUpload === 1) {
            let failedRes = uploadResult.filter((o) => {
              if (o.errorMessage) {
                return true;
              }
            });

            if (failedRes.length > 0) {
              this.showToast(failedRes[0].errorMessage, "error")
            } else {
              this.showToast(this.$i18n.t('general.errors.unknownError'), "error")
            }

            return false;
          }

          if (uploadedCount > 0) {
            this.showToast(this.$i18n.t('documents.x_docs_uploaded', {
              "count": uploadedCount
            }))
            return true;
          }
        });
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get(documentId, show_loader) {
    if (typeof (show_loader) === 'undefined') {
      show_loader = false;
    }
    return this.trigger('document/get', {
      uid: documentId
    }, show_loader).then((response) => {
      if (response.data.success) {
        return response.data.document;
      } else {
        this.showToast(response.data.message, "error");
        return [];
      }
    })
  }
  tree(data) {
    return this.trigger('documents_module/tree', data).then((response) => {

      if (response.data.success) {
        return response.data;
      } else {
        this.showToast(response.data.message, "error");
        return [];
      }
    })
  }

  download(documentId) {
    this.trigger('document/get', {
      uid: documentId,
      download: 1
    }, true).then((response) => {
      if (response.data.success) {
        location.href = 'download';
      } else {
        this.showToast(response.data.message, "error");
      }
    })
  }

  delete(documentId) {
    return this.trigger('document/delete', {
      record: {
        "prim_uid": documentId
      }
    }, true).then((response) => {
      if (response.data.success) {
        return true
      } else {
        this.showToast(response.data.message, "error");
        return false;
      }
    })
  }

  update(data, show_loader) {
    if (typeof (show_loader) === 'undefined') {
      show_loader = true;
    }
    return this.trigger('document/update', data, show_loader).then(response => {
      if (response.data.success) {
        return true;
      } else {
        this.showToast(response.data.message, "error")
        return false;
      }
    });
  }

  saveRotate(data) {
    return this.trigger('document/saveRotate', data).then(response => {
      if (response.data.success) {
        this.showToast(this.$i18n.t('documents.save_rotate_success'), 'success');
        return true;
      } else {
        this.showToast(response.data.message, 'error')
        return false;
      }
    });
  }

  sendDocument(data, show_loader) {
    if (typeof (show_loader) === 'undefined') {
      show_loader = true;
    }

    return this.trigger('document/send_document', data, show_loader).then(response => {
      if (response.data.success) {
        this.showToast(this.$i18n.t('documents.send_document_dlg.success'), 'success');
        return true;
      }

      this.showToast(response.data.message, 'error');

      return false;
    });
  }

  duplicateDocument(data, show_loader) {
    if (typeof (show_loader) === 'undefined') {
      show_loader = true;
    }

    return this.trigger('document/duplicate_document', data, show_loader).then(response => {
      if (response.data.success) {
        this.showToast(this.$i18n.t('documents.duplicate_dlg.success'), 'success');
        return true;
      }

      this.showToast(response.data.message, 'error');

      return false;
    });
  }
}
