<template>
  <MainLayout value="dataExportStatus">
    <template>
      <div class="h-full bg-white pl-7 w-full">
        <PageHeader :title="notification.description"/>
        <div class="flex flex-col justify-center items-center">
          <div class="w-full">
            <div class="mt-10 mr-6 text-left">
              <div class="flex justify-around space-x-1 mt-5">
                <div class="w-full text-left flex">
                  <label>{{ $t('general.data_export_completed') }}</label>
                </div>
              </div>

              <div class="flex justify-end space-x-6 mt-20 mr-3">
                <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="backToDashboard"/>
                <Button class="w-40" :text="$t('general.download_now')" @click="downloadNow"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {UserService} from "@/services/user.service";

export default {
  name: "dataExportStatus",
  components: { MainLayout },
  data() {
    return {
      userService: new UserService(),
      notification: {}
    };
  },
  watch: {
    '$route'(to) {
      if (typeof this.notification.prim_uid === 'undefined' || (this.notification.prim_uid && this.notification.prim_uid !== to.query.uid)) {
        this.fetchNotification();
      }
    }
  },
  mounted() {
    this.fetchNotification();
  },
  methods: {
    downloadNow() {
      this.$api.trigger('notification/download_file', {
        "id": this.notification.prim_uid
      }, true).then((response) => {
        if(response.data.success && response.data.token) {
          const downloadLink = document.createElement("a");
          downloadLink.href = '/download?token=' + response.data.token;
          downloadLink.click();
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    backToDashboard() {
      this.$router.push('/home');
    },
    fetchNotification() {
      this.userService.getNotification(this.$route.query.uid).then((response) => {
        if (response.data.success && response.data.notification && response.data.notification.prim_uid) {
          if (response.data.notification.extra_details) {
            response.data.notification.extra_details = JSON.parse(response.data.notification.extra_details);
          }
          this.notification = response.data.notification;
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">

</style>
