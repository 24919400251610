<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('properties.title')" :breadcrumbs="breadcrumbs"/>
    <div class="flex flex-col justify-center items-center">
      <div class="w-full mr-12">
        <FormSteps :steps="availableSteps"/>
      </div>
      <div class=" pr-5 w-full">
        <div class="mt-10" v-if="completed===false">
          <component
              v-for="(item, index) in availableSteps"
              :key="`component-${index}`"
              :is="item.component"
              :stepIndex="index"
              :isLast="item.last"
              @goToNextStep="goToNextStep"
              @cancelStep="cancelStep"
              @goBack="goBack"
              @finish="finish"
              v-show="item.active === true"
          />
        </div>
        <div class="mt-10 mr-6 ml-6" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick" :propertyUid="property_uid" :clientUid="client_uid"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSteps from "../FormSteps";
import StepClient from '@/components/properties/property_steps/StepClient.vue';
import StepAddress from '@/components/properties/property_steps/StepAddress.vue';
import StepDetails from '@/components/properties/property_steps/StepDetails.vue';
import StepPlot from '@/components/properties/property_steps/StepPlot.vue';
import Finish from '@/components/properties/property_steps/Finish.vue';
import {PropertyService} from "@/services/property.service";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "AddNewProperty",
  components: {FormSteps, StepClient, StepAddress, StepDetails, StepPlot, Finish},
  data() {
    return {
      service: new PropertyService(),
      breadcrumbs: [{
        title: 'Grundstücke',
          link: '/property/list'
      }, {
        title: 'Grundstück hinzufügen',
        link: ''
      }
      ],
      availableSteps: [],
      completed: false,
      property_uid: 0,
      client_uid: 0
    }
  },
  computed: {
    ...mapGetters("property", ["getAddFlowSteps", "getSteps"])
  },
  mounted() {
    if(!(this.getCurrentUser().permissions.manage_property && parseInt(this.getCurrentUser().permissions.manage_property) === 1)) {
      this.$router.push('/property/list');
    }
    this.init();
    if (this.$route.params.parentType && this.$route.params.parentType === 'CLIENT' && this.$route.params.parentId) {
      this.saveStepClient({
        clientUid: this.$route.params.parentId + ''
      });
    }
  },
  watch: {
    getAddFlowSteps(newValue, oldValue) {
      if (newValue !== oldValue && newValue.length > 0) {
        this.availableSteps = newValue;
      }
    }
  },
  methods: {
    ...mapMutations("property", ["init", "moveStep", "saveStepClient"]),
    ...mapGetters("user", ["getCurrentUser"]),
    goToNextStep() {
      this.moveStep('next');
    },
    cancelStep() {
      this.$router.go(-1);
    },
    goBack() {
      this.moveStep('back');
    },
    async finish() {
      this.service.save(this.getSteps)
          .then(response => {
            if (response && response.success) {
              this.$emit('finish');
              this.moveStep('finish');
              this.completed = true;
              this.property_uid = response.property_uid;
              this.client_uid = response.client_uid;
            } else {
              this.$api.showToast(response.message, "error");
            }
          });
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    handleFinishButtonClick() {
      this.$emit("click", "property_add_finish")
    }
  }
}
</script>

<style scoped lang="scss">

.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

</style>
