<template>
  <div>
    <ModalDialog v-show="showRenameDlg && !readOnly" modalSize="small" :headerText="$t('documents.rename')" @close="cancelRename">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class=" mt-8">
            <InputText v-model="renameDocument.filename_wout_ext" ref="el_filename" @keyup="saveDocumentName"/>
          </div>
          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="cancelRename"/>
            <Button class="w-40" :text="$t('buttons.save')" @click="saveDocumentName"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="showUploadDlg && !readOnly" :headerText="$t('documents.upload_file')" @close="cancelUpload">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class=" mt-4">
            <div class="box flex justify-start items-center mb-4">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <label class="text ml-2">
                {{ $t('documents.info_box') }}

              </label>
            </div>
            <InputUpload ref="uploadInput" :label="$t('documents.select_files')" multiple="multiple" :isRequired="true"
                         :accept="allowedTypes"
                         :isBlackText="true" :showDragAndDrop="true" @select="fileSelect"
            />
          </div>
          <div class="mt-4" v-if="useDms === true && allowDatevDocTransfer === true">
            <InputCheck :text="$t('documents.upload_for_datev_dms')" :isChecked="datevDms" @check="dmsChecked"/>
          </div>
          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="cancelUpload"/>
            <Button class="w-40" :text="$t('buttons.save')" @click="upload"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="showSendDocumentDlg && !readOnly" :headerText="$t('documents.send_document_dlg.title')" @close="cancelSendDocument">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-2 text-left">
            {{ $t('documents.send_document_dlg.description') }}
          </div>
          <div class="mt-4">
            <p class="text-left mb-2 label">{{ $t('documents.send_document_dlg.personal_message') }}</p>
            <InputText bodyClass="non-resize-textarea w-full"
                       :isMultiline="true"
                       :rows="15"
                       v-model="emailTemplateMessage"
                       style="min-height: 300px;"
            />
          </div>

          <div class="mt-4">
            <p class="text-left mb-2 label">{{ $t('documents.send_document_dlg.email_recipient') }}</p>
            <div class="flex">
              <InputText
                class="email-recipient"
                v-model="email_recipient"
                :isEmail="true"
                :isError="email_recipient_error"
                :errorMessage="email_recipient_error_msg"
                placeholder=""
              />
              <Button
                class="w-40 mt-1 ml-2"
                :text="$t('documents.send_document_dlg.add_to')"
                :disabled="email_recipient === ''"
                @click="addEmail"/>
            </div>
          </div>

          <div class="added-emails">
            <div v-for="(addedEmail, index) in recipientEmails" :key="index" class="added-email">
              <div>
                {{ addedEmail }}
              </div>
              <div class="ml-3">
                <img class="icon-close" src="@/assets/cross_filter_gray.svg" @click="removeEmail(addedEmail)" />
              </div>
            </div>
          </div>

          <p class="text-left mt-4 mb-2 label">{{ $t('documents.send_document_dlg.attachment') }}</p>
          <div class="attachment-section">
            <div v-for="(attachment, index) in attachments" :key="index" class="attachment">
              <img :src="getIcon(attachment)">
              <div class="filename">
                {{ attachment.filename }}
              </div>
            </div>
          </div>

          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('buttons.cancel')" :secondary="true" @click="cancelSendDocument"/>
            <Button
              class="w-40"
              :text="$t('buttons.send')"
              :disabled="recipientEmails.length === 0"
              @click="sendDocument"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="showDuplicateDocumentDlg && !readOnly" :headerText="$t('documents.duplicate_dlg.title')" @close="closeDuplicateDocumentDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-2 text-left">
            {{ $t('documents.duplicate_dlg.description_1') }}
          </div>

          <div class="flex justify-around space-x-10 mt-4">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_1') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyName }}</p>
          </div>

          <div class="flex justify-around space-x-10">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_2') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyClientId }}</p>
          </div>

          <div class="flex justify-around space-x-10">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_3') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyClientName }}</p>
          </div>

          <p class="mt-8 w-full text-left">{{ $t('documents.duplicate_dlg.description_2') }}</p>
          <p class="w-full text-left">{{ $t('documents.duplicate_dlg.description_3') }}</p>


          <div class="mt-6 search-declaration-container">
            <!-- form tag just needed to prevent auto complete -->
            <form autocomplete="off">
              <input
                  :placeholder="$t('sidebar.search')"
                  v-model="searchDeclarationText"
                  @input="searchDeclaration"
                  type="text"
                  autocomplete="off"
                  :class="{searching_bg: searchingDeclaration}"
                  class="search-declaration pl-3 flex justify-start items-center w-full focus:outline-none"
              />
              <i class="pi pi-spin pi-spinner" v-show="searchingDeclaration"/>
            </form>
          </div>

          <div class="mt-2 declaration-container">
            <div
              v-for="(declaration, index) in propertyDeclarations"
              :key="index"
              class="flex items-center space-x-3 mt-2"
            >
              <RadioButton
                :value="declaration.prim_uid"
                v-model="selectedDeclarationId"
              />
              <span class="mt-1 label">{{ declaration.uid }}, {{ declaration.property_name }}</span>
            </div>
          </div>

          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('documents.duplicate_dlg.button_1')" :secondary="true" @click="closeDuplicateDocumentDlg"/>
            <Button
              class="w-40"
              :text="$t('documents.duplicate_dlg.button_2')"
              :disabled="selectedDeclarationId === ''"
              @click="duplicateDocument"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="showDuplicateDocumentDlg" :headerText="$t('documents.duplicate_dlg.title')" @close="closeDuplicateDocumentDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start">
          <div class="mt-2 text-left">
            {{ $t('documents.duplicate_dlg.description_1') }}
          </div>

          <div class="flex justify-around space-x-10 mt-4">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_1') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyName }}</p>
          </div>

          <div class="flex justify-around space-x-10">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_2') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyClientId }}</p>
          </div>

          <div class="flex justify-around space-x-10">
            <p class="textbold mt-4 w-full text-left">{{ $t('documents.duplicate_dlg.item_3') }}:</p>
            <p class="mt-4 w-full text-right">{{ propertyClientName }}</p>
          </div>

          <p class="mt-8 w-full text-left">{{ $t('documents.duplicate_dlg.description_2') }}</p>
          <p class="w-full text-left">{{ $t('documents.duplicate_dlg.description_3') }}</p>


          <div class="mt-6 search-declaration-container">
            <!-- form tag just needed to prevent auto complete -->
            <form autocomplete="off">
              <input
                  :placeholder="$t('sidebar.search')"
                  v-model="searchDeclarationText"
                  @input="searchDeclaration"
                  type="text"
                  autocomplete="off"
                  :class="{searching_bg: searchingDeclaration}"
                  class="search-declaration pl-3 flex justify-start items-center w-full focus:outline-none"
              />
              <i class="pi pi-spin pi-spinner" v-show="searchingDeclaration"/>
            </form>
          </div>

          <div class="mt-2 declaration-container">
            <div
              v-for="(declaration, index) in propertyDeclarations"
              :key="index"
              class="flex items-center space-x-3 mt-2"
            >
              <RadioButton
                :value="declaration.prim_uid"
                v-model="selectedDeclarationId"
              />
              <span class="mt-1 label">{{ declaration.uid }}, {{ declaration.property_name }}</span>
            </div>
          </div>

          <div class="flex mt-10 mb-8  justify-end space-x-4 ">
            <Button class="w-40" :text="$t('documents.duplicate_dlg.button_1')" :secondary="true" @click="closeDuplicateDocumentDlg"/>
            <Button
              class="w-40"
              :text="$t('documents.duplicate_dlg.button_2')"
              :disabled="selectedDeclarationId === ''"
              @click="duplicateDocument"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <AppGrid
        class="document-grid"
        v-if="!rendering"
        :grid-id="gridId"
        data-endpoint="document/list"
        default-sort-attr="uploaded_at"
        :default-sort-dir="-1"
        :columns="columns"
        :selectable="true"
        selection-data-key="prim_uid"
        :standard-filters="standardFilters"
        :menus="menus"
        :server-params="serverParams"
        :show-state-loader="false"
        :auto-load="false"
        :show-create-record="false"
        tax-software="false"
        @menu-clicked="handleMenuClick"
        @action-menu-click="handleGridAction"
        @col-click="handleColClick"
        @on-item-selected="handleItemSelected"
        @col-check="handleColCheck"
    />
  </div>
</template>

<script>

import AppGrid from "./AppGrid";
import {DocumentService} from "@/services/document.service";
import {documentColumns, documentMenus, documentStandardFilters, readOnlyDocumentMenus, readOnlyDocumentColumnMenus} from "@/core/constants";
import {mapGetters, mapMutations} from "vuex"
import RadioButton from 'primevue/radiobutton';

export default {
  name: "Document",
  components: {
    AppGrid,
    RadioButton
  },
  props: {
    gridId: {
      type: String,
      default: ""
    },
    parentType: {
      type: String,
      default: ""
    },
    parentId: {
      type: Number,
      default: 0
    },
    viewerBreadcrumbs: {
      type: Array,
      default: () => []
    },
    datevClient: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    parentObject: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      rendering: true,
      service: new DocumentService(),
      standardFilters: documentStandardFilters,
      columns: documentColumns,
      menus: documentMenus,
      serverParams: {
        "parentType": this.parentType,
        "parentId": this.parentId,
        "gridId": this.gridId
      },
      showUploadDlg: false,
      showSendDocumentDlg: false,
      showDuplicateDocumentDlg: false,
      showRenameDlg: false,
      renameDocument: {},
      files: [],
      allowedTypes: ".pdf,.xlsx,.docx,.pptx,.txt,.xml,.jpg,.jpeg,.png,.tiff,.tif",
      viewerAllowedTypes: ["pdf", "jpg", "jpeg", "gif", "png"],
      datevDms: false,
      useDms: false,
      emailTemplateMessage: '',
      email_recipient: '',
      recipientEmails: [],
      email_recipient_error: false,
      email_recipient_error_msg: '',
      sendDocumentData: {},
      attachments: [],
      searchingDeclaration: false,
      searchDeclarationText: '',

      propertyName: '',
      propertyClientName: '',
      propertyClientId: '',
      propertyDeclarations: [],
      selectedDeclarationId: '',
      duplicateDocumentData: {},
      allowDatevDocTransfer: true
    }
  },
  watch: {
    parentId(nv, ov) {
      if (parseInt(nv) > 0) {
        this.serverParams.parentId = nv;
        if (this.serverParams.parentType !== '') {
          this.service.setDefaultParams(this.serverParams);

          if (nv !== ov) {
            this.triggerReload(this.gridId);
          }
        }
      }
    },
    datevClient(nv) {
      this.rendering = true;
      this.updateColumns();
      this.$nextTick(() => this.rendering = false);
    }
  },
  created() {
    if (parseInt(this.serverParams.parentId) > 0 && this.serverParams.parentType !== '') {
      this.service.setDefaultParams(this.serverParams)
    }

    this.allowDatevDocTransfer = this.getCurrentUser().permissions
      && typeof this.getCurrentUser().permissions.allow_datev_doc_transfer !== 'undefined'
      && this.getCurrentUser().permissions.allow_datev_doc_transfer === '1';

    if (this.allowDatevDocTransfer === false) {
      for (const i in this.columns) {
        if (this.columns[i].field === 'datev_transfered_at' || this.columns[i].field === 'datev_dms') {
          delete(this.columns[i]);
        }
      }
    }

    this.updateColumns();

    if (this.readOnly) {
      this.menus = readOnlyDocumentMenus;
      if (this.columns[0].menus) {
        this.columns[0].menus = readOnlyDocumentColumnMenus;
      }
    }

    if (this.gridId === 'property_documents') {
      let duplicateFieldExist = false;
      this.menus.map((o) => {
        if (o.id === 'd.duplicate') {
          duplicateFieldExist = true;
        }
      });

      if (!duplicateFieldExist) {
        this.menus.push({
          id: 'd.duplicate',
          icon: require('@/assets/duplicate_green.svg'),
          icon_disabled: require('@/assets/duplicate_gray.svg'),
          title: this.$t('documents.menu.duplicate'),
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        });
      }
    }

    if (this.getCurrentUser().settings.use_send_document) {
      this.emailTemplateMessage = this.formatTemplate(this.getCurrentUser().settings.send_document);
    } else {
      this.emailTemplateMessage = this.$t('settings.taxCategories.send_document_msg_tmp');
    }

    this.rendering = false;
  },
  methods: {
    ...mapMutations("grid", ["triggerReload"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapGetters("declaration", ["getDeclarationClientEmail"]),
    updateColumns() {
      this.useDms = this.datevClient && this.getCurrentUser().settings.tax_office_software === 'datev' && this.getCurrentUser().settings.datev_dms_enabled === '1';
      this.columns = documentColumns.filter((o) => {
        if (o.field === 'datev_dms' || o.field === 'datev_transfered_at') {
          return this.useDms;
        } else if (o.field === 'visible_in_client_portal') {
          return !this.$isBusiness && this.parentType === 'PROPERTY';
        } else {
          return true;
        }
      });

      if(this.readOnly) {
        this.columns = this.columns.map((o) => {
          if(o.field === 'visible_in_client_portal') {
            o.disabledEl = true;
          }

          return o;
        });
      }
    },
    openViewer(documentId) {
      this.$router.push({
        name: "DocumentViewer",
        params: {
          'id': documentId,
          'parentType': this.parentType,
          'parentId': this.parentId,
          'readOnly': this.readOnly,
          'breadcrumbs': this.viewerBreadcrumbs
        }
      });
    },
    handleGridAction(args) {
      switch (args.menu.id) {
        case 'd.ga.options.view':
          if (args.row.prim_uid) {
            if (args.row.file_ext && this.viewerAllowedTypes.includes(args.row.file_ext)) {
              this.openViewer(args.row.prim_uid)
            } else {
              this.service.download(args.row.prim_uid)
            }
          }
          break;
        case 'd.ga.options.rename':
          if (args.row.prim_uid && !this.readOnly) {
            this.renameDocument = args.row;
            this.showRenameDlg = true;
            this.$nextTick(() => this.$refs.el_filename.setFocus())
          }
          break;
      }
    },
    handleMenuClick(args) {
      if (this.readOnly && args.menuId !== 'd.view' && args.menuId !== 'd.duplicate') {
        return ;
      }

      switch (args.menuId) {
        case "d.upload":
          this.$refs.uploadInput.clear();
          this.files = [];
          this.showUploadDlg = true;
          break;
        case "d.view":
          if (args.data[0].prim_uid) {
            if (args.data[0].file_ext && this.viewerAllowedTypes.includes(args.data[0].file_ext)) {
              this.openViewer(args.data[0].prim_uid)
            } else {
              this.service.download(args.data[0].prim_uid)
            }
          }
          break;
        case "d.sendDocument":
          this.recipientEmails = [];
          this.recipientEmails.push(this.getDeclarationClientEmail());

          this.attachments = args.data;
          let documentIds = [];
          args.data.map((o) => {
            documentIds.push(o.prim_uid);
          });
          this.sendDocumentData.documentIds = documentIds;
          this.showSendDocumentDlg = true;
          break;

        case "d.duplicate":
          let docIds = [];
          args.data.map((o) => {
            docIds.push(o.prim_uid);
          });

          this.duplicateDocumentData.documentIds = docIds;

          this.propertyName = this.parentObject.step2.name;
          this.propertyClientName = this.parentObject.clientName;
          this.propertyClientId = this.parentObject.clientId;
          this.propertyDeclarations = this.parentObject.declarations;

          this.showDuplicateDocumentDlg = true;
          break;
      }
    },
    handleColClick(args) {
      if(this.readOnly) {
        return ;
      }

      if (args.field === 'filename' && args.row.prim_uid) {
        this.service.download(args.row.prim_uid);
      } else if(args.field === 'datev_transfered_at') {
        this.$router.push({
          "name": "Datev",
          "params": {
            "comp": "dms",
            "hideInfo": true
          }
        });
      }
    },
    handleItemSelected(args) {
      if(!this.readOnly) {
        let data = {
          documentId: args.item.primUid,
          datevDms: args.item.code
        };
        this.service.update(data);
      }
    },
    handleColCheck(args) {
      if(!this.readOnly) {
        let data = {
          documentId: args.prim_uid,
          visibleInClientPortal: args.visible_in_client_portal
        };

        this.service.update(data, false).then((res) => {
          if (res) {
            this.triggerReload(this.gridId);
          }
        });
      }
    },
    fileSelect(e) {
      this.files = [];
      if (e.files.length > 0) {
        this.files = e.files;
      }
    },
    async upload() {
      if(this.readOnly) {
        return ;
      }

      if (this.files.length > 0) {
        if (await this.service.upload(this.files, this.datevDms)) {
          this.showUploadDlg = false;
          this.$emit('document-uploaded');
        }
        this.triggerReload(this.gridId);
      }
    },
    cancelUpload() {
      this.showUploadDlg = false
    },
    cancelSendDocument() {
      this.showSendDocumentDlg = false
    },
    closeDuplicateDocumentDlg() {
      this.showDuplicateDocumentDlg = false
    },
    cancelRename() {
      this.showRenameDlg = false;
    },
    saveDocumentName() {
      if(!this.readOnly) {
        let data = {
          documentId: this.renameDocument.prim_uid,
          fileName: this.renameDocument.filename_wout_ext + '.' + this.renameDocument.file_ext
        };
        this.service.update(data).then((res) => {
          if (res) {
            this.triggerReload(this.gridId);
            this.showRenameDlg = false;
          }
        });
      }
    },
    dmsChecked(checked) {
      if(!this.readOnly) {
        this.datevDms = !!checked;
      }
    },
    formatTemplate(str) {
      var regex = /<br\s*[\/]?>/gi;
      return str.replace(regex, "");
    },
    validateEmail(email) {
      const reg = /^[A-Za-z\d\.\_\%\+\-]+@([A-Za-z\d-]+\.)+[A-Za-z]{2,7}$/i;
      return reg.test(email.trim());
    },
    addEmail() {
      this.email_recipient_error = false;
      if (this.email_recipient !== '') {
        if (!this.validateEmail(this.email_recipient)) {
          this.email_recipient_error = true;
          this.email_recipient_error_msg = this.$t('general.errors.invalid_email');
        } else {
          this.recipientEmails.push(this.email_recipient);
          this.email_recipient = '';
        }
      }
    },
    removeEmail(email) {
      this.recipientEmails = this.recipientEmails.filter((o) => {
        return o !== email
      });
    },
    async sendDocument() {
      this.sendDocumentData.emailTemplateMessage = this.emailTemplateMessage;
      this.sendDocumentData.recipientEmails = this.recipientEmails;

      if (await this.service.sendDocument(this.sendDocumentData)) {
        this.showSendDocumentDlg = false;
      }
    },
    getIcon(attachment) {
      switch (attachment.file_ext) {
        case "xls,xlsx":
          return require('@/assets/document_xls.svg')
        case "doc,docx":
          return require('@/assets/document_doc.svg')
        case "pdf":
          return require('@/assets/document_pdf.svg')
        default:
          return require('@/assets/document_generic.svg')
      }
    },

    searchDeclaration(event) {
      if (this.searchDeclarationText.length > 0) {
        this.searchingDeclaration = true;
        setTimeout(() => this.triggerDeclarationSearch(), 200);
      } else {
        this.propertyDeclarations = this.parentObject.declarations;
        this.searchingDeclaration = false;
      }
    },
    triggerDeclarationSearch() {
      const allDeclarations = this.$api.parse_object_data(this.parentObject.declarations);

      this.propertyDeclarations = [];

      allDeclarations.map((o) => {
        if (o.uid.includes(this.searchDeclarationText)) {
          this.propertyDeclarations.push(o);
        }
      });

      this.searchingDeclaration = false;
    },

    async duplicateDocument() {
      this.duplicateDocumentData.parentType = this.parentType;
      this.duplicateDocumentData.parentId = this.parentId;
      this.duplicateDocumentData.selectedDeclarationId = this.selectedDeclarationId;
      if (await this.service.duplicateDocument(this.duplicateDocumentData)) {
        this.showDuplicateDocumentDlg = false;

        this.selectedDeclarationId = '';
        this.duplicateDocumentData.selectedDeclarationId = '';
      }
    },
  }
}
</script>
<style lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}
.document-grid {
  padding-bottom: 15px !important;

  ::v-deep .table-container {
    height: calc(100vh - 410px) !important;
  }
}
.email-recipient {
  min-width: 250px;
}
.added-emails {
  margin-top: 15px;
  min-height: 50px;
  padding: 10px;
  border: 1px solid rgb(34, 157, 86);
  border-radius: 3px;
  display: flex;
  flex-flow: wrap;
}
.added-email {
  padding: 5px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  margin: 3px;
  border-radius: 5px;
}
.icon-close {
  cursor: pointer;
}
.attachment-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.attachment {
  display: flex;
  margin: 5px;
  max-width: 250px;
}
.attachment img {
  margin-right: 5px;
  width: 18px;
  height: 20px;
}
.attachment .filename {
  width: 100%;
  word-break: break-all;
}

.search-declaration-container {
  position: relative;

  .pi-spinner {
    position: absolute;
    right: 25px;
    color: #fff;
    top: 8px;
  }

  .searching_bg {
    background: none;
  }
}

.search-declaration {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid white;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid white;
  }
}
</style>
