<template>
  <div class="step_box">
    <div v-show="fetchingClientDetail">
      <div class="mt-20 flex flex-col container h-150">
        <div class="flex flex-col w-full loading-container">
          <div class="spin"></div>
        </div>
      </div>
      <div class="flex flex-col w-full h-full">
        <p class="loading-text">{{ $t('clients.datev_import.steps.step4.fetching_client_details') }}</p>
        <p class="loading-text" v-show="selectedClientCount > 0">
          {{ $t('clients.datev_import.steps.step4.client_selected') }} {{ selectedClientCount }}</p>
        <p class="loading-text" v-show="detailsFetched > 0">
          {{ $t('clients.datev_import.steps.step4.client_details_fetched') }} {{ detailsFetched }}</p>
        <p class="loading-text" v-show="processedClients > 0">
          {{ $t('clients.datev_import.steps.step4.clients_processed') }} {{ processedClients }}</p>
      </div>
    </div>
    <div v-show="!fetchingClientDetail">
      <div class="datev_client_import">
        <DataGrid v-if="renderGrid"
                  :grid-id=gridId
                  :local-data="datevClients"
                  :columns="columns"
                  default-sort-attr="number"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
        />
      </div>

      <div class="flex justify-around space-x-10 mt-2 "/>
      <div class="flex justify-end space-x-4 mr-2 mt-10 mb-20">
        <Button class="w-40" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-40" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {ClientService} from "@/services/client.service";
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import _find from "lodash/find";
import {BranchService} from "@/services/branch.service";
import {S3Service} from "@/services/s3.service";

export default {
  name: "Step4",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(nv) {
      if (nv) {
        let branchService = new BranchService();
        branchService.listMyBranches().then((list) => {
          let branchList = list.map((o) => {
            return {
              "name": o.name,
              "code": o.uid
            }
          });
          branchList.unshift({
            "name": this.$t('clients.datev_import.do_not_import'),
            "code": "0"
          })
          this.columns = this.columns.map((o) => {
            if (o.field === 'branch') {
              o.dataSource = branchList;
            }

            return o;
          });
          this.renderGrid = true;
        });

        this.loadDatevClients();
      }
    }
  },
  data() {
    return {
      service: new ClientService(),
      s3Service: new S3Service(),
      gridId: "datev-client-import",
      renderGrid: false,
      fetchingClientDetail: false,
      selectedClientCount: 0,
      detailsFetched: 0,
      processedClients: 0,
      chunkSize: 50,
      selectedClients: [],
      columns: [
        {
          field: 'number',
          header: this.$t('clients.datev_import.steps.step4.columns.number'),
          isChecked: true
        }, {
          field: 'consultant_number',
          header: this.$t('clients.datev_import.steps.step4.columns.consultant_number'),
          isChecked: true
        }, {
          field: 'client_type_icon',
          header: this.$t('clients.datev_import.steps.step4.columns.client_type'),
          isChecked: true,
          image: true,
          bodyClass: 'client_type',
          headerClass: 'text-center-imp',
          tooltip: (row) => {
            return this.getColTip('client_type', row);
          }
        }, {
          field: 'name',
          header: this.$t('clients.datev_import.steps.step4.columns.client_name'),
          isChecked: true
        }, {
          field: 'new',
          header: this.$t('clients.datev_import.steps.step4.columns.new_record'),
          isChecked: true,
        }, {
          field: 'branch',
          header: this.$t('clients.datev_import.steps.step4.columns.branch'),
          isChecked: true,
          colElType: "select",
          dataSource: [],
          model: 'branch_uid',
          disableFn: (row) => {
            return this.disableBranchSelect(row);
          }
        }
      ],
      datevClients: []
    }
  },
  methods: {
    ...mapMutations("datev_import", ["saveResult", "resetBusinessPartner", "saveClients"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapGetters("datev_import", ["getClients", "getSelectedBusinessPartner", "getOrgInfo"]),
    ...mapMutations("grid", ["triggerReload"]),
    getColTip(col, row) {
      if (col === 'client_type' && row.client_type) {
        let found = _find(this.$t('clients.client_types'), {"code": row.client_type});
        if (found) {
          return found.name;
        }
      }
      return '';
    },
    disableBranchSelect(row) {
      return !!(row.prim_uid && parseInt(row.prim_uid) > 0);
    },
    loadDatevClients() {
      this.datevClients = this.getClients();

      this.triggerReload(this.gridId);
    },
    submitNext() {
      this.selectedClients = this.datevClients.filter((o) => {
        if (parseInt(o.branch_uid) > 0) {
          return o;
        }
      });

      if (this.selectedClients.length > 0) {
        this.$loading.show();
        let selectedClients = [];
        for(let datevClient of this.selectedClients) {
          selectedClients.push({
            "id": datevClient.id,
            "name": datevClient.name,
            "branch_uid": datevClient.branch_uid,
            "existing_etag": datevClient.existing_etag,
            "data_repair": datevClient.data_repair
          });
        }

        let s3Data = {};
        let business_partner_id = this.getSelectedBusinessPartner();
        if(business_partner_id) {
          s3Data['business_partner_id'] = business_partner_id;
        } else {
          let org_info = this.getOrgInfo();
          if(org_info.business_partners && org_info.business_partners.length === 1) {
            s3Data['business_partner_id'] = org_info.business_partners[0]['number'];
          } else if(org_info.id) {
            s3Data['datev_org_id'] = org_info.id
          }
        }
        s3Data['selectedClients'] = selectedClients;
        this.s3Service.upload({
          data: s3Data,
          fileName: 'selected_client.json'
        }).then((res) => {
          if(res.success) {
            this.service.start_datev_client_import({
              "s3Path": res.s3Path
            }).then((response) => {
              this.$loading.hide();
              if(response.data.success) {
                this.saveResult({
                  "selectedClientCount": s3Data['selectedClients'].length,
                  "notificationId": response.data.notificationId ? parseInt(response.data.notificationId) : 0
                });
                this.$emit('finish');
              } else {
                this.$api.showToast(response.data.message, "error")
              }
            });
          } else {
            this.$loading.hide();
            this.$api.showToast(this.$t('general.errors.unknownError'), "error")
          }
        })
      } else {
        this.$api.showToast(this.$t('clients.datev_import.steps.step4.no_clients_selected'), "error")
      }
    },
    goBack() {
      this.resetBusinessPartner();
      this.$emit("goBack");
    }
  }
}
</script>

<style scoped lang="scss">
.datev_client_import::v-deep {
  td.client_type div.cell-content img.column_icon {
    margin-left: auto;
    margin-right: auto;
  }

  .table-container {
    height: calc(100vh - 370px);
  }
}

.container {
  position: relative;
}

.loading-text {
  color: #9d9d9d;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 75%;
  margin-top: 45px;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}
</style>
