<template>
  <div
    class="flex flex-col mb-1 relative"
    :class="sticky ? 'sticky-header' : ''"
  >
    <div class="flex flex-col xl:flex-row">
      <div class="grid grid-cols-1 w-full xl:w-3/5 mt-3 mr-6 content-between">
        <div class="w-full">
          <Breadchrumbs
            class="w-full"
            :breadcrumbs="breadcrumbs"
            v-if="breadcrumbs.length > 0"
          />
          <div v-else class="gap w-full"></div>
        </div>

        <div class="flex">
          <p class="header-component ">{{ title }} </p>
          <!--      help icon with HTML tooltip/popover-->
          <VDropdown
            v-if="popoverTexts.length > 0"
            :showTriggers="['click','hover']"
            :popperTriggers="['hover','click']"
            :hideTriggers="['click']"
          >
            <!-- This will be the popover target (for the events and position) -->
            <img
              class="help_icon self-center mt-1  cursor-pointer"
              :src="getAssetPath('help_green.svg')"
            />
            <!-- This will be the content of the popover -->
            <template #popper>
              <div class="tooltip-content">
                <p
                  class="space-y-2 mb-1 popover-text"
                  v-for="text in popoverTexts"
                  v-bind:key="text"
                >
                  {{ text }}
                </p>

                <h2 style="line-height:80%; margin-top: 5px;">
                  <a
                    class="popover-text"
                    :href="popoverLink"
                    target="_blank"
                  >
                    {{ popoverLink }}
                  </a>
                </h2>
              </div>
            </template>
          </VDropdown>
        </div>

        <img
          :src="logo"
          :class="logoClass"
          v-if="logo"
        />

        <!--        declaration pagination-->
        <div
          class="flex justify-start items-center page-number mr-2"
          v-if="pagination"
        >
          {{ currentRecord }} {{ $t('general.from') }} {{ totalRecord }}
          <img
            class="up-arrow"
            :src="getLeftIcon"
            @click="decrementCounter"
          />
          <img
            class="down-arrow"
            :src="getRightIcon"
            @click="incrementCounter"
          />
        </div>
      </div>

      <div class="flex justify-start items-center w-full xl:w-2/5 py-3 px-2 mt-6 mr-6 rounded bg-gray_light" v-if="info">
        <img class="info__icon" src="@/assets/info_gray.svg"/>
        <label class="info__text">{{ info }}</label>
      </div>
    </div>

    <hr
      :style="{'margin-top':getMargin}"
      class="hr-line-user mr-7"
      v-show="showBottomLine"
    >
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    sticky: {
      type: Boolean,
      default: false
    },
    showBottomLine: {
      type: Boolean,
      default: true
    },
    popoverTexts: {
      type: Array(),
      default: () => [],
    },
    popoverLink: {
      type: String,
      default: ''
    },
    logoClass: {
      type: String,
      default: 'logo mb-2'
    },
    pagination: {
      type: Boolean,
      default: false
    },
    totalRecordCount: {
      type: Number,
      default: 7
    },
    currentRecordIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getMargin() {
      return this.logo ? '4px' : '25px'
    },
    getLeftIcon() {
      return (this.currentRecord > 1 || this.currentRecord === this.totalRecord) ? this.getAssetPath('pagination_arrow_active.svg') : this.getAssetPath('pagination_arrow.svg')
    },
    getRightIcon() {
      return (this.currentRecord < this.totalRecord) ? this.getAssetPath('pagination_arrow_active.svg') : this.getAssetPath('pagination_arrow.svg')
    }
  },
  watch: {
    totalRecordCount(nv) {
      this.totalRecord = parseInt(nv);
    },
    currentRecordIndex(nv) {
      this.currentRecord = parseInt(nv);
    }
  },
  mounted() {
    this.totalRecord = this.totalRecordCount
    this.currentRecord = this.currentRecordIndex + 1
  },
  data() {
    return {
      totalRecord: 0,
      currentRecord: 0
    }
  },
  methods: {
    incrementCounter() {
      if (this.currentRecord < this.totalRecord) {
        this.currentRecord++
        this.$emit('page', this.currentRecord);
      }
    },
    decrementCounter() {
      if (this.currentRecord > 1 || this.currentRecord === this.totalRecord) {
        this.currentRecord--
        this.$emit('page', this.currentRecord);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 56px;
  height: 55px;
}

.wk-logo {
  width: 257px;
  height: 55px;
}

.gap {
  margin-top: 58px;
}

.help_icon {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.up-arrow {
  transform: rotate(270deg);
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.down-arrow {
  transform: rotate(90deg);
  margin-top: 3px;
  margin-left: 7px;
  cursor: pointer;
}
.page-number {
  font-size: 13px;
  color: #aeaeae;
  z-index: 1;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}
</style>
