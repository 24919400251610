<template>
  <div class="pt-1">
    <table class="table-fixed min-w-full">
      <thead>
        <tr>
          <th class="w-1/5 font-normal text-sm text-gray_dark">
            <div class="flex flex-col justify-end h-32 text-left">
                <span v-if="!$isBusiness" class="pb-3">{{ $t('settings.billing.billing_base_price') }}</span>
                <span v-else class="pb-3">{{ $t('settings.billing.billing_base_price_business') }}</span>
            </div>
          </th>

          <th
            v-for="plan in plans"
            class="w-1/5"
            :class="getColumnClassesForPlan(plan)"
          >
            <div v-if="getMostPopularPlanIds().includes(plan.prim_uid)" class="ribbon">
              <span class="text-xs text-white">{{ $t('billing_trial.professional_lable') }}</span>
            </div>

            <div class="font-semibold text-lg mb-3">{{ plan.plan_name }}</div>
            <div class="font-normal">
                <span class="text-3xl">{{ plan.plan_price }}&nbsp;€&nbsp;</span><span class="font-normal">/&nbsp;{{ $t('billing_trial.month') }}</span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="feature in features"
          v-show="feature.isVisible"
        >
          <th>
            <FeatureHover
              :label="feature.featureHover.label"
              :title="feature.featureHover.title"
              :subtitle="feature.featureHover.subtitle"
              :list="feature.featureHover.list"
              :imagePath="feature.featureHover.imagePath"
            />
          </th>

          <td
            v-for="plan in plans"
            :class="getColumnClassesForPlan(plan)"
          >
            <span v-if="feature.isBoolean">
              <img
                v-if="parseInt(plan[feature.feature]) === 1 || parseInt(plan[feature.feature]) !== feature.emptyValue"
                :src="getAssetPath('plan_tick.svg')"
              >
              <img v-else :src="getAssetPath('plan_cross.svg')">
            </span>

            <span v-else-if="feature.emptyValueText !== null">
              <span v-if="plan[feature.feature] === feature.emptyValue">{{ feature.emptyValueText }}</span>
              <span v-else>{{ plan[feature.feature] }}{{ feature.valueLabel }}</span>
            </span>

            <span v-else>
              {{ plan[feature.feature] }}{{ feature.valueLabel }}
            </span>
          </td>
        </tr>

        <tr>
          <th></th>
          <td
            v-for="plan in plans"
            :class="getColumnClassesForPlan(plan)"
          >
            <div>
              <Button
                class="p-4"
                :text="getBuyPackageLabel(plan)"
                :disabled="isCurrentPlan(plan.prim_uid) && !subscriptionExpired"
                :secondary="isCurrentPlan(plan.prim_uid) && !subscriptionExpired"
                @click="selectPlan(plan)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <Dialog
      class="plan-downgrade-confirmation-modal"
      :modal="true"
      :visible.sync="isPlanDowngradeConfirmationVisible"
      :contentStyle="{flex: '1'}"
      :header="$t('confirmation_dialog_plan_downgrade.title')"
      @hide="this.closeDowngradeConfirmationModal"
    >
      <div class="flex flex-col mt-2">
        <label class="text-left">{{ $t('confirmation_dialog_plan_downgrade.message') }}</label>
      </div>

      <div class="flex flex-col mt-5 space-y-4">
        <div
          v-for="feature in features"
          v-show="downgradeLostFeatures.includes(feature.feature) && feature.isVisible"
          class="flex justify-items-center items-center space-x-3"
        >
          <img
            class="icon flex flex-col"
            :src="require('@/assets/delete.svg')"
          />

          <label class="text-left text_bold">
            <FeatureHover
              :label="getFeatureDowngradeLabel(feature)"
              :title="feature.featureHover.title"
              :subtitle="feature.featureHover.subtitle"
              :list="feature.featureHover.list"
              :imagePath="feature.featureHover.imagePath"
            />
          </label>
        </div>
      </div>

      <div class="flex flex-col mt-5">
        <InputCheck
          class="mt-5"
          :text="$t('confirmation_dialog_plan_downgrade.checkbox_title')"
          :isChecked="isPlanDowngradeConfirmed"
          @check="(checkEvent) => isPlanDowngradeConfirmed = checkEvent"
        />
      </div>

      <template v-slot:footer>
        <div class="flex flex-row-reverse">
          <Button
            :text="$t('confirmation_dialog_plan_downgrade.buttons.save')"
            :secondary="true"
            :disabled="!isPlanDowngradeConfirmed"
            @click='downgradePackage(downgradeSelectedPlan.prim_uid)'
          />
          <Button
            class="mr-2"
            :text="$t('confirmation_dialog_plan_downgrade.buttons.cancel')"
            @click="isPlanDowngradeConfirmationVisible = false"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import FeatureHover from '@/components/Billing/FeatureHover.vue';

import { BillingService } from '@/services/billing.service';

import { mapGetters } from 'vuex';

export default {
  name: 'SelectPackageTable',
  emits: ['downgraded', 'upgraded'],
  components: {
    FeatureHover,
    Dialog,
  },
  props: {
    changeSubscriptionAfterEndTerm: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      billingService: new BillingService(),
      plans: [],

      downgradeSelectedPlan: null,
      isPlanDowngradeConfirmationVisible: false,
      isPlanDowngradeConfirmed: false,

      features: [
        {
          feature: 'elster_price',
          valueLabel: this.$t('billing_trial.labels.elster_price'),
          emptyValue: null,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.elster_price'),
            title: this.$t('billing_trial.gifs.elster_price.title'),
            subtitle: this.$t('billing_trial.gifs.elster_price.subtitle'),
            list: null,
            imagePath: 'gifs/elster.gif',
          },
          isBoolean: false,
          isVisible: true
        },
        {
          feature: 'mass_appeal_price',
          valueLabel: this.$t('billing_trial.labels.mass_appeal_price'),
          emptyValue: '0,00',
          emptyValueText: this.$t('billing_trial.no_mass_appeal'),
          featureHover: {
            label: this.$t('billing_trial.mass_appeal_price'),
            title: this.$t('billing_trial.gifs.mass_appeal_price.title'),
            subtitle: this.$t('billing_trial.gifs.mass_appeal_price.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: true
        },
        {
          feature: 'minimum_turnover',
          valueLabel: this.$t('billing_trial.labels.minimum_turnover'),
          emptyValue: '-',
          emptyValueText: this.$t('billing_trial.no_min_turnover'),
          featureHover: {
            label: this.$t('billing_trial.minimum_turnover'),
            title: this.$t('billing_trial.gifs.minimum_turnover.title'),
            subtitle: this.$t('billing_trial.gifs.minimum_turnover.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: !this.$isBusiness
        },
        {
          feature: 'contract_period',
          valueLabel: this.$t('billing_trial.labels.contract_period'),
          emptyValue: '',
          emptyValueText: '12 ' + this.$t('billing_trial.labels.contract_period'),
          featureHover: {
            label: this.$t('billing_trial.contract_period'),
            title: this.$t('billing_trial.gifs.contract_period.title'),
            subtitle: this.$t('billing_trial.gifs.contract_period.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: true
        },
        {
          feature: 'number_of_plot',
          valueLabel: null,
          emptyValue: 'Unlimited',
          emptyValueText: this.$t('billing_trial.Unlimited'),
          featureHover: {
            label: this.$t('billing_trial.number_of_plot'),
            title: null,
            subtitle: null,
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: this.$isBusiness
        },
        {
          feature: 'user_or_employee',
          valueLabel: null,
          emptyValue: 'Unlimited',
          emptyValueText: this.$t('billing_trial.Unlimited'),
          featureHover: {
            label: this.$t('billing_trial.user_or_employee'),
            title: null,
            subtitle: null,
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: this.$isBusiness
        },
        {
          feature: 'location',
          valueLabel: null,
          emptyValue: 'Unlimited',
          emptyValueText: this.$t('billing_trial.Unlimited'),
          featureHover: {
            label: this.$t('billing_trial.location'),
            title: null,
            subtitle: null,
            list: null,
            imagePath: null,
          },
          isBoolean: false,
          isVisible: this.$isBusiness
        },
        {
          feature: 'function_range',
          valueLabel: null,
          emptyValue: '',
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.function_range'),
            title: null,
            subtitle: null,
            list: null,
            imagePath: null,
          },
          isBoolean: true,
          isVisible: this.$isBusiness
        },
        {
          feature: 'maximum_user',
          valueLabel: '',
          emptyValue: null,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.maximum_user'),
            title: this.$t('billing_trial.gifs.maximum_user.title'),
            subtitle: null,
            list: null,
            imagePath: 'gifs/maximum_user.gif',
          },
          isBoolean: false,
          isVisible: !this.$isBusiness
        },
        {
          feature: 'maximum_branch',
          valueLabel: '',
          emptyValue: null,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.maximum_branch'),
            title: this.$t('billing_trial.gifs.maximum_branch.title'),
            subtitle: null,
            list: null,
            imagePath: 'gifs/niederlassungen.gif',
          },
          isBoolean: false,
          isVisible: !this.$isBusiness
        },
        {
          feature: 'feature_change_management',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.feature_change_management'),
            title: this.$t('billing_trial.gifs.feature_change_management.title'),
            subtitle: this.$t('billing_trial.gifs.feature_change_management.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: true,
          isVisible: true
        },
        {
          feature: 'feature_mass_ops',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.feature_mass_ops'),
            title: this.$t('billing_trial.gifs.feature_mass_ops.title'),
            subtitle: this.$t('billing_trial.gifs.feature_mass_ops.subtitle'),
            list: [
              this.$t('billing_trial.gifs.feature_mass_ops.line1'),
              this.$t('billing_trial.gifs.feature_mass_ops.line2'),
              this.$t('billing_trial.gifs.feature_mass_ops.line3'),
              this.$t('billing_trial.gifs.feature_mass_ops.line4'),
              this.$t('billing_trial.gifs.feature_mass_ops.line5'),
              this.$t('billing_trial.gifs.feature_mass_ops.line6'),
              this.$t('billing_trial.gifs.feature_mass_ops.line7'),
            ],
            imagePath: 'gifs/massenverarbeitung.gif',
          },
          isBoolean: true,
          isVisible: true
        },
        {
          feature: 'feature_deadline_control',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.feature_deadline_control'),
            title: this.$t('billing_trial.gifs.feature_deadline_control.title'),
            subtitle: this.$t('billing_trial.gifs.feature_deadline_control.subtitle'),
            list: null,
            imagePath: 'gifs/fristenkontrolle.gif',
          },
          isBoolean: true,
          isVisible: true
        },
        {
          feature: 'feature_mass_notice_review',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.feature_mass_notice_review'),
            title: this.$t('billing_trial.gifs.feature_mass_notice_review.title'),
            subtitle: this.$t('billing_trial.gifs.feature_mass_notice_review.subtitle'),
            list: null,
            imagePath: 'gifs/mass_notice_review.gif',
          },
          isBoolean: true,
          isVisible: true
        },
        {
          feature: 'feature_expert_webinar',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.feature_expert_webinar'),
            title: this.$t('billing_trial.gifs.feature_expert_webinar.title'),
            subtitle: this.$t('billing_trial.gifs.feature_expert_webinar.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: true,
          isVisible: !this.$isBusiness
        },
        {
          feature: 'fino_tc_forum',
          valueLabel: '',
          emptyValue: 0,
          emptyValueText: null,
          featureHover: {
            label: this.$t('billing_trial.fino_tc_forum'),
            title: this.$t('billing_trial.gifs.fino_tc_forum.title'),
            subtitle: this.$t('billing_trial.gifs.fino_tc_forum.subtitle'),
            list: null,
            imagePath: null,
          },
          isBoolean: true,
          isVisible: !this.$isBusiness
        },
      ],
    }
  },
  mounted() {
    this.fetchPlans();
  },
  computed: {
    subscriptionExpired() {
      if (this.getCurrentUser().subscriptionExpired) {
        return true;
      }

      return false;
    },
    hasActivePlan() {
      if (this.subscriptionExpired) {
        return false;
      }

      return parseInt(this.getCurrentUser().plan_uid) > 0;
    },
    downgradeLostFeatures() {
      const currentPlan = this.plans.find((planEntry) => {
        return this.isCurrentPlan(planEntry.prim_uid);
      });

      const featureKeys = [
        'number_of_plot',
        'user_or_employee',
        'location',
        'feature_mass_ops',
        'feature_deadline_control',
        'feature_mass_notice_review',
        'feature_expert_webinar',
        'fino_tc_forum',
      ];

      const downgradeLostFeatures = [];
      featureKeys.forEach((featureKey) => {
        if (
          typeof currentPlan === 'undefined'
          || typeof this.downgradeSelectedPlan === 'undefined'
          || this.downgradeSelectedPlan === null
        ) {
          return;
        }

        const feature = this.getFeatureByKey(featureKey);

        if (feature.isBoolean) {
          const isFeatureInCurrentPlan = this.doesPlanContainFeature(currentPlan, featureKey);
          const isFeatureInSelectedPlan = this.doesPlanContainFeature(this.downgradeSelectedPlan, featureKey);

          if (isFeatureInCurrentPlan && !isFeatureInSelectedPlan) {
            downgradeLostFeatures.push(featureKey);
          }

          return;
        }

        if (this.doesNewPlanHaveLessOfFeature(currentPlan, this.downgradeSelectedPlan, featureKey)) {
          downgradeLostFeatures.push(featureKey);
        }
      });

      return downgradeLostFeatures;
    },
    currentPlan() {
      return this.plans.find((planEntry) => {
        return this.isCurrentPlan(planEntry.prim_uid);
      });
    }
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    fetchPlans() {
      this.billingService.listAll(true).then((list) => {
        // Current AB Testing for Basis plan on-hold since 2024-08-21
        /*const planAbTestingSince = process.env.VUE_APP_PLAN_AB_TESTING_TIMESTAMP;

        if (
          planAbTestingSince > 0
          && parseInt(this.getCurrentUser().ab_testing_enabled) === 1
          && this.getCurrentUser().account_created_at > planAbTestingSince
        ) {
          this.plans = list.filter((plan) => {
            return plan.plan_name !== 'Basis';
          });
          return;
        }*/

        this.plans = list;
      })
    },
    getBuyPackageLabel(plan) {
      if (this.isCurrentPlan(plan.prim_uid) && !this.subscriptionExpired) {
        return this.$t('billing_trial.current_package');
      }

      if (plan.upgradable && !this.subscriptionExpired) {
        return this.$t('billing_trial.upgrade');
      }

      if (this.subscriptionExpired) {
        return this.$t('billing_trial.submit_btn');
      }

      if (this.hasActivePlan) {
        return this.$t('billing_trial.downgrade');
      }

      return this.$t('billing_trial.select');
    },
    isCurrentPlan(planId) {
      planId = parseInt(planId);
      const currentPlanId = parseInt(this.getCurrentUser().plan_uid);

      return planId === currentPlanId;
    },
    getColumnClassesForPlan(plan) {
      let columnClasses = [];

      if (
        (this.isCurrentPlan(plan.prim_uid) && !this.subscriptionExpired)
        || (
          this.getMostPopularPlanIds().includes(plan.prim_uid)
          && (this.subscriptionExpired || this.getCurrentUser().account_pay_type === 'trial')
        )
      ) {
        columnClasses.push('highlighted-plan');

        if (this.$isBusiness) {
          columnClasses.push('business');
        }
      }

      return columnClasses;
    },
    getMostPopularPlanIds() {
      return ['2', '5'];
    },
    selectPlan(plan) {
      if (this.isCurrentPlan(plan.prim_uid) && !this.subscriptionExpired) {
        return;
      }

      if (!plan.upgradable && this.hasActivePlan) {
        return this.showPlanDowngradeConfirmation(plan.prim_uid);
      }

      if (this.changeSubscriptionAfterEndTerm) {
        return this.changeAfterEndTermPackage(plan.prim_uid).then((response) => {
          if (response.data.success) {
            this.$toast.success(this.$t('billing_trial.upgrade_msg')
              .replace('%end_date%', this.getCurrentUser().end_date));

            this.$emit('upgraded');
          }
        });
      }

      return this.buyPackage(plan.prim_uid);
    },
    showPlanDowngradeConfirmation(planId) {
      this.downgradeSelectedPlan = this.plans.find((planEntry) => {
        return planEntry.prim_uid === planId;
      });

      this.isPlanDowngradeConfirmationVisible = true;
    },
    buyPackage(planId) {
      this.$store.commit('billing/setPlanDetail', planId);
      this.$router.push('/setting/BillingSubscribe');
    },
    downgradePackage(planId) {
      this.changeAfterEndTermPackage(planId).then((response) => {
        if (response.data.success) {
          this.$toast.success(this.$t('billing_trial.downgrade_msg').replace('%end_date%', this.getCurrentUser().end_date));
          this.$toast.warning(this.$t('billing_subscribe.downgrade'));

          this.closeDowngradeConfirmationModal();
          this.$emit('downgraded');
        }
      });
    },
    changeAfterEndTermPackage(planId) {
      return this.billingService.after_end_term(
        {
          'status': 'another_package',
          'after_plan_uid': planId,
          'plan_uid': this.getCurrentUser().plan_uid
        },
        true
      );
    },
    closeDowngradeConfirmationModal() {
      this.isPlanDowngradeConfirmationVisible = false;
      this.isPlanDowngradeConfirmed = false;
      this.downgradeSelectedPlan = null;
    },
    doesPlanContainFeature(plan, featureKey) {
      if (!Object.hasOwn(plan, featureKey)) {
        return false;
      }

      const featureValueInt = parseInt(plan[featureKey] ?? 0);
      if (featureValueInt === 1) {
        return true;
      }

      return false;
    },
    doesNewPlanHaveLessOfFeature(oldPlan, newPlan, featureKey) {
      const maxValuesForFeature = ['Unbegrenzt', 'Unlimited'];
      if (maxValuesForFeature.includes(newPlan[featureKey])) {
        return false;
      } else if (maxValuesForFeature.includes(oldPlan[featureKey])) {
        return true;
      }

      const oldPlanFeatureValue = parseInt(oldPlan[featureKey] ?? 0);
      const newPlanFeatureValue = parseInt(newPlan[featureKey] ?? 0);
      if (newPlanFeatureValue < oldPlanFeatureValue) {
        return true;
      }

      return false;
    },
    getFeatureByKey(featureKey) {
      let feature = this.features.find((feature) => {
        return feature.feature === featureKey;
      });

      if (typeof feature === 'undefined') {
        return null;
      }

      return feature;
    },
    getFeatureDowngradeLabel(feature) {
      if (typeof this.currentPlan === 'undefined') {
        return '';
      }

      if (feature.isBoolean) {
        return feature.featureHover.label;
      }

      let lostFeatureValue = this.currentPlan[feature.feature];
      if (lostFeatureValue === feature.emptyValue) {
        lostFeatureValue = feature.emptyValueText;
      }

      return feature.featureHover.label + ': ' + lostFeatureValue;
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog.plan-downgrade-confirmation-modal) {
  height: auto;
  max-width: 800px;
  width: 65vw;
}

table {
  @include segoe-regular;

  th,
  td {
    border: 1px solid #dfdfdf;

    &.highlighted-plan {
      background-color: #effff7;

      &.business {
        background-color: #fff1c0;
      }
    }
  }

  thead {
    th:first-of-type {
      border: none;
    }

    th {
      position: relative;
    }

    .ribbon {
      position: absolute;
      right: -4px;
      top: -6px;
      z-index: 1;
      overflow: hidden;
      width: 100px;
      height: 100px;

      span {
        text-align: center;
        transform: rotate(45deg);
        width: 130px;
        background-color: $primary;
        box-shadow: 0 3px 10px -5px black;
        position: absolute;
        top: 25px;
        right: -29px;
        padding: 5px 5px 5px 6px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          z-index: -1;
          border-width: 3px;
          border-style: solid;
          border-color: #79a70a;
        }

        &::before {
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          left: 0;
        }

        &::after {
          border-left: 3px solid transparent;
          border-bottom: 3px solid transparent;
          right: 0;
        }
      }
    }
  }

  td img {
    margin-left: auto;
    margin-right: auto;
  }

  tbody {
    th {
      @apply text-sm;

      height: 4em;

      :deep(.v-popper) {
        height: 100%;

        p {
          height: 100%;
          padding: 0 1em;
        }
      }

      :deep(p) {
        height: 100%;
        padding: 0 1em;
      }
    }

    tr:last-of-type {
      th:first-of-type {
        border: none;
      }
    }
  }

  :deep(.button) {
    max-width: 100%;
  }
}
</style>
