<template>
  <div>
    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1">
        <InputSelect class="mt-2 w-full" :isError="errors.assignedUser.invalid"
                     :errorMessage="$t('general.errors.required')"
                     :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
                     :options="userList"
                     v-model="assignedUser"
                     :selectedValue="assignedUser"
                     :filterable="true"
                     @on-item-selected="fetchClient"
                     :isRequired="true" :placeholder="$t('general.please_select')"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div v-show="!fromPropertiesActive" class="flex justify-around space-x-1 mt-2">
        <div class="w-full text-left flex">
          <label :class="$isBusiness ? 'w-36' : 'w-28'">{{ $t('declarations.add_declaration.steps.step1.client') }}:</label>
          <label class="ml-5">{{ clientName}}</label>
        </div>
      </div>

      <div class="rectangle mt-4">
        <div class="flex w-full pl-3">
          <label class="text ">{{ $t("declarations.mass_update.selected_properties") }}</label>
        </div>
        <div class="flex justify-around space-x-1 mt-2">
        <DataGrid grid-id="grid-selected-properties"
                  :local-data="selectedProperties"
                  :columns="columns"
                  :pagination="false"
                  default-sort-attr="propertyTitle"
                  :default-sort-dir="1"
                  :show-select-col="false"
                  :use-local-data="true"
                  :resizable-columns="false"
                  :reorderable-columns="false"
                  :show-create-record="false"
        />
      </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex justify-end space-x-4 mr-6">
        <Button v-show="fromPropertiesActive" class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="goBack"/>
        <Button v-show="!fromPropertiesActive" class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack"/>
        <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DataGrid from "@/components/DataGrid";
import {UserService} from "@/services/user.service";

export default {
  name: "Step3",
  components: {DataGrid},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedProperties: [],
      columns: [
        {
          field: 'propertyTitle',
          header: this.$t('declarations.mass_update.property'),
          isChecked: true,
          sortable: false,
          autoWidth: true
        }, {
          field: 'clientName',
          header: this.$t('declarations.mass_update.client'),
          isChecked: false, // only set true for bulk add from properties
          sortable: false,
          autoWidth: true
        }, {
          field: 'declarationReferenceDate',
          header: this.$t('declarations.add_declaration.steps.step2.reference_date'),
          isChecked: true,
          colElType: "select",
          dataSource: [],
          model: 'declarationReferenceDate',
          sortable: false,
          width: 200
        }, {
          field: 'declarationNumber',
          header: this.$t('declarations.add_declaration.steps.step2.declaration_number'),
          isChecked: true,
          colElType: "text",
          width: 250,
          sortable: false,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('declarations.add_declaration.steps.step2.tooltip.type'),
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/"
        }, {
          field: 'declarationType',
          header: this.$t('declarations.add_declaration.steps.step2.type'),
          isChecked: true,
          colElType: "select",
          dataSource: [],
          model: 'declarationType',
          sortable: false,
          width: 250,
          showHelpIcon: true,
          helpIconType: 'popover',
          popoverTexts: [
            this.$t('properties.step3.tooltip.tooltip_client_text2')
          ],
          popoverLink: "https://hilfe.grundsteuer-digital.de/faq/grund-der-feststellung/"
        }
      ],
      initialized: false,
      fromPropertiesActive: false,
      errors: {
        "assignedUser": {
          invalid: false
        }
      },
      userList: [],
      userService: new UserService(),
      assignedUser: 0
    }
  },
  watch: {
    active(newValue) {
      if(newValue) {
        this.init();
      }
    }
  },
  computed: {
    clientName() {
      return this.getSteps().clientName ? this.getSteps().clientName : '';
    },
  },
  methods: {
    ...mapGetters("declaration_bulk_add", ["getSteps"]),
    ...mapMutations("declaration_bulk_add", ["saveSteps"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapGetters("user", ["getCurrentUser"]),

    init() {
      if (this.getSteps().selectedProperties && this.getSteps().selectedProperties.length > 0) {
        this.fromPropertiesActive = this.getSteps().fromPropertiesActive === true;
        this.selectedProperties = JSON.parse(JSON.stringify(this.getSteps().selectedProperties));
        this.selectedProperties = this.selectedProperties.map((o) => {
          if (typeof o.declarationType === 'undefined' || o.declarationType === null || o.declarationType === '') {
            if (typeof o.declarationRelevantMarkedByUser !== 'undefined' && parseInt(o.declarationRelevantMarkedByUser) === 1) {
              o.declarationType = o.nextDeclarationType;
              if (o.declarationType === '' && o.declarationType === '0') {
                o.declarationType = '1';
              }
            } else {
              o.declarationType = '1';
            }
          }

          if (o.declarationType === '1') {
            o.declarationReferenceDate = '2022';
          } else {
            o.declarationReferenceDate = '2023';
          }

          o.propertyTitle = o.property_id + ' - ' + o.name;
          o.dataSources = {};
          o.dataSources['declarationType'] = [];
          if (o.federalStateUid === 6 || o.federalStateUid === 2 || o.federalStateUid === 9) {
            // types_bayern
            o.dataSources['declarationType'] = this.$t('declarations.add_declaration.type_options.types_bayern');
          } else if (o.federalStateUid === 1) {
            // types_baden
            o.dataSources['declarationType'] = this.$t('declarations.add_declaration.type_options.types_baden');
          } else if (o.federalStateUid === 7) {
            // types_hessen
            o.dataSources['declarationType'] = this.$t('declarations.add_declaration.type_options.types_hessen');
          } else {
            o.dataSources['declarationType'] = this.$t('declarations.add_declaration.type_options.types_all');
          }

          let declaration_reference_date_options = [];
          for (var i = 2022; i <= 2050; i++) {
            declaration_reference_date_options.push({
              'name': i,
              'code': i
            });
          }
          o.dataSources['declarationReferenceDate'] = declaration_reference_date_options;

          if (this.fromPropertiesActive) {
            o.clientName = o.client_name;
          }

          return o;
        });
      }
      this.triggerReload('grid-selected-properties');
      this.initialized = true;
    },
    fetchClient(e) {
      if(e && e.name) {
        this.saveSteps({
          "assignedUser": e.code,
          "assignedUserName": e.name
        });
      }
    },
    submitNext() {
      if (this.assignedUser === "0") {
        this.errors.assignedUser.invalid = true
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.assignedUser.invalid = false
      }

      if(this.selectedProperties.length > 0) {
        this.saveSteps({
          "selectedProperties": this.selectedProperties
        });
        this.$emit("goToNextStep")
      }
    },
    goBack() {
      this.$emit("goBack");
    }
  },
  mounted() {
    if (!this.initialized) {
      this.init();
    }

    this.userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            "name": item.name,
            "code": item.id
          });
        }

        return filtered;
      }, []);

      this.fetchClient({
        "code": this.getCurrentUser().id,
        "name": this.getCurrentUser().name
      });

      this.assignedUser = this.getCurrentUser().id;
    });
  },
  beforeMount() {
    if (this.$route.name === 'Properties') {
      this.columns.find(c => c.field === 'clientName').isChecked = true;
      this.columns.find(c => c.field === 'clientName').header =
          this.$isBusiness ? this.$t('declarations.mass_update.clients_business') : this.$t('declarations.mass_update.client');
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  font-family: 'Segoe UI', sans-serif;
  color: $primary;
}
.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}
.text_information {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Segoe UI Regular", "Segoe UI", sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}
.rectangle {
  padding-top: 10px;
  border: 1px solid #dfdfdf;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position:sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
</style>
