<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('properties.title')" :breadcrumbs="breadcrumbs"/>
    <!-- Import Error Dialog -->
    <ModalDialog v-show="showImportError" :headerText="$t('general.import_error')" @close="closeImportErrorDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-2">
          <DataGrid
              grid-id="property_import_errors"
              :local-data="importErrors"
              grid-type="importError"
              default-sort-attr="row_no"
              :default-sort-dir="1"
              :show-select-col="false"
              :use-local-data="true"
          />
          <div class="flex mt-2 mb-4  mr-6 justify-end   space-x-4">
            <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeImportErrorDlg"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <div class="w-full flex flex-col pr-7 ">
      <p class="header1 mt-4">{{ $t('clients.import.title') }}</p>
      <div class="box flex justify-start items-center">
        <img class="image ml-2" src="@/assets/info_gray.svg"/>
        <label class="text ml-2">
          {{ $t('properties.import.info1') }}
          <span class="text-link underline cursor-pointer" :class="isBusiness ? 'business-text-link' : ''"
                @click="downloadTemplate">{{
              $t('properties.import.info2')
            }}</span>
          {{ $t('clients.import.info3') }}
        </label>
      </div>
      <div class="flex justify-around mt-6 space-x-10">
        <InputSelect
            class="w-full"
            v-model="step1.clientUid"
            :isError="isClientError"
            :errorMessage="clientError"
            :label="$t('properties.step1.client')"
            :options="clientList"
            :search-fn="clientListSearch"
            :total-record-count="totalClientsCount"
            :sort="true"
            :filterable="true"
            :isRequired=true
            placeholder=""
            @on-item-selected="selectclientUid"
        />
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex justify-around mt-6 space-x-10">
        <InputUpload class="w-full" :label="$t('clients.import.select')" @select="fileSelect" accept=".xls,.xlsx,.csv"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex  justify-end space-x-4 mt-10  ">
        <Button class="w-52  " @click="submitNext" :text="$t('clients.import.upload_review')"></Button>
      </div>
    </div>
    <div class="w-full flex flex-col pr-7 pb-10 ">
      <hr class="hr-line-user mt-7">
      <p class="header1 mt-4">{{ $t('properties.import.multi_client_title') }}</p>
      <div class="box flex justify-start items-center">
        <img class="image ml-2" src="@/assets/info_gray.svg"/>
        <label class="text ml-2">
          {{ $t('properties.import.multi_client_info1') }}
          <span class="text-link underline cursor-pointer" :class="isBusiness ? 'business-text-link' : ''"
                @click="downloadTemplate">{{
              $t('properties.import.info2')
            }}</span>
          {{ $t('clients.import.info3') }}
        </label>
      </div>
      <div class="flex justify-around mt-6 space-x-10">
        <InputUpload class="w-full" :label="$t('clients.import.select')" @select="fileSelect" accept=".xls,.xlsx,.csv"/>
        <div class="w-full pr-6 invisible"/>
      </div>
      <div class="flex  justify-end space-x-4 mt-10  ">
        <Button class="w-52  " @click="cancelImport" :text="$t('buttons.back')" :secondary="true"></Button>
        <Button class="w-52  " @click="uploadMultiClient" :text="$t('clients.import.upload_review')"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import {PropertyService} from "../../services/property.service";
import InputUpload from "../inputs/InputUpload";
import {mapGetters, mapMutations} from "vuex";
import {ClientService} from "../../services/client.service";
import DataGrid from "../DataGrid";
import {S3Service} from "@/services/s3.service";

export default {
  name: "csvimportproperty",
  components: {
    InputUpload,
    DataGrid
  },
  data() {
    return {
      service: new PropertyService(),
      clientService: new ClientService(),
      s3Service: new S3Service(),
      excelData: null,
      fileExtension: '',
      clientList: [],
      totalClientsCount: 0,
      breadcrumbs: [
        {
          title: this.$i18n.t('breadcrumbs.land_units'),
          link: ''
        }, {
          title: this.$i18n.t('breadcrumbs.add_property'),
          link: ''
        }
      ],
      step1: {
        clientUid: "0"
      },
      clientError: '',
      isClientError: '',

      importErrors: [],
      showImportError: false,
    }
  },
  mounted() {
    this.setImportData([]);
    this.clientService.listUserClient('current').then((clients) => {
      if (clients && clients.list) {
        this.totalClientsCount = clients.totalRecordCount;
        this.clientList = clients.list.map((o) => {
          return {
            "name": o.client_id + ' - ' + o.client_name,
            "code": o.prim_uid
          }
        });
      }
    });
  },
  methods: {
    ...mapMutations("property", ["setImportData", "setExcelError"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapGetters("property", ["getFilePath"]),
    selectclientUid(item) {
      if (item.code === "0" || item.code === "") {
        this.isClientError = true;
      } else {
        this.isClientError = false;
      }
    },
    clientListSearch(params) {
      return this.clientService.listUserClient('current', params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "name": o.client_id + ' - ' + o.client_name,
              "code": o.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    async uploadFile() {
      this.$loading.show();
      let file_name = 'property_data.xlsx';
      this.s3Service.upload({
        data: this.excelData,
        fileName: file_name,
        prefix: 'properties/',
        contentType: this.excelData.type
      }).then((res) => {
        if (res.success) {
          let propertyService = new PropertyService();
          propertyService.csvImport(this.step1.clientUid, res.s3Path).then((response) => {
            if (response.data.success && response.data.jobId) {
              setTimeout(() => {
                this.checkImportStatus(response.data.jobId);
              }, 30000);
            } else {
              this.$loading.hide();
              this.$api.showToast(response.data.message, "error");
            }
          }).catch((error) => {
            this.$loading.hide();
            this.$api.showToast(error, "error")
          });
        } else {
          this.$loading.hide();
          this.$api.showToast(this.$t('general.errors.unknownError'), "error")
        }
      }).catch(() => {
        this.$loading.hide();
        this.$api.showToast(this.$t('general.errors.unknownError'), "error")
      });
    },
    checkImportStatus(jobId) {
      this.$api.trigger('property/check_import_status', {"jobId": jobId}).then((response) => {
        if (response.data.success) {
          if (response.data.status === 'completed') {
            this.$loading.hide();
            let importResult = response.data.importResult;
            if (importResult.success) {
              this.setImportData([]);
              this.$router.push('/property/FinishProperty');
            } else if (importResult.importErrors) {
              this.showImportError = true;
              this.importErrors = this.service.parse_object_data(importResult.importErrors);
              this.triggerReload('property_import_errors');
            } else if (importResult.message) {
              this.$api.showToast(importResult.message, "error");
            } else {
              let validationObject = importResult.error_info;
              let message_type = 'error';
              if (importResult.sheet_count && parseInt(importResult.sheet_count) === 1) {
                message_type = 'warn'
                let lastIndex = this.excelData[0].indexOf('Worksheet')
                this.excelData[0].splice(lastIndex, 1);
                let totalLines = this.excelData[0].length - 1;
                for (let i = 0; i < totalLines; i++) {
                  for (let j = 0; j < this.excelData[0][i].length; j++) {
                    if (this.excelData[0][i][j] === '' || this.excelData[0][i][j] == null || this.excelData[0][i][j] === ' ') {
                      this.excelData[0][i].splice(j, 1);
                    }
                  }
                  this.excelData[0][i] = this.excelData[0][i].filter(function (el) {
                    if (el !== '' || el !== '&lsquo; &rsquo;' || el !== '&ldquo; &rdquo;') {
                      return el;
                    }
                  });
                }

                this.excelData[0] = this.excelData[0].filter(function (el) {
                  if (el.length !== 0) {
                    return el;
                  }
                });

                this.setImportData(this.excelData[0]);
                this.setExcelError(validationObject);
                this.$store.state.clientUid = this.step1.clientUid;
                this.$router.push('/property/csvimportierenproperty');
              }
              this.$api.showToast(importResult.error_message, message_type);
            }
          } else {
            setTimeout(() => {
              this.checkImportStatus(jobId);
            }, 30000);
          }
        } else {
          this.$loading.hide();
          this.$api.showToast(error, "error")
        }
      })
    },
    submitNext() {
      if (this.excelData) {
        this.isClientError = false
        if (this.step1.clientUid === "0" || this.step1.clientUid === "") {
          this.isClientError = true
          this.clientError = this.$t('general.errors.required')
        } else {
          this.uploadFile();
        }
      }
    },
    uploadMultiClient() {
      this.step1.clientUid = 'MULTI-CLIENT';
      this.submitNext();
    },
    cancelImport() {
      this.$router.go(-1)
    },
    fileSelect(e) {
      if (e.files.length > 0) {
        let file = e.files[0];
        this.excelData = file;
        let fileName = file.name;
        this.fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
      }
    },
    downloadTemplate() {
      window.open("https://hilfe.grundsteuer-digital.de/faq/vorerfassungsdateien-der-angaben-fuer-die-feststellungserklaerungen/", "_blank")
    },
    closeImportErrorDlg() {
      this.showImportError = false;
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.header1 {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-bottom: 20px;
}

span.text-link {
  color: $primary;
}
</style>
