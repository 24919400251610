<template>
  <div class="flex space-x-2">
    <InputSelect class="w-full"
                 :options="poaTypeOptions"
                 overlayAppendTo="body"
                 v-model="poaType"
                 :selected-value="poaType"
                 @on-item-selected="poaTypeSelected"
    />
    <InputSelect v-show="showPoaList" class="w-full"
                 :options="poaList"
                 :filterable="true"
                 v-model="poaId"
                 :selected-value="poaId"
                 overlayAppendTo="body"
    />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";

export default {
  name: "PoaSelect",
  components: {
    InputSelect
  },
  props: {
    poaTypeOptions: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      service: new PowersOfAttorneyService(),
      poaType: '',
      poaId: '',
      poaList: [],
      showPoaList: false
    }
  },
  watch: {
    poaType() {
      this.selected();
    },
    poaId() {
      this.selected();
    },
    selectedValue(nv) {
      this.handleSelectedValue();
    }
  },
  mounted() {
    this.service.listAll().then((list) => {
      this.poaList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
    });
    this.handleSelectedValue();
  },
  methods: {
    poaTypeSelected(e) {
      this.showPoaList = e.code && e.code === 'tax_consultant';
    },
    selected() {
      if(this.poaType === 'tax_consultant') {
        this.$emit('input', this.poaType + ':' + this.poaId);
      } else {
        this.$emit('input', this.poaType);
      }
    },
    handleSelectedValue() {
      if(this.selectedValue) {
        let selectedValueParts = this.selectedValue.split(':');
        if (selectedValueParts[0]) {
          this.poaType = selectedValueParts[0];
        }
        if (selectedValueParts[1]) {
          this.poaId = selectedValueParts[1];
        }
      } else {
        this.poaType = '';
        this.poaId = '';
      }
    }
  }
}
</script>

<style scoped lang="scss">


</style>
