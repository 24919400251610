<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('clients.datev_import.title')" :logo="require('@/assets/datev_logo.png')"/>

    <div class="flex flex-col justify-center items-center">
      <div class=" mr-12 w-full wiz">
        <FormSteps :steps="getSteps"/>
      </div>
      <div class="w-full">
        <div class="mt-10 mr-6" v-if="completed===false">
          <component
              v-for="(item, index) in getSteps"
              :key="`component-${index}`"
              :is="item.component"
              :active="item.active"
              @goToNextStep="goToNextStep"
              @goToLast="goToLast"
              @goBack="goBack"
              @finish="finish"
              @updateSteps="updateSteps"
              v-show="item.active === true"
          />
        </div>
        <div class="mt-10 mr-6" v-else>
          <Finish @finishButtonClick="handleFinishButtonClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSteps from "../FormSteps";
import Step1 from '@/components/datev/data_steps/Step1.vue';
import Step2 from '@/components/datev/data_steps/Step2.vue';
import Step3 from '@/components/datev/data_steps/Step3.vue';
import Step4 from '@/components/datev/data_steps/Step4.vue';
import Finish from '@/components/datev/data_steps/Finish.vue';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ImportData",
  components: {FormSteps, Step1, Step2, Step3, Step4, Finish},
  data() {
    return {
      completed: false,
      steps: [
        {
          id: 1,
          title: this.$t('clients.datev_import.steps.step1.title'),
          completed: false,
          active: true,
          first: true,
          show: true,
          component: "Step1"
        },
        {
          id: 2,
          title: this.$t('clients.datev_import.steps.step2.title'),
          completed: false,
          active: false,
          last: true,
          show: true,
          component: "Step2"
        },
        {
          id: 3,
          title: this.$t('clients.datev_import.steps.step3.title'),
          completed: false,
          active: false,
          last: true,
          show: false,
          component: "Step3"
        },
        {
          id: 4,
          title: this.$t('clients.datev_import.steps.step4.title'),
          completed: false,
          active: false,
          last: true,
          show: true,
          component: "Step4"
        },
        {
          id: 5,
          title: this.$t('clients.datev_import.steps.finish.title'),
          completed: false,
          active: false,
          last: true,
          show: true,
          component: "finish"
        }
      ],
    }
  },
  computed: {
    ...mapGetters("datev_import", ["getCurrentStep"]),
    getSteps() {
      return this.steps.filter((o) => {
        return o.show;
      });
    }
  },
  mounted() {
    this.setCurrentStep(1);
    this.init();
    if(this.$route.params.oauth && this.$route.params.oauth === 'completed') {
      this.goToNextStep();
    }
  },
  methods: {
    ...mapMutations("datev_import", ["setCurrentStep", "init"]),
    goToNextStep() {
      this.moveStep('next')
    },
    goToLast() {
      this.moveStep('next', true)
    },
    goBack() {
      this.moveStep('back')
    },
    finish() {
      this.moveStep('next', true)
      this.completed = true
    },
    updateSteps(e) {
      this.steps = this.steps.map((o) => {
        if(e.show && e.show === o.id) {
          o.show = true;
        }
        if(e.hide && e.hide === o.id) {
          o.show = false;
        }

        return o;
      });
    },
    changeWindowSize() {
      window.ipcRenderer.send('resizeRegister')
    },
    moveStep(direction, isLast) {
      if (isLast) {
        this.steps = this.steps.map(x => {
          return {...x, completed: true, active: false}
        })
      } else {
        let step = direction === 'next' ? this.getCurrentStep + 1 : this.getCurrentStep - 1
        let index = step - 1;
        let steps = this.getSteps.map((x, i) => {
          if (i === index) {
            return {...x, completed: false, active: true}
          } else if (i > index) {
            return {...x, completed: false, active: false}
          } else if (i < index) {
            return {...x, completed: true, active: false}
          }
        })
        this.steps = this.steps.map((o) => {
          let index = steps.findIndex(item => item.id === o.id);
          o = {...o, ...steps[index]};

          return o;
        });
        this.setCurrentStep(step)
      }
    },
    handleFinishButtonClick() {
      this.$emit("click", "report_add_finish")
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.wiz::v-deep {
  .step_div {
    max-width: 140px;
  }

  .step_div .text {
    white-space: nowrap;
  }
  .step_div.step_1 .line {
    width: 20px;
    margin-right: -90px;
  }
}

.logo {
  width: 56px;
  height: 55px;

}
</style>
