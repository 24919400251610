<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('clients.import.title')" :breadcrumbs="breadcrumbs"/>
    <div class="w-full flex flex-col pr-7 mt-8">
      <div class="box flex justify-start items-center">
        <img class="image ml-2" src="@/assets/info_gray.svg"/>
        <label class="text ml-2">
          {{ $t('clients.import.info1') }}
          <span class="text-link underline cursor-pointer" :class="isBusiness ? 'business-text-link' : ''" @click="downloadTemplate">{{
              isBusiness ? $t('clients.import.info2_business') : $t('clients.import.info2')
            }}</span>
          {{ $t('clients.import.info3') }}
        </label>
      </div>
      <div class="space-y-4 mt-6">
        <InputUpload
          class="w-1/2"
          :label="$t('clients.import.select')"
          accept=".xls,.xlsx,.csv"
          @select="fileSelect"
        />
      </div>
      <div class="flex justify-end mt-10 mb-6 space-x-4 ">
        <Button class="w-56" :text="$t('buttons.cancel')" :secondary="true" @click="cancelImport"/>
        <Button class="w-56" :text="$t('clients.import.upload_review')" @click="submitNext"/>
      </div>
    </div>
  </div>
</template>

<script>
import InputUpload from "../inputs/InputUpload";
import {mapGetters, mapMutations} from "vuex";
import {i18n} from "@/plugins/i18n";
import {ClientService} from "../../services/client.service";

export default {
  name: "csvimport",
  components: {InputUpload},
  data() {
    return {
      service: new ClientService(),
      excelData: [],
      selectedFile: [],
      breadcrumbs: [
        {
          title: this.$t('clients.title'),
          link: '/client/list'
        }, {
          title: this.$t('clients.import.title'),
          link: ''
        }
      ],
    }
  },
  mounted() {
    this.setImportData([]);
    let clientData = this.getClientData();
    if (typeof (clientData['branch']) === 'undefined' || clientData['branch'] === 0) {
      this.cancelImport();
    }
  },
  methods: {
    ...mapMutations("client", ["setImportData"]),
    ...mapGetters("client", ["getClientData"]),
    async uploadFile() {
      let response = await this.service.csvUpload(this.selectedFile);

      if (response.success !== true) {
        return;
      }

      const numberOfRows = (response.rows[0] ?? []).length;
      if (numberOfRows < 2) {
        this.$api.showToast(i18n.t('clients.import.minimum_rows'), 'error');
        return;
      }

      if (numberOfRows > parseInt(process.env.VUE_APP_CLIENT_IMPORT_LIMIT)) {
        this.$api.showToast(i18n.t('clients.import.limit_error'), 'error');
        return;
      }

      this.excelData = response.rows[0];
      this.setImportData(this.excelData);
      this.$router.push('/client/csvimportieren');
    },
    submitNext() {
      if (this.selectedFile.length > 0) {
        this.uploadFile();
      } else {
        this.$api.showToast(i18n.t('clients.import.choose_file'), 'error');
      }
    },
    cancelImport() {
      this.$router.push('/client/list')
    },
    fileSelect(e) {
      if(e.files.length > 0) {
        let file = null;
        file = e.files[0];
        this.excelData = [];
        this.selectedFile = [];
        this.selectedFile.push(file);
        this.setImportData(this.excelData);
      }
    },
    downloadTemplate() {
      window.open("https://hilfe.grundsteuer-digital.de/faq/importvorlage-mandanten/", "_blank")
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.text-link {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: $primary;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

</style>
